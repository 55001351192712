import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { RecoverContentModal } from '../RecoverContentModal';

export const RecoverContentButton = ({ contentIds, onSuccess }) => {
    const { setModal } = useModal();

    const handleRecoverContent = () => setModal({
        header: 'Are you sure you recover this content?',
        component: <RecoverContentModal contentIds={contentIds} onSuccess={onSuccess} />
    });

    return (
        <Button className="mr-1" color="primary" onClick={handleRecoverContent}>
            Recover content
        </Button>
    );
};
