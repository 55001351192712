import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { UPDATE_REPORT } from '../../pages/Room/Reports/graphql';

export const useUpdateReport = () => {
    const [loading, setLoading] = useState(false);
    const [updateReport, { data }] = useMutation(UPDATE_REPORT, {
        onCompleted: () => { setLoading(false); },
        onError: () => setLoading(false)
    });

    return {
        updateReport: (variables) => {
            setLoading(true);
            updateReport({ variables });
        },
        data: data?.updateReport,
        loading
    };
};
