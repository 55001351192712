import React from 'react';
import uuid from 'uuid-random';

export const LoadingState = ({ items }) => (
    <div className="loading-container">
        {items.map(() => (
            <div key={uuid()} className="mb-4">
                <div className="loading-bg py-5 px-3 mb-2"><p>Loading</p></div>
            </div>
        ))}
    </div>
);
