import moment from 'moment';
import React from 'react';
import { Col } from 'reactstrap';
import { TeamRoleRestricted } from '../../../../../components/Molecules/TeamRoleRestricted';

export const CreatedBy = ({ selected, collapse }) => (
    <Col xs={12} md={3}>
        {(selected && collapse) && (
            <div className="p-4">
                <TeamRoleRestricted>
                    <p className="mt-0 mb-2">Owner</p>
                    {selected.createdBy && <h4 className="mt-0">{selected.createdBy.displayName || selected.createdBy.email}</h4>}
                    <p className="mt-4 mb-2">Last edited by</p>
                    {selected.updatedBy ? (
                        <div>
                            <h4 className="mb-1">{selected.updatedBy.displayName || selected.updatedBy.email}</h4>
                            <p className="m-0">{moment(new Date(selected.updatedAt)).format('DD MMMM YYYY | h:mm A')}</p>
                        </div>
                    ) : (
                        <div>
                            {selected.createdBy && (
                                <div>
                                    <h4 className="mb-1">{selected.createdBy.displayName || selected.createdBy.email}</h4>
                                    <p className="m-0">{moment(new Date(selected.createdAt)).format('DD MMMM YYYY | h:mm A')}</p>
                                </div>
                            )}
                        </div>
                    )}
                </TeamRoleRestricted>
            </div>
        )}
        {(selected && !collapse) && <h4 className="m-0 p-4">{selected.name}</h4>}
    </Col>
);
