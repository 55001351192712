import { convertToMarkers } from '../convertToMarkers';

export const quotesValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    // Find all double quotes
    const allQuotes = [...query.matchAll(/"/g)];

    for (let i = 0; i < allQuotes.length; i += 2) {
        const openingQuote = allQuotes[i];
        const closingQuote = allQuotes[i + 1];
        if (!closingQuote) {
            // no closing quote so they are unbalanced
            highlight.push([openingQuote.index, query.length]);
        }
    }

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have an odd number of quotes in your query. You may have used too many or too few quotes.'
        )
    };
};
