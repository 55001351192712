import React, { useState } from 'react';
import { Row, Col, CustomInput } from 'reactstrap';

import { useUser } from '../../../context/user/UserContext';
import { modeChecker } from '../../../utils/modeSwitch';

export const Preferences = ({ updateProfile }) => {
    const { user, setUser } = useUser();
    const [cookies, setCookies] = useState(user.preferences.cookies);
    const [marketing, setMarketing] = useState(user.preferences.marketingEmails);
    const [darkmode, setDarkmode] = useState(user.preferences.darkmode);

    const handleChange = ({ cookies: cookiesOption, marketing: marketingOption, darkMode }) => {
        const preferences = {
            cookies: cookiesOption,
            marketingEmails: marketingOption,
            productEmails: user.preferences.productEmails || false,
            darkmode: darkMode
        };
        setUser({
            ...user,
            preferences
        });
        updateProfile({
            uid: user.uid,
            email: user.email,
            preferences
        }, true);
    };

    const handleDarkmode = (e) => {
        modeChecker(e.target.checked);
        setDarkmode(e.target.checked);
    };
    const checkDarkmode = () => !((!darkmode && !document.body.classList.contains('darkmode--activated'))
            || (darkmode && document.body.classList.contains('darkmode--activated')));
    return (
        <div data-testid="account-preferences-tab">
            <Row className="align-items-center">
                <Col>
                    <h3 className="mt-0">Cookies</h3>
                    <p className="mb-0">Allow sites to save and read cookie data (recommended)</p>
                </Col>
                <Col xs="auto">
                    <CustomInput type="switch"
                        id="cookiesSwitch"
                        data-testid="cookiesSwitch"
                        valid={!!cookies}
                        checked={!!cookies}
                        onChange={(e) => {
                            setCookies(e.target.checked);
                            handleChange({ cookies: e.target.checked });
                        }}
                        className="custom-control-input-big"
                    />
                </Col>
            </Row>
            <hr />
            <Row className="align-items-center">
                <Col>
                    <h3 className="mt-0">Marketing Emails</h3>
                    <p className="mb-0">Be the first to hear about Logically&apos;s new products, features and innovations</p>
                </Col>
                <Col xs="auto">
                    <CustomInput type="switch"
                        id="marketingSwitch"
                        data-testid="marketingSwitch"
                        valid={!!marketing}
                        checked={!!marketing}
                        onChange={(e) => {
                            setMarketing(e.target.checked);
                            handleChange({ marketing: e.target.checked });
                        }}
                        className="custom-control-input-big"
                    />
                </Col>
            </Row>
            <hr />
            <Row className="align-items-center">
                <Col>
                    <h3 className="mt-0">Darkmode</h3>
                    {checkDarkmode() ? <p className="mb-0">Switching mode..</p>
                        : <p className="mb-0">Turn {!darkmode ? 'on' : 'off'} Darkmode</p>}
                </Col>
                <Col xs="auto">
                    <CustomInput type="switch"
                        id="darkmodeSwitch"
                        data-testid="darkmodeSwitch"
                        valid={!!darkmode}
                        checked={!!darkmode}
                        onChange={(e) => {
                            handleDarkmode(e);
                            handleChange({ darkMode: e.target.checked });
                        }}
                        className="custom-control-input-big"
                    />
                </Col>
            </Row>
        </div>
    );
};
