import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Button } from 'reactstrap';

type OpenButtonProps = {
    name: string,
    handleClick: () => void,
    isOpen: boolean,
    slidersIcon?: boolean,
    hasValues: boolean
}

export const OpenButton = ({
    name,
    handleClick,
    isOpen,
    slidersIcon = false,
    hasValues
}: OpenButtonProps) => (
    <Button className={`d-flex align-items-center ${hasValues ? 'btn-has-filters' : ''}`}
        onClick={handleClick}
        data-testid="new-boolean-button"
    >
        {slidersIcon && <FeatherIcon.Sliders size={16} className="mr-2" />}
        {name}
        <FeatherIcon.ChevronDown className={`ml-11 transform-transition ${isOpen ? 'transform-btn-icon' : ''}`} />
    </Button>
);
