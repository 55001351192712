import React, { useState } from 'react';
import { useTour } from '@reactour/tour';
import { TourStep } from '../../../components/FeatureTour';

export const aiNarrativesTourSteps = [
    {
        title: 'Feature tour',
        content: (
            <>
                <p className="mb-3">AI Narratives have arrived!</p>
                <p className="mb-3">
                    Content within your Situation Room has been grouped into Narrative Themes,
                    indicating the theme of the content within.
                </p>
                <p className="mb-3">
                    Narrative Themes contain Narratives which could contain discourse around a specific topic,
                    an artificially amplified narrative or even a copypasta campaign.
                </p>
                <p className="mb-3">
                    AI Narratives will enable you to identify themes, narratives, and content which help you answer
                    your research questions, quicker.
                </p>
                <p className="m-0">Ready to take a tour?</p>
            </>
        )
    },
    {
        title: 'Narratives and Narrative Themes',
        content: (
            <>
                <p className="mb-3">The base atom of information within AI Narratives is a piece of content.</p>
                <p className="mb-3">
                    These pieces of content are grouped together within a Narrative.
                    They&#x2019;re grouped based on how similar the text within the content is and the underlying themes.
                </p>
                <p className="m-0">
                    Narrative Themes contain similar Narratives acting like folders for organized content
                    enabling you to quickly identify the most important for your investigation. Narrative Themes
                    could identify overarching themes and trends within your Situation Room.
                </p>
            </>
        )
    },
    {
        title: 'Narrative and Narrative Theme Details',
        content: (
            <>
                <p className="mb-3">
                    Hover over the Narrative Themes and Narratives to see the full title,
                    the number of pieces of content, and the sentiment.
                </p>
                <p className="mb-3">
                    For Narrative Themes, you&#x2019;ll also see the number of Narratives, the top Actor,
                    and the number of duplicate documents within the Narrative Theme.
                </p>
                <p className="mb-3">
                    On clicking a Narrative theme, you&#x2019;ll be directed to see its details.
                    These consist of the Narrative theme summary,
                    engagement score, details of the top actors, sources, keywords, location mentions and content details.
                    You can use the volume over time graph to observe how the Narrative theme has behaved in the past.
                </p>
                <p className="m-0">
                    All of these data points will enable you to get a comprehensive understanding of Narratives and Narrative Themes.
                </p>
            </>
        )
    },
    {
        title: 'Chart View',
        content: (
            <>
                <p className="mb-3">You have the option to explore Narrative themes using either the Chart View or List View.</p>
                <p className="mb-3">
                    Chart View represents Narratives and Narrative Themes as bubbles, visually representing them within the Situation Room.
                    Larger Narrative themes are those that contain more content and the colors represent sentiment scores.
                    We&#x2019;ll be expanding on what&#x2019;s represented by the size, colour, and position of the bubbles in the future.
                </p>
                <p className="m-0">
                    This view is best for when you want to get an initial understanding of the landscape of a Situation Room.
                </p>
            </>
        )
    },
    {
        title: 'List View',
        content: `Alternatively, List View provides a comprehensive list of Narrative 
        Themes so that you can sort the Narrative themes by the
         different columns shown: engagement score, sentiment, number of content, number of duplicates, and number of Narratives.
          This enables you to explore the Narrative themes in a more systematic and detailed way.`
    },
    {
        title: 'Help and feature tour',
        content: 'Access the feature tour and documentation by clicking on the white question mark next to AI Narratives.'
    }
] as TourStep[];

export const useAINarrativesFeatureTour = () => {
    const hasDoneFeatureTour = localStorage.getItem('hasDoneAINarrativesFeatureTour');
    const [done, setDone] = useState(hasDoneFeatureTour);
    const { setIsOpen, isOpen } = useTour();

    return {
        handleAINarrativesFeatureTour: () => {
            if (done) return null;
            setDone('true');
            localStorage.setItem('hasDoneAINarrativesFeatureTour', 'true');
            setIsOpen(true);
        },
        isOpen
    };
};
