import React from 'react';
import { color } from '../../utils/getColors';

export const Report = () => (
    <svg width="122" height="80" viewBox="0 0 122 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color.grey[300]}
            d="M0.0318298 75.5202C0.0318298 76.7083 0.503851 77.8477 1.34393 78.6877C2.18401 79.5278
            3.32333 79.9998 4.51139 79.9998H117.489C118.077 80.0001 118.66 79.8845 119.203 79.6595C119.747
            79.4345 120.241 79.1046 120.657 78.6885C121.073 78.2725 121.403 77.7786 121.628 77.2349C121.853
            76.6913 121.969 76.1086 121.968 75.5202V12.6738H0.0318298V75.5202Z"
        />
        <path fill="#D2D4DB"
            d="M117.489 0H4.51139C3.32334 0 2.18401 0.471954 1.34393 1.31204C0.503852
            2.15212 0.0318298 3.2915 0.0318298 4.47956V12.6659H121.966V4.47956C121.966 3.29198 121.494
            2.15301 120.654 1.31301C119.815 0.47301 118.676 0.000731717 117.489 0Z"
        />
        <path fill={color.grey[200]}
            d="M7.81552 8.33533C8.89323 8.33533 9.76688 7.46168 9.76688 6.38397C9.76688
            5.30627 8.89323 4.43262 7.81552 4.43262C6.73782 4.43262 5.86417 5.30627 5.86417
            6.38397C5.86417 7.46168 6.73782 8.33533 7.81552 8.33533Z"
        />
        <path fill={color.grey[300]}
            d="M12.6009 8.33533C13.6786 8.33533 14.5522 7.46168 14.5522 6.38397C14.5522
            5.30627 13.6786 4.43262 12.6009 4.43262C11.5232 4.43262 10.6495 5.30627 10.6495
            6.38397C10.6495 7.46168 11.5232 8.33533 12.6009 8.33533Z"

        />
        <path fill={color.grey[300]}
            d="M17.6799 8.33533C18.7576 8.33533 19.6312 7.46168 19.6312 6.38397C19.6312
            5.30627 18.7576 4.43262 17.6799 4.43262C16.6022 4.43262 15.7285 5.30627
            15.7285 6.38397C15.7285 7.46168 16.6022 8.33533 17.6799 8.33533Z"
        />
        <path fill={color.grey[200]}
            d="M42.4588 24.6191H11.4855C10.4413 24.6191 9.59482 25.4656 9.59482
            26.5098V63.6352C9.59482 64.6794 10.4413 65.5259 11.4855 65.5259H42.4588C43.5029 65.5259
            44.3494 64.6794 44.3494 63.6352V26.5098C44.3494 25.4656 43.5029 24.6191 42.4588 24.6191Z"
        />
        <path fill={color.grey[200]}
            d="M109.83 24.6191H55.6801C54.6359 24.6191 53.7894 25.4656
            53.7894 26.5098V40.5198C53.7894 41.564 54.6359 42.4104 55.6801
            42.4104H109.83C110.874 42.4104 111.72 41.564 111.72
            40.5198V26.5098C111.72 25.4656 110.874 24.6191 109.83 24.6191Z"
        />
        <path fill={color.grey[200]}
            d="M109.83 46.3276H55.6801C54.6359 46.3276 53.7894 47.1741
            53.7894 48.2183V62.2283C53.7894 63.2725 54.6359 64.119 55.6801 64.119H109.83C110.874
            64.119 111.72 63.2725 111.72 62.2283V48.2183C111.72 47.1741 110.874 46.3276 109.83 46.3276Z"
        />
        <path fill={color.grey[300]}
            d="M24.2519 38.4248L14.4675 50.1412H34.0363L24.2519 38.4248Z"
        />
        <path d="M31.8056 37.1089L20.92 50.1419H42.6885L31.8056 37.1089Z" fill="white" />
        <path fill={color.grey[300]}
            d="M18.6459 39.383C19.9019 39.383 20.9201 38.3648 20.9201 37.1088C20.9201
            35.8527 19.9019 34.8345 18.6459 34.8345C17.3898 34.8345 16.3716 35.8527
            16.3716 37.1088C16.3716 38.3648 17.3898 39.383 18.6459 39.383Z"
        />
        <path fill="#D2D4DB"
            d="M104.53 31.5226H59.9362C59.7532 31.5226 59.5777 31.4499 59.4483
            31.3205C59.3189 31.1911 59.2462 31.0156 59.2462 30.8326C59.2462 30.6496
            59.3189 30.4741 59.4483 30.3447C59.5777 30.2153 59.7532 30.1426 59.9362 30.1426H104.53C104.713
            30.1426 104.889 30.2153 105.018 30.3447C105.148 30.4741 105.22 30.6496
            105.22 30.8326C105.22 31.0156 105.148 31.1911 105.018 31.3205C104.889
            31.4499 104.713 31.5226 104.53 31.5226Z"
        />
        <path fill="#D2D4DB"
            d="M104.53 36.8795H59.9362C59.7532 36.8795 59.5777 36.8069
            59.4483 36.6775C59.3189 36.5481 59.2462 36.3725 59.2462 36.1895C59.2462
            36.0065 59.3189 35.831 59.4483 35.7016C59.5777 35.5722 59.7532 35.4995 59.9362
            35.4995H104.53C104.713 35.4995 104.889 35.5722 105.018 35.7016C105.148
            35.831 105.22 36.0065 105.22 36.1895C105.22 36.3725 105.148 36.5481 105.018 36.6775C104.889
            36.8069 104.713 36.8795 104.53 36.8795Z"

        />
        <path fill="#D2D4DB"
            d="M104.53 53.2301H79.5852C79.4022 53.2301 79.2267 53.1575 79.0973
            53.0281C78.9679 52.8986 78.8951 52.7231 78.8951 52.5401C78.8951 52.3571
            78.9679 52.1816 79.0973 52.0522C79.2267 51.9228 79.4022 51.8501 79.5852
            51.8501H104.53C104.713 51.8501 104.889 51.9228 105.018 52.0522C105.148
            52.1816 105.22 52.3571 105.22 52.5401C105.22 52.7231 105.148 52.8986
            105.018 53.0281C104.889 53.1575 104.713 53.2301 104.53 53.2301Z"
        />
        <path fill="#D2D4DB"
            d="M104.53 58.5988H79.5852C79.4022 58.5988 79.2267 58.5261
            79.0973 58.3967C78.9679 58.2673 78.8951 58.0918 78.8951
            57.9088C78.8951 57.7258 78.9679 57.5503 79.0973 57.4209C79.2267
            57.2915 79.4022 57.2188 79.5852 57.2188H104.53C104.713 57.2188
            104.889 57.2915 105.018 57.4209C105.148 57.5503 105.22 57.7258
            105.22 57.9088C105.22 58.0918 105.148 58.2673 105.018 58.3967C104.889 58.5261 104.713 58.5988 104.53 58.5988Z"
        />
    </svg>
);
