import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { SelectedSubClusters } from './SubClustersTable';
import { RemoveSubClustersConfirmation } from '../Utils/ActionDropdown/RemoveSubClustersConfirmation';

type RemoveSubClusterButtonProps = {
    selectedSubClusters: SelectedSubClusters[]
    onCompleted: () => void
    className?: any
}

export const RemoveSubClusterButton = ({
    selectedSubClusters,
    onCompleted,
    className = ''
}: RemoveSubClusterButtonProps) => {
    const { setModal } = useModal();

    const setRemoveClustersModal = () => {
        const removeData = selectedSubClusters.map(({ id, status }) => ({ id, status }));
        setModal({
            component: <RemoveSubClustersConfirmation subClusterIdAndStatus={removeData} onCompleted={onCompleted} />
        });
    };
    return (
        <Button color="danger" onClick={setRemoveClustersModal} data-testid="remove-sub-clusters-button" className={className}>
            Remove Narrative{selectedSubClusters.length > 1 && 's'}
        </Button>
    );
};
