import React, { useState } from 'react';
import {
    Input as ReactInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input.d';
import { AlertTriangle, Check } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { color } from '../../utils/getColors';

type InputProps = {
    value: string;
    onChange: (e: any) => void;
    state?: 'error' | 'success';
    error?: string;
    label?: string;
    type: InputType;
    required?: boolean;
    disabled?: boolean;
    testId?: string;
    onBlur?: (e: any) => void;
}

export const Input = ({ value, onChange, state, error, label, type = 'text', required, disabled, testId, onBlur }: InputProps) => {
    const [id] = useState(uuid());
    return (
        <div>
            {label && <Label className="mb-1" for={id}>{label}</Label>}
            <InputGroup border="none" className={`m-0 ${state === 'error' ? 'border-danger' : ''}`}>
                <ReactInput data-testid={testId || 'error-input'}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={type}
                    id={id}
                    required={required}
                    disabled={disabled}
                />
                {state === 'error' && (
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="border-0 text-danger">
                            <AlertTriangle />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
                {state === 'success' && (
                    <InputGroupAddon data-testid="success-input" addonType="prepend">
                        <InputGroupText className="border-0">
                            <Check color={color.green[200]} />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
            </InputGroup>
            {error && <p className="mt-1 mb-0 text-danger">{error}</p>}
        </div>
    );
};
