import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { createEditorState } from '../../pages/Room/Reports/CreateReport/AddData/createEditorState';
import { GET_REPORTS } from '../../pages/Room/Reports/graphql';
import { removeAllTypename } from '../../utils/removeAllTypename';
import { setReportAction } from '../../redux/reports';

export const useGetReport = ({ id }) => {
    const dispatch = useDispatch();
    const { data, loading } = useQuery(GET_REPORTS, {
        variables: { id },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        const reports = data?.getReports || [];
        if (reports.length > 0) {
            dispatch(setReportAction({
                ...formatReport(reports[0])
            }));
        }
    }, [data?.getReports, dispatch]);

    return { loading };
};

export const formatReport = (report) => {
    if (!report) return null;
    const [removedTypename] = removeAllTypename([report]);
    return {
        ...removedTypename,
        pages: removedTypename.pages.map(page => ({
            ...page,
            ...(page.heading ? {
                heading: createEditorState({
                    ...page.heading,
                    entityMap: page.heading.entityMap || {}
                })
            } : {}),
            ...(page.subtitle ? {
                subtitle: createEditorState({
                    ...page.subtitle,
                    entityMap: page.subtitle.entityMap || {}
                })
            } : {}),
            ...(page.pageTitle ? {
                pageTitle: createEditorState({
                    ...page.pageTitle,
                    entityMap: page.pageTitle.entityMap || {}
                })
            } : {}),
            ...(page.bodyText ? {
                bodyText: createEditorState({
                    ...page.bodyText,
                    entityMap: page.bodyText.entityMap || {}
                })
            } : {})
        }))
    };
};
