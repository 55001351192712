import { useQuery } from '@apollo/client';
import isValidDomain from 'is-valid-domain';
import { GET_SUGGESTIONS } from '../../../../graphql';
import { isValidHttpUrl } from '../../../../utils/isValidUrl';

export const useSingleSuggestion = ({ keyword }) => {
    const { data } = useQuery(GET_SUGGESTIONS, {
        fetchPolicy: 'no-cache',
        variables: {
            query: keyword
        },
    });

    const suggestion = data ? data.getSuggestions?.data[0] : false;
    if (!suggestion) {
        return {};
    }

    const newObj = {};
    if (isValidHttpUrl(keyword)) {
        newObj.value = keyword.trim();
        newObj.type = 'url';
    } else if (isValidDomain(newObj.value || keyword)) {
        newObj.value = keyword.replace('www.', '').trim();
        newObj.type = 'domain';
    } else if (keyword.charAt(0) === '@') {
        newObj.value = keyword.trim();
        newObj.type = 'account';
        newObj.accountType = 'all';
    } else if (data.getSuggestions?.data[0]?.type === 'Keyword') {
        newObj.value = keyword.trim();
        newObj.type = 'phrase';
    } else if (data.getSuggestions?.data[0]?.type === 'Country') {
        newObj.value = keyword.trim();
        newObj.type = 'country';
    } else if (data.getSuggestions?.data[0]?.type === 'Person') {
        newObj.value = keyword.trim();
        newObj.type = 'person';
    } else if (data.getSuggestions?.data[0]?.type === 'Account') {
        newObj.value = keyword.trim();
        newObj.type = 'account';
        newObj.accountType = data.getSuggestions?.data[0]?.description?.toLowerCase();
        newObj.fromAccount = 'Both';
        newObj.toAccount = 'Both';
    } else if (data.getSuggestions?.data[0]?.type === 'Article') {
        newObj.value = keyword.trim();
        newObj.type = 'domain';
    }

    return {
        data: newObj
    };
};
