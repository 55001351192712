import React, { useState } from 'react';
import { WATCHLIST_BUTTON_TYPES } from '../../pages/Room/Threats/Threat/WatchlistButton';
import { WatchlistButtonContainer, WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { useGetWatchlist } from '../../services/Threat/getWatchlist';
import { getRoom } from '../../utils/variables';
import { StickToBottom } from '../StickToBottom';
import { MarkAsNotThreatButton } from './MarkAsNotThreatButton';
import { RemoveContentButtons } from './RemoveContentButtons';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { ContentTableTypes } from './index';
import { Content } from '../../services/Content/contentInterface';

type ContentTableActionsBarProps = {
    selectedContent: Pick<Content, WatchlistButtonContentKeys>[]
    clearSelection: () => void
    total: number
    onSelectAll: () => void
    contentType: string
    tableType: string
    contentsToDisplay: number
}

export const ContentTableActionsBar = ({
    selectedContent,
    clearSelection,
    total = 0,
    onSelectAll,
    contentType,
    tableType,
    contentsToDisplay
}: ContentTableActionsBarProps) => {
    const room = getRoom();
    const { watchlist, refetchWatchlist } = useGetWatchlist({ situationRoomId: room.id });
    const [allSelected, setAllSelected] = useState(false);
    const onWatchlistActionCompleted = () => {
        refetchWatchlist();
        clearSelection();
    };
    const optionsListToAdd = selectedContent.filter(
        contentItem => !watchlist.some(({ id }: {id: string}) => id === contentItem.id)
    );
    const optionsListToRemove = selectedContent.filter(
        contentItem => watchlist.some(({ id }: {id: string}) => id === contentItem.id)
    );
    const handleClearSelection = () => {
        setAllSelected(false);
        clearSelection();
    };
    const handleToggleSelectAll = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();

        setAllSelected(!allSelected);

        if (!allSelected && onSelectAll) onSelectAll();
    };

    const selectAllButtonText = (allSelected) ? `Select ${selectedContent.length}` : 'Select all';
    const selectedCount = allSelected ? total : selectedContent.length;

    if (selectedCount === 0) return null;

    const isThreatsOrMatchesType = contentType === CONTENT_TYPE.THREAT || contentType === CONTENT_TYPE.MATCH;
    const isThreatsOrMatchesTable = tableType === ContentTableTypes.THREATS || tableType === ContentTableTypes.MATCHES;
    const allowSelectAll = isThreatsOrMatchesType
        && isThreatsOrMatchesTable
        && contentsToDisplay
        && total
        && Number(total || 0) > Number(contentsToDisplay || 0);

    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    return (
        <StickToBottom>
            <div className="bg-hover p-1 d-flex align-items-center justify-content-between" data-testid="actions-bar">
                <p className="m-0 font-weight-bold">
                    {selectedCount} item
                    {selectedCount !== 1 ? 's' : ''} selected
                    {(allowSelectAll) && (
                        <span onClick={handleToggleSelectAll} className="text-primary font-weight-normal cursor-pointer ml-2">
                            <span className="pl-1">{selectAllButtonText}</span>
                        </span>
                    )}
                </p>
                <div className="d-flex align-items-end">
                    {(optionsListToAdd.length > 0) && (
                        <WatchlistButtonContainer contents={optionsListToAdd}
                            onCompleted={onWatchlistActionCompleted}
                            onError={clearSelection}
                            type={WATCHLIST_BUTTON_TYPES.BUTTON}
                            disabled={allSelected}
                        />
                    )}
                    {(optionsListToRemove.length > 0) && (
                        <WatchlistButtonContainer contents={optionsListToRemove}
                            isWatching
                            onCompleted={onWatchlistActionCompleted}
                            onError={clearSelection}
                            type={WATCHLIST_BUTTON_TYPES.BUTTON}
                            disabled={allSelected}
                        />
                    )}
                    {contentActionsEnabled && (
                        <>
                            <MarkAsNotThreatButton selectedContent={selectedContent}
                                onCompleted={handleClearSelection}
                                disabled={allSelected}
                            />
                            {(contentType) && (
                                <RemoveContentButtons allSelected={allSelected}
                                    selectedContent={selectedContent}
                                    total={total}
                                    contentType={contentType}
                                    onCompleted={handleClearSelection}
                                    onAbort={handleClearSelection}
                                    onAction={handleClearSelection}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </StickToBottom>
    );
};
