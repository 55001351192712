import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { AddOverviewWidgetsModal } from './AddOverviewWidgetsModal';
import { DeleteOverviewBlockButton } from './DeleteOverviewBlockButton';
import { LocationMentions } from './LocationMentions';
import { Sentiment } from './Sentiment';
import { Sources } from './Sources';
import { TopLanguages } from './TopLanguages';
import { TopPublishers } from './TopPublishers';
import { TopSharedUrls } from './TopSharedUrls';
import { TotalThreatsAndMatches } from './TotalThreatsAndMatches';
import { WordcloudGenerator } from './WordcloudGenerator';

export const OverviewPage = ({ data }) => {
    const { setModal } = useModal();
    if (data.blocks.length === 0) {
        return (
            <div className="w-100 flex-1 d-flex align-items-center justify-content-center">
                <Button color="link"
                    className="text-decoration-none"
                    onClick={() => setModal({
                        header: false,
                        size: 'full',
                        component: <AddOverviewWidgetsModal />
                    })}
                >
                    + Add overview widgets
                </Button>
            </div>
        );
    }
    return (
        <Row className="align-items-stretch">
            {data.blocks.map((widget) => (
                <Col key={widget.type} xs={12} md={4}>
                    <div className="pb-3 h-100 position-relative">
                        <div className="position-absolute report-overview-edit-position">
                            <DeleteOverviewBlockButton block={widget.type} />
                        </div>
                        <div className="border h-100 font-size-10">
                            <div className="bg-light p-1 border-bottom font-weight-bold">
                                {widget.title === 'Top publishers' ? 'Top actors' : widget.title}
                            </div>
                            {widget.type === 'locationMentions' && <LocationMentions small />}
                            {widget.type === 'topPublishers' && <TopPublishers small />}
                            {widget.type === 'sources' && <Sources small />}
                            {widget.type === 'topLanguages' && <TopLanguages small />}
                            {widget.type === 'topSharedUrls' && <TopSharedUrls small className="topSharedUrls" />}
                            {widget.type === 'totalThreatsAndMatches' && <TotalThreatsAndMatches small />}
                            {widget.type === 'sentiment' && <Sentiment small />}
                            {widget.type === 'wordcloudGenerator' && <WordcloudGenerator small />}
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};
