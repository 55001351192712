import React from 'react';
import { Progress } from 'reactstrap';
import { color } from '../../utils/getColors';

type ProgressBarProps = {
    max: number,
    current: number
}

export const ProgressBar = ({ max, current }: ProgressBarProps) => {
    const calcString = Number((current / max) * 100).toFixed(2);
    const percentage = Math.floor(Number(calcString));

    if (max <= 0) return null;

    return <Progress data-testid="progress-bar" striped color={color.blue[500]} value={percentage} />;
};
