import { gql, useQuery } from '@apollo/client';
import { getRoom } from '../../../../../../utils/variables';
import { NetworksHistoryState } from '../../../store';

type NetworkFields = 'deletedNodes' | 'layout' | 'includeAuthorsAndMentions' | 'includeHashtags' |
'includeSharedURLs' | 'minimumNodeConnections' | 'sizeNodesBy' | 'clusterBy';
export interface SavedNetwork extends Pick<NetworksHistoryState, NetworkFields> {
    id: string
    createdBy: {
        displayName: string
    }
    createdAt: Date
    name: string
    chartState: {
        why: string
        positions: { id: string, x: number, y: number }[]
    },
    filters: JSON,
    dslQuery: JSON
}

type UseSavedNetworksResponse = {
    savedNetworks: SavedNetwork[]
    loading: boolean
    total: number
}

export type UseSavedNetworksProps = {
    search?: string
    sort?: {
        order: 'asc' | 'desc'
        field: 'name' | 'createdAt'
    }
    skip?: number
}

export const useSavedNetworks = ({ sort, search = '', skip }: UseSavedNetworksProps): UseSavedNetworksResponse => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_SAVED_NETWORKS, {
        variables: {
            situationRoom: room.id,
            search,
            sort,
            skip
        },
        fetchPolicy: 'no-cache'
    });

    const savedNetworks = data?.getNetworks?.networks || [];
    const total = data?.getNetworks?.total || 0;

    return {
        savedNetworks,
        total,
        loading
    };
};

export const GET_SAVED_NETWORKS = gql`
    query getNetworks(
        $situationRoom: ID!
        $search: String
        $sort: NetworksSortInput
        $skip: Int
    ) {
        getNetworks(
            situationRoom: $situationRoom
            search: $search
            sort: $sort
            skip: $skip
        ) {
            networks {
                id
                createdBy {
                    displayName
                }
                createdAt
                name
                deletedNodes
                chartState {
                    why
                    positions {
                        id
                        x
                        y
                    }
                }
                layout
                includeAuthorsAndMentions
                includeHashtags
                includeSharedURLs
                minimumNodeConnections
                sizeNodesBy
                clusterBy
                filters
            }
            total
        }
    }
`;
