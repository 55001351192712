import React from 'react';
import { ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useSaveReport } from '../../../../../services/Report/saveReport';
import { CREATE_REPORT_TABS, setActiveTabAction } from '../../../../../redux/reports';

export const Tabs = () => {
    const dispatch = useDispatch();
    const { report, activeTab } = useSelector(state => state.createReport);
    const saveDisabled = !report?.name || !report?.template;
    const { saveReport } = useSaveReport();

    const handleAddDataTabClick = () => {
        dispatch(setActiveTabAction(CREATE_REPORT_TABS.VISUALISE));
    };
    const handleExportTabClick = async () => {
        if (saveDisabled) return;
        dispatch(setActiveTabAction(CREATE_REPORT_TABS.EXPORT));
        await saveReport({ ...report, draft: false });
    };
    const markSelectedTab = (current) => (activeTab === current ? 'selected-tab' : '');

    return (
        <div className="multi-tabs">
            <div className={`mr-4 ${markSelectedTab(CREATE_REPORT_TABS.VISUALISE)}`} onClick={handleAddDataTabClick}>
                <h4 data-testid="visualise-tab-item-h4">1. Add data & visualise</h4>
            </div>
            <ChevronRight className="mr-4 text-dark mb-1" size={22} />
            <div className={`mr-4 ${markSelectedTab(CREATE_REPORT_TABS.EXPORT)} ${saveDisabled ? 'disabled' : ''}`}
                onClick={handleExportTabClick}
            >
                <h4 data-testid="export-tab-item-h4">2. Save & export</h4>
            </div>
        </div>
    );
};
