import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const ExitDetailsView = () => {
    const { setModal, closeModal } = useModal();
    const history = useHistory();
    const onCancelClick = () => {
        setModal({
            component: (
                <div className="d-flex flex-column">
                    <h3>Do you want to exit the investigation screen without saving?</h3>
                    <hr className="w-100" />
                    <p>If you press exit, your updates made in the investigation won&#39;t be saved.</p>
                    <hr className="w-100" />
                    <div className="d-flex">
                        <Button color="secondary"
                            className="mr-2"
                            onClick={() => closeModal()}
                        >Cancel
                        </Button>
                        <Button color="secondary"
                            className="mr-4"
                            onClick={() => { history.goBack(); closeModal(); }}
                        >Exit
                        </Button>
                    </div>

                </div>
            )
        });
    };

    return (
        <Button color="secondary" className="ml-3" onClick={() => onCancelClick()}>Cancel</Button>
    );
};
