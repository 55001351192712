import { isEqual } from 'lodash';
import React, { useState, useMemo, useContext, createContext } from 'react';

const RoomContext = createContext({});

export const useRoom = () => useContext(RoomContext);

export const RoomContextProvider = ({ children, defaultRoom }) => {
    const [roomBackup, setRoomBackup] = useState(defaultRoom);
    const [room, setRoom] = useState(defaultRoom);
    const context = useMemo(() => ({
        room,
        setRoom,
        changesMade: !isEqual(room, roomBackup),
        saveRoom: (newRoom) => setRoomBackup(newRoom || room),
        roomBackup
    }), [room, roomBackup]);

    return (
        <RoomContext.Provider value={context}>
            { children }
        </RoomContext.Provider>
    );
};
