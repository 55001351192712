import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { useDeleteNetwork } from './useDeleteNetwork';
import { Loading } from '../../../../../../components/Loading';
import { getToast } from '../../../../../../utils/getToast';

export const DeleteSavedNetworkGraph = ({ id }: { id: string }) => {
    const { openPreviousModal } = useModal();
    const { deleteNetwork, loading } = useDeleteNetwork();
    const toast = getToast();
    const handleDeleteNetwork = () => {
        deleteNetwork({
            id,
            onCompleted: () => {
                openPreviousModal();
                toast.success('Saved network graph deleted');
            }
        });
    };
    if (loading) {
        return <Loading relative height={120} />;
    }
    return (
        <div>
            <p>Are you sure to delete the saved network graph. This action will permanently delete the saved item.</p>
            <hr />
            <Button onClick={() => openPreviousModal()} className="mr-2">Cancel</Button>
            <Button color="danger" onClick={handleDeleteNetwork}>Delete</Button>
        </div>
    );
};
