import React, { useState } from 'react';
import FlipMove from 'react-flip-move';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';
import { MatchesHeader } from './header';
import { useContent } from '../../../../services/Content/getContent';
import { getRoom } from '../../../../utils/variables';
import { EmptyState } from '../../../../components/EmptyState';
import { NoSearchResults } from '../../../../assets/svg/noSearchResults';
import { ActivityItems } from '../../../../components/ActivityItem/ActivityItems';
import { ActivityItem } from '../../../../components/ActivityItem/ActivityItem';

export const Matches = () => {
    const room = getRoom();
    const [sort, setSort] = useState({ value: 'date', name: 'Most recent' });
    const newContentTableView = !!room.instance?.plan?.others?.newContentTableView;
    const { loading, data: matches, count } = useContent({
        limit: 25,
        sort: sort.value,
        name: 'getMatches',
        useGlobalFilters: true
    });
    return (
        <div className="h-100 d-flex flex-column">
            <MatchesHeader count={count} sort={sort.name} setSort={setSort} />
            {(matches.length === 0 && loading) && <Loading relative />}
            {count === 0 && !loading ? (
                <EmptyState title="We couldn’t find any matches"
                    summary="Please try another keyword"
                    svgIcon={<NoSearchResults />}
                />
            )
                : (
                    <div className="h-100 overflow-y-auto scrollbar-small mx-n3 mt-n4 py-3 pr-1 pl-3">
                        <FlipMove>
                            {matches.map((contentItem) => (
                                <div key={contentItem.id}>
                                    {newContentTableView
                                        ? <ActivityItems content={contentItem} preview={false} />
                                        : <ActivityItem content={contentItem} />}
                                </div>
                            ))}
                        </FlipMove>
                        {count > 25 && (
                            <Link to={`/situation-rooms/${room.id}/overview/matches`}>
                                See more matches
                            </Link>
                        )}
                    </div>
                )}
        </div>
    );
};
