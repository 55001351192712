import React from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import { InvestigationsDetail } from './InvestigationsDetail';
import { useModal } from '../../../context/modal/ModalComponent';

export const InvestigationItem = ({ data, onSetPageParam }) => {
    const { closeModal, setModal } = useModal();
    const { status, summary, details, email, updatedAt, uploadAttachments, report, id } = data;
    const setInvestigationItemModal = () => {
        setModal({
            size: 'lg',
            component: <InvestigationsDetail status={status}
                summary={summary}
                details={details}
                email={email}
                updatedAt={updatedAt}
                uploadAttachments={uploadAttachments}
                report={report}
                onClose={() => {
                    onSetPageParam('all');
                    closeModal();
                }}
            />
        });
    };

    if (!data || Object.keys(data).length === 0) return null;

    return (
        <tr onClick={() => { onSetPageParam(id); setInvestigationItemModal(); }}>
            <td><Button className="text-left" color="link">{summary}</Button></td>
            <td><p>{email}</p></td>
            <td><p>{`${moment(updatedAt).format('DD/MM/YYYY, @ hh:mm:ss')}`}</p></td>
            <td className="p-3 pl-1">
                <div className={`d-flex align-items-center status-${status === 'Completed' ? 'Active' : 'InProgress'}`}>
                    <span className="pl-2 text-nowrap">{status}</span>
                </div>
            </td>
            <td>
                <p>
                    {report ? (uploadAttachments?.length || 0) + 1 : (uploadAttachments?.length || 0)}
                </p>
            </td>
        </tr>
    );
};
