import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { truncateString } from '../../utils/text';
import { GET_MULTIPLE_TRANSLATION } from '../../graphql/Queries';

export const useTranslateMultipleText = ({ text = '', extracts = [] }) => {
    const [showTranslated, setShowTranslated] = useState(false);
    const [getTranslationApi, { data, loading }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: extracts?.length ? extracts.map((item, index) => ({
                text: item,
                id: index.toString(),
                target: 'en'
            })) : {
                text: truncateString(text, 1000),
                target: 'en',
                id: '1'
            },
        },
    });
    const getTranslation = () => {
        if (loading) { return false; }
        if (!data) {
            getTranslationApi();
            setShowTranslated(true);
        } else {
            setShowTranslated(!showTranslated);
        }
    };
    const translated = data?.translateMultipleText || [];
    const defaultData = extracts?.length ? extracts.map(extract => ({
        translations: [extract]
    })) : [{
        translations: [text]
    }];
    return {
        getTranslation,
        data: (showTranslated && translated?.length) ? translated : defaultData,
        loading
    };
};
