import React, { createRef, forwardRef, useImperativeHandle } from 'react';
import { saveSvgAsPng } from 'save-svg-as-png';
import { WordCloud as WordCloudHC } from '../../../../../components/Highcharts';
import { entityTypes } from '../utils';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { useWordcloud } from '../../../../../services/Overview';
import { color } from '../../../../../utils/getColors';

export const WordCloud = forwardRef(({ field, onWordClick, size }, ref) => {
    const { keywords, loading, error } = useWordcloud({ field, size });
    const wordcloudRef = createRef();
    useImperativeHandle(ref, () => ({
        exportWordcloud: async () => {
            if (loading || !keywords.length) return;
            const svgElement = wordcloudRef.current.querySelector('svg');
            saveSvgAsPng(svgElement, 'wordcloud.png');
        }
    }));
    if (loading) {
        return (
            <div className="loading-container pt-3">
                <div className="loading-square-keyword-mentions loading-bg w-100">
                    <p>Loading</p>
                </div>
            </div>
        );
    }
    if (!keywords.length) {
        return (
            <EmptyState title="No data available" icon={Images().overview} />
        );
    }
    if (error) return `Error! ${error}`;

    if (!keywords.length) { return null; }

    const data = keywords.map(item => ({
        name: trimString(item.keyword, 40),
        weight: item.count,
        original: item.key,
        color: entityTypes.find(entity => entity.key === item.type)?.color || color.blue[500],
        ...item
    }));
    return (
        <div style={{ height: `calc(100% - ${field === 'entities' ? 140 : 100}px)` }}>
            <span ref={wordcloudRef}>
                <WordCloudHC data={data}
                    callBack={onWordClick}
                    minFontSize={5}
                />
            </span>
            {field === 'entities' && (
                <div className="d-flex align-items-center flex-wrap mt-2">
                    {entityTypes.map(type => (
                        <div key={type.key} className="d-flex align-items-center mr-2">
                            <div className="p-1 rounded-sm mr-11" style={{ backgroundColor: type.color }} />
                            <span className="font-weight-semibold">{type.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

const trimString = (string, length) => (string.length > length ? `${string.substring(0, length)}...` : string);
