import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import _ from 'lodash';
import { ThumbsDown, ThumbsUp } from 'react-feather';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ManageThreatsModal } from '../../../../../components/Organisms/ContentTableView/ThreatSignals/ManageThreatsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { LikelihoodContextModal } from '../../../../../components/Organisms/ContentTableView/ThreatSignals/LikelihoodContextModal';
import { getRoom, getUser } from '../../../../../utils/variables';
import { allTypes } from '../../../../../utils/lib/search';
import { ThreatSignalTooltip } from '../../../../../components/Organisms/ContentTableView/ThreatSignals/threatSignalTooltip';
import noDataAvailable from '../../../../../assets/images/NoDataAvailable.png';
import { Content } from '../../../../../services/Content/contentInterface';
import { ThreatSignal } from '../../../../../components/Organisms/ContentTableView/ContentTableContext';
import { ContentTableContentType } from '../../../../../components/Organisms/ContentTableView';
import { useGetActiveQueries } from '../../../../../services/getActiveQueries';
import { ResetThreatsModal } from './ResetThreatsModal';
import { TooltipItem } from '../../../../../components/ToolTip';

type ThreatSignalsProps = {
    content: Content,
    isMatch: boolean
}
export const ThreatSignals = ({ content, isMatch }: ThreatSignalsProps) => {
    const { setModal, closeModal } = useModal();
    const room = getRoom();
    const user = getUser();
    const [like, setLike] = useState(false);
    const [unlike, setUnlike] = useState(false);
    const [dispplayManageThreats, setDisplayManageThreats] = useState(false);
    const [updateInterest] = useMutation(UPDATE_USER_INTEREST, {
        refetchQueries: useGetActiveQueries(['getThreatSummary'])
    });
    const { data } = useQuery(GET_ORIGINAL_CONTENT_TYPE, {
        variables: {
            contentId: content?.id
        }
    });
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    let finalDisplayList = [] as ThreatSignal[];
    if (!isMatch) {
        const { threatResolvers, removedThreatLabels, customThreatLabels } = content.threatObject;
        const displayList = [] as ThreatSignal[];
        allTypes.forEach(type => {
            if (!disabledThreatTypes.includes(type.key)) {
                const key = type.isResolver ? 'resolver' : 'type';
                threatResolvers?.forEach((c: ContentTableContentType['threatObject']['threatResolvers']) => {
                    if ((c[key] === type.key) && (removedThreatLabels ? !removedThreatLabels.includes(type.key) : true)) {
                        const item = { signalName: '', confidence: 0, isResolver: false };
                        item.signalName = type.name;
                        item.confidence = c.confidence;
                        item.isResolver = type.isResolver || false;
                        displayList.push(item);
                    }
                });
                if (customThreatLabels && (customThreatLabels.map(a => a.signalKey)).includes(type.key)) {
                    const item = { signalName: '', confidence: 0, isResolver: false, userName: '' };
                    item.signalName = type.name;
                    item.confidence = 0;
                    item.isResolver = type.isResolver || false;
                    item.userName = customThreatLabels.filter((a) => a.signalKey === type.key)[0].userName || 'Not available';
                    displayList.push(item);
                }
            }
        });
        finalDisplayList = _.uniqBy(displayList, 'signalName');
    }

    const sortSignals = (list: ThreatSignal[]) => {
        if (list.length > 1) {
            return list.sort((a, b) => b.confidence - a.confidence);
        }
        return list;
    };
    const handleManage = () => {
        setDisplayManageThreats(true);
    };
    const handleOnClick = () => {
        setModal({
            header: 'How do we calculate likelihood?',
            component: <LikelihoodContextModal />
        });
    };
    const renderSignal = (signal: ThreatSignal) => (
        <div className="py-2 px-2 text-center h-100 d-flex align-items-center justify-content-start flex-1">
            <span className="d-flex flex-column">
                <div className="d-flex">
                    {signal.isResolver && (
                        <>
                            <ThreatSignalTooltip widgetName="Toxic information -" classes="no-white-space" />&nbsp;
                        </>
                    )}
                    <ThreatSignalTooltip widgetName={signal.signalName} classes="no-white-space" />
                </div>
                {(signal.confidence > 0) ? (
                    <p
                        className={`${signal.confidence > 50 ? 'high-threat-tag' : 'low-threat-tag'} py-11 px-12 set-fit-content`}
                    >
                        {signal.confidence > 50 ? 'Highly likely' : 'Likely'}
                    </p>
                ) : <p className="no-threat-tag py-11 px-12 set-fit-content">Assigned by {signal.userName}</p>}
            </span>
        </div>
    );
    if (content?.threatObject?.likedUsers?.includes(user.displayName) && !like) {
        setLike(true);
    }
    if (content?.threatObject?.unlikedUsers?.includes(user.displayName) && !unlike) {
        setUnlike(true);
    }
    const handleUnlike = () => {
        if (like) {
            setLike(false);
            setUnlike(true);
        } else {
            setUnlike(!unlike);
        }
        let finalLikedUsers = content?.threatObject?.likedUsers || [];
        let finalUnlikedUsers = content?.threatObject?.unlikedUsers || [];
        if (!unlike) {
            if (finalLikedUsers.includes(user.id)) {
                finalLikedUsers = finalLikedUsers.filter((a) => a !== user.id);
            }
            finalUnlikedUsers = [...finalUnlikedUsers, user.id];
        } else if (finalUnlikedUsers.includes(user.id)) {
            finalUnlikedUsers = finalUnlikedUsers.filter((a) => a !== user.id);
        }

        updateInterest({
            variables: {
                projectId: room?.project_id,
                contentId: content.id,
                likedUsers: finalLikedUsers,
                unlikedUsers: finalUnlikedUsers
            }
        });
    };
    const handleLike = () => {
        if (unlike) {
            setUnlike(false);
            setLike(true);
        } else {
            setLike(!like);
        }
        let finalLikedUsers = content?.threatObject?.likedUsers || [];
        let finalUnlikedUsers = content?.threatObject?.unlikedUsers || [];
        if (!like) {
            if (finalUnlikedUsers.includes(user.id)) {
                finalUnlikedUsers = finalUnlikedUsers.filter((a) => a !== user.id);
            }
            finalLikedUsers = [...finalLikedUsers, user.id];
        } else if (finalLikedUsers.includes(user.id)) {
            finalLikedUsers = finalLikedUsers.filter((a) => a !== user.id);
        }
        updateInterest({
            variables: {
                projectId: room?.project_id,
                contentId: content.id,
                likedUsers: finalLikedUsers,
                unlikedUsers: finalUnlikedUsers
            }
        });
    };
    const handleReset = () => {
        setModal({
            header: 'Reset threats?',
            component: <ResetThreatsModal contentIds={[content.id]} projectId={room?.project_id} clearSelection={closeModal} />
        });
    };
    useEffect(() => {
        if (!like && !unlike) {
            const finalLikedUsers = content?.threatObject?.likedUsers || [];
            const finalUnlikedUsers = content?.threatObject?.unlikedUsers || [];

            if (finalLikedUsers.includes(user.id)) {
                setLike(true);
            }

            if (finalUnlikedUsers.includes(user.id)) {
                setUnlike(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content?.threatObject?.likedUsers, content?.threatObject?.unlikedUsers, user.id]);
    const threatLevel = data?.getOriginalContentType?.threatObject?.threatLevel;
    const isOrginalThreat = (threatLevel && threatLevel !== 'none') || false;

    return (
        <div className="bg-white rounded-sm my-3 p-2">
            {!dispplayManageThreats ? (
                <>
                    <div className="d-flex justify-content-between mr-2">
                        <div className="d-flex align-items-center">
                            <h3>Threats</h3>
                            <TooltipItem id="metricsContent"
                                placement="top"
                                content={`Threats are AI generated classifiers we place on potentially harmful content which may originate
                                     from a suspicious source. These can be sources that are known to publish biased, non-factual 
                                     information & display traits of inauthentic behaviour.`}
                            />
                        </div>
                        <Button className="p-0 mw-0" color="link" data-testid="manage-threats" onClick={handleManage}>Manage</Button>
                    </div>
                    {(isMatch && !dispplayManageThreats && isOrginalThreat)
                    && (
                        <div className="d-flex text-center justify-content-center my-4">
                            <div className="maxw-500">
                                <img src={noDataAvailable} className="mb-2" />
                                <h2>Threats removed</h2>
                                <p>
                                    Our system detected several threats on this content, which were manually removed and marked this
                                    content as ‘non-threatening’. You cannot request an investigation, request a fact check or add to
                                    watchlist on ‘non-threatening’ content. You can reassign threats manually in the manage panel, or
                                    <span className="text-primary font-weight-normal cursor-pointer" onClick={handleReset}> reset
                                    </span> the content back to its original state.
                                </p>
                            </div>
                        </div>
                    )}
                    {(isMatch && !dispplayManageThreats && !isOrginalThreat) && (
                        <div className="d-flex text-center justify-content-center my-4">
                            <div className="maxw-500">
                                <img src={noDataAvailable} className="mb-2" />
                                <h2>No threats detetcted</h2>
                                <p>
                                    Our system hasn’t detected any threats in this content & is marked as ‘non-threatening’.
                                    You cannot request an investigation, request a fact check or add to watchlist on
                                    ‘non-threatening’ content. You can manually assign threats to content under the
                                    <span className="text-primary font-weight-normal cursor-pointer"
                                        onClick={() => setDisplayManageThreats(true)}
                                    > manage
                                    </span> panel.
                                </p>
                            </div>
                        </div>
                    )}
                    {(!dispplayManageThreats && !isMatch) && (
                        <>
                            <Button color="link" className="p-0 mw-0 d-block mt-2" onClick={handleOnClick}>
                                How do we calculate likelihood scores?
                            </Button>
                            <div className="manage-signals my-3">
                                {sortSignals(finalDisplayList).map((signal) => (
                                    <div key={signal.signalName} className="signal-item mr-2 p-0">
                                        {renderSignal(signal)}
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex">
                                <p className="mr-2">Are these threats accurate?</p>
                                <ThumbsUp className="mr-1 cursor-pointer"
                                    color="#006FF9"
                                    fill={like ? '#006FF9' : 'none'}
                                    onClick={() => handleLike()}
                                />
                                <ThumbsDown color="#006FF9"
                                    className="cursor-pointer"
                                    fill={unlike ? '#006FF9' : 'none'}
                                    onClick={() => handleUnlike()}
                                />
                            </div>
                        </>
                    )}

                </>
            ) : (
                <ManageThreatsModal selectedContents={[content]}
                    handleClose={() => setDisplayManageThreats(false)}
                    summary={() => setDisplayManageThreats(false)}
                    clearSelection={() => setDisplayManageThreats(false)}
                    isMatch={isMatch}
                    singleContent
                />
            )}
        </div>
    ); };

export const UPDATE_USER_INTEREST = gql`
        mutation updateUserInterest ($projectId: String!, $contentId: String!, $likedUsers: [String], $unlikedUsers: [String]) {
            updateUserInterest(projectId: $projectId, contentId: $contentId, likedUsers: $likedUsers, unlikedUsers: $unlikedUsers)
        }
    `;

export const GET_ORIGINAL_CONTENT_TYPE = gql`
        query getOriginalContentType ($contentId: String!) {
            getOriginalContentType (contentId: $contentId) {
                    isThreat
                    threatLevel
                    threatObject {
                        threatLevel
                        threatLabels
                        threatResolvers {
                            confidence
                            resolver
                            type
                        }
                    }
                    whyathreat
            }
        }
`;
