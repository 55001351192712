import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    filters,
    watchlistFilters,
    customNarratives,
    roomPreviewKeywords,
    removeContentFilter,
    selectedCluster,
    activeTab,
    selectedFilters,
    clusterFilters,
    activeAiClusteringTableView,
    narrativeComparison,
    narrativeThemeComparison
} from './pages/Room/store';
import { modals } from './context/modal/store';
import { overview } from './pages/Room/Overview/store';
import { createReport } from './redux/reports';
import { networks, networksHistory } from './pages/Room/Networks/store';
import { aiNarratives } from './pages/Room/AIClustering/store';

export const reducer = combineReducers({
    filters,
    watchlistFilters,
    modals,
    overview,
    customNarratives,
    createReport,
    roomPreviewKeywords,
    removeContentFilter,
    networks,
    networksHistory,
    selectedCluster,
    activeTab,
    selectedFilters,
    clusterFilters,
    activeAiClusteringTableView,
    narrativeComparison,
    narrativeThemeComparison,
    aiNarratives
});

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
