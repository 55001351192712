import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { Input } from '../../../../components/Form/Input';
import { Loading } from '../../../../components/Loading';
import { useModal } from '../../../../context/modal/ModalComponent';
import { GET_ALL_SITUATION_ROOMS, UPDATE_SITUATION_ROOM } from '../../../../graphql';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../../utils/SuccessErrorModal';

export const EditRoomNameModal = ({ title: oldTitle, id, onChange }) => {
    const { closeModal } = useModal();
    const [title, setTitle] = useState(oldTitle);
    const [updateRoom, { loading, data, error }] = useMutation(UPDATE_SITUATION_ROOM, {
        refetchQueries: [GET_ALL_SITUATION_ROOMS]
    });
    const handleRoomNameChange = () => {
        updateRoom({ variables: { id, project_name: title } }).then(() => {
            onChange(title);
        }).catch(() => {}); // error state is being handled via useMutation but this still needs a catch
    };
    if (loading) {
        return <Loading height={200} relative />;
    }
    if (data?.updateSituationRoom) {
        return (
            <StatusModalBodyNew messageType={STATUS_TYPES.success}
                message="Situation room name changed successfully"
            />
        );
    }
    return (
        <div>
            <h2 className="m-0">Edit situation room title</h2>
            <hr />
            <h3 className="m-0">{oldTitle}</h3>
            <hr />
            <Input value={title} onChange={(e) => setTitle(e.target.value)} required />
            <hr />
            <Button onClick={closeModal} className="mr-2">Cancel</Button>
            <Button color="primary" onClick={handleRoomNameChange}>
                Save
            </Button>
            <Alert isOpen={!!error} color="danger" className="mt-2 mb-0">
                Something went wrong, please try a different title or try again later.
            </Alert>
        </div>
    );
};
