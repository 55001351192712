import React from 'react';
import { Button } from 'reactstrap';
import { useInstance } from './InstanceContext';

export const InstanceFormFooter = () => {
    const { changesMade, resetInstance } = useInstance();
    if (!changesMade) { return null; }
    return (
        <div className="border-top d-flex align-items-center justify-content-end p-3 bg-white">
            <Button onClick={resetInstance}>Cancel</Button>
            <Button color="primary" className="ml-3" type="submit">Save</Button>
        </div>
    );
};
