import React, { useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import SplitPane from 'react-split-pane';

export const SplitViewNarratives = ({ left, right, expand }) => {
    const [size, setSize] = useState({ value: '50%' });

    const checkSize = () => {
        const sizeValue = parseInt((size.value).split('.')[0], 10);
        return sizeValue >= 80;
    };

    const onSizeChange = (e) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setSize({ value: e[1] }), 500);
    };
    const timerRef = useRef(null);
    return (
        <SplitPane split="vertical"
            primary="first"
            className="split-view bg-white"
            onChange={(e) => onSizeChange(e)}
        >
            <div className="p-4 h-100 left-narrative-container">
                {left}
            </div>

            <div className="h-100 right-container position-relative right-panel-border px-4 pt-4 d-flex flex-column"
                size={size.value}
            >
                {!expand && (
                    <div className="position-relative top-left-new p-1">
                        <div className="py-0 px-11 text-primary mw-0 hover-white square-icon-button mr-auto "
                            onClick={(e) => { setSize({ value: parseInt((size.value).split('.')[0], 10) >= 80 ? '50%' : '100%' });
                                e.currentTarget.blur(); }}
                            id="Tooltip-collapse-arrow"
                        >
                            {checkSize()
                                ? (
                                    <p className="d-flex text-primary cursor-pointer ml-1">
                                        Collapse
                                        <span className="ml-1">
                                            <ChevronRight size={20} />
                                        </span>
                                    </p>
                                )
                                : (
                                    <p className="d-flex text-primary cursor-pointer">
                                        <span className="mr-1">
                                            <ChevronLeft size={20} />
                                        </span>
                                        Expand
                                    </p>
                                )}
                        </div>
                    </div>
                )}
                {right}
            </div>

        </SplitPane>
    );
};
