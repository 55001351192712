import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CHECK_USER_EXISTS, INVITE_USER } from '../../graphql';
import { AddUserForm } from './AddUserForm';
import { Header } from './Header';
import { AddedEmails } from './AddedEmails';
import { Buttons } from './Buttons';
import { SelectInstance } from './SelectInstance';

export const InviteUser = ({ membersCanBeAdded, instance: defaultInstance, toggle, isSuperAdmin }) => {
    const [instance, setInstance] = useState(defaultInstance);
    const [email, setEmail] = useState('');
    const [emailLoading, setEmailLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [emails, setEmails] = useState([]);
    const [checkUserExists] = useLazyQuery(CHECK_USER_EXISTS, {
        onCompleted: (data) => {
            if (data.checkUserExists.done) {
                setError(data.checkUserExists.message);
            } else {
                setEmails([...emails, { email, id: uuid() }]);
                setEmail('');
                setError(false);
            }
            setEmailLoading(false);
        },
    });
    const [inviteUser] = useMutation(INVITE_USER, {
        onCompleted: (data) => {
            if (data.inviteUser.done) {
                setSuccess(true);
            } else {
                setError(data.inviteUser.message);
            }
        },
    });
    const handleRemove = (em) => {
        setEmails(emails.filter((item) => item !== em));
    };
    const handleAddUser = (e) => {
        e.preventDefault();
        setEmailLoading(true);
        let shouldAdd = true;

        for (let i = 0; i < emails.length; i++) {
            if (emails[i] === email) {
                shouldAdd = false;
            }
        }
        if (shouldAdd) {
            checkUserExists({ variables: { email } });
            if (error) {
                setError('');
            }
        }
    };
    const handleInvite = () => {
        if (!instance && isSuperAdmin) {
            setError(
                'You have to select an instance'
            );
        } else if (!emails.length) {
            setError(
                'You have to add at least one email and press "Add" in order to send an invite'
            );
        } else if (emails.length > membersCanBeAdded) {
            setError(
                'If you need to add more members to your team, please speak to your account owner'
            );
        } else {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 1);
            for (let i = 0; i < emails.length; i++) {
                const invite = {
                    email: emails[i].email,
                    role: emails[i].role,
                    expiryDate: expiryDate.getTime(),
                    instance,
                };
                addInvite(invite);
            }
        }
    };
    const addInvite = (invite) => {
        inviteUser({
            variables: {
                data: invite,
            },
        });
    };
    const resetValues = () => {
        setEmails([]);
        setEmail('');
        setError(false);
        setSuccess(false);
        toggle();
    };

    return (
        <div data-testid="invite-user-component">
            <Header success={success} />
            <hr className="my-2" />
            {!success ? (
                <>
                    {isSuperAdmin && <SelectInstance instance={instance} setInstance={setInstance} />}
                    <AddUserForm handleAddUser={handleAddUser} email={email} setEmail={setEmail} emailLoading={emailLoading} />
                    <AddedEmails emails={emails} handleRemove={handleRemove} setEmails={setEmails} />
                    <p className="text-danger my-2" data-testid="errormessage">{error}</p>
                </>
            ) : <p>You have invited {emails.length} member{emails.length !== 1 ? 's' : ''} to your team space.</p>}
            <hr className="my-2" />
            <Buttons success={success} resetValues={resetValues} emails={emails} handleInvite={handleInvite} />
        </div>
    );
};
