import { useQuery, gql } from '@apollo/client';

export const useSocialAccounts = ({ username, skip }) => {
    const { loading, data } = useQuery(GET_SOCIAL_ACCOUNTS, {
        variables: {
            username
        },
        skip
    });
    const ret = data?.getSocialAccounts || [];
    return {
        loading,
        data: ret.filter(a => a.source === 'twitter')
    };
};

const GET_SOCIAL_ACCOUNTS = gql`
    query getSocialAccounts($username: String) {
        getSocialAccounts(username: $username) {
            source
            id
            name
            url
            image
            username
            verified
        }
    }
`;
