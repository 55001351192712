import React, { useEffect, useState } from 'react';
import { Tabs } from '../../../components/Tabs';
import { BooleanHelpText, KeywordHelpText } from './HelpText';
import { KeywordSuggestions } from './KeywordSuggestions';
import { useRoomEditor } from './RoomEditorContext';
import { RoomPreview } from './RoomPreview';

export const RightPanel = () => {
    const [setTab, setChangeTabFn] = useState(() => {});
    const { room } = useRoomEditor();
    const tabs = [
        ...(room.query_type === 'keyword' ? [{
            id: 'keyword-suggestions',
            name: 'Keyword suggestions',
            content: <KeywordSuggestions />
        }] : []),
        {
            id: 'room-preview',
            name: 'Room preview',
            content: <RoomPreview queryType={room.query_type} />
        },
        {
            id: 'help',
            name: 'Help',
            content: room.query_type === 'keyword' ? <KeywordHelpText /> : <BooleanHelpText />
        }
    ];

    const [currentTab, setCurrentTab] = useState(tabs[0].id);
    useEffect(() => {
        if (room.query_type === 'boolean' && setTab) {
            setTab('room-preview');
        }
    }, [room.query_type, setTab]);
    return (
        <div className={`px-4 py-5 h-100 bg-${currentTab === 'room-preview' ? 'light' : 'white'} position-relative  right-panel-border`}>
            <div className="right-panel" id="right-panel">
                <div className="overflow-hidden mw-300">
                    <Tabs goToTab={(fn) => setChangeTabFn(() => fn)}
                        data={tabs}
                        onChange={setCurrentTab}
                    />
                </div>
            </div>
        </div>
    );
};
