import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BackButton } from '../../../../components/Atoms';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { getRoom } from '../../../../utils/variables';
import { ClusterWrapper } from '..';
import { TabsContainer } from '../SelectedClusterView/TabsContainer';
import { ExtractCluster } from '../types';
import { Loading } from '../../../../components/Loading';
import { ActionButtons } from '../SelectedClusterView/ActionButtons';
import { NarrativeThemeComparison } from '../Utils/ComparisonContainer/NarrativeThemeComparison';
import { NarrativeComparison } from '../Utils/ComparisonContainer/NarrativeComparison';
import { RootState } from '../../../../store';

export const NarrativeThemeDetails = () => {
    const room = getRoom();
    const match = useRouteMatch();
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);

    const narrativeThemeId = (match.params as { id: string }).id;

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'AI Narratives', href: `/situation-rooms/${room.id}/ai-narratives` },
        { title: 'Narrative theme details' }
    ];
    return (
        <>
            <div className={`position-sticky top-0 bg-white z-index-10 ${
                (selectedNarrativeThemes.length || selectedNarratives.length) ? 'mb-n4' : ''}`}
            >
                {
                    selectedNarrativeThemes.length > 0 ? <NarrativeThemeComparison /> : null
                }
                {
                    selectedNarratives.length > 0 ? <NarrativeComparison /> : null
                }
            </div>
            <div className="page-padding">
                <BreadCrumbs items={bread} />
                <BackButton pathName={`/situation-rooms/${room.id}/ai-narratives`} gotToPreviousPath />
                <h2 className="pb-4 m-0">Narrative theme details</h2>
                <ClusterWrapper id={narrativeThemeId}
                    renderComponent={(cluster) => (
                        <div id="selected-cluster-view" className="bg-white">
                            <div className="d-flex align-items-center justify-content-between p-3">
                                <p className="font-weight-bold m-0 w-75">{cluster.title}</p>
                                <ActionButtons clusterTitle={cluster.title}
                                    summary={cluster.description}
                                    isWatching={cluster?.isWatching}
                                    cluster={cluster}
                                />
                            </div>
                            <hr className="m-0" />
                            <TabsContainer cluster={cluster as ExtractCluster<typeof cluster>}
                                isParent
                            />
                        </div>
                    )}
                    renderLoading={() => <Loading gif relative height={400} />}
                />
            </div>
        </>
    );
};
