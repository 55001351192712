import moment from 'moment-timezone';

const getUnix = (date) => Math.floor(new Date(date).getTime() / 1000);

export const checkAndGetUnix = (date) => (date.toString().length > 11
    ? Math.floor(new Date(date).getTime() / 1000) : date);

export const DATE_RANGE_LABEL = {
    all: 'All time',
    day: 'Last 24 hrs',
    week: 'Last week',
    fortnight: 'Last 2 weeks',
    month: 'Last month',
    sixmonths: 'Last 6 months',
    custom: 'Custom date',
};

export const DATE_RANGE_VALUE = {
    all: 'all',
    day: 'day',
    week: 'week',
    fortnight: 'fortnight',
    month: 'month',
    sixmonths: 'sixmonths',
    custom: 'custom',
};

export const processDateRange = ({ rangeFilterOption, range }) => {
    if (!rangeFilterOption || rangeFilterOption === DATE_RANGE_VALUE.all) {
        return {};
    } if (rangeFilterOption === DATE_RANGE_VALUE.custom && range?.startDate) {
        return range;
    }
    const today = new Date();
    const startDate = new Date();

    switch (rangeFilterOption) {
        case DATE_RANGE_VALUE.day:
            today.setDate(today.getDate());
            startDate.setDate(today.getDate() - 1); // 1 day
            break;
        case DATE_RANGE_VALUE.week:
            today.setDate(today.getDate());
            startDate.setDate(today.getDate() - 7); // 1 week
            break;
        case DATE_RANGE_VALUE.fortnight:
            today.setDate(today.getDate());
            startDate.setDate(today.getDate() - 14); // 2 weeks
            break;
        case DATE_RANGE_VALUE.month:
            today.setDate(today.getDate());
            startDate.setDate(today.getDate() - 30); // 1 month
            break;
        case DATE_RANGE_VALUE.sixmonths:
            today.setDate(today.getDate());
            startDate.setMonth(today.getMonth() - 6); // 6 months
            break;
        default:
            today.setDate(today.getDate());
            startDate.setDate(startDate.getDate());
    }

    return {
        startDate: getUnix(startDate),
        endDate: getUnix(today)
    };
};

export const convertToDate = (value) => {
    if (typeof value === 'number' && value < (new Date().getTime() / 1000 + (100 * 365 * 24 * 3600))) {
        return new Date(value * 1000);
    }

    return new Date(value);
};

export const differenceInMonths = (min, max) => {
    const diff = Math.round(max.diff(min, 'months', true));
    switch (true) {
        case (diff <= 1):
            return ['1m'];
        case (diff <= 3):
            return ['1m', '3m', 'All'];
        case (diff <= 6):
            return ['1m', '3m', '6m', 'All'];
        default:
            return ['1m', '3m', '6m', '1y', 'All'];
    }
};

export const formatDate = (date, format = 'DD/MM/YYYY') => moment.utc(convertToDate(date)).format(format);

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const formatDateLocal = (date, format = 'DD/MM/YYYY') => moment(convertToDate(date)).tz(localTimeZone).format(format);

export const convertToLocalDate = (date) => moment(date).tz(localTimeZone).toDate();
