import { useState } from 'react';

type DropDownReturnProps = [
    boolean,
    () => void,
    (b: boolean) => void
];

export const useDropDown = (): DropDownReturnProps => {
    const [dropdown, setDropdown] = useState<boolean>(false);
    const toggleDropdown = () => setDropdown(!dropdown);
    return [
        dropdown,
        toggleDropdown,
        setDropdown
    ];
};
