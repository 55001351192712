import React, { useState } from 'react';
import { Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { getRoom } from '../../../utils/variables';
import { ContentTableContentType } from './index';
import { LabelsDataProps } from '../../../pages/Room/Labels/InputLabelName';
import { GET_CONTENT_LABELS } from '../../../pages/Room/Labels';
import { allTypes } from '../../../utils/lib/search';

type ThreatLabelsProps = {
    content: ContentTableContentType
}

type GatAllLabelsProps = {
    contentId: string
    userLabelsData: LabelsDataProps[]
    threatResolvers: { type: string, resolver: string }[]
}

export const getAllLabels = ({ contentId, userLabelsData, threatResolvers }: GatAllLabelsProps) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;

    const actualLabels = allTypes.filter(type => {
        if (disabledThreatTypes.includes(type.key)) {
            return false;
        }
        const key = type.isResolver ? 'resolver' : 'type';
        return threatResolvers.find(resolver => resolver[key] === type.key);
    }).map(({ name }) => name);

    const allUserLabels = userLabelsData.filter((labelObject) => (labelObject.contentIds.includes(contentId) ? labelObject : false))
        .sort((current, next) => current.label.length - next.label.length)
        .map(({ label }) => label);
    if (threatSignalsEnabled) {
        return allUserLabels;
    }
    return actualLabels.concat(allUserLabels);
};

export const ThreatLabels = ({ content }: ThreatLabelsProps) => {
    const room = getRoom();
    const [expand, setExpand] = useState(false);
    const labelsFeatureEnabled = !!room?.instance?.plan?.others?.labels;
    const { data } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'network-only',
        skip: !labelsFeatureEnabled
    });
    const labelsData = (data?.getContentLabels || []) as LabelsDataProps[];

    /*
     * ASSUMPTIONS
     *  - The widest character is capital M
     *  - We can fit 55 of the widest characters in a single line
     *  - Each TAG takes 2 widest characters space
     */

    let charactersLimit = 55;
    const allLabels = getAllLabels({
        contentId: content.id,
        userLabelsData: labelsData,
        threatResolvers: content?.threatObject?.threatResolvers || []
    });
    const displayLabels: string[] = [];
    const hiddenLabels: string[] = [];

    for (const label of allLabels) {
        charactersLimit -= label.length;
        if (expand) {
            displayLabels.push(label);
        } else if (charactersLimit > 0) {
            displayLabels.push(label);
        } else if (charactersLimit <= 0) {
            hiddenLabels.push(label);
        }
    }

    if (allLabels.length === 0) {
        return null;
    }

    return (
        <div className="d-flex flex-wrap pl-2">
            {displayLabels.map((label, i) => (
                <Col key={label} xs="auto" className="pr-1 pl-0 mb-1">
                    <div color="dark" className="tag no-hover">
                        {label}
                    </div>
                </Col>
            ))}
            {(hiddenLabels.length > 0 && !expand) && (
                <Col xs="auto" className="pr-0 pl-0 clickable">
                    <div className="tag no-hover"
                        onClick={(e) => { e.preventDefault(); setExpand(!expand); }}
                    >
                        +{hiddenLabels.length} Label{hiddenLabels.length > 1 ? 's' : ''}
                    </div>
                </Col>
            )}
        </div>
    );
};
