import { convertToMarkers } from '../convertToMarkers';
import { OPERATORS } from '../editorConfig';

export const consecutiveOperatorsValidator = (query, queryByLines, lineLengths) => {
    const correctOperatorsRanges = [];
    const highlight = [];

    if (!query || query.trim().length === 0 || OPERATORS.length === 0) {
        return {
            isValid: true,
            highlight
        };
    }

    // Find all correct operators and store the indexes
    [...query.matchAll(new RegExp(`(\\b${OPERATORS.join('\\b|\\b')}\\b)`, 'gm'))].forEach((match) => {
        correctOperatorsRanges.push({ start: match.index, end: match.index + match[0].length, operator: match[0] });
    });

    // Check if operators are used consecutively
    for (let i = 0; i < correctOperatorsRanges.length - 1; i++) {
        const firstOperator = correctOperatorsRanges[i];
        const nextOperator = correctOperatorsRanges[i + 1];
        if ((nextOperator.start - firstOperator.end) === 1) {
            highlight.push([firstOperator.start, nextOperator.end]);
        }
    }

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have used two consecutive operators'
        )
    };
};
