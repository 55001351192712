import React from 'react';
import { getIcons } from '../../../../../../utils/getIcons';
import { PieChart } from '../../../../../../components/Highcharts';
import { capitalizeFirstLetter } from '../../../../../../utils/text';
import { getSentimentColor } from '../../../../../../utils/getSentimentColor';
import { TextTooltip } from '../../../../../../components/ToolTip/TextTooltip';
import { Sentiment } from '../../../types';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';

type SentimentProps = {
    averageSentiment?: string
    sentiments?: Sentiment[]
    id: string
}

export const SentimentCard = ({
    averageSentiment,
    sentiments,
    id
}: SentimentProps) => (
    <div className="cluster-stat-container">
        <WidgetTextTooltip widgetName={WidgetName.Sentiment} />
        <span className="d-flex align-items-center">{getIcons('sentiment', averageSentiment)}
            <TextTooltip placement="right" id={id} title={capitalizeFirstLetter(averageSentiment)} className="ml-1">
                <p className="m-0">Sentiment</p>
                <PieChart data={sentiments?.map(({ score }) => Math.round(parseFloat(score))) || []}
                    labels={sentiments?.map(item => item.sentiment) || []}
                    colors={sentiments?.map(item => getSentimentColor(item.sentiment)) || []}
                    legendEnabled={false}
                    height={200}
                    width={200}
                    innerSize="80%"
                />
                {sentiments?.map(({ sentiment, score }, i) => (
                    <div className="d-flex align-items-center" key={sentiment}>
                        <div style={{
                            height: 11,
                            width: 11,
                            borderRadius: 2,
                            backgroundColor: getSentimentColor(sentiment),
                        }}
                        />
                        <p className="mb-0 pl-1">
                            {capitalizeFirstLetter(sentiment)}- {Math.round(parseFloat(score))}%
                        </p>
                    </div>
                ))}
            </TextTooltip>
        </span>
    </div>
);
