import React from 'react';
import { Table } from 'reactstrap';
import { LocationMentions } from '../../types';
import { EmptyState } from '../../../../../components/EmptyState';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { Images } from '../../../../../utils/modeSwitch';

type LocationMentionsProps = {
    topLocations: LocationMentions[]
}

export const TopLocationMentions = ({ topLocations }: LocationMentionsProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], actorName: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={actorName} />,
        goToPreviousOnClose: true,
        size: 'xl'
    });
    if (!topLocations?.length) {
        return <div className="border m-2"><EmptyState title="No data found" icon={Images().overview} /></div>;
    }
    return (
        <div className="bg-white px-2 pt-2 pb-1 w-100 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <Table hover className="border-left border-right border-bottom w-50">
                <thead>
                    <tr>
                        <th>Sources</th>
                        <th>Contents</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        topLocations.map((locationsData) => (
                            <tr key={locationsData.location}>
                                <td className="w-75 text-primary cursor-pointer"
                                    onClick={() => openDuplictedDocsModal(locationsData.contentIds, locationsData.location)}
                                >
                                    {locationsData.location}
                                </td>
                                <td>{locationsData.contentIds.length}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    );
};
