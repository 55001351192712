import React, { useState } from 'react';
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';
import slide1image from './ai-narratives.png';
import slide2image from './expanded-coverage.png';
import slide3image from './enhanced-filters.png';
import slide4image from './labels.png';
import slide5image from './network-views.png';

const items = [
    {
        src: slide1image,
        altText: '',
        caption: 'AI Narratives',
        subText: `Save time with AI Narratives, which automatically groups related content into clear, 
        meaningful clusters. Quickly identify key narratives and focus on what matters most.`,
        key: 1,
    },
    {
        src: slide2image,
        altText: '',
        caption: 'Expanded coverage',
        subText: `Monitor a broader range of content with enhanced coverage across key platforms like Meta and X, 
        as well as fringe platforms. Stay informed on emerging narratives no matter where they start.`,
        key: 2,
    },
    {
        src: slide3image,
        altText: '',
        caption: 'Enhanced Filters',
        subText: `Take full control of your information environment with advanced filters. 
        Easily customise searches and create precise “rooms within rooms” to zero in on the insights you need.`,
        key: 3,
    },
    {
        src: slide4image,
        altText: '',
        caption: 'Labels',
        subText: `Users can now label their own documents using their own language or terminology. 
        These labels can then be searched and filtered, allowing you to personalise and tailor your information environment 
        to your specific needs.`,
        key: 4,
    },
    {
        src: slide5image,
        altText: '',
        caption: 'Network views',
        subText: `Visualise connections between actors within a Situation Room. 
        Uncover hidden relationships and gain deeper insights into the dynamics at play.`,
        key: 5,
    }
];

export const WhatsNewCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => (
        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
        >
            <img src={item.src} alt={item.altText} className="mb-3 mt-2 maxh-500" />
            <h2>{item.caption}</h2>
            <p>{item.subText}</p>
        </CarouselItem>
    ));
    return (
        <Carousel activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={5000}
            className="carousel-fade"
            slide={false}
        >
            {slides}
            <CarouselIndicators items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
                className="custom-carousel-indicators"
            />
        </Carousel>
    );
};
