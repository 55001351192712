import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { debounce, isEqual, pick } from 'lodash';
import {
    NetworksHistoryState,
    NetworksHistoryStore,
    incrementChartKey,
    incrementChartUpdates,
    networksHistoryInitialState,
    resetNetworks,
    setRegraphLoading,
    settingsFields,
    updateNetworks
} from '../../store';

export const useNetworkHistory = () => {
    const { past, present, future } = useSelector((state: NetworksHistoryStore) => state.networksHistory);
    const dispatch = useDispatch();

    const triggerLoadingState = (action: () => void) => {
        dispatch(setRegraphLoading(true));
        debounce(action, 300)();
        // In case chart updates before we trigger loading state
        debounce(() => dispatch(setRegraphLoading(false)), 4000)();
    };

    const performAction = (nextState: NetworksHistoryState, action: () => void) => {
        if (
            isEqual(present.deletedNodes, nextState.deletedNodes)
            && isEqual(pick(present, settingsFields), pick(nextState, settingsFields))
        ) {
            action();
        } else {
            triggerLoadingState(() => action());
        }
    };

    return {
        triggerLoadingState,
        undo: () => {
            if (!past.length) return null;
            performAction(past[past.length - 1], () => dispatch(ActionCreators.undo()));
            dispatch(incrementChartUpdates());
        },
        redo: () => {
            if (!future.length) return null;
            performAction(future[0], () => dispatch(ActionCreators.redo()));
            dispatch(incrementChartUpdates());
        },
        reset: (nextState?: NetworksHistoryState) => {
            performAction(nextState || networksHistoryInitialState, () => {
                dispatch(resetNetworks());
                dispatch(ActionCreators.clearHistory());
                if (nextState) {
                    dispatch(updateNetworks(nextState));
                }
                dispatch(incrementChartKey());
            });
        }
    };
};
