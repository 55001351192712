import React from 'react';
import { Trash2 } from 'react-feather';
import { Row, Col, Input, Label } from 'reactstrap';
import { Dropdown } from '../Form/Dropdown';

export const AddedEmails = ({ emails, handleRemove, setEmails }) =>
    emails.length > 0 && (
        <div className="my-3">
            <h3>Check emails and select user roles</h3>
            {emails.map((email, i) => (
                <Row key={`${email.id}invite_email`} className="align-items-center mx-n1 mb-2">
                    <Col xs={12} md={6} className="px-1">
                        <Label>Email address</Label>
                        <Input value={email.email}
                            onChange={(e) => setEmails(emails.map((a, b) => {
                                if (b === i) {
                                    return {
                                        ...a,
                                        email: e.target.value
                                    };
                                }
                                return a;
                            }))}
                        />
                    </Col>
                    <Col className="flex-1 px-1">
                        <Label>User role</Label>
                        <Dropdown className="text-capitalize"
                            value={
                                email.role
                                    ? userRoles.find(a => a.key === email.role)
                                    : { key: 'TEAM', name: 'Team' }
                            }
                            options={userRoles}
                            onChange={(item) => setEmails(emails.map((a, b) => {
                                if (b === i) {
                                    return {
                                        ...a,
                                        role: item.key
                                    };
                                }
                                return a;
                            }))}
                        />
                    </Col>
                    <Col xs="auto" className="px-1">
                        <div className="text-primary"
                            role="button"
                            data-testid="remove-members"
                            onClick={() => handleRemove(email)}
                        >
                            <Trash2 />
                        </div>
                    </Col>
                </Row>
            ))}
        </div>
    );

const userRoles = [
    { key: 'ADMIN', name: 'Admin' },
    { key: 'TEAM', name: 'Team' },
    { key: 'SYNDICATE', name: 'Syndicated' }
];
