import React, { useState, useEffect } from 'react';
import { useRoomEditor } from '../RoomEditorContext';
import { BooleanQuery } from './BooleanQuery';

export const BooleanEditor = () => {
    const { room, setRoom, convertedQuery } = useRoomEditor();
    const [boolean, setBoolean] = useState(room.query.boolean);
    const [usedConvertedQuery, setUsedConverted] = useState(false);
    useEffect(() => {
        if (convertedQuery && !usedConvertedQuery) {
            setBoolean(convertedQuery.boolean);
            setUsedConverted(true);
        }
    }, [convertedQuery, usedConvertedQuery]);
    const handleBooleanChange = (query) => {
        const spaceBeforeNewLine = query.replaceAll('\n', ' \n');
        setBoolean(query);
        setRoom((state) => ({
            ...state,
            query: {
                ...state.query,
                boolean: spaceBeforeNewLine
            }
        }));
    };
    return (
        <BooleanQuery booleanQuery={boolean}
            setBooleanQuery={handleBooleanChange}
        />
    );
};
