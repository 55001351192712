import { convertToMarkers } from '../convertToMarkers';

/*
 * Due to ES limitations we can only allow to use dots in: urls, domains, links and page urls
 * these tags require an empty space after the colon and this validator must only run after
 * the empty space is validated
 */
const tagsWithDotsAllowed = [
    'url:\\s*',
    'links:\\s*',
    'domain:\\s*',
    'from:*',
    'mentions:*'
];

const componentsWithDotsAllowed = [
    'http',
    'https',
    'www'
];

export const dotsValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];
    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    const allValidComponents = [...query.matchAll(
        new RegExp(`(\\b${componentsWithDotsAllowed.join('\\b|\\b')}\\b)(.*?)(\\s|$)`, 'ig')
    )];
    const allValidTags = [...query.matchAll(
        new RegExp(`(\\b${tagsWithDotsAllowed.join('\\b|\\b')}\\b)(.*?)(\\s|$)`, 'ig')
    )];

    // Remove elements with the valid use of dots from the query
    let queryExcludingValidDots = query.split('');
    allValidComponents.forEach((match) => {
        for (let i = 0; i < match[0].length; i++) {
            queryExcludingValidDots[match.index + i] = 'X';
        }
    });
    allValidTags.forEach((match) => {
        for (let i = 0; i < match[0].length; i++) {
            queryExcludingValidDots[match.index + i] = 'X';
        }
    });

    queryExcludingValidDots = queryExcludingValidDots.join('');

    [...queryExcludingValidDots.matchAll(/\./g)].forEach(match => {
        highlight.push([match.index, match.index + match[0].length]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'Dots are supported only in urls, domains, tags (ie. Twitter) and account names'
        )
    };
};
