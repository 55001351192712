import React from 'react';
import { color } from '../../utils/getColors';

export const InvestigationSuccess = () => (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_998_2277)">
            <path fill="#D2D4DB"
                d="M61.5757 17.4137L25.6305 12.3305C23.2118 11.9885 20.9737 13.672 20.6316 16.0908L14.8172
                57.2075C14.4751 59.6263 16.1586 61.8643 18.5774 62.2064L54.5226 67.2895C56.9414 67.6316 59.1795
                65.9481 59.5215 63.5293L65.336 22.4126C65.6781 19.9939 63.9945 17.7557 61.5757 17.4137Z"
            />
            <path fill={color.grey[300]}
                d="M4.27941 0.000155608C3.72463 -0.00748039 3.17378 0.0943491 2.65838 0.299756C2.14297 0.505163
                1.67315 0.810125 1.2757 1.19726C0.878254 1.58439 0.561019 2.04607 0.342129 2.5559C0.123239 3.06572
                0.00695581 3.61368 0 4.16847V71.5725C0.00724537 72.1271 0.123707 72.6748 0.342726 73.1844C0.561745
                73.694 0.879042 74.1553 1.27646 74.5422C1.67388 74.9291 2.14366 75.234 2.65892 75.4392C3.17418 75.6445
                3.72482 75.7462 4.27941 75.7385H56.1856C56.7401 75.7462 57.2908 75.6445 57.806 75.4392C58.3213 75.234
                58.7911 74.9291 59.1885 74.5422C59.5859 74.1553 59.9032 73.694 60.1222 73.1844C60.3413 72.6748 60.4577
                72.1271 60.465 71.5725V10.5565L49.4487 0.000155608H4.27941Z"
            />
            <path fill="#D2D4DB"
                d="M41.0562 46.2557H20.3924C20.2451 46.2557 20.1038 46.1972 19.9996 46.0931C19.8954
                45.9889 19.8369 45.8476 19.8369 45.7003C19.8369 45.5529 19.8954 45.4116 19.9996
                45.3075C20.1038 45.2033 20.2451 45.1448 20.3924 45.1448H41.0562C41.2035 45.1448
                41.3448 45.2033 41.449 45.3075C41.5532 45.4116 41.6117 45.5529 41.6117 45.7003C41.6117
                45.8476 41.5532 45.9889 41.449 46.0931C41.3448 46.1972 41.2035 46.2557 41.0562 46.2557Z"
            />
            <path fill="#D2D4DB"
                d="M35.5905 49.4086H20.3904C20.2431 49.4086 20.1018 49.3501
                19.9976 49.2459C19.8935 49.1417 19.835 49.0004 19.835 48.8531C19.835 48.7058
                19.8935 48.5645 19.9976 48.4603C20.1018 48.3561 20.2431 48.2976 20.3904
                48.2976H35.5905C35.7379 48.2976 35.8792 48.3561 35.9833 48.4603C36.0875
                48.5645 36.146 48.7058 36.146 48.8531C36.146 49.0004 36.0875 49.1417 35.9833
                49.2459C35.8792 49.3501 35.7379 49.4086 35.5905 49.4086Z"
            />
            <path fill="#D2D4DB"
                d="M30.7223 52.3392H20.3904C20.2431 52.3392 20.1018 52.2807 19.9976
                52.1765C19.8935 52.0724 19.835 51.9311 19.835 51.7837C19.835 51.6364
                19.8935 51.4951 19.9976 51.391C20.1018 51.2868 20.2431 51.2283 20.3904
                51.2283H30.7223C30.8697 51.2283 31.011 51.2868 31.1151 51.391C31.2193
                51.4951 31.2778 51.6364 31.2778 51.7837C31.2778 51.9311 31.2193 52.0724
                31.1151 52.1765C31.011 52.2807 30.8697 52.3392 30.7223 52.3392Z"
            />
            <path fill="#D2D4DB"
                d="M14.6713 45.1003H9.19652C8.63817 45.1003 8.18555 45.553 8.18555
                46.1113V51.5861C8.18555 52.1444 8.63817 52.5971 9.19652
                52.5971H14.6713C15.2297 52.5971 15.6823 52.1444 15.6823 51.5861V46.1113C15.6823
                45.553 15.2297 45.1003 14.6713 45.1003Z"
            />
            <path fill="#D2D4DB"
                d="M34.6974 58.0096H20.3904C20.2431 58.0096 20.1018 57.9511 19.9976 57.847C19.8935
                57.7428 19.835 57.6015 19.835 57.4542C19.835 57.3068 19.8935 57.1655 19.9976 57.0614C20.1018
                56.9572 20.2431 56.8987 20.3904 56.8987H34.6974C34.8447 56.8987 34.9859 56.9572 35.0901
                57.0614C35.1943 57.1655 35.2529 57.3068 35.2529 57.4542C35.2529 57.6015 35.1943
                57.7428 35.0901 57.847C34.9859 57.9511 34.8447 58.0096 34.6974 58.0096Z"
            />
            <path fill="#D2D4DB"
                d="M30.9134 61.1581H20.3904C20.2431 61.1581 20.1018 61.0996 19.9976 60.9954C19.8935 60.8912 19.835
                60.7499 19.835 60.6026C19.835 60.4553 19.8935 60.314 19.9976 60.2099C20.1018 60.1057 20.2431 60.0471 20.3904
                60.0471H30.9134C31.0608 60.0471 31.202 60.1057 31.3062 60.2099C31.4104 60.314 31.4689 60.4553 31.4689
                60.6026C31.4689 60.7499 31.4104 60.8912 31.3062 60.9954C31.202 61.0996 31.0608 61.1581 30.9134 61.1581Z"
            />
            <path fill="#D2D4DB"
                d="M27.5428 64.0934H20.3904C20.2431 64.0934 20.1018 64.0348 19.9976 63.9306C19.8935
                63.8265 19.835 63.6852 19.835 63.5379C19.835 63.3906 19.8935 63.2493 19.9976 63.1451C20.1018
                63.0409 20.2431 62.9824 20.3904 62.9824H27.5428C27.6901 62.9824 27.8314 63.0409 27.9356
                63.1451C28.0397 63.2493 28.0983 63.3906 28.0983 63.5379C28.0983 63.6852 28.0397 63.8265
                27.9356 63.9306C27.8314 64.0348 27.6901 64.0934 27.5428 64.0934Z"
            />
            <path fill="#D2D4DB"
                d="M51.5464 33.4108H8.7879C8.64058 33.4108 8.49928 33.3522 8.39511 33.2481C8.29093
                33.1439 8.23242 33.0026 8.23242 32.8553C8.23242 32.708 8.29093 32.5667 8.39511 32.4625C8.49928
                32.3583 8.64058 32.2998 8.7879 32.2998H51.5486C51.6959 32.2998 51.8372 32.3583 51.9414
                32.4625C52.0456 32.5667 52.1041 32.708 52.1041 32.8553C52.1041 33.0026 52.0456 33.1439
                51.9414 33.2481C51.8372 33.3522 51.6959 33.4108 51.5486 33.4108H51.5464Z"
            />
            <path fill="#D2D4DB"
                d="M40.2369 36.5548H8.7879C8.64058 36.5548 8.49928 36.4963 8.39511 36.3921C8.29093
                36.2879 8.23242 36.1466 8.23242 35.9993C8.23242 35.852 8.29093 35.7108 8.39511
                35.6066C8.49928 35.5024 8.64058 35.4438 8.7879 35.4438H40.2391C40.3864 35.4438 40.5277
                35.5024 40.6319 35.6066C40.7361 35.7108 40.7946 35.852 40.7946 35.9993C40.7946 36.1466
                40.7361 36.2879 40.6319 36.3921C40.5277 36.4963 40.3864 36.5548 40.2391 36.5548H40.2369Z"
            />
            <path fill="#D2D4DB"
                d="M30.1649 39.4901H8.7879C8.64058 39.4901 8.49928 39.4315 8.39511
                39.3274C8.29093 39.2232 8.23242 39.0819 8.23242 38.9346C8.23242 38.7873 8.29093
                38.646 8.39511 38.5418C8.49928 38.4377 8.64058 38.3792 8.7879
                38.3792H30.1672C30.3145 38.3792 30.4558 38.4377 30.5599 38.5418C30.6641
                38.646 30.7226 38.7873 30.7226 38.9346C30.7226 39.0819 30.6641 39.2232
                30.5599 39.3274C30.4558 39.4315 30.3145 39.4901 30.1672 39.4901H30.1649Z"
            />
            <path fill="#D2D4DB"
                d="M14.6382 64.2953H9.23228C8.9671 64.2953 8.71281 64.19 8.5253 64.0024C8.33779
                63.8149 8.23242 63.5606 8.23242 63.2955V57.8895C8.23242 57.6243 8.33779 57.37 8.5253
                57.1825C8.71281 56.995 8.9671 56.8896 9.23228 56.8896H14.6404C14.9056 56.8896 15.1599
                56.995 15.3474 57.1825C15.5349 57.37 15.6403 57.6243 15.6403 57.8895V63.2955C15.6403
                63.427 15.6144 63.5571 15.564 63.6786C15.5136 63.8001 15.4397 63.9104 15.3467 64.0033C15.2536
                64.0961 15.1431 64.1698 15.0215 64.2199C14.9 64.27 14.7697 64.2956 14.6382
                64.2953ZM9.34115 63.1844H14.5271V58.0006H9.34115V63.1844Z"
            />
            <path fill="#D2D4DB"
                d="M31.3909 10.0454H10.1183C9.38203 10.0454 8.78516 10.6423 8.78516 11.3786V25.7321C8.78516 26.4684
                9.38203 27.0653 10.1183 27.0653H31.3909C32.1272 27.0653 32.7241 26.4684 32.7241 25.7321V11.3786C32.7241
                10.6423 32.1272 10.0454 31.3909 10.0454Z"
            />
            <path fill="#D2D4DB"
                d="M49.4492 0V8.47886C49.4492 9.02984 49.6681 9.55823 50.0577 9.94784C50.4473 10.3374 50.9757
                10.5563 51.5267 10.5563H60.4677L49.4492 0Z"
            />
            <path fill="#C8CACE"
                d="M56.7612 80.0022C64.9044 80.0022 71.5058 73.4008 71.5058 65.2576C71.5058 57.1143
                64.9044 50.5129 56.7612 50.5129C48.618 50.5129 42.0166 57.1143 42.0166 65.2576C42.0166
                73.4008 48.618 80.0022 56.7612 80.0022Z"
            />
            <path fill={color.grey[200]}
                d="M11.2668 50.6263C11.1937 50.6266 11.1214 50.6124 11.0539 50.5846C10.9864 50.5567
                10.9251 50.5158 10.8735 50.4641L9.76251 49.3532C9.65821 49.2489 9.59961 49.1074 9.59961
                48.9599C9.59961 48.8124 9.65821 48.6709 9.76251 48.5666C9.86681 48.4623 10.0083 48.4037
                10.1558 48.4037C10.3033 48.4037 10.4448 48.4623 10.5491 48.5666L11.2668 49.2865L13.3176
                47.2335C13.4219 47.1292 13.5633 47.0706 13.7109 47.0706C13.8584 47.0706 13.9998 47.1292
                14.1041 47.2335C14.2084 47.3378 14.267 47.4792 14.267 47.6267C14.267 47.7742 14.2084
                47.9157 14.1041 48.02L11.66 50.4641C11.6084 50.5158 11.5471 50.5567 11.4796
                50.5846C11.4121 50.6124 11.3398 50.6266 11.2668 50.6263Z"

            />
            <path fill="white"
                d="M54.4831 70.3745C54.4103 70.3742 54.3384 70.3594 54.2713 70.3312C54.2041 70.303
                54.1432 70.2619 54.0921 70.2101L49.5305 65.6507C49.4292 65.5461 49.373 65.4058
                49.374 65.2602C49.3751 65.1145 49.4333 64.9751 49.5362 64.872C49.639 64.7688
                49.7782 64.7102 49.9239 64.7087C50.0695 64.7073 50.2099 64.7631 50.3148
                64.8641L54.4831 69.0325L63.2042 60.3025C63.3085 60.1982 63.4499 60.1396 63.5974
                60.1396C63.7449 60.1396 63.8864 60.1982 63.9907 60.3025C64.095 60.4069 64.1536
                60.5483 64.1536 60.6958C64.1536 60.8433 64.095 60.9848 63.9907 61.0891L54.8809
                70.199C54.8297 70.2508 54.7689 70.2919 54.7018 70.3201C54.6346 70.3484 54.5626
                70.3631 54.4898 70.3634L54.4831 70.3745Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_998_2277">
                <rect width="71.5056" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
