import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useSelector } from 'react-redux';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { CREATE_REPORT_TABS } from '../../../../redux/reports';
import { AddData } from './AddData';
import { Export } from './Export';
import { getRoom } from '../../../../utils/variables';
import { getParam } from '../../../../utils/urlParams';
import { useGetReport } from '../../../../services/Report/getReport';
import { Loading } from '../../../../components/Loading';
import { Tabs } from './Tabs';
import { color } from '../../../../utils/getColors';

export const CreateReport = ({ bread }) => {
    const room = getRoom();
    const id = getParam('id');
    const { activeTab } = useSelector(state => state.createReport);
    const { loading } = useGetReport({ id: id || '' });

    return (
        <div className="p-4">
            {bread}
            <div className="my-4">
                <Link className="mt-4 mb-4" to={`/situation-rooms/${room?.id}/report`}>
                    <div className="d-flex align-items-center">
                        <ArrowLeft color={color.blue[500]} size={18} />
                        <span className="ml-1" data-testid="Back">Back to reports</span>
                    </div>
                </Link>
            </div>
            <h2 className="my-4">Create report</h2>
            {loading && <Loading relative height={500} />}
            {!loading && (
                <>
                    <Tabs />
                    <TabContent data-testid="tab-content" activeTab={activeTab}>
                        <TabPane data-testid="visualise-tab-pane" tabId={CREATE_REPORT_TABS.VISUALISE}>
                            <AddData />
                        </TabPane>
                        <TabPane data-testid="export-tab-pane" tabId={CREATE_REPORT_TABS.EXPORT}>
                            <Export />
                        </TabPane>
                    </TabContent>
                </>
            )}
        </div>
    );
};
