import React, { useEffect, useState } from 'react';
import {
    AlignCenter,
    AlignJustify,
    AlignLeft,
    AlignRight,
    Bold,
    Italic,
    Underline
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ColorInput } from './ColorInput';
import { setToolbarAction } from '../../../../../../../redux/reports';
import { Tool } from './Tool';

export const Toolbar = () => {
    const dispatch = useDispatch();
    const { toolbar } = useSelector(state => state.createReport);
    const [scrollTop, setScrollTop] = useState();
    const [offset, setOffset] = useState(450);

    useEffect(() => {
        const onScroll = () => {
            const currentPosition = window.pageYOffset;
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
            const toolbarEl = document.getElementById('pp-toolbar');
            const newOffset = toolbarEl ? toolbarEl.getBoundingClientRect().top + window.scrollY - 72 : 450;
            if (newOffset !== offset) {
                setOffset(newOffset);
            }
        };
        const contentPage = document.getElementsByClassName('content-page')[0];
        contentPage?.addEventListener('scroll', onScroll);
        return () => contentPage?.removeEventListener('scroll', onScroll);
    }, [offset, scrollTop]);
    return (
        <div id="pp-toolbar">
            <div className="bg-white border-bottom py-3 px-2 position-absolute z-index w-100 d-flex align-items-center"
                style={{ top: scrollTop < offset ? 0 : (scrollTop - offset) || 0, transition: 'all .05s ease-in-out' }}
            >
                <ColorInput value={toolbar.color} onChange={(val) => dispatch(setToolbarAction({ color: val }))} />
                {tools.map(tool => {
                    const { Icon } = tool;
                    return (
                        <Tool selected={tool.type === 'boolean' ? toolbar[tool.field] : toolbar[tool.field] === tool.value}
                            key={`${tool.field}-${tool.value}`}
                            Icon={Icon}
                            onClick={() => dispatch(setToolbarAction({
                                [tool.field]: tool.type === 'boolean' ? !toolbar[tool.field] : tool.value
                            }))}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const tools = [
    {
        field: 'bold',
        Icon: Bold,
        type: 'boolean'
    },
    {
        field: 'italic',
        Icon: Italic,
        type: 'boolean'
    },
    {
        field: 'underline',
        Icon: Underline,
        type: 'boolean'
    },
    {
        field: 'align',
        Icon: AlignLeft,
        type: 'string',
        value: 'left'
    },
    {
        field: 'align',
        Icon: AlignCenter,
        type: 'string',
        value: 'center'
    },
    {
        field: 'align',
        Icon: AlignRight,
        type: 'string',
        value: 'right'
    },
    {
        field: 'align',
        Icon: AlignJustify,
        type: 'string',
        value: 'justify'
    }
];
