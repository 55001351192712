import React from 'react';

export const LoadingState = () => (
    <div className="loading-container">
        <div className="loading-bg mb-2 py-7"><p>Loading</p></div>
        {[1, 2, 3].map((a) => (
            <div key={a} className="loading-bg px-2 mt-2">
                <p>Loading</p>
            </div>
        ))}
    </div>
);
