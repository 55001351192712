import React from 'react';
import { color } from '../../utils/getColors';

export const TranslateIcon = ({ color, size }) => (
    <svg fill={color || '#f62459'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width={size ? `${size}px` : '50px'} height={size ? `${size}px` : '50px'}>
        <path d="M 6 3 C 4.300781 3 3 4.300781 3 6 L 3 26 C 3 27.699219 4.300781 29 6 29 L 6.40625 29 L 8 27 L 6 27 C 5.398438 27 5 26.601563 5 26 L 5 6 C 5 5.398438 5.398438 5 6 5 L 26 5 C 26.601563 5 27 5.398438 27 6 L 27 21 L 24 21 C 22.300781 21 21 22.300781 21 24 L 21 27 L 16 27 L 17.59375 29 L 21 29 L 21 32.40625 L 23 34.09375 L 23 24 C 23 23.398438 23.398438 23 24 23 L 44 23 C 44.601563 23 45 23.398438 45 24 L 45 44 C 45 44.601563 44.601563 45 44 45 L 24 45 C 23.398438 45 23 44.601563 23 44 L 23 42 L 21 43.6875 L 21 44 C 21 45.699219 22.300781 47 24 47 L 44 47 C 45.699219 47 47 45.699219 47 44 L 47 24 C 47 22.300781 45.699219 21 44 21 L 29 21 L 29 6 C 29 4.300781 27.699219 3 26 3 Z M 16 8 L 16 10 L 8 10 L 8 12 L 19.90625 12 C 19.597656 14.226563 18.292969 16.054688 16.65625 17.53125 C 14.148438 15.332031 12.875 13.03125 12.875 13.03125 L 11.125 13.96875 C 11.125 13.96875 12.433594 16.378906 15.0625 18.78125 C 14.996094 18.828125 14.941406 18.890625 14.875 18.9375 C 12.234375 20.757813 9.59375 21.65625 9.59375 21.65625 L 10.21875 23.5625 C 10.21875 23.5625 13.125 22.597656 16.03125 20.59375 C 16.238281 20.449219 16.449219 20.28125 16.65625 20.125 C 17.796875 20.96875 19.125 21.742188 20.625 22.34375 L 21.375 20.46875 C 20.226563 20.011719 19.199219 19.417969 18.28125 18.78125 C 20.109375 17.050781 21.636719 14.792969 21.9375 12 L 25 12 L 25 10 L 18 10 L 18 8 Z M 12 25 L 7 31 L 10 31 L 10 35 L 14 35 L 14 31 L 17 31 Z M 33 26.40625 L 27.8125 40.1875 L 30.3125 40.1875 L 31.40625 37 L 36.6875 37 L 37.8125 40.1875 L 40.3125 40.1875 L 35.09375 26.40625 Z M 34 29.40625 L 36 35.09375 L 32 35.09375 Z M 19 33 L 19 36 L 10 36 L 14 40 L 19 40 L 19 43 L 25 38 Z" />
    </svg>
);

export const TranslateIconBlue = ({ size = 20 }) => (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className='mt-0'
    >
        <defs>
            <image width={7}
                height={7}
                id="a"
                href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNyA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi42ODEyNSA1LjM5MjVMMi4xMzc1IDdIMC43NUwzLjA3NzUgMC43NUg0LjY4MjVMNyA3SDUuNTQxMjVMNC45OTc1IDUuMzkyNUgyLjY4MTI1Wk00LjcyMzc1IDQuNDcyNUwzLjg3NSAxLjk0NUgzLjgxMzc1TDIuOTY1IDQuNDcyNUg0LjcyNUg0LjcyMzc1WiIgZmlsbD0iIzAwNkZGOSIvPgo8L3N2Zz4K"
            />
        </defs>
        <path fillRule="evenodd"
            d="M0 2.5C0 1.8.3 1.2.7.7 1.2.3 1.8 0 2.5 0h8.8c.6 0 1.2.3 1.7.7.5.5.7 1.1.7 1.8v3.8h3.8c.7 0 1.3.2 1.8.7.4.5.7 1.1.7 1.8v8.7c0 .7-.3 1.3-.7 1.8-.5.4-1.1.7-1.8.7H8.7c-.6 0-1.2-.3-1.7-.7-.5-.5-.7-1.1-.7-1.8v-3.7H2.5c-.7 0-1.3-.3-1.8-.8-.4-.5-.7-1.1-.7-1.7zm2.5-1.3c-.3 0-.6.1-.9.4-.2.2-.3.5-.3.9v8.7c0 .3.1.6.3.9.3.2.6.4.9.4h8.8c.3 0 .6-.2.8-.4.3-.3.4-.6.4-.9V2.5c0-.4-.1-.7-.4-.9-.2-.3-.5-.4-.9-.4zm9 12.5q.3.6.8 1.1-1.4 1-3.5 1.6c.2.2.6.8.7 1.1q2.1-.7 3.6-1.9c1 .8 2.2 1.4 3.7 1.8.1-.3.5-.8.7-1.1q-2.1-.5-3.5-1.6 1.3-1.4 2.1-3.4h1.4V10h-3.7v1.3h1q-.6 1.5-1.6 2.6l-.6-.6q-.5.3-1.1.4z"
            className="fill-primary"
        />
        <use href="#a" transform="translate(3 3)" />
    </svg>
  )

export const KeyBoardUpIcon = ({ height, width, className }) => (
    <svg className={className ? className: ''} xmlns="http://www.w3.org/2000/svg" height={height ? height : "18px"} viewBox="0 0 24 24" width={ width ? width : "18px"} fill={color.blue[500]}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z" />
    </svg>
);

export const KeyBoardDownIcon = ({ height, width, className }) => (
    <svg className={className ? className: ''} xmlns="http://www.w3.org/2000/svg" height={height ? height :"18px" } viewBox="0 0 24 24" width={ width ? width : "18px"} fill={color.blue[500]}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    </svg>
);
