import { gql, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { useContentFilters } from '../../useFilters';
import { Content } from '../../../../services/Content/contentInterface';
import { incrementChartUpdates } from '../store';

export type NetworkNode = Pick<Content, 'id' | 'contentType' | 'from' | 'hashtags' | 'url' | 'engagement' | 'mentions'
| 'pubdatetime' | 'threatLevel' | 'sentiment'>

type NetworksResponse = {
    loading: boolean
    data: NetworkNode[]
    count: number
}

export const useNetworksData = (): NetworksResponse => {
    const filters = useContentFilters({
        useGlobalFilters: true
    });
    const dispatch = useDispatch();

    const { data, loading } = useQuery(GET_NETWORKS, {
        variables: {
            limit: 2500,
            sort: 'engagement',
            ...filters
        },
        onCompleted: () => {
            dispatch(incrementChartUpdates());
            dispatch(ActionCreators.clearHistory());
        }
    });
    const content = (data?.getContent?.content || []) as NetworkNode[];
    const count = data?.getContent?.total || 0;

    return {
        loading,
        data: content,
        count
    };
};

export const GET_NETWORKS = gql`
    query getNetworksData(
        $projectId: String!
        $sort: ContentSort
        $limit: Int = 10
        $offset: Int = 0
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        getContent(
            projectId: $projectId
            sort: $sort
            limit: $limit
            offset: $offset
            filters: $filters
            dslQuery: $dslQuery
        ) {
            total
            content {
                id
                contentType
                from
                hashtags
                url
                engagement
                mentions
                pubdatetime
                threatLevel
                sentiment
            }
        }
    }
`;
