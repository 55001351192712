import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { BackButton } from '../../../../components/Atoms/BackButton';
import { RemoveSource } from '../SourceList/RemoveSource';
import { SourceBody } from './SourceBody';
import { GET_CTYPE } from '../../../../graphql';
import { Loading } from '../../../../components/Loading';
import { removeTypename } from '../../../../utils/removeAllTypename';
import { ContentType } from '../../../../utils/variables';

export const EditSource = () => {
    const match = useRouteMatch();
    const ctypeId = (match.params as { id: string }).id;
    const { data: ctypeData, loading } = useQuery(GET_CTYPE, {
        variables: { id: ctypeId }
    });

    if (loading) {
        return <Loading />;
    }

    const source = ctypeData.getCtype as ContentType;

    const ctypeName = source.name;
    const bread = [
        { title: 'Admin home', href: '/admin' }, { title: 'Source mapping', href: '/admin/ctypes' }, { title: ctypeName }
    ];
    return (
        <div className="page-padding">
            <BreadCrumbs items={bread} />
            <BackButton pathName="/admin/ctypes" />
            <div className="d-flex justify-content-between mb-4">
                <h2 className="m-0">{source.name}</h2>
                <RemoveSource ctypeId={source.id} isEditPage />
            </div>
            <SourceBody sourceData={removeTypename(source)} />
        </div>
    );
};
