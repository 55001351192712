import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { Trash2 } from 'react-feather';
import { UseSavedNetworksProps, useSavedNetworks } from './useSavedNetworks';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { SortToggle } from '../../../../../../components/Table/SortToggle';
import { formatDate } from '../../../../../../utils/getTimeFrom';
import { IconButton } from '../../../../../../components/Atoms';
import { Loading } from '../../../../../../components/Loading';
import { DeleteSavedNetworkGraph } from './DeleteSavedNetworkGraph';
import { Pagination } from '../../../../../../components/Molecules';
import { EmptyState } from '../../../../../../components/EmptyState';
import savedNetworksIcon from '../../../../../../assets/images/investigations/approveInvestigationSuccess.svg';

export const SavedNetworksModal = () => {
    const [sort, setSort] = useState({ field: 'createdAt', order: 'desc' } as NonNullable<UseSavedNetworksProps['sort']>);
    const [skip, setSkip] = useState(0);
    const { savedNetworks, loading, total } = useSavedNetworks({ sort, skip });
    const { closeModal, setModal } = useModal();
    const handleDelete = (id: string) => {
        setModal({
            header: 'Delete saved network graph',
            component: <DeleteSavedNetworkGraph id={id} />
        });
    };
    if (!loading && !savedNetworks.length) {
        return <EmptyState title="No saved networks" icon={savedNetworksIcon} summary="Saved networks will appear here." />;
    }
    return (
        <div>
            {loading && <Loading />}
            <div className={`border rounded mb-3 overflow-hidden ${loading ? 'minh-300' : ''}`}>
                <Table className="m-0" hover>
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                <SortToggle sort={sort} setSort={setSort} name="Saved network name" field="name" />
                            </th>
                            <th className="border-top-0">
                                Saved by
                            </th>
                            <th className="border-top-0">
                                <SortToggle sort={sort} setSort={setSort} name="Saved date" field="createdAt" />
                            </th>
                            <th className="border-top-0">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {savedNetworks.map(network => (
                            <tr key={network.id}>
                                <td>{network.name}</td>
                                <td>{network.createdBy.displayName}</td>
                                <td>{formatDate(network.createdAt, 'DD/MM/YYYY')}</td>
                                <td>
                                    <IconButton className="text-danger"
                                        onClick={() => handleDelete(network.id)}
                                        dataTestid="network-delete-button"
                                        icon={<Trash2 />}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {total > 10 ? (
                    <div className="border-top pt-2 px-2">
                        <Pagination total={total}
                            itemsPerPage={10}
                            offset={skip}
                            setOffset={setSkip}
                        />
                    </div>
                ) : null}
                {(!loading && !savedNetworks.length) ? <p className="p-2">No results</p> : null}
            </div>
            <Button onClick={closeModal}>Close</Button>
        </div>
    );
};
