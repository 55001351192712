import React, { Children, PropsWithChildren, ReactElement, useState } from 'react';
import { AccordionItemProps } from './AccordionItem';
import { AccordionView } from './AccordionView';

type AccordionProps = {
    id: string
}

export const Accordion = ({ id: accordionId, children }: PropsWithChildren<AccordionProps>) => {
    const [activeIndex, setActiveIndex] = useState('');
    const arrayChildren = Children.toArray(children) as ReactElement<PropsWithChildren<AccordionItemProps>>[];
    const toggle = (id: string) => {
        if (activeIndex !== id) return setActiveIndex(id);
        return setActiveIndex('');
    };

    return (
        <>
            {arrayChildren.map((element, index) => (
                <AccordionView key={`accordion-${accordionId}-item-${arrayChildren.length - index}`}
                    index={String(index)}
                    activeIndex={activeIndex}
                    toggle={toggle}
                    header={element.props.header}
                    iconOpen={element.props?.iconOpen}
                    iconClose={element.props?.iconClose}
                    useSeparator={element.props?.useSeparator}
                >
                    {element.props.children}
                </AccordionView>
            ))}
        </>
    );
};
