import React from 'react';
import { Button } from 'reactstrap';
import { InvestigationSuccess } from '../../../assets/svg/investigation-success';
import { useModal } from '../../../context/modal/ModalComponent';

export const FurtherInvestigationModal = () => {
    const { setModal, closeModal } = useModal();
    const requestFurtherInvestigationModal = () => {
        setModal({
            component:
    <>
        <InvestigationSuccess />
        <h2 className="mt-2">You have successfully raised a request for further investigation.</h2>
        <hr />
        <p>Your account manager will contact you with the next steps.</p>
        <hr />
        <Button onClick={() => closeModal()}>Close</Button>
    </>
        });
    };

    return (
        <div>
            <Button color="primary" className="mx-2" onClick={() => requestFurtherInvestigationModal()}>Request for further</Button>
        </div>

    );
};
