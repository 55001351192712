import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ExternalLink } from 'react-feather';
import { useQuery } from '@apollo/client';
import { ContentTableContentType } from '../Organisms/ContentTableView';
import { TextTooltip } from '../ToolTip/TextTooltip';
import { ExternalLinkButton } from '../LinkModal/ExternalLinkButton';
import { GET_CONTENT_LABELS, LabelDropdown } from '../../pages/Room/Labels';
import { getRoom } from '../../utils/variables';
import { useGetWatchlist } from '../../services/Threat/getWatchlist';
import { WatchlistButtonContainer } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { WATCHLIST_BUTTON_TYPES } from '../../pages/Room/Threats/Threat/WatchlistButton';
import { ContentActionsDropdown } from '../ContentTable/ContentActionsDropdown';
import { Content } from '../Organisms/ContentTableView/Content';
import { ContentFooter } from '../Organisms/ContentTableView/ContentFooter';
import { getAllLabels, ThreatLabels } from '../Organisms/ContentTableView/ThreatLabels';
import { ContextWrapper, Engagements, Keywords, ThreatSignals } from '../Organisms/ContentTableView/ContentTableContext';

type ActivityItemsProps = {
    content: ContentTableContentType,
    preview?: boolean,
    isCluster?: boolean
}

export const ActivityItems = ({ content, preview = false, isCluster = false }: ActivityItemsProps) => {
    const room = getRoom();
    const [textLimit, setTextLimit] = useState(200);
    const [keywordsExpanded, setKeywordsExpanded] = useState(false);
    const [engagementExpanded, setEngagementExpanded] = useState(false);
    const [signalsExpanded, setSignalsExpanded] = useState(false);
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;
    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const { data } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'network-only',
        skip: !(room?.instance?.plan?.others?.labels)
    });
    const allLabels = getAllLabels({
        contentId: content.id,
        userLabelsData: data?.getContentLabels || [],
        threatResolvers: content?.threatObject?.threatResolvers || []
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: any) => id === content.id).length > 0 : false;
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const handleTextLimitChange = (value: number) => {
        setTextLimit(value);
    };
    const handleMetricsClick = (type: string) => {
        switch (type) {
            case 'keywords':
                setKeywordsExpanded(!keywordsExpanded);
                setEngagementExpanded(false);
                setSignalsExpanded(false);
                break;
            case 'engagement':
                setKeywordsExpanded(false);
                setEngagementExpanded(!engagementExpanded);
                setSignalsExpanded(false);
                break;
            case 'signals':
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                setSignalsExpanded(!signalsExpanded);
                break;
            case 'closeAll':
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                setSignalsExpanded(false);
                break;
        }
    };

    return (
        <>
            <tr>
                <td className="p-0 align-top">
                    <Row className="p-0 m-0">
                        <Col className="p-0">
                            <Row className="mr-0">
                                <Col lg="8" className="p-0">
                                    <ThreatLabels content={content} />
                                </Col>
                                {!preview && !isCluster ? (
                                    <Col lg="4" className="p-0">
                                        <div className="d-flex justify-content-end align-items-center content-top-bar">
                                            {content.url && (
                                                <TextTooltip placement="top"
                                                    title="Open source"
                                                    id={`content-${content.id}-link`}
                                                    withIcon
                                                    className="mt-11 mr-11"
                                                >
                                                    <ExternalLinkButton url={content.url}>
                                                        <ExternalLink size={24} className="mr-1" />
                                                    </ExternalLinkButton>
                                                </TextTooltip>
                                            )}
                                            {!isCluster
                                    && (
                                        <TextTooltip placement="top"
                                            title="Labels"
                                            id={`content-${content.id}-labels`}
                                            withIcon
                                            className="mt-11"
                                        >
                                            <LabelDropdown contentId={content.id} roomId={room?.id} />
                                        </TextTooltip>
                                    )}
                                            {(content.threatObject?.threatLevel !== 'none' && watchlistEnabled) ? (
                                                <TextTooltip placement="top"
                                                    title={isWatching ? 'Remove to watchlist' : 'Add to watchlist'}
                                                    id={`content-${content.id}-watchlist`}
                                                    className="ml-2"
                                                    withIcon
                                                >
                                                    <WatchlistButtonContainer contents={[content]}
                                                        isWatching={isWatching}
                                                        onCompleted={refetchWatchlist}
                                                        type={WATCHLIST_BUTTON_TYPES.TEXT_BUTTON}
                                                        showText={false}
                                                    />
                                                </TextTooltip>
                                            ) : null }
                                            {(contentActionsEnabled) && (
                                                <TextTooltip placement="top"
                                                    title="Menu"
                                                    id={`content-${content.id}-menu`}
                                                    withIcon
                                                >
                                                    <ContentActionsDropdown content={content} />
                                                </TextTooltip>
                                            )}
                                        </div>
                                    </Col>
                                ) : null}
                            </Row>
                            <Row className={`mx-0 mb-2 ${preview ? 'mt-0' : ''} ${isCluster ? 'mt-1' : ''} 
                            ${allLabels.length === 0 && 'no-labels-header'}`}
                            >
                                <div className="col col-lg-11 p-0">
                                    <Content content={content}
                                        textLimit={textLimit}
                                        onReadMoreClick={() => handleTextLimitChange(999999)}
                                        onReadLessClick={() => handleTextLimitChange(200)}
                                        isOverview
                                    />
                                </div>
                                <div className="col col-lg-1 p-0" />
                            </Row>
                            <ContentFooter content={content}
                                onSentimentClick={() => null}
                                onKeywordsClick={() => handleMetricsClick('keywords')}
                                onEngagementClick={() => handleMetricsClick('engagement')}
                                isOverview
                                preview={preview}
                                onSignalClick={() => handleMetricsClick('signals')}
                            />
                        </Col>
                    </Row>
                    <hr className="m-0" />
                    {(engagementExpanded || keywordsExpanded || signalsExpanded) ? (
                        <div className="context-box p-2">
                            {(engagementExpanded) && (
                                <ContextWrapper onClose={() => handleMetricsClick('closeAll')}
                                    title="Engagement"
                                    tooltip="Our analysis of the engagement of this content so you can see how it is being engaged with."
                                >
                                    <Engagements content={content} />
                                </ContextWrapper>
                            )}
                            {(keywordsExpanded) && (
                                <ContextWrapper onClose={() => handleMetricsClick('closeAll')}
                                    title="Keywords mentioned"
                                    tooltip="Keywords that are mentioned within the content."
                                >
                                    <Keywords content={content} />
                                </ContextWrapper>
                            )}
                            {(signalsExpanded && threatSignalsEnabled) && (
                                <ContextWrapper onClose={() => handleMetricsClick('closeAll')}
                                    title="Threats"
                                    tooltip="Threats are AI generated classifiers we place on potentially harmful content which
                                    may originate
                     from a suspicious source. These can be sources that are known to publish biased, non-factual information
                     & display traits of inauthentic behaviour."
                                >
                                    <ThreatSignals content={content} />
                                </ContextWrapper>
                            )}
                        </div>
                    ) : null}
                </td>
            </tr>
            {!isCluster ? <hr className="mt-0 mb-3" /> : null}
        </>
    );
};
