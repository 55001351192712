import { scaleLinear } from 'd3';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getReportPages } from './index';

export const scaleH = scaleLinear().domain([0, 564]).range([0, 438]);

export const ExportAsPDF = () => {
    const { report } = useSelector(state => state.createReport);
    const [loading, setLoading] = useState(false);
    const pagesToExport = getReportPages(report);

    if (!report?.name || !report?.template) return null;

    const exportToPdf = () => {
        setLoading(true);
        const allPages = pagesToExport.map((page) => new Promise((res) => {
            const element = document.getElementById(`pdf-page-${page.id}`);
            html2canvas(element, { scale: 2.5 }).then((canvas) => {
                const imgData = canvas.toDataURL();
                res({ imgData, h: element?.clientHeight });
            });
        }));
        Promise.all(allPages).then((values) => {
            const pdf = jsPDF('l', 'mm', [776, 438], true);
            for (let i = 0; i < values.length; i++) {
                const { imgData, h } = values[i];
                const pageHeight = scaleH(h);
                if (i !== 0) pdf.addPage([776, pageHeight]);
                pdf.addImage(imgData, 'JPEG', 0, 0, 776, pageHeight);
            }
            pdf.save(`${report.name}.pdf`);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    };
    return (
        <Button color="secondary" className="mr-1" onClick={exportToPdf} disabled={loading}>
            {loading ? <div className="text-primary px-4"><Spinner size="sm" data-testid="export-report" /></div> : 'Export as PDF'}
        </Button>
    );
};
