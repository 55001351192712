import React from 'react';
import { Row, Col } from 'reactstrap';
import { Images } from '../../../utils/modeSwitch';
import { Accordion } from '../../../components/Organisms/Accordion';
import { AccordionItem } from '../../../components/Organisms/Accordion/AccordionItem';

export const EmptySituationRoomView = () => (
    <div className="bg-white rounded">
        <Row className="m-0">
            <Col className="p-6" xs={6} style={{ minHeight: 500 }}>
                <img src={Images().overview}
                    alt="setting-up-your-room"
                    className="maxw-350 maxh-300"
                />
                <h2 data-testid="empty-search-results" className="my-2">Setting up your room</h2>
                <p data-testid="processing-data">
                    Your situation room is being prepared, and the data will be available within 3-5 minutes.
                    Please check back shortly.
                </p>
            </Col>
            <Col className="border-left p-6" xs={6}>
                <h3 className="m-0">FAQs</h3>
                <hr className="my-3" />
                <Accordion id="settingRoomFaq">
                    <AccordionItem header="What is a threat and match?">
                        <p className="m-0 mt-2">
                            Matches are the content that is related to the keywords in your situation room.
                            Threats are content that may harm individuals, communities, or the overall
                            online environment, particularly on social media platforms.
                        </p>
                    </AccordionItem>
                    <AccordionItem header="How long does it take for new content to appear in a room?">
                        <p className="m-0 mt-2">
                            Enhancing data involves improving raw data to provide deeper insights for
                            analysis. This process includes tasks like threat analysis, entity
                            extraction, and sentiment analysis, allowing users to better understand the
                            content and identify relevant information. Enhanced data is made available
                            after completing these improvements, while raw data remains accessible for
                            immediate review.
                        </p>
                    </AccordionItem>
                    <AccordionItem header="What is enhancing data?">
                        <p className="m-0 mt-2">
                            New content will appear in a room within 3-5 minutes from the point we
                            ingest data from our sources. Additionally, we conduct a full ingest every
                            hour as standard practice to continuously train our AI models.
                        </p>
                    </AccordionItem>
                </Accordion>
            </Col>
        </Row>
    </div>
);
