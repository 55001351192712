import React, { useState } from 'react';
import { HighlightWithinTextarea, Selection } from 'react-highlight-within-textarea';

type BooleanInputProps = {
    booleanQuery?: string,
    onChange?: (e: string) => void,
    errorHighlights?: {id: string, highlight: any}[],
    disabled: boolean,
    placeholder: string,
    highlightErrorFocus?: string
}

export const BooleanInput = ({
    booleanQuery = '',
    onChange,
    errorHighlights = [],
    disabled,
    placeholder,
    highlightErrorFocus = ''
}: BooleanInputProps) => {
    const [{ selectionStart, selectionEnd }, setSelection] = useState({ selectionStart: 0, selectionEnd: 0 });
    const selection = booleanQuery.slice(selectionStart, selectionEnd) || null;
    const highlightsData = errorHighlights.map(({ id, highlight }: {id: string, highlight: any}) => ({
        highlight,
        className: highlightErrorFocus === id ? 'error-focus' : undefined
    }));
    const highlights = selection !== booleanQuery && selection?.match(/^[a-zA-Z0-9\s]*$/) ? highlightsData.concat([{
        highlight: new RegExp(`\\b${selection}\\b`, 'g'),
        className: 'selected'
    }]) : highlightsData;

    return (
        <HighlightWithinTextarea id="test"
            placeholder={placeholder || 'Add Boolean query'}
            ariaLabel="boolean query editor"
            type="textarea"
            className={`p-2 ${disabled && 'disabled-input'}`}
            rows={15}
            value={booleanQuery}
            highlight={disabled ? [] : highlights}
            readOnly={disabled}
            selection={(selectionStart && selectionEnd) ? new Selection(selectionStart, selectionEnd) : null}
            stripPastedStyles
            onChange={(val: string, { start, end }: {start: number, end: number}) => {
                if (disabled) return;
                const trimmedQuery = val?.replaceAll('\n', ' ')
                    .replaceAll(/[\u2018\u2019]/g, "'")
                    .replaceAll(/[\u201C\u201D]/g, '"');
                if (start === selectionStart && end === selectionEnd) {
                    setSelection({ selectionStart: 0, selectionEnd: 0 });
                } else {
                    setSelection({ selectionStart: start, selectionEnd: end });
                }
                onChange && onChange(trimmedQuery || '');
            }}
        />
    );
};
