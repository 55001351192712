import React, { useState } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { SelectAllCheckBox } from '../Form/CheckBox';

export const CheckboxFilter = ({ title, filters, values, setValues, value, disabledOptions = [], disabled = false, subLevel = [] }) => {
    const [id] = useState(uuid());
    return (
        <SelectAllCheckBox id={`checkbox-${title}-${id}`}
            options={filters?.map(({ key }) => key)}
            values={values}
            selectAllReversed
            allCheck={(data) => setValues(data.map(item => disabledOptions.indexOf(item) === -1 && item).filter(item => item))}
            disabledOptions={disabledOptions}
            disabled={disabled}
        >
            {filters?.map((item, index) => (
                <FormGroup key={item.key} className={index === filters.length - 1 ? 'mb-0' : ''}>
                    <CustomInput type="checkbox"
                        checked={values.indexOf(item.key) === -1}
                        className={`custom-control-input-big${item.key.length > 19 ? ' word-short' : ''} 
                        ${subLevel.includes(item.label) ? 'ml-1' : ''}`}
                        label={item.label}
                        id={`filter-checkbox-${item.key}-${id}`}
                        data-testid={`checkbox-${item.key}`}
                        value={value}
                        onChange={({ target: { checked } }) => {
                            /*
                                 * Because this is "Filter" functionality when the user "unselects" the filter on the screen then
                                 *      the value is added to the filter "collection" and used in the query
                                 */
                            if (disabledOptions && disabledOptions.indexOf(item.key) !== -1) {
                                if (!checked) {
                                    setValues([item.key]);
                                } else {
                                    setValues([]);
                                }
                                return;
                            }

                            if (!checked) {
                                setValues([...values, item.key]);
                            } else {
                                setValues(values.filter(a => a !== item.key));
                            }
                        }}
                        disabled={disabled}
                    />
                </FormGroup>
            ))}
        </SelectAllCheckBox>
    );
};
