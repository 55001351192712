import * as React from 'react';

export const Spinner = () => (
    <svg xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        preserveAspectRatio="xMidYMid"
        style={{
            shapeRendering: 'auto',
            background: '0 0',
        }}
        viewBox="25 25 50 50"
    >
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
        >
            <animate attributeName="opacity"
                begin="-0.9411764705882353s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(21.176 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.8823529411764706s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(42.353 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.8235294117647058s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(63.53 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.7647058823529411s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(84.706 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.7058823529411765s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(105.882 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.6470588235294118s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(127.059 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.5882352941176471s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(148.235 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.5294117647058824s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(169.412 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.47058823529411764s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(190.588 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.4117647058823529s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(211.765 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.35294117647058826s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(232.941 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.29411764705882354s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(254.118 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.23529411764705882s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(275.294 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.17647058823529413s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(296.47 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.11764705882352941s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(317.647 50 50)"
        >
            <animate attributeName="opacity"
                begin="-0.058823529411764705s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
        <rect width={5}
            height={11}
            x={47.5}
            y={24.5}
            fill="#006ff9"
            rx={2.5}
            ry={3.52}
            transform="rotate(338.824 50 50)"
        >
            <animate attributeName="opacity"
                begin="0s"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                values="1;0"
            />
        </rect>
    </svg>
);
