import { FROM_TAG_SOURCES } from '../../BooleanValidationErrors';

export const getTagData = (tagFragments, queryWithoutStringLiterals) => {
    const tagKey = tagFragments[0] || '';
    const tagValue = [...tagFragments.slice(1, tagFragments.length)].join(':').replaceAll('.', '\\.').replaceAll('?', '\\?') || '';

    let tagSnapshots;
    // Source tag ie. mentions:someone:twitter
    if (tagFragments.length > 2 && FROM_TAG_SOURCES.includes(tagFragments[tagFragments.length - 1])) {
        tagSnapshots = [...queryWithoutStringLiterals.matchAll(
            new RegExp(`${tagKey}\\s*:\\s*\\"?${tagFragments[1]}\\"?\\s*:\\s*${tagFragments[2]}(?=\\s|\\)|$)`, 'g')
        )];
    // All other tags with a simple key:value pair
    } else {
        tagSnapshots = [...queryWithoutStringLiterals.matchAll(
            new RegExp(`${tagKey}\\s*:\\s*\\"?${tagValue}\\"?(?=\\s|\\)|$)`, 'g')
        )];
    }

    return tagSnapshots.map((match) => {
        const tagSnapshot = match[0].trimEnd();
        const tagSnapshotData = tagSnapshot.split(':');
        const tagSnapshotKey = tagSnapshotData[0] || '';
        const tagSnapshotValue = [...tagSnapshotData.slice(1, tagSnapshotData.length)]?.join(':')
            .replaceAll('.', '\\.').replaceAll('?', '\\?') || '';
        return { tagKey, tagValue, tagSnapshot, tagSnapshotKey, tagSnapshotValue, tagSource: tagSnapshotValue.split(':')[1] };
    });
};
