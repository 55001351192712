import React from 'react';
import { Col, Row } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ConfigurationForm } from './ConfigurationForm';
import { EntitlementsForm } from './EntitlementsForm';
import { useInstance } from './InstanceContext';
import { InstanceFormFooter } from './InstanceFormFooter';
import { SaveInstanceModal } from './SaveInstanceModal';
import { SummaryForm } from './SummaryForm';

export const InstanceForm = () => {
    const { setModal } = useModal();
    const { instance, setError, saveInstance, updateId } = useInstance();
    const handleSave = (e) => {
        e.preventDefault();
        setModal({
            component: <SaveInstanceModal instance={instance}
                setError={setError}
                onCompleted={saveInstance}
                updateId={updateId}
            />
        });
    };
    return (
        <form onSubmit={handleSave}>
            <Row className="bg-white m-0">
                <Col xs={12} lg={6} className="border-right px-3 pt-3 pb-0">
                    <SummaryForm />
                </Col>
                <Col xs={12} lg={6} className="p-0">
                    <div className="p-3 border-bottom">
                        <ConfigurationForm />
                    </div>
                    <div className="p-3">
                        <EntitlementsForm />
                    </div>
                </Col>
            </Row>
            <InstanceFormFooter />
        </form>
    );
};
