import React, { useState } from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../utils/useDropdown';
import { Loading } from '../../Loading';
import { color } from '../../../utils/getColors';

type DropdownWithSearchProps = {
    name: string,
    placeholder?: string
    items: {
        id: string,
        name: string
    }[],
    handleSelectItem: (id: string) => void,
    loading: boolean,
    emptyStateText?: string
};

export const DropdownWithSearch = ({ name, placeholder, items, handleSelectItem, loading,
    emptyStateText }: DropdownWithSearchProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');
    const displayItems = searchValue ? items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : items;
    const finalText = searchValue || !emptyStateText ? 'No results found' : emptyStateText;

    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
        >
            <DropdownToggle className="dropdown-filter d-flex justify-content-between align-items-center cursor-pointer px-2 py-1"
                tag="div"
                data-testid="dropdown-toggle-select"
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className="dropdown-filter-menu">
                {items.length > 0 && (
                    <div className="p-2 border-bottom">
                        <Input value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            placeholder={placeholder || 'Search'}
                            data-testid="dropdown-search-input"
                        />
                    </div>
                )}
                <div className="dropdown-filter-menu-items scrollbar-small">
                    {(displayItems.length > 0 && !loading) && displayItems.map(item => (
                        <DropdownItem key={item.id}
                            onClick={() => handleSelectItem(item.id)}
                            className="truncate-this mw-100 pl-1"
                            data-testid={item.id}
                        >
                            {item.name}
                        </DropdownItem>
                    ))}
                    {loading && <Loading relative height={168} width={255} />}
                    {(!displayItems.length && !loading) && <p className="no-white-space">{finalText}</p>}
                </div>
            </DropdownMenu>
        </Dropdown>
    ); };
