import React from 'react';
import { FormGroup, Label, Input, InputGroup } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { TooltipItem } from '../ToolTip';

export const Details = ({ details, setDetails }) => (
    <FormGroup className="mb-2">
        <Label className="mt-1">
            <strong className="pr-1">Details</strong>
            <TooltipItem id="DetailsTrigger"
                content={(
                    <div>
                        <p>Why is this content concerning?</p>
                        <p>
                            What disinformation themes or narratives this threat belongs to? (prompt to supply keywords)
                        </p>
                        <p>
                            Please note here any other information you think might be useful,
                            including specific information which would be helpful,
                            what countermeasures you think might be appropriate and links
                            to ongoing threats or investigations.
                        </p>
                        <p className="mb-0">Associated users/IDs</p>
                    </div>
                )}
                Icon={FeatherIcon.Info}
            />
        </Label>
        <InputGroup border={details ? 'active' : 'none'}>
            <Input value={details}
                onChange={(e) => setDetails(e.target.value)}
                type="textarea"
                placeholder="Please add details..."
                required
                data-testid="details-input"
            />
        </InputGroup>
    </FormGroup>
);
