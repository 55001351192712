import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

export const AddUserForm = ({ handleAddUser, email, setEmail, emailLoading }) => (
    <Form onSubmit={handleAddUser}>
        <FormGroup>
            <Label>Enter email address</Label>
            <div className="d-flex pt-1">
                <Input className="form-control mr-2"
                    cols="10"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    data-testid="email-input"
                />
                <Button type="submit" disabled={emailLoading} data-testid="add-user-button">
                    Add
                </Button>
            </div>
        </FormGroup>
    </Form>
);
