import React, { useMemo } from 'react';
import { SourcesList } from './SourcesList';
import { TotalMatchesAndThreatsOverTime } from './TotalMatchesAndThreatsOverTime';
import { CurrentSentiment } from './Pie/CurrentSentiment';
import { Wordcloud } from './Wordcloud';
import { TermAnalysis } from './TermAnalysis';
import { Matches } from '../Matches';
import { TopLocationMentions } from './Table/TopLocationMentions';
import { TopSources } from './Table/TopSources';
import { TopLanguages } from './Table/TopLanguages';
import { TopPublishers } from './Table/TopPublishers';
import { TopSharedURLs } from './Table/TopSharedURLs';
import { getRoom } from '../../../../utils/variables';
import { useCountOfContentAwatingEnhancement } from '../../../../services/Overview/countOfContentAwatingEnhancement';
import { useTotalMatches } from '../../../../services/Overview/totalMatches';

type VisualisationProps = {
    type: string;
    id: string;
};

export const Visualisation = ({ type, id }: VisualisationProps) => {
    const { awaitingEnhancementCount } = useCountOfContentAwatingEnhancement();
    const { totalMatches: totalMatchesWithoutFilters } = useTotalMatches({}, {}, false);
    const enhancementsCompleteCount = totalMatchesWithoutFilters - awaitingEnhancementCount;

    const room = getRoom();
    const newEtlEnabled = room.instance?.plan?.others?.newEtl;
    const noAnnotations = newEtlEnabled && enhancementsCompleteCount === 0;

    const ret = useMemo(() => {
        if (id === 'sourcesList') {
            return <SourcesList />;
        }
        if (id === 'totalMatchesAndThreatsOverTime') {
            return <TotalMatchesAndThreatsOverTime noAnnotations={noAnnotations} />;
        }
        if (id === 'currentSentiment') {
            return <CurrentSentiment noAnnotations={noAnnotations} />;
        }
        if (id === 'topSharedUrls') {
            return <TopSharedURLs noAnnotations={noAnnotations} />;
        }
        if (id === 'topPublishers') {
            return <TopPublishers noAnnotations={noAnnotations} />;
        }
        if (id === 'topSources') {
            return <TopSources noAnnotations={noAnnotations} />;
        }
        if (id === 'locationMentions') {
            return <TopLocationMentions noAnnotations={noAnnotations} />;
        }
        if (id === 'topLanguages') {
            return <TopLanguages noAnnotations={noAnnotations} />;
        }
        if (type === 'wordcloud') {
            return <Wordcloud noAnnotations={noAnnotations} />;
        }
        if (type === 'termAnalysis') {
            return <TermAnalysis noAnnotations={noAnnotations} />;
        }
        if (type === 'matches') {
            return <Matches />;
        }
    }, [id, type, noAnnotations]);
    return (
        <div className="h-100">
            {ret}
        </div>
    );
};
