import { gql, useQuery } from '@apollo/client';

export const useAnalyseArticle = ({ url, skip }) => {
    const { loading, data } = useQuery(ANALYSE_ARTICLE, {
        variables: {
            url
        },
        skip
    });
    const ret = data?.analyseArticle || [];
    return {
        loading,
        data: ret
    };
};

const ANALYSE_ARTICLE = gql`
    query analyseArticle($url: String) {
        analyseArticle(url: $url) {
            claim
            entities {
                value
                type
            }
        }
    }
`;
