import moment from 'moment';
import React from 'react';
import { DateRangePicker } from '../../../../components/DateRangePicker';
import { RadioInput } from '../../../../components/Atoms';
import { convertToDate, formatDate } from '../../../../utils/getTimeFrom';
import { useInstance } from './InstanceContext';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { MultiSelectDropdown } from '../../../../components/Form/MultiSelectDropdown';

const priorityOptions = [
    { id: 'isPriorityX', text: 'X' },
    { id: 'isPriorityMeta', text: 'Facebook' }
];

export const ConfigurationForm = () => {
    const { instance, handleChange, setInstance } = useInstance();
    const { user } = useAdminUser();
    return (
        <div>
            <h3 className="m-0">Configuration</h3>
            <hr className="my-3" />
            <p><strong>Trial</strong></p>
            <div className="d-flex align-items-center">
                <RadioInput id="isTrialYes"
                    data-testid="isTrialYes"
                    name="plan"
                    checked={!!instance.plan.isTrial}
                    onChange={(checked) => handleChange(checked, 'plan.isTrial')}
                    label="Yes"
                    disabled={user.role !== 'ADMIN'}
                />
                <RadioInput id="isTrialNo"
                    data-testid="isTrialNo"
                    name="plan"
                    checked={!instance.plan.isTrial}
                    onChange={(checked) => handleChange(!checked, 'plan.isTrial')}
                    label="No"
                    disabled={user.role !== 'ADMIN'}
                />
            </div>
            <p className="mt-2"><strong>Limited &ldquo;Team&rdquo; permissions</strong></p>
            <div className="d-flex align-items-center">
                <RadioInput id="restrictedTeamRoleYes"
                    name="restrictedTeamRole"
                    checked={!!instance.plan.restrictedTeamRole}
                    onChange={(checked) => handleChange(checked, 'plan.restrictedTeamRole')}
                    label="Yes"
                    disabled={user.role !== 'ADMIN'}
                />
                <RadioInput id="restrictedTeamRoleNo"
                    name="restrictedTeamRole"
                    checked={!instance.plan.restrictedTeamRole}
                    onChange={(checked) => handleChange(!checked, 'plan.restrictedTeamRole')}
                    label="No"
                    disabled={user.role !== 'ADMIN'}
                />
            </div>
            <p className="mt-2"><strong>Priority</strong></p>
            <MultiSelectDropdown id="priorityOptions"
                options={priorityOptions}
                defaultOptions={[...priorityOptions].filter(option => instance.plan[option.id])}
                onChange={(values) => {
                    const ids = values.map(({ id }) => id);
                    setInstance({
                        ...instance,
                        plan: {
                            ...instance.plan,
                            isPriorityX: ids.includes('isPriorityX'),
                            isPriorityMeta: ids.includes('isPriorityMeta')
                        }
                    });
                }}
            />
            <hr className="my-3" />
            <p><strong>Access date</strong></p>
            <DateRangePicker inputRange={`${formatDate(instance.plan.validFrom)} - ${formatDate(instance.plan.validUntil)}`}
                onDatePickerCallback={(startDate, endDate) => {
                    if (user.role === 'ADMIN') {
                        setInstance({
                            ...instance,
                            plan: {
                                ...instance.plan,
                                validFrom: startDate.valueOf(),
                                validUntil: endDate.valueOf()
                            }
                        });
                    }
                }}
                config={{
                    append: true,
                    noMaxDate: true,
                    defaultDateRange: {
                        startDate: convertToDate(instance.plan.validFrom),
                        endDate: convertToDate(instance.plan.validUntil),
                    },
                    ranges: {
                        Today: [moment().toDate(), moment().toDate()],
                        'Next Month': [
                            moment().toDate(),
                            moment().add(1, 'month')
                        ],
                    },
                }}
            />
        </div>
    );
};
