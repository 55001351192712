import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { BooleanInputValidated } from '../../Molecules/BooleanInputValidated';

type BooleanSearchContentProps ={
    booleanQuery: string,
    setBooleanQuery: (e: string) => void,
    setInvalidQuery: (e: boolean) => void,
    validationErrors: any,
    setValidationErrors: (e: any) => void,
    disabled: boolean,
    emptyQuery: boolean,
    edit?: boolean
}

export const BooleanSearchContent = ({
    booleanQuery,
    setBooleanQuery,
    setInvalidQuery,
    validationErrors,
    setValidationErrors,
    disabled, emptyQuery = false, edit = false
}: BooleanSearchContentProps) => (
    <Container fluid>
        <Row>
            <Col className="p-0">
                <p className="mb-3">Formulate your search query using Boolean operators for even more relevant and precise results.
                    <br /> Before starting, check the&nbsp;
                    <a target="_blank"
                        href="https://logically-intelligence.readme.io/docs/new-writing-a-boolean-query"
                        rel="noreferrer"
                        className="link-zindex-1"
                    >
                        best practices for writing a boolean query.
                    </a>
                </p>
            </Col>
        </Row>
        <Row className={`boolean-search-content ${edit && 'mb-5'}`} data-testid="boolean-search-content">
            <BooleanInputValidated booleanQuery={booleanQuery}
                setBooleanQuery={setBooleanQuery}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                resetGlobalErrors={() => { setInvalidQuery(false); }}
                disabled={disabled}
                emptyQuery={emptyQuery}
            />
        </Row>
    </Container>
);
