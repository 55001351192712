import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { allCtypesMongo, getRoom, languagesList } from '../../utils/variables';
import { allTypes as allThreatTypes, sentiments } from '../../utils/lib/search';

export const useContentFilters = ({ defaultFilters = {}, useGlobalFilters = true, watchlist = false } = {}) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const location = useLocation();
    const filtersCopy = useSelector((state) => (watchlist ? state.watchlistFilters.filters : state.filters.filters));
    const filters = JSON.parse(JSON.stringify(filtersCopy));
    delete filters?.labels;
    const sourcesFilter = filters?.sourcesWithDomains?.map(source => source.key) || [];
    const domainsFilter = filters?.sourcesWithDomains?.map(domain => domain.domains || '').flat().filter(dom => dom) || [];
    const searchFilters = JSON.parse(JSON.stringify(defaultFilters));

    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;

    const { pathname } = useLocation();
    const isRoomPreview = pathname?.includes('create-room') || pathname?.includes('edit-room');

    if (useGlobalFilters && !isRoomPreview) {
        if (filters.query.length && !searchFilters?.search) searchFilters.search = filters.query;
        if (filters.dateRange?.startDate && filters.dateRange?.endDate && !searchFilters?.date) {
            searchFilters.date = { ...filters.dateRange };
        }
        if (domainsFilter.length && !searchFilters?.domains) searchFilters.domains = domainsFilter;
        if (filters.origin?.length && !searchFilters?.locations) searchFilters.locations = filters.origin;

        searchFilters.contentType = getSupportedCTypesFilters({
            sourcesFilter,
            providedFilters: searchFilters?.contentType
        });
        searchFilters.sentiment = getSupportedSentimentFilters({
            filtersSentiment: filters?.sentiment,
            providedSentiment: searchFilters?.sentiment,
            betaFiltersEnabled
        });
        searchFilters.threatLabels = getSupportedThreatTypesFilters({
            filtersThreatTypes: filters?.threatTypes,
            providedThreatTypes: searchFilters?.threatLabels,
            betaFiltersEnabled,
            location,
            disabledThreatTypes
        });
        searchFilters.threatResolvers = getSupportedThreatTypesFilters({
            filtersThreatTypes: filters?.threatTypes,
            providedThreatTypes: searchFilters?.threatLabels,
            isResolver: true,
            betaFiltersEnabled,
            location,
            disabledThreatTypes
        });
        searchFilters.languages = getSupportedLanguagesFilters({
            filtersLanguages: filters?.languages,
            providedLanguages: searchFilters?.languages,
            betaFiltersEnabled
        });
        if (filters.from?.length) {
            searchFilters.from = filters.from;
        }
        if (filters.keywords?.length) {
            searchFilters.keywords = filters.keywords;
        }
        if (filters.ids?.length) {
            searchFilters.ids = filters.ids;
        }
        if (filters.entities?.length) {
            searchFilters.entities = filters.entities;
        }
        if (filters.mentions?.length) {
            searchFilters.mentions = filters.mentions;
        }
        if (filters.hashtags?.length) {
            searchFilters.hashtags = filters.hashtags;
        }
    }

    const ret = {
        ...((room?.project_id && !isRoomPreview) && { projectId: room.project_id }),
        filters: searchFilters
    };

    if (useGlobalFilters && filters.dateRange?.startDate && filters.dateRange?.endDate && !searchFilters?.date) {
        searchFilters.date = { ...filters.dateRange };
    }

    if (!searchFilters?.date?.endDate && room.contentVisibilityRestriction) {
        if (!searchFilters?.date) searchFilters.date = {};
        searchFilters.date.endDate = moment(moment().subtract(room.contentVisibilityRestriction, 'days').startOf('day').toDate()).unix();
    }

    if (useGlobalFilters && filters.convertedBooleanQuery && !watchlist) {
        ret.dslQuery = filters.convertedBooleanQuery;
    }

    return ret;
};

const getSupportedCTypesFilters = ({ sourcesFilter = [], providedFilters = [] }) => {
    const allTypes = allCtypesMongo().map((type) => type.key);

    if (providedFilters.length) {
        return allTypes.filter((type) => providedFilters.includes(type));
    }
    if (sourcesFilter.length) {
        return allTypes.filter((type) => sourcesFilter.includes(type));
    }

    return undefined;
};

const getSupportedSentimentFilters = ({ filtersSentiment = [], providedSentiment = [], betaFiltersEnabled }) => {
    const allSentiment = sentiments.map((sentiment) => sentiment.key);

    if (providedSentiment.length) {
        return allSentiment.filter((sentiment) => providedSentiment.includes(sentiment));
    }
    if (filtersSentiment.length) {
        return allSentiment.filter((sentiment) => {
            if (betaFiltersEnabled) {
                return filtersSentiment.includes(sentiment);
            }
            return !filtersSentiment.includes(sentiment);
        });
    }

    return undefined;
};

const getSupportedThreatTypesFilters = ({ filtersThreatTypes = [], providedThreatTypes = [], isResolver = false, betaFiltersEnabled,
    location, disabledThreatTypes }) => {
    let allThreatLabels = [];
    if (isResolver) {
        allThreatLabels = allThreatTypes.filter(item => (item.isResolver
             && !disabledThreatTypes.includes(item.key))).map((type) => type.key);
    } else {
        allThreatLabels = allThreatTypes.filter(item => !item.isResolver
             && !disabledThreatTypes.includes(item.key)).map((type) => type.key);
    }
    let ret;

    if (providedThreatTypes.length) {
        ret = allThreatLabels.filter((type) => providedThreatTypes.includes(type));
    }
    if (filtersThreatTypes.length) {
        ret = allThreatLabels.filter((type) => {
            if (betaFiltersEnabled) {
                return filtersThreatTypes.includes(type);
            }
            return !filtersThreatTypes.includes(type); });
    }
    if (location?.pathname?.includes('threats') || location?.pathname?.includes('watchlist') || location?.pathname?.includes('networks')) {
        return ret?.length ? ret : undefined;
    }
    return undefined;
};

const getSupportedLanguagesFilters = ({ filtersLanguages = [], providedLanguages = [], betaFiltersEnabled }) => {
    const allLanguages = languagesList();

    if (providedLanguages.length) {
        return allLanguages.filter(lang => providedLanguages.includes(lang));
    }
    if (filtersLanguages.length) {
        return allLanguages.filter(lang => {
            if (betaFiltersEnabled) {
                return filtersLanguages.includes(lang);
            }
            return !filtersLanguages.includes(lang);
        });
    }

    return undefined;
};
