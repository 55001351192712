import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTopPublishers = ({ dateFilter = {}, size = 10 } = {}) => {
    const defaultFilters = (dateFilter?.startDate || dateFilter?.endDate) ? { date: dateFilter } : {};
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters });
    const ret = useQuery(TOP_PUBLISHERS, {
        variables: {
            ...filters,
            size
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.topPublishers?.data?.map(item => ({
            ...item,
            key: item.publisher
        })) || [] : [],
        total: ret.data?.topPublishers?.total || 0
    };
};

export const TOP_PUBLISHERS = gql`
    query topPublishers(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topPublishers(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        ) {
            total
            data {
                publisher
                count
                threats
            }
        }
    }
`;
