import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownWithMultiCheckbox } from '../components/DropdownWIthMultiCheckbox';
import { sentiments } from '../../../utils/lib/search';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';

export const SentimentFilter = () => {
    const dispatch = useDispatch();
    const { sentiment: selectedSentiment }: { sentiment: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const sentimentData = sentiments.map(({ label, key }: {label: string, key: string}) => ({
        id: key,
        name: label,
        selected: selectedSentiment.includes(key)
    }));
    const handleSelectItem = (ids: string[]) => {
        dispatch(saveSelectedFilters({
            sentiment: ids
        }));
    };

    const countFilters = selectedSentiment.length;

    return (
        <div>
            <DropdownWithMultiCheckbox value="sentiment"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                filterOptions={sentimentData}
                handleSelectItem={handleSelectItem}
                singleDropdown
            />
        </div>
    ); };
