import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useUpdateThreatSignals } from './useUpdateThreatSignals';
import { AddedSignal } from './ManageThreatsModal';
import { allTypes } from '../../../../utils/lib/search';

type RemoveAllThreatsModalProps = {
    clearSelection: () => void,
    addedSignals: AddedSignal[],
    removedSignals: string[],
    contentIds?: string[],
    projectId: string,
    removeContentIds?: string[]
}

export const RemoveAllThreatsModal = ({ clearSelection, addedSignals, removedSignals, contentIds,
    projectId, removeContentIds = [] }: RemoveAllThreatsModalProps) => {
    const { openPreviousModal } = useModal();
    const { updateThreatSignals, loading } = useUpdateThreatSignals({ clearSelection,
        remove: true,
        ids: removeContentIds.length > 0 ? removeContentIds : contentIds });
    const handleRemove = () => {
        updateThreatSignals({
            variables: {
                projectId,
                contentIds,
                addedThreatSignals: addedSignals,
                removedThreatSignals: removedSignals
            }
        });
    };
    const renderSignalElement = () => removedSignals.map((a, index) => {
        const filteredItem = allTypes.filter((b) => b.key === a);
        if (index > 0 && (index === removedSignals.length - 1)) {
            return <span> & {filteredItem[0].name}</span>;
        } if (index > 0) {
            return <span>, {filteredItem[0].name}</span>;
        }
        return <span>{filteredItem[0].name}</span>;
    });
    return (
        <>
            {removeContentIds.length === 0 ? (
                <p className="mb-2">
                    Removing all threats will mark this content as ‘non-threatening’, which will
                    prevent you from being able to add these content(s) to watchlist, request a fact check & request an investigation.
                </p>
            ) : (
                <p>
                    Removing {renderSignalElement()} will remove all threats off
                    <strong> {removeContentIds.length > 1
                        ? `${removeContentIds.length} contents` : `${removeContentIds.length} content`}
                    </strong> marking {removeContentIds.length > 1
                        ? 'these contents' : 'this content'} ‘non-threatening’.
                    This will prevent you from being able to see this content in the threats page, add these contents to watchlist, request
                    a fact check & request an investigation.
                </p>
            )}
            <hr />
            <div className="mt-3">
                <Button color="secondary" className="mr-2" disabled={loading} onClick={() => openPreviousModal()}>Back</Button>
                <Button color="danger" onClick={() => handleRemove()} disabled={loading}>{loading
                    ? <Spinner className="ml-1" size="sm" color="white" /> : 'Remove threats'}
                </Button>
            </div>
        </>
    );
};
