import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { useGetActiveQueries } from '../../../services/getActiveQueries';
import { getToast } from '../../../utils/getToast';

type DeleteFilterProps = {
    id: string,
    clusters: boolean,
    openEditSavedItem?: boolean,
    setOpenEditSavedItem?: (e: boolean) => void
}

export const DeleteFilter = ({ id, clusters = false, openEditSavedItem = false, setOpenEditSavedItem }: DeleteFilterProps) => {
    const { closeModal } = useModal();
    const toast = getToast();
    const refetchQueries = useGetActiveQueries(['getRoomFilters']);
    const [deleteRoomFilter, { loading }] = useMutation(DELETE_ROOM_FILTER, {
        refetchQueries,
        onCompleted: () => {
            if (setOpenEditSavedItem) {
                setOpenEditSavedItem(false);
            }
            closeModal();
            toast.success('Filters deleted successfully');
        }
    });
    const handleDeleteRoomFilter = () => {
        deleteRoomFilter({
            variables: {
                id,
                text: clusters ? 'clusters' : 'overview'
            }
        });
    };
    return (
        <>
            <h2>Delete room filter</h2>
            <hr />
            <p>Are you sure want to delete saved filter?</p>
            <hr />
            <div className="d-flex">
                <Button color="secondary mr-2" onClick={closeModal} disabled={loading}>Cancel</Button>
                <Button color="danger" onClick={() => handleDeleteRoomFilter()} disabled={loading}>Delete{loading
                    && <Spinner className="ml-1" size="sm" color="white" />}
                </Button>
            </div>
        </>
    );
};
const DELETE_ROOM_FILTER = gql`
    mutation deleteRoomFilter( $id: ID, $text: String) {
        deleteRoomFilter(id: $id, text: $text) {
            id
        }
    }
`;
