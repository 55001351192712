import React from 'react';
import { Label } from 'reactstrap';
import { MultiSelectDropdown } from '../../../components/Form/MultiSelectDropdown';

export const FilterByStatus = ({ onChange }) => {
    const options = [
        { id: 'active', text: 'Active' },
        { id: 'archived', text: 'Archived' },
        { id: 'deleted', text: 'Deleted' },
        { id: 'paused', text: 'Paused' }
    ];
    return (
        <div className="d-flex align-items-center ml-2 ">
            <Label className="mr-1">Filter by status</Label>
            <MultiSelectDropdown options={options}
                defaultOptions={options.filter(option => option.id !== 'deleted')}
                id="filter-by-status"
                onChange={onChange}
            />
        </div>
    );
};
