import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Images } from '../../utils/modeSwitch';
import { resetFilters, resetSelectedFilters } from '../../pages/Room/store';
import { getRoom } from '../../utils/variables';

type EmptySearchResultsProps = {
    subtitle?: string;
    mainHeading?: string;
    isSubtitleDisplayed?: boolean;
    filters?: boolean;
}

export const EmptySearchResults = ({ subtitle, mainHeading, isSubtitleDisplayed = true, filters = false }: EmptySearchResultsProps) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const handleOnClick = () => {
        dispatch(resetFilters({ roomId: room?.id }));
        dispatch(resetSelectedFilters());
    };

    return (
        <div className="py-10 bg-white px-2 mt-5">
            <Row className="text-center align-items-center">
                <Col xs={12}>
                    <img src={filters ? Images().noResults : Images().overview}
                        alt="no-data-found"
                        className="maxw-350 maxh-300"
                    />
                </Col>
                <Col xs={12}>
                    <h2 data-testid="empty-search-results" className="my-2">{mainHeading || "There's nothing to see here just yet"}</h2>
                    {isSubtitleDisplayed && (
                        <p data-testid="processing-data">
                            {subtitle || 'We are still processing the data, check back later.'}
                        </p>
                    )}
                    {filters && <Button className="mt-1" onClick={handleOnClick}>Clear filters and search</Button>}
                </Col>
            </Row>
        </div>
    ); };
