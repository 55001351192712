import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Loading } from '../../../components/Loading/index';
import { SearchFeedback } from '../../../components/SearchFeedback';
import { EmptySearchResults } from '../../../components/EmptySearchResults';
import { Layout } from './Layout';
import { OverviewEditBar } from './OverviewEditBar';
import { RemovedWidgets } from './RemovedWidgets';
import { CONTENT_TYPE } from '../../../utils/contentTypes';
import { useTotalMatches } from '../../../services/Overview/totalMatches';
import { SearchBarNew } from '../../../components/NewFilters';
import { SearchBar } from '../../../components/Search';
import { getRoom } from '../../../utils/variables';
import { initialState, saveFilters } from '../store';
import { EmptySituationRoomView } from './EmptySituationRoomView';
import { setLayoutBackup } from './store';
import { standardTemplate } from './Templates/Standard';

export const Overview = ({ bread, data }) => {
    const dispatch = useDispatch();
    const { loading: roomOverviewLoading } = useQuery(GET_ROOM_OVERVIEW, {
        variables: {
            roomId: data.situationRoom.id
        },
        onCompleted: (overview) => {
            dispatch(setLayoutBackup(overview?.getRoomOverview || standardTemplate));
        }
    });
    const { totalMatches, loading: totalMatchesLoading } = useTotalMatches();
    const { totalMatches: totalMatchesWithoutFilters, loading: totalMatchesLoadingB } = useTotalMatches({}, {}, false);

    if (roomOverviewLoading || totalMatchesLoading || totalMatchesLoadingB) {
        return <Loading gif height="100vh" pointer={false} />;
    }
    return (
        <div className="page-padding">
            <OverviewInner bread={bread} totalMatches={totalMatches} totalMatchesWithoutFilters={totalMatchesWithoutFilters} />
        </div>
    );
};

export const GET_ROOM_OVERVIEW = gql`
    query getRoomOverview($roomId: ID!) {
        getRoomOverview(roomId: $roomId) {
            situationRoom
            data {
                id
                type
                x
                y
                w
                h
                minH
                minW
                maxH
                maxW
                data {
                    type
                    size
                    title
                    columns
                    items {
                        type
                        id
                        title
                    }
                    percentage
                    fields
                }
                removed
                dateRemoved
            }
        }
    }
`;

const OverviewInner = ({ bread, totalMatches, error, totalMatchesWithoutFilters }) => {
    const { edit, hasChanges } = useSelector((state) => state.overview);
    const dispatch = useDispatch();
    const parseFilters = sessionStorage.getItem('initialFilters');
    const initialFilters = parseFilters ? JSON.parse(parseFilters) : null;
    const room = getRoom();

    if (initialFilters) {
        dispatch(saveFilters({ ...initialFilters, roomId: room?.id }));
        sessionStorage.removeItem('initialFilters');
    }
    const filters = useSelector((state) => state.filters.filters);
    const hasFilters = filters.origin?.length > 0
      || filters.sentiment?.length > 0
      || filters.sources?.length > 0
      || filters.dateRange?.startDate !== initialState.filters.dateRange.startDate
      || filters.query?.length > 0;

    const settingUpRoom = new Date().getTime() < room.updatedAt + (2 * 60 * 60 * 1000); // 2 hours

    if (settingUpRoom && !totalMatchesWithoutFilters && !hasFilters && !filters.convertedBooleanQuery?.boosting?.positive?.bool) {
        return <EmptySituationRoomView />;
    }

    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;

    return (
        <div>
            <Prompt when={edit && hasChanges} message="Are you sure want to exit the screen? Changes you made will not be saved." />
            {edit && (
                <>
                    <OverviewEditBar />
                    <div className="overview-spacer" />
                    <RemovedWidgets />
                </>
            )}
            <div className="d-flex justify-content-between align-items-start">
                {bread}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-0 mb-2">
                <h2>Overview</h2>
                <SearchFeedback totalCount={totalMatches} error={error} contentType={CONTENT_TYPE.MATCH} />
            </div>
            <div className={edit ? 'pointer-events-none' : ''}>
                {betaFiltersEnabled ? <SearchBarNew overview showBooleanSearch /> : <SearchBar name="Overview Filters" showBooleanSearch />}
            </div>
            {totalMatches === 0 ? (
                <EmptySearchResults mainHeading={totalMatchesWithoutFilters ? "We couldn't find any results" : ''}
                    subtitle={totalMatchesWithoutFilters ? 'Try adjusting your search or filter options' : ''}
                    filters={!!totalMatchesWithoutFilters}
                />
            ) : (
                <Layout />
            )}
        </div>
    );
};
