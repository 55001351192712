import Hotjar from '@hotjar/browser';

const siteId = 3215539;
const hotjarVersion = 6;

type User = {
    id: string
    email: string
    name: string
    instanceId: string
    clientName: string
}

export const enableHotjar = (user: User) => {
    const { id, email, name, instanceId, clientName } = user;
    if (!email.includes('@logically')) {
        Hotjar.init(siteId, hotjarVersion);
        Hotjar.identify(id, {
            name,
            email,
            instanceId,
            clientName
        });
    }
};
