import { color } from '../../../../../../utils/getColors';

type CreateNodeProps = {
    text?: string
    fontIcon: string
    fontFamily?: string
}

export const createNode = ({ text, fontIcon, fontFamily }: CreateNodeProps) => ({
    color: color.blue[500],
    label: [
        {
            backgroundColor: 'transparent',
            fontSize: 45,
            padding: {
                top: 3
            },
            fontIcon: {
                text: fontIcon,
                color: '#fff',
                ...(fontFamily ? { fontFamily } : {})
            }
        },
        ...(text ? [{
            text,
            padding: {
                top: 15
            },
            backgroundColor: 'transparent'
        }] : [])
    ]
});
