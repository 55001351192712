import React, { useRef, useState } from 'react';
import { Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { SearchBarNew } from '../../../../components/NewFilters';
import { ListViewRef } from '../../AIClustering/ListView';
import { ClustersSortOptions } from '../../AIClustering/Utils/ClusterSort';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../graphql';
import { useGlobalFiltersInClusters } from '../../../../services/Clusters/GlobalFIltersInClusters';
import { Cluster } from '../../AIClustering/types';
import { Loading } from '../../../../components/Loading';
import { ClustersTable } from '../../AIClustering/ListView/ClustersTable';
import { setParam } from '../../../../utils/urlParams';
import { EmptyState } from '../../../../components/EmptyState';
import { Images } from '../../../../utils/modeSwitch';
import { getRoom } from '../../../../utils/variables';

export const NarrativeThemesWatchlist = () => {
    const room = getRoom();
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Most content' });
    const [clustersOffset, setClustersOffset] = useState(0);
    const listViewRef = useRef<ListViewRef>(null);
    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            limit: 10,
            skip: clustersOffset,
            ...filters,
            sort: pick(sort, ['field', 'order']),
            isWatchlisted: true
        },
        fetchPolicy: 'no-cache'
    });
    const getClustersData = clustersData?.getClusters;
    const clusters: Cluster[] = getClustersData?.clusters || [];
    const setSelectedCluster = (id: string) => {
        setParam('narrative-theme', id);
    };
    if (clusterLoading) {
        return <Loading gif relative height={300} />;
    }
    if (clusters?.length < 1) {
        return (
            <div className="border">
                <EmptyState title="Your watchlist is empty"
                    summary="Add narrative themes to start tracking them here."
                    icon={Images().metrics}
                    action={{ name: 'Explore AI Narrative', color: 'primary', to: `/situation-rooms/${room.id}/ai-narratives` }}
                />
            </div>
        );
    }
    return (
        <>
            <div className="mb-2">
                <SearchBarNew clusters score listViewRef={listViewRef} isListViewEnabled>
                    <Col xs="auto" className="mb-1">
                        <ClustersSortOptions sort={sort} setSort={setSort} isWatchlist />
                    </Col>
                </SearchBarNew>
            </div>
            <ClustersTable clusters={clusters}
                total={getClustersData.total}
                offset={clustersOffset}
                onSetSelectedCluster={setSelectedCluster}
                onOffsetChange={setClustersOffset}
                itemsPerPage={10}
                isFromWatchlist
            />
        </>
    );
};
