import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Chart } from 'regraph';
import { incrementChartUpdates, NetworksHistoryStore, setSelection, updateNetworks } from '../../store';

export const useChartChange = (): { handleChange: Chart.onChangeHandler } => {
    const dispatch = useDispatch();
    const { chartState } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);

    return {
        handleChange: (newChartState) => {
            if (newChartState.selection) {
                dispatch(setSelection(Object.keys(newChartState.selection)));
            }
            if (newChartState.why === 'user' && (newChartState.view || newChartState.selection)) {
                return null;
            }
            dispatch(updateNetworks({
                chartState: {
                    ...chartState,
                    ...omit(newChartState, ['view', 'selection'])
                }
            }));
            if (newChartState.why === 'user') {
                dispatch(incrementChartUpdates());
            }
        }
    };
};
