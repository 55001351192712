import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../../utils/useDropdown';
import { Loading } from '../../../Loading';
import { MultiCheckboxFilter } from './MultiCheckboxFilter';
import { color } from '../../../../utils/getColors';

export type ItemProps = {
    id: string,
    name: string,
    selected: boolean
    contentIds?: string[]
}

type DropdownWithMultiCheckboxProps = {
    value: string,
    name: string,
    filterOptions: ItemProps[],
    handleSelectItem: (e: string[]) => void,
    singleDropdown?: boolean,
    loading?: boolean,
    threat?: boolean
}
export const DropdownWithMultiCheckbox = ({ value, name, filterOptions, handleSelectItem,
    singleDropdown = false, loading = false, threat = false }: DropdownWithMultiCheckboxProps) => {
    const [dropdown, toggleDropdown] = useDropDown();

    const selectedOptions = filterOptions?.length ? filterOptions.filter((option) => option.selected) : [];
    const handleSetValues = (filters: ItemProps[]) => {
        const filterIds = filters.map((a) => a.id);
        handleSelectItem(filterIds);
    };

    return (
        <Dropdown isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle tag="div"
                className={`dropdown-filter d-flex justify-content-between
                 align-items-center cursor-pointer px-2 py-1 ${dropdown && 'border border-primary'}`}
                data-testid="dropdown-toggle"
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={dropdown ? 'border border-primary' : ''}>
                <div className={`${(singleDropdown) && 'single-column-dropdown'} ${(!singleDropdown && !threat)
                && 'dropdown-filter-multi-checkbox'} py-2 pr-2 scrollbar-small overflow-y-auto`}
                >
                    {(filterOptions.length > 0 && !loading) && (
                        <MultiCheckboxFilter title="Sources"
                            filters={filterOptions}
                            values={filterOptions?.filter(src => !selectedOptions?.map(x => x.id).includes(src.id))?.map(x => x.id)}
                            setValues={(newItem: string[]) =>
                                handleSetValues(filterOptions.filter(src => !newItem.includes(src.id)))}
                            value={value}
                            threat={threat}
                        />
                    )}
                    {loading && <Loading relative height={168} width={255} />}
                    {(filterOptions.length === 0 && !loading) && <p className="text-center no-white-space pl-2">No results found</p>}
                </div>

            </DropdownMenu>
        </Dropdown>
    );
};
