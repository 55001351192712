import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useUser } from '../../context/user/UserContext';
import { NoSearchResults } from '../../assets/svg/noSearchResults';
import { DataAdd } from '../../assets/svg/DataAdd';

export const NoSituationRooms = () => {
    const { isAdmin } = useUser();
    return (
        <div className="text-center bg-white py-7 px-2 mt-2 d-flex flex-column align-items-center justify-content-center">
            <DataAdd />
            <h2 className="my-2">Your situation rooms will appear here</h2>
            <p className="mb-2">
                Everything in Logically Intelligence is very new, so there&apos;s<br />
                nothing to show you here yet. You can get started by<br />
                creating your first situation room.
            </p>
            {isAdmin && (
                <Link to="situation-room/create-room">
                    <Button color="primary">
                        Create New Situation Room
                    </Button>
                </Link>
            )}
        </div>
    );
};

export const NoSituationRoomsFound = () => (
    <div className="bg-white px-2 py-7 mb-4 d-flex flex-column align-items-center justify-content-center">
        <NoSearchResults />
        <h2 className="mb-0 mt-2">No situation rooms found</h2>
    </div>
);
