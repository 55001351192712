import { useQuery } from '@apollo/client';
import { GET_AGGS } from '../../graphql';
import { useFilterQuery } from '../Content/useFilterQuery';

export const useMapData = ({ dateFilter, size = 100 }) => {
    const { query: filters } = useFilterQuery();
    const ret = useQuery(GET_AGGS, {
        variables: {
            query: {
                ...filters,
                bool: {
                    ...(filters?.bool || {}),
                    filter: [
                        ...(filters?.bool?.filter || []),
                        {
                            range: {
                                datetime_tz: {
                                    gte: dateFilter.startDate,
                                    lte: dateFilter.endDate
                                }
                            }
                        }
                    ]
                }
            },
            limit: 0,
            aggs: {
                key: 'data',
                value: {
                    terms: {
                        size,
                        field: 'latlon_coords__keyword',
                        exclude: ['Other', '', 'unknown']
                    },
                    aggs: {
                        key: 'sources',
                        value: {
                            terms: {
                                size: 1,
                                field: 'ctype__keyword',
                            },
                        }
                    }
                }
            },
            opts: {
                trackTotalHits: true
            }
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.contentServiceSearch?.aggregations?.data?.buckets || [] : []
    };
};
