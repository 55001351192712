import React from 'react';
import { Table } from 'reactstrap';
import { TitleTooltip } from '../utils';
import { LoadingState } from './Loading';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { ContentModalButton } from '../../ContentModal/ContentModalButton';
import { ExternalLinkButton } from '../../../../../components/LinkModal/ExternalLinkButton';
import { roundPercentage } from '../../../../../utils/NumberFormat';
import { NoAnnotationsEmptyState } from '../../NoAnnotationsEmptyState';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { Spinner } from '../../../../../assets/svg/Spinner';

export const withTableStructure = (config) => ({ preview = false, noAnnotations }) => {
    const { data, loading, total } = config.api(config.apiParams);

    if (loading) return <LoadingState size={10} />;

    const showNoAnnotationsEmptyState = noAnnotations && config.emptyWithoutAnnotations;

    const layout = (children) => (
        <div className="h-100 d-flex flex-column">
            <TitleTooltip title={config.title({ preview })}
                tooltip={config.tooltip}
            />

            <div className={showNoAnnotationsEmptyState ? '' : 'overflow-x-auto scrollbar-small mx-n3 mt-2'}>
                {children}
            </div>
        </div>
    );

    if (showNoAnnotationsEmptyState) {
        return layout(<NoAnnotationsEmptyState label={config.label} />);
    }

    if (!data.length) {
        return layout(<EmptyState title="No data available" icon={Images().overview} />);
    }

    return layout(
        <Table className="mb-0 px-3" hover>
            <thead>
                <tr>
                    {config.columns.map(column => {
                        if (column.key === 'threats' && noAnnotations) {
                            return (
                                <th key={column.key} className="d-flex">
                                    {column.label}
                                    <TooltipWrapper id={`${config.id}TableTooltip`}
                                        tooltipText={`Threat information will be available once the enhancement 
                                            is completed for a few pieces of content.`}
                                        className="ml-1"
                                    >
                                        <Spinner />
                                    </TooltipWrapper>
                                </th>
                            );
                        }
                        return (
                            <th key={column.key}>{column.label}</th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {data.map(item => (
                    <tr key={item.key}>
                        {config.columns.map(column => {
                            const label = item[column.key];
                            if (column.type === 'url') {
                                return (
                                    <td key={column.key}>
                                        <ExternalLinkButton url={label} />
                                    </td>
                                );
                            }
                            if (column.type) {
                                return (
                                    <td key={column.key}>
                                        <ContentModalButton value={[item.key]}
                                            type={column.type}
                                            label={label}
                                            preview={preview}
                                            count={item?.count}

                                        />
                                    </td>
                                );
                            }
                            if (column.key === 'threats' && noAnnotations) {
                                return (
                                    <td key={column.key}>
                                        NA
                                    </td>
                                );
                            }
                            return (
                                <td key={column.key}>
                                    {label}
                                    {column.percentage && total > 0 ? (
                                        <span className="ml-11">
                                            ({roundPercentage((label / total) * 100)}%)
                                        </span>
                                    ) : null}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
