import { convertToMarkers } from '../convertToMarkers';

export const operatorAtEndValidator = (query, queryByLines, lineLengths) => {
    const operators = ['AND', 'OR', 'NOT', 'NEAR'];
    const highlight = [];

    if (!query || query.length === 0) {
        return {
            isValid: true,
            highlight
        };
    }

    const trimmedQuery = query.trim();

    const lastOperatorMatch = [...trimmedQuery.matchAll(new RegExp(`\\b(${operators.join('|')})\\b`, 'g'))].pop();
    if (lastOperatorMatch) {
        const operatorClosingQuery = lastOperatorMatch.index + lastOperatorMatch[0].length === trimmedQuery.length;
        if (operatorClosingQuery) {
            highlight.push([lastOperatorMatch.index, lastOperatorMatch.index + lastOperatorMatch[0].length]);
            return {
                isValid: false,
                markers: convertToMarkers(
                    highlight,
                    queryByLines,
                    lineLengths,
                    'You have an operator at the end of your query.'
                )
            };
        }
    }

    // INVALID OPERATORS VARIATIONS:
    // AND, AND), AND ), AND}, AND }, AND)}, AND )}, AND ) }, AND}), AND }), AND } )
    // OR, OR), OR ), OR}, OR }, OR)}, OR )}, OR ) }, OR}), OR }), OR } )

    // Check if the operator is followed by the closing bracket ie. ) or }
    const closingBrackets = [')', '}'];
    const allOperatorsWithFollowingCharacter = [
        ...trimmedQuery.matchAll(new RegExp(`\\b(${operators.join('|')})\\b\\s*.`, 'g'))
    ].filter(match => {
        if (closingBrackets.indexOf(match[0].slice(-1)) !== -1) {
            return match;
        }
    });

    if (allOperatorsWithFollowingCharacter.length > 0) {
        for (const matchingOperator of allOperatorsWithFollowingCharacter) {
            highlight.push([matchingOperator.index, matchingOperator.index + matchingOperator[0].length]);
        }

        return {
            isValid: false,
            markers: convertToMarkers(
                highlight,
                queryByLines,
                lineLengths,
                'You have an operator at the end of your query.'
            )
        };
    }

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have an operator at the end of your query.'
        )
    };
};
