import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getToast } from '../../../../utils/getToast';
import { RootState } from '../../../../store';
import { RoomBooleanProps, ValidationErrors } from '../../ManageSavedItems';
import { useGetActiveQueries } from '../../../../services/getActiveQueries';

type SaveBooleanProps = {
    booleanName: string,
    setOpenEditSavedItem?: (e: boolean) => void,
    booleanNamesList?: string[],
    roomBoolean: RoomBooleanProps,
    validationErrors: ValidationErrors,
    invalidQuery: boolean
}

export const SaveBooleanButton = ({
    booleanName,
    booleanNamesList,
    roomBoolean,
    setOpenEditSavedItem,
    validationErrors,
    invalidQuery
}: SaveBooleanProps) => {
    const { booleanQuery } = useSelector((state: RootState) => state.selectedFilters.filters);
    const location = useLocation();
    const roomId = location.pathname.split('/')[2];
    const toast = getToast();
    const refetchQueries = useGetActiveQueries(['getSavedBooleanSearch']);
    const [saveBooleans, { data, loading }] = useMutation(SAVE_BOOLEAN_SEARCH, {
        refetchQueries,
        onCompleted: () => {
            toast.success('Boolean search saved');
            if (setOpenEditSavedItem) setOpenEditSavedItem(false);
        },
        onError: () => {
            toast.error('Boolean search not saved');
            console.error('Boolean search not saved', data);
        }
    });

    const checkIfValidationError = () => {
        const hasErrors = Boolean(Object.values(validationErrors)
            .find((error: any) => error.isError === true));
        return hasErrors;
    };
    const checkForErrors = () => (checkIfValidationError() || invalidQuery || !booleanName.trim() || !booleanQuery
     || booleanNamesList?.includes(booleanName.trim()));

    const handleSaveBooleans = () => {
        if (checkForErrors()) {
            toast.error('Resolve all errors before continuing');
        } else if (!((booleanQuery.trim() !== roomBoolean.query) || (roomBoolean.name !== booleanName))) {
            toast.error('Make amendments to the Boolean before saving');
        } else {
            saveBooleans({ variables: {
                name: booleanName,
                query: booleanQuery,
                situationRoom: roomId,
                user: roomBoolean.user.id,
                id: roomBoolean.id,
            } });
        }
    };

    return (
        <div>
            <Button data-testid="save-boolean-button"
                onClick={handleSaveBooleans}
                disabled={loading}
                color="primary"
            >Save
            </Button>
        </div>

    );
};

export const SAVE_BOOLEAN_SEARCH = gql`
    mutation saveBooleanSearch($query: String, $name: String, $situationRoom: ID!,$user: ID,  $id: ID) {
        saveBooleanSearch(query: $query, name: $name, situationRoom: $situationRoom, user: $user, id: $id) {
            id
            name
            query
            user {
                displayName
            }
        }
    }
`;
