import React from 'react';
import { FormGroup, Label, Input, InputGroup } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { TooltipItem } from '../ToolTip';

export const Source = ({ source, setSource }) => (
    <FormGroup className="mb-2">
        <Label className="mt-1">
            <strong className="pr-1">Source</strong>
            <TooltipItem id="SourceTrigger"
                content="Add a description of the source of the content,
                                             the platform it was raised on and any relevant links/URLs and dates/times."
                Icon={FeatherIcon.Info}
            />
        </Label>
        <InputGroup border={source ? 'active' : 'none'}>
            <Input value={source}
                onChange={(e) => setSource(e.target.value)}
                type="textarea"
                required
                data-testid="source-input"
            />
        </InputGroup>
    </FormGroup>
);
