import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { BatchRemoveModal } from '../Organisms/Modals/BatchRemoveModal/BatchRemoveModal';
import { getRoom } from '../../utils/variables';
import { BatchRemoveCompletedModal } from '../Organisms/Modals/BatchRemoveModal/BatchRemoveCompletedModal';
// import { CONTENT_TYPE } from '../../utils/contentTypes';

type RemoveBulkContentButtonProps = {
    onCompleted: () => void
    onAbort: () => void
    onAction: () => void
    contentType: string
}

type HandleCompletedTypes = {
    total: number
    timeInSeconds: number
}

export const RemoveBulkContentButton = ({ onCompleted, onAbort, onAction, contentType }: RemoveBulkContentButtonProps) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();

    const handleCompleted = ({ total: totalRemoved, timeInSeconds }: HandleCompletedTypes) => {
        onCompleted();
        setModal({
            header: false,
            component: <BatchRemoveCompletedModal totalRemoved={totalRemoved} timeInSeconds={timeInSeconds} />,
        });
    };

    const setRemoveContentConfirmationModal = () => {
        onAction();
        setModal({
            header: 'Remove this content?',
            component: (
                <>
                    <p>
                        Selected contents will be removed from {room.project_name}.
                        You won&apos;t be able to see these contents within this room.
                        These contents can be restored under room settings, removed content.
                    </p>
                    <hr className="my-2" />
                    <Button className="mr-2" onClick={closeModal}>Cancel</Button>
                    <Button color="danger" className="mr-2" onClick={setRemoveContentModal}>Remove</Button>
                </>
            )
        });
    };
    const setRemoveContentModal = () => setModal({
        header: false,
        component: <BatchRemoveModal onCompleted={handleCompleted} onAbort={onAbort} contentType={contentType} />,
        backdrop: 'static'
    });

    return (
        <Button color="danger" onClick={setRemoveContentConfirmationModal} data-testid="bulk-remove-contents-button">
            Remove contents
        </Button>
    );
};
