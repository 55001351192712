import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { Col } from 'reactstrap';
import { LabelsDataProps } from './InputLabelName';
import { GET_CONTENT_LABELS, LabelDropdown, LabelDropdownProps } from '.';
import { getRoom } from '../../../utils/variables';
import { NoDataAvailableState } from '../../../components/EmptyState';

interface LabelsDisplayProps extends LabelDropdownProps {
    showEmptyState?: boolean;
}

export const LabelsDisplay = ({ roomId, contentId, showEmptyState }: LabelsDisplayProps) => {
    const room = getRoom();
    const [expand, setExpand] = useState(false);
    const [allLabels, setAllLabels] = useState<LabelsDataProps[]>([]);
    const { data } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: roomId
        },
        fetchPolicy: 'network-only'
    });

    const labelsData = useMemo(() => data?.getContentLabels || [], [data?.getContentLabels]);

    useEffect(() => {
        const filteredLabels = labelsData.filter((labelObject: LabelsDataProps) => {
            if (labelObject.contentIds.includes(contentId)) {
                return labelObject;
            }
        });
        setAllLabels(filteredLabels);
    }, [contentId, data, labelsData]);

    const labelsFeatureEnabled = !!room?.instance?.plan?.others?.labels;
    if (!labelsFeatureEnabled) return null;

    if (allLabels.length === 0) {
        if (showEmptyState) {
            const replacedId = contentId.includes('.') ? contentId.replace(/\./g, '-') : contentId;
            return (
                <div className="w-100">
                    <NoDataAvailableState text="No labels added"
                        subText={(
                            <p className="mt-2 mb-0">
                                To add labels to this content, click
                                <LabelDropdown roomId={roomId} contentId={contentId}>
                                    <span role="button" className="text-primary" id={`child-label-${replacedId}`}> here </span>
                                </LabelDropdown>
                                or <br /> click the label above & right.
                            </p>
                        )}
                    />
                </div>
            );
        }
        return null;
    }

    return (
        <div className="d-flex flex-wrap cursor-default">
            {allLabels.map((label, index) => {
                if (index > 8 && !expand) { return null; }
                return (
                    <Col xs="auto" className="pr-1 pl-0 mb-1" key={label.label}>
                        <div className="tag selected-label small new-content-view no-hover">
                            {label.label}
                        </div>
                    </Col>
                );
            })}
            {(allLabels.length > 9 && !expand) && (
                <Col xs="auto"
                    className="pr-1 pl-0 mb-1"
                    onClick={(e) => {
                        e.preventDefault();
                        setExpand(!expand);
                    }}
                >
                    <div className="tag selected-label small new-content-view no-hover">
                        +{allLabels.length - 9} labels
                    </div>
                </Col>
            )}
        </div>
    );
};
