import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

export const ActionsDropdown = ({ children, className }: { children: React.ReactNode, className?: string }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    return (
        <Dropdown onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            className={`d-inline mw-0 ${className || ''}`}
            isOpen={isOpen}
            toggle={toggleDropdown}
            direction="left"
            data-testid="actions-dropdown"
        >
            <DropdownToggle color="link" className="mw-0 p-0">
                <MoreVertical />
            </DropdownToggle>
            <DropdownMenu className="mw-0 border">
                {children}
            </DropdownMenu>
        </Dropdown>
    );
};
