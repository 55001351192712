import { tagsWithEnforcedEmptySpaces } from '../../BooleanValidationErrors';
import { convertToMarkers } from '../../convertToMarkers';
import { getTagData } from './getTagData';

export const illegalEmptySpaceInValueValidator = (query, queryByLines, lineLengths, tag) => {
    const highlight = [];

    const tagFragments = tag.split(':');

    const tagData = getTagData(tagFragments, query);
    tagData.forEach(({ tagValue, tagSnapshotValue, tagSnapshot }) => {
        if (tagValue.length !== tagSnapshotValue.replaceAll('"', '').length) {
            highlight.push([
                query.indexOf(tagSnapshot),
                query.indexOf(tagSnapshot) + tagSnapshot.length
            ]);
        }
    });

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            `Empty spaces are allowed only in tags: ${tagsWithEnforcedEmptySpaces.join(', ')}`
        )
    };
};
