import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Deleted } from '../../../../assets/svg/deleted';
import { useModal } from '../../../../context/modal/ModalComponent';

export const RecoverSuccessModal = ({ contentIds }) => {
    const { closeModal } = useModal();

    let successText = 'This content was successfully moved back to the situation room.';
    if (contentIds.length > 1) {
        successText = 'These contents were successfully moved back to the situation room.';
    }

    return (
        <Modal isOpen toggle={closeModal}>
            <ModalBody>
                <Deleted />
                <h2 className="pt-2">{successText}</h2>
                <hr />
                <Button onClick={closeModal}>Close</Button>
            </ModalBody>
        </Modal>
    );
};
