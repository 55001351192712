import React from 'react';
import { X } from 'react-feather';
import { Button } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import { getToast } from '../../../utils/getToast';
import { useGetActiveQueries } from '../../../services/getActiveQueries';

type DeleteBooleanProps = {
        id: string,
        closeModal: any,
        setOpenEditSavedItem?: any
    }

export const DeleteBoolean: React.FC<DeleteBooleanProps> = ({ id, closeModal, setOpenEditSavedItem }) => {
    const handleDeleteRoomBoolean = () => {
        deleteRoomBoolean({ variables: { id } });
    };

    const toast = getToast();
    const refetchQueries = useGetActiveQueries(['getSavedBooleanSearch']);
    const [deleteRoomBoolean, { loading }] = useMutation(DELETE_BOOLEAN_SEARCH, {
        refetchQueries,
        onCompleted: () => {
            closeModal();
            toast.success('Boolean search deleted successfully');
            if (setOpenEditSavedItem) setOpenEditSavedItem(false);
        }
    });

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h2>Delete saved Boolean search</h2>
                <div className="cursor-pointer">
                    <X data-testid="delete-boolean-x" onClick={closeModal} size={18} />
                </div>
            </div>
            <hr />
            <p>Are you sure that you want to delete this Boolean search?</p>
            <hr />
            <div className="d-flex">
                <Button color="secondary" className="mr-2" onClick={closeModal} disabled={loading}>Cancel</Button>
                <Button color="danger" onClick={handleDeleteRoomBoolean} disabled={loading}>Delete</Button>
            </div>
        </div>
    );
};

export const DELETE_BOOLEAN_SEARCH = gql`
    mutation deleteBooleanSearch($id: ID!) {
        deleteBooleanSearch(id:$id) {
            id
        }
    }
`;
