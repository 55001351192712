import { gql, useMutation } from '@apollo/client';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useGetActiveQueries } from '../../../../services/getActiveQueries';
import { getToast } from '../../../../utils/getToast';
import { RemoveContentActions, useRemoveContent } from '../../../CommonModals/useRemoveContent';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

type UseUpdateThreatSignalsProps = {
    clearSelection: () => void,
    remove?: boolean,
    ids?: string[],
    isMatch?: boolean,
    addedSignals?: string[],
    threatsReset?: boolean
}

export const useUpdateThreatSignals = ({ clearSelection, remove = false, ids = [],
    isMatch = false, addedSignals = [], threatsReset = false }: UseUpdateThreatSignalsProps) => {
    const { closeModal } = useModal();
    const toast = getToast();
    const refetchQueries = useGetActiveQueries(['getThreatSummary', 'getThreats', 'getMatches']);
    const { removeContent, loading: removeLoading } = useRemoveContent({
        type: isMatch ? CONTENT_TYPE.MATCH : CONTENT_TYPE.THREAT,
        action: isMatch ? RemoveContentActions.MarkAsThreat : RemoveContentActions.MarkNotAsThreat,
        onCompleted: () => {
            if (isMatch) {
                toast.success('This content has been marked as ‘threatening’.');
            } else if (remove) {
                toast.success('Threats successfully removed, this content has been marked as ‘non-threatening’.');
            }
            if (clearSelection) clearSelection();
            closeModal();
        },
        ids
    });
    const [updateThreatSignals, { loading }] = useMutation(UPDATE_THREAT_SIGNALS, {
        refetchQueries,
        awaitRefetchQueries: true,
        onCompleted: () => {
            if (isMatch) {
                removeContent([{
                    id: ids[0],
                    markThreatInfo: addedSignals
                }]);
            } else if (remove) {
                removeContent(ids.map(id => ({ id })));
            } else {
                if (threatsReset) {
                    toast.success('Threats successfully reset.');
                } else {
                    toast.success('Threats successfully amended.');
                }
                clearSelection();
                closeModal();
            }
        },
        onError: (e) => {
            console.error(e.message);
            toast.info('Threat signals not updated');
        },
        fetchPolicy: 'no-cache'

    });
    return {
        loading: loading || removeLoading,
        updateThreatSignals
    };
};

export const UPDATE_THREAT_SIGNALS = gql`
    mutation updateThreatSignals (
          $projectId: String!,
          $contentIds: [String]!,
          $addedThreatSignals: [AddedThreatSignalInput],
          $removedThreatSignals: [String]
          $reset: Boolean
        ) {
            updateThreatSignals (
                projectId: $projectId,
                contentIds: $contentIds,
                addedThreatSignals: $addedThreatSignals,
                removedThreatSignals:$removedThreatSignals
                reset: $reset
            )
        }
`;
