/* eslint-disable no-nested-ternary */
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

type BreadCrumbsProps = {
    items: { title: string; href?: string }[];
}

export const BreadCrumbs = ({ items }: BreadCrumbsProps) => (
    <Breadcrumb data-testid="breadcrumb-component">
        {items.filter(({ title }) => title).map((item, index) => (
            <BreadcrumbItem key={item.title} active={index !== items.length - 1}>
                {index === items.length - 1 || !item.href ? item.title.includes('-') ? item.title.replace('-', ' ') : item.title : (
                    <Link className="text-primary" to={item.href} data-testid={`breadcrumb-link-${item.title}`}>
                        {item.title}
                    </Link>
                )}
            </BreadcrumbItem>
        ))}
    </Breadcrumb>
);
