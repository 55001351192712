import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type RadioInputProps = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    id: string;
    label: string;
    name: string;
    disabled?: boolean;
    className?: string;
}

export const RadioInput = ({ checked, onChange, id, label, disabled, name, className = '' }: RadioInputProps) => (
    <FormGroup check className={`mr-2 d-flex align-items-center ${className}`}>
        <Input type="radio"
            name={name}
            id={id}
            checked={checked}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
            className="mt-0"
            disabled={disabled}
        />
        <Label for={id} check className="ml-1">
            {label}
        </Label>
    </FormGroup>
);
