import { gql, useMutation } from '@apollo/client';

export const useDeleteNarrativeVersion = () => {
    const [deleteNarrativeVersion, { loading }] = useMutation(DELETE_NARRATIVE_VERSION);
    return {
        loading,
        deleteNarrativeVersion
    };
};

const DELETE_NARRATIVE_VERSION = gql`
    mutation deleteNarrativeVersion(
        $versionId: ID!
    ) {
        deleteNarrativeVersion(
            versionId: $versionId,
        ) { versionId }
    }
`;
