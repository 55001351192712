import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Images } from '../../../../utils/modeSwitch';
import { formatDateLocal } from '../../../../utils/getTimeFrom';
import { getRoom } from '../../../../utils/variables';
import { FindNarrativesButton } from './FindNarratives';
import { useStartRoomClustering } from '../../../../services/Clusters/startRoomClustering';
import { Loading } from '../../../../components/Loading';
import { getToast } from '../../../../utils/getToast';

type NoNarrativesFoundProps = {
    lastRunOn: number;
};

export const NoNarrativesFound = ({ lastRunOn }: NoNarrativesFoundProps) => {
    const toast = getToast();
    const tryAgainDate = new Date(lastRunOn + (24 * 60 * 60 * 1000));
    const canTryAgain = tryAgainDate < new Date();
    const room = getRoom();
    const { startRoomClustering, loading } = useStartRoomClustering({ recluster: true });
    if (loading) {
        return <Loading relative height={300} />;
    }
    const showError = () => {
        toast.error(`You can only update Narratives every 24 hours. You can 
            try again at ${formatDateLocal(tryAgainDate, 'DD/MM/YYYY, h:mmA')}.`);
    };
    return (
        <div className="text-center" style={{ maxWidth: 470 }}>
            <img src={Images().noResults} style={{ height: 80 }} />
            <h2 className="my-2">No narratives found</h2>
            <p>
                Narrative analysis was run on {formatDateLocal(lastRunOn, 'DD/MM/YYYY, h:mmA')}, however no Narratives were found.
            </p>
            <p className="my-3">You can try again at {formatDateLocal(tryAgainDate, 'DD/MM/YYYY, h:mmA')}</p>
            <p className="mb-2">
                Try adjusting the {room.query_type === 'boolean' ? 'boolean' : 'keywords'} within the room settings.
            </p>
            <Link component={Button} to={`/situation-rooms/${room.id}/settings`} className="mr-2">
                Room settings
            </Link>
            <Link component={Button}
                className="mr-2"
                to={{ pathname: 'https://logically-intelligence.readme.io/docs/ai-narratives' }}
                target="_blank"
                rel="noreferrer"
            >
                Learn more
            </Link>
            {canTryAgain ? <FindNarrativesButton startRoomClustering={startRoomClustering} />
                : <Button onClick={showError} color="primary">Find narratives</Button>}
        </div>
    );
};
