import { useCoOccurence } from './useCoOccurence';
import { withTable } from './withTable';

const columns = [
    { key: 'keyword', label: 'Keywords' },
    { key: 'count', label: 'Matches' }
];

export const CoOccurence = withTable({
    columns,
    api: useCoOccurence,
});
