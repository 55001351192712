import React from 'react';
import errorImg from '../assets/images/emptyStates/errorPage.svg';

// This is pretty special react magic file. Allow to ignore some of the eslint rules

// eslint-disable-next-line no-restricted-syntax
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line react/no-unused-state
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        const { state, props } = this;

        if (state?.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="d-flex align-items-center justify-content-center h-100vh">
                    <div>
                        <div className="p-4 text-center">
                            <img src={errorImg} alt="" className="maxw-350" />
                        </div>
                        <div className="text-center">
                            <h1 className="p-2">Something went wrong.</h1>
                            <p className="mb-0">We&apos;re sorry, it looks like something didn&apos;t quite</p>
                            <p>go to plan. Please try again later.</p>
                        </div>
                    </div>
                </div>
            );
        }

        return props.children;
    }
}
