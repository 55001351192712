import React from 'react';
import { Button } from 'reactstrap';
import { X } from 'react-feather';
import { IconButton } from '../Atoms';

type FeatureTourPopoverProps = {
    currentStep: number
    setCurrentStep: (a: number) => void
    totalSteps: number
    closeTour: () => void
    title: string
    content: string | JSX.Element
}

export const FeatureTourPopover = ({ currentStep, setCurrentStep, totalSteps, closeTour, title, content }: FeatureTourPopoverProps) => {
    const isIntroStep = currentStep === 0;
    const isFirstStep = currentStep === 1;
    const isLastStep = currentStep === totalSteps - 1;
    let primaryButtonText = isIntroStep ? 'Let\'s go' : 'Next';
    if (isLastStep) primaryButtonText = 'Got it';
    const handleNextClick = () => {
        if (isLastStep) {
            closeTour();
            setCurrentStep(0);
        } else {
            setCurrentStep(currentStep + 1);
        }
    };
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="my-0">
                    {title} {isIntroStep ? '' : `(${currentStep}/${totalSteps - 1})`}
                </h2>
                <IconButton onClick={closeTour}
                    icon={(
                        <X color="#050E2B"
                            size={20}
                            className="cursor-pointer"
                            stroke="#050E2B"
                        />
                    )}
                />
            </div>
            <hr className="mb-3 mt-2" />
            {content}
            <hr className="mb-3 mt-2" />
            {(!isIntroStep && !isFirstStep) && (
                <Button onClick={() => setCurrentStep(currentStep - 1)} className="mr-2">
                    Previous
                </Button>
            )}
            <Button color="primary" onClick={handleNextClick}>
                {primaryButtonText}
            </Button>
        </div>
    );
};
