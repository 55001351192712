import moment from 'moment';
import React, { useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWithAction } from '../../../components/Molecules';
import { useModal } from '../../../context/modal/ModalComponent';
import { Box } from './Layout/Box';
import { Visualisation } from './Visualisation';
import { setLayout } from './store';
import { RootState } from '../../../store';
import { OverviewWidget } from '../../../generated/graphql';

const GridLayout = WidthProvider(Responsive);

export const RemovedWidgets = () => {
    const { setModal } = useModal();
    const [showWidgets, setShowWidgets] = useState(false);
    const { layout: { data, ...layout } } = useSelector((state: RootState) => state.overview);
    const dispatch = useDispatch();
    const removedWidgets = data?.filter(item => item?.removed) || [];
    if (!removedWidgets.length) return null;

    let prevX = 0;
    const largeLayout = removedWidgets.map(item => {
        const ret = {
            i: item.id,
            x: prevX,
            y: 0,
            h: item.h + 3,
            w: item.w,
        };
        prevX += item.w;
        if (prevX >= 24) prevX = 0;
        return ret;
    });

    const openRestoreModal = (widget: OverviewWidget) => setModal({
        component: <ModalWithAction heading="Are you sure want to restore the widget?"
            subheading="The widget will be restored to your layout with this action. "
            action={{
                onClick: () => {
                    dispatch(setLayout({
                        ...layout,
                        data: data.map(item => {
                            if (item.id === widget.id) {
                                return {
                                    ...item,
                                    removed: false,
                                    dateRemoved: null
                                };
                            }
                            return item;
                        })
                    }));
                },
                name: 'Yes, Restore'
            }}
        />
    });

    return (
        <div className="px-4 py-2 bg-white border-bottom mx-n4 mb-4 mt-n2">
            <Row className="justify-content-between">
                <Col xs="auto">
                    <h3>Removed widgets ({removedWidgets.length})</h3>
                </Col>
                <Col xs="auto">
                    <Button color="link" className="px-0" onClick={() => setShowWidgets(!showWidgets)}>
                        {showWidgets ? 'Hide' : 'Show'} removed widgets
                    </Button>
                </Col>
            </Row>
            {showWidgets && (
                <div className="py-2">
                    <GridLayout rowHeight={2}
                        layouts={{
                            lg: largeLayout,
                            sm: largeLayout.map(item => ({ ...item, w: 6 }))
                        }}
                        breakpoints={{ lg: 768, sm: 0 }}
                        cols={{ lg: 24, sm: 6 }}
                        isDraggable={false}
                        isResizable={false}
                        margin={[24, 24]}
                        className="m-n3"
                    >
                        {removedWidgets.map((item) => (
                            <div key={item.id} className="d-flex flex-column">
                                <div className="border border-bottom-0 p-2">
                                    <Row className="justify-content-between">
                                        <Col xs="auto">
                                            <p className="m-0">Removed on <br />{moment(item.dateRemoved).format('DD/MM/YYYY')}</p>
                                        </Col>
                                        <Col xs="auto">
                                            <Button color="primary" onClick={() => openRestoreModal(item)}>Restore</Button>
                                        </Col>
                                    </Row>
                                </div>
                                <Box className={`pointer-events-none flex-1 ${
                                    (item.type === 'table' || item.type === 'matches') ? 'px-3 pt-3' : 'p-3'
                                }`}
                                >
                                    <Visualisation type={item.type} id={item.id} />
                                </Box>
                            </div>
                        ))}
                    </GridLayout>
                </div>
            )}
        </div>
    );
};
