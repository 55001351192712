import { useState } from 'react';
import { useTour } from '@reactour/tour';
import { TourStep } from '../../../components/FeatureTour';

export const networksTourSteps = [
    {
        title: 'Feature tour',
        content: `We have updated Networks. You can now remove irrelevant
         entities, select multiple entities and save a network for later.
         Do you want to take a tour?`
    },
    {
        title: 'Select node',
        content: 'Click to select content node or user node and see more details.'
    },
    {
        title: 'Select and manage multiple nodes',
        content: `Hold down the Shift key and click on another node. This will
         allow you to select multiple nodes. Press delete to remove them.`
    },
    {
        title: 'Reset network graph',
        content: 'Use the reset button to bring your Network back to its original layout.',
        selector: '.reset-network-graph'
    },
    {
        title: 'Save network graph',
        content: `Use the save button to save your Network so you can access it later and
         share your changes with your colleagues.`,
        selector: '.save-network-graph'
    },
    {
        title: 'Download network graph as image',
        content: 'Use the download button to save your Network as an image (JPEG/PNG)',
        selector: '.export-network-graph'
    },
    {
        title: 'Settings',
        content: 'Use settings to narrow down your results',
        selector: '#settingsLink'
    },
    {
        title: 'Saved networks',
        content: 'Access saved network from \'Saved networks\'',
        selector: '#savedNetworks'
    },
    {
        title: 'Help and feature tour',
        content: `Select this icon to relaunch the tour or access documentation
         explaining this feature in more detail.`
    }
] as TourStep[];

export const useNetworksFeatureTour = () => {
    const hasDoneFeatureTour = localStorage.getItem('hasDoneNetworksFeatureTour');
    const [done, setDone] = useState(hasDoneFeatureTour);
    const { setIsOpen, isOpen } = useTour();

    return {
        handleNetworksFeatureTour: () => {
            if (done) return null;
            setDone('true');
            localStorage.setItem('hasDoneNetworksFeatureTour', 'true');
            setIsOpen(true);
        },
        isOpen
    };
};
