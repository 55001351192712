import { NetworkNode } from '../../useNetworks';
import { createLink } from './createLink';

export const useHashtagLinks = (data: NetworkNode[]) => Object.fromEntries(
    data.map(({ id, hashtags }) => {
        const contentNodeId = `content_${id}`;
        return hashtags.map(hashtag => ([
            `${contentNodeId}_hashtag_${hashtag}`,
            createLink({
                id1: contentNodeId,
                id2: `hashtag_${hashtag}`,
                label: ''
            })
        ]));
    }).flat()
);
