import { convertToMarkers } from '../convertToMarkers';

export const emptyParenthesesValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    // Find all empty parentheses and store the indexes
    [...query.matchAll(/([[(])+\s*([\])])|([[{])+\s*([\]}])/g)].forEach((match) => {
        highlight.push([match.index, match.index + match[0].length]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have an empty set of parentheses.'
        )
    };
};
