import React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Cluster, SubCluster } from '../../types';
import { IconButton } from '../../../../../components/Atoms';
import { Sources } from './Sources';
import { WidgetName, WidgetTextTooltip } from '../../SelectedClusterView/Summary/WidgetTextTooltip';

type TopSourcesProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    clusterData: SubCluster[] | Cluster[];
};

export const TopSources = ({ isCollapsed, setCollapsable, clusterData }: TopSourcesProps) => (
    <>
        <IconButton
            className="icon-button text-decoration-none"
            icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
            onClick={setCollapsable}
            text={<WidgetTextTooltip widgetName={WidgetName.Sources} noMargin className="ml-2" />}
            dataTestid="topSourcesComparison"
        />
        <div className="grid_container_narrative mb-4 mt-3">
            {
                isCollapsed && [0, 1, 2, 3].map((_, index) => {
                    if (clusterData[index]) { return (
                        <Sources topSources={clusterData[index].topSources} key={clusterData[index].id} />
                    ); }
                    return (
                        <div className="border p-2" key={_} />
                    );
                })
            }
        </div>
    </>
);
