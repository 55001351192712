import { convertToMarkers } from '../convertToMarkers';
import { OPERATORS } from '../editorConfig';

export const operatorCapitalisationValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0 || OPERATORS.length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    // Find all operators and check if they are in correct operators array
    [...query.matchAll(new RegExp(`(\\s${OPERATORS.join('\\s|\\s')}\\s)`, 'gmi'))].forEach((match) => {
        let firstIndex = match.index;
        if (match[0].length > match[0].trim().length) {
            firstIndex = match.index + 1;
        }

        if (!OPERATORS.includes(match[0].trim())) {
            highlight.push([firstIndex, firstIndex + match[0].trim().length]);
        }
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have used an invalid operator. Please check the capitalisation.'
        )
    };
};
