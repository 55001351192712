import moment from 'moment';
import React from 'react';
import { ThreatTags } from '../../../../../../../components/ActivityItem/ThreatTags';
import { largeNumber } from '../../../../../../../utils/NumberFormat';

export const ThreatSummary = ({ threatData, small }) => {
    if (!threatData) return null;

    const { engagement, datetime: dateTime, locationsDetected, whyathreat } = threatData;

    return (
        <div>
            {!small && <ThreatTags whyathreat={whyathreat} />}
            <p>
                <strong className="mb-0">Engagement:&nbsp;</strong>
                {engagement === -1 ? 'N/A' : largeNumber(engagement)}
            </p>
            <p className={`mb-0 mt-${small ? '0' : '1'} no-white-space`}>
                <strong>Posted:&nbsp;</strong>{moment(dateTime).format('DD/MM/YYYY @hh:mm A')}
            </p>
            <p className={`mb-0 mt-${small ? '0' : '1'}`}><strong>Location mentions:&nbsp;</strong>{locationsDetected}</p>
        </div>
    );
};
