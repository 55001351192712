import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { ArrowLeft, ChevronDown } from 'react-feather';
import { v4 as uuid } from 'uuid';
import {
    Button,
    Card,
    Col,
    Collapse,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import { Loading } from '../../../../../../components/Loading';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { useUser } from '../../../../../../context/user/UserContext';
import { CREATE_TEMPLATE, DELETE_TEMPLATE, GET_TEMPLATES, UPDATE_TEMPLATE } from '../../../graphql';
import { FontSelector } from './FontSelector';
import { Slides } from './Slides';
import covera from '../../../../../../assets/images/reports/cover-1.jpg';
import coverb from '../../../../../../assets/images/reports/cover-2.jpg';
import coverc from '../../../../../../assets/images/reports/cover-3.jpg';
import coverd from '../../../../../../assets/images/reports/cover-4.jpg';

export const NewTemplate = ({
    instance,
    template,
    allTemplates,
    updateReport
}) => {
    const { user: { id } } = useUser();
    const { closeModal } = useModal();
    const [createTemplate, { loading }] = useMutation(CREATE_TEMPLATE, {
        onCompleted: () => {
            closeModal();
        },
        refetchQueries: () => [GET_TEMPLATES],
    });
    const [updateTemplate, { loading: loadingB }] = useMutation(UPDATE_TEMPLATE, {
        onCompleted: () => {
            closeModal();
        },
        refetchQueries: () => [GET_TEMPLATES],
    });
    const [deleteTemplate, { loading: loadingC }] = useMutation(DELETE_TEMPLATE, {
        onCompleted: () => {
            closeModal();
        },
        refetchQueries: () => [GET_TEMPLATES],
    });
    const style = {
        fontFamily: 'Roboto',
        mainTitle: { fontSize: 24, color: 2, bold: true },
        subtitle: { fontSize: 18, color: 2, bold: true },
        pageTitle: { fontSize: 22, color: 2, bold: true },
        bodyText: { fontSize: 14, color: 2, bold: false },
        ...template?.style,
    };
    const settings = {
        logicallyLogo: true,
        pageNumber: false,
        ...template?.settings,
    };
    const data = {
        name: template?.name || '',
        backgroundImage: template?.backgroundImage || '',
        style,
        settings,
    };

    const [name, setName] = useState(data.name);
    const { fontFamily } = style;
    const [mainTitle, setMainTitle] = useState(style.mainTitle);
    const [subtitle, setSubtitle] = useState(style.subtitle);
    const [pageTitle, setPageTitle] = useState(style.pageTitle);
    const [bodyText, setBodyText] = useState(style.bodyText);
    const [logicallyLogo, setLogicallyLogo] = useState(settings.logicallyLogo);
    const [pageNumber, setPageNumber] = useState(settings.pageNumber);
    const [bgImage, setBgImage] = useState(data.backgroundImage);
    const [textOpen, setTextOpen] = useState(true);
    const [settingsOpen, setSettingsOpen] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, setPage] = useState(0);
    const allPages = ['Cover', 'Body'];

    const handleDelete = () => {
        deleteTemplate({
            variables: {
                id: template.id
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (template) {
            if (name !== template.name && allTemplates.find(a => a.name === name)) {
                setError(true);
            } else {
                const updatedTemplate = {
                    id: template.id,
                    updatedBy: id,
                    updatedAt: new Date(),
                    name,
                    settings: {
                        logicallyLogo,
                        pageNumber,
                    },
                    backgroundImage: bgImage,
                    style: {
                        fontFamily,
                        mainTitle,
                        subtitle,
                        pageTitle,
                        bodyText,
                    },
                };
                updateTemplate({
                    variables: {
                        data: updatedTemplate,
                    },
                });
                updateReport({ template: updatedTemplate });
            }
        } else if (allTemplates.find(a => a.name === name)) {
            setError(true);
        } else {
            createTemplate({
                variables: {
                    data: {
                        instance,
                        createdBy: id,
                        createdAt: new Date(),
                        name,
                        settings: {
                            logicallyLogo,
                            pageNumber,
                        },
                        backgroundImage: bgImage,
                        style: {
                            fontFamily,
                            mainTitle,
                            subtitle,
                            pageTitle,
                            bodyText,
                        },
                    },
                },
            });
        }
    };

    const checkIfBgIsDark = (pg) => {
        const page = pg !== undefined ? pg : currentPage;
        if (page === 1 || bgImage === coverc) {
            return false;
        }
        if (bgImage) {
            return true;
        }
        return false;
    };
    const hasDarkBg = checkIfBgIsDark();
    return (
        <div className="report-template">
            {(loading || loadingB || loadingC) && <Loading background="rgba(250,250,250,.7)" height="100vh" />}
            <Row noGutters className="align-items-stretch">
                <Col xs={12} md={4} lg={3} xl={2} className="border-right">
                    <div className="px-4 py-2 d-flex align-items-center h-100 text-primary" role="button" onClick={closeModal}>
                        <ArrowLeft size={18} />
                        <p className="my-0 ml-2 text-primary">Back</p>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={9} xl={10}>
                    <h3 className="m-0 py-2 px-4">{template ? `Edit ${template.name}` : 'Create new template'}</h3>
                </Col>
            </Row>
            <hr className="m-0" />
            <Row noGutters className="align-items-stretch">
                <Col xs={12} md={4} lg={3} xl={2} className="border-right">
                    <div className="px-4 py-3">
                        <h3 className="m-0">Style options</h3>
                    </div>
                    <hr className="m-0" />
                    <div className="p-4">
                        <div className="d-flex justify-content-between align-items-center mb-2"
                            role="button"
                            onClick={() => setTextOpen(!textOpen)}
                        >
                            <p className="m-0 font-weight-semibold">TEXT</p>
                            <ChevronDown size={16} className={`transition ${textOpen ? 'rotate-180' : ''}`} />
                        </div>
                        <Collapse isOpen={textOpen}>
                            <FormGroup className="mb-2">
                                <Label>Main title</Label>
                                <FontSelector value={mainTitle} onChange={(val) => setMainTitle(val)} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>Sub title</Label>
                                <FontSelector value={subtitle} onChange={(val) => setSubtitle(val)} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label>Page title</Label>
                                <FontSelector value={pageTitle} onChange={(val) => setPageTitle(val)} />
                            </FormGroup>
                            <FormGroup className="pb-4">
                                <Label>Body text</Label>
                                <FontSelector value={bodyText} onChange={(val) => setBodyText(val)} type="body" />
                            </FormGroup>
                        </Collapse>
                        <div className="d-flex justify-content-between align-items-center mb-2 mt-4"
                            role="button"
                            onClick={() => setSettingsOpen(!settingsOpen)}
                        >
                            <p className="m-0 font-weight-semibold">PAGE SETTINGS</p>
                            <ChevronDown size={16} className={`transition ${settingsOpen ? 'rotate-180' : ''}`} />
                        </div>
                        <Collapse isOpen={settingsOpen}>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <p className="mr-1">Logically logo</p>
                                <CustomInput id="logicallyLogo"
                                    type="switch"
                                    checked={logicallyLogo}
                                    onChange={(e) => setLogicallyLogo(e.target.checked)}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <p className="mr-1">Page Numbers</p>
                                <CustomInput id="pageNumber"
                                    type="switch"
                                    checked={pageNumber}
                                    onChange={(e) => setPageNumber(e.target.checked)}
                                />
                            </div>
                        </Collapse>
                    </div>
                </Col>
                <Col xs={12} md={8} lg={9} xl={10}>
                    <form className="px-4 py-3" onSubmit={handleSubmit}>
                        {error && (
                            <p className="text-danger mr-2 my-0">
                                This template name already exists. Please choose a different name.
                            </p>
                        )}
                        <div className="d-flex align-items-end">
                            <div className="flex-1 mr-2">
                                <Label className="mb-1">Enter template name</Label>
                                <Input required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <Button color="primary" type="submit" disabled={name.length === 0}>Save template</Button>
                            {template ? (
                                <Button onClick={handleDelete} className="ml-2" color="danger">
                                    Delete Template
                                </Button>
                            ) : null}
                        </div>
                    </form>
                    <hr className="m-0" />
                    <Row noGutters className="align-items-stretch">
                        <Col xs={12} md={8} lg={9}>
                            <div className="px-4 py-3 bg-light">
                                <h3 className="mt-0 mb-3">
                                    Edit&nbsp;
                                    {allPages[currentPage].toLowerCase()}
                                    {' '}
                                    page
                                </h3>
                                {/* eslint-disable-next-line max-len */}
                                <Card className={`pp-slide-card shadow-lg rounded-0 px-5 py-4 ${hasDarkBg ? 'bg-secondary text-white' : 'bg-light text-secondary'}`}
                                    body
                                    style={{
                                        backgroundImage: (bgImage && currentPage !== 1) ? `url("${bgImage}")` : 'none',
                                        backgroundSize: 'cover'
                                    }}
                                >
                                    <Slides slide={0}
                                        logicallyLogo={logicallyLogo}
                                        mainTitle={mainTitle}
                                        subtitle={subtitle}
                                        pageTitle={pageTitle}
                                        bodyText={bodyText}
                                        pageNumber={pageNumber}
                                        currentPage={currentPage}
                                    />
                                    <Slides slide={1}
                                        logicallyLogo={logicallyLogo}
                                        mainTitle={mainTitle}
                                        subtitle={subtitle}
                                        pageTitle={pageTitle}
                                        bodyText={bodyText}
                                        pageNumber={pageNumber}
                                        currentPage={currentPage}
                                    />
                                </Card>
                                {currentPage !== 1 && <h3 className="mt-5">Select background image</h3>}
                                {currentPage !== 1 && (
                                    <Row>
                                        {[covera, coverb, coverc, coverd].map((src, index) => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={uuid()}>
                                                <div role="button"
                                                    onClick={() => {
                                                        setBgImage(src === bgImage ? '' : src);
                                                        if (src === bgImage || index === 2) {
                                                            setMainTitle({ ...mainTitle, color: 2 });
                                                            setSubtitle({ ...subtitle, color: 2 });
                                                        } else {
                                                            setMainTitle({ ...mainTitle, color: 1 });
                                                            setSubtitle({ ...subtitle, color: 1 });
                                                        }
                                                    }}
                                                >
                                                    {/* eslint-disable-next-line max-len */}
                                                    <img className={`w-100 rounded border ${src === bgImage ? 'border-primary bg-primary' : 'border-light bg-light'}`}
                                                        src={src}
                                                        alt=""
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={3} className="border-left">
                            <div className="px-4 pt-3">
                                <div className="d-flex mb-4">
                                    <h3 className="my-0 mr-4">Pages</h3>
                                    <p className="m-0">Click to select</p>
                                </div>
                                {allPages.map((item, index) => (
                                    <div key={item} className="mb-3" role="button" onClick={() => setPage(index)}>
                                        <p className="mb-1">
                                            {item}
                                            {' '}
                                            page
                                        </p>
                                        {/* eslint-disable-next-line max-len */}
                                        <div className={`slide-thumbnail minh-100 transition rounded border ${currentPage === index ? 'border-primary' : 'border-white'}${checkIfBgIsDark(index) ? ' bg-secondary text-white' : ' bg-light text-secondary'}`}
                                            style={{ backgroundImage: (bgImage && index !== 1) ? `url("${bgImage}")` : 'none',
                                                backgroundSize: 'cover' }}
                                        >
                                            <Slides slide={index}
                                                currentPage={index}
                                                bodyText={bodyText}
                                                pageTitle={pageTitle}
                                                subtitle={subtitle}
                                                mainTitle={mainTitle}
                                                pageNumber={pageNumber}
                                                logicallyLogo={logicallyLogo}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
