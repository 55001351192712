import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddLogo } from './AddLogo';
import covera from '../../../../../../assets/images/reports/cover-1.jpg';
import coverb from '../../../../../../assets/images/reports/cover-2.jpg';
import coverd from '../../../../../../assets/images/reports/cover-4.jpg';
import { TextEditor } from './TextEditor';
import { updatePageAction } from '../../../../../../redux/reports';

export const checkIfBgIsDark = (template) => {
    if ([covera, coverb, coverd].includes(template.backgroundImage)) {
        return true;
    }
    return !!template.settings.darkBackground;
};

export const CoverPage = ({ data, pdf }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const { template } = report;
    const hasDarkBg = checkIfBgIsDark(template);
    const handleUpdatePage = (updates) => dispatch(updatePageAction({ id: data.id, updates }));

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className={`${template.settings.logicallyLogo ? '' : 'invisible'}`}>
                    <AddLogo logo={data.logo}
                        handleChange={newLogo => handleUpdatePage({ logo: newLogo })}
                        pdf={pdf}
                        hasDarkBg={hasDarkBg}
                    />
                </div>
                <AddLogo logo={data.clientLogo}
                    handleChange={newLogo => handleUpdatePage({ clientLogo: newLogo })}
                    pdf={pdf}
                    hasDarkBg={hasDarkBg}
                />
            </div>
            <div className={`bg-transparent px-3 ${pdf ? 'pt-2' : ' py-2 border-dashed'}
            main-title font-size-${template.style.mainTitle.fontSize}`}
            >
                <TextEditor value={data.heading}
                    onChange={newHeading => handleUpdatePage({ heading: newHeading })}
                    placeholder={pdf ? '' : 'Edit heading'}
                    disabled={pdf}
                    hasDarkBg={hasDarkBg}
                />
            </div>
            <div className={`bg-transparent px-3 ${pdf ? 'pb-2' : ' py-2 border-dashed'} sub-title 
            ${template.style.mainTitle.fontSize > 30 ? ' mt-3' : ''} font-size-${template.style.subtitle.fontSize}`}
            >
                <TextEditor value={data.subtitle}
                    onChange={newSubtitle => handleUpdatePage({ subtitle: newSubtitle })}
                    placeholder={pdf ? '' : 'Edit subtitle'}
                    disabled={pdf}
                    hasDarkBg={hasDarkBg}
                />
            </div>
            {data.footer && (
                <div className={`bg-transparent px-3 ${pdf ? 'pb-2' : ' py-2 border-dashed'} footer 
            ${template.style.mainTitle.fontSize > 30 ? ' mt-3' : ''} font-size-${template.style.subtitle.fontSize}`}
                >
                    <TextEditor value={data.footer}
                        onChange={newFooter => handleUpdatePage({ footer: newFooter })}
                        placeholder={pdf ? '' : 'Edit footer'}
                        disabled={pdf}
                        hasDarkBg={hasDarkBg}
                    />
                </div>
            )}
        </div>
    );
};
