import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ManageThreatsModal } from './ManageThreatsModal';
import { ContentTableContentType } from '..';

type ManageThreatsButtonProps = {
    selectedContents: ContentTableContentType[],
    clearSelection: () => void
}

export const ManageThreatsButton = ({ selectedContents, clearSelection }: ManageThreatsButtonProps) => {
    const { setModal } = useModal();
    const hanldeOnClick = () => {
        setModal({
            header: false,
            size: 'lg',
            component: <ManageThreatsModal selectedContents={selectedContents} clearSelection={clearSelection} />,
        });
    };
    return (
        <Button color="primary" className="mr-1" data-testid="manage-threats" onClick={hanldeOnClick}>Manage threats</Button>
    );
};
