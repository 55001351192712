import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../../../../components/Loading';
import { GET_TEMPLATES } from '../../../graphql';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../../../utils/variables';
import { NewTemplate } from './NewTemplate';
import { removeAllTypename } from '../../../../../../utils/removeAllTypename';
import { setReportAction } from '../../../../../../redux/reports';
import { EditTemplate } from '../../Templates/EditTemplate';
import { CreatedBy } from '../../Templates/CreatedBy';
import { CreateNew } from '../../Templates/CreateNew';
import { Template } from '../../Templates/Template';
import { checkIfBgIsDark } from '../Pages/CoverPage';
import logicLogo from '../../../../../../assets/images/Logo_Type_Light_v_1_20180821.png';
import logicLogoDark from '../../../../../../assets/images/Logo_Type_Dark.png';

export const TemplateSelector = () => {
    const room = getRoom();
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const [selected, setSelected] = useState(report.template);
    const { data, loading } = useQuery(GET_TEMPLATES, {
        onCompleted: (a) => {
            const t = a ? removeAllTypename(a.getTemplates) : [];
            if (selected) {
                const s = t.filter((item) => item.id === selected.id)[0];
                dispatch(setReportAction({ ...report, template: s || false }));
            }
        },
        fetchPolicy: 'no-cache',
    });
    const templates = data ? removeAllTypename(data.getTemplates) : [];
    const { setModal } = useModal();
    const setNewTemplateModal = (template) => {
        setModal({
            header: false,
            size: 'full',
            component: <NewTemplate template={template}
                instance={room.instance.id}
                allTemplates={templates}
                updateReport={updateReport}
            />,
        });
    };

    const setSelectedTemplate = (template) => {
        const isTemplateSelected = selected?.id === template.id;
        updateReport({ template: isTemplateSelected ? false : template });
    };

    const updateReport = ({ template }) => {
        let updatedLogo = report.pages[0].logo;
        if (template) {
            if (!report.pages[0].logo || [logicLogo, logicLogoDark].includes(report.pages[0].logo)) {
                const hasDarkBg = checkIfBgIsDark(template);
                updatedLogo = hasDarkBg ? logicLogo : logicLogoDark;
            }
        }
        dispatch(setReportAction({
            ...report,
            pages: report.pages.map((page, index) => {
                if (index !== 0) return page;
                return ({
                    ...page,
                    logo: updatedLogo
                });
            }),
            template
        }));
    };

    useEffect(() => {
        setSelected(report.template);
    }, [report.template]);

    const [collapse, setCollapse] = useState(true);

    return (
        <Row className="flex-row-reverse" noGutters>
            <CreatedBy selected={selected}
                collapse={collapse}
            />
            {loading && <Loading background="rgba(250,250,250,.7)" />}
            <Col xs={12} md={9} className="border-right">
                <div className="position-relative h-100 pt-4 pr-4">
                    <EditTemplate collapse={collapse}
                        selected={selected}
                        setNewTemplateModal={setNewTemplateModal}
                        setCollapse={setCollapse}
                    />
                    <Collapse isOpen={collapse}>
                        <Row>
                            {templates.map((template) => (
                                <Template template={template}
                                    key={template.id}
                                    selected={selected}
                                    setReportAction={setReportAction}
                                    dispatch={dispatch}
                                    report={report}
                                    onClick={() => setSelectedTemplate(template)}
                                />
                            ))}
                            <CreateNew setNewTemplateModal={setNewTemplateModal} />
                        </Row>
                    </Collapse>
                </div>
            </Col>
        </Row>
    );
};
