import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Button, CustomInput } from 'reactstrap';
import { getRoom } from '../../utils/variables';
import { Loading } from '../Loading';
import { GET_SUBCATEGORY_DOMAIN } from '../Search/Filters/SourceFilter';

export const SubcategoryCheckboxFilter = ({ filters: sourceData, values, setValues, shownDetails, setShownDetails, setCollapseAll }) => {
    const room = getRoom();
    const [domainsList, setDomainsList] = useState(sourceData.subCategories);
    useEffect(() => {
        setDomainsList(sourceData.subCategories);
    }, [sourceData]);

    const [loadAllDomains, { loading }] = useLazyQuery(GET_SUBCATEGORY_DOMAIN, {
        onCompleted: (data) => {
            if (data.getContentTypesWithDomains[0].domains) {
                setDomainsList(data.getContentTypesWithDomains[0].domains.map(domain => ({ key: domain, label: domain })));
            }
        },
    });
    const onSeeMoreDomainsRequest = (contentType) => {
        loadAllDomains({ variables: {
            projectId: room?.project_id,
            filters: {
                contentType
            },
            size: sourceData.domainCount
        } });
    };

    const isParentChecked = Boolean(values.find(item => item.key === sourceData.key));
    const currentValue = values.find(value => value.key === sourceData.key);
    const toggleDomainList = (key) => {
        setCollapseAll(false);
        setShownDetails(() => ({
            ...shownDetails,
            [key]: !shownDetails[key],
        }));
    };
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <Checkbox checked={isParentChecked}
                    value={sourceData.key}
                    label={`${sourceData.label} (${sourceData.domainCount})`}
                    onChange={(checked) => {
                        if (checked) {
                            setValues([...values, { key: sourceData.key, subCategories: sourceData.subCategories.map(({ key }) => key) }]);
                        } else {
                            setValues(values.filter(item => item.key !== sourceData.key));
                        }
                    }}
                />
                {sourceData.domains.length > 0 && (
                    <span onClick={() => toggleDomainList(sourceData.key)} className="cursor-pointer">
                        {shownDetails[sourceData.key]
                            ? <span className="text-primary"><ChevronUp /> </span>
                            : <span className="text-primary"><ChevronDown /></span>}
                    </span>
                )}
            </div>
            <div className={`pl-3 ${shownDetails[sourceData.key] ? 'd-block' : 'd-none'}`}>
                {domainsList.map((filter) => (
                    <div key={filter.key}>
                        <Checkbox checked={currentValue && currentValue?.subCategories?.includes(filter.key)}
                            value={filter.key}
                            label={filter.label}
                            onChange={(checked) => {
                                if (checked) {
                                    if (!isParentChecked) {
                                        setValues(
                                            [
                                                ...values,
                                                {
                                                    key: sourceData.key,
                                                    subCategories: [filter.key]
                                                }
                                            ]
                                        );
                                    } else {
                                        setValues(values.map(value => {
                                            if (value.key !== sourceData.key) return value;
                                            return {
                                                key: value.key,
                                                subCategories: [...(currentValue?.subCategories || []), filter.key]
                                            };
                                        }));
                                    }
                                } else {
                                    setValues(values.map(value => {
                                        if (value.key !== sourceData.key) return value;
                                        return {
                                            key: value.key,
                                            subCategories: value.subCategories.filter(item => item !== filter.key)
                                        };
                                    }));
                                }
                            }}
                        />
                    </div>
                ))}
                {loading && <Loading relative />}
                {!loading && domainsList.length !== sourceData.domainCount && sourceData.domainCount > 100 && (
                    <Button color="link" onClick={() => onSeeMoreDomainsRequest(sourceData.key)}>
                        +{sourceData.domainCount - 100} more domains
                    </Button>
                )}
            </div>
        </>
    );
};

const Checkbox = ({ checked, value, label, onChange }) => (
    <CustomInput type="checkbox"
        checked={checked}
        className={`custom-control-input-big${value.length > 19 ? ' dont-break-out' : ''}`}
        label={label}
        id={`filter-checkbox-${value}`}
        data-testid={`checkbox-${value}`}
        value={value}
        onChange={({ target }) => {
            onChange(target.checked);
        }}
    />
);
