import React from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { BooleanVersionModal } from '../Modals/BooleanVersionModal/BooleanVersionModal';
import { STATUS_TYPES, StatusModalBodyNew } from '../../../utils/SuccessErrorModal';
import { useUpdateBooleanVersion } from '../Modals/BooleanVersionModal/useUpdateBooleanVersion';
import { DeleteVersionModal } from '../Modals/DeleteVersionModal/DeleteVersionModal';
import { useDeleteBooleanVersion } from '../Modals/DeleteVersionModal/useDeleteBooleanVersion';
import { useDropDown } from '../../../utils/useDropdown';

type QueryProps = {
    projectId: string,
    situationRoom: string,
    booleanTitle?: string
}

type BooleanVersionContextMenuProps = {
    query: QueryProps,
    getBooleanVersions: () => void,
    goToPreviousVersion: () => void,
    goToNextVersion: () => void,
    selectedIndex: number,
    isCurrentQuery: boolean
}

export const BooleanVersionContextMenu = ({
    query,
    getBooleanVersions,
    goToPreviousVersion,
    goToNextVersion,
    selectedIndex,
    isCurrentQuery
}: BooleanVersionContextMenuProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const { setModal, closeModal } = useModal();
    const { updateBooleanVersion } = useUpdateBooleanVersion();
    const { deleteBooleanVersion } = useDeleteBooleanVersion();
    const setBooleanVersionModal = (onConfirm: (e: {title: string, notes: string}) => Promise<void>) => {
        setModal({
            header: false,
            component: <BooleanVersionModal query={query} onConfirm={onConfirm} onCancel={closeModal} modalTitle="Edit Version" />
        });
    };
    const setDeleteVersionModal = ({ callback }: { callback: () => Promise<void> }) => {
        setModal({
            header: false,
            component: <DeleteVersionModal onConfirm={callback} onCancel={closeModal} />
        });
    };
    const setStatusModal = ({ message, title, type }: {message: string, title: boolean, type: string}) => {
        setModal({
            header: false,
            component: <StatusModalBodyNew title={title} message={message} messageType={type} />
        });
    };
    const handleEditRoomClick = () => setBooleanVersionModal(({ title, notes }) => updateBooleanVersion({
        variables: { projectId: query.projectId, booleanTitle: title, notes }
    })
        .then(() => {
            getBooleanVersions();
            closeModal();
            setStatusModal({
                title: false,
                type: STATUS_TYPES.success,
                message: 'Situation room version updated successfully'
            });
        })
        .catch(() => {
            setStatusModal({
                title: false,
                type: STATUS_TYPES.error,
                message: 'Error occurred, Please try again later'
            });
        }));

    const handleDeleteVersionClick = () => {
        setDeleteVersionModal({
            callback: async () => {
                try {
                    await deleteBooleanVersion({ variables: { situationRoom: query.situationRoom, projectId: query.projectId } });
                    getBooleanVersions();
                    if (selectedIndex === 0) {
                        goToNextVersion();
                    } else {
                        goToPreviousVersion();
                    }
                    setStatusModal({
                        title: false,
                        type: STATUS_TYPES.success,
                        message: 'Situation room version deleted successfully'
                    });
                    closeModal();
                } catch (err) {
                    setStatusModal({
                        title: false,
                        type: STATUS_TYPES.error,
                        message: 'Error occurred, Please try again later'
                    });
                }
            }
        });
    };

    return (
        <div onClick={toggleDropdown}>
            <Dropdown onClick={(e: React.MouseEvent<any>) => { e.preventDefault(); }}
                className="d-inline pl-1"
                isOpen={dropdown}
                direction={window.innerWidth < 540 ? 'left' : 'right'}
                toggle={() => null}
                data-testid={`booleanVersion-${query.projectId}-dropdown`}
            >
                <DropdownToggle className="btn bg-transparent pb-0 pt-0 align-dropdown-toggle"
                    onClick={(e: React.MouseEvent<any>) => { e.preventDefault(); }}
                    tag="button"
                    data-testid={`booleanVersion-${query.projectId}-dropdownToggle`}
                >
                    <span><i className="fa fa-ellipsis-v text-secondary" /></span>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>
                        <span onClick={handleEditRoomClick}>{' '}Edit Version</span>
                    </DropdownItem>
                    {!isCurrentQuery && (
                        <DropdownItem>
                            <span onClick={handleDeleteVersionClick}>{' '}Delete Version</span>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
