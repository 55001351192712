import React from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { saveCustomNarratives } from '../../../../store';

export const TopicItem = ({ removeTopic, topic }) => {
    const dispatch = useDispatch();
    const { selectedTopic } = useSelector(state => state.customNarratives);

    return (

        <div onClick={() => { dispatch(saveCustomNarratives({ selectedTopic: topic })); }}
            color={(topic.keywords.length === 0 && !topic.boolean
            && selectedTopic.threat !== topic.threat) ? 'danger' : ''}
            className={`topic mb-1 mr-1 ${selectedTopic.threat === topic.threat ? 'selected' : ''} cursor-pointer`}
        >
            <span className="dont-break-out">
                {topic.threat.trim()}
            </span>
            <button className="btn"
                type="button"
                onClick={(e) => { removeTopic(); e.stopPropagation();
                    e.preventDefault(); }}
            >
                <X />
            </button>
        </div>

    ); };
