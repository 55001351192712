import React from 'react';
import { WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../services/Content/contentInterface';
// import { CONTENT_TYPE } from '../../utils/contentTypes';
import { RemoveBulkContentButton } from './RemoveBulkContentButton';
import { RemoveContentButton } from './RemoveContentButton';

type RemoveContentButtonsProps = {
    allSelected: boolean
    total: number
    selectedContent: Pick<Content, WatchlistButtonContentKeys>[]
    onCompleted: () => void
    onAbort: () => void
    onAction: () => void
    contentType: string
}

export const RemoveContentButtons = ({ allSelected, total, selectedContent, onCompleted,
    onAbort, onAction, contentType }: RemoveContentButtonsProps) => {
    if (allSelected) {
        return (
            <RemoveBulkContentButton contentType={contentType}
                onCompleted={onCompleted}
                onAbort={onAbort}
                onAction={onAction}
            />
        );
    }

    return (<RemoveContentButton selectedContent={selectedContent} onCompleted={onCompleted} contentType={contentType} />);
};
