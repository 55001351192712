import React, { ReactNode } from 'react';
import { Icon } from 'react-feather';
import { NavigationItems } from './NavigationItems';

export type Links = {
    id: string,
    icon?: Icon,
    path: string,
    name: string
}
export type Category = {
    name: string,
    links: [Links]
}
type SidebarMenuProps = {
    children: ReactNode,
    menu: Category[],
    collapse?: boolean
}

export const SidebarMenu = ({ children, menu, collapse = true }: SidebarMenuProps) => (
    <div>
        <div className={`left-side-menu scrollbar ${!collapse ? 'no-collapse' : ''}`} data-testid="left-side-menu">
            <div className="sidebar-content">
                <div id="sidebar-menu">
                    {menu.map(category => (
                        <NavigationItems key={category.name} category={category} />
                    ))}
                </div>
            </div>
        </div>
        <div className="with-side-bar">
            {children}
        </div>
    </div>
);
