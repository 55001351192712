import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownWithSearchAlphabetical } from '../components/DropdownWithSearchAlphabetical';
import { TOP_PUBLISHERS } from '../../../services/Overview/topPublishers';
import { getRoom } from '../../../utils/variables';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';

type Actor = {
    publisher: string
}

type ActorsFilterProps = {
    clusters: boolean
}

export const ActorsFilter = ({ clusters = false }: ActorsFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { from: selectedActors }: { from: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const room = getRoom();
    const { projectId } = location.state || {};
    const { data, loading } = useQuery(!clusters ? TOP_PUBLISHERS : GET_TOP_ACTORS_IN_CLUSTERS, {
        variables: {
            size: clusters ? 1000 : 100,
            projectId: room?.project_id || projectId
        }
    });
    let topActors = [];
    if (clusters) {
        topActors = data?.getTopActorsInClusters?.data || [];
    } else {
        topActors = data?.topPublishers?.data || [];
    }
    const filteredActorsData = topActors.filter((a: Actor) => a?.publisher);

    const actorsData = filteredActorsData.map((actor: Actor) => ({
        id: actor.publisher,
        name: actor.publisher,
        selected: selectedActors.includes(actor.publisher)
    }));

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            from: selectedActors.includes(id)
                ? selectedActors.filter(actor => actor !== id)
                : [...selectedActors, id]
        }));
    };
    const countFilters = selectedActors.length;

    return (
        <DropdownWithSearchAlphabetical placeholder="Search for actors"
            items={actorsData}
            handleSelectItem={handleSelectItem}
            name={countFilters > 0
                ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
            loading={loading}
        />
    );
};

export const GET_TOP_ACTORS_IN_CLUSTERS = gql`
    query getTopActorsInClusters(
        $projectId: String
        $size: Int
    ) {
        getTopActorsInClusters(
            projectId: $projectId
            size: $size 
        ) {
            data {
                publisher
                count
            }
            total    
        }
    }
`;
