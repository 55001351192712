import { transform } from 'lodash';
import { NetworksHistoryState } from '../../../store';

type Position = { x: number, y: number, id: string };

export const formatNetworksData = (data: NetworksHistoryState) => {
    const positions: Position[] = data.chartState.positions ? transform(
        data.chartState.positions,
        (result, value, key) => result.push({ id: key, ...value }),
        [] as Position[]
    ) : [];
    return {
        ...data,
        chartState: {
            ...data.chartState,
            positions
        }
    };
};
