/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { HelpCircle, Plus } from 'react-feather';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'reactstrap';
import { TooltipItem } from '../../../../components/ToolTip';
import { useRoomEditor } from '../RoomEditorContext';
import verifiedIcon from '../../../../assets/images/verifiedIcon.svg';

export const SuggestionsBox = ({ title, tooltip, keywords, field, type, accountFromTo }) => (
    <div className="border rounded px-2 py-1 mb-2">
        <p className="m-0">
            <strong>{title}</strong>
            {tooltip && (
                <TooltipItem placement="bottom"
                    id={title.replaceAll(' ', '-').toLowerCase()}
                    content={tooltip}
                    Icon={HelpCircle}
                    color="#6C63FF"
                />
            )}
        </p>
        {keywords.length ? (
            <div className="mt-1">
                <KeywordList keywords={keywords} field={field} type={type} accountFromTo={accountFromTo} title={title} />
            </div>
        ) : (
            <p className="mb-0">
                No {title.toLowerCase()} available
            </p>
        )}
    </div>
);

export const KeywordList = ({ keywords, field, type, accountFromTo, title }) => {
    const { room, setRoom } = useRoomEditor();
    const addKeyword = (keyword) => {
        setRoom({
            ...room,
            query: {
                ...room.query,
                [field]: [
                    ...room.query[field],
                    ...(type === 'socialAccounts' ? [{
                        value: keyword.value,
                        type: keyword.type,
                        ...accountFromTo
                    }] : [keyword])
                ]
            }
        });
    };
    const filteredKeywords = [...keywords].filter(keyword => {
        const findKeyword = (item) => {
            if (type === 'socialAccounts') {
                return item.value === keyword.value && (item.toAccount || item.fromAccount);
            }
            return item.value === keyword.value;
        };
        return !room.query.included.find(findKeyword) && !room.query.excluded.find(findKeyword);
    });
    const handleAddAll = () => {
        setRoom({
            ...room,
            query: {
                ...room.query,
                [field]: [
                    ...room.query[field],
                    ...(type === 'socialAccounts' ? filteredKeywords.map(keyword => ({
                        value: keyword.value,
                        type: keyword.type,
                        ...accountFromTo
                    })) : filteredKeywords)
                ]
            }
        });
    };
    return (
        <Droppable droppableId="keywords">
            {(droppableProvided) => (
                <div ref={droppableProvided.innerRef} className="d-flex flex-wrap align-items-center">
                    {filteredKeywords.map((keyword, index) => (
                        <Draggable key={keyword.value}
                            draggableId={JSON.stringify({
                                ...keyword,
                                ...(type === 'socialAccounts' ? accountFromTo : {})
                            })}
                            index={index}
                        >
                            {(provided) => (
                                <div ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={provided.draggableProps.style}
                                >
                                    <div className="tag mb-1 mr-1"
                                        key={keyword.value}
                                        onClick={() => addKeyword(keyword)}
                                        role="button"
                                    >
                                        <span className="dont-break-out">
                                            {keyword.value.trim()}
                                        </span>
                                        {keyword.verified && <img src={verifiedIcon} alt="verfied icon" height="15" className="pl-1" />}
                                        <button className="btn"
                                            type="button"
                                        >
                                            <Plus />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {filteredKeywords.length ? (
                        <Button color="link"
                            className="mt-0 mb-1 ml-1 text-link p-0 mw-0"
                            onClick={handleAddAll}
                        >
                            Add all
                        </Button>
                    ) : (
                        <p className="mb-0">
                            No {title.toLowerCase()} available
                        </p>
                    )}
                </div>
            )}
        </Droppable>
    );
};
