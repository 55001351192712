import { scaleLinear } from 'd3';
import { getCtype } from '../../../../../../../utils/text';

export const formatMapData = (data) => {
    if (!data.length) { return {}; }
    const max = data[0].doc_count;
    const min = data[data.length - 1].doc_count;
    const scale = scaleLinear().domain([min, max]).range([0.2, 1.5]);

    return Object.fromEntries(data.map(item => {
        const ctype = getCtype(item.sources.buckets.length ? item.sources.buckets[0].key : '');
        const coordinates = item.key.split(',');
        return [
            item.key,
            {
                // label: { text: data.date },
                type: 'content',
                data: { ...data },
                fontIcon: { text: ctype.icon, color: ctype.iconColor, fontFamily: ctype.FontFamily },
                coordinates: {
                    lat: parseInt(coordinates[0], 10),
                    lng: parseInt(coordinates[1], 10),
                },
                color: ctype.nodeColor,
                size: scale(item.doc_count)
            }
        ];
    }));
};
