import React from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '../../context/modal/ModalComponent';

type TdWithLinkProps = {
    children: React.ReactNode
    to: string
    className?: string
    linkClassName?: string
    disabled: boolean
    target?: string
}

export const TdWithLink = ({ children, to, className = '', linkClassName = '', disabled = false, target }: TdWithLinkProps) => {
    const { closeModal } = useModal();
    if (disabled) {
        return <td className={className}>{children}</td>;
    }
    return (
        <td className={`p-0 ${className}`} onClick={closeModal}>
            <Link to={to} target={target} className={linkClassName || 'py-2 px-3'}>
                {children}
            </Link>
        </td>
    );
};
