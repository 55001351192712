import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AddPageDropdown } from './AddPageDropdown';
import { Page } from './Page';
import { PagesList } from './PagesList';
import { Toolbar } from './Toolbar';

export const Pages = () => {
    const { report } = useSelector(state => state.createReport);
    return (
        <Row noGutters>
            <Col xs={12} md={9} className="border-right position-relative">
                <Toolbar />
                <div className="py-4 pr-4">
                    {report.pages.map((page, index) => <Page key={page.id} data={page} pageNumber={index + 1} />)}
                </div>
            </Col>
            <Col xs={12} md={3}>
                <AddPageDropdown />
                <PagesList />
            </Col>
        </Row>
    );
};
