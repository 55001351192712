import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../../useFilters';

export const useCoOccurence = ({ keywords = [] }) => {
    const filters = useContentFilters({ useGlobalFilters: true });
    const ret = useQuery(CO_OCCURENCE, {
        variables: {
            ...filters,
            keywordsToExclude: keywords
        }
    });

    return {
        ...ret,
        data: ret.data?.coOccurence?.map(item => ({ ...item, value: item.keyword })) || []
    };
};

export const CO_OCCURENCE = gql`
    query coOccurence(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $keywordsToExclude: [String]
    ) {
        coOccurence(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery,
            keywordsToExclude: $keywordsToExclude
        ) {
            keyword
            count
        }
    }
`;
