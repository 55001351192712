import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { getRoom } from '../../../utils/variables';

type RemoveBulkContentButtonProps = {
    onBatchContentRemove?: () => void
}

export const RemoveBulkContentButton = ({ onBatchContentRemove }: RemoveBulkContentButtonProps) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();

    if (!onBatchContentRemove) {
        return null;
    }

    const setRemoveContentConfirmationModal = () => {
        setModal({
            header: 'Remove this content?',
            component: (
                <>
                    <p>
                        Selected contents will be removed from {room.project_name}.
                        You won&apos;t be able to see these contents within this room.
                        These contents can be restored under room settings, removed content.
                    </p>
                    <hr className="my-2" />
                    <Button className="mr-2" onClick={closeModal}>Cancel</Button>
                    <Button color="danger" className="mr-2" onClick={() => onBatchContentRemove()}>Remove</Button>
                </>
            )
        });
    };

    return (
        <Button color="danger" onClick={setRemoveContentConfirmationModal} data-testid="bulk-remove-contents-button">
            Remove contents
        </Button>
    );
};
