import React, { useState } from 'react';
import { X as Close } from 'react-feather';
import { Button, Row, Card, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { closeFilters, saveFilters, saveWatchlistFilters } from '../../pages/Room/store';
import { DateFilter, SourceFilter, SentimentFilter, ThreatFilter } from './Filters';
import { OriginsFilter } from './Filters/OriginsFilter';
import { LanguageFilter } from './Filters/LanguageFilter';

type SearchFiltersProps = {
    onSaveFilters: () => void,
    additionalFilters: string[],
    hiddenFilters?: string[],
    watchlist?: boolean
}

export const SearchFilters = ({ onSaveFilters, additionalFilters, hiddenFilters = [], watchlist = false }: SearchFiltersProps) => {
    const dispatch = useDispatch();
    const filters = useSelector((state: any) => (watchlist ? state.watchlistFilters.filters : state.filters.filters));
    const dateStampStart = filters?.dateRange?.startDate ? moment.unix(filters.dateRange.startDate).format('L') : null;
    const dateStampEnd = filters?.dateRange?.endDate ? moment.unix(filters.dateRange.endDate).format('L') : null;
    let defaultInputRangeText = '';
    if (dateStampStart && dateStampEnd) {
        defaultInputRangeText = `${dateStampStart} - ${dateStampEnd}`;
    }

    const [dateRangeOption, setRangeOption] = useState(filters.dateRangeOption);
    const [dateRange, setRange] = useState(filters.dateRange);
    const [sourcesWithDomains, setSourcesWithDomains] = useState(filters.sourcesWithDomains);
    const [origin, setOrigin] = useState(filters.origin);
    const [sentiment, setSentiment] = useState(filters.sentiment);
    const [threatTypes, setTypes] = useState([]);
    const [inputRange, setInputRange] = useState(defaultInputRangeText);
    const [languages, setLanguages] = useState(filters.languages);
    const handleReset = () => {
        setRangeOption('all');
        setRange({});
        setOrigin([]);
        setSentiment([]);
        setTypes([]);
        setInputRange('');
        setSourcesWithDomains([]);
        setLanguages([]);
    };
    const handleApply = () => {
        if (watchlist) {
            dispatch(saveWatchlistFilters({
                dateRangeOption,
                dateRange,
                origin,
                sentiment,
                threatTypes,
                sourcesWithDomains,
                languages
            }));
        } else {
            dispatch(saveFilters({
                dateRangeOption,
                dateRange,
                origin,
                sentiment,
                threatTypes,
                sourcesWithDomains,
                languages
            }));
        }
        onSaveFilters();
    };

    const handleCloseFilters = () => {
        dispatch(closeFilters());
    };

    return (
        <Card body outline color="primary" className="bg-light border">
            <div className="d-flex align-items-center justify-content-between mb-2">
                <h3 className="font-weight-bold m-0">Filters</h3>
                <Close size={16} role="button" onClick={handleCloseFilters} data-testid="filters-x-icon" />
            </div>
            <Row className="align-items-stretch">
                <Col sm={12} md={6} xl={4} className="p-2 flex-basis-0 flex-grow-1 mw-220">
                    <div className="bg-white rounded p-3 h-100">
                        <DateFilter dateRangeOption={dateRangeOption}
                            setRangeOption={setRangeOption}
                            setRange={setRange}
                            inputRange={inputRange}
                            setInputRange={setInputRange}
                            dateRange={dateRange}
                        />
                    </div>
                </Col>
                {!hiddenFilters.includes('ctype') && (
                    <Col sm={12} md={6} xl={4} className="p-2 flex-basis-0 flex-grow-1 mw-220">
                        <div className="bg-white rounded p-2 h-100">
                            <SourceFilter sourcesWithDomains={sourcesWithDomains}
                                setSourcesWithDomains={setSourcesWithDomains}
                            />
                        </div>
                    </Col>
                )}
                {!hiddenFilters.includes('origin') && (
                    <Col sm={12} md={6} xl={4} className="p-2 flex-basis-0 flex-grow-1 mw-220">
                        <div className="bg-white rounded p-2 h-100">
                            <OriginsFilter selected={origin} setSelected={setOrigin} />
                        </div>
                    </Col>
                )}
            </Row>
            <Row className="align-items-stretch">
                {!hiddenFilters.includes('language') && !watchlist && (
                    <Col sm={12} md={6} xl={4} className="p-2 flex-basis-0 flex-grow-1 mw-220">
                        <div className="bg-white rounded p-2 h-100">
                            <LanguageFilter languages={languages} setLanguages={setLanguages} />
                        </div>
                    </Col>
                )}
                {!hiddenFilters.includes('sentiment') && (
                    <Col sm={12} md={6} xl={4} className="p-2 flex-basis-0 flex-grow-1 mw-220">
                        <div className="bg-white rounded p-2 h-100">
                            <SentimentFilter sentiment={sentiment} setSentiment={setSentiment} />
                        </div>
                    </Col>
                )}
                {additionalFilters?.includes('whyathreat') && (
                    <Col sm={12} md={6} xl={4} className="p-2 flex-basis-0 flex-grow-1 mw-220">
                        <div className="bg-white rounded p-2 h-100">
                            <ThreatFilter threatTypes={threatTypes} setTypes={setTypes} />
                        </div>
                    </Col>
                )}
            </Row>
            <Col className="d-flex mt-3">
                <Button color="primary" onClick={handleApply} className="mr-3" data-testid="apply-filters-button">Apply Filters</Button>
                <Button color="danger" className="border-0" onClick={handleReset}>Reset</Button>
            </Col>
        </Card>
    );
};
