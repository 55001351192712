import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Dropdown } from '../../../../components/Form/Dropdown';
import { UPDATE_USER } from '../../../../graphql';

export const EditDetails = ({ userData, instances }) => {
    const history = useHistory();
    const dropdownOptions = instances.map(instance => ({ key: instance?.id, name: instance?.clientName }));
    const [instance, setInstance] = useState({ key: userData.instance?.id, name: userData.instance?.clientName });
    const [role, setRole] = useState(userData?.role);
    const [updateUser, { loading }] = useMutation(UPDATE_USER, {
        onCompleted: () => history.push('/admin/allusers')
    });
    const handleReset = () => {
        setInstance(dropdownOptions[0]);
        setRole(userData?.role);
    };
    const handleSave = () => {
        updateUser({
            variables: {
                data: {
                    uid: userData.uid,
                    role,
                    instance: instance.key
                }
            }
        });
    };
    return (
        <div className="p-3 bg-white">
            <p className="font-weight-bold">Instance</p>
            <Dropdown options={dropdownOptions}
                value={instance}
                onChange={setInstance}
                className="w-50"
                search
            />
            <p className="font-weight-bold mt-3">Role</p>
            <div className="d-flex">
                <Label>
                    <input className="mb-n11"
                        type="radio"
                        id="admin"
                        name="admin"
                        value="admin"
                        checked={(role) === 'ADMIN'}
                        onChange={() => setRole('ADMIN')}
                    />
                    Admin
                </Label>
                <Label className="ml-2">
                    <input type="radio"
                        className="mb-n11"
                        id="team"
                        name="team"
                        value="team"
                        checked={role === 'TEAM'}
                        onChange={() => setRole('TEAM')}
                    />
                    Team
                </Label>
            </div>
            <hr className="w-100 my-3" />
            <Button disabled={loading} color="primary" onClick={() => handleSave()}>Save</Button>
            <Button className="ml-3" onClick={() => handleReset()}>Reset</Button>
        </div>
    );
};
