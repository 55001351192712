import { useQuery } from '@apollo/client';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { Loading } from '../../../../components/Loading';
import { GET_INSTANCES, GET_USER } from '../../../../graphql';
import { EditDetails } from './EditDetails';
import { useModal } from '../../../../context/modal/ModalComponent';
import { RemoveUserModal } from './RemoveUserModal';
import { BackButton } from '../../../../components/Atoms/BackButton';

export const UserDetail = () => {
    const match = useRouteMatch();
    const { setModal, closeModal } = useModal();
    const { loading, data: userData } = useQuery(GET_USER, {
        variables: { uid: match.params.id },
        fetchPolicy: 'cache-and-network'
    });
    const user = userData?.user;
    const { data: instancesData, loading: instancesLoading } = useQuery(GET_INSTANCES);
    const instances = instancesData?.instances || [];
    const handleRemoveUser = () => {
        setModal({
            component: <RemoveUserModal userData={user} closeRemoveModal={closeModal} />
        });
    };
    if (loading || instancesLoading) {
        return <Loading />;
    }
    if (!user) {
        return (
            <div>
                <BackButton pathName="/admin/allusers" />
                <p>Could not find user</p>
            </div>
        );
    }
    const bread = [
        { title: 'Admin home', href: '/admin' },
        { title: 'Users', href: '/admin/allusers' }, { title: `${user.displayName || 'NA'}` }
    ];

    return (
        <div className="page-padding">
            <BreadCrumbs items={bread} />
            <BackButton pathName="/admin/allusers" />
            {
                (loading || instancesLoading) ? <Loading /> : (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h2 className="m-0">{user.displayName || 'Not available'}</h2>
                            <Button color="link" className="text-danger" onClick={handleRemoveUser}>Remove user</Button>
                        </div>
                        <EditDetails userData={user} instances={instances} />
                    </>
                )
            }
        </div>
    );
};
