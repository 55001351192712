import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyState } from './EmptyState';
import { Pages } from './Pages';
import { TemplateSelector } from './TemplateSelector';
import { ReportName } from './ReportName';

export const AddData = () => {
    const { report } = useSelector(state => state.createReport);

    return (
        <div>
            <ReportName />
            <EmptyState field="name" />
            {report?.name && (
                <>
                    <hr className="mb-0 mt-4" />
                    <TemplateSelector />
                    <hr className="mb-0 mt-0" />
                    <EmptyState field="template" />
                    {report.template && <Pages />}
                </>
            )}
        </div>
    );
};
