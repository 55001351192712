import React from 'react';
import { Button, Progress } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Images } from '../../../../utils/modeSwitch';
import { TooltipItem } from '../../../../components/ToolTip';
import { useTimeRemaining, UseTimeRemainingProps } from './useTimeRemaining';

type FindingNarrativesProps = UseTimeRemainingProps

export const FindingNarratives = ({ timeRemaining, startTime, refetchStatus }: FindingNarrativesProps) => {
    const { progress, timeLeft } = useTimeRemaining({ timeRemaining, startTime, refetchStatus });

    return (
        <div>
            <img src={Images().loading}
                alt="loading..."
                className="d-block m-auto"
                style={{ height: 80 }}
            />
            <div className="my-3">
                <p>
                    Finding narratives
                    <TooltipItem id="FindingNarrativesTooltip"
                        placement="bottom"
                        content={FindingNarrativesTooltip}
                    />
                </p>
                <div className="bg-light rounded">
                    <div style={{ width: `${progress}%`, backgroundColor: '#4caf50', height: '100%', borderRadius: '5px' }} />
                </div>
                <Progress value={progress} style={{ width: 600 }} />
                <div className="d-flex align-items-center justify-content-between my-1">
                    <p className="m-0">ETA {moment.duration(timeLeft * 1000).humanize()}</p>
                    <p className="m-0">{Math.round(progress)}%</p>
                </div>
                <hr />
                <div className="d-flex justify-content-end">
                    <Link component={Button}
                        className="ml-auto"
                        to={{ pathname: 'https://logically-intelligence.readme.io/docs/ai-narratives' }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn more
                    </Link>
                </div>
            </div>
        </div>
    );
};

const FindingNarrativesTooltip = `We find narratives by putting textual data through advanced algorithms that detect patterns, 
sentiments, and relationships. This process involves the extraction of Keywords, Actors, and Sentiments, which are then presented 
as a Narrative Theme or Narrative.`;
