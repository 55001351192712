import React from 'react';
import { Button } from 'reactstrap';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { GET_REMOVED_CONTENT } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { resetAll } from '../../store';

export const DownloadCSV = () => {
    const room = getRoom();
    const dispatch = useDispatch();
    const [getData] = useLazyQuery(GET_REMOVED_CONTENT, {
        onCompleted: (data) => {
            exportToExcel(data?.getRemovedContent.data.map(item => {
                const formatted = { ...item,
                    ...item?.additional_info,
                    ...item?._id };
                delete formatted.additional_info;
                delete formatted.threat_object;
                delete formatted._id;
                delete formatted.from;
                delete formatted.created_by;
                return formatted; }));
            dispatch(resetAll());
        },
        fetchPolicy: 'no-cache',
    });
    const downloadRemovedData = () => {
        getData({
            variables: {
                projectId: room?.project_id,
                limit: 20000,
                offset: 0
            },
        });
    };

    const exportToExcel = (apiData) => {
        const csvExporter = new ExportToCsv({
            title: `${room?.project_name}_RemovedContent`,
            useKeysAsHeaders: true,
            filename: `${room?.project_name}_RemovedContent`
        });
        csvExporter.generateCsv(apiData.map(a => {
            const ret = {};
            for (const key of Object.keys(a)) {
                if (a[key]) { ret[key] = a[key]; } else { ret[key] = 'NA'; }
            }
            return ret;
        }));
    };
    return (
        <Button className="set-max-content ml-2 mb-1" onClick={() => downloadRemovedData()}>Download as CSV</Button>
    );
};
