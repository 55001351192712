import { useMutation } from '@apollo/client';
import React from 'react';
import { Spinner } from 'reactstrap';
import { UPDATE_ADMIN_USER, GET_ADMIN_USERS } from '../../../../graphql';
import { removeTypename } from '../../../../utils/removeAllTypename';

export const ChangeUserRole = ({ adminUser }) => {
    const [updateAdminUser, { loading }] = useMutation(UPDATE_ADMIN_USER, {
        refetchQueries: [GET_ADMIN_USERS]
    });
    const changeUserRole = () => {
        const usr = { ...adminUser };
        usr.role = adminUser.role === 'ADMIN' ? 'TEAM' : 'ADMIN';
        updateAdminUser({
            variables: { data: removeTypename(usr) }
        });
    };
    if (loading) { return <Spinner size="sm" color="primary" className="mx-auto d-block" />; }
    return (
        <span className="text-primary cursor-pointer" onClick={() => changeUserRole()}>
            Change to {`${adminUser.role === 'ADMIN' ? 'team' : 'admin'}`}
        </span>
    );
};
