import React from 'react';
import { LocationMentions } from '../../types';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';

type LocationsProps = {
    locations: LocationMentions[]
}

export const Locations = ({ locations }: LocationsProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], location: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={location} />,
        size: 'xl'
    });
    return (
        <div className="bg-white border p-2">
            {
                !locations ? <p className="m-0">No data found</p>
                    : locations.map((location, index) => {
                        if (index > 4) return null;
                        return (
                            <div key={location.location} className={index !== 0 ? 'mt-1' : ''}>
                                <p className="btn btn-link d-inline p-0"
                                    onClick={() => openDuplictedDocsModal(location.contentIds, location.location)}
                                >{location.location}
                                </p> - {location.contentIds.length}
                            </div>
                        ); })
            }
        </div>
    );
};
