import React from 'react';
import moment from 'moment';
import { Alert, Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import { ObjectID } from 'bson';
import { RadioInput } from '../../../../components/Atoms';
import { useRoom } from './RoomContext';
import { GET_ALL_SITUATION_ROOMS, UPDATE_SITUATION_ROOM } from '../../../../graphql';
import { SelectClient } from './SelectClient';

export const EditRoomDetails = () => {
    const { room, setRoom, changesMade, saveRoom, roomBackup } = useRoom();

    const [updateRoom, { loading, error }] = useMutation(UPDATE_SITUATION_ROOM, {
        refetchQueries: [GET_ALL_SITUATION_ROOMS]
    });
    const handleUpdateRoom = () => {
        updateRoom({
            variables: {
                ...pick(room, ['id', 'action', 'deleted', 'archived', 'paused', 'project_id']),
                instance: room.instance.id || room.instance
            }
        }).then(() => {
            saveRoom();
        });
    };

    return (
        <div className="p-3 bg-white">
            <p className="font-weight-bold">Project ID</p>
            <p className="mb-3">{roomBackup.project_id}</p>
            <p className="font-weight-bold mt-3">Actions</p>
            <RadioInput label="Active"
                name="status"
                id="active"
                checked={room.action === 'create'}
                onChange={() => setRoom({
                    ...room,
                    action: 'create',
                    deleted: false,
                    archived: false,
                    paused: false
                })}
                className="mb-1"
            />
            <RadioInput label="Archived"
                name="status"
                id="archived"
                checked={room.archived}
                onChange={() => setRoom({
                    ...room,
                    archived: true,
                    deleted: false,
                    action: 'delete',
                    paused: false
                })}
                className="mb-1"
            />
            <RadioInput label="Deleted"
                name="status"
                id="deleted"
                checked={room.deleted}
                onChange={() => setRoom({
                    ...room,
                    deleted: true,
                    archived: false,
                    action: 'delete',
                    paused: false
                })}
                className="mb-1"
            />
            <RadioInput label="Paused"
                name="status"
                id="paused"
                checked={room.paused}
                onChange={() => setRoom({
                    ...room,
                    deleted: false,
                    archived: false,
                    action: 'delete',
                    paused: true
                })}
            />
            <p className="font-weight-bold mt-3">Instance</p>
            <SelectClient value={room.instance.id || room.instance}
                onChange={value => setRoom({
                    ...room,
                    instance: value,
                    project_id: new ObjectID().toString()
                })}
            />
            <p className="font-weight-bold mt-3">Created date</p>
            <p className="mb-3">{moment(new Date(room.createdAt)).format('DD/MM/YYYY h:mm A')}</p>
            <p className="font-weight-bold">Created by</p>
            <p>{room.actuallyCreatedBy?.displayName || room.createdBy?.displayName}</p>
            <hr className="my-3" />
            <Button disabled={!changesMade || loading} color="primary" onClick={handleUpdateRoom}>Save</Button>
            <Alert className="mt-2 mb-0" color="danger" isOpen={Boolean(error)}>
                Something went wrong, please try again later.
            </Alert>
        </div>
    );
};
