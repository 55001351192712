import React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { Cluster, SubCluster } from '../types';
import { IconButton } from '../../../../components/Atoms';
import { largeNumber } from '../../../../utils/NumberFormat';
import { getSentimentIcon } from '../../../../components/Organisms/ContentTableView/SentimentCard';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';

type EngagementAndSentimentProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    clusterData: SubCluster[] | Cluster[];
};

export const EngagementAndSentiment = ({ isCollapsed, setCollapsable, clusterData }: EngagementAndSentimentProps) => (
    <>
        <div className="d-flex align-items-center gap-1 cursor-pointer" onClick={setCollapsable}>
            <IconButton
                className="icon-button text-decoration-none"
                icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                text={<WidgetTextTooltip widgetName={WidgetName.Engagement} noMargin className="ml-2" />}
            />
            <p className="mb-0">and</p>
            <WidgetTextTooltip widgetName={WidgetName.Sentiment} noMargin />
        </div>
        <div className="grid_container_narrative mb-4 mt-3">
            {
                isCollapsed && [0, 1, 2, 3].map((_, index) => {
                    if (clusterData[index]) { return (
                        <div className="bg-white" key={clusterData[index].id}>
                            <div className="border p-2">
                                <p className="m-0">{largeNumber(clusterData[index].engagement)}</p>
                            </div>
                            <div className="p-2 border">
                                {getSentimentIcon(clusterData[index].averageSentiment)}
                                {capitalizeFirstLetter(clusterData[index].averageSentiment)}
                            </div>
                        </div>
                    ); }
                    return (
                        <div className="border p-2" key={_} />
                    );
                })
            }
        </div>
    </>
);
