import React from 'react';
import { Source } from '../../types';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getCtype } from '../../../../../utils/text';

type SourcesProps = {
    topSources: Source[]
}

export const Sources = ({ topSources }: SourcesProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], sourceName: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={sourceName} />,
        size: 'xl'
    });
    return (
        <div className="bg-white border p-2">
            {
                !topSources ? <p className="m-0">No data found</p>
                    : topSources.map((source, index) => {
                        if (index > 4) return null;
                        const { name } = getCtype(source.source);
                        return (
                            <div key={source.source} className={index !== 0 ? 'mt-1' : ''}>
                                <p className="btn btn-link d-inline p-0"
                                    onClick={() => openDuplictedDocsModal(source.contentIds, name)}
                                >{name}
                                </p> - {source.contentIds.length}
                            </div>
                        ); })
            }
        </div>
    );
};
