import React from 'react';
import { ChevronRight } from 'react-feather';
import { useRoomEditor } from './RoomEditorContext';
import { useRoomNameAvailability } from './inputs/useRoomNameAvailability';
import { getToast } from '../../../utils/getToast';

export const Tabs = ({ setActiveTab, activeTab }) => {
    const toast = getToast();
    const { room } = useRoomEditor();
    const { nameNotAvailable } = useRoomNameAvailability({ roomName: room.project_name });
    const NotToBeActive = (nameNotAvailable || !room.project_name || !room?.reason);

    const handleRoomDetailsClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setActiveTab('room-details');
    };
    const handleRoomCreationClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (nameNotAvailable || !room.project_name || !room?.reason) {
            toast.error('Fill all the must have room details');
        }
        else {
            setActiveTab('room-creation');
        }
    };
    const markSelectedTab = (current) => (activeTab === current ? 'selected-tab' : '');

    return (
        <div className={`${activeTab === 'room-creation' ? 'multi-tabs mb-0 mt-4' : 'multi-tabs'}`}>
            <div className={`mr-4 ${markSelectedTab('room-details')}`} onClick={handleRoomDetailsClick}>
                <h4 className="cursor-pointer">Room details</h4>
            </div>
            <ChevronRight className="mr-4 text-dark mb-1" size={22} />
            <div className={`mr-4 ${markSelectedTab('room-creation')} ${NotToBeActive ? 'disabled' : ''}`}
                onClick={handleRoomCreationClick}
            >
                <h4 className={!NotToBeActive ? 'cursor-pointer' : ''}>Room creation</h4>
            </div>
        </div>
    );
};
