import React from 'react';
import * as FeatherIcon from 'react-feather';
import {
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    FormGroup
} from 'reactstrap';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import { color } from '../../utils/getColors';

type DateRangePickerProps = {
    inputRange: string | undefined;
    onDatePickerCallback: (start: Date, end: Date) => void;
    config?: {
        noMaxDate?: boolean;
        drops?: string;
        maxDate?: Date;
        minDate?: Date;
        startDate?: Date;
        endDate?: Date;
        defaultDateRange?: {
            startDate: Date;
            endDate: Date;
        };
        ranges?: {
            Today: [Date, Date];
            Yesterday: [Date, Date];
            'Last 7 Days': [Date, Date];
            'Last 30 Days': [Date, Date];
            'This Month': [Date, Date];
            'Last Month': [Date, Date];
        };
        append?: boolean;
    };
    iconOnly?: boolean;
    selected?: boolean;
}

export const DateRangePicker = ({ inputRange, onDatePickerCallback, config, iconOnly = false, selected = false }: DateRangePickerProps) => {
    const layout = (children: React.ReactElement) => (
        <FormGroup className="mb-0">
            <ReactDateRangePicker onCallback={onDatePickerCallback}
                initialSettings={{
                    opens: 'left',
                    drops: config?.drops || 'up',
                    ...(config?.noMaxDate ? {} : { maxDate: config?.maxDate || new Date() }),
                    ...(config?.minDate ? { minDate: config.minDate } : {}),
                    ...(config?.startDate ? { startDate: config.startDate } : {}),
                    ...(config?.endDate ? { endDate: config.endDate } : {}),
                    ...(config?.defaultDateRange ? config.defaultDateRange : {}),
                    ...(config?.ranges ? { ranges: config.ranges } : {})
                }}
            >
                {children}
            </ReactDateRangePicker>
        </FormGroup>
    );

    if (iconOnly) {
        return layout(
            <button className={`p-1 mw-0 btn btn-${selected ? 'primary' : 'secondary'}`}
                data-testid="date-range-picker-button"
                type="button"
            >
                <FeatherIcon.Calendar />
            </button>
        );
    }

    return layout(
        <button type="button"
            className="btn p-0 cursor-default"
            data-testid="date-range-picker-button"
        >
            <div className="pointer-events-none">
                <InputGroup border="none" className="m-0">
                    {!config?.append && (
                        <InputGroupAddon addonType="prepend" className="mr-0 border-right">
                            <InputGroupText className="border-left-0">
                                <FeatherIcon.Calendar color={color.blue[500]} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                    <Input className="border-right-0"
                        defaultValue={inputRange || ''}
                        data-testid={inputRange ? 'with-input-range' : 'without-input-range'}
                        id={inputRange ? 'filter-time-customdate1' : undefined}
                        readOnly
                    />
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className={`bg-white border-left${!config?.append ? '-0' : ''} p-1`}>
                            {!config?.append
                                ? <FeatherIcon.ChevronDown color={color.blue[500]} />
                                : <FeatherIcon.Calendar color={color.blue[500]} />}
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </button>
    );
};
