import React from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { getRoom } from '../../../../utils/variables';
import { GET_REMOVED_CONTENT, REMOVE_CONTENT_PERMANENTLY } from '../../../../graphql';
import { DeleteSuccessModal } from './DeleteSuccessModal';
import { useModal } from '../../../../context/modal/ModalComponent';

export const PermanentDeleteModal = ({ ids, onSuccess }) => {
    const { setModal, closeModal } = useModal();
    const room = getRoom();
    const [removeContentPermanentRequest, { loading }] = useMutation(REMOVE_CONTENT_PERMANENTLY, {
        refetchQueries: [GET_REMOVED_CONTENT],
        onCompleted: () => {
            onSuccess();
            handleSuccess();
        },
        fetchPolicy: 'no-cache'
    });

    const handleSuccess = () => setModal({
        component: <DeleteSuccessModal />
    });

    const deletePermanently = () => removeContentPermanentRequest({
        variables: {
            projectId: room?.project_id,
            documentIds: ids
        }
    });

    return (
        <>
            <p>
                You and your team won’t be able to see it. This process cannot be undone.
            </p>
            <hr />
            <Button onClick={closeModal}>Cancel</Button>
            <Button color="danger" disabled={loading} className="ml-2" onClick={deletePermanently}>Delete</Button>
        </>
    );
};
