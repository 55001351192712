import React from 'react';
import { useDispatch } from 'react-redux';
import { getIcons } from '../../../../../../utils/getIcons';
import { largeNumber } from '../../../../../../utils/NumberFormat';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';
import { setParam } from '../../../../../../utils/urlParams';
import { saveSelectedCluster } from '../../../../store';

type DuplicatesProps = {
    count?: number | string
    setTab?: (a: string) => void
    onClick?: () => void
    disabled?: boolean
}

export const Duplicates = ({
    count,
    setTab,
    onClick,
    disabled
}: DuplicatesProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        if (disabled) return;
        if (onClick) return onClick();
        setTab?.('content');
        setParam('hasDuplicates', true);
        dispatch(saveSelectedCluster({
            hasDuplicatesFilter: true
        }));
    };
    return (
        <div className="cluster-stat-container" onClick={handleClick}>
            <WidgetTextTooltip widgetName={WidgetName.Duplicates} />
            <span className="font-weight-bold d-flex align-items-center">{getIcons('duplicates')}
                <p className={`m-0 ml-1 font-weight-normal ${disabled ? '' : 'btn btn-link d-inline p-0 mw-0'}`}>
                    {largeNumber(count)}
                </p>
            </span>
        </div>
    );
};
