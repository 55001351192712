import React from 'react';
import { Keyphrase } from '../../types';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { truncateString } from '../../../../../utils/text';

type KeywordsProps = {
    keywords: Keyphrase[]
}

export const Keywords = ({ keywords }: KeywordsProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], keyword: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={keyword} />,
        size: 'xl'
    });
    return (
        <div className="bg-white border p-2">
            {
                !keywords ? <p className="m-0">No data found</p>
                    : keywords.map((keyword, index) => {
                        if (index > 4) return null;
                        return (
                            <div key={keyword.keyword} className={index !== 0 ? 'mt-1' : ''}>
                                <p className="btn btn-link d-inline p-0"
                                    onClick={() => openDuplictedDocsModal(keyword.contentIds, keyword.keyword)}
                                >{truncateString(keyword.keyword, 15)}
                                </p> - {keyword.contentIds.length}
                            </div>
                        ); })
            }
        </div>
    );
};
