import React, { useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import { Dropdown } from '../../../../components/Form/Dropdown';
import { rgbToHex, getRGB, ctypeFontOptions } from '../Utils';
import { ActionButtons } from './ActionButtons';
import { CheckBox } from '../../../../components/Form/CheckBox';
import { ContentType } from '../../../../utils/variables';

type SourceBodyProps = {
    sourceData: ContentType;
}

export const SourceBody = ({ sourceData }: SourceBodyProps) => {
    const [ctypeData, setCtypeData] = useState(sourceData);
    const [font, setFont] = useState(sourceData?.FontFamily?.includes('Brands') ? ctypeFontOptions[1] : ctypeFontOptions[0]);
    return (
        <div className="bg-white">
            <Row className="m-0">
                <Col xs={10} md={5} className="p-3">
                    <p>Key</p>
                    <Input data-testid="ctype-key-input"
                        value={ctypeData.key}
                        onChange={(e) => setCtypeData({ ...ctypeData, key: e.target.value })}
                    />
                    <p className="mt-3">Name</p>
                    <Input data-testid="ctype-name-input"
                        value={ctypeData?.name}
                        onChange={(e) => setCtypeData({ ...ctypeData, name: e.target.value })}
                    />
                </Col>
                <Col xs={10} md={7} className="p-3 op-border-left">
                    <p>Icon font</p>
                    <Dropdown options={ctypeFontOptions}
                        value={font}
                        onChange={setFont}
                        className="w-50"
                    />
                    <p className="mt-3">Icon name</p>
                    <Input data-testid="ctype-icon-input"
                        value={ctypeData?.icon}
                        onChange={(e) => setCtypeData({ ...ctypeData, icon: e.target.value })}
                        className="w-50"
                    />

                    <p className="mt-3">Icon color</p>
                    <div className="d-flex align-items-center">
                        <Input data-testid="ctype-color-input"
                            value={ctypeData?.nodeColor}
                            className="mr-2 w-25"
                            onChange={(e) => setCtypeData({ ...ctypeData,
                                color: getRGB(e.target.value),
                                nodeColor: e.target.value })}
                        />
                        <Input type="color"
                            className="ctype-selection-box"
                            value={rgbToHex(ctypeData?.color.split(','))}
                            onChange={(e) =>
                                setCtypeData({ ...ctypeData,
                                    color: getRGB(e.target.value),
                                    nodeColor: e.target.value })}
                        />
                        <i className={`font-size-28 fa ${ctypeData?.icon} ml-2`}
                            style={
                                font?.name
                                    ? { fontFamily: `"${font?.name}"`, color: `rgb(${ctypeData?.color})` }
                                    : { color: `rgb(${ctypeData?.color})` }
                            }
                        />
                    </div>
                </Col>
            </Row>
            <hr className="m-0" />
            <div className="p-3">
                <p><strong>Others</strong></p>
                <CheckBox id="disable-engagement"
                    label="Disable engagement"
                    name="bob"
                    value="bob"
                    checked={ctypeData.disableEngagement}
                    onChange={() => setCtypeData({ ...ctypeData, disableEngagement: !ctypeData.disableEngagement })}
                />
            </div>
            <hr className="m-0" />
            <ActionButtons ctypeData={ctypeData} sourceData={sourceData} setCtypeData={setCtypeData} font={font} />
        </div>
    );
};
