import { uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { createNode } from './createNode';
import { NetworkNode } from '../../useNetworks';
import { NetworksHistoryStore } from '../../../store';

export const useHashtagNodes = (data: NetworkNode[]) => {
    const { includeHashtags } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    if (!includeHashtags) return {};
    const allHashtags = uniq(data.map(({ hashtags }) => hashtags).flat());
    return Object.fromEntries(
        allHashtags.map(hashtag => [
            `hashtag_${hashtag}`,
            {
                ...createNode({
                    text: hashtag,
                    fontIcon: 'fa-hashtag'
                }),
                data: {
                    type: 'hashtag',
                    id: hashtag
                }
            }
        ])
    );
};
