import { convertToMarkers } from '../../convertToMarkers';

export const domainTagValidator = (query, queryByLines, lineLengths, tag) => {
    const highlight = [];

    const tagValue = tag.replace('domain:', '');
    const tagValueSplit = tagValue.split('.');
    if (tagValueSplit.length < 2 || tagValueSplit[0].split(':')[0].match(/http|https|www|\//gi) || tagValue.endsWith('.')) {
        [...query.matchAll(new RegExp(`domain:\\s*${tagValue}`, 'g'))].forEach((match) => {
            highlight.push([match.index, match.index + match[0].length]);
        });
    }

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have entered an invalid domain. Please check the Boolean help for guidance.'
        )
    };
};
