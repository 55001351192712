import React from 'react';
import { getIcons } from '../../../../../../utils/getIcons';
import { largeNumber } from '../../../../../../utils/NumberFormat';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';

type EngagementProps = {
    count?: number | string
    disabled?: boolean
}

export const Engagement = ({ count, disabled }: EngagementProps) => (
    <div className="cluster-stat-container">
        <WidgetTextTooltip widgetName={WidgetName.Engagement} />
        <span className="font-weight-bold d-flex align-items-center">{getIcons('engagement')}
            <p className={`m-0 ml-1 font-weight-normal ${disabled ? '' : 'text-primary cursor-pointer'}`}>
                {largeNumber(count)}
            </p>
        </span>
    </div>
);
