import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { Loading } from '../../../components/Loading';
import { SortToggle } from '../../../components/Table/SortToggle';
import { GET_SITUATION_ROOMS } from '../../../graphql';
import { lodashSort } from '../../../utils/lib/tableSorting';
import noRoomsAssigned from '../../../assets/images/noRoomsAssigned.svg';

export const RoomsList = ({ instance, user }) => {
    const { data, loading } = useQuery(GET_SITUATION_ROOMS, {
        variables: { instance, user },
    });
    const rooms = data ? data.situationRooms?.data : [];
    const [sort, setSort] = useState({ field: 'project_name', sort: 'dsc' });
    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            {!loading && rooms.length > 0 ? (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <div className="d-flex">
                                    <SortToggle sort={sort} setSort={setSort} field="project_name" name={`Room name (${rooms.length})`} />
                                </div>
                            </th>
                            <th>
                                <div className="d-flex">
                                    <SortToggle sort={sort} setSort={setSort} field="is_active" name="Status" />
                                </div>
                            </th>
                            <th>
                                <div className="d-flex">
                                    <SortToggle sort={sort} setSort={setSort} field="createdAt" name="Created at" />
                                </div>
                            </th>
                            <th>
                                <div className="d-flex">
                                    <SortToggle sort={sort} setSort={setSort} field="private" name="Privacy" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {lodashSort([...rooms], sort).map(room => (
                            <tr key={room.id}>
                                <td><a href={`/situation-rooms/${room.id}`}>{room.project_name}</a></td>
                                <td>
                                    <p className="d-flex align-items-center">
                                        <span className={`d-flex mr-1 status-${room.is_active ? 'Active' : 'Paused'}`} />
                                        {room.is_active ? 'Active' : 'Paused'}
                                    </p>
                                </td>
                                <td>{moment(new Date(room.createdAt)).format('DD MMM YYYY')}</td>
                                <td>{room.private ? 'Private' : 'Public'}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )
                : (
                    <div className="d-flex pt-4 mt-4 align-items-center">
                        <img src={noRoomsAssigned} alt="" className="flex-fill" height="200" />
                    </div>
                )}
        </div>
    );
};
