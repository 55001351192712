import { useMutation } from '@apollo/client';
import { GET_WATCHLIST, REMOVE_FROM_WATCHLIST } from '../../graphql';
import { getToast } from '../../utils/getToast';

type UseRemoveFromWatchlistProps = {
    situationRoomId: string
    onCompleted?: () => void;
    onError?: (data: any) => void;
}

export const useRemoveFromWatchlist = ({
    situationRoomId,
    onCompleted,
    onError
}: UseRemoveFromWatchlistProps) => {
    const toast = getToast();
    const [removeFromWatchlistRequest, { loading }] = useMutation(REMOVE_FROM_WATCHLIST, {
        refetchQueries: [{ query: GET_WATCHLIST, variables: { situationRoom: situationRoomId } }],
        onCompleted: () => {
            toast.success("You've removed the selected content from the watchlist.");
            onCompleted?.();
        },
        onError
    } as any);
    const removeFromWatchlist = async ({ contentId }: any) => {
        if (!situationRoomId) {
            throw new Error('Situation Room Id is required');
        }
        if (!contentId) {
            throw new Error('Threat content id is required');
        }

        await removeFromWatchlistRequest({
            variables: {
                situationRoom: situationRoomId,
                contentId
            }
        });
    };

    return { removeFromWatchlist, loading };
};
