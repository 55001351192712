import { convertToMarkers } from '../convertToMarkers';

export const balancedParenthesesValidator = (query, queryByLines, lineLengths) => {
    const errorFlag = 'ERRORbracket';
    const stack = [];
    const output = query.split('');

    for (let i = 0; i < output.length; i++) {
        const currentCharacter = output[i];
        // Opening bracket, push into stack
        if (currentCharacter === '(') {
            stack.push(i);

            // Closing bracket
        } else if (currentCharacter === ')') {
            // If this closing bracket is unmatched
            if (stack.length === 0) {
                output[i] = errorFlag;
            } else {
                // Replace balanced parentheses with 0 (opening) and 1 (closing)
                output[i] = '1';
                output[stack[stack.length - 1]] = '0';
                stack.pop();
            }
        }
    }

    // If parentheses are not balanced mark them as error in the string
    while (stack.length > 0) {
        output[stack[stack.length - 1]] = errorFlag;
        stack.pop();
    }

    // Extract the index ranges if invalid parentheses
    const highlight = [];
    [...output.join('').matchAll(/ERRORbracket/g)].forEach((match) => {
        let startIndex;
        // First invalid parentheses
        if (highlight.length === 0) {
            startIndex = match.index;
        } else {
            startIndex = match.index - ((errorFlag.length - 1) * highlight.length);
        }
        highlight.push([startIndex, startIndex + 1]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'Please ensure proper closure/opening of parentheses.'
        )
    };
};
