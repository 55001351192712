export const convertESInterval = (interval, date) => {
    if (!interval) { return 0; }
    // years
    if (interval.includes('y')) {
        const years = parseInt(interval.replace('y', ''), 10);
        return years * 365 * 24 * 60 * 60 * 1000;
    }
    // months
    if (interval.includes('M')) {
        const months = parseInt(interval.replace('M', ''), 10);
        return months * (date ? new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 0).getDate() : 30) * 24 * 60 * 60 * 1000;
    }
    // weeks
    if (interval.includes('w')) {
        const weeks = parseInt(interval.replace('w', ''), 10);
        return weeks * 7 * 24 * 60 * 60 * 1000;
    }
    // days
    if (interval.includes('d')) {
        const days = parseInt(interval.replace('d', ''), 10);
        return days * 24 * 60 * 60 * 1000;
    }
    // hours
    if (interval.includes('h')) {
        const hours = parseInt(interval.replace('h', ''), 10);
        return hours * 60 * 60 * 1000;
    }
    return 0;
};
