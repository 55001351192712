import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Spinner } from 'reactstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useModal } from '../../../../context/modal/ModalComponent';
import { SaveFilterModal } from './SaveFilterModal';
import { getRoom } from '../../../../utils/variables';
import { RoomFilterProps, SaveFilterTypes } from '../../FiltersLayout';
import { removeTypename } from '../../../../utils/removeAllTypename';
import { getToast } from '../../../../utils/getToast';
import { RootState } from '../../../../store';
import { useGetActiveQueries } from '../../../../services/getActiveQueries';

type FilterItemProps = {
    id: string,
    name: string,
    selected: boolean
}

export type FiltersDataProps = {
    filter: string,
    items: FilterItemProps[]
}

type SaveFilterProps = {
    text: string,
    filterId?: string,
    userID?: string,
    filterName?: string,
    setOpenEditSavedFIlter?: (e: boolean) => void,
    filterNamesList?: string[],
    roomFilters: RoomFilterProps[],
    defaultName?: string,
    overview?: boolean,
    clusters?: boolean,
    setSaveFilter?: (e: boolean) => void
}

export const SaveFilterButton = ({ text, filterId, userID, filterName, filterNamesList, roomFilters, defaultName = '',
    setOpenEditSavedFIlter, overview = false, clusters = false, setSaveFilter }: SaveFilterProps) => {
    const { setModal } = useModal();
    const allFilters = useSelector((state: RootState) => state.selectedFilters.filters);
    const room = getRoom();
    const location = useLocation();
    const roomId = location.pathname.split('/')[2];

    const handleAllFiltersData = () => {
        const finalData = [
            { filter: 'Sources', items: allFilters.contentType },
            { filter: 'Languages', items: allFilters.languages },
            { filter: 'Keywords', items: allFilters.keywords },
            { filter: 'Actors', items: allFilters.from },
            { filter: 'Sentiment', items: allFilters.sentiment },
            { filter: 'Labels', items: allFilters.labels },
            { filter: 'ThreatType', items: overview || clusters ? [] : allFilters.threatTypes },
            { filter: 'Locations', items: allFilters.origin },
            { filter: 'Entities', items: clusters ? [] : allFilters.entities },
            { filter: 'Mentions', items: clusters ? [] : allFilters.mentions },
            { filter: 'Hashtags', items: clusters ? [] : allFilters.hashtags }
        ];
        return finalData;
    };
    const checkForChangesInFilters = () => {
        let filterNotUpdated = false;
        const editFilters = roomFilters.filter(c => c.id === filterId)?.map((a) => removeTypename(a.data?.map((b) => removeTypename(b))));
        if (_.isEqual(editFilters[0], handleAllFiltersData())
        && _.isEqual(removeTypename(roomFilters.filter(c => c.id === filterId)[0].dateRange), allFilters.dateRange)
        && defaultName === filterName) {
            filterNotUpdated = true;
        }
        return filterNotUpdated;
    };

    const checkIfCurrentFilterIsEmpty = () => {
        let noOptionSelected = true;
        handleAllFiltersData().forEach((a) => {
            if (a.items.length !== 0) {
                noOptionSelected = false;
            }
        });
        if (!_.isEqual(allFilters.dateRange, {
            startDate: 0,
            endDate: 0,
        })) {
            noOptionSelected = false;
        }
        return noOptionSelected;
    };

    const toast = getToast();

    const refetchQueries = useGetActiveQueries(['getRoomFilters', 'getRoomFiltersInClusters']);

    const [saveFilters, { loading }] = useMutation(SAVE_ROOM_FILTERS, {
        refetchQueries,
        onCompleted: () => {
            toast.success('Filter saved');
            if (setOpenEditSavedFIlter) {
                setOpenEditSavedFIlter(false);
            }
        },
        onError: () => {
            toast.error('Filter not saved');
        }
    });

    const handleOpenSaveFilterModal = () => {
        if (checkIfCurrentFilterIsEmpty() && setSaveFilter) {
            setSaveFilter(true);
            toast.error('Resolve all errors before continuing');
        } else {
            setModal({
                header: 'Save filters',
                component: (
                    <SaveFilterModal filtersData={handleAllFiltersData()}
                        roomId={room.id}
                        filterNamesList={filterNamesList}
                        roomFilters={roomFilters || []}
                        clusters={clusters}
                    />
                )
            });
        }
    };
    const handleSaveFilters = () => {
        if (checkForChangesInFilters()) {
            return toast.error('Update the filters or name to save the filter');
        }
        if (checkIfCurrentFilterIsEmpty() || !filterName?.trim() || filterNamesList?.includes(filterName?.trim())) {
            toast.error('Resolve all errors before continuing');
        } else {
            saveFilters({ variables: {
                data: {
                    name: filterName,
                    situationRoom: room.id || roomId,
                    data: handleAllFiltersData(),
                    user: userID,
                    dateRange: allFilters.dateRange
                },
                id: filterId,
                text: clusters ? 'clusters' : 'overview'
            } });
        }
    };

    return (
        <Button data-testid="save-filter-button"
            onClick={() => { text === SaveFilterTypes.SaveFilter ? handleOpenSaveFilterModal() : handleSaveFilters(); }}
            disabled={loading}
            color={(text !== 'Save filter') ? 'primary' : 'secondary'}
        >{!loading ? text : <Spinner className="ml-1" size="sm" color="white" />}
        </Button>

    );
};

export const SAVE_ROOM_FILTERS = gql`
    mutation saveRoomFilters($data: roomFiltersInput, $id: ID,$text: String) {
        saveRoomFilters(data: $data, id: $id, text: $text) {
            id
            name
            user {
                displayName
            }
            data {
                filter
                items
            }
            dateRange {
                startDate
                endDate
            }
        }
    }
`;
