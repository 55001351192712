import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import ErrorImg from '../../assets/images/magnify-screen.gif';

export const NotFound = ({ small }) => (
    <Container className={`${!small ? 'content-page' : ''} d-flex justify-content-center align-items-center error-page`}>
        <Row className="align-items-center">
            <Col xs={12} md={6}>
                <img src={ErrorImg} alt="" className="w-100" />
            </Col>
            <Col xs={12} md={6}>
                <h1 className="text-primary mt-0">We looked everywhere.</h1>
                <h2 className="m-0" data-testid="notfound">Looks like this page is missing.</h2>
            </Col>
        </Row>
    </Container>
);
