import React from 'react';
import { useUser } from '../../../context/user/UserContext';
import { useGetInstance } from '../../../services/Instance/getInstance';

type TeamRoleRestrictedProps = {
    children: React.ReactNode;
}

export const TeamRoleRestricted = ({ children }: TeamRoleRestrictedProps) => {
    const { user } = useUser();
    const { instance } = useGetInstance({ instanceId: user?.instance?.id });

    if (!instance?.plan?.restrictedTeamRole || (!!instance?.plan?.restrictedTeamRole && user.role === 'ADMIN')) {
        return children || null;
    }

    return null;
};
