import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../utils/variables';

export const LikelihoodContextModal = ({ contentId }: {contentId?: string}) => {
    const { closeModal } = useModal();
    const room = getRoom();

    return (
        <>
            <p>
                Using <a href="https://logically-intelligence.readme.io/docs/glossary" target="_blank" rel="noopener noreferrer">Natural Language Processing</a> (NLP) AI, we categorise our threats with likelihood scores
                for a more specific assessment. We analyse written language within content to determine the meaning,
                intent and sentiment behind the it. By combining language rules with advanced machine learning techniques,
                this enables us to evaluate and categorise content effectively. We use two main labels:
            </p>
            <div className="d-flex my-2">
                <div className="p-2 bg-lightgray mr-2 signal-tag">
                    <p className="high-threat-tag py-11 px-12 set-fit-content">Highly likely</p>
                    <p>This indicates a high degree of certainty based on our analysis.</p>
                </div>
                <div className="p-2 bg-lightgray signal-tag">
                    <p className=" low-threat-tag py-11 px-12 set-fit-content">Likely</p>
                    <p>This suggests a significant but slightly lower level of confidence.</p>
                </div>
            </div>
            <h3>Feedback:</h3>
            <p className="mb-2">
                While we strive for accuracy, we don&apos;t always get things right.
                We welcome your feedback on our scores to help us improve, you can do so by
                reviewing the threats in the
                {contentId ? (
                    <a href={`/situation-rooms/${room.id}/threats/${contentId}`}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="link-details"
                    > details
                    </a>
                ) : ' details'} of the content.
            </p>
            <Button color="secondary" onClick={() => closeModal()}>Close</Button>
        </>
    );
};
