import { Monaco, EditorProps, OnMount } from '@monaco-editor/react';
import { FROM_TAG_SOURCES, MENTIONS_TAG_SOURCES } from './BooleanValidationErrors';
import { color } from '../../../utils/getColors';

export const OPERATORS = ['AND', 'OR', 'NOT', 'NEAR'];
export const TAGS = ['domain', 'url', 'links', 'country', 'title', 'language', 'https', 'http', 'engagementType',
    'engagingWithGuid'];
export const ACCOUNT_TAGS = ['from', 'mentions', 'engagingWith'];

export const ERROR_MARKER = 8;
export const HIGHLIGHT_ERROR_MARKER = 4;

export const editorOptions: EditorProps['options'] = {
    fontFamily: '"Nunito", sans-serif',
    fontSize: 16,
    fixedOverflowWidgets: true,
    occurrencesHighlight: 'off',
    wordWrap: 'on',
    autoIndent: 'advanced',
    formatOnPaste: true,
    formatOnType: true,
    matchBrackets: 'near',
    stopRenderingLineAfter: -1,
    unicodeHighlight: {
        ambiguousCharacters: false
    },
    contextmenu: false,
    smoothScrolling: true,
    overviewRulerLanes: 1,
    minimap: {
        enabled: false
    },
    padding: {
        top: 16
    },
    lineNumbersMinChars: 3,
    guides: { indentation: false }
};

export const handleEditorWillMount = (
    monaco: Monaco,
    setCompletionDisposable: (e: any) => void
) => {
    monaco.languages.register({ id: 'boolean' });
    monaco.languages.setMonarchTokensProvider('boolean', {
        OPERATORS,
        TAGS,
        tokenizer: {
            root: [
                [/NEAR\/\d*/, 'operator'],
                [/@?[a-zA-Z][\w$]*/, {
                    cases: { '@OPERATORS': 'operator', '@default': 'keyword', '@TAGS': 'keyword' }
                }],
                [/".*?"/, 'string']
            ]
        }
    });
    monaco.editor.defineTheme('boolean-theme', {
        base: 'vs-dark',
        inherit: true,
        colors: {
            'editor.background': color.grey.white,
            'editor.foreground': color.darkblue[700],
            'editor.lineHighlightBorder': color.grey.white,
            'editorHoverWidget.background': color.grey.white,
            'editorHoverWidget.foreground': color.darkblue[700],
            'editorBracketHighlight.foreground1': '#000000',
            'editorBracketHighlight.foreground2': '#000000',
            'editorBracketHighlight.foreground3': '#000000',
            'editorBracketHighlight.foreground4': '#000000',
            'editorBracketHighlight.foreground5': '#000000',
            'editorBracketMatch.background': color.grey[400],
            'editorBracketMatch.border': color.grey[400],
            'menu.background': color.grey.white,
            'menu.foreground': color.darkblue[700],
            'input.background': color.grey.white,
            'editorWidget.background': color.grey.white,
            'editorWidget.foreground': color.darkblue[700],
            'editorWidget.border': color.darkblue[700],
            'editorWidget.resizeBorder': color.grey.white,
            'editor.findMatchBackground': color.yellow[300],
            'editor.findMatchHighlightBackground': color.yellow[300],
            'editor.selectionBackground': color.yellow[300],
            'editorError.foreground': 'transparent',
            'editorOverviewRuler.errorForeground': color.red[200],
            'editorOverviewRuler.warningForeground': color.red[200],
            'editorOverviewRuler.selectionHighlightForeground': color.grey.white,
            'editorLineNumber.foreground': color.grey[400],
            'editorLineNumber.activeForeground': color.darkblue[700],
            'editorSuggestWidget.foreground': color.darkblue[700],
            'editorSuggestWidget.selectedForeground': color.darkblue[700],
            'editorSuggestWidget.matchedForeground': color.blue[400],
            'editorSuggestWidget.background': color.grey.white,
            'editorSuggestWidget.selectedBackground': color.blue[100],
            'editorSuggestWidget.border': color.blue[100],
            'list.hoverBackground': color.blue[100],
            'input.foreground': color.darkblue[700],
            'input.placeholderForeground': color.grey[400],
            'inputOption.hoverBackground': 'transparent',
            fontFamily: '"Nunito", sans-serif',
        },
        rules: [
            { token: '' },
            { token: 'operator', foreground: color.graphColors.robinsEggBlue },
            { token: 'keyword', foreground: color.darkblue[700] },
            { token: 'string', foreground: color.graphColors.tomato }
        ]
    });

    setCompletionDisposable(monaco.languages.registerCompletionItemProvider('boolean', {
        triggerCharacters: [':'],
        provideCompletionItems: (
            model,
            position
        ) => {
            const suggestions = [
                ...OPERATORS.map((operator) => (
                    { label: operator,
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: `${operator}`,
                        range: new monaco.Range(
                            position.lineNumber,
                            position.column - 1,
                            position.lineNumber,
                            position.column + operator.length - 1
                        ) }
                )),
                ...TAGS.map((tag) => ({ label: tag,
                    kind: monaco.languages.CompletionItemKind.Keyword,
                    insertText: `${tag}:`,
                    range: new monaco.Range(
                        position.lineNumber,
                        position.column - 1,
                        position.lineNumber,
                        position.column + `${tag}:`.length - 1
                    ) })),
                ...ACCOUNT_TAGS.map((tag) => ({ label: tag,
                    kind: monaco.languages.CompletionItemKind.Keyword,
                    insertText: `${tag}:@`,
                    range: new monaco.Range(
                        position.lineNumber,
                        position.column - 1,
                        position.lineNumber,
                        position.column + `${tag}:@`.length - 1
                    ) }))
            ];

            const lineContent = model.getLineContent(position.lineNumber).substr(0, position.column - 1);
            const fromMatch = lineContent.match(/from:"?@(\S+):\w*$/);
            const mentionsMatch = lineContent.match(/mentions:"?@(\S+):\w*$/);

            if (fromMatch) {
                return {
                    suggestions: FROM_TAG_SOURCES.map(suggestion => ({
                        label: suggestion,
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: suggestion,
                        range: new monaco.Range(
                            position.lineNumber,
                            position.column,
                            position.lineNumber,
                            position.column + suggestion.length - 1
                        ),
                    }))
                };
            }

            if (mentionsMatch) {
                return {
                    suggestions: MENTIONS_TAG_SOURCES.map(suggestion => ({
                        label: suggestion,
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: suggestion,
                        range: new monaco.Range(
                            position.lineNumber,
                            position.column,
                            position.lineNumber,
                            position.column + suggestion.length - 1
                        ),
                    }))
                };
            }
            const filterConditions = [undefined, ' ', '@'];
            const showSuggestions = filterConditions.includes(lineContent[lineContent.length - 2]);
            if (lineContent[lineContent.length - 1] !== ':' && showSuggestions) {
                return { suggestions };
            }

            return { suggestions: [] };
        }
    }));

    const config = {
        surroundingPairs: [
            { open: '(', close: ')' },
            { open: '"', close: '"' }
        ],
        autoClosingPairs: [
            { open: '(', close: ')' },
            { open: '"', close: '"', notIn: ['string', 'comment'] }
        ],
        indentationRules: {
            decreaseIndentPattern: /^((?!.*?\/\*).*\*\/)?\s*[}\])].*$/,
            increaseIndentPattern: /^((?!\/\/).)*(\{[^}"'`]*|\([^)"'`]*|\[[^\]"'`]*)$/
        }
    };
    monaco.languages.setLanguageConfiguration('boolean', config);
};

export type IStandaloneCodeEditor = Parameters<OnMount>[0];

export const handleEditorDidMount = (
    editor: IStandaloneCodeEditor,
    monaco: Monaco,
    setEditorInstance: (e: IStandaloneCodeEditor) => void,
    setMonacoInstance: (e: Monaco) => void
) => {
    editor.onDidChangeModelContent(() => {
        const model = editor.getModel();
        let text = model?.getValue();

        text = text?.replaceAll(/[\u2018\u2019]/g, "'").replaceAll(/[\u201C\u201D]/g, '"');

        if (text !== model?.getValue()) {
            model?.pushEditOperations(
                [],
                [
                    {
                        range: model?.getFullModelRange(),
                        text: text ?? null
                    }
                ],
                () => null
            );
        }
    });

    setEditorInstance(editor);
    setMonacoInstance(monaco);
};
