import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { Range } from '../../../../../components/Atoms/Range';
import { CheckBox } from '../../../../../components/Form/CheckBox';
import {
    updateSettings,
    NetworksHistoryStore,
    setRegraphLoading,
    NetworksHistoryState,
    resetSettings
} from '../../store';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';

export const NetworkSettings = () => {
    const dispatch = useDispatch();
    const [settingsData, setSettingsData] = useState<Partial<NetworksHistoryState>>();
    const [nodeValue, setNodeValue] = useState(0);
    const {
        includeAuthorsAndMentions,
        includeHashtags,
        includeSharedURLs,
        layout,
        sizeNodesBy,
        clusterBy,
        minimumNodeConnections
    } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);

    const handleUpdateSettings = (data: Partial<NetworksHistoryState>) => {
        setSettingsData(prevSettingsData => ({
            ...prevSettingsData,
            ...data,
        }));
    };

    const handleNodeConnections = (newValue: number) => {
        const cappedValue = Math.min(Math.max(newValue, 0), 10);
        setNodeValue(cappedValue);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        const cappedValue = Math.min(newValue, 10);
        if (!isNaN(cappedValue)) {
            setNodeValue(cappedValue);
            handleNodeConnections(cappedValue);
        } else {
            setNodeValue(0);
            handleNodeConnections(0);
        }
    };
    const handleApply = () => {
        dispatch(updateSettings({ minimumNodeConnections: nodeValue }));
        dispatch(setRegraphLoading(true));
        dispatch(updateSettings(settingsData));
    };

    const handleReset = () => {
        dispatch(setRegraphLoading(true));
        dispatch(resetSettings());
        setNodeValue(0);
    };

    const selectedLayout = nodeLayoutOptions.find(({ key }) => key === settingsData?.layout) || nodeLayoutOptions[0];
    const selectedNodeSize = nodeSizeOptions.find(({ key }) => key === settingsData?.sizeNodesBy) || nodeSizeOptions[0];
    const selectedClusterBy = clusterByOptions.find(({ key }) => key === settingsData?.clusterBy) || clusterByOptions[0];
    const includedHashtags = settingsData?.includeHashtags || false;
    const includedAuthors = settingsData?.includeAuthorsAndMentions || false;
    const includedUrls = settingsData?.includeSharedURLs || false;

    useEffect(() => {
        if (minimumNodeConnections !== nodeValue) {
            setNodeValue(minimumNodeConnections);
        }
        if (!settingsData) {
            setSettingsData({
                layout,
                sizeNodesBy,
                clusterBy,
                includeAuthorsAndMentions,
                includeHashtags,
                includeSharedURLs
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="grid-container-2 mb-3">
                <div className="border-right pr-3">
                    <TooltipWrapper
                        tooltipText={`Node layout determines how the nodes are distributed.
                                            Selecting the correct layout can help detangle links, detect patterns,
                                            anomalies and clusters within a network.`}
                        id="node-layout"
                    >
                        <p className="widget-text-style">Node Layout</p>
                    </TooltipWrapper>

                    <Dropdown options={nodeLayoutOptions}
                        value={selectedLayout}
                        onChange={({ key }) => handleUpdateSettings({ layout: key as NetworksHistoryState['layout'] })}
                        dropdownMenuClass="w-100"
                        className="mb-2"
                    />
                    <TooltipWrapper
                        tooltipText={`Nodes in the network are measured and sized based on your selection.
                                            Sizing nodes can be useful to highlight which nodes have a greater
                                            influence over the network.`}
                        id="size-node-by"
                    >
                        <p className="widget-text-style">Size node by</p>
                    </TooltipWrapper>

                    <Dropdown options={nodeSizeOptions}
                        value={selectedNodeSize}
                        onChange={({ key }) => handleUpdateSettings({ sizeNodesBy: key as NetworksHistoryState['sizeNodesBy'] })}
                        dropdownMenuClass="w-100"
                        className="mb-2"
                    />
                    <TooltipWrapper
                        tooltipText={`Cluster nodes with similar properties, such as what source the node is from,
                                            or the threat level of the node.`}
                        id="cluster-by"
                    >
                        <p className="widget-text-style">Cluster by</p>
                    </TooltipWrapper>
                    <Dropdown options={clusterByOptions}
                        value={selectedClusterBy}
                        onChange={({ key }) => handleUpdateSettings({ clusterBy: key as NetworksHistoryState['clusterBy'] })}
                        dropdownMenuClass="w-100"
                    />
                </div>
                <div className="pl-3">
                    <div className="mb-3">
                        <p>
                            Select minimum node connections
                        </p>
                        <div className="d-flex align-items-center network-settings">
                            <div className="d-flex align-items-center">
                                <span className="pr-1">0</span>
                                <Range min={0}
                                    max={10}
                                    value={nodeValue}
                                    onChange={(newValue: number) => handleNodeConnections(newValue)}
                                    step={1}
                                    className="network-node-bar"
                                />
                                <span className="pl-1 pr-2">10</span>
                            </div>
                            <div className="network-settings-input">
                                <Input placeholder="0"
                                    value={nodeValue != null ? nodeValue.toString() : ''}
                                    // Provide a default value of '' if nodeValue is null or undefined
                                    onChange={handleInputChange}
                                    className="border-rounded network-node-input"
                                    data-testid="minimum-node-connections"
                                />
                            </div>
                        </div>
                    </div>
                    <CheckBox id="include-author-mentions"
                        label="Include Authors & Mentions"
                        name="bob"
                        value="bob"
                        checked={includedAuthors}
                        onChange={() => handleUpdateSettings({ includeAuthorsAndMentions: !includedAuthors })}
                    />
                    <CheckBox id="include-hashtags"
                        label="Include Hashtags"
                        name="bob"
                        value="bob"
                        checked={includedHashtags}
                        onChange={() => handleUpdateSettings({ includeHashtags: !includedHashtags })}
                    />
                    <CheckBox id="include-shared-urls"
                        label="Include Shared URLs"
                        name="bob"
                        value="bob"
                        checked={includedUrls}
                        onChange={() => handleUpdateSettings({ includeSharedURLs: !includedUrls })}
                    />
                </div>
            </div>
            <hr />
            <>
                <Button onClick={handleApply} color="primary" data-testid="network-apply">
                    Apply
                </Button>
                <Button onClick={handleReset} color="link-danger">
                    Reset
                </Button>
            </>
        </>

    );
};

const nodeLayoutOptions = [
    {
        name: 'Organic',
        key: 'organic',
    },
    {
        name: 'Structural',
        key: 'structural',
    },
    {
        name: 'Lens',
        key: 'lens',
    },
    {
        name: 'Radial',
        key: 'radial',
    },
    {
        name: 'Sequential',
        key: 'sequential',
    }
];

const nodeSizeOptions = [
    {
        name: 'Nodes not sized',
        key: '',
    },
    {
        name: 'Content engagement',
        key: 'engagement',
    },
    {
        name: 'Degrees',
        key: 'degrees',
    },
    {
        name: 'Betweenness',
        key: 'betweenness',
    },
    {
        name: 'Page Rank',
        key: 'pageRank',
    },
    {
        name: 'Eigen Centrality',
        key: 'eigenCentrality',
    }
];

const clusterByOptions = [
    {
        name: 'Not grouped',
        key: '',
    },
    {
        name: 'URL',
        key: 'url',
    },
    {
        name: 'Hashtag',
        key: 'hashtag',
    },
    {
        name: 'Source',
        key: 'source'
    },
    {
        name: 'Account',
        key: 'from',
    },
    {
        name: 'Sentiment',
        key: 'sentiment',
    },
    {
        name: 'Threat Level',
        key: 'threatLevel'
    }
];
