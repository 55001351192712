import React from 'react';
import { Input } from 'reactstrap';

import { MultiSelectDropdown } from '../../../../components/Form/MultiSelectDropdown';

export const PageFilters = ({ handleSearchValue, filterRooms, currentTab }) => (
    <div className="mb-4 d-flex align-items-center">
        <div className=" mr-2 flex-fill">
            <Input onChange={(e) => handleSearchValue(e.target.value, 'searchValue')}
                placeholder="Search investigations"
                data-testid={`search-input-${currentTab}`}
            />
        </div>
        <div className="d-flex align-items-center">
            <p className="mr-1 my-0">Filter by situation rooms</p>
            <MultiSelectDropdown options={filterRooms}
                defaultOptions={filterRooms}
                id={currentTab}
                onChange={(e) => handleSearchValue(e, 'situationRooms')}
                position
            />
        </div>
    </div>
);
