import { DocumentNode, useApolloClient } from '@apollo/client';
import { QueryInfo } from '@apollo/client/core/QueryInfo';
import { intersection } from 'lodash';

export const useGetActiveQueries = (toRefetch: string[]) => {
    const client = useApolloClient() as any; // any to get access to private props
    const initialQueries = client.queryManager.queries as Map<string, QueryInfo>;
    const queryDocuments: Record<string, {
        query: DocumentNode | null,
        variables: Record<string, any> | undefined
    }> = {};

    // Convert the iterator to an array
    const allQueries = Array.from(initialQueries.values());
    for (const queryDoc of allQueries) {
        queryDocuments[queryDoc.queryId] = {
            query: queryDoc.document,
            variables: queryDoc.variables
        };
    }

    return intersection(Object.values(queryDocuments).map(doc => {
        const def = doc.query?.definitions[0];
        if (def?.kind === 'OperationDefinition') {
            return def.name?.value ?? '';
        }
        return '';
    }).filter(name => name !== ''), toRefetch);
};
