import React from 'react';
import { diffWords } from 'diff';
import { omit } from 'lodash';

export const HighlightDifferences = ({ before, after }) => {
    const formatObject = (object) => JSON.stringify(omit(object, ['id']), null, 2);
    const diff = diffWords(formatObject(before), formatObject(after));
    return (
        <div>
            {diff.map(({ value, added, removed }) => {
                const className = added ? 'bg-success' : '';
                return (
                    <span key={value} className={removed ? 'bg-danger' : className}>
                        {value}
                    </span>
                );
            })}
        </div>
    );
};
