import { isArray } from 'lodash';

export const findAndReplaceInObject = (data, key, replaceFunction) => {
    const type = typeof data;
    if (isArray(data)) {
        return [...data].map(item => findAndReplaceInObject(item, key, replaceFunction));
    }
    if (type === 'object') {
        const ret = { ...data };
        if (ret[key]) {
            ret[key] = replaceFunction(ret[key]);
        }
        Object.entries(ret).forEach((item) => {
            ret[item[0]] = findAndReplaceInObject(item[1], key, replaceFunction);
        });
        return ret;
    }
    return data;
};
