import React from 'react';
import moment from 'moment';
import * as FeatherIcon from 'react-feather';
import { v4 as uuid } from 'uuid';
import { Button } from 'reactstrap';
import { FurtherInvestigationModal } from './FurtherInvestigationModal';

export const InvestigationsDetail = ({ status, summary, details, email, updatedAt, uploadAttachments, report, onClose }) => (
    <div>
        <h2>Investigation Status</h2>
        <hr />
        <div className="mb-3">
            <h5>Investigation Status</h5>
            <div className={`d-flex align-items-center status-${status === 'Completed' ? 'Active' : 'InProgress'}`}>
                <span className="pl-2 text-nowrap">{status}</span>
            </div>
        </div>
        <div className="mb-3">
            <h5>Investigation title</h5>
            <p>{summary}</p>
        </div>
        <div className="mb-3">
            <h5>Details</h5>
            <p>{details}</p>
        </div>
        <div className="mb-3">
            <h5>Contact email</h5>
            <p>{email}</p>
        </div>
        <div className="mb-3">
            <h5>Requested date</h5>
            <p>{`${moment(updatedAt).format('DD/MM/YYYY, @ hh:mm:ss')}`}</p>
        </div>
        <div className="mb-3">
            <h5>Attachments</h5>
            {uploadAttachments?.map(
                (item) => (
                    <div key={uuid()}>
                        <a target="_blank" href={item} key={item} rel="noreferrer">
                            <FeatherIcon.Paperclip size={15} className="mr-1" />
                            {item?.split('Z')[1]}
                        </a>
                    </div>
                )
            )}
        </div>
        <hr />
        <div className="d-flex">
            {report && (
                <>
                    <a href={report.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button color="primary">
                            <FeatherIcon.Download size={16} className="mr-1" /> Download Report
                        </Button>
                    </a>
                    <FurtherInvestigationModal />
                </>
            )}
            <Button color="secondary" onClick={() => onClose()}>Close</Button>
        </div>
    </div>
);
