import React from 'react';
import { X } from 'react-feather';
import { color } from '../../../../utils/getColors';
import { truncateString } from '../../../../utils/text';

type SelectedNarrativeThemesType = {
    id: string
    title: string
}

type SelectedNarrativeProps = {
    selectedNarrative: SelectedNarrativeThemesType
    removeItem: (id: string) => void
}

export const SelectedNarrative = ({ selectedNarrative, removeItem }: SelectedNarrativeProps) => (
    <div key={selectedNarrative.id} className="tag d-flex align-items-center ml-3">
        <span>{truncateString(selectedNarrative.title, 20)}</span>
        <div className="d-flex align-items-center pl-1 cursor-pointer"
            data-testid={`x-icon-${selectedNarrative.id}`}
            onClick={() => removeItem(selectedNarrative.id)}
        >
            <X color={color.blue[500]} data-testid="remove-narratives" />
        </div>
    </div>
);
