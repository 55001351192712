import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ProfileImage } from '../../../components/Atoms';

export const UserRow = ({ user }) => (
    <tr key={user.uid} className="position-relative">
        <td>
            <Link to={`/team/${user.uid}`} className="underline">
                <ProfileImage photoURL={user.photoURL} className="mr-1" />
                {
                    user.displayName
                }
            </Link>
        </td>
        <td>{user.email}</td>
        <td>{user.role}</td>
        <td>
            <p className="d-flex align-items-center">
                <span className={`d-flex mr-1 status-${user?.status || 'not-available'}`} />
                {user.status || 'Not available'}
            </p>
        </td>
        <td>{user.lastSeen ? moment(new Date(user.lastSeen)).from(moment(new Date())) : 'No data available'}</td>
    </tr>
);
