import * as React from 'react';
import { color } from '../../utils/getColors';

export const DataErrorIcon = () => (
    <svg width={110}
        height={80}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M.466 67.55a4.007 4.007 0 0 0 4.007 4.007h101.054a4 4 0 0 0 3.702-2.473 3.996 3.996 0 0 0 .304-1.534V11.336H.466V67.55Z"
            fill={color.grey[300]}
        />
        <path d="M105.527 0H4.473A4.007 4.007 0 0 0 .466 4.007v7.322h109.065V4.007A4.007 4.007 0 0 0 105.527 0Z"
            fill="#D2D4DB"
        />
        <path d="M7.429 7.456a1.745 1.745 0 1 0 0-3.491 1.745 1.745 0 0 0 0 3.49Z"
            fill={color.grey[200]}
        />
        <path fill={color.grey[300]}
            d="M11.709 7.456a1.745 1.745 0 1 0 0-3.491 1.745 1.745 0 0 0 0 3.49ZM16.252 7.456a1.745 1.745 0 1 0 0-3.491 1.745
        1.745 0 0 0 0 3.49Z"
        />
        <path fill={color.grey[200]}
            d="M38.416 22.02H10.71c-.934 0-1.691.758-1.691 1.692v33.207c0 .934.757 1.691 1.691 1.691h27.704c.934 0 1.692-.757
        1.692-1.69V23.711c0-.934-.758-1.691-1.691-1.691ZM98.676 22.02H50.24c-.934 0-1.69.758-1.69 1.692v12.531c0 .934.756
        1.691 1.69 1.691h48.435c.934 0 1.691-.757 1.691-1.69V23.711c0-.934-.757-1.691-1.691-1.691ZM98.676 41.438H50.24c-.934
        0-1.69.757-1.69 1.691V55.66c0 .934.756 1.692 1.69 1.692h48.435c.934 0 1.691-.758
        1.691-1.692V43.13c0-.934-.757-1.691-1.691-1.691Z"
        />
        <path d="m22.13 34.37-8.752 10.48h17.504L22.13 34.37Z" fill={color.grey[300]} />
        <path d="M28.887 33.192 19.15 44.85H38.62l-9.734-11.658Z" fill="#fff" />
        <path d="M17.116 35.227a2.034 2.034 0 1 0 0-4.069 2.034 2.034 0 0 0 0 4.069Z"
            fill={color.grey[300]}
        />
        <path fill="#D2D4DB"
            d="M93.936 28.196H54.048a.617.617 0 0 1 0-1.235h39.888a.617.617 0 1 1 0 1.235ZM93.936 32.987H54.048a.617.617 0 0
        1 0-1.234h39.888a.618.618 0 0 1 0 1.234ZM93.936 47.612H71.623a.617.617 0 0 1 0-1.234h22.313a.617.617 0 1 1 0
        1.234ZM93.936 52.414H71.623a.617.617 0 1 1 0-1.234h22.313a.617.617 0 0 1 0 1.234Z"
        />
        <path fill="#C8CACE"
            d="M54.998 80c9.048 0 16.383-7.335 16.383-16.383 0-9.047-7.335-16.382-16.383-16.382-9.047 0-16.382 7.335-16.382
        16.383C38.616 72.665 45.95 80 54.998 80Z"
        />
        <path fill="#fff"
            d="M54.999 72.364a8.745 8.745 0 1 1 8.744-8.744 8.754 8.754 0 0 1-8.744 8.744Zm0-16.254a7.51 7.51 0 1 0 7.51 7.51
        7.517 7.517 0 0 0-7.51-7.51Z"
        />
        <path fill="#fff"
            d="M54.998 64.237a.618.618 0 0 1-.617-.618v-3.25a.617.617 0 1 1 1.235 0v3.25a.618.618 0 0 1-.617.618ZM55.008
        67.486a.622.622 0 0 1-.577-.853.61.61 0 0 1 .568-.382.617.617 0 1 1 0 1.235h.01Z"
        />
    </svg>
);
