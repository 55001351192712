import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { TooltipItem } from '../../../../components/ToolTip';
import { useRoomEditor } from '../RoomEditorContext';
import { KeywordsInput } from '../inputs/KeywordsInput';
import { useEditKeywords } from './useEditKeywords';

export const KeywordsEditor = () => {
    const { room, setRoom, errors } = useRoomEditor();
    const {
        includedKeywordError,
        setIncludedKeywordError,
        excludedKeywordError,
        setExcludedKeywordError,
        duplicateKeyword,
        setDuplicateKeyword,
        addKeyword,
        removeKeyword,
        clearKeywords
    } = useEditKeywords({ room, setRoom });

    return (
        <div className="keywords-container">
            <div className="mb-1">
                <p className="font-weight-bold d-inline">Keywords</p>
                <TooltipItem id="Keywords"
                    content="Enter keywords that you want to monitor for in your Situation Room.
                This could be your own name, your brand, a competitor, a topic, a hashtag etc.
                Don't use email addresses or contact numbers as these are unlikely to appear on social media."
                    color="#6C63FF"
                />
            </div>
            <p className="mb-4">
                You can enter words, strings, URL/Domains, social media account and names.
                Separate keywords using comma.
            </p>
            <div className="mb-1">
                <p className="font-weight-bold d-inline">Included keywords</p>
                <TooltipItem id="includedKeywords"
                    content="Add keywords here if you want to see them in the content of your situation room."
                    color="#6C63FF"
                />
            </div>
            <div className={`${(includedKeywordError || errors.emptyKeywords
                 || (!errors.emptyKeywords && errors.noChanges)) ? 'mb-1 keywords-container' : 'mb-4 keywords-container'}`}
            >
                <Droppable droppableId="included">
                    {(provided) => (
                        <div ref={provided.innerRef} className="keywords-container">
                            <KeywordsInput keywords={room.query.included}
                                duplicateKeyword={duplicateKeyword}
                                setDuplicateKeyword={setDuplicateKeyword}
                                addKeyword={addKeyword}
                                removeKeyword={removeKeyword}
                                clearKeywords={clearKeywords}
                                isError={includedKeywordError || excludedKeywordError || errors.emptyKeywords
                                    || (!errors.emptyKeywords && errors.noChanges)}
                                clearErrors={() => { setExcludedKeywordError(undefined); setIncludedKeywordError(undefined); }}
                                isIncludedKeyword
                            />
                            <div className="d-none">{provided.placeholder}</div>
                        </div>
                    )}
                </Droppable>
            </div>
            {includedKeywordError && <p className="text-danger">{includedKeywordError}</p>}
            {errors.emptyKeywords && <p className="text-danger">Include or exclude keywords for your room to operate.</p>}
            {(!errors.emptyKeywords && errors.noChanges) && <p className="text-danger">Make amendments to your keywords before saving.</p>}

            <div className="mb-1">
                <p className="font-weight-bold d-inline">Excluded keywords</p>
                <TooltipItem id="excludedKeywords"
                    content={(
                        <p>
                            Add keywords here if you <strong>don&apos;t want</strong> to see them in the content of your situation room.
                        </p>
                    )}
                    color="#6C63FF"
                />
            </div>
            <div className={`${(excludedKeywordError || errors.emptyKeywords
                || (!errors.emptyKeywords && errors.noChanges)) ? 'mb-1 keywords-container' : 'mb-4 keywords-container'}`}
            >
                <Droppable droppableId="excluded">
                    {(provided) => (
                        <div ref={provided.innerRef} className="keywords-container">
                            <KeywordsInput keywords={room.query.excluded}
                                duplicateKeyword={duplicateKeyword}
                                setDuplicateKeyword={setDuplicateKeyword}
                                addKeyword={addKeyword}
                                removeKeyword={removeKeyword}
                                clearKeywords={clearKeywords}
                                isError={includedKeywordError || excludedKeywordError || errors.emptyKeywords
                                     || (!errors.emptyKeywords && errors.noChanges)}
                                clearErrors={() => { setExcludedKeywordError(undefined); setIncludedKeywordError(undefined); }}
                            />
                            <div className="d-none">{provided.placeholder}</div>
                        </div>
                    )}
                </Droppable>
            </div>
            {excludedKeywordError && <p className="text-danger">{excludedKeywordError}</p>}
            {errors.emptyKeywords && <p className="text-danger">Include or exclude keywords for your room to operate.</p>}
            {(!errors.emptyKeywords && errors.noChanges) && <p className="text-danger">Make amendments to your keywords before saving.</p>}

        </div>
    );
};
