import { Button } from 'reactstrap';
import React from 'react';
import { X } from 'react-feather';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const BatchRecoverCompletedModal = ({ totalRecovered, timeInSeconds } = {}) => {
    const room = getRoom();
    const { closeModal } = useModal();
    const { time, timeText } = getExecutionTime({ timeInSeconds: totalRecovered === 0 ? 0 : timeInSeconds });

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">Bulk recover completed successfully</h2>
                <X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={closeModal}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <div>
                {totalRecovered} contents have been successfully recovered back to {room.project_name}{' '}
                in approximately {time} {timeText}{time > 1 ? 's' : ''}.
                This content is now visible to you and your team in this room again.
            </div>
            <hr className="my-3" />
            <Button color="primary" className="mr-2" onClick={closeModal}>Close</Button>
        </>
    );
};

const getExecutionTime = ({ timeInSeconds }) => {
    const timeInMinutes = Number(timeInSeconds / 60).toFixed(0);
    const time = timeInSeconds > 60 ? timeInMinutes : Number(timeInSeconds).toFixed(0);
    const timeText = timeInSeconds > 60 ? 'minute' : 'second';

    return { time, timeText };
};
