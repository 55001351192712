import { color } from '../../../../../utils/getColors';

export const entityColors = {
    positive: {
        bg: 'success',
        border: 'success',
        bgHex: color.green[200]
    },
    negative: {
        bg: 'danger',
        border: 'danger',
        bgHex: color.red[200]
    },
    neutral: {
        bg: 'primary',
        border: 'primary',
        bgHex: color.yellow[500]
    }
};

export const options = [
    {
        name: 'Change label',
        options: [
            {
                color: 'success',
                name: 'positive',
                bgHex: color.green[200]
            },
            {
                color: 'danger',
                name: 'negative',
                bgHex: color.red[200]
            },
            {
                color: 'primary',
                name: 'neutral',
                bgHex: color.yellow[500]
            }
        ]
    },
    {
        name: 'Delete entity',
        options: []
    }
];
