import React from 'react';
import { Button, Input } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { CreateSource } from './CreateSource';

export const PageFilters = ({ handleSearchValue }) => {
    const { setModal, closeModal } = useModal();

    const onClickAddSource = () => {
        setModal({
            component: <CreateSource closeModal={closeModal} />
        });
    };

    return (
        <div className="my-4 d-flex align-items-center">
            <div className="mr-2 flex-fill">
                <Input onChange={(e) => handleSearchValue(e.target.value)}
                    placeholder="Search source"
                    data-testid="search-source"
                />
            </div>
            <Button color="primary" onClick={() => onClickAddSource()}>Add new source</Button>
        </div>
    ); };
