import * as React from 'react';
import { color } from '../../utils/getColors';

export const SelectIcon = () => (
    <svg width={72}
        height={80}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#a)">
            <path fill="#D2D4DB"
                d="M61.823 17.414 25.878 12.33a4.423 4.423 0 0 0-5 3.76l-5.814 41.117a4.423 4.423 0 0 0 3.76 4.999L54.77
                 67.29a4.423 4.423 0 0 0 4.999-3.76l5.814-41.117a4.423 4.423 0 0 0-3.76-5Z"
            />
            <path fill={color.grey[300]}
                d="M4.527 0a4.222 4.222 0 0 0-4.28 4.168v67.404a4.221 4.221 0 0 0 4.28 4.166h51.906a4.224 4.224 0 0 0
                 4.28-4.166V10.556L49.695 0H4.526Z"

            />
            <path fill="#D2D4DB"
                d="M41.303 46.255H20.64a.555.555 0 1 1 0-1.11h20.663a.555.555 0 1 1 0 1.11ZM35.838 49.409h-15.2a.555.555 0
                 1 1 0-1.111h15.2a.556.556 0 0 1 0 1.11ZM30.97 52.34H20.638a.555.555 0 1 1 0-1.112H30.97a.555.555 0 1 1 0
                  1.111ZM14.919 45.1H9.444c-.559 0-1.011.453-1.011 1.012v5.474c0 .559.452 1.011 1.01 1.011h5.476c.558 0
                   1.01-.452 1.01-1.01v-5.475c0-.559-.452-1.011-1.01-1.011ZM34.945 58.01H20.638a.555.555 0 1 1
                    0-1.112h14.307a.555.555 0 1 1 0 1.111ZM31.161 61.158H20.638a.555.555 0 1 1 0-1.111h10.523a.555.555 0 1 1
                     0 1.11ZM27.79 64.093h-7.152a.556.556 0 0 1 0-1.11h7.152a.555.555 0 1 1 0 1.11ZM51.794 33.41H9.035a.556.556
                      0 0 1 0-1.11h42.76a.555.555 0 1 1 0 1.11h-.002ZM40.484 36.555H9.035a.555.555 0 1 1
                       0-1.111h31.451a.556.556 0 0 1 0 1.11h-.002ZM30.412 39.49H9.035a.555.555 0 1 1 0-1.11h21.38a.555.555 0
                        1 1 0 1.11h-.003ZM14.885 64.295H9.48a1 1 0 0 1-1-1V57.89a1 1 0 0 1 1-1h5.409a1 1 0 0 1 1 1v5.406a1 1
                         0 0 1-1.003 1Zm-5.297-1.11h5.186V58H9.588v5.183ZM31.638 10.045H10.366c-.737 0-1.333.597-1.333
                          1.334v14.353c0 .736.596 1.333 1.333 1.333h21.272c.737 0 1.333-.597
                           1.333-1.333V11.38c0-.737-.596-1.334-1.333-1.334ZM49.696 0v8.479a2.077 2.077 0 0 0 2.078 2.077h8.94L49.697 0Z"

            />
            <path fill="#C8CACE"
                d="M57.008 80.002c8.144 0 14.745-6.602 14.745-14.745 0-8.143-6.601-14.744-14.745-14.744-8.143 0-14.744
                 6.601-14.744 14.744s6.601 14.745 14.744 14.745Z"

            />
            <path fill={color.grey[200]}
                d="M11.514 50.627a.555.555 0 0 1-.393-.163l-1.111-1.11a.556.556 0 0 1 .787-.787l.717.72 2.051-2.053a.556.556
                 0 0 1 .787.786l-2.444 2.444a.554.554 0 0 1-.394.163Z"
            />
            <path fill="#fff"
                d="M54.73 70.374a.553.553 0 0 1-.39-.164l-4.562-4.56a.556.556 0 0 1 .784-.786l4.169 4.168 8.72-8.73a.556.556
                 0 0 1 .787.787l-9.11 9.11a.552.552 0 0 1-.39.164l-.007.011Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(.247)" d="M0 0h71.506v80H0z" />
            </clipPath>
        </defs>
    </svg>
);
