import React, { useState } from 'react';
import { AlertTriangle, Eye, EyeOff } from 'react-feather';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { color } from '../../utils/getColors';

type PasswordInputProps = {
    password: string,
    setPassword: (e: string) => void,
    testId?: string,
    required?: boolean
    setError?: (msg: string) => void
    state?: boolean
    error?: string
    checkPassword?: () => void
}

export const PasswordInput = ({ password,
    setPassword, testId, required = false, setError, state, error, checkPassword }: PasswordInputProps) => {
    const [inputType, setInputType] = useState('password');
    return (
        <>
            <InputGroup border="none" className={`m-0 ${state ? 'border-danger' : ''}`}>
                <Input name="password"
                    data-testid={testId || 'passwordInput'}
                    value={password}
                    onBlur={checkPassword}
                    onChange={(e) => { setPassword(e.target.value); setError && setError(''); }}
                    type={inputType === 'password' ? 'password' : 'text'}
                    required={required}
                />
                {state && (
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className={`border-0 ${password ? 'p-0' : ''}`}>
                            <AlertTriangle color={color.red[200]} onClick={() => setInputType('password')} />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
                {password && (
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="border-0">
                            {inputType === 'password'
                                ? (
                                    <EyeOff className="pr-11"
                                        color={color.blue[500]}
                                        onClick={() => setInputType('text')}
                                    />
                                )
                                : <Eye className="pr-11" color={color.blue[500]} onClick={() => setInputType('password')} />}
                        </InputGroupText>
                    </InputGroupAddon>
                )}
            </InputGroup>
            {error && <p className="mt-1 mb-0 text-danger">{error}</p>}
        </>
    );
};
