import React from 'react';
import { Frown, Meh, Slash, Smile } from 'react-feather';
import { capitalizeFirstLetter } from '../../../utils/text';
import { color } from '../../../utils/getColors';
import { WidgetName, WidgetTextTooltip } from '../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';

type SentimentCardProps = {
    sentiment?: string
}

export const getSentimentIcon = (sentiment: string) => {
    switch (sentiment) {
        case 'positive':
            return (<Smile size={24} color={color.green[200]} className="mb-11 mr-1" />);
        case 'neutral':
            return (<Meh size={24} color={color.yellow[500]} className="mb-11 mr-1" />);
        case 'negative':
            return (<Frown size={24} color={color.red[200]} className="mb-11 mr-1" />);
        default:
            return (<Slash size={24} color={color.graphColors.indigo} className="mb-11 mr-1" />);
    }
};

export const SentimentCard = ({ sentiment }: SentimentCardProps) => {
    if (sentiment !== 'positive' && sentiment !== 'neutral' && sentiment !== 'negative') {
        return (
            <div className="p-1 pl-2">
                <WidgetTextTooltip widgetName={WidgetName.ContentSentiment} classes="card-text title" />
                <p className="card-text mt-1">
                    {getSentimentIcon('unknown')}
                    Unknown
                </p>
            </div>
        );
    }

    return (
        <div className="p-1 pl-2">
            <WidgetTextTooltip widgetName={WidgetName.ContentSentiment} classes="card-text title" />
            <p className="card-text mt-1">
                {getSentimentIcon(sentiment)}
                {capitalizeFirstLetter(sentiment)}
            </p>
        </div>

    );
};
