import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { DeleteInstanceModal } from './DeleteInstanceModal';
import { useInstance } from './InstanceContext';

export const DeleteInstanceButton = () => {
    const { setModal } = useModal();
    const { instance } = useInstance();
    return (
        <Button color="danger"
            onClick={() => setModal({
                component: <DeleteInstanceModal id={instance.id} clientName={instance.clientName} />
            })}
        >
            Delete client
        </Button>
    );
};
