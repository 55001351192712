/* eslint-disable @typescript-eslint/no-empty-function */
import { makeVar } from '@apollo/client';

export { getRoom } from './room';
export { getUser } from './user';

export const refetchRoom = makeVar(() => {});

export type ContentType = {
    id: string
    key: string
    name: string
    color: string
    FontFamily: string
    nodeColor: string
    iconColor: string
    icon: string
    disableEngagement: boolean
};

export const allCtypesMongo = makeVar<ContentType[]>([]);
export const getFilterQuery = makeVar({});
export const languagesList = makeVar([]);
