import React from 'react';
import { Button } from 'reactstrap';
import { PermanentDeleteModal } from '../PermanentDeleteModal';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const DeletePermanentlyButton = ({ selectedContentIds, onSuccess, disabled }) => {
    const { setModal } = useModal();

    const deletePermanently = () => setModal({
        header: 'Are you sure you want to permanently delete selected content?',
        component: <PermanentDeleteModal ids={selectedContentIds} onSuccess={onSuccess} />
    });

    return (
        <Button color="danger" onClick={deletePermanently} disabled={disabled}>Delete content</Button>
    );
};
