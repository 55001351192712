import React, { useState } from 'react';
import { ClusterWithSubClusters } from '../../types';
import { SearchInput } from '../../../../../components/Molecules';
import { NoDataFound } from '../../Nodatafound';
import { SubClustersTable } from '../../ListView/SubClustersTable';
import { Loading } from '../../../../../components/Loading';
import { ClustersSortOptions } from '../../Utils/ClusterSort';
import { lodashSort } from '../../../../../utils/lib/tableSorting';

type SubClustersProps = {
    clusterData: ClusterWithSubClusters
    loading: boolean
}

export const SubClusters = ({ clusterData, loading }: SubClustersProps) => {
    const [searchVal, setVal] = useState('');
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Volume' });
    const subClusters = clusterData?.subClusters.filter(({ subClusterTitle, subClusterSummary }) =>
        subClusterTitle.toLowerCase().includes(searchVal.toLowerCase())
        || subClusterSummary.toLowerCase().includes(searchVal.toLowerCase())).map(subCluster => ({
        ...subCluster,
        title: subCluster.subClusterTitle
    }));
    // splitting search value into keywords and adding * before and after each keyword to highlight partial matches
    const keywordsToHighlight = searchVal.match(/(?:[^\s"]+|"[^"]*")+/g)?.map(str => `*${str}*`) || [];

    return (
        <div className="bg-white p-2 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <div className="d-flex mb-3 align-items-center">
                <SearchInput placeholder="Search by keywords"
                    handleSearch={setVal}
                    className="flex-1 mr-2"
                />
                <ClustersSortOptions sort={sort} setSort={setSort} isNarrative />
            </div>
            {loading ? <Loading relative height={200} /> : (
                <>
                    {searchVal && subClusters.length === 0 && <div className="p-4 border"><NoDataFound /></div>}
                    {subClusters.length !== 0 && (
                        <>
                            <p className="d-block mb-3">{subClusters.length} Narratives</p>
                            <SubClustersTable subClusters={lodashSort(subClusters, sort)}
                                itemsPerPage={99999}
                                offset={0}
                                onOffsetChange={() => null}
                                total={subClusters.length}
                                keywordsToHighlight={keywordsToHighlight}
                                isTabContent
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
