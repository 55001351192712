import React, { forwardRef } from 'react';
import { TimeBar } from 'regraph';
import { isEqual } from 'lodash';
import { useTimeline } from './useTimeline';
import { convertESInterval } from '../../../../../utils/convertESInterval';
import { color } from '../../../../../utils/getColors';
import { useContentFilters } from '../../../useFilters';
import { checkAndGetUnix } from '../../../../../utils/getTimeFrom';

export const NetworksTimeline = forwardRef((_, ref) => {
    const { loading, data, interval } = useTimeline();
    const intervalInMilliseconds = convertESInterval(interval) as number;
    const { filters } = useContentFilters({
        useGlobalFilters: true
    });
    const allDates = data.map(({ key }) => [key, key + intervalInMilliseconds]).flat().sort((a, b) => a - b);
    const defaultDateRange = allDates.length ? {
        start: new Date(allDates[0]),
        end: new Date(allDates[allDates.length - 1])
    } : undefined;

    const dateRange = (filters.date?.startDate && filters.date?.endDate) ? {
        start: new Date(checkAndGetUnix(filters.date.startDate) * 1000),
        end: new Date(checkAndGetUnix(filters.date.endDate) * 1000)
    } : defaultDateRange;

    if (loading) return null;

    const timeBarOptions = {
        style: {
            color: color.blue[500],
            hoverColor: '#328bfa'
        },
        backgroundColor: color.grey[100],
        sliders: {
            color: 'rgb(248, 248, 248, 0.9)',
            lineColor: color.grey[300]
        },
        zoom: {
            max: {
                unit: intervalInMilliseconds > (1000 * 60 * 60 * 24) ? 'week' : 'day' as ZoomUnits
            }
        }
    };
    return (
        <div className="border-top">
            <MemoizedTimeBar options={timeBarOptions}
                items={Object.fromEntries(data.map(({ key, count }) => ([key, {
                    times: [{
                        time: {
                            start: key,
                            end: key + intervalInMilliseconds
                        },
                        value: count
                    }]
                }])))}
                range={dateRange}
            />
        </div>
    );
});

const MemoizedTimeBar = React.memo(({
    options,
    items,
    range
}: TimeBar.Props) => (
    <TimeBar options={options}
        items={items}
        style={{ height: 100 }}
        range={(range?.start && range?.end) ? range : undefined}
    />
), (prevProps, nextProps) => isEqual(prevProps.items, nextProps.items) && isEqual(prevProps.range, nextProps.range));

type ZoomUnits = 'year' | 'month' | 'week' | 'day' | 'hour';
