import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { Pagination } from '../../../../components/Molecules';
import { GET_ALL_USERS } from '../../../../graphql';
import { TeamMembersTable } from './TeamMembersTable';
import { PageFilters } from './PageFilters';

export const AllUsers = () => {
    const [sort, setSort] = useState({ field: 'lastSeen', order: 'desc' });
    const [page, setPage] = useState(0);
    const [searchFilters, setSearchFilters] = useState({ searchValue: '', clientList: [] });
    const { data: allUserData, loading } = useQuery(GET_ALL_USERS, {
        variables: {
            searchValue: searchFilters.searchValue,
            instance: searchFilters.clientList,
            skip: page,
            limit: 10,
            sort
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleSearch = (val, identifier) => {
        setSearchFilters({
            ...searchFilters,
            [identifier]: val
        });
        setPage(0);
    };

    return (
        <>
            <h2 className="m-0">Team members ({allUserData?.allUsers?.total || 0})</h2>
            <PageFilters handleSearchValue={handleSearch} />
            {
                loading ? <Loading relative height={300} />
                    : (
                        <div className="bg-white overflow-x-auto">
                            <TeamMembersTable usersData={allUserData?.allUsers.data || []}
                                sort={sort}
                                setSort={setSort}
                            />
                            <Pagination total={allUserData?.allUsers.total || 0}
                                itemsPerPage={10}
                                offset={page}
                                setOffset={setPage}
                                className="py-2"
                            />
                        </div>
                    )
            }
        </>
    );
};
