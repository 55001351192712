import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { getHighlightedContent } from '../../utils/getHighlightedContent';

type ContentURLProps = {
    url: string
}

export const ContentURL = ({ url }: ContentURLProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [id] = useState(`content-url-${uuid()}`);
    if (!url) return null;
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const highlightedURL = getHighlightedContent(url);
    return (
        <>
            <span className="single-line-ellipsis highlight-em" id={id}>{highlightedURL}</span>
            <Tooltip isOpen={tooltipOpen}
                target={id}
                toggle={toggle}
                placement="auto"
                className="url-tooltip"
                autohide={false}
            >
                <div className="maxw-500 dont-break-out highlight-em" onClick={(e) => e.preventDefault()}>{highlightedURL}</div>
            </Tooltip>
        </>
    );
};
