import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { useSelector } from 'react-redux';
import { Page } from '../AddData/Pages/Page';
import { stripHTML } from '../../../../../utils/lib/stripHTML';
import { ExportAsPDF } from './ExportAsPDF';
import { ExportAsPPT } from './ExportAsPPT';

const blockPages = ['overview', 'threats', 'customNarratives', 'threatsWatchlist', 'narrativesWatchlist'];
export const getReportPages = (report) => report.pages.filter(page => {
    if (blockPages.includes(page.type) && !page.blocks.length) return false;
    if (page.type === 'summary') {
        const rawContent = stripHTML([stateToHTML(page.bodyText.getCurrentContent())]);
        if (!rawContent) {
            return false;
        }
    }
    return true;
});

export const Export = () => {
    const { report } = useSelector(state => state.createReport);
    if (!report?.name || !report?.template) return null;
    const pagesToExport = getReportPages(report);
    return (
        <div className="report-export">
            <div className="d-flex">
                <div className="flex-1">
                    <p className="mb-11">Report name</p>
                    <p className="m-0">{report.name}</p>
                </div>
                <div>
                    <ExportAsPDF />
                    <ExportAsPPT />
                </div>
            </div>
            <div className="py-4 pr-4">
                {pagesToExport.map(page => (
                    <React.Fragment key={page.id}>
                        <Page data={page} pdf />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
