import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownWithMultiCheckbox, ItemProps } from '../components/DropdownWIthMultiCheckbox/index';
import { useSources } from '../../../services/Overview';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';

type MultiSelectSourceFilterProps = {
    clusters: boolean
}

export const MultiSelectSourceFilter = ({ clusters = false }: MultiSelectSourceFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { contentType: selectedSources }: { contentType: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { projectId } = location.state || {};
    const { data, loading } = useSources({ size: 20, useGlobalFilters: false, clusters, projectId });
    const finalData = data?.length > 0 ? data : [];
    const sourceData = finalData.map((source: {key: string, source: string}) => ({
        id: source.key,
        name: source.source,
        selected: selectedSources.includes(source.key)
    })).sort((a: ItemProps, b: ItemProps) => a.name.localeCompare(b.name));

    const handleSelectItem = (ids: string[]) => {
        dispatch(saveSelectedFilters({
            contentType: ids
        }));
    };

    const countFilters = selectedSources.length;
    return (
        <div>
            <DropdownWithMultiCheckbox handleSelectItem={handleSelectItem}
                filterOptions={sourceData}
                value="sources"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
            />
        </div>
    );
};
