import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { ChevronDown } from 'react-feather';
import { Link, useRouteMatch } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useUser } from '../../../context/user/UserContext';
import { useDropDown } from '../../../utils/useDropdown';
import { sidebarRoutesLinks, integrationsRoutesLinks, configureRoutesLinks } from '../../../routes';
import { getRoom } from '../../../utils/variables';

export const GET_SITUATION_ROOMS = gql`
    query situationRooms($instance: ID!, $limit: Int) {
        situationRooms(instance: $instance, limit: $limit) {
            total
            data {
                id
                project_name
                archived
            }
        }
    }
`;

export const SituationRoomsDropdown = ({ currentPage, tag = 'h1' }) => {
    const match = useRouteMatch();
    const currentRoomLink = match.path.replace(':situation', match.params.situation);

    const room = getRoom();
    const { user } = useUser();
    const [dropdown, toggleDropdown] = useDropDown();
    const { data: situationRooms } = useQuery(GET_SITUATION_ROOMS, {
        variables: {
            instance: user.instance.id,
            limit: 100
        },
    });
    const rooms = situationRooms?.situationRooms?.data || [];
    const activeRooms = (situationRoom) => !situationRoom.archived;
    const pathToRoute = window.location.pathname.split('/').slice(0, 4).join('/');
    return (
        <Dropdown isOpen={dropdown} toggle={toggleDropdown}>
            <DropdownToggle tag={tag}
                role="button"
                className="d-flex align-items-center m-0"
            >
                <span className="d-none d-md-block mr-1">{room.project_name}</span>
                <span className="d-block d-md-none mr-1">{currentPage.replace(/-/g, ' ')}</span> <ChevronDown size={18} />
            </DropdownToggle>
            <DropdownMenu className="maxh-300 scrollbar-small overflow-y-auto overflow-x-hidden">
                {rooms.filter(activeRooms).map(item => {
                    if (room.project_name !== item.project_name) {
                        return (
                            <Link key={item.id}
                                to={pathToRoute.replace(room.id, item.id)}
                                className="d-none d-md-block"
                            >
                                <DropdownItem active={item.id === room.id}>{item.project_name}</DropdownItem>
                            </Link>
                        );
                    }
                    return null;
                })}
                {sidebarRoutesLinks.map(item => {
                    if (!user.instance.plan.core[item.id]) { return null; }
                    return (
                        <Link to={currentRoomLink + item.path} key={item.id} className="d-block d-md-none">
                            <DropdownItem active={item.name === currentPage}>{item.name}</DropdownItem>
                        </Link>
                    );
                })}
                {integrationsRoutesLinks.map(item => {
                    if (!user.instance.plan.integrations[item.id]) { return null; }
                    return (
                        <Link to={currentRoomLink + item.path} key={item.id} className="d-block d-md-none">
                            <DropdownItem active={item.name === currentPage}>{item.name}</DropdownItem>
                        </Link>
                    );
                })}
                {configureRoutesLinks.map(item => (
                    <Link to={currentRoomLink + item.path} key={item.id} className="d-block d-md-none">
                        <DropdownItem active={item.name === currentPage}>{item.name}</DropdownItem>
                    </Link>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
