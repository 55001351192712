import { convertToMarkers } from '../convertToMarkers';

export const nearAndValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    const isAnd = [...query.matchAll(/\bAND\b/g)]?.length > 0;
    const isNear = [...query.matchAll(/(\bNEAR\b)/g)]?.length > 0;

    // Is there NEAR operator used with AND
    if (!isNear || (isNear && !isAnd)) {
        return {
            isValid: true,
            markers: []
        };
    }

    // Is there a NEAR outside brackets
    let mutableQuery = query;
    const openingBracketStack = [];
    const closingBracketStack = [];
    for (let i = 0; i < mutableQuery.length; i++) {
        if (mutableQuery[i] === '(') {
            openingBracketStack.push(i);
        }
        if (mutableQuery[i] === ')') {
            closingBracketStack.push(i);
        }
        if (openingBracketStack.length !== closingBracketStack.length) {
            mutableQuery = replaceAllChars(mutableQuery, i);
        }
    }

    const nearsWithoutBrackets = [...mutableQuery.matchAll(/\bNEAR\b/g)];
    if (nearsWithoutBrackets.length !== 0) {
        nearsWithoutBrackets.forEach(match => {
            highlight.push([match.index, match.index + match[0].length]);
        });
        return {
            isValid: false,
            markers: convertToMarkers(
                highlight,
                queryByLines,
                lineLengths,
                'Operator NEAR is mixed with an AND operator.'
            )
        };
    }

    return findNearInBrackets(query, queryByLines, lineLengths);
};
// validating both NEAR and AND within brackets
const findNearInBrackets = (query, queryByLines, lineLengths) => {
    const highlight = [];
    let isValid = true;
    const queriesInBrackets = fetchQueryWithinBrackets(query);
    if (queriesInBrackets.length === 0) {
        const isAnd = [...query.matchAll(/\bAND\b/g)]?.length > 0;
        const isNear = [...query.matchAll(/(\bNEAR\b)/g)]?.length > 0;
        if (isNear && isAnd) {
            [...query.matchAll(/(?=\bNEAR\b|\bAND\b)(.*)(\bAND\b|\bNEAR\b)/g)].forEach((match) => {
                highlight.push([match.index, match.index + match[0].length]);
            });
            return {
                isValid: false,
                markers: convertToMarkers(
                    highlight,
                    queryByLines,
                    lineLengths,
                    'Operator NEAR is mixed with an AND operator.'
                )
            };
        }
    }
    // there is some brackets
    queriesInBrackets.forEach(match => {
        const nestedBrackets = fetchQueryWithinBrackets(match);
        const matchWithoutBrackets = replaceBulk(match, nestedBrackets, 'x');
        const isNear = [...matchWithoutBrackets.matchAll(/(\bNEAR\b)/g)]?.length > 0;
        if (isNear) {
            // check if nested brackets contain AND
            nestedBrackets.forEach(nestedMatch => {
                const nestedMatchBrackets = fetchQueryWithinBrackets(nestedMatch);
                const nestedMatchWithoutBrackets = replaceBulk(match, nestedMatchBrackets, 'x');
                const isAnd = [...nestedMatchWithoutBrackets.matchAll(/\bAND\b/g)]?.length > 0;
                if (isAnd) {
                    isValid = false;
                    [...nestedMatchWithoutBrackets.matchAll(/(?=\bNEAR\b|\bAND\b)(.*)(\bAND\b|\bNEAR\b)/g)].forEach((invalidMatch) => {
                        highlight.push([query.indexOf(match) + invalidMatch.index,
                            query.indexOf(match) + invalidMatch.index + invalidMatch[0].length]);
                    });
                }
            });
        }

        const result = findNearInBrackets(match);
        if (!result.isValid) {
            isValid = false;
            highlight.push(...result.highlight);
        }
    });

    return highlight.length === 0 ? {
        isValid,
        markers: []
    } : {
        isValid,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'Operator NEAR is mixed with an AND operator.'
        )
    };
};

const replaceBulk = (str, findArray, replaceWith) => {
    let i; let regex = []; const
        map = {};
    for (i = 0; i < findArray.length; i++) {
        regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
        map[findArray[i]] = replaceWith;
    }
    regex = regex.join('|');
    return str.replace(new RegExp(regex, 'g'), (matched) => map[matched] || '');
};

const fetchQueryWithinBrackets = (query) => {
    const mutableQuery = query;
    const openingBracketStack = [];
    const queriesInBrackets = [];
    for (let i = 0; i < mutableQuery.length; i++) {
        if (mutableQuery[i] === '(') {
            openingBracketStack.push(i);
        }
        if (mutableQuery[i] === ')') {
            const openingBracketIndex = openingBracketStack.pop() || 0;
            queriesInBrackets.push(mutableQuery.substring(openingBracketIndex + 1, i));
        }
    }
    return queriesInBrackets;
};

const replaceAllChars = (query, i) => `${query.substr(0, i)}x${query.substr(i + 1)}`;
