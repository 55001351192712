import React from 'react';

export const LoadingState = ({ size = 10 }) => (
    <div className="loading-container overflow-hidden h-100" data-testid="table-loading">
        {[...Array(size).keys()].map((a) => (
            <div key={a} className="loading-bg px-2 mt-2">
                <p>Loading</p>
            </div>
        ))}
    </div>
);
