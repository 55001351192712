import { convertToMarkers } from '../convertToMarkers';

export const andOrValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.length === 0) {
        return {
            isValid: highlight.length === 0,
            markers: []
        };
    }

    // Create mutable copy of query
    let mutableQuery = query;

    // Check is query contains both AND and OR
    if (!containsAndAndOrOperator(mutableQuery).containsBoth) {
        return {
            isValid: true,
            markers: []
        };
    }

    // Use stack and iterate to find bracket pairs
    const openingBracketStack = [];

    for (let i = 0; i < mutableQuery.length; i++) {
        if (mutableQuery[i] === '(') {
            openingBracketStack.push(i);
        }
        if (mutableQuery[i] === ')') {
            const openingBracketIndex = openingBracketStack.pop() || 0;
            const subQuery = mutableQuery.substring(openingBracketIndex, i);

            // Check subquery for AND and OR together
            const { containsBoth, andOperators, orOperators } = containsAndAndOrOperator(subQuery);
            if (containsBoth) {
                if (andOperators[0] < orOperators[0]) {
                    const andOperatorStartIndex = openingBracketIndex + andOperators[0];
                    const orOperatorEndIndex = openingBracketIndex + orOperators[orOperators.length - 1] + 2;
                    highlight.push([andOperatorStartIndex, orOperatorEndIndex]);
                } else {
                    const orOperatorStartIndex = openingBracketIndex + orOperators[0];
                    const andOperatorEndIndex = openingBracketIndex + andOperators[andOperators.length - 1] + 3;
                    highlight.push([orOperatorStartIndex, andOperatorEndIndex]);
                }
            }

            // Replace subquery in brackets so it doesnt get rechecked
            const replacementCharacters = new Array(i - openingBracketIndex).join('X');
            mutableQuery = mutableQuery.substring(0, openingBracketIndex + 1)
                + replacementCharacters + mutableQuery.substring(i);
        }
    }

    // Check remaining query outside the brackets
    const { containsBoth, andOperators, orOperators } = containsAndAndOrOperator(mutableQuery);

    if (containsBoth) {
        if (andOperators[0] < orOperators[0]) {
            const andOperatorStartIndex = andOperators[0];
            const orOperatorEndIndex = orOperators[orOperators.length - 1] + 2;
            highlight.push([andOperatorStartIndex, orOperatorEndIndex]);
        } else {
            const orOperatorStartIndex = orOperators[0];
            const andOperatorEndIndex = andOperators[andOperators.length - 1] + 3;
            highlight.push([orOperatorStartIndex, andOperatorEndIndex]);
        }
    }

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'The AND and OR operators cannot be mixed in the same sub-query.'
        )
    };
};

const containsAndAndOrOperator = (query) => {
    const andOperators = [];
    const orOperators = [];

    [...query.matchAll(/\bAND\b/g)].forEach((match) => { andOperators.push(match.index); });
    [...query.matchAll(/\bOR\b/g)].forEach((match) => { orOperators.push(match.index); });

    if (andOperators.length && orOperators.length) {
        return {
            containsBoth: true,
            andOperators,
            orOperators
        };
    }

    return {
        containsBoth: false
    };
};
