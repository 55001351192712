import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ItemProps } from '../components/DropdownWIthMultiCheckbox';
import { useTopLanguages } from '../../../services/Overview';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { languagesList } from '../../../utils/variables';
import { DropdownWithSearchAlphabetical } from '../components/DropdownWithSearchAlphabetical';

type MultiSelectLanguageFiltersProps = {
    clusters: boolean
}

export const MultiSelectLanguageFilters = ({ clusters = false }: MultiSelectLanguageFiltersProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { projectId } = location.state || {};
    const { data, loading } = useTopLanguages({ size: 1000, useGlobalFilters: false, clusters, projectId });

    const { languages: selectedLanguages }: { languages: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const languagesData = data?.length > 0 ? data : [];

    const languagesDataList = languagesData.map((lang: any) => ({
        id: lang.key,
        name: lang.language,
        selected: selectedLanguages.includes(lang.key)
    })).sort((a: ItemProps, b: ItemProps) => a.name.localeCompare(b.name));
    languagesList(data.map(({ key }: { key: string }) => key));

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            languages: selectedLanguages.includes(id)
                ? selectedLanguages.filter(lang => lang !== id)
                : [...selectedLanguages, id]
        }));
    };

    const countFilters = selectedLanguages.length;

    return (
        <div>
            <DropdownWithSearchAlphabetical items={languagesDataList}
                handleSelectItem={handleSelectItem}
                placeholder="Search for languages"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
            />
        </div>
    ); };
