import { inRange } from 'lodash';

export const replaceStringsInQuery = ({ query, replacementChar = 'X' }) => {
    if (!query || query.trim().length === 0) {
        return query;
    }

    if (replacementChar.length > 1) {
        throw new Error('Replacement character must not be longer than 1 char.');
    }

    const correctlyClosedQuotesIndexRanges = [];

    const queryWithoutStringLiterals = query.split('');
    [...query.matchAll(/(["])(?:(?=(\\?))\2.)*?\1/gm)].forEach((match) => {
        for (let i = 0; i < match[0].length; i++) {
            queryWithoutStringLiterals[match.index + i] = replacementChar;
        }
        correctlyClosedQuotesIndexRanges.push({ start: match.index, end: match.index + match[0].length });
    });

    [...query.matchAll(/["]/g)].forEach((match) => {
        let isWithinCorrectlyCloseQuoteRange = false;
        correctlyClosedQuotesIndexRanges.forEach(({ start, end }) => {
            if (inRange(match.index, start, end)) {
                isWithinCorrectlyCloseQuoteRange = true;
            }
        });

        if (!isWithinCorrectlyCloseQuoteRange) {
            for (let i = 0; i < (query.length - match.index); i++) {
                queryWithoutStringLiterals[match.index + i] = replacementChar;
            }
        }
    });

    return queryWithoutStringLiterals.join('');
};
