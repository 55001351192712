import { uniqBy } from 'lodash';
import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { Keyword } from '../KeywordsEditor/Keyword';
import { useRoomEditor } from '../RoomEditorContext';

export const KeywordsInput = ({ keywords,
    addKeyword,
    removeKeyword,
    clearKeywords,
    isIncludedKeyword = false,
    duplicateKeyword,
    setDuplicateKeyword,
    isError,
    clearErrors }) => {
    const [inputText, setInputText] = useState('');
    const { setSelectedKeyword } = useRoomEditor();

    return (
        <div className={`form-control ${isError && 'border-danger'} keyword-input flex-1`}>
            {(keywords.length > 0) && (
                <div className="d-flex align-items-center flex-wrap tags pt-1 pl-1 keyword-textarea">
                    {uniqBy(keywords, 'value').map((keyword, index) => (
                        <div key={keyword.value}>
                            <Keyword keyword={keyword}
                                removeKeyword={(e) => {
                                    e.stopPropagation();
                                    removeKeyword(keyword, isIncludedKeyword);
                                }}
                                index={index}
                                duplicateKeyword={duplicateKeyword}
                            />
                        </div>
                    ))}
                    {keywords.length > 0 && (
                        <Button color="link"
                            className="p-0 mb-1"
                            onClick={() => {
                                clearKeywords(isIncludedKeyword);
                                setSelectedKeyword(false);
                            }}
                        >Clear all
                        </Button>
                    )}
                </div>
            )}
            <Input type="textarea"
                rows="3"
                className="border-0 px-1 pb-0 pt-1 h-25"
                placeholder="Please enter keywords"
                value={inputText}
                data-testid={isIncludedKeyword ? 'included-keyword-input' : 'excluded-keyword-input'}
                onChange={(e) => {
                    if (!['\n', ','].includes(e.target.value)) {
                        setDuplicateKeyword(undefined);
                        clearErrors();
                    }
                    setInputText(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ',') {
                        const inputKeywords = e.target.value.split(',');
                        const newKeyword = inputKeywords[inputKeywords.length - 1].trim();
                        if (newKeyword) {
                            addKeyword(newKeyword, isIncludedKeyword);
                            setSelectedKeyword({
                                value: newKeyword,
                                type: 'unknown'
                            });
                        }
                        setInputText('');
                    }
                    if (e.key === 'Backspace') {
                        if (e.target.value === '') {
                            const lastKeyword = keywords?.length && keywords.pop();
                            removeKeyword(lastKeyword, isIncludedKeyword);
                        }
                    }
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' || e.key === ',') {
                        setInputText('');
                    }
                }}
            />
        </div>
    );
};
