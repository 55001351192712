import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { Button, Col } from 'reactstrap';
import { pick } from 'lodash';
import { useDispatch } from 'react-redux';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../../graphql';
import { Loading } from '../../../../../components/Loading';
import { ClustersTable } from '../../ListView/ClustersTable';
import { Cluster } from '../../types';
import { setParam, unsetParam } from '../../../../../utils/urlParams';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { SearchBarNew } from '../../../../../components/NewFilters';
import { ClustersSortOptions } from '../../Utils/ClusterSort';
import { useGlobalFiltersInClusters } from '../../../../../services/Clusters/GlobalFIltersInClusters';
import { resetClusterFilters } from '../../../store';

export const AddNarrativeThemeModal = () => {
    const room = getRoom();
    const { closeModal } = useModal();
    const [clustersOffset, setClustersOffset] = useState(0);
    const dispatch = useDispatch();
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Most content' });
    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            limit: 10,
            skip: clustersOffset,
            ...filters,
            sort: pick(sort, ['field', 'order']),
            projectId: room.project_id,
        },
        fetchPolicy: 'cache-and-network'
    });
    const getClustersData = clustersData?.getClusters;
    const clusters: Cluster[] = getClustersData?.clusters || [];
    const setSelectedCluster = (id: string) => {
        unsetParam('tab');
        if (!id) {
            return unsetParam('narrative-theme');
        }
        setParam('narrative-theme', id);
    };
    const handleClose = () => {
        dispatch(resetClusterFilters());
        closeModal();
    };
    if (clusterLoading) {
        return <Loading gif relative height={400} />;
    }
    return (
        <>
            <h2>
                Add narrative theme for comparison
                <X className="float-right" type="button" onClick={handleClose} />
            </h2>
            <hr className="mb-3" />
            <div className="mb-2">
                <SearchBarNew clusters score>
                    <Col xs="auto">
                        <ClustersSortOptions sort={sort} setSort={setSort} />
                    </Col>
                </SearchBarNew>
            </div>
            <ClustersTable clusters={clusters}
                total={getClustersData?.total}
                offset={clustersOffset}
                onSetSelectedCluster={setSelectedCluster}
                onOffsetChange={setClustersOffset}
                itemsPerPage={10}
                isCompareModal
            />
            <Button color="secondary" className="mt-2" onClick={handleClose}>Close</Button>
        </>
    );
};
