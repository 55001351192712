import React from 'react';
import { TooltipItem } from '../../../../components/ToolTip';
import { AllTypes, capitalizeFirstLetter, getCtype } from '../../../../utils/text';
import { color } from '../../../../utils/getColors';

export const config = {
    colors: [color.graphColors.tangerineYellow,
        color.graphColors.tomato, color.graphColors.robinsEggBlue, color.graphColors.magenta, color.blue[500], '#ffbe0b'],
    specificColors: {
        positive: color.graphColors.robinsEggBlue,
        negative: color.graphColors.magenta,
        neutral: color.blue[500]
    },
    getColor(label, index) {
        return this.specificColors[label] || this.colors[index];
    }
};

export const formatLabel = (label, type) => {
    if (type === 'ctype__keyword') {
        const { name } = getCtype(label);
        return name;
    }
    if (['extracted_urls__keyword', 'from__keyword'].includes(type)) {
        return label;
    }
    if (type === 'keywords__keyword' && label.includes(':')) {
        return capitalizeFirstLetter(label.split(':')[1]);
    }
    if (type === 'threat_object__threat_resolvers__resolver__keyword' && AllTypes[label]) {
        return AllTypes[label].name;
    }
    return capitalizeFirstLetter(label.replace(/_/g, ' '));
};

export const toId = (label) => label.split('.').join('')
    .replace(/ /g, '-')
    .replace(/,/g, '')
    .replace(/:/g, '')
    .replace(/#/g, '');

const tooltips = {
    theme_matches: `Total Matches detected as a number. This graph indicates the general trend of the matches
     and whether they are trending up or down.`,
    high_threats: `Total Threats found within the room matches.
    This graph indicates the general trend of the threats and whether they are trending up or down.`,
    sentiment__keyword: `A high-level overview of the sentiment analysis of all content in a room,
    with the amounts split across the sentiment types of Neutral, Negative and Positive. 
    The volume of matched content shown by this pie chart analysis is not representative of all the 
    content within the room as Non-English content is not currently analyzed by our sentiment analysis model.`,
    locations_detected__keyword: 'Top locations mentioned in the content across this situation room',
    extracted_urls__keyword: 'This is a high-level summary of the Top 10 most shared URLs and how often they’ve been shared.',
    lang_verbose__keyword: `View the top languages being used in content across this room, with a count of the number of matches and
    threats written in that language. Where ‘unknown’ we’re unable to detect the language being used.`,
    ctype__keyword: `A breakdown of the sources where we’ve detected matching content for this room, and the matches and
    threats from each source.`,
    from__keyword: `View the top publishers of content in this room, with a count of the number of publications made.
    These can be websites, posts and tweets.`,
    wordcloud: 'Generates a wordcloud that summarises room content based on volume. The bigger a word, the more it appears.'
};

export const TitleTooltip = ({ title, type, tooltip }) => (
    <div className="d-flex align-items-center">
        <h3 className="m-0">{title} </h3>
        {(tooltips[type] || tooltip) && (
            <TooltipItem placement="top"
                id={`${type}-${toId(title)}`}
                content={tooltips[type] || tooltip}
            />
        )}
    </div>
);

export const entityTypes = [
    { name: 'Organisation', key: 'ORG', color: '#4367F8' },
    { name: 'Location', key: 'LOC', color: color.graphColors.magenta },
    { name: 'Person', key: 'PER', color: color.graphColors.robinsEggBlue },
    { name: 'Other', key: 'MISC', color: color.graphColors.tomato }
];
