import React from 'react';
import { HelpCircle } from 'react-feather';
import NoData from '../../../../assets/images/no-narratives.png';
import { Loading } from '../../../../components/Loading';
import { TooltipItem } from '../../../../components/ToolTip';
import { useRoomEditor } from '../RoomEditorContext';
import { SocialAccounts } from './SocialAccounts';
import { KeywordList, SuggestionsBox } from './SuggestionsBox';
import { useSuggestions } from './useSuggestions';

export const KeywordSuggestions = () => {
    const { selectedKeyword } = useRoomEditor();
    if (!selectedKeyword) {
        return (
            <div className="py-10 d-flex justify-content-center align-items-center flex-column">
                <img src={NoData} alt="" />
                <p className="mb-0 mt-2 maxw-500 text-center">
                    Start typing your keywords in the left panel and we will generate suggested or related terms here for you
                    to choose from.
                </p>
            </div>
        );
    }
    return <KeywordSuggestionsInner />;
};

const KeywordSuggestionsInner = () => {
    const { selectedKeyword, room } = useRoomEditor();
    const {
        loading,
        keywordData,
        relatedTopics,
        relatedQueries,
        article,
        socialAccounts
    } = useSuggestions({ selected: selectedKeyword.value?.trim() || '' });

    if (loading) {
        return (
            <Loading relative height={300} />
        );
    }

    const field = room.query.included.find(item => item.value === selectedKeyword.value) ? 'included' : 'excluded';
    return (
        <div>
            <div className="mb-3">
                <strong className="mr-2 mb-1 d-inline-block">Keyword suggestions for</strong>
                <div className="tag mb-1 no-hover">{selectedKeyword?.value?.trim()}</div>
                <TooltipItem placement="bottom"
                    id="suggestedTerms"
                    content="We'll present suggested terms based on words that are similar to your keyword.
                Select '+' to add them to your situation room or drag and drop them into the panel to your left."
                    Icon={HelpCircle}
                    color="#B7B7B7"
                />
            </div>
            {(socialAccounts && socialAccounts.length !== 0) && <SocialAccounts data={socialAccounts} field={field} />}
            {keywordData && (
                <div>
                    <SuggestionsBox title="Synonyms"
                        keywords={keywordData.synonyms.map(a => ({ value: a, type: 'phrase' }))}
                        tooltip="Words that are similar, or have a related meaning"
                        field={field}
                    />
                    <SuggestionsBox title="Antonyms"
                        keywords={keywordData.antonyms.map(a => ({ value: a, type: 'phrase' }))}
                        tooltip="Words opposite in meaning to another (e.g. bad and good)"
                        field={field}
                    />
                    <SuggestionsBox title="Lemmas"
                        keywords={keywordData.lemmas.map(a => ({ value: a, type: 'phrase' }))}
                        tooltip="All the other possible forms of a word"
                        field={field}
                    />
                    <SuggestionsBox title="Leetspeak"
                        keywords={keywordData.leetspeak.map(a => ({ value: a, type: 'phrase' }))}
                        tooltip="All the possible leetspeak forms of a word"
                        field={field}
                    />
                    <hr className="my-4" />
                </div>
            )}
            {(relatedQueries || relatedTopics) && (
                <div>
                    <p className="my-0 mb-3">
                        <strong>Related Terms</strong>
                        <TooltipItem placement="bottom"
                            id="relatedTerms"
                            content="We'll present related terms based on Google searches that are similar to your keywords.
                    Select '+' to add them to your situation room or drag and drop them into the panel to your right."
                            Icon={HelpCircle}
                            color="#B7B7B7"
                        />
                    </p>
                    <SuggestionsBox title="Topics"
                        keywords={relatedTopics ? relatedTopics.map(item => ({
                            value: item.topic.title,
                            type: 'unknown'
                        })) : []}
                        tooltip="Users searching Google for your term also searched for these topics"
                        field={field}
                    />
                    <SuggestionsBox title="Queries"
                        keywords={relatedQueries ? relatedQueries.map(item => ({
                            value: item.query,
                            type: 'unknown'
                        })) : []}
                        tooltip="Users searching Google for your term also searched for these queries"
                        field={field}
                    />
                </div>
            )}
            {article && (
                <div>
                    <p>We have processed the URL you provided and have found the following claims. Here&apos;s what we found.</p>
                    {article.map((claim) => (
                        <div key={claim.claim} className="border rounded px-2 py-1 mb-2">
                            <p>{claim.claim}</p>
                            <KeywordList field={field}
                                title="Claims"
                                keywords={claim.entities.map(item => ({
                                    ...item,
                                    type: articleTypeMap[item.type] || 'unknown'
                                }))}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const articleTypeMap = {
    LOC: 'location',
    PER: 'person',
    MISC: 'phrase'
};
