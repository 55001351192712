import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useUser } from '../../../../../context/user/UserContext';
import { getRoom } from '../../../../../utils/variables';

export const CustomNarrativeInfo = ({ customNarrative }) => {
    const room = getRoom();
    const { notAllowed } = useUser();

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="text-uppercase font-weight-bold font-size-15">Topics</p>
                {!notAllowed.includes('manageCustomNarratives') && (
                    <Link to={{ pathname: `/situation-room/${room.id}/create-custom-narrative`,
                        state: { roomId: room.id,
                            narrativeId: customNarrative.id } }}
                    >
                        <p role="button" className="text-primary">
                            <u>Manage</u>
                        </p>
                    </Link>

                )}
            </div>
            <p className="font-weight-bold mb-2">Active Topics</p>
            {customNarrative.data.map((item) => (
                <div key={item.id} className="mb-2">
                    <Button disabled color="success" style={{ opacity: 1 }}>
                        {item.threat}
                    </Button>
                </div>
            ))}
        </div>
    );
};
