import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, FormGroup } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { useWordcloud } from '../../../../services/Overview';
import { Loading } from '../../../Loading';
import { TooltipWrapper } from '../../../ToolTip/TooltipWrapper';

type EntitiesFilterProps = {
    searchQuery: string
}

export const EntitiesFilter = ({ searchQuery = '' }: EntitiesFilterProps) => {
    const dispatch = useDispatch();
    const [dropdownId] = useState(uuid());

    const { entities: selectedEntities }: { entities: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { keywords: topEntities, loading } = useWordcloud({
        field: 'entities',
        size: 10,
        dateFilter: {},
        defaultFilters: {
            wildcard: {
                search: searchQuery,
                field: 'entities'
            }
        },
        search: searchQuery
    });
    const entitiesKeys = [] as string[];
    const entitesList = topEntities.filter((a: {key: string}) => {
        if (entitiesKeys.includes(a.key)) {
            return false;
        }
        entitiesKeys.push(a.key);
        return true;
    });

    const entitiesData = entitesList?.slice(0, 20)?.map((entity: {key: string, keyword: string}) => ({
        id: entity.key,
        name: entity.keyword,
        selected: selectedEntities.includes(entity.key)
    }));

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(saveSelectedFilters({
            entities: selectedEntities.includes(id)
                ? selectedEntities.filter(entity => entity !== id)
                : [...selectedEntities, id]
        }));
    };

    return (
        <div>
            {(!searchQuery && entitiesData.length) ? <p>Top 20 entities</p> : ''}
            <div className="scrollbar-small tab-filter">
                {(entitiesData.length > 0 && !loading) && (
                    <>
                        {entitiesData.map((item: {id: string, name: string, selected: boolean}) => (
                            <FormGroup key={item.id}>
                                <CustomInput tag="checkbox"
                                    type="checkbox"
                                    checked={item.selected}
                                    className="custom-input-filter py-1"
                                    label={item.name.length >= 11
                                        ? (
                                            <TooltipWrapper tooltipText={item.name} id={`tooltip_${dropdownId}_${item.id}`}>
                                                <p className="truncate-this">{item.name}</p>
                                            </TooltipWrapper>
                                        ) : <p className="truncate-this">{item.name}</p>}
                                    id={`${dropdownId}_${item.id}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={(e) => { handleSelectItem(item.id, e); }}
                                />
                            </FormGroup>
                        ))}
                    </>
                )}
                {loading && <Loading relative height={168} width={255} />}
                {(!entitiesData.length && !loading) && <p className="no-white-space">No results found</p>}
            </div>
        </div>
    );
};
