import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { ThreatsWatchlistTableContainer } from './ThreatsWatchlistTableContainer';
import { StickToBottom } from '../../../../../../../components/StickToBottom';
import { setReportAction } from '../../../../../../../redux/reports';

export const AddThreatsFromWatchlistModal = () => {
    const { closeModal } = useModal();
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const handleSave = () => {
        dispatch(setReportAction({ ...report }));
        closeModal();
    };
    return (
        <div>
            <div className="p-3">
                <h2>Add threats in watchlist</h2>
                <p className="mb-3 mt-2">
                    View all threats in watchlist and use ‘add to report’ button to add them to your report.
                    If you have updates the watchlist then you will need to click again on &apos;add watchlist&apos; to update
                    the watchlist on the slide
                </p>
                <ThreatsWatchlistTableContainer />
            </div>
            <StickToBottom>
                <div className=" bg-hover py-2 px-4 border-top d-flex justify-content-end">
                    <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
                    <Button color="primary" onClick={handleSave}>Save</Button>
                </div>
            </StickToBottom>
        </div>
    );
};
