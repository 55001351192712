import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOM_NARRATIVES } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { isOdd } from '../../../../utils/lib';
import { Loading } from '../../../../components/Loading';
import { CustomNarrativeItem } from './CustomNarrativeItem';
import { EmptyCustomNarrativeState } from './EmptyNarrativeState';
import { AddNewNarrativeButton } from './AddNewNarrativeButton';

export const CustomNarrativesOverview = () => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: {
            situationRoom: room.id,
        },
        fetchPolicy: 'no-cache'
    });
    const narratives = data?.getCustomNarratives || [];

    if (loading) {
        return (
            <div className=" px-4 py-5 bg-white rounded">
                <div className="py-3" />
                <div className=" d-flex align-items-center justify-content-center">
                    <div className="py-5 d-flex flex-column align-items-center">
                        <Loading pointer={false} gif relative height={200} />
                    </div>
                </div>
            </div>
        );
    }
    if (narratives?.length === 0) {
        return <EmptyCustomNarrativeState />;
    }

    return (
        <div>
            <AddNewNarrativeButton className="mb-4" />
            {narratives.map((narrative, index) => (
                <div key={narrative.id} className={`threat-item ${isOdd(index) ? '' : 'odd'}`}>
                    <CustomNarrativeItem narrative={narrative} />
                </div>
            ))}
        </div>
    );
};
