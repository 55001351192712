import React, { useState } from 'react';
import {
    Row,
    Table,
    FormGroup,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { Link } from 'react-router-dom';
import noData from '../../../assets/images/noFactChecks.svg';
import notFound from '../../../assets/images/emptyStates/peopleNotFound.svg';
import { EmptyState } from '../../../components/EmptyState';
import { KeyBoardDownIcon, KeyBoardUpIcon } from '../../../assets/icons/svgicons';
import { useDropDown } from '../../../utils/useDropdown';
import { color } from '../../../utils/getColors';

export const FactsTable = ({ project, claimDocs }) => {
    const [searchBox, setSearchBox] = useState('');
    const [status, setStatus] = useState('All');
    const [dropdown, toggleDropdown] = useDropDown();
    const [filteredData, setFilteredData] = useState(claimDocs || []);
    const [sortOrder, setSortOrder] = useState('desc');

    if (!claimDocs || claimDocs?.length <= 0) {
        return (
            <EmptyState title="No Fact check requested"
                summary="You can request for Fact check in Threats section."
                icon={noData}
                action={{
                    name: 'View threats',
                    to: `/situation-rooms/${project}/threats`,
                    color: 'secondary',
                }}
            />
        );
    }

    const handleSearch = (e) => {
        setSearchBox(e.target.value);
        searchFilterData(e.target.value, status);
    };

    const handleStatusFilter = (item) => {
        setStatus(item);
        searchFilterData(searchBox, item);
    };

    const clearSearch = () => {
        setSearchBox('');
        searchFilterData('', status);
    };

    const handleStatusSort = () => {
        let data = [...filteredData];
        data = data.sort((a, b) => (sortOrder === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status)));
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
        setFilteredData(data);
    };

    const searchFilterData = (searchTerm, statusFilter) => {
        let filteredDocs = [...claimDocs];
        if (searchTerm) {
            filteredDocs = filteredDocs.filter(claimDoc => claimDoc.body.toLowerCase().includes(searchTerm.toLowerCase())
            || claimDoc._id.includes(searchTerm.trim()));
        }
        if (statusFilter !== 'All') {
            if (statusFilter === 'Complete') {
                filteredDocs = filteredDocs.filter(claimDoc => claimDoc.status === 'resolved');
            }
            if (statusFilter === 'In-progress') {
                filteredDocs = filteredDocs.filter(claimDoc => claimDoc.status !== 'resolved' && claimDoc.status !== 'rejected');
            }
        }
        return setFilteredData(filteredDocs);
    };

    return (
        <>
            <div className="d-flex mt-2 pr-2">
                <div className="flex-grow-1 ">
                    <FormGroup className="pr-3 mb-4 mr-2">
                        <InputGroup border={searchBox ? 'active' : 'none'}>
                            <Input value={searchBox}
                                onChange={handleSearch}
                                name="searchBox"
                                placeholder="Search for fact checks"
                                data-testid="search-fc"
                            />
                            {searchBox && (
                                <InputGroupAddon addonType="prepend" className="border-right">
                                    <InputGroupText role="button"
                                        onClick={clearSearch}
                                        data-testid="clear-search"
                                    >
                                        <FeatherIcon.X color={color.blue[500]} size={16} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText role="button">
                                    <FeatherIcon.Search color={color.blue[500]} size={16} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="align-items-center">
                    <Row>
                        <span className="align-self-center pr-1">Filter by status</span>
                        <Dropdown isOpen={dropdown} toggle={toggleDropdown}>
                            <DropdownToggle tag="div" className="form-control" data-testid="progress-filter">
                                {status}
                                <span className="float-right">
                                    <FeatherIcon.ChevronDown color={color.blue[500]} size={15} />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu>
                                {['All', 'Complete', 'In-progress'].map((item) => (
                                    <DropdownItem onClick={() => handleStatusFilter(item)} key={item}>
                                        {item}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Row>
                </div>
            </div>
            {filteredData?.length <= 0
                ? (
                    <EmptyState title="No data found" summary="We can&apos;t find any items matching your search" icon={notFound} />
                )
                : (
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th className="no-white-space">ID</th>
                                <th className="no-white-space">Title</th>
                                <th className="no-white-space">Date added</th>
                                <th className="no-white-space">
                                    <span className="d-flex align-items-center"
                                        role="button"
                                        onClick={() =>
                                            handleStatusSort()}
                                    >
                                        Status{' '}
                                        {sortOrder === 'desc' ? (
                                            <KeyBoardUpIcon />
                                        ) : (
                                            <KeyBoardDownIcon />
                                        )}
                                    </span>
                                </th>
                                <th className="no-white-space">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item) => (
                                <tr key={item._id}>
                                    <td>{item._id}</td>
                                    <td style={{ width: '80%' }}>
                                        {item.body}
                                    </td>
                                    <td>{item.published_date}</td>
                                    <td className="no-white-space">
                                        <span className={item.status === 'resolved'
                                            ? 'show-circle no-white-space mr-1' : 'show-circle show-circle-orange no-white-space mr-1'}
                                        />
                                        {item.status === 'resolved' ? 'Complete' : 'In-progress'}
                                    </td>
                                    <td className="no-white-space">
                                        <Link to={{ pathname: `/situation-rooms/${project}/fact-checks/${item._id}`, state: { item } }}>
                                            {item.status === 'resolved' ? 'View result' : 'View details'}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
        </>
    );
};
