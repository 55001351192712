import React, { useState, useMemo, useContext, createContext } from 'react';

const SituationRoomsContext = createContext({});

export const useSituationRooms = () => useContext(SituationRoomsContext);

export const SituationRoomsContextProvider = ({ children }) => {
    const [filters, setFilters] = useState({ deleted: false });
    const [sort, setSort] = useState({ field: 'createdAt', order: 'desc' });
    const context = useMemo(() => ({
        filters,
        setFilters,
        sort,
        setSort
    }), [filters, sort]);

    return (
        <SituationRoomsContext.Provider value={context}>
            { children }
        </SituationRoomsContext.Provider>
    );
};
