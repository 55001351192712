import { convertToMarkers } from '../../convertToMarkers';
import { ACCOUNT_TAGS, TAGS } from '../../editorConfig';
import { getTagData } from './getTagData';

export const allowedTagsValidator = (query, tag, queryByLines, lineLengths) => {
    const highlight = [];

    const tagFragments = tag.split(':');
    if (TAGS.includes(tagFragments[0]) || ACCOUNT_TAGS.includes(tagFragments[0])) {
        const tagData = getTagData(tagFragments, query);

        tagData.forEach(({ tagKey, tagValue, tagSnapshotKey, tagSnapshot }) => {
            if (
            // Empty tag or value
                (tagKey.length === 0 || tagValue.length === 0)
                    // Empty spaces in key
                    || tagKey.length !== tagSnapshotKey.length
            ) {
                highlight.push([
                    query.indexOf(tagSnapshot),
                    query.indexOf(tagSnapshot) + tagSnapshot.length
                ]);
            }
        });
        // Invalid tag
    } else {
        const tagData = getTagData(tagFragments, query);
        tagData.forEach(({ tagSnapshot }) => {
            highlight.push([
                query.indexOf(tagSnapshot),
                query.indexOf(tagSnapshot) + tagSnapshot.length
            ]);
        });
    }

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'Your query contains tags/sources that are not allowed or empty tags.'
        )
    };
};
