import createStyles from 'draft-js-custom-styles';

const { styles, customStyleFn, exporter } = createStyles(['color'], '');

const addColor = (updateEditorState, getEditorState) => (color) => updateEditorState(styles.color.add(getEditorState(), color));

const removeColor = (updateEditorState, getEditorState) => () => updateEditorState(styles.color.remove(getEditorState()));

const currentColor = (getEditorState) => () => styles.color.current(getEditorState());

export const colorPickerPlugin = (updateEditorState, getEditorState) => ({
    addColor: addColor(updateEditorState, getEditorState),
    removeColor: removeColor(updateEditorState, getEditorState),
    currentColor: currentColor(getEditorState),
    customStyleFn,
    exporter,
});
