import React, { useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { useSaveNetwork } from './useSaveNetwork';
import { Loading } from '../../../../../../components/Loading';
import { getToast } from '../../../../../../utils/getToast';

export const SaveNetworkGraph = () => {
    const [networkName, setName] = useState('');
    const { closeModal } = useModal();
    const { saveNetwork, loading } = useSaveNetwork();
    const toast = getToast();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        saveNetwork({
            name: networkName,
            onCompleted: () => {
                closeModal();
                toast.success('Network graph saved');
            }
        });
    };

    if (loading) {
        return <Loading relative height={200} />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Label className="mb-1">Enter name</Label>
            <Input value={networkName}
                onChange={(e) => setName(e.target.value)}
                required
                className="mb-3"
                data-testid="network-name-input"
            />
            <Button className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button color="primary" type="submit">Save</Button>
        </form>
    );
};
