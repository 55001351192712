import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from '../../../components/Loading';
import { GET_ALL_SITUATION_ROOMS } from '../../../graphql';
import { SituationRoomsTable } from './SituationRoomsTable';
import { useSituationRooms } from './SituationRoomsContext';
import { SearchAndFilter } from './SearchAndFilter';
import { EmptyState } from '../../../components/EmptyState';
import { NoSearchResults } from '../../../assets/svg/noSearchResults';
import { Pagination } from '../../../components/Molecules';

export const SituationRoomsInner = () => {
    const [offset, setOffset] = useState(0);

    const { filters, sort } = useSituationRooms();
    const itemsPerPage = 10;
    const { loading, data } = useQuery(GET_ALL_SITUATION_ROOMS, {
        variables: {
            limit: itemsPerPage,
            skip: offset,
            filter: filters,
            sort
        },
        fetchPolicy: 'no-cache'
    });
    const rooms = data?.allSituationRooms?.data || [];
    const total = data?.allSituationRooms?.total || 0;
    return (
        <div>
            <SearchAndFilter />
            {loading ? <Loading relative height={250} /> : (
                <div>
                    {!rooms.length ? (
                        <EmptyState title="We couldn’t find any matches"
                            summary="Please try another keyword"
                            svgIcon={<NoSearchResults />}
                        />
                    ) : (
                        <div className="overflow-x-auto">
                            <SituationRoomsTable rooms={rooms} />
                            <Pagination total={total}
                                itemsPerPage={itemsPerPage}
                                offset={offset}
                                setOffset={setOffset}
                                className="py-2"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
