import { NetworkNode } from '../../useNetworks';
import { createLink } from './createLink';

export const useURLLinks = (data: NetworkNode[]) => Object.fromEntries(
    data.map(({ id, url }) => {
        const contentNodeId = `content_${id}`;
        return [
            `${contentNodeId}_url_${url}`,
            createLink({
                id1: contentNodeId,
                id2: `url_${url}`,
                label: ''
            })
        ];
    })
);
