import React, { useEffect, useState } from 'react';
import { Button, Row } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { useMutation } from '@apollo/client';
import { NarrativeVersionSelector } from '../../../../../components/Organisms/NarrativeVersionSelector';
import { UPDATE_CUSTOM_NARRATIVE } from '../../../../../graphql';
import { SmallPagination } from '../../../../../components/Molecules';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../../../utils/SuccessErrorModal';
import { Loading } from '../../../../../components/Loading';
import { NarrativeActionModal } from './NarrativeActionModal';
import { useCookies } from '../../../../../context/cookies/CookiesContext';
import { useNarrativeVersions } from './useNarrativeVersions';

const ITEMS_PER_PAGE = 10;

export const NarrativeVersions = ({ closeVersions, narrativeData, user, setRedirect }) => {
    const { setModal, closeModal } = useModal();
    const [isApplyingVersion, setIsApplyingVersion] = useState(false);
    const { getCookie } = useCookies();
    const doNotShowNarrativeApplyWarning = getCookie('doNotShowNarrativeVersionApplyWarning') === 'true';
    const narrativeId = narrativeData[0].id;
    const currentNarrative = {
        narrativeId: narrativeData[0].id,
        name: narrativeData[0].name,
        data: narrativeData[0].data,
        narrativeTitle: narrativeData[0].name,
        versionId: narrativeData[0].versionId,
        user
    };
    const [selectedVersion, setSelectedVersion] = useState(currentNarrative);
    const [page, setPage] = useState(0);

    const setStatusModal = ({ message, title, type, action }) => {
        setModal({
            header: false,
            onClose: () => { action(); closeModal(); },
            component: <StatusModalBodyNew title={title} message={message} messageType={type} action={action} />
        });
    };

    const setActionConfirmationModal = ({ onConfirm, onCancel }) => {
        setModal({
            header: false,
            component: <NarrativeActionModal onConfirm={onConfirm}
                onCancel={onCancel}
                cookieId="doNotShowNarrativeVersionApplyWarning"
            />
        });
    };

    const { getNarrativeVersions, loading: narrativeVersionsLoading, error, data } = useNarrativeVersions(narrativeId);
    const [updateCustomNarrative] = useMutation(UPDATE_CUSTOM_NARRATIVE, {
        onCompleted: () => {
            setIsApplyingVersion(false);
            setStatusModal({
                title: false,
                type: STATUS_TYPES.success,
                message: 'Version successfully applied to Narrative!',
                action: setRedirect
            });
        },
        onError: () => {
            setIsApplyingVersion(false);
            setStatusModal({
                title: false,
                status: STATUS_TYPES.error,
                message: 'Error occurred, Please try again later',
            });
        }
    });

    useEffect(() => {
        getNarrativeVersions();
    }, [getNarrativeVersions]);

    if (narrativeVersionsLoading || error || isApplyingVersion) {
        return <Loading />;
    }

    const versionData = data?.getNarrativeVersions || [];
    const narrativeVersions = versionData.find(version => version.versionId === currentNarrative.versionId)
        ? versionData : [currentNarrative, ...versionData];

    return (
        <div className="content-container">
            <Row className="d-flex flex-direction-row align-items-center px-4 py-2 m-0">
                <h2 className="m-0">Custom narrative versions</h2>
                <div className="ml-auto">
                    <FeatherIcon.X color="#050E2B"
                        size={20}
                        className="settings ml-3 cursor-pointer"
                        stroke="#050E2B"
                        onClick={closeVersions}
                    />
                </div>
            </Row>
            <div className="versions-row">
                <NarrativeVersionSelector currentNarrative={currentNarrative}
                    narrativeVersions={narrativeVersions}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    getNarrativeVersions={getNarrativeVersions}
                    page={page}
                    itemsPerPage={ITEMS_PER_PAGE}
                    setPage={setPage}
                />
            </div>
            <div className="fixed-footer">
                <div className="d-flex border-top px-4 py-2 bg-lightgray">
                    <SmallPagination total={narrativeVersions.length} itemsPerPage={ITEMS_PER_PAGE} page={page} setPage={setPage} />
                    <Button className="ml-auto mr-2" color="secondary" onClick={closeVersions} data-testid="versions-back-button">
                        Back
                    </Button>
                    <Button color="primary"
                        data-testid="apply-version-button"
                        disabled={currentNarrative.versionId === selectedVersion.versionId}
                        onClick={() => {
                            const filteredtopics = selectedVersion.data.map((a) => {
                                const newTopic = { ...a };
                                delete newTopic.__typename;
                                return newTopic;
                            });
                            const finalData = {
                                id: selectedVersion.narrativeId,
                                data: filteredtopics,
                                name: narrativeData[0].name,
                                situationRoom: selectedVersion.situationRoom,
                                versionId: selectedVersion.versionId,
                            };
                            if (doNotShowNarrativeApplyWarning) {
                                setIsApplyingVersion(true);
                                updateCustomNarrative({
                                    variables: {
                                        data: finalData
                                    }
                                });
                            } else {
                                setActionConfirmationModal({
                                    onConfirm: () => {
                                        setIsApplyingVersion(true);
                                        updateCustomNarrative({
                                            variables: {
                                                data: finalData
                                            }
                                        });
                                        closeModal();
                                    },
                                    onCancel: () => { closeModal(); }
                                });
                            }
                        }}
                    >
                        Apply version to narrative
                    </Button>
                </div>
            </div>
        </div>
    ); };
