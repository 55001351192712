import React from 'react';
import { v4 as uuid } from 'uuid';
import { isValidHttpUrl } from '../../../../utils/isValidUrl';
import Attachment from '../../../../assets/images/investigations/Attachment.svg';
import { useInvestigation } from '../InvestigationDetails/InvestigationContext';

export const checkSource = (source) => {
    if (source && source !== 'null') {
        if (isValidHttpUrl(source)) {
            return (
                <a href={source} target="_blank" rel="noreferrer">
                    {source}
                </a>
            );
        }
        return (source);
    }
    return ('Not available');
};

const fetchNameFromUrl = (url) => url.replace('https://storage.googleapis.com/logically_li_app_investigation_dev/', '').split('Z').pop();
export const UserAttachments = ({ attachment }) => (
    <li className="d-flex align-items-center">
        <img src={Attachment} height={14} width={14} className="mr-1" />
        <a className="text-secondary"
            target="_blank"
            key={uuid()}
            rel="noreferrer"
            href={attachment}
        >
            {fetchNameFromUrl(attachment)}
        </a>
    </li>
);

export const LogicallyAttachments = () => {
    const { investigation } = useInvestigation();
    return (
        <>
            <h3 className="mt-2">Logically attachments</h3>
            {investigation.report ? (
                <a href={investigation.report}
                    target="_blank"
                    rel="noreferrer"
                >
                    {investigation.report?.filename}
                </a>
            ) : <p>No attachments</p>}
        </>
    );
};
