import React from 'react';
import { RequestInvestigationForm } from './RequestInvestigationForm';

export const RequestInvestigation = ({
    urlOfSource = '',
    threatTest = false,
    defaultDetails,
    noModal = false,
    onClose
}) => (
    <RequestInvestigationForm defaultDetails={defaultDetails}
        urlOfSource={urlOfSource}
        threatTest={threatTest}
        noModal={noModal}
        onClose={onClose}
    />
);
