import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem
} from 'reactstrap';
import { Bell, ExternalLink, MoreVertical } from 'react-feather';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Emptynotificationsicon from '../../../assets/images/Emptynotificationsicon.svg';
import { GET_ALERTS } from '../../../graphql';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';
import { useMarkAsRead } from '../../../services/Alerts/useMarkAsRead';
import { NotificationCard } from './NotificationsCard';

type AlertSituation = {
 id: string,
 name: string
}

type NotificationDefaultProps = {
    id: string,
    projectId: string,
    date: number,
    // rule: number,
    category: string,
    subcategory: string,
    description: string,
    situationRoom: AlertSituation,
    read: boolean

};

export const NotificationsPanel = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { data } = useQuery(GET_ALERTS, {
        variables: {
            limit: 10,
            offset: 0
        }
    });
    const notifications = (data?.getAlerts?.alerts || []) as NotificationDefaultProps[];
    const unread = data?.getAlerts?.unread || 0;

    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const todays = notifications?.filter((item, i) =>
        (moment(item.date).format('DD/MM/YY') === moment(new Date()).format('DD/MM/YY')
    && i <= 6));
    const yesterdays = notifications?.filter((item, i) =>
        moment(item.date).format('DD/MM/YY') === moment(yesterday).format('DD/MM/YY')
    && i <= 6);
    const older = notifications?.filter((item, i) =>
        (moment(item.date).format('DD/MM/YY') !== moment(new Date()).format('DD/MM/YY'))
        && (moment(item.date).format('DD/MM/YY') !== moment(yesterday).format('DD/MM/YY'))
        && i <= 6);
    const { markAsRead } = useMarkAsRead({ alertIds: notifications.map(item => item.id), markAllAsRead: true });

    const location = useLocation();
    const isNotificationsPage = location.pathname === '/notifications';

    return (
        <Dropdown style={{ minWidth: 10 }}
            isOpen={isOpen}
            className="notification-list"
            tag="div"
            id="notiDropdown"
            toggle={() => setIsOpen(!isOpen)}
            data-testid="notifications-dropdown"
        >
            <DropdownToggle tag="div"
                className={`nav-link px-2 ${isNotificationsPage ? 'active' : ''}`}
                data-testid="notifications-dropdown-toggle"
            >
                <Bell color="white" />
                {unread > 0 && (
                    <p className="noti-icon-badge">
                        {unread >= 10 ? '9+' : unread}
                    </p>
                )}

            </DropdownToggle>
            <DropdownMenu className="notification-dropdown mr-n10" right>
                <div>
                    <div className="dropdown-item border-bottom p-3 bg-white">
                        <h5 className="m-0 mt-1 font-size-18">
                            {notifications?.length ? (
                                <span className="float-right">
                                    <span className="text-dark"
                                        role="button"
                                    >
                                        <div className="d-flex">
                                            <TooltipWrapper tooltipText="Open notifications page in new tab" id="tooltip-noti-page">
                                                <Link to="/notifications">
                                                    <ExternalLink size={24} role="button" className="text-primary d-block mr-1" />
                                                </Link>
                                            </TooltipWrapper>
                                            <TooltipWrapper tooltipText="More" id="tooltip-noti-menu">
                                                <UncontrolledDropdown direction="left"
                                                    className="mw-0"
                                                >
                                                    <DropdownToggle tag="div">
                                                        <MoreVertical size={24} role="button" className="text-primary d-block" />
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem className="px-2 py-1" onClick={() => markAsRead()}>
                                                            Mark all as read
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </TooltipWrapper>
                                        </div>
                                    </span>
                                </span>
                            ) : null}
                            Notifications
                        </h5>
                    </div>
                    <div className="scrollbar-small overflow-y-auto maxh-560">
                        <div className="noti-container p-3">
                            {todays?.length !== 0 && (
                                <>
                                    <p className="pb-1 font-size-14">Today</p>
                                    {todays?.map(item => (
                                        <NotificationCard notification={item} key={item.id} />
                                    ))}
                                </>
                            )}
                            {yesterdays?.length !== 0 && (
                                <>
                                    <p className="pb-1 font-size-14">Yesterday</p>
                                    {yesterdays?.map(item => (
                                        <NotificationCard notification={item} key={item.id} />
                                    ))}
                                </>
                            )}
                            {older?.length !== 0 && (
                                <>
                                    <p className="pb-1 font-size-14">Older</p>
                                    {older?.map(item =>
                                        (
                                            <NotificationCard notification={item} key={item.id} />
                                        ))}
                                </>
                            )}
                            <hr />
                            <div className="text-center ">
                                <img src={Emptynotificationsicon} alt="empty-notification" className="mb-1 mt-1" />
                                {!notifications?.length ? (
                                    <>
                                        <h5>No notifications yet</h5>
                                        <p>There are no notifications from the past 7 days.
                                            We’ll display any new updates here.
                                        </p>
                                    </>
                                )
                                    : (
                                        <p>That’s all your notifications from the last 7 days
                                        </p>
                                    )}
                                <Link to="/notifications">
                                    <p className="text-primary">View older notifications</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};
