import { convertToMarkers } from '../convertToMarkers';

export const nearOperatorValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];
    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            highlight
        };
    }

    const allNearClauses = [...query.matchAll(/\bNEAR\b(.*?)(\s|$)/g)];

    allNearClauses.forEach(match => {
        const isValidSeparator = match[0].indexOf('NEAR/') !== -1;
        const numberContent = match[0].replace('NEAR/', '').replace(/(f|f\s)$/gm, '');

        if (!isValidSeparator) {
            highlight.push([match.index, match.index + match[0].length]);
        } else if (!Number(numberContent)) {
            highlight.push([match.index, match.index + match[0].length]);
        }
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'Operator "NEAR" must follow the guidelines. ie. NEAR/5 or NEAR/5f'
        )
    };
};
