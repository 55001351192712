import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../../../../../components/Loading';
import { Table } from '../../../../../../../components/Table';
import { useTopLanguages } from '../../../../../../../services/Overview';

export const TopLanguages = ({ className, small }) => {
    const { report } = useSelector(state => state.createReport);
    const { data, loading } = useTopLanguages({
        dateFilter: report.filters,
        includeThreats: true
    });

    if (loading) {
        return <Loading relative height={200} />;
    }
    return (
        <Table columns={topLanguagesTablecolumns}
            data={data}
            condensed={`p-${small ? 1 : 2}`}
            className={className}
            hover
        />
    );
};

const topLanguagesTablecolumns = [
    {
        name: 'Languages',
        field: 'language'
    },
    {
        name: 'Matches',
        field: 'count'
    },
    {
        name: 'Threats',
        field: 'threats'
    }
];
