import { useQuery } from '@apollo/client';
import { GET_SUGGESTIONS } from '../../../../graphql';
import { useAnalyseArticle } from '../../../../services/analyseArticle';
import { useRelatedQueries } from '../../../../services/google/relatedQueries';
import { useRelatedTopics } from '../../../../services/google/relatedTopics';
import { useDictionary } from '../../../../services/useDictionary';
import { useSocialAccounts } from '../../../../services/useSocialAccounts';

export const useSuggestions = ({ selected }) => {
    const { loading, data } = useQuery(GET_SUGGESTIONS, {
        variables: {
            query: selected
        }
    });
    const suggested = data ? data.getSuggestions.data[0] : false;
    const isKeyword = suggested?.type === 'Keyword' || suggested?.type === 'Country';
    const isArticle = suggested?.type === 'Article';
    const isPerson = suggested?.type === 'Account' || suggested?.type === 'Person' || selected.indexOf('@') === 0;

    const { loading: loadingA, data: relatedTopics } = useRelatedTopics({
        query: selected,
        skip: isArticle
    });

    const { loading: loadingB, data: relatedQueries } = useRelatedQueries({
        query: selected,
        skip: isArticle
    });

    const { loading: loadingC, data: keywordData } = useDictionary({
        variables: {
            keyword: selected,
            lang: 'en'
        },
        skip: !isKeyword
    });

    const { data: article, loading: loadingD } = useAnalyseArticle({
        url: selected,
        skip: !isArticle
    });

    const { data: socialAccounts, loading: loadingE } = useSocialAccounts({
        username: selected,
        skip: !isPerson
    });

    return {
        loading: loading || loadingA || loadingB || loadingC || loadingD || loadingE,
        suggested: suggested ? {
            ...suggested,
            type: typesMap[suggested.type] || suggested.type
        } : false,
        relatedTopics: !isArticle ? relatedTopics : false,
        relatedQueries: !isArticle ? relatedQueries : false,
        keywordData: isKeyword ? keywordData : false,
        article: isArticle ? article : false,
        socialAccounts: isPerson ? socialAccounts : false
    };
};

const typesMap = {
    Country: 'country',
    Person: 'person',
    Account: 'account',
    Keyword: 'phrase',
    Article: 'domain'
};
