import { ERROR_MARKER } from './editorConfig';

export const convertToMarkers = (highlight, queryByLines, lineLengths, errorMessage) => {
    const markers = highlight.map((highlightRange) => {
        let startMarkerLine;
        let startMarkerColumn;
        let endMarkerLine;
        let endMarkerColumn;

        let totalLength = 0;

        for (let i = 0; i < queryByLines.length; i++) {
            totalLength += lineLengths[i];

            if ((startMarkerLine === undefined || startMarkerColumn === undefined) && highlightRange[0] < totalLength) {
                startMarkerLine = i + 1;
                startMarkerColumn = highlightRange[0] - totalLength + lineLengths[i] + 1;
            }
            if ((endMarkerLine === undefined || endMarkerColumn === undefined) && highlightRange[1] < totalLength) {
                endMarkerLine = i + 1;
                endMarkerColumn = highlightRange[1] - totalLength + lineLengths[i] + 1;
            }
        }

        return {
            startLineNumber: startMarkerLine,
            endLineNumber: endMarkerLine,
            startColumn: startMarkerColumn,
            endColumn: endMarkerColumn,
            message: errorMessage,
            severity: ERROR_MARKER
        };
    });
    return markers;
};
