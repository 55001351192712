import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';
import { Page } from './Page';
import { NotFound } from '../../../Error/404';
import { useContent } from '../../../../services/Content/getContent';

export const Threat = () => {
    const match = useRouteMatch();
    const {
        loading,
        data: contents
    } = useContent({
        filters: { ids: match.params.id },
        limit: 1,
        fetchPolicy: 'no-cache',
        name: 'getThreatSummary'
    });

    if (loading) return <Loading gif height={600} />;
    if (!contents[0]) return <NotFound small />;

    return (
        <Page data={contents[0]} />
    );
};
