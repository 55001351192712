import React from 'react';
import { FormGroup, Label } from 'reactstrap';

export const RadioFilter = ({ title, filters, checked, setChecked }) => (
    <>
        {title && <p className="font-weight-bold">{title}</p>}
        {filters.map((item) => (
            <FormGroup key={item.id} className="center-block">
                <input type="radio"
                    checked={checked === item.key}
                    id={item.id}
                    value="time"
                    onChange={(e) => {
                        if (e.target.checked) setChecked(item.key);
                    }}
                    data-testid={`radio-${item.key}`}
                />
                <Label className="mt-1 align-top">{item.label}</Label>
            </FormGroup>
        ))}
    </>
);
