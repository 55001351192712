import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import { BackButton } from '../../../components/Atoms';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { Loading } from '../../../components/Loading';
import { useAdminUser } from '../../../context/adminUser/AdminUserContext';
import { InstancesTable } from './InstancesTable';

export const Instances = () => {
    const { user } = useAdminUser();
    const { data, loading } = useQuery(GET_INSTANCES, {
        fetchPolicy: 'no-cache'
    });
    const [searchVal, setSearchVal] = useState('');
    const instances = data?.instances?.map(instance => ({
        ...instance,
        ...instance.account,
        ...instance.plan
    })) || [];

    const filterInstances = () => {
        if (!searchVal) { return instances; }
        const lowercaseSearch = searchVal.toLowerCase();
        return instances.filter((instance) =>
            instance.clientName.toLowerCase().includes(lowercaseSearch)
            || instance.account.contactName.toLowerCase().includes(lowercaseSearch));
    };

    return (
        <div className="p-4" data-testid="admin-dashboard">
            <BreadCrumbs items={[
                { title: 'Admin home', href: '/admin' },
                { title: 'Instances' }
            ]}
            />
            <BackButton pathName="/admin" />
            <h2 className="mb-4 mt-0">Instances ({instances.length || 0})</h2>
            <div className="mb-4 d-flex align-items-center">
                <Input value={searchVal}
                    onChange={(e) => setSearchVal(e.target.value)}
                    placeholder="Search clients"
                    className="mr-3 flex-1"
                />
                {user.role === 'ADMIN' && (
                    <Link to="/admin/instances/create" className="btn btn-primary">
                        Add new client
                    </Link>
                )}
            </div>
            <InstancesTable instances={filterInstances(instances)} />
            {loading && <Loading height={300} relative />}
        </div>
    );
};

export const GET_INSTANCES = gql`
    query instances {
        instances {
            id
            clientName
            account {
                contactName
            }
            plan {
                isTrial
                restrictedTeamRole
                validFrom
                validUntil
            }
        }
    }
`;
