import React from 'react';
import { chartColors, dateTimeLabelFormats } from '../../../../../../../components/Highcharts/config';
import { ResponsiveChart } from '../../../../../../../components/Highcharts';

export const CustomNarrativeChart = ({ data }) => {
    const options = {
        title: {
            text: ''
        },
        chart: {
            backgroundColor: 'transparent',
            spacingLeft: 0,
            height: 350
        },
        colors: chartColors,
        series: data.threats.map(threat => ({
            type: 'line',
            name: threat.threat,
            data: threat.aggs.trend.map(item => ({
                x: item.key,
                y: item.doc_count
            }))
        })),
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats,
            tickPixelInterval: 150
        },
        yAxis: {
            title: {
                text: 'Volume'
            }
        },
        tooltip: {
            dateTimeLabelFormats,
        },

        legend: {
            align: 'left',
            margin: 24,
            itemDistance: 16,
            padding: 0,
            itemStyle: {
                cursor: 'default'
            },
            symbolHeight: 24,
            symbolWidth: 8,
            squareSymbol: false,
            symbolRadius: 4,
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: (e) => e.preventDefault()
                },
                marker: {
                    enabled: false
                }
            }
        }
    };

    return (
        <ResponsiveChart options={options} />
    );
};
