import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import { routes } from '../../../routes';
import { CustomNarrativesOverview } from './Overview';

export const CustomNarratives = ({ room }) => {
    const bread = useMemo(() => ([
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: room?.project_name, href: `/situation-rooms/${room?.id}` },
        { title: 'Custom Narratives', href: `/situation-rooms/${room?.id}/custom-narratives` }
    ]), [room]);

    return (
        <div className="page-padding narratives">
            <BreadCrumbs items={bread} />
            <Switch>
                <Route exact path={routes.situationRoom.customNarratives}>
                    <CustomNarrativesOverview />
                </Route>
            </Switch>
        </div>
    );
};
