import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
    Bookmark,
    CornerUpLeft,
    CornerUpRight,
    Download,
    Minus,
    Plus,
    RefreshCw,
    Save,
    Settings
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { maxZoom, minZoom } from '.';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ChartExportModal, ChartExportModalProps } from './modals/ChartExportModal';
import { NetworksHistoryStore } from '../store';
import { useNetworkHistory } from './handlers';
import { SaveNetworkGraph } from './modals';
import { ResetGraph } from './modals/ResetGraph';
import { IconButton } from '../../../../components/Atoms';
import { resetFilters } from '../../store';
import { getRoom } from '../../../../utils/variables';
import { SavedNetworksModal } from './modals/SavedNetworks/SavedNetworksModal';
import { NetworkSettings } from './modals/NetworkSettingsModal';
import { SearchBarNewRef } from '../../../../components/NewFilters';

export type NetworksToolbarRef = {
    setZoom: (zoom: number) => void;
}

interface NetworksToolbarProps extends ChartExportModalProps {
    chartZoom: (direction: 'in' | 'out') => void;
}

export const NetworksToolbar = forwardRef(({ chartZoom, chartExport }: NetworksToolbarProps, ref) => {
    const searchRef = useRef<SearchBarNewRef>(null);
    const room = getRoom();
    const [zoomPercentage, setZoomPercentage] = useState(0);
    const { setModal } = useModal();
    const { past, future } = useSelector((state: NetworksHistoryStore) => state.networksHistory);
    const networkHistory = useNetworkHistory();
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        setZoom: (zoom: number) => {
            const currentZoom = Math.max(minZoom, Math.min(maxZoom, zoom * 1.2));
            let newZoomPercentage = 0;

            if (parseFloat(zoom.toFixed(3)) !== minZoom) {
                newZoomPercentage = Math.round((Math.log(currentZoom / minZoom) / Math.log(maxZoom / minZoom)) * 100);
            }
            if (newZoomPercentage === zoomPercentage) return null;
            setZoomPercentage(newZoomPercentage);
        }
    }));

    const openExportModal = () => {
        setModal({
            header: 'Export image as',
            component: <ChartExportModal chartExport={chartExport} />
        });
    };

    const openSaveModal = () => {
        setModal({
            header: 'Save network graph',
            component: <SaveNetworkGraph />
        });
    };

    const openResetGraphModal = () => {
        setModal({
            header: 'Reset graph',
            component: <ResetGraph resetGraph={() => { networkHistory.reset(); dispatch(resetFilters({ roomId: room?.id })); }} />
        });
    };

    const handleOpenSavedNetworks = () => {
        setModal({
            header: 'Saved networks',
            size: 'lg',
            component: <SavedNetworksModal />,
        });
    };
    const handleSettings = () => {
        setModal({
            header: 'Settings',
            size: 'lg',
            component: <NetworkSettings />
        });
        if (searchRef.current) {
            searchRef.current.closeFilters();
        }
    };

    return (
        <div className="absolute-bottom-right bg-white mr-4 mb-4 border rounded d-flex align-items-center">
            <div className="p-2 border-right text-primary">
                <IconButton className="mr-2"
                    onClick={() => networkHistory.undo()}
                    dataTestid="history-undo-button"
                    icon={<CornerUpLeft />}
                    disabled={!past.length}
                />
                <IconButton className="mr-2"
                    onClick={() => networkHistory.redo()}
                    dataTestid="history-redo-button"
                    icon={<CornerUpRight />}
                    disabled={!future.length}
                />
                <IconButton className="mr-2 reset-network-graph"
                    onClick={openResetGraphModal}
                    dataTestid="history-reset-button"
                    icon={<RefreshCw />}
                    disabled={!past.length}
                />
            </div>
            <div className="p-2 border-right">
                <IconButton className="mr-2"
                    onClick={() => chartZoom('out')}
                    dataTestid="zoom-out-button"
                    icon={<Minus />}
                />
                <span className="user-select-none">{zoomPercentage}%</span>
                <IconButton className="ml-2"
                    onClick={() => chartZoom('in')}
                    dataTestid="zoom-in-button"
                    icon={<Plus />}
                />
            </div>
            <div className="p-2">
                <IconButton className="save-network-graph"
                    onClick={openSaveModal}
                    dataTestid="network-save-button"
                    icon={<Save />}
                    disabled={!past.length}
                />
                <IconButton className="ml-2"
                    onClick={() => handleOpenSavedNetworks()}
                    icon={<Bookmark />}
                />
                <IconButton className="ml-2"
                    onClick={() => handleSettings()}
                    icon={<Settings />}
                    dataTestid="network-settings"
                />
                <IconButton className="export-network-graph ml-2"
                    onClick={openExportModal}
                    dataTestid="download-button"
                    icon={<Download />}
                />
            </div>
        </div>
    );
});
