import { ExportToCsv } from 'export-to-csv-fix-source-map';
import React from 'react';
import { Button } from 'reactstrap';
import { formatDate } from '../../../../utils/getTimeFrom';
import { formatThreatLabel, getCtype } from '../../../../utils/text';
import { getRoom } from '../../../../utils/variables';
import { getContentText } from '../../../../utils/getContentText';

export const ThreatsWatchlistCsv = ({ contents }) => {
    const room = getRoom();

    const ExportCsvFile = (threats) => {
        const csvExporter = new ExportToCsv({
            title: `${room?.project_name}_ThreatsWatchlist`,
            useKeysAsHeaders: true,
            filename: `${room?.project_name}_ThreatsWatchlist`,
            useBlanksForUndefined: true
        });
        csvExporter.generateCsv(threats.map((threat) => {
            const content = getContentText({ body: threat?.body, text: threat?.text });
            const likesCount = Number.isNaN(Number(threat?.likesCount)) ? -1 : Number(threat?.likesCount);
            const repliesCount = Number.isNaN(Number(threat?.repliesCount)) ? -1 : Number(threat?.repliesCount);
            const sharesCount = Number.isNaN(Number(threat?.sharesCount)) ? -1 : Number(threat?.sharesCount);
            const viewsCount = Number.isNaN(Number(threat?.viewsCount)) ? -1 : Number(threat?.viewsCount);
            const engagementCount = Number.isNaN(Number(threat.engagement)) ? -1 : Number(threat.engagement);

            return {
                threat_label: formatThreatLabel(threat.threatObject.threatLabels)?.name || formatThreatLabel(threat.whyathreat)?.name,
                posted_on: formatDate(threat?.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z'),
                location_mentions: threat?.locationsDetected,
                threat: `"${(content[0] || '')}"`,
                content_url: threat?.url === -1 ? 0 : threat?.url,
                source: getCtype(threat?.contentType)?.name,
                engagement: engagementCount < 0 ? 'NA' : engagementCount,
                likes: likesCount < 0 ? 'NA' : likesCount,
                replies: repliesCount < 0 ? 'NA' : repliesCount,
                shares: sharesCount < 0 ? 'NA' : sharesCount,
                views: viewsCount < 0 ? 'NA' : viewsCount
            };
        }));
    };

    return (
        <Button onClick={() => ExportCsvFile(contents)} className="set-button-content ml-2 mb-4">Download as CSV</Button>
    );
};
