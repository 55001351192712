import React from 'react';
import { Button, CustomInput, Spinner } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Images } from '../../../../utils/modeSwitch';
import { useClusteringTimeEstimate } from '../../../../services/Clusters/getClusteringTimeEstimate';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useCookies } from '../../../../context/cookies/CookiesContext';
import { useStartRoomClustering } from '../../../../services/Clusters/startRoomClustering';
import { Loading } from '../../../../components/Loading';

const cookieId = 'findNarrativesConfirmation';

export const FindNarratives = () => {
    const { startRoomClustering, loading } = useStartRoomClustering();

    if (loading) {
        return <Loading relative height={300} />;
    }

    return (
        <div className="maxw-300 text-center">
            <img src={Images().noResults}
                className="maxw-350 maxh-300"
            />
            <h2 className="my-2">Find narratives</h2>
            <p className="my-2">
                AI Narratives allows you to follow new trends or emerging stories generated
                across your situation room. To get started click ‘Find Narratives’ below.
            </p>
            <Link component={Button}
                className="mr-2"
                to={{ pathname: 'https://logically-intelligence.readme.io/docs/ai-narratives' }}
                target="_blank"
                rel="noreferrer"
            >
                Learn more
            </Link>
            <FindNarrativesButton startRoomClustering={startRoomClustering} />
        </div>
    );
};

export const FindNarrativesButton = ({ startRoomClustering, refresh }: { startRoomClustering: () => void, refresh?: boolean }) => {
    const { setModal } = useModal();
    const { getCookie } = useCookies();

    const handleClick = () => {
        const dontShowConfirmation = getCookie(cookieId);
        if (dontShowConfirmation === 'true') {
            startRoomClustering();
            return;
        }
        setModal({
            header: 'Find Narratives',
            component: <FindNarrativesModal startRoomClustering={startRoomClustering} refresh={refresh} />
        });
    };

    return <Button color="primary" onClick={handleClick}>Find Narratives</Button>;
};

const FindNarrativesModal = ({ startRoomClustering, refresh }: { startRoomClustering: () => void, refresh?: boolean }) => {
    const { closeModal } = useModal();
    const { setCookie } = useCookies();

    const { loading, estimatedTime } = useClusteringTimeEstimate({ refresh });
    return (
        <div>
            <p>
                The estimated time to find new narratives
                is {loading ? <Spinner size="sm" className="text-primary" /> : (
                    <strong>
                        {moment.duration(estimatedTime * 1000).humanize()}
                    </strong>
                )}.
            </p>
            <p className="my-3">You can monitor its progress on this screen.</p>
            <CustomInput type="checkbox"
                name={cookieId}
                onChange={({ target: { checked } }) => setCookie(cookieId, !!checked)}
                className="custom-control-input-small my-3"
                label="Do not show this message again"
                id={cookieId}
            />
            <hr className="my-3" />
            <Button onClick={closeModal} className="mr-2">Cancel</Button>
            <Button color="primary" onClick={() => { startRoomClustering(); closeModal(); }}>Find Narratives</Button>
        </div>
    );
};
