/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

export const ResizeHandle = forwardRef((props, ref) => {
    const { handleAxis, ...restProps } = props;
    return (
        <div ref={ref}
            data-testid={`resize-handle-${handleAxis}`}
            className={`bg-white border border-primary react-resizable-handle handle-${handleAxis}`}
            {...restProps}
        />
    );
});
