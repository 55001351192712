import React from 'react';
import { Label } from 'reactstrap';
import { useInvestigation } from './InvestigationContext';
import { UploadFile } from './UploadFile';

export const AddNotes = () => {
    const { note, setNote, file, setFile, investigationStatus, setInvestigationStatus } = useInvestigation();
    return (
        <>
            <hr className="w-100" />
            <p className="mt-1">Add notes</p>
            <textarea className="form-control mb-3"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                data-testid="add-note"
            />
            <UploadFile setFile={setFile} filename={file && (file.name || file.filename)} />
            <h3>Change status</h3>
            <div className="d-flex pt-1">
                <Label>
                    <input type="radio"
                        className="mb-n11"
                        id="in-progress"
                        name="in-progress"
                        value="in-progress"
                        checked={investigationStatus === 'In Progress'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setInvestigationStatus('In Progress');
                            }
                        }}
                    />
                    In progress
                </Label>
                <Label className="ml-2">
                    <input className="mb-n11"
                        type="radio"
                        id="completed"
                        name="completed"
                        value="completed"
                        checked={investigationStatus === 'Completed'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setInvestigationStatus('Completed');
                            }
                        }}
                    />
                    Completed
                </Label>
            </div>
            <hr className="w-100 mt-3 mb-3" />
        </>
    );
};
