import React, { useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Card } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { BooleanSearchContent } from './BooleanSearchContent';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../utils/SuccessErrorModal';
import { closeBooleanFilters, saveFilters } from '../../../pages/Room/store';
import { Loading } from '../../Loading';
import { useQueryValidator } from '../../../pages/SituationRooms/RoomEditor/RoomPreview/useQueryValidator';
import { findAndReplaceInObject } from '../../../utils/lib/findAndReplaceInObject';

export const BooleanSearch = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state: any) => state.filters.filters);
    const { setModal } = useModal();
    const [searching, setSearching] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [invalidQuery, setInvalidQuery] = useState(false);
    const { validateQuery } = useQueryValidator({
        lazy: true,
        room: {
            query_type: 'boolean',
            query: {
                included: [],
                excluded: [],
                boolean: filters.booleanQuery
            }
        }
    });

    const setStatusModal = ({ message, title, type }: {message: string, title: boolean, type: string}) => {
        setModal({
            header: false,
            component: <StatusModalBodyNew title={title} message={message} messageType={type} />
        });
    };

    const handleSearch = async () => {
        setSearching(true);
        setInvalidQuery(false);
        try {
            const { data: ret } = await validateQuery({
                variables: {
                    query: {
                        included: [],
                        excluded: [],
                        boolean: filters.booleanQuery
                    },
                    query_type: 'boolean'
                }
            });

            if (ret.validateQuery.isValid) {
                dispatch(saveFilters({
                    convertedBooleanQuery: findAndReplaceInObject(
                        ret.validateQuery.query?.query || {},
                        'minimum_should_match',
                        (a: any) => a.toString()
                    ),
                    booleanQueryKeywords: ret.validateQuery.keywords || []
                }));
                dispatch(closeBooleanFilters());
            } else {
                setInvalidQuery(true);
            }
            setSearching(false);
        } catch (err) {
            setStatusModal({
                title: false,
                type: STATUS_TYPES.error,
                message: 'Validation error occurred, Please try again later'
            });
            setSearching(false);
        }
    };

    const handleReset = () => {
        setValidationErrors({});
        setInvalidQuery(false);
        dispatch(saveFilters({
            booleanQuery: '',
            convertedBooleanQuery: ''
        }));
    };

    return (
        <div className="position-relative">
            {searching && (
                <div className="h-100 w-100 position-absolute bg-white d-flex
                    align-items-center justify-content-center z-index border border-primary rounded"
                >
                    <div>
                        <Loading relative />
                        <p className="mt-2">We&apos;re just validating your query...</p>
                    </div>
                </div>
            )}
            <Card body outline color="primary" className="bg-light border" data-testid="boolean-search">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h3 className="font-weight-bold m-0">Boolean Search</h3>
                    <FeatherIcon.X size={16}
                        role="button"
                        onClick={() => dispatch(closeBooleanFilters())}
                        data-testid="boolean-search-x-icon"
                    />
                </div>
                <Row className="align-items-stretch m-0 p-3 bg-white rounded">
                    <BooleanSearchContent booleanQuery={filters.booleanQuery}
                        setBooleanQuery={(query: string) => {
                            dispatch(saveFilters({
                                booleanQuery: query
                            }));
                        }}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                        disabled={searching}
                        invalidQuery={invalidQuery}
                        setInvalidQuery={setInvalidQuery}
                    />
                </Row>
                <div className="d-flex mt-4">
                    <Button color="primary"
                        onClick={handleSearch}
                        className="mr-3"
                        disabled={searching
                            || (!filters.booleanQuery || Boolean(Object.values(validationErrors)
                                .find((error: any) => error.isError === true)))
                            || invalidQuery}
                    >
                        Search
                    </Button>
                    <Button color="danger"
                        className="border-0"
                        onClick={handleReset}
                        disabled={searching}
                    >
                        Reset
                    </Button>
                </div>
            </Card>
        </div>
    );
};
