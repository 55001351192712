import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { BrushChart } from './BrushChart';
import { useContentFilters } from '../useFilters';

export const ThreatVolumeOverTime = ({ setSelectedDate, setOffset }) => {
    const contentFilters = useContentFilters({
        defaultFilters: {
            isThreat: true
        }
    });
    const { data, loading } = useQuery(GET_CONTENT_VOLUME_OVER_TIME, {
        variables: contentFilters
    });
    const buckets = data?.getContentVolumeOverTime || [];
    if (loading || !buckets.length) return null;
    return (
        <BrushChart title="Threats volume over time"
            data={buckets}
            onClick={(newDate) => { setSelectedDate(newDate); setOffset(0); }}
        />
    );
};

export const GET_CONTENT_VOLUME_OVER_TIME = gql`
    query getContentVolumeOverTime($projectId: String!, $filters: ContentFilters, $dslQuery: JSON) {
        getContentVolumeOverTime(projectId: $projectId, filters: $filters, dslQuery: $dslQuery) {
            key
            count
        }
    }
`;
