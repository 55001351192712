import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { ClustersSortOptions } from '../../AIClustering/Utils/ClusterSort';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../graphql';
import { Cluster } from '../../AIClustering/types';
import { Loading } from '../../../../components/Loading';
import { ClustersTable } from '../../AIClustering/ListView/ClustersTable';
import { setParam } from '../../../../utils/urlParams';
import { EmptyState } from '../../../../components/EmptyState';
import { Images } from '../../../../utils/modeSwitch';
import { SearchInput } from '../../../../components/Molecules';
import { getRoom } from '../../../../utils/variables';

export const NarrativeThemesRemovedContent = () => {
    const room = getRoom();
    const [searchVal, setVal] = useState('');
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Most content' });
    const [clustersOffset, setClustersOffset] = useState(0);
    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            limit: 10,
            skip: clustersOffset,
            search: searchVal,
            projectId: room.project_id,
            sort: pick(sort, ['field', 'order']),
            checkStatus: 'deleted'
        },
        fetchPolicy: 'no-cache'
    });
    const getClustersData = clustersData?.getClusters;
    const clusters: Cluster[] = getClustersData?.clusters || [];
    const setSelectedCluster = (id: string) => {
        setParam('narrative-theme', id);
    };
    if (clusterLoading) {
        return <Loading relative height={300} />;
    }
    if (clusters?.length < 1) {
        return (
            <div className="border mb-3">
                <EmptyState title="No removed narrative themes"
                    summary="Removed narrative themes will appear here."
                    icon={Images().metrics}
                />
            </div>
        );
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <SearchInput placeholder="Search by keywords"
                    handleSearch={setVal}
                    className="w-100 mr-2"
                    defaultValue={searchVal}
                />
                <ClustersSortOptions sort={sort} setSort={setSort} classname="mb-1 mr-4" />
            </div>
            <p className="mb-3">{clusters.length} narrative theme{clusters.length > 1 ? 's' : ' '}</p>
            <ClustersTable clusters={clusters}
                total={getClustersData.total}
                offset={clustersOffset}
                onSetSelectedCluster={setSelectedCluster}
                onOffsetChange={setClustersOffset}
                itemsPerPage={10}
                isFromRemoveContent
            />
        </>
    );
};
