import { Button } from 'reactstrap';
import React from 'react';
import { X } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

type BatchRemovePreparingModalProps = {
    handleCancel: () => void;
};
export const BatchRemovePreparingModal = ({ handleCancel }: BatchRemovePreparingModalProps) => (
    <>
        <div className="d-flex justify-content-between">
            <h2 className="mt-0">In progress</h2>
            <X color="#050E2B"
                size={20}
                data-testid="close-modal"
                className="cursor-pointer mt-1"
                stroke="#050E2B"
                onClick={handleCancel}
            />
        </div>
        <hr className="mb-2 mt-0" />
        <FontAwesomeIcon icon={faSpinner as any} className="mr-1 fa-spin" /> Preparing, please wait
        <hr className="my-2" />
        <Button className="mr-2" color="danger" onClick={handleCancel}>Cancel removal</Button>
    </>
);
