type ColorProps = {
    blue: {
        600: string;
        500: string;
        400: string;
        300: string;
        200: string;
        100: string;
    },
    darkblue: {
        800: string;
        700: string;
        600: string;
        500: string;
        400: string;
        300: string;
        200: string;
        100: string;
    },
    grey: {
        500: string;
        400: string;
        300: string;
        200: string;
        100: string;
        white: string;
    },
    red: {
        400: string;
        300: string;
        200: string;
        100: string;

    },
    yellow: {
        500: string,
        400: string;
        300: string;
        200: string;
        100: string;

    },
    green: {
        300: string;
        200: string;
        100: string;

    },
    graphColors: {
        magenta: string;
        robinsEggBlue: string;
        tomato: string;
        tangerineYellow: string;
        slateBlue: string;
        citrus: string;
        endeavour: string;
        lightBlue: string;
        kellyGreen: string;
        indigo: string;
        unknown: string;
    }
}

export const color: ColorProps = {
    blue: {
        600: '#025AC7',
        500: '#006FF9',
        400: '#3D94FF',
        300: '#6BAEFF',
        200: '#C5D0FC',
        100: '#E6F1FE',
    },
    darkblue: {
        800: '#002149',
        700: '#030E3A',
        600: '#1B1B31',
        500: '#20233C',
        400: '#232643',
        300: '#272949',
        200: '#2B2D50',
        100: '#53538C',
    },
    grey: {
        500: '#6f6f6f', // using it for wordclould reports
        400: '#B0B4C5',
        300: '#E5E6EB',
        200: '#F8F8F8',
        100: '#FDFDFD',
        white: '#FFFFFF'
    },
    red: {
        400: '#53010E',
        300: '#D4294B',
        200: '#F84367',
        100: '#FFEDF0'
    },
    yellow: {
        500: '#FCD742',
        400: '#503F01',
        300: '#FCCD13',
        200: '#FFFF00',
        100: '#FFFAE8'
    },
    green: {
        300: '#004F41',
        200: '#13BA9C',
        100: '#E8F9F6'
    },
    graphColors: {
        magenta: '#D945D0',
        robinsEggBlue: '#00C0C7',
        tomato: '#FF6C4C',
        tangerineYellow: '#FFC700',
        slateBlue: '#9C50FD',
        citrus: '#ABC700',
        endeavour: '#295F87',
        lightBlue: '#B8D3DE',
        kellyGreen: '#2DAD00',
        indigo: '#5E1291',
        unknown: '#E7C2FF'
    }
};
