import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type TextareaWithCounterProps = {
    label: string,
    name: string,
    limit: number,
    rows: string,
    value?: string,
    className?: string,
    onChange: (e: string) => void,
    ariaLabel: string,
    error?: boolean
}

export const TextareaWithCounter = (
    { label, name, limit, rows, value = '', className = '', onChange, ariaLabel, error = false }: TextareaWithCounterProps
) => (
    <FormGroup className={className}>
        <div className="d-flex justify-content-between">
            <Label>{label}</Label>
            {limit && <div>{value.length}/{limit} characters remaining</div>}
        </div>
        <Input type="textarea"
            aria-label={ariaLabel}
            name={name}
            value={value}
            rows={rows}
            data-testid={`${name}-text-area`}
            onChange={(e) => onChange((e.target.value || '').slice(0, limit))}
            invalid={error}
        />
    </FormGroup>
);
