import React from 'react';
import { X } from 'react-feather';

export const KeywordItem = ({ removeKeyword, keyword, setSelectedKeyword, duplicateKeyword, selectedKeyword }) => (

    <div onClick={() => setSelectedKeyword(keyword)}
        color={duplicateKeyword === keyword ? 'danger' : 'primary'}
        className={`tag mb-1 mr-1 ${selectedKeyword === keyword ? 'selected' : ''} ${duplicateKeyword === keyword && 'duplicate'}
         cursor-pointer`}
    >
        <span className="dont-break-out">
            {keyword.trim()}
        </span>
        <button className="btn"
            type="button"
            onClick={removeKeyword}
        >
            <X />
        </button>
    </div>

);
