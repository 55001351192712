import { useMutation } from '@apollo/client';
import { ADD_TO_WATCHLIST, GET_WATCHLIST } from '../../graphql';
import { getToast } from '../../utils/getToast';

type UseAddToWatchlistProps = {
    situationRoomId: string
    onCompleted?: () => void;
    onError?: (data: Error | string) => void;
}

export const useAddToWatchlist = ({
    situationRoomId,
    onCompleted,
    onError
}: UseAddToWatchlistProps) => {
    const toast = getToast();
    const [addToWatchlistRequest, { loading }] = useMutation(ADD_TO_WATCHLIST, {
        refetchQueries: [{ query: GET_WATCHLIST, variables: { situationRoom: situationRoomId } }],
        onCompleted: () => {
            toast.success("You've added selected content to the watchlist.");
            onCompleted?.();
        },
        onError
    });
    const addToWatchlist = async (content: any[]) => {
        if (!situationRoomId) {
            return onError?.('Situation Room Id is required');
        }
        const validData = content.map(contentItem => {
            if (Object.keys(contentItem).length > 0 && contentItem.id) {
                return contentItem;
            }
        }).filter(contentItem => contentItem);

        if (validData.length !== content.length) {
            return onError?.('One or more items from provided data are invalid');
        }

        return addToWatchlistRequest({
            variables: {
                situationRoom: situationRoomId,
                watchlistItem: validData.map(contentItem => ({
                    content_id: contentItem.id,
                    text: contentItem.text,
                    from: contentItem.from,
                    ctype: contentItem.contentType,
                    engagement: contentItem.engagement,
                    display_name: contentItem.displayName,
                    locations_detected: contentItem.locationsDetected,
                    url: contentItem.url,
                    whyathreat: contentItem.whyathreat,
                    sentiment: contentItem.sentiment,
                    lang: contentItem.lang,
                    detected_langs: contentItem.detectedLangs,
                    lang_verbose: contentItem.langVerbose,
                    pubdatetime: contentItem.pubdatetime.toString(),
                    threat_level: contentItem.threatObject?.threatLevel,
                    threat_labels: contentItem.threatObject?.threatLabels,
                    entities: contentItem?.entities,
                    hashtags: contentItem?.hashtags,
                    mentions: contentItem?.mentions,
                    keywords: contentItem?.keywords
                }))
            }
        });
    };

    return { addToWatchlist, loading };
};
