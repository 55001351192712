import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Login } from './pages/Login';
import { SSORedirect } from './pages/Login/SSORedirect';
import { useUser } from './context/user/UserContext';
import { routes } from './routes';
import { Loading } from './components/Loading';
import { DeletedUser } from './pages/Profile/userDeleted';
import { PasswordReset } from './pages/Login/PasswordReset';
import { SignUp } from './pages/SignUp';

export const LoggedOutApp = () => {
    const { user } = useUser();

    if (user) return <Redirect to="/situation-rooms" />;

    return (
        <Switch>
            <Suspense fallback={<Loading relative height={500} />}>
                <Route path={routes.loggedOut.login}>
                    <Login />
                </Route>
                <Route path={routes.loggedOut.ssoAuth}>
                    <SSORedirect />
                </Route>
                <Route path={routes.loggedOut.userDeleted} exact>
                    <DeletedUser />
                </Route>
                <Route path={routes.loggedOut.resetPassword}>
                    <PasswordReset />
                </Route>
                <Route path={routes.loggedIn.signUpInvite}>
                    <SignUp />
                </Route>
            </Suspense>
        </Switch>
    );
};
