import React, { useState } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { checkAndGetUnix } from '../../../../../utils/getTimeFrom';
import { useContent } from '../../../../../services/Content/getContent';
import { getRoom } from '../../../../../utils/variables';
import { ContentTables, ContentTableTypes } from '../../../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { Loading } from '../../../../../components/Loading';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { ContentId } from '../../types';
import { SearchInput } from '../../../../../components/Molecules';

type ChartsPointsContentProps = {
    selectedDate: number
    contentIds: ContentId[]
}

export const ChartsPointsContent = ({ selectedDate, contentIds }: ChartsPointsContentProps) => {
    const { closeModal } = useModal();
    const [val, setVal] = useState('');
    const room = getRoom();
    const limit = 10;
    const [offset, setOffset] = useState(0);
    const unixSelectedDate = checkAndGetUnix(selectedDate);
    const { loading, data: contents, count, error } = useContent({
        filters: {
            ids: contentIds.map(content => ([content.id, ...content.duplicateIds])).flat(),
            ...(val ? { search: val } : {}),
            ...(selectedDate ? {
                date: {
                    startDate: unixSelectedDate,
                    endDate: unixSelectedDate + 86399
                }
            } : {})
        },
        limit,
        skip: offset,
        fetchPolicy: 'no-cache',
        skipRequest: !room.project_id,
        name: 'getContentForChartModal'
    });

    const total = error ? 0 : count;

    if (loading) {
        return <Loading gif relative height={500} />;
    }

    return (
        <>
            <h3>Content - {moment(selectedDate).format('DD/MM/YYYY')}</h3>
            {!total ? (
                <div className="border mt-4">
                    <EmptyState title="No data found" icon={Images().metrics} />
                </div>
            )
                : (
                    <>
                        <hr />
                        <SearchInput placeholder="Search by keywords"
                            handleSearch={setVal}
                            className="flex-1 mr-2"
                            defaultValue={val}
                        />
                        <p className="my-3">{count} content found</p>
                        <ContentTables contents={contents}
                            pagination={{ offset, setOffset, count }}
                            tableType={ContentTableTypes.MATCHES}
                            total={total}
                            contentType={CONTENT_TYPE.MATCH}
                            contentsToDisplay={limit}
                        />
                        <hr className="m-0" />
                        <Button color="secondary" className="mt-2" onClick={closeModal}>Close</Button>
                    </>
                )}
        </>
    );
};
