import { SelectedClusters } from '../ListView/ClustersTable';
import { SelectedSubClusters } from '../ListView/SubClustersTable';

export const getShowAiNarrativeCompare = ({
    selectedInComparison,
    selectedItems
}: {
    selectedInComparison: { id: string, title: string}[],
    selectedItems: SelectedClusters[] | SelectedSubClusters[]
}) => {
    if (selectedInComparison.length >= 4) {
        return false;
    }
    if (selectedInComparison.length === 0) {
        return selectedItems.length > 1 && selectedItems.length < 5;
    }

    const newItemsToCompare = selectedItems.filter(selectedItem => {
        for (const selectedCompareItem of selectedInComparison) {
            if (selectedItem.id === selectedCompareItem.id) return false;
        }
        return true;
    }).length;

    return newItemsToCompare > 0 && (newItemsToCompare + selectedInComparison.length < 5);
};
