import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Deleted } from '../../../../assets/svg/deleted';
import { useModal } from '../../../../context/modal/ModalComponent';

export const DeleteSuccessModal = () => {
    const { closeModal } = useModal();
    return (
        <Modal isOpen toggle={closeModal}>
            <ModalBody>
                <Deleted />
                <h2 className="pt-2">This content is successfully deleted.</h2>
                <hr />
                <Button onClick={closeModal}>Close</Button>
            </ModalBody>
        </Modal>
    );
};
