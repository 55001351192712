import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Loading } from '../../../components/Loading';
import { CardWithLink } from '../../../components/Molecules';
import { useUser } from '../../../context/user/UserContext';

export const AdminOverview = () => {
    const { user } = useUser();
    const { data, loading } = useQuery(GET_ADMIN_OVERVIEW);
    const featureCounts = data ? data.getAdminOverview : false;
    const adminFeatures = [
        {
            title: 'Instances',
            subtitle: featureCounts ? <p className="mb-2"><strong>{featureCounts.instances}</strong> Instances</p> : '',
            link: '/admin/instances'
        },
        {
            title: 'Users',
            subtitle: featureCounts ? <p className="mb-2"><strong>{featureCounts.users}</strong> Users</p> : '',
            link: '/admin/allusers'
        },
        {
            title: 'Super admin users',
            subtitle: featureCounts ? <p className="mb-2"><strong>{featureCounts.adminUsers}</strong> Admin users</p> : '',
            link: '/admin/users'
        },
        {
            title: 'Situation rooms',
            subtitle: featureCounts ? <p className="mb-2"><strong>{featureCounts.situationRooms}</strong> Situation rooms</p> : '',
            link: '/admin/situation-rooms'
        },
        {
            title: 'Investigations',
            subtitle: featureCounts ? <p className="mb-2"><strong>{featureCounts.investigations}</strong> Investigations</p> : '',
            link: '/admin/investigations'
        },
        {
            title: 'Source mapping',
            subtitle: featureCounts ? <p className="mb-2"><strong>{featureCounts.sources}</strong> Sources</p> : '',
            link: '/admin/ctypes'
        }
    ];
    return (
        <div className="p-4" data-testid="admin-dashboard">
            <div className="d-flex justify-content-between mb-3">
                <h1>Hello {user.displayName}</h1>
                <Link to="/situation-rooms">
                    <Button>Go to Logically Intelligence</Button>
                </Link>
            </div>
            <h2 className="mb-4">Admin features</h2>
            {loading && <Loading pointer={false} />}
            <Row>
                {adminFeatures.map((feature) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={feature.link} className="mb-3">
                        <CardWithLink title={feature.title}
                            subtitle={feature.subtitle}
                            link={feature.link}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export const GET_ADMIN_OVERVIEW = gql`
    query getAdminOverview {
        getAdminOverview {
            instances
            users
            adminUsers
            investigations
            situationRooms
            sources
        }
    }
`;
