import { Chart } from 'regraph';
import { NetworksToolbarRef } from '../Toolbar';

type UseChartZoomProps = {
    chartRef: React.RefObject<Chart<any, string | number>>
    toolbarRef: React.RefObject<NetworksToolbarRef>
}

type UseChartZoomResponse = {
    chartZoom: (direction: 'in' | 'out') => void
    onViewChange: Chart.onViewChangeHandler
}

export const useChartZoom = ({ chartRef, toolbarRef }: UseChartZoomProps): UseChartZoomResponse => ({
    chartZoom: (direction) => {
        if (!chartRef.current) return null;
        chartRef.current.zoom(direction);
    },
    onViewChange: (viewOptions) => {
        const { zoom } = viewOptions as Chart.ViewOptions;
        if (!toolbarRef.current) return null;
        toolbarRef.current.setZoom(zoom);
    }
});
