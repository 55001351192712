import React, { useState } from 'react';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import { AlertTriangle } from 'react-feather';
import { client } from '../../auth0';
import { Input } from '../../components/Form/Input';
import { PasswordInput } from '../../components/Form/PasswordInput';

type ErrorType ={
    code: string
}

type LoginFormProps = {
    setForgotPasswordFlow: (forgotPass: boolean) => void
    setIsSSOLogin: (isSSO: boolean) => void
}

export const LoginForm = ({ setForgotPasswordFlow, setIsSSOLogin }: LoginFormProps) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const checkEmailFormat = (e: React.FormEvent<HTMLFormElement>, emailCheckOnly?: string) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        e.preventDefault();
        if (!email) {
            setEmailError('Missing email, please enter your email.');
        }
        else if (email.indexOf('@') === -1) {
            setEmailError('This is an invalid email, emails should contain an "@" and a domain.');
        }
        else if (email.indexOf('.') === -1) {
            setEmailError('Email must contain a "." after the "@" symbol.');
        }
        else if (!pattern.test(email)) {
            setEmailError('Invalid email address format.');
        }
        else if (!emailCheckOnly) checkPassword();
    };
    const checkPassword = (emailCheckOnly?: string) => {
        if (!password) {
            setPasswordError('Missing password, please enter your password.');
        }
        else if (!emailCheckOnly) authenticate();
    };
    const authenticate = async () => {
        client.login({
            email,
            password,
            responseType: 'id_token',
            scope: 'openid profile email user_metadata',
            realm: 'Username-Password-Authentication',
            redirectUri: window.location.origin,
        }, (err: ErrorType) => {
            if (err) {
                setError(err.code === 'access_denied' ? `That email and password combination is incorrect, 
                    please try re-enter your email and password.` : 'Something went wrong, please try again.');
            } else {
                window.location.replace(window.location.origin);
            }
        });
    };

    const renderSingleSignOnButton = (className: string) => (
        <Button color="link"
            className={className}
            onClick={() => setIsSSOLogin(true)}
        >
            Use Single Sign-on
        </Button>
    );

    return (
        <Form onSubmit={checkEmailFormat} autoComplete="false">
            <Label className="mb-1">Email</Label>
            <Input value={email}
                onChange={(e) => { setEmail(e.target.value); setError(''); setEmailError(''); }}
                onBlur={(e) => checkEmailFormat(e, 'emailCheckOnly')}
                type="text"
                error={emailError}
                state={(error || emailError) ? 'error' : undefined}
                data-testId="emailInput"
            />
            <FormGroup className="my-2">
                <Label className="mb-1">Password</Label>
                <PasswordInput password={password}
                    setPassword={setPassword}
                    setError={() => {
                        setError('');
                        setPasswordError('');
                    }}
                    state={(error || passwordError) ? true : undefined}
                    error={passwordError}
                    checkPassword={() => checkPassword('checkPasswordOnly')}
                />
            </FormGroup>
            <Alert color="danger" isOpen={!!error} className="mt-3 text-danger d-flex align-items-center p-2">
                <AlertTriangle className="mr-2" size={error === 'Please enter a valid email address' ? 22 : 40} />
                <p className="text-black m-0">{typeof error === 'string' ? error : 'Something went wrong, please try again.'}</p>
            </Alert>
            <div className="d-flex align-items-center justify-content-between">
                {renderSingleSignOnButton('pl-0 d-none d-xl-block')}
                <div className="d-block d-xl-none" />
                <div className="d-flex justify-self-end">
                    <Button color="link" onClick={() => setForgotPasswordFlow(true)} className="d-block p-0 mr-2">
                        Forgot your password?
                    </Button>
                    <Button type="submit" color="primary" disabled={Boolean(error)}>Continue</Button>
                </div>
            </div>
            {/* responsive single sign-on button */}
            {renderSingleSignOnButton('position-absolute bottom-middle d-xl-none mb-2')}
        </Form>
    );
};
