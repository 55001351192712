import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useRemoveRestoreNarratives } from '../../../../../services/Clusters/useRemoveRestoreNarratives';

type RemoveConfirmationProps = {
    id: string
    isParentCluster: boolean
    onRemoved?: () => void
}

export const RemoveConfirmation = ({ id, isParentCluster, onRemoved }: RemoveConfirmationProps) => {
    const { closeModal } = useModal();
    const { handleRemoveRestoreClusters, restoreRemoveLoading } = useRemoveRestoreNarratives();
    const deleteClusterOrSubCluster = () => {
        handleRemoveRestoreClusters(
            [id],
            'remove',
            isParentCluster ? 'theme' : 'narratives'
        );
        onRemoved?.();
    };
    return (
        <>
            <h3>Are you sure that you want to remove this {isParentCluster ? 'narrative theme'
                : 'narrative'}?
            </h3>
            <hr />
            <p>The selected {isParentCluster ? 'narrative theme ' : 'narrative '}
                will be fully removed from this situation room.
            </p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={restoreRemoveLoading} color="danger" onClick={deleteClusterOrSubCluster}>Remove</Button>
        </>
    );
};
