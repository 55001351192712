import React from 'react';
import { Maximize2 } from 'react-feather';
import { Button } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { useURLSnapshot } from '../../services/Content/getUrlSnapshot';
import { truncateString } from '../../utils/text';
import { Loading } from '../Loading';
import { NoDataAvailableState } from '../EmptyState';

type UrlSnapshotElem = {
    url: string;
    text: string
}

type UrlSnapshotProps = {
    data: UrlSnapshotElem
}

export const UrlSnapshot = ({ data: { url, text } }: UrlSnapshotProps) => {
    const { data, loading } = useURLSnapshot({ url });
    const { setModal, closeModal } = useModal();

    if (loading) {
        return <Loading relative height={200} />;
    }
    if (!data || data === 'unknown') {
        return <NoDataAvailableState />;
    }

    return (
        <div className="position-relative">
            <img src={data} alt="" className="w-100" data-testid="url-snapshot-image" />
            <div className="absolute-bottom-right p-11 d-flex">
                <Button className="square-icon-button p-0"
                    color="primary"
                    onClick={() => setModal({
                        size: 'xl',
                        component: (
                            <div>
                                <h3 className="m-0">{truncateString(text, 100)}</h3>
                                <hr />
                                <img src={data} alt="" className="w-100" data-testid="modal-url-snapshot-image" />
                                <hr />
                                <Button onClick={closeModal}>Close</Button>
                            </div>
                        )
                    })}
                >
                    <Maximize2 size={18} />
                </Button>
            </div>
        </div>
    );
};
