import React from 'react';
import { TikTok as ReactTikTok } from 'react-tiktok';

type ContentProps = {
    id: string;
    url: string;
    from: string;
}

type TikTokProps = {
    data: ContentProps
}

export const TikTok = ({ data }: TikTokProps) => {
    const url = data.url.includes('https://www.tiktok.com/')
        ? data.url
        : `https://www.tiktok.com/${data.from[0] === '@' ? '' : '@'}${data.from}/video/${data.id.split('-')[0]}`;
    return <ReactTikTok url={url} />;
};
