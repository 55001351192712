import { useQuery } from '@apollo/client';
import { useUser } from '../../../../context/user/UserContext';
import { GET_SITUATION_ROOMS_NAMES } from '../../../../graphql';
import { useRoomEditor } from '../RoomEditorContext';

export const useRoomNameAvailability = ({ roomName }) => {
    const { instance } = useUser();
    const { edit, oldRoom } = useRoomEditor();
    const { data } = useQuery(GET_SITUATION_ROOMS_NAMES, {
        fetchPolicy: 'no-cache',
        variables: { instance, limit: 100 }
    });
    const allRooms = data?.situationRooms?.data || [];
    let availability = true;
    for (let i = 0; i < allRooms.length; i++) {
        if (allRooms[i].project_name.toLowerCase().replaceAll('  ', ' ')
            === roomName?.trim().toLowerCase().replaceAll('  ', ' ') && allRooms[i].deleted === false) {
            if (oldRoom?.id) {
                if (allRooms[i].id !== oldRoom?.id) {
                    availability = false;
                    break;
                }
            }
            else {
                availability = false;
                break;
            }
        }
    }
    const nameNotAvailable = (roomName?.trim() && !availability && (!edit || roomName?.trim() !== oldRoom?.project_name));
    return {
        availability,
        nameNotAvailable
    };
};
