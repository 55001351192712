import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { RemoveSource } from './RemoveSource';

export const SourceList = ({ ctypeData }) => {
    const { isAdmin } = useAdminUser();
    const [sort, setSort] = useState({ field: 'name', sort: 'asc' });

    return (
        <div className="bg-white overflow-x-auto">
            <Table className="mb-0" hover>
                <thead>
                    <tr>
                        <th><SortToggle sort={sort} setSort={setSort} field="name" name="Source name" /></th>
                        <th>Key</th>
                        {isAdmin && <th />}
                        {isAdmin && <th />}
                    </tr>
                </thead>
                <tbody>
                    {lodashSort([...ctypeData], sort).map((ctype) => (
                        <tr key={ctype.id}>
                            <td>
                                <span>
                                    <i className={`font-size-14 fa ${ctype.icon} mr-2`}
                                        style={
                                            ctype.FontFamily
                                                ? { fontFamily: `"${ctype.FontFamily}"`, color: `rgb(${ctype.color})` }
                                                : { color: `rgb(${ctype.color})` }
                                        }
                                    />
                                    {ctype?.name}
                                </span>
                            </td>
                            <td>{ ctype?.key }</td>
                            {isAdmin && (
                                <td className="text-primary">
                                    <Link to={{ pathname: `/admin/ctypes/${ctype.id}`, state: { ctype } }}>Edit source</Link>
                                </td>
                            )}
                            {isAdmin && <td><RemoveSource ctypeId={ctype.id} /></td>}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
