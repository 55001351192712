import { gql, useMutation } from '@apollo/client';

export const useUpdateBooleanVersion = () => {
    const [updateBooleanVersion, { loading }] = useMutation(UPDATE_BOOLEAN_VERSION);
    return {
        loading,
        updateBooleanVersion
    };
};

const UPDATE_BOOLEAN_VERSION = gql`
    mutation updateBooleanVersion(
        $projectId: ID!,
        $booleanTitle: String,
        $notes: String
    ) {
        updateBooleanVersion(
            data: {
                projectId: $projectId,
                booleanTitle: $booleanTitle
                notes: $notes
            }
        ) { projectId }
    }
`;
