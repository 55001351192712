import React from 'react';
import { Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../../../components/Form/Input';
import { useCheckNameExists } from '../../../../../services/Report/checkReportNameExists';
import { setReportAction } from '../../../../../redux/reports';
import { SaveAsDraftButton } from './SaveAsDraftButton';
import { SaveCreateButton } from './SaveCreateButton';
import { DatePicker } from './DatePicker';

export const ReportName = () => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const { checkNameExists, loading, exists } = useCheckNameExists();

    const handleChange = async (e) => {
        const name = e.target.value;
        dispatch(setReportAction({ ...report, name: name || '' }));

        if (name !== ' ') {
            await checkNameExists(name);
        }
    };

    return (
        <>
            <div className="d-flex align-items-end">
                <div className="flex-1">
                    <Label className="mb-1">Enter report name</Label>
                    <Input value={report.name}
                        onChange={handleChange}
                        state={(!loading && exists) ? 'error' : 'normal'}
                    />
                </div>
                <DatePicker />
                <SaveAsDraftButton />
                <SaveCreateButton />
            </div>
            {(!loading && exists) && <p className="text-danger">Name has already been used</p>}
        </>
    );
};
