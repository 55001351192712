import React, { useState } from 'react';
import { Label, FormGroup, Button, Form } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PASSWORD_RESET } from '../../../graphql/Queries';
import { RESET_PASSWORD } from '../../../graphql/Mutations';
import { AuthLayout } from '../AuthLayout';
import { PasswordInput } from '../../../components/Form/PasswordInput';
import { PasswordResetExpired } from './passwordResetExpired';
import { passwordMessage, validatePassword } from '../../../utils/validatePassword';

export const PasswordReset = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const { search } = useLocation();
    const codeFromUrl = new URLSearchParams(search).get('code');
    const { data } = useQuery(GET_PASSWORD_RESET, {
        variables: {
            code: codeFromUrl
        }
    });
    const [updatePassword, { loading: loadingB }] = useMutation(RESET_PASSWORD, {
        onCompleted: (res) => {
            const { done, message } = res.resetPassword;
            if (done) {
                window.location.replace(`${window.location.origin}/login`);
            } else {
                setError(message);
            }
        }
    });
    const email = data ? data.getPasswordReset?.email : '';

    const resetPassword = (e) => {
        e.preventDefault();
        if (!loadingB) {
            const { isValid, message } = validatePassword(password);
            if (!isValid) {
                setError(message);
            } else if (confirmPassword !== password) {
                setError('Password and Confirm password do not match.');
            } else {
                updatePassword({
                    variables: {
                        password,
                        code: codeFromUrl,
                        email
                    }
                });
            }
        }
    };

    const expired = new Date() - new Date(data?.getPasswordReset?.expiryDate) > 0;

    if (!email) {
        return null;
    }

    return (
        <AuthLayout>
            {
                expired ? <PasswordResetExpired email={email} />

                    : (
                        <div className="login-form" data-testid="password-reset-component">
                            <h1>Reset your password</h1>
                            <p>
                                Reset your password by providing new credentials.
                            </p>
                            <hr />
                            <p>
                                * {passwordMessage}
                            </p>
                            <hr />
                            <Form onSubmit={resetPassword}>
                                <FormGroup className="mb-2">
                                    <Label className="mb-1">New Password</Label>
                                    <PasswordInput password={password}
                                        setPassword={setPassword}
                                        required
                                        testId="password-input"
                                    />
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <Label className="mb-1">Confirm Password</Label>
                                    <PasswordInput password={confirmPassword}
                                        setPassword={setConfirmPassword}
                                        required
                                        testId="confirm-password-input"
                                    />
                                </FormGroup>
                                {error && <p className="text-danger">{error}</p>}
                                <Button color="primary"
                                    type="submit"
                                    disabled={loadingB}
                                    data-testid="reset-password-button"
                                >Submit
                                </Button>
                            </Form>
                        </div>
                    )
            }
        </AuthLayout>
    );
};
