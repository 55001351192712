import React from 'react';
import { Button, Input } from 'reactstrap';
import { CreateAdminUser } from './CreateAdminUser';
import { useModal } from '../../../../context/modal/ModalComponent';

export const PageFilters = ({ handleSearchValue, adminUsers }) => {
    const { setModal, closeModal } = useModal();
    const setCreateAdminUserModal = () => {
        setModal({
            component: <CreateAdminUser adminUsers={adminUsers}
                closeModal={closeModal}
            />,
        });
    };
    return (
        <div className="mb-4 d-flex align-items-center">
            <div className=" mr-2 flex-fill">
                <Input onChange={(e) => handleSearchValue(e.target.value)}
                    placeholder="Search members"
                    data-testid="search-super-admin"
                />
            </div>
            <Button onClick={() => setCreateAdminUserModal()}>Add new user</Button>
        </div>
    );
};
