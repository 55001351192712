import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { Loading } from '../../../components/Loading';
import { GET_ADMIN_USERS } from '../../../graphql';
import { AdminUsersTable } from './AdminUserTable';
import { PageFilters } from './PageFilters';
import { BackButton } from '../../../components/Atoms';

export const SuperAdminUsers = () => {
    const { loading, data } = useQuery(GET_ADMIN_USERS, {
        fetchPolicy: 'cache-and-network'
    });
    const bread = [
        { title: 'Admin home', href: '/admin' }, { title: 'Super admin users' }
    ];
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (val) => {
        setSearchValue(val);
    };
    const filterData = () => data?.adminUsers?.filter(user => {
        let ret = true;
        if (searchValue && !(user.displayName?.toLowerCase()?.includes(searchValue.toLowerCase())
        || user.email.toLowerCase().includes(searchValue.toLowerCase()))) {
            ret = false;
        }
        return ret;
    });

    return (
        <div className="page-padding">
            <BreadCrumbs items={bread} />
            <BackButton pathName="/admin" />
            <h2 className="mb-4 mt-0">Super admin users ({data?.adminUsers.length})</h2>
            <PageFilters handleSearchValue={val => handleSearch(val)} adminUsers={data?.adminUsers} />
            {loading ? <Loading /> : <AdminUsersTable adminUsers={filterData() || []} />}
        </div>
    );
};
