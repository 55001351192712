const getAvg = (arr) => arr.reduce((acc, c) => acc + c, 0) / arr.length;

const getSum = (arr) => arr.reduce((acc, c) => acc + c, 0);

export const trendline = (data) => {
    const xData = data.map((value) => value.x);
    const yData = data.map((value) => value.y);

    const xMean = getAvg(xData);
    const yMean = getAvg(yData);

    const xMinusxMean = xData.map((val) => val - xMean);
    const yMinusyMean = yData.map((val) => val - yMean);

    const xMinusxMeanSq = xMinusxMean.map((val) => val ** 2);

    const xy = [];
    for (let x = 0; x < data.length; x++) {
        xy.push(xMinusxMean[x] * yMinusyMean[x]);
    }

    const xySum = getSum(xy);

    const b1 = xySum / getSum(xMinusxMeanSq);

    return {
        slope: b1
    };
};
