import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Table } from 'reactstrap';
import { Trash2 } from 'react-feather';
import { Loading } from '../../../../components/Loading';
import { GET_ROOM_TEAM } from '../../../../graphql';
import { User } from '../../../../assets/svg/user';
import { useModal } from '../../../../context/modal/ModalComponent';
import { RemoveUserModal } from './RemoveUserModal';
import { AddUserModal } from './AddUserModal';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom } from '../../../../utils/variables';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { Pagination } from '../../../../components/Molecules';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';

export const Team = () => {
    const room = getRoom();
    const { user: currentUser, isAdmin } = useUser();
    const [sort, setSort] = useState('');
    const { setModal, closeModal } = useModal();
    const [page, setPage] = useState(0);
    const { data, loading, refetch } = useQuery(GET_ROOM_TEAM, {
        variables: { instance: room.instance.id, situationRoom: room.id },
        fetchPolicy: 'no-cache'
    });
    const users = useMemo(() => (data ? data.users : []), [data]);
    const roomRemoved = data ? data.situationRoom.removedUsers || [] : [];
    const usersWithAccess = users.filter(user => !roomRemoved.includes(user.id));
    const removedUsers = users.filter(user => roomRemoved.includes(user.id));
    const tableData = usersWithAccess?.slice(page, page + 10) || [];

    return (
        <div className="bg-white">
            <div className="d-flex flex-column justify-content-between border p-3">
                <h3 className="mr-3 my-0 pb-1">Team ({usersWithAccess.length || 0})</h3>
                {(removedUsers.length > 0 && isAdmin) && (
                    <Button onClick={() => setModal({
                        header: false,
                        size: 'lg',
                        component: <AddUserModal users={removedUsers} room={{ id: room.id, removedUsers: roomRemoved }} refetch={refetch} />
                    })}
                    >Add member
                    </Button>
                )}
                <div>
                    <p className="d-inline">View all users of this situation room.
                        Only admin level users can remove or change user access.
                    </p>
                    <a href="https://logically-intelligence.readme.io/"
                        target="_blank"
                        rel="noreferrer"
                    > Read more.
                    </a>
                </div>
            </div>
            {loading ? <Loading height={200} relative /> : (
                <Table className="border mb-0">
                    <thead>
                        <tr>
                            <th>
                                <SortToggle sort={sort}
                                    setSort={setSort}
                                    name="Name"
                                    field="displayName"
                                />
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                <SortToggle sort={sort}
                                    setSort={setSort}
                                    name="Role"
                                    field="role"
                                />
                            </th>
                            {isAdmin && (
                                <th>
                                    Action
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {lodashSort(tableData, sort).map(user => (
                            <tr key={user.id}>
                                <td>
                                    {user.photoURL ? (
                                        <img src={user.photoURL}
                                            alt="logically user"
                                            className="rounded-circle mr-1"
                                            style={{ width: 32, height: 32 }}
                                        />

                                    )
                                        : (
                                            <span className="mr-1"> <User size="32" /></span>
                                        )}
                                    {user.displayName || `${user.firstName || ''} ${user.lastName || 'Not available'}`}
                                </td>
                                <td data-testid="team-user-table-email">
                                    {user.email}
                                </td>
                                <td className="text-capitalize">{user.role.toLowerCase()}</td>
                                {isAdmin && (
                                    <td>
                                        {user.id !== currentUser.id && (
                                            <p className="text-danger"
                                                role="button"
                                                data-testid="remove-user"
                                                onClick={() => setModal({
                                                    header: false,
                                                    component: <RemoveUserModal room={{ ...room, removedusers: roomRemoved }}
                                                        closeModal={closeModal}
                                                        refetch={refetch}
                                                        user={user}
                                                    />
                                                })}
                                            >
                                                <TooltipWrapper id={`tooltip-settings-delete-${user.id}`}
                                                    placement="top"
                                                    tooltipText="Remove user"
                                                >
                                                    <Trash2 size="20" />
                                                </TooltipWrapper>

                                            </p>
                                        )}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            {usersWithAccess.length > 10 && (
                <div className="border bg-white">
                    <Pagination total={usersWithAccess.length || 0}
                        itemsPerPage={10}
                        offset={page}
                        setOffset={setPage}
                        className="p-1 pt-2"
                    />
                </div>
            )}
        </div>
    );
};
