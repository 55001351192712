import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_ROOM_OVERVIEW } from '../../graphql';

export const useSituationRoomByID = ({ id }) => {
    const { loading, data } = useQuery(GET_ROOM_OVERVIEW, {
        variables: {
            id
        },
        fetchPolicy: 'no-cache',
        skip: !id
    });
    return {
        loading,
        data: data?.situationRoom ? {
            ...data.situationRoom,
            start_date: moment(new Date(data.situationRoom.start_date))
        } : false
    };
};
