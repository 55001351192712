import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { WatchListThreats } from './WatchlistThreats';
import { NarrativeThemesWatchlist } from './NarrativeThemesWatchlist';
import { getRoom } from '../../../utils/variables';
import { NarrativeThemeComparison } from '../AIClustering/Utils/ComparisonContainer/NarrativeThemeComparison';
import { NarrativeComparison } from '../AIClustering/Utils/ComparisonContainer/NarrativeComparison';
import { RootState } from '../../../store';
import { NarrativesWatchlist } from './NarrativesWatchlist';

export const WatchList = () => {
    const room = getRoom();
    const [selectedTab, setSelectedTab] = useState(0);
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);

    const breadCrumbs = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: room?.project_name, href: `/situation-rooms/${room?.id}` },
        { title: 'Watchlist' }
    ];

    return (
        <>
            {[1, 2].includes(selectedTab) ? (
                <div className={`position-sticky top-0 bg-white z-index-10 ${
                    (selectedNarrativeThemes.length || selectedNarratives.length) ? 'mb-n4' : ''}`}
                >
                    {
                        selectedNarrativeThemes.length > 0 ? <NarrativeThemeComparison /> : null
                    }
                    {
                        selectedNarratives.length > 0 ? <NarrativeComparison /> : null
                    }
                </div>
            ) : null}
            <div className="page-padding">
                <BreadCrumbs items={breadCrumbs} />
                <h2 className="font-weight-bold my-4">Watchlist</h2>
                <Row className="align-items-center">
                    <Col xs={12}>
                        <div className="multi-tabs mb-4">
                            <div className={`mr-4 cursor-pointer ${selectedTab === 0 ? 'selected-tab ' : ''}`}
                                onClick={() => setSelectedTab(0)}
                            >
                                <h4>Threats</h4>
                            </div>
                            <div className={`mr-4 cursor-pointer ${selectedTab === 1 ? 'selected-tab ' : ''}`}
                                onClick={() => setSelectedTab(1)}
                            >
                                <h4>Narrative themes</h4>
                            </div>
                            <div className={`mr-4 cursor-pointer ${selectedTab === 2 ? 'selected-tab ' : ''}`}
                                onClick={() => setSelectedTab(2)}
                            >
                                <h4>Narratives</h4>
                            </div>
                        </div>
                        {selectedTab === 0 && <WatchListThreats />}
                        {selectedTab === 1 && <NarrativeThemesWatchlist />}
                        {selectedTab === 2 && <NarrativesWatchlist />}
                    </Col>
                </Row>
            </div>
        </>
    );
};
