import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateReport } from '../../../../../services/Report/updateReport';
import { useCreateReport } from '../../../../../services/Report/createReport';
import { CREATE_REPORT_TABS, setActiveTabAction } from '../../../../../redux/reports';
import { useSaveReport } from '../../../../../services/Report/saveReport';

export const SaveCreateButton = () => {
    const dispatch = useDispatch();
    const { report, activeTab } = useSelector(state => state.createReport);
    const { loading: createReportLoading } = useCreateReport();
    const { loading: updateLoading } = useUpdateReport();
    const { saveReport, loading: saveLoading } = useSaveReport();
    const handleSaveReport = async () => {
        if (saveLoading) return;
        if (activeTab === CREATE_REPORT_TABS.VISUALISE) {
            dispatch(setActiveTabAction(CREATE_REPORT_TABS.EXPORT));
        }
        await saveReport({ ...report, draft: false });
    };

    return (
        <Button disabled={!report?.name || !report?.template || createReportLoading || updateLoading}
            className="ml-3"
            color="primary"
            onClick={handleSaveReport}
        >
            {(createReportLoading || updateLoading) ? <Spinner color="primary" size="sm" /> : 'Save & export'}
        </Button>
    );
};
