import { gql, useMutation } from '@apollo/client';

export const useDeleteNetwork = () => {
    const [deleteNetwork, { loading }] = useMutation(DELETE_NETWORK, {
        refetchQueries: ['getNetworks']
    });

    return {
        deleteNetwork: ({ id, onCompleted }: { id: string, onCompleted: () => void }) => {
            deleteNetwork({
                variables: {
                    id
                },
                onCompleted
            });
        },
        loading
    };
};

export const DELETE_NETWORK = gql`
    mutation deleteNetwork($id: ID!) {
        deleteNetwork(id: $id)
    }
`;
