import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BackButton } from '../../../../components/Atoms';
import { Loading } from '../../../../components/Loading';
import { GET_ALL_SITUATION_ROOMS } from '../../../../graphql';
import { RoomContextProvider } from './RoomContext';
import { RoomInner } from './RoomInner';

export const Room = () => {
    const params = useParams();
    const { data, loading } = useQuery(GET_ALL_SITUATION_ROOMS, {
        variables: { filter: { id: params.id } }
    });
    const rooms = data?.allSituationRooms?.data || [];
    const room = rooms[0];
    if (loading) {
        return <Loading />;
    }
    if (!room) {
        return (
            <div>
                <BackButton pathName="/admin/situation-rooms" />
                <p>Could not find room with ID: {params.id}</p>
            </div>
        );
    }
    return <RoomContextProvider defaultRoom={room}><RoomInner /></RoomContextProvider>;
};
