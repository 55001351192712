import React, { useMemo } from 'react';
import { ChevronLeft, ChevronRight, X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { resetSelectedCluster, saveSelectedCluster } from '../../store';
import { TabsContainer } from './TabsContainer';
import { ClusterOrSubCluster, ExtractCluster } from '../types';
import { color } from '../../../../utils/getColors';
import { addHighlights, useQueryKeywords } from '../../../../services/Content/getQueryExtracts';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';
import { ActionButtons } from './ActionButtons';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { LoadingState as TotalMatchesLoading } from '../../Overview/Visualisation/TotalMatchesAndThreatsOverTime/Loading';
import { findElementByStringInNestedArray } from '../../../../utils/lib/findElementByStringInNestedArray';
import { IconButton } from '../../../../components/Atoms';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { TranslateButton } from '../../../../components/Atoms/TranslateButton';

export const SelectedClusterView = <T extends boolean>({ cluster, isParent, allClusterIds, loading }: ClusterOrSubCluster<T>) => {
    const dispatch = useDispatch();
    const checkParent = useMemo(() => (isParent ? {
        clusterTitle: cluster?.title,
        summary: cluster?.description,
        narrative: getParam('narrative-theme'),
        allClusters: allClusterIds?.narrativeTheme || []
    } : {
        clusterTitle: cluster?.subClusterTitle,
        summary: cluster?.subClusterSummary,
        narrative: getParam('narrative'),
        allClusters: findElementByStringInNestedArray(allClusterIds?.narrative || [], getParam('narrative') || '') || []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [allClusterIds, cluster, isParent]);

    const keywordsToHighlight = useQueryKeywords('clusterFilters');
    const { highlightedText: title } = addHighlights({
        text: checkParent.clusterTitle,
        keywordsToHighlight
    });
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        extracts: [title, checkParent.summary]
    });
    const isNoneEnglish = /[^a-zA-Z0-9\s.,!?'";:()-]/.test(checkParent.clusterTitle);
    const translatedTitle = translated[0].translations[0];
    const translatedSummary = translated[1].translations[0];
    const currentCount = useMemo(
        () => checkParent.allClusters?.findIndex(
            (value: any) => (isParent ? value.id : value) as string === getParam(isParent ? 'narrative-theme' : 'narrative')
        ),
        [checkParent, isParent]
    );
    const leftCondition = checkParent?.allClusters?.length > 0 && (currentCount + 1) !== 1;
    const rightCondition = checkParent?.allClusters?.length > 0 && (currentCount + 1) < checkParent?.allClusters?.length;
    const navigateClusters = (direction: string) => {
        if (direction === 'left') {
            const seletedCluster = isParent ? checkParent?.allClusters[currentCount - 1].id : checkParent?.allClusters[currentCount - 1];
            setParam(
                isParent ? 'narrative-theme' : 'narrative',
                seletedCluster
            );
            dispatch(saveSelectedCluster({
                selectedCluster: seletedCluster,
                isParentCluster: isParent
            }));
        } else if (direction === 'right') {
            const seletedCluster = isParent ? checkParent?.allClusters[currentCount + 1].id : checkParent?.allClusters[currentCount + 1];
            setParam(
                isParent ? 'narrative-theme' : 'narrative',
                seletedCluster
            );
            dispatch(saveSelectedCluster({
                selectedCluster: seletedCluster,
                isParentCluster: isParent
            }));
        }
    };
    const prevNarrative = getParam('prev_narrative');

    const handleGoBack = () => {
        if (prevNarrative) {
            setParam('narrative', prevNarrative);
            unsetParam('narrative-theme');
            unsetParam('prev_narrative');
            dispatch(saveSelectedCluster({
                selectedCluster: prevNarrative,
                isParentCluster: false
            }));
        }
    };
    return (
        <div className="position-relative mh-content-page-n4 pt-4">
            <div className="h-100 pb-4 z-index position-absolute w-75 d-flex flex-column" id="selected-cluster-view">
                <div className="bg-white p-3 align-items-center d-flex text-align-center justify-content-between ">
                    <div className="d-flex align-items-center gap-4">
                        {isParent && prevNarrative && (
                            <IconButton icon={<ChevronLeft color={color.blue[500]} />}
                                onClick={handleGoBack}
                                text="Back"
                            />
                        )}
                        <p className="m-0 font-weight-bold">{isParent ? 'Narrative theme' : 'Narrative'} details</p>
                    </div>
                    <div className="d-flex">
                        {!prevNarrative && (
                            <div className="d-flex mr-2">
                                <ChevronLeft className="cursor-pointer"
                                    color={leftCondition ? color.blue[500] : color.grey[400]}
                                    onClick={() => leftCondition && navigateClusters('left')}
                                />
                                <GetCount checkParent={checkParent} loading={!cluster?.id} currentCount={currentCount} />
                                <ChevronRight className="cursor-pointer"
                                    color={rightCondition ? color.blue[500] : color.grey[400]}
                                    onClick={() => rightCondition && navigateClusters('right')}
                                />
                            </div>
                        )}
                        <X className="cursor-pointer" color={color.blue[500]} onClick={() => dispatch(resetSelectedCluster())} />
                    </div>
                </div>
                <hr className="m-0" />
                <div className="d-flex bg-white px-3 py-3 justify-content-between cursor-pointer">
                    <p className="m-0 font-weight-bold highlight-em maxw-75">{checkParent.clusterTitle
                        ? getHighlightedContent(translatedTitle)
                        : <TotalMatchesLoading items={['a']} />}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        {isNoneEnglish && (
                            <TranslateButton className="mr-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    getTranslation();
                                }}
                                loading={translateLoading}
                            />
                        )}
                        {cluster && !loading ? (
                            <ActionButtons clusterTitle={checkParent.clusterTitle}
                                summary={checkParent.summary}
                                isWatching={!!cluster?.isWatching}
                                cluster={cluster}
                                isSubCluster={!isParent}
                            />
                        ) : null}
                    </div>
                </div>
                <hr className="m-0" />
                <div>
                    <TabsContainer
                        isParent={isParent}
                        loading={loading}
                        allClusterIds={allClusterIds}
                        cluster={{
                            ...cluster,
                            subClusterTitle: translatedTitle,
                            subClusterSummary: translatedSummary
                        } as ExtractCluster<typeof cluster>}
                    />
                    <div className="mt-4" />
                </div>
            </div>
        </div>
    );
};
type GetCountProps = {
    checkParent: any
    loading: boolean | undefined
    currentCount: number
};

const GetCount = ({ checkParent, loading, currentCount }: GetCountProps) => {
    let x = 1;
    if (checkParent.narrative) {
        x = currentCount + 1;
    }
    if (loading || checkParent?.allClusters?.length === 0) {
        return <p>loading </p>;
    }
    return (
        <p>{x} of {checkParent?.allClusters?.length || 1}</p>
    );
};
