import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_INVESTIGATIONS } from '../../../../graphql';
import { Loading } from '../../../../components/Loading';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { InvestigationSummary } from './InvestigationSummary';
import { DetailsHeader } from './DetailsHeader';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { BackButton } from '../../../../components/Atoms';
import { InvestigationContextProvider } from './InvestigationContext';

export const InvestigationDetails = () => {
    const match = useRouteMatch();
    const { user } = useAdminUser();
    const { data, loading } = useQuery(GET_ALL_INVESTIGATIONS, {
        variables: {
            id: match.params.id,
        },
    });

    if (loading) { return <Loading />; }

    const investigation = data.allInvestigations[0];

    if (!investigation) {
        return (
            <div>
                <BackButton pathName="/admin/investigations" />
                <p>Investigation not found.</p>
            </div>
        );
    }

    const bread = [
        { title: 'Admin home', href: '/admin' },
        { title: 'Investigations', href: '/admin/investigations' },
        { title: investigation.summary }
    ];

    return (
        <InvestigationContextProvider investigation={investigation}>
            <div className="page-padding">
                <BreadCrumbs items={bread} />
                <BackButton pathName="/admin/investigations" />
                <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                    <h2 className="m-0">Investigation details</h2>
                    <DetailsHeader detailsId={match.params.id} />
                </div>
                <InvestigationSummary detailsData={data.allInvestigations[0]} user={user} />
            </div>
        </InvestigationContextProvider>
    );
};
