import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { Deleted } from '../../../../../assets/svg/deleted';

export const DeleteEntity = ({ item, handleDelete }) => {
    const { closeModal } = useModal();
    return (
        <div>
            <Deleted />
            <h2 className="dont-break-out">Are you sure you want to delete “{item.entity}” entity?</h2>
            <hr />
            <Button className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button color="primary" onClick={handleDelete}>Delete</Button>
        </div>
    );
};
