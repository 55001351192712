export const largeNumber = (value, precision = 1) => {
    if (value <= 1000) {
        return value;
    }

    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor((`${value}`).length / 3);
    let shortValue = value / 1000 ** suffixNum; // Adjust value based on suffix

    shortValue = shortValue.toFixed(precision); // Use the precision argument here

    // Remove unnecessary trailing zeros after the decimal
    shortValue = parseFloat(shortValue);

    return shortValue + suffixes[suffixNum];
};

export const roundPercentage = (percentage) => {
    let ret = Math.round(percentage);
    if (ret < 10) {
        ret = Math.round(percentage * 10) / 10;
        if (ret < 1) {
            ret = Math.round(percentage * 1000) / 1000;
            if (ret === 0.1) {
                ret = Math.round(percentage * 10000) / 10000;
            }
        }
    }
    return ret;
};
