import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import EmptyIcon from '../../../assets/images/emptyStates/investigations.svg';

export const RequestInvestigationsSection = ({ setOpen, title, subtitle, subtitle2, subtitle3, tokens, isAdmin }) => (
    <div className="bg-white p-5">
        <Row className="align-items-center justify-content-around">
            <Col xs={12} className="text-center">
                <img src={EmptyIcon} alt="empty-icon" className="w-100" style={{ height: 80 }} />
            </Col>
            <Col xs={12} className="text-center">
                <h2>{title}</h2>
                {subtitle && <p className={subtitle2 ? 'mt-2 mb-0' : ' mt-2 mb-2'}>{subtitle}</p>}
                {subtitle2 && <p className="mb-0">{subtitle2}</p>}
                {subtitle3 && <p className="mb-2">{subtitle3}</p>}
                <Button color="primary" onClick={setOpen} disabled={!isAdmin && tokens === 0}>
                    Request Investigation
                </Button>
            </Col>
        </Row>
    </div>
);
