import React from 'react';

export const KeywordHelpText = () => (
    <div className="bg-white">
        <p>
            Input words, phrases, strings, URLs, domains, social media accounts, people, or locations into
            the Included or Excluded Keywords text boxes to include or exclude them from the Situation Room.<br /><br />
            After they have been entered, click on one, and you can see synonyms, antonyms, lemmas, leetspeak,
            and related terms for the selected option. These can also be added to the Included and Excluded Keywords text boxes.<br /><br />
            We advise you to select a minimum of 5 terms when creating a situation room. Where terms are more defined at the point
            of creation, rooms present a higher standard of content.<br /><br />
            You can drag and drop any keyword to add them to the included or excluded box.<br /><br />
            Don’t use email addresses or contact numbers as these are unlikely to appear on social media.<br /><br />
            Phrases should not contain more than two or three words, as extremely long phrases have a performance impact on LI.<br /><br />
            Use an &apos;@&apos; symbol before a social media account name to ensure it is matched correctly.<br /><br />
        </p>
    </div>
);
