import { gql, useMutation } from '@apollo/client';

export const useDeleteBooleanVersion = () => {
    const [deleteBooleanVersion, { loading }] = useMutation(DELETE_BOOLEAN_VERSION);
    return {
        loading,
        deleteBooleanVersion
    };
};

const DELETE_BOOLEAN_VERSION = gql`
    mutation deleteBooleanVersion(
        $projectId: ID!
    ) {
        deleteBooleanVersion(
            projectId: $projectId,
        ) { projectId }
    }
`;
