import { convertToMarkers } from '../convertToMarkers';

const OPERATORS = ['AND', 'OR', 'NOT'];

export const missingOperatorQuotesValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.length === 0) {
        return {
            isValid: highlight.length === 0,
            markers: []
        };
    }

    // clean up query and split into elements using whitespace
    const queryElements = query?.trim().replaceAll(/\s*:\s*/g, ':').replaceAll(/\(\s*/g, '(').replaceAll(/\s*\)/g, ')')
        .split(/\s+/g);

    for (let i = 0; i < queryElements.length - 1; i++) {
        const currentElement = queryElements[i];
        const nextElement = queryElements[i + 1];
        // if current or next elements isn't an operator it's invalid
        if (!((!OPERATORS.includes(currentElement) && nextElement === '')
        || OPERATORS.includes(currentElement) || currentElement.includes('NEAR/'))
            && !(OPERATORS.includes(nextElement) || nextElement.includes('NEAR/'))) {
            const incorrectElements = [currentElement, nextElement];
            let lastElementIndex = i + 2;
            let lastElement = queryElements[lastElementIndex];
            // loop through to find whole part of query missing operator or quotes
            while (lastElement && !(OPERATORS.includes(lastElement) || lastElement.includes('NEAR/'))) {
                incorrectElements.push(lastElement);
                lastElementIndex++;
                lastElement = queryElements[lastElementIndex];
                i++;
            }
            const charfileteredElements = incorrectElements.map(element => element.replace(/[*]/g, '\\$&'));
            [...query.matchAll(
                new RegExp(charfileteredElements.join('\\s*')
                    .replaceAll(':', '\\s*:\\s*')
                    .replaceAll('(', '\\(\\s*')
                    .replaceAll(')', '\\s*\\)'), 'g')
            )].forEach((match) => {
                const finalIndexList = [match.index, match.index + match[0].length];
                const isDuplicate = highlight.filter((a) => (a[0] === finalIndexList[0] && a[1] === finalIndexList[1]));

                if (!isDuplicate.length > 0) {
                    highlight.push([match.index, match.index + match[0].length]);
                }
            });
        }
    }

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You are missing an operator or quotations'
        )
    };
};
