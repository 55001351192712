import React from 'react';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '../components/DatePicker';
import { getRoom } from '../../../utils/variables';
import { MultiSelectSourceFilter } from './MultiSelectSourceFilters';
import { MultiSelectLanguageFilters } from './MultiSelectLanguageFilters';
import { KeywordsFilter } from './KeywordsFilter';
import { ActorsFilter } from './ActorsFilter';
import { SentimentFilter } from './SentimentFilter';
import { LabelsFilter } from './LabelsFilter';
import { ThreatTypesFilter } from './ThreatTypesFilter/ThreatTypesFilter';
import { LocationsFilter } from './LocationsFilter';
import { MultiKeywordsFilter } from './MultiKeywordsFilter';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';

type FiltersListProps = {
    watchlist?: boolean,
    contentIds?: string[],
    overview?: boolean,
    clusters?: boolean
}

export const FiltersList = ({ watchlist = false, contentIds = [], overview = false, clusters = false }: FiltersListProps) => {
    const room = getRoom();
    const labelsFeatureEnabled = !!room.instance?.plan?.others?.labels;

    return (
        <div className="border p-3 bg-white">
            <p className="font-weight-bold mb-3">Filters</p>
            <Row className="align-items-stretch pl-1">
                {clusters && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <p className="font-weight-semibold">Date</p>
                        <DatePicker />
                    </Col>
                )}
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper tooltipText="Sources from which the content is ingested" id="tooltip-sources-label" placement="top">
                        <p className="font-weight-semibold widget-text-style set-fit-content">Sources</p>
                    </TooltipWrapper>
                    <MultiSelectSourceFilter clusters={clusters} />
                </Col>
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper tooltipText="Languages that have been used in the content" id="tooltip-languages-label" placement="top">
                        <p className="font-weight-semibold widget-text-style set-fit-content">Languages</p>
                    </TooltipWrapper>
                    <MultiSelectLanguageFilters clusters={clusters} />
                </Col>
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper id="tooltip-keywords-label"
                        placement="top"
                        tooltipText="Top 20 keywords in your Situation Room that match the query or keywords used to create the Room"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Keywords</p>
                    </TooltipWrapper>
                    {clusters ? <KeywordsFilter clusters={clusters} /> : <MultiKeywordsFilter />}
                </Col>
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper id="tooltip-actors-label"
                        tooltipText="Accounts, groups, and websites that posted content available in your Situation Room"
                        placement="top"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Actors</p>
                    </TooltipWrapper>
                    <ActorsFilter clusters={clusters} />
                </Col>
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper id="tooltip-sentiment-label"
                        tooltipText="Sentiment types based on terms used, language patterns, and source credibility in the content"
                        placement="top"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Sentiment</p>
                    </TooltipWrapper>
                    <SentimentFilter />
                </Col>
                {(labelsFeatureEnabled && !clusters) && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper id="tooltip-labels-label"
                            placement="top"
                            tooltipText="Labels that have been applied to content by users of this Situation Room"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Labels</p>
                        </TooltipWrapper>
                        <LabelsFilter watchlist={watchlist}
                            contentIdsList={contentIds}
                        />
                    </Col>
                )}
                {(!overview && !clusters) && (
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <TooltipWrapper id="tooltip-threats-label"
                            tooltipText={`Threats are AI generated classifiers we place on potentially harmful content which may originate
                                 from a suspicious source. These can be sources that are known to publish biased, non-factual information & 
                                 display traits of inauthentic behaviour.`}
                            placement="top"
                        >
                            <p className="font-weight-semibold widget-text-style set-fit-content">Threat type</p>
                        </TooltipWrapper>
                        <ThreatTypesFilter />
                    </Col>
                )}
                <Col xs="12" sm="auto" className="mb-1 px-1">
                    <TooltipWrapper placement="top"
                        tooltipText="Locations mentioned in the content of this Situation Room"
                        id="tooltip-locations-label"
                    >
                        <p className="font-weight-semibold widget-text-style set-fit-content">Location mentions</p>
                    </TooltipWrapper>
                    <LocationsFilter clusters={clusters} />
                </Col>
            </Row>
        </div>
    );
};
