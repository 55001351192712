const minLength = 12;
const specialCharRegex = /[!@#$%^&*]/;
const upperCaseRegex = /[A-Z]/;
const lowerCaseRegex = /[a-z]/;
const numberRegex = /[0-9]/;

export const validatePassword = (password: string) => {
    const requirements = [
        { test: (pwd: string) => pwd.length >= minLength, message: `at least ${minLength} characters` },
        { test: (pwd: string) => specialCharRegex.test(pwd), message: 'one special character (!@#$%^&*)' },
        { test: (pwd: string) => upperCaseRegex.test(pwd), message: 'one uppercase letter' },
        { test: (pwd: string) => lowerCaseRegex.test(pwd), message: 'one lowercase letter' },
        { test: (pwd: string) => numberRegex.test(pwd), message: 'one number' }
    ];

    const unmetRequirements = requirements
        .filter(requirement => !requirement.test(password))
        .map(requirement => requirement.message);

    if (unmetRequirements.length === 0) {
        return {
            isValid: true,
            message: ''
        };
    }

    const lastRequirement = unmetRequirements.pop();

    return {
        isValid: false,
        message: `Password must include ${unmetRequirements.join(', ')}${unmetRequirements.length ? ', and ' : ''}${lastRequirement}.`
    };
};

export const passwordMessage = `The password must have at least 12 characters, one special character (!@#$%^&*),
one uppercase letter, one lowercase letter, and one number.`;
