import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { InviteUser } from '../../../../components/InviteUser';
import { useModal } from '../../../../context/modal/ModalComponent';
import { DeleteInstanceButton } from './DeleteInstanceButton';
import { useInstance } from './InstanceContext';

export const SummaryTopBar = () => {
    const { instance, isNew } = useInstance();
    const { setModal, closeModal } = useModal();
    return (
        <div className="d-flex align-items-center mb-4">
            <h2 className="m-0 flex-1">{isNew ? instance.clientName || 'Add new client' : instance.clientName}</h2>
            {!isNew && (
                <>
                    <Link className="btn btn-secondary mr-3" to={`/situation-rooms?instance=${instance.id}`}>
                        View Situation rooms
                    </Link>
                    <Button className="mr-3"
                        onClick={() => setModal({
                            component: <InviteUser toggle={closeModal}
                                instance={instance.id}
                            />,
                        })}
                    >
                        Add new members
                    </Button>
                    <DeleteInstanceButton />
                </>
            )}
        </div>
    );
};
