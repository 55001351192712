import React from 'react';
import { Spinner } from 'reactstrap';
import { Images } from '../../utils/modeSwitch';

type LoadingProps = {
    width?: number;
    height?: number | string;
    gif?: boolean;
    pointer?: boolean;
    relative?: boolean;
    background?: string;
    message?: string;
    iconSize?: string | number
    gifSize?: number
}

export const Loading = ({
    width,
    height,
    gif,
    pointer,
    relative,
    background,
    message = '',
    iconSize = 10,
    gifSize = 250
}: LoadingProps) => (
    <div data-testid="loading-div"
        className="d-flex align-items-center justify-content-center flex-column z-index"
        style={{
            height: height || '100%',
            width: width || '100%',
            position: relative ? 'relative' : 'absolute',
            top: 0,
            left: 10,
            right: 20,
            background: background || 'transparent',
            pointerEvents: pointer === false ? 'none' : 'all',
        }}
    >
        {gif ? (
            <img style={{ width: gifSize }}
                src={Images().loading}
                alt="loading..."
                className="d-block"
                data-testid="loading-gif"
            />
        ) : <Spinner size={iconSize.toString()} color="primary" data-testid="loading-spinner" />}
        {message && <p className="mt-2">{message}</p>}
    </div>
);
