import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../../useFilters';

export type UseTimelineResponse = {
    loading: boolean
    data: { key: number, count: number }[]
    interval: string
}

export const useTimeline = (): UseTimelineResponse => {
    const filters = useContentFilters({ useGlobalFilters: false });
    const { data, loading } = useQuery(MATCHES_OVER_TIME, {
        variables: filters
    });

    return {
        loading,
        data: data?.getMatchesOverTime?.data || [],
        interval: data?.getMatchesOverTime?.interval || '1d'
    };
};

export const MATCHES_OVER_TIME = gql`
    query getMatchesOverTime(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        getMatchesOverTime(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
        ) {
            data {
                key
                count
            }
            count
            interval
        }
    }
`;
