import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MultiSelectDropdown } from '../Form/MultiSelectDropdown';
import { TooltipItem } from '../ToolTip';
import { RemoveContentActions, useRemoveContent } from './useRemoveContent';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { getToast } from '../../utils/getToast';
import { getRoom } from '../../utils/variables';

const threatIdentifierOptions = [
    { text: 'Automated account', id: 'Automated account' },
    { text: 'Toxic language', id: 'TOXICITY' },
    { text: 'Severely toxic language', id: 'Severe_Toxicity' },
    { text: 'Threatening language', id: 'THREAT' },
    { text: 'Insulting language', id: 'INSULT' },
    { text: 'Attack on identity', id: 'IDENTITY_ATTACK' },
    { text: 'Inflammatory language', id: 'INFLAMMATORY' },
    { text: 'Attack on author', id: 'ATTACK_ON_AUTHOR' },
    { text: 'Attack on commenter', id: 'ATTACK_ON_COMMENTER' },
    { text: 'Source credibility', id: 'Source credibility' },
    { text: 'Domestic extremism', id: 'domestic_extremism' }
];

type ThreatIdentifierProps = {
    onClose: () => void,
    threatId: string,
    onCompleted?: () => void
}

export const ThreatIdentifier = ({ onClose, threatId, onCompleted }: ThreatIdentifierProps) => {
    const [optionsSelected, setOptionsSelected] = useState([] as { id: string, text?: string }[]);
    const toast = getToast();
    const [confirmButtonClicked, setConfirmButtonClicked] = useState(false);
    const { removeContent, loading } = useRemoveContent({
        type: CONTENT_TYPE.MATCH,
        action: RemoveContentActions.MarkAsThreat,
        onCompleted: () => {
            onClose();
            toast.success('You have updated threat signals for the selected content.');
            if (onCompleted) onCompleted();
        }
    });

    const confirmation = () => {
        setConfirmButtonClicked(true);
        if (optionsSelected.length > 0) {
            removeContent([{
                id: threatId,
                markThreatInfo: optionsSelected?.map(x => x.id)
            }]);
        }
    };

    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];

    return (
        <Modal isOpen toggle={onClose} data-testid="SubmissionComplete">
            <ModalBody>
                <h2>Is this content a threat?</h2>
                <hr />
                <p>
                    Mark the content as a threat if you think that this content might be harmful.
                    This content will be classified as threat and will be visible in the threats section for you and your team.
                </p>
                <p className="font-weight-bold pt-1">
                    Select threat type(s)
                    <TooltipItem placement="bottom"
                        id="threatTypes"
                        content={(
                            <div>
                                <div>
                                    <strong>Automated account. </strong>
                                    Content that originates from bot-operated account(s).
                                </div>
                                <div>
                                    <strong>Toxic. </strong>
                                    A rude, disrespectful, or unreasonable comment that is likely to make people leave a discussion.
                                </div>
                                <div>
                                    <strong>Severely Toxic. </strong>
                                    A very hateful, aggressive, disrespectful comment
                                    or otherwise very likely to make a user leave a discussion
                                    or give up on sharing their perspective. This attribute is much less sensitive to more
                                    mild forms of toxicity, such as comments that include positive uses of curse words.
                                </div>
                                <div>
                                    <strong>Threatening. </strong>
                                    Describes an intention to inflict pain, injury, or violence against an individual or group.
                                </div>
                                <div>
                                    <strong>Insulting. </strong>
                                    Insulting, inflammatory, or negative comment towards a person or a group of people.
                                </div>
                                <div>
                                    <strong>Identity Attack. </strong>
                                    Negative or hateful comments targeting someone because of their identity.
                                </div>
                                <div>
                                    <strong>Inflammatory. </strong>
                                    Content intending to provoke or inflame.
                                </div>

                                <div>
                                    <strong>Attack on Author. </strong>
                                    Attack on the author of an article or post.
                                </div>
                                <div>
                                    <strong>Attack on Commenter. </strong>
                                    Attack on fellow commenter.
                                </div>
                            </div>
                        )}
                    />
                </p>
                <MultiSelectDropdown id="threatIdentifier"
                    options={threatIdentifierOptions.filter(({ id }) => !disabledThreatTypes.includes(id))}
                    className="text-capitalize"
                    onChange={setOptionsSelected}
                />
                {confirmButtonClicked
                    && optionsSelected?.length < 1 && <p className="text-danger pt-1">Please select at least one option.</p>}
                <hr />
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => !loading && confirmation()}
                    color="primary"
                    className="ml-2"
                    disabled={loading}
                >
                    Confirm
                </Button>
            </ModalBody>
        </Modal>
    );
};
