import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { DeleteIcon } from '../../../../assets/svg/delete-icon';
import { DELETE_INSTANCE } from '../../../../graphql';
import { useModal } from '../../../../context/modal/ModalComponent';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../../utils/SuccessErrorModal';

export const DeleteInstanceModal = ({ clientName, id }) => {
    const { closeModal, setModal } = useModal();
    const [showSuccess, setShowSuccess] = useState(false);
    const history = useHistory();
    const setStatusModal = ({ message, title, type }) => {
        setModal({
            component: <StatusModalBodyNew title={title} message={message} messageType={type} />
        });
    };
    const [deleteInstance, { loading }] = useMutation(DELETE_INSTANCE, {
        onCompleted: () => {
            setShowSuccess(true);
        },
        onError: () => {
            setStatusModal({
                message: 'Looks like something went wrong. Please try again later.',
                title: 'Delete instance',
                type: STATUS_TYPES.error
            });
        }
    });

    const handleDeleteInstance = () => {
        deleteInstance({
            variables: {
                id
            }
        });
    };

    const closeAndRedirect = () => {
        closeModal();
        history.push('/admin/instances');
    };

    if (showSuccess) {
        return (
            <div>
                <DeleteIcon />
                <p className="font-weight-bold mt-2">{`'${clientName}'`} deleted successfully</p>
                <hr />
                <Button onClick={closeAndRedirect}>Close</Button>
            </div>
        );
    }

    return (
        <div>
            <h1> Are you sure you want to delete client {`"${clientName}"?`} </h1>
            <hr />
            <p>
                It will be permanantly deleted.
                All data within this instance will also be deleted including users, situation rooms, reports etc.
            </p>
            <hr />
            <div>
                <Button onClick={closeModal} className="mr-2">Cancel</Button>
                <Button onClick={handleDeleteInstance} color="danger" disabled={loading}>Delete</Button>
                {loading && <Spinner className="ml-2" color="primary" size="sm" />}
            </div>
        </div>
    );
};
