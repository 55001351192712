import React from 'react';
import { TrendingDown, TrendingUp } from 'react-feather';
import { useSelector } from 'react-redux';
import { SimpleLineChart } from '../../../../../../../components/Highcharts/SimpleLineChart';
import { Loading } from '../../../../../../../components/Loading';
import { useTotalThreatsAndMatches } from '../../../../../../../services/Overview';
import { trendline } from '../../../../../../../utils/trendline';
import { color } from '../../../../../../../utils/getColors';

export const TotalThreatsAndMatches = ({ small }) => {
    const { report } = useSelector(state => state.createReport);
    const { data, loading } = useTotalThreatsAndMatches({
        dateFilter: report.filters
    });

    if (loading) {
        return <Loading relative height={200} />;
    }
    return (
        <TotalThreatsAndMatchesInner data={data} small={small} />
    );
};

const TotalThreatsAndMatchesInner = ({ data, small }) => {
    const matches = {
        title: 'Total matches',
        data: data.matches.data.map(a => ({
            x: a.key,
            y: a.count
        })),
        count: data.matches.count
    };
    const threats = {
        title: 'Total threats',
        data: data.threats.data.map(a => ({
            x: a.key,
            y: a.count
        })),
        count: data.threats.count
    };
    return (
        <div>
            {[matches, threats].map((item, i) => {
                const { slope } = trendline(item.data);
                return (
                    <div key={item.title.replace(/ /, '')} className={`${i === 0 ? 'border-bottom' : ''} p-${small ? 1 : 2}`}>
                        <p>
                            <strong>
                                {item.title}
                                <span className="ml-2">
                                    {item.count}
                                </span>
                            </strong>
                            <span className="ml-1">
                                {slope > 0
                                    ? <TrendingUp color={color.green[200]} size={16} />
                                    : <TrendingDown color={color.red[200]} size={16} />}
                            </span>
                        </p>
                        <SimpleLineChart data={item.data} title={item.title} />
                    </div>
                );
            })}
        </div>
    );
};
