import React from 'react';
import { X } from 'react-feather';
import { Loading } from '../../../components/Loading';
import { useModal } from '../../../context/modal/ModalComponent';
import { useCreateSituationRoomDraft } from '../../../services/SituationRooms/useCreateSituationRoomDraft';
import { useUpdateSituationRoomDraft } from '../../../services/SituationRooms/useUpdateSituationRoomDraft';
import { ConfirmCloseModal } from './ConfirmCloseModal';
import { useRoomNameAvailability } from './inputs/useRoomNameAvailability';
import { useRoomEditor } from './RoomEditorContext';

export const Header = ({ goBack }) => {
    const { edit, changesMade, canCreateRoom, draft, room } = useRoomEditor();
    const { setModal } = useModal();
    const { nameNotAvailable } = useRoomNameAvailability({ roomName: room.project_name });

    const { createSituationRoomDraft, loading: loadingA } = useCreateSituationRoomDraft({ room });
    const { updateSituationRoomDraft, loading: loadingB } = useUpdateSituationRoomDraft({ room });

    const saveAsDraft = async () => {
        if (draft) {
            await updateSituationRoomDraft();
            goBack();
        } else {
            await createSituationRoomDraft();
            goBack();
        }
    };
    return (
        <div className="bg-light border-bottom py-2 px-4 d-flex align-items-center justify-content-between">
            {(loadingA || loadingB) && <Loading />}
            <h2 className="m-0">{edit ? 'Edit situation room' : 'What would you like to monitor?'}</h2>
            <div role="button"
                onClick={changesMade ? () => setModal({
                    header: false,
                    component: <ConfirmCloseModal saveAsDraft={saveAsDraft}
                        disabled={!canCreateRoom || nameNotAvailable}
                        edit={edit}
                        goBack={goBack}
                    />
                }) : goBack}
            >
                <X size={18} />
            </div>
        </div>
    );
};
