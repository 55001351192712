import { gql, useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTotalMatches = (appliedFilters, defaultFilters, useGlobalFilters = true, watchlist = false, clusters = false) => {
    const filters = useContentFilters({ useGlobalFilters, defaultFilters, watchlist });

    const ret = useQuery(TOTAL_MATCHES, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, appliedFilters?.filters || {}), ['labels'])
        },
        skip: clusters
    });

    return {
        ...ret,
        totalMatches: ret.data ? ret.data?.totalMatches : 0
    };
};

export const TOTAL_MATCHES = gql`
    query totalMatches(
        $projectId: String!
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        totalMatches(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
        )
    }
`;
