import React from 'react';
import { AlertTriangle, Check } from 'react-feather';
import { Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { color } from '../../../../../../utils/getColors';

export const AddNarrativeName = ({ narrativeName, nameError, handleNameChange, rightPanel }) => (
    <div className="d-flex flex-column">
        <p className="d-inline">
            Narrative name ({narrativeName?.length}/70 characters)
        </p>
        <div className={`d-flex ${nameError || (rightPanel && !narrativeName) ? 'border-danger' : 'custom-narrative-input'}`}>
            <Input autoFocus
                data-testid="custom-narrative-name"
                value={narrativeName}
                onChange={(e) => handleNameChange(e)}
                placeholder="Enter narrative name and ENTER"
                className="border-0 narrative-name-input"
            />
            {nameError && (
                <InputGroupAddon addonType="append">
                    <InputGroupText className="border-0 p-1 bg-white">
                        <AlertTriangle color={color.red[200]} size={20} />
                    </InputGroupText>
                </InputGroupAddon>
            )}
            {(!nameError && narrativeName) && (
                <InputGroupAddon addonType="append">
                    <InputGroupText className="border-0 p-1 bg-white">
                        <Check color={color.green[200]} size={16} strokeWidth={3} />
                    </InputGroupText>
                </InputGroupAddon>
            )}
        </div>
        {nameError && (
            <p className="text-danger">
                This narrative name already exists. Choose a different name.
            </p>
        )}
        {rightPanel && !narrativeName && (
            <p className="text-danger">
                The narrative name can&apos;t be blank.
            </p>
        )}
    </div>
);
