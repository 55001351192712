import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { ClustersSortOptions } from '../../AIClustering/Utils/ClusterSort';
import { GET_ALL_SUB_CLUSTERS } from '../../../../graphql';
import { SubCluster } from '../../AIClustering/types';
import { Loading } from '../../../../components/Loading';
import { EmptyState } from '../../../../components/EmptyState';
import { Images } from '../../../../utils/modeSwitch';
import { getRoom } from '../../../../utils/variables';
import { SubClustersTable } from '../../AIClustering/ListView/SubClustersTable';
import { SearchInput } from '../../../../components/Molecules';

export const NarrativesWatchlist = () => {
    const room = getRoom();
    const [searchVal, setVal] = useState('');
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Most content' });
    const [subClustersOffset, setSubClustersOffset] = useState(0);
    const { data: subClustersData, loading: subClusterLoading } = useQuery(GET_ALL_SUB_CLUSTERS, {
        variables: {
            limit: 10,
            skip: subClustersOffset,
            search: searchVal,
            projectId: room.project_id,
            sort: pick(sort, ['field', 'order']),
            isWatchlisted: true
        },
        fetchPolicy: 'no-cache'
    });
    const getSubClustersData = subClustersData?.getAllSubClusters;
    const subClusters: SubCluster[] = getSubClustersData?.subClusters || [];
    if (subClusterLoading) {
        return <Loading gif relative height={300} />;
    }
    if (subClusters?.length < 1) {
        return (
            <div className="border">
                <EmptyState title="Your watchlist is empty"
                    summary="Add narratives to start tracking them here."
                    icon={Images().metrics}
                    action={{ name: 'Explore AI Narrative', color: 'primary', to: `/situation-rooms/${room.id}/ai-narratives` }}
                />
            </div>
        );
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <SearchInput placeholder="Search by keywords"
                    handleSearch={setVal}
                    className="w-100 mr-2"
                    defaultValue={searchVal}
                />
                <ClustersSortOptions sort={sort} setSort={setSort} classname="mb-1 mr-4" isNarrative />
            </div>
            <SubClustersTable subClusters={subClusters}
                total={getSubClustersData.total}
                offset={subClustersOffset}
                onOffsetChange={setSubClustersOffset}
                itemsPerPage={10}
                keywordsToHighlight={[searchVal]}
            />
        </>
    );
};
