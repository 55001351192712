import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../store';
import { resetNarrativeThemeList, setNarrativesThemeComparisonList } from '../../../store';
import { TooltipItem } from '../../../../../components/ToolTip';
import { SelectedNarrative } from '../SelectedNarrative';
import { getToast } from '../../../../../utils/getToast';
import { getRoom } from '../../../../../utils/variables';

type SelectedNarrativeThemesType = {
    id: string
    title: string
}

export const NarrativeThemeComparison = () => {
    const dispatch = useDispatch();
    const toast = getToast();
    const history = useHistory();
    const room = getRoom();
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);
    const removeNarrativeThemeItem = (themeId: string) => {
        toast.success('Narrative theme removed from comparison');
        const selectedThemes = selectedNarrativeThemes.filter((theme: SelectedNarrativeThemesType) => theme.id !== themeId);
        dispatch(setNarrativesThemeComparisonList({
            selectedNarrativeThemes: selectedThemes
        }));
    };
    const setCompare = () => {
        if (selectedNarrativeThemes.length < 2) {
            toast.error('Please select at least 2 Narrative themes to compare');
            return;
        }
        const id = `?id=${selectedNarrativeThemes.map((item: {id: string}) => item.id)}`;
        history.push(`/situation-rooms/${room.id}/ai-narratives/narrative-theme-comparison/${id}`);
    };
    return (
        <div className={`bg-white d-flex justify-content-between py-2 px-4 border-top border-bottom 
        ${selectedNarratives.length === 0 ? 'mb-3' : ''}`}
        >
            <div className="d-flex align-items-center">
                <p className="mb-0">Selected narrative themes</p>
                <TooltipItem id="compareThemes"
                    placement="bottom-start"
                    className="bg-white text-black"
                    content={(
                        <p>You can compare up to 4 narrative themes</p>
                    )}
                />
                {selectedNarrativeThemes.length > 0
                    ? selectedNarrativeThemes.map((narrativeTheme: SelectedNarrativeThemesType) => (
                        <SelectedNarrative key={narrativeTheme.id}
                            selectedNarrative={narrativeTheme}
                            removeItem={removeNarrativeThemeItem}
                        />
                    )) : null}
            </div>
            <div>
                <Button color="link" className="mr-1" onClick={() => dispatch(resetNarrativeThemeList())}>Clear all</Button>
                <Button color="primary" onClick={setCompare}>Compare</Button>
            </div>
        </div>
    );
};
