import { convertToMarkers } from '../convertToMarkers';

export const specialCharsValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }
    [...query.matchAll(/[!^–]/g)].forEach((match) => {
        highlight.push([match.index, match.index + 1]);
    });
    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You cannot use any of the following special characters within a Boolean, !, —, or ^.'
        )
    };
};
