import { convertToMarkers } from '../../convertToMarkers';

export const tagsCapitalisationValidator = (query, queryByLines, lineLengths, tag, tagKey, caseSensitiveTags) => {
    const highlight = [];

    if (!caseSensitiveTags.includes(tagKey)) {
        const tagValue = tag.replace(`${tagKey}:`, '');
        [...query.matchAll(new RegExp(`${tagKey}:\\s*${tagValue}`, 'g'))].forEach((match) => {
            highlight.push([match.index, match.index + match[0].length]);
        });
    }

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have used an invalid tag. Please check the capitalisation.'
        )
    };
};
