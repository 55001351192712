import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { debounce, isEqual } from 'lodash';
import { getRoom } from '../../utils/variables';
import { extractKeywords } from '../../utils/extractKeywords';

export const useQueryExtracts = ({ url = '', text = '', keywordsToHighlight = [] }) => {
    const filtersCopy = useSelector((state) => state.filters.filters);
    const filters = JSON.parse(JSON.stringify(filtersCopy));
    delete filters.labels;

    const searchHighlights = useMemo(() => [
        ...extractKeywords(filters.query) || [],
        ...filters.booleanQueryKeywords
    ].filter(keyword => keyword), [filters.query, filters.booleanQueryKeywords]);

    const room = getRoom();
    const { data } = useQuery(GET_QUERY_KEYWORDS, {
        variables: {
            projectId: room?.project_id
        },
        skip: !room?.project_id
    });

    const keywordsFromRoomQuery = useMemo(() => (
        (keywordsToHighlight.length ? keywordsToHighlight : (data?.getQueryKeywords || []))
            .filter(keyword => !keyword.includes('http://') && !keyword.includes('https://'))
    ), [keywordsToHighlight, data?.getQueryKeywords]);

    const [state, setState] = useState({
        highlightedText: text,
        highlightedURL: url,
        keywordsMentioned: []
    });

    const prevKeywordsRef = useRef();

    const updateHighlights = useCallback(() => {
        const newKeywordsToHighlight = searchHighlights.length ? searchHighlights : keywordsFromRoomQuery;
        const hasKeywordsChanged = !isEqual(prevKeywordsRef.current, newKeywordsToHighlight);
        prevKeywordsRef.current = newKeywordsToHighlight;

        if (!hasKeywordsChanged) {
            return;
        }

        let { highlightedText } = addHighlights({
            text,
            keywordsToHighlight: keywordsFromRoomQuery
        });
        let highlightedURL = addHighlights({
            text: url,
            keywordsToHighlight: keywordsFromRoomQuery
        }).highlightedText;
        const keywordsMentioned = [highlightedText, highlightedURL]?.join(' ').match(/<em>(.*?)<\/em>/g)?.map(word => word?.replace(/(<([^>]+)>)/gi, ''));

        if (searchHighlights.length) { // keeping this separate to maintain keywordsMentioned from boolean
            highlightedURL = addHighlights({
                text: url,
                keywordsToHighlight: searchHighlights
            }).highlightedText;
            highlightedText = addHighlights({
                text,
                keywordsToHighlight: searchHighlights
            }).highlightedText;
        }

        setState({
            highlightedURL,
            highlightedText,
            keywordsMentioned: keywordsFromRoomQuery.map(keyword => ({
                value: keyword,
                count: keywordsMentioned?.filter(mention => {
                    const mentionLower = mention.toLowerCase();
                    return mentionLower === keyword.toLowerCase() || mentionLower.replaceAll('-', ' ') === keyword.toLowerCase();
                }).length
            })).filter(mention => mention.count)
        });
    }, [text, url, searchHighlights, keywordsFromRoomQuery]);

    const debouncedUpdateHighlights = useMemo(() => debounce(updateHighlights, 300), [updateHighlights]);

    useEffect(() => {
        debouncedUpdateHighlights();
        return () => debouncedUpdateHighlights.cancel();
    }, [debouncedUpdateHighlights]);

    return state;
};

export const addHighlights = ({ text, keywordsToHighlight }) => {
    if (!text) {
        return { highlightedText: text, containsHighlight: false };
    }
    let returnValue = text;

    const keywords = [...keywordsToHighlight];

    if (text.includes('http://') || text.includes('https://')) {
        keywordsToHighlight.forEach(keyword => {
            if (keyword.includes(' ')) {
                keywords.push(keyword.replace(/\s+/g, '-').toLowerCase());
            }
        });
    }

    for (const word of keywords) {
        const formattedWordNew = word.replaceAll('*', '')
            .replace(/[.،:]/g, '\\$&');
        if (
            returnValue.toLowerCase().includes(formattedWordNew.toLowerCase())
            && !returnValue.toLowerCase().includes(`<em>${formattedWordNew.toLowerCase()}</em>`)
        ) {
            try {
                // Replace any empty space in any language with standard UTF-8 empty space
                // eslint-disable-next-line max-len
                const formattedWord = word.replace('[\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DB5\u4E00-\u9FEF\uF900-\uFA6D\uFA70-\uFAD9\\U00020000-\\U0002A6D6\\U0002A700-\\U0002B734\\U0002B740-\\U0002B81D\\U0002B820-\\U0002CEA1\\U0002CEB0-\\U0002EBE0\\U0002F800-\\U0002FA1D]', ' ')
                    .replace(/[+$#^&!.،:]/g, '\\$&');

                if (word.includes('*')) {
                    const regex = getWildcardSelectionRegex(formattedWord);
                    returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
                    returnValue = returnValue.replaceAll(' </em>', '</em> ');
                // Latin "support"
                } else if (formattedWord.match(/[a-zA-Z]/, 'gi')) {
                    const regex = new RegExp(`(?<![a-zA-Z])${formattedWord}+(?![a-zA-Z])`, 'ig');
                    returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
                } else if (formattedWord.match(/[\u0600-\u06FF]/)) {
                    // Arabic characters with non-word boundary assertions and support for Arabic punctuation
                    const regex = new RegExp(`(?<![\\p{L}\\d])${formattedWord}(?![\\p{L}\\d])`, 'uig');
                    returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
                }
                else {
                    const regex = new RegExp(`(?<![\\S])(${formattedWord})(?![\\S])`, 'ig');
                    returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
    return {
        highlightedText: returnValue.trim(),
        containsHighlight: returnValue.includes('<em>')
    };
};

const getWildcardSelectionRegex = (word) => {
    // Determine how many wildcards we have and their indexes
    let regex = '';
    const indices = [];
    for (let i = 0; i < word.length; i++) {
        if (word[i] === '*') indices.push(i);
    }

    const rightToLeft = word.match('[\u04c7-\u0591\u05D0-\u05EA\u05F0-\u05F4\u0600-\u06FF]', 'gi') !== null || word.match(/[\u0600-\u06FF]/) !== null;

    // Wildcard around the word
    if (indices.length === 2 && indices[0] === 0 && indices[1] === word.length - 1) {
        regex = new RegExp(`[\\S]*(${word.replaceAll('*', '')})[\\S]*`, 'ig');
    // Wildcard before the word
    } else if (indices[0] === 0) {
        regex = new RegExp(`([\\S])*(${word.replace('*', '')})(\\s|$)`, 'ig');
        if (rightToLeft) {
            regex = new RegExp(`(?<![\\S])(${word.replace('*', '')})[\\S]*`, 'ig');
        }
    // Wildcard after the word
    } else if (indices[0] === word.length - 1) {
        regex = new RegExp(`(?<![\\S])(${word.replace('*', '')})[\\S]*`, 'ig');
        if (rightToLeft) {
            regex = new RegExp(`([\\S])*(${word.replace('*', '')})(\\s|$)`, 'ig');
        }
    }

    return regex;
};

export const GET_QUERY_KEYWORDS = gql`
    query getQueryKeywords($projectId: String!){
        getQueryKeywords(projectId: $projectId)
    }
`;

export const useQueryKeywords = (text = 'filters') => {
    const { query } = useSelector((state) => state[text].filters);
    if (!query) return [];
    return query.match(/(?:[^\s"]+|"[^"]*")+/g).map(word => word.replace(/"/g, ''));
};
