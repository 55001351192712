import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useMarkAsRead } from '../../../services/Alerts/useMarkAsRead';
import { GET_ALERTS } from '../../../graphql';
import { NotificationCard } from './NotificationsCard';
import { Pagination } from '../../Molecules';
import { Loading } from '../../Loading';

type AlertSituation = {
    id: string,
    name: string
   }

type NotificationDefaultProps = {
    id: string,
    projectId: string,
    date: number,
    category: string,
    subcategory: string,
    description: string,
    situationRoom: AlertSituation,
    read: boolean
};

export const Notifications = () => {
    const [showUnread, setShowUnread] = useState(false);
    const [offset, setOffset] = useState(0);
    const { data, loading } = useQuery(GET_ALERTS, {
        variables: {
            offset,
            limit: 10,
            unreadOnly: showUnread
        },
        fetchPolicy: 'cache-and-network'
    });
    const notifications = (data?.getAlerts?.alerts || []) as NotificationDefaultProps[];
    const total = data?.getAlerts?.total || 0;
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const todays = notifications?.filter(item =>
        moment(item.date).format('DD/MM/YY') === moment(new Date()).format('DD/MM/YY')) || [];
    const yesterdays = notifications?.filter(item =>
        moment(item.date).format('DD/MM/YY') === moment(yesterday).format('DD/MM/YY')) || [];
    const older = notifications?.filter(item =>
        (moment(item.date).format('DD/MM/YY') !== moment(new Date()).format('DD/MM/YY'))
        && (moment(item.date).format('DD/MM/YY') !== moment(yesterday).format('DD/MM/YY'))) || [];

    const { markAsRead } = useMarkAsRead({
        alertIds: notifications.map(item => item.id),
        markAllAsRead: true,
        showUnread
    });

    return (
        <div className="d-flex justify-content-center">
            <div className="mw-750 w-100 pb-3">
                <h3 className="my-3 font-size-24">Notifications</h3>
                <hr />
                {loading ? <Loading height={200} relative /> : (
                    <>
                        <div className="d-flex my-3 align-items-center">
                            <p className="mb-0">{total} notifications</p>
                            <CustomInput type="switch"
                                id="unreadSwitch"
                                data-testid="unreadSwitch"
                                className="custom-control-input-big mr-1 ml-auto primary"
                                onChange={(e) => setShowUnread(e.target.checked)}
                                checked={showUnread}
                            />
                            <p className="mr-3 mb-0">Unread only</p>
                            <p className="text-primary cursor-pointer mb-0"
                                onClick={() => markAsRead()}
                            >Mark all as read
                            </p>
                        </div>
                        <div className="noti-container px-3 pt-3 pb-2">
                            <div>
                                {todays?.length !== 0 && (
                                    <>
                                        <p className="pb-2 font-size-14">Today</p>
                                        {todays?.map(item => (
                                            <NotificationCard notification={item} showUnread={showUnread} />
                                        ))}
                                    </>
                                )}
                                {yesterdays?.length !== 0 && (
                                    <>
                                        <p className="pb-2 font-size-14">Yesterday</p>
                                        {yesterdays?.map(item => (
                                            <NotificationCard notification={item} showUnread={showUnread} />
                                        ))}
                                    </>
                                )}
                                {older?.length !== 0 && (
                                    <>
                                        <p className="pb-2 font-size-14">Older</p>
                                        {older?.map(item => (
                                            <NotificationCard notification={item} showUnread={showUnread} />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                        {total > 40 && (
                            <div className="bg-white">
                                <hr className="m-0" />
                                <Pagination total={total || 0}
                                    itemsPerPage={10}
                                    offset={offset}
                                    setOffset={setOffset}
                                    className="p-2 mb-0"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
