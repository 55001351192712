import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Alert, Button } from 'reactstrap';
import { CheckCircle, X } from 'react-feather';
import { RootState } from '../../../../store';
import { WarningAlert } from '../../../../components/Atoms/Alert/WarningAlert';
import { setClusteringStatus } from '../store';

export const NarrativeAnalysisCompletedAlert = () => {
    const { clusteringStatus: completedClusteringStatus } = useSelector((state: RootState) => state.aiNarratives);
    const dispatch = useDispatch();

    if (!completedClusteringStatus) return null;

    const clearAlert = () => dispatch(setClusteringStatus(null));

    const { newThemes, newNarratives, completedAt } = completedClusteringStatus;

    if (newThemes === 0 && newNarratives === 0) {
        return (
            <WarningAlert onClose={clearAlert} className="w-fit-content">
                <span className="mr-2">
                    Narrative analysis was completed {moment(completedAt).format('DD/MM/YYYY, h:mmA')}, however no
                    new Narrative themes or Narratives were found.
                </span>
            </WarningAlert>
        );
    }

    return (
        <Alert color="success" className="d-flex align-items-center w-fit-content">
            <CheckCircle className="text-success mr-2" />
            Narrative analysis completed {moment(completedAt).format('DD/MM/YYYY, h:mmA')}, {newThemes} Narrative
            Themes & {newNarratives} Narratives found.
            <Button color="link" className="mw-0 p-0 ml-2" onClick={clearAlert}>
                <X />
            </Button>
        </Alert>
    );
};
