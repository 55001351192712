import { omit } from 'lodash';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { BackButton } from '../../../../components/Atoms/BackButton';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { Loading } from '../../../../components/Loading';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { removeTypename } from '../../../../utils/removeAllTypename';
import { InstanceContextProvider } from './InstanceContext';
import { InstanceForm } from './InstanceForm';
import { SummaryTopBar } from './SummaryTopBar';
import { useGetInstance } from '../../../../services/Instance/getInstance';

export const InstanceSummary = () => {
    const match = useRouteMatch();
    const { user } = useAdminUser();
    const { instance, loading } = useGetInstance({ instanceId: match.params.id, skip: match.params.id === 'create' });

    if (loading) {
        return <Loading />;
    }
    return (
        <InstanceContextProvider defaultInstance={instance ? omit(removeTypename(instance), ['plan.tokensRequestedBy']) : undefined}>
            <div className="p-4">
                <BreadCrumbs items={[
                    { title: 'Admin home', href: '/admin' },
                    { title: 'Instances', href: '/admin/instances' },
                    { title: instance?.clientName || match.params.id }
                ]}
                />
                <BackButton pathName="/admin/instances" />
                {(user.role !== 'ADMIN' && match.params.id === 'create') ? (
                    <p>
                        You don&apos;t have permission to create an instance.
                    </p>
                ) : (
                    <>
                        <Alert isOpen={user.role !== 'ADMIN'} color="danger">Only admin user can make changes here</Alert>
                        <SummaryTopBar />
                        <InstanceForm />
                    </>
                )}
            </div>
        </InstanceContextProvider>
    );
};
