/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { X } from 'react-feather';
import { Draggable } from 'react-beautiful-dnd';
import { useRoomEditor } from '../RoomEditorContext';
import { useSingleSuggestion } from './useSingleSuggestion';

export const Keyword = ({ keyword, removeKeyword, index, duplicateKeyword }) => {
    const { selectedKeyword, setSelectedKeyword, room, setRoom } = useRoomEditor();
    const { data } = useSingleSuggestion({ keyword: keyword.value });
    useEffect(() => {
        if (data && data.type && keyword.type === 'unknown') {
            setSelectedKeyword({
                ...selectedKeyword,
                type: data.type
            });
            const findAndReplace = (item) => {
                if (item.value === keyword.value) {
                    if (data.type === 'account') {
                        return {
                            ...item,
                            value: data.value,
                            type: data.type,
                            accountType: data.accountType
                        };
                    }
                    return {
                        ...item,
                        type: data.type
                    };
                }
                return item;
            };
            setRoom({
                ...room,
                query: {
                    ...room.query,
                    included: room.query.included.map(findAndReplace),
                    excluded: room.query.excluded.map(findAndReplace)
                }
            });
        }
    }, [setSelectedKeyword, selectedKeyword, setRoom, room, data, keyword]);
    return (
        <Draggable key={keyword.value} draggableId={keyword.value} index={index}>
            {(provided) => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                >
                    <div onClick={() => setSelectedKeyword(keyword)}
                        color={duplicateKeyword === keyword.value ? 'danger' : 'primary'}
                        className={`tag mb-1 mr-1 ${selectedKeyword.value === keyword.value ? 'selected' : ''}
                            ${duplicateKeyword === keyword.value && 'duplicate'}`}
                    >
                        <span className="dont-break-out">
                            {keyword.value.trim()}
                        </span>
                        <button className="btn"
                            type="button"
                            onClick={removeKeyword}
                        >
                            <X />
                        </button>
                    </div>
                </div>
            )}
        </Draggable>
    );
};
