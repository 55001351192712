import { convertToMarkers } from '../../convertToMarkers';
import { getTagData } from './getTagData';

export const accountTagValidator = (query, queryByLines, lineLengths, tag, allowedSources) => {
    const highlight = [];

    const tagFragments = tag.split(':');
    const tagData = getTagData(tagFragments, query);

    tagData.forEach(({ tagKey, tagValue, tagSnapshotKey, tagSnapshot, tagSource }) => {
        if (
            // Empty tag, value or unsupported source
            (tagKey.length === 0 || tagValue.length === 0 || !allowedSources.includes(tagSource))
                    // Empty spaces in key
                    || tagKey.length !== tagSnapshotKey.length
        ) {
            highlight.push([
                query.indexOf(tagSnapshot),
                query.indexOf(tagSnapshot) + tagSnapshot.length
            ]);
        }
    });

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'The account tag doesn\'t support the source you have entered.'
        )
    };
};
