import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    Table
} from 'reactstrap';
import { Loading } from '../../components/Loading';
import { SortToggle } from '../../components/Table/SortToggle';
import { useUser } from '../../context/user/UserContext';
import { GET_SITUATION_ROOMS } from '../../graphql';
import { DraftRooms } from './DraftRooms';
import { SituationRoomRow } from './SituationRoomRow';
import { RecentSituationRooms } from './RecentSituationRooms';
import { SearchInput, Pagination } from '../../components/Molecules';
import { NoSituationRooms, NoSituationRoomsFound } from './NoSituationRooms';
import { useRoomMetrics } from './RoomMetrics';
import { ArchivedNotification } from './ArchivedNotification';
import { getParam } from '../../utils/urlParams';
import { TextTooltip } from '../../components/ToolTip/TextTooltip';
import { TooltipWrapper } from '../../components/ToolTip/TooltipWrapper';

export const SituationRoomsList = () => {
    const ROOMS_PER_PAGE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [tab, setTab] = useState(parseInt(getParam('tab'), 10) || 0);
    const [offset, setOffset] = useState(0);
    const [sort, setSort] = useState({ field: 'createdAt', order: 'desc' });
    const { user } = useUser();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const instanceParam = params.get('instance');
    const instanceId = instanceParam || user.instance.id;

    const [setShowArchivedTabDot] = useMutation(SET_SHOW_ARCHIVED_TAB_DOT);
    const { data: showArchivedTabDotData } = useQuery(SHOW_ARCHIVED_TAB_DOT, {
        variables: {
            userId: user.id
        }
    });
    const showArchivedTabDot = showArchivedTabDotData?.showArchivedTabDot;

    const statuses = ['active', '', 'archived', 'all']; // drafts handled in a separate request
    const { data, loading } = useQuery(GET_SITUATION_ROOMS, {
        variables: {
            instance: instanceId,
            status: statuses[tab] || 'all',
            limit: ROOMS_PER_PAGE,
            skip: offset,
            sort,
            searchValue
        }
    });
    const rooms = data?.situationRooms?.data || [];
    const roomMetrics = useRoomMetrics(rooms.map((room) => room.project_id));
    const setActiveTab = (num) => {
        setTab(num);
        setOffset(0);
    };
    if (rooms.length === 0 && !loading && tab === 0 && !searchValue && offset === 0) {
        return (
            <NoSituationRooms />
        );
    }

    const goToArchivedTab = () => {
        setActiveTab(2);
        if (!showArchivedTabDot) return;
        setShowArchivedTabDot({
            variables: {
                userId: user.id,
                showDot: false
            },
            refetchQueries: ['showArchivedTabDot']
        });
    };

    const isNarrativesEnabled = user?.instance?.plan?.core?.aiClustering;

    return (
        <div>
            <ArchivedNotification goToArchivedTab={goToArchivedTab} />
            <RecentSituationRooms instanceId={instanceId} />
            <h2 className="m-0">Situation Rooms</h2>
            <Row className="align-items-end">
                <Col xs={12} md={6}>
                    <Nav tabs className="my-2">
                        <TabLink label="Active" onClick={() => setActiveTab(0)} active={tab === 0} />
                        <TabLink label="Drafts" onClick={() => setActiveTab(1)} active={tab === 1} />
                        <TabLink label="Archived" onClick={goToArchivedTab} active={tab === 2} hasDot={showArchivedTabDot} />
                        <TabLink label="All" onClick={() => setActiveTab(3)} active={tab === 3} />
                    </Nav>
                </Col>
                <Col xs={12} md={6}>
                    <SearchInput placeholder="Search situation room"
                        handleSearch={(value) => {
                            setSearchValue(value);
                            setOffset(0);
                        }}
                        className="my-2"
                    />
                </Col>
            </Row>
            {tab === 1 ? (
                <DraftRooms instanceId={instanceId} />
            ) : (
                <div>
                    {loading ? <Loading gif relative height={400} /> : (
                        <div className="pb-2">
                            <Table className="mb-0" hover>
                                <thead>
                                    <tr>
                                        <th>
                                            <SortToggle sort={sort} setSort={setSort} name="Name" field="project_name" />
                                        </th>
                                        <th>Status</th>
                                        <th>
                                            <TextTooltip title="Matches"
                                                text="Matches are contents
                                                that are related to the keywords in your situation room."
                                            />
                                        </th>
                                        <th>
                                            <TextTooltip title="Threats"
                                                text="Threats are content that may harm individuals, communities,
                                                    or the overall online environment, particularly on social media platforms."
                                            />
                                        </th>
                                        {isNarrativesEnabled && (
                                            <th>
                                                <TextTooltip title="Narrative themes"
                                                    text="Narratives grouped into themes based on similarities and commonalities."
                                                />
                                            </th>
                                        )}
                                        <th>
                                            <TextTooltip title="Narratives"
                                                text="A narrative is formed when a significant amount of
                                                    content related to the keywords in your situation room is generated,
                                                     and multiple accounts start spreading the content."
                                            />
                                        </th>
                                        {tab === 2 ? (
                                            <>
                                                <th>Archived by</th>
                                                <th>
                                                    <SortToggle sort={sort}
                                                        setSort={setSort}
                                                        name="Archived date"
                                                        field="archivedDetails.archivedAt"
                                                    />
                                                </th>
                                                <th />
                                            </>
                                        ) : (
                                            <>
                                                <th>
                                                    <TextTooltip title="Last viewed"
                                                        text="The most recent time you visited this situation room."
                                                    />
                                                </th>
                                                <th>
                                                    <TooltipWrapper id="last-updated"
                                                        tooltipText="The most recent time this situation room was modified."
                                                    >
                                                        <SortToggle sort={sort}
                                                            setSort={setSort}
                                                            name="Last updated"
                                                            field="updatedAt"
                                                        />
                                                    </TooltipWrapper>
                                                </th>
                                                <th />
                                                <th />
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rooms.map((room) => (
                                        <tr key={room.id}>
                                            <SituationRoomRow tab={tab}
                                                room={{
                                                    ...room,
                                                    metrics: roomMetrics.find((agg) => agg.projectId === room.project_id)
                                                }}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {(!loading && !rooms.length) && <NoSituationRoomsFound />}
                            <Pagination total={data.situationRooms.total}
                                itemsPerPage={ROOMS_PER_PAGE}
                                offset={offset}
                                setOffset={setOffset}
                                className="p-2 bg-white border-top"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const TabLink = ({ label, active, onClick, hasDot }) => (
    <NavItem key={label}>
        <NavLink className={`${active ? 'active' : ''} d-flex align-items-center`}
            onClick={onClick}
            data-testid={`${label}-tab-link`}
        >
            <h4>{label}</h4>
            {hasDot && <div className="p-11 bg-primary rounded ml-11" data-testid={`${label}-tab-dot`} />}
        </NavLink>
    </NavItem>
);

export const SHOW_ARCHIVED_TAB_DOT = gql`
    query showArchivedTabDot ($userId: ID!) {
        showArchivedTabDot(userId: $userId)
    }
`;

export const SET_SHOW_ARCHIVED_TAB_DOT = gql`
    mutation setShowArchivedTabDot ($userId: ID!, $showDot: Boolean!) {
        setShowArchivedTabDot(userId: $userId, showDot: $showDot)
    }
`;
