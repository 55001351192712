import { uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { createNode } from './createNode';
import { NetworkNode } from '../../useNetworks';
import { NetworksHistoryStore } from '../../../store';

export const useURLNodes = (data: NetworkNode[]) => {
    const { includeSharedURLs } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    if (!includeSharedURLs) return {};
    const allURLs = uniq(data.map(({ url }) => url));
    return Object.fromEntries(
        allURLs.map(url => [
            `url_${url}`,
            {
                ...createNode({
                    text: url,
                    fontIcon: 'fa-link'
                }),
                data: {
                    type: 'url',
                    id: url
                }
            }
        ])
    );
};
