import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTopSharedUrls = ({ dateFilter, size = 10 } = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters: { date: dateFilter } });
    const ret = useQuery(TOP_SHARED_URLS, {
        variables: {
            ...filters,
            size
        }
    });

    return {
        ...ret,
        data: ret.data?.topSharedURLs?.map(item => ({
            ...item,
            key: item.url
        })) || []
    };
};

export const TOP_SHARED_URLS = gql`
    query topSharedURLs(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topSharedURLs(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        ) {
            url
            count
        }
    }
`;
