import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../../utils/variables';
import { BatchRecoverCompletedModal } from './BatchRecoverCompletedModal';
import { BatchRecoverModal } from '../../../../../components/Organisms/Modals/BatchRecoverModal/BatchRecoverModal';
import { resetAll } from '../../../store';

export const BatchRecoverContentButton = ({ onSuccess, onAction, onAbort, total, searchFilters, offset, sort }) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const dispatch = useDispatch();
    const handleCompleted = ({ total: totalRecovered, timeInSeconds }) => {
        onSuccess();
        dispatch(resetAll());
        setModal({
            header: false,
            component: <BatchRecoverCompletedModal totalRecovered={totalRecovered} timeInSeconds={timeInSeconds} />,
        });
    };
    const setRecoverContentConfirmationModal = () => {
        onAction();
        setModal({
            header: 'Recover this content?',
            component: (
                <>
                    <p>
                        {total} selected contents are about to be recovered back into {room.project_name}.
                        This content will be visible to you and your team in this room again.
                    </p>
                    <hr className="my-2" />
                    <Button className="mr-2" onClick={closeModal}>Cancel</Button>
                    <Button color="primary" className="mr-2" onClick={setRecoverContentModal}>Recover</Button>
                </>
            )
        });
    };

    const setRecoverContentModal = () => setModal({
        header: false,
        component: (
            <BatchRecoverModal onCompleted={handleCompleted}
                onAbort={onAbort}
                searchFilters={searchFilters}
                offset={offset}
                sort={sort}
            />
        ),
        backdrop: 'static'
    });

    return (
        <Button className="mr-1" color="primary" onClick={setRecoverContentConfirmationModal}>
            Recover content
        </Button>
    );
};
