import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../../../../../components/Loading';
import { Table } from '../../../../../../../components/Table';
import { useSources } from '../../../../../../../services/Overview';

export const Sources = ({ className, small }) => {
    const { report } = useSelector(state => state.createReport);
    const { data, loading } = useSources({
        dateFilter: report.filters,
        includeThreats: true
    });

    if (loading) {
        return <Loading relative height={200} />;
    }
    return (
        <Table columns={sourcesTablecolumns}
            data={data}
            condensed={`p-${small ? 1 : 2}`}
            className={className}
            hover
        />
    );
};

const sourcesTablecolumns = [
    {
        name: 'Plaform',
        field: 'source'
    },
    {
        name: 'Matches',
        field: 'count'
    },
    {
        name: 'Threats',
        field: 'threats'
    }
];
