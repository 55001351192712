import React from 'react';
import { NoDataAvailableState } from '../../../../../components/EmptyState';
import { EngagementCard } from './EngagementCard';
import { EngagementDataType } from './EngagementCards';

type KeywordsCardsProps = {
    title: string;
    total: number;
    engagementData: EngagementDataType[];
    onOtherKeywordsClick: () => void;
}

export const KeywordsCards = ({ title, engagementData, total, onOtherKeywordsClick }: KeywordsCardsProps) => (
    <>
        <h3 className="m-0 mb-1">{title}</h3>
        {engagementData.length > 0 ? (
            <div className="row row-cols-4 gap-2 ml-0 engagement-context">
                {engagementData.slice(0, 5).map(item => (
                    <EngagementCard key={item.value} value={item.value} count={item.count || -1} />
                ))}
                {total === 6 && (
                    <EngagementCard value={engagementData[5].value} count={engagementData[5].count} />
                )}
                {total > 6 && (
                    <EngagementCard value="Others" count={total} onClick={onOtherKeywordsClick} />
                )}
            </div>
        ) : (
            <NoDataAvailableState />
        )}
    </>
);
