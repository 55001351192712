import { convertToMarkers } from '../convertToMarkers';

export const singleQuotesValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    // Find all single quotes inlcuding two single qoutes which are used instead of single double qoutes
    // Ignoring those used as apostrophes
    const singleQuoteGroups = [...query.matchAll(/(\s|^)'(.*?)'(\s|$)|''/g)];

    singleQuoteGroups.forEach(group => {
        highlight.push([group.index, group.index + group[0].length]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have used single quotes instead of double quotes'
        )
    };
};
