import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { SuccessIcon } from '../../../assets/svg/success-icon';

type RoomCreatedModalProps = {
    goToRoom: () => void
}

export const RoomCreatedModal = ({ goToRoom }: RoomCreatedModalProps) => {
    const [timer, setTimer] = useState(5);

    useEffect(() => {
        if (!timer) return goToRoom();
        const intervalId = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [goToRoom, timer]);

    return (
        <div data-testid="create-room-success">
            <SuccessIcon />
            <h2 className="my-2">Situation Room created successfully</h2>
            <hr />
            <p>You will be redirected to the Logically Intelligence dashboard in {timer} seconds.</p>
            <hr />
            <Button color="primary" onClick={goToRoom}>Proceed to dashboard</Button>
        </div>
    );
};
