import { convertToMarkers } from '../convertToMarkers';

export const emptyNearValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    const nearQueries = [...query.matchAll(/NEAR\/\d+/g)];
    const isNear = nearQueries?.length > 0;

    // Is there's no NEAR operator
    if (!isNear) {
        return {
            isValid: true,
            markers: []
        };
    }

    nearQueries.forEach(match => {
        let leftTerms = '';
        let rightTerms = '';

        let nearStart = match.index;
        const nearEnd = match.index + match[0].length;

        const leftSide = query.substring(0, nearStart);
        const rightSide = query.substring(nearEnd, query.length);

        let rightTermsStart = 0;
        let index = 1;
        let steps = leftSide.length;
        let leftOpened = 0;
        let leftClosed = 0;
        let rightOpened = 0;
        let rightClosed = 0;
        let leftTermsStart = 0;

        // find all terms associated with NEAR operator
        while (steps) {
            const char = leftSide.charAt(leftSide.length - index);
            if (char === '(') {
                leftOpened++;
                if (leftOpened === leftClosed || leftClosed === 0) {
                    leftTermsStart = leftSide.length - index + 1;
                    leftTerms = leftSide.substring(leftTermsStart, nearStart);
                    break;
                }
            }
            if (char === ')') {
                leftClosed++;
                if (steps === leftSide.length - 1) {
                    nearStart = leftSide.length - index;
                }
            }
            if (steps === 1) {
                leftTerms = leftSide.substring(0, nearStart);
                break;
            }
            index++;
            steps--;
        }

        index = 1;
        steps = rightSide.length;

        while (steps) {
            const char = rightSide.charAt(index - 1);
            if (char === '(') {
                rightOpened++;
                if (steps === rightSide.length - 1) {
                    rightTermsStart += 2;
                }
            }
            if (char === ')') {
                rightClosed++;
                if (rightOpened === rightClosed || rightOpened === 0) {
                    rightTerms = rightSide.substring(rightTermsStart, index - 1);
                    break;
                }
            }
            if (steps === 1) {
                rightTerms = rightSide.substring(rightTermsStart, rightTermsStart + index);
                break;
            }
            index++;
            steps--;
        }

        // if terms are empty
        if (!leftTerms.match(/[a-zA-Z]/g) || !rightTerms.match(/[a-zA-Z]/g)) {
            highlight.push([nearStart, nearEnd]);
        }
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have an empty NEAR clause'
        )
    };
};
