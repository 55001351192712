import moment from 'moment';
import { Chart, ExportOptions } from 'regraph';
import { getRoom } from '../../../../../utils/variables';

type UseChartExportProps = {
    chartRef: React.RefObject<Chart<any, string | number>>
}

export const useChartExport = ({ chartRef }: UseChartExportProps) => {
    const room = getRoom();
    return {
        chartExport: (exportOptions: ExportOptions) => {
            if (!chartRef.current) return null;
            chartRef.current.export(exportOptions).then((image) => {
                image.download(`${room.project_name}_${moment().format('DD-MM-YYYY-h-mm')}`);
            });
        }
    };
};
