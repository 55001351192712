import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useSaveReport } from '../../../../../services/Report/saveReport';

export const SaveAsDraftButton = () => {
    const { report } = useSelector(state => state.createReport);
    const { saveReport, loading } = useSaveReport();
    const saveButtonDisabled = !report?.name || !report?.template || loading;
    return (
        <Button disabled={saveButtonDisabled}
            className="ml-1"
            onClick={() => saveReport({ ...report, draft: true })}
        >
            {loading ? <div className="text-primary px-4"><Spinner size="sm" /></div> : 'Save as draft'}
        </Button>
    );
};
