import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_USER } from '../../../graphql';
import { Loading } from '../../../components/Loading';
import { BackButton, ProfileImage } from '../../../components/Atoms';
import { capitalizeFirstLetter } from '../../../utils/text';
import { EditUserRole } from './EditUserRole';
import { RoomsList } from './RoomsList';
import { useModal } from '../../../context/modal/ModalComponent';
import { RemoveUserModal } from './RemoveUserModal';

export const UserDetails = ({ currentUser }) => {
    const { setModal, closeModal } = useModal();
    const match = useRouteMatch();

    const { loading, data } = useQuery(GET_USER, {
        variables: {
            uid: match.params.uid,
            truncateName: 20
        },
        fetchPolicy: 'cache-and-network'
    });

    const user = data ? data.user : false;
    const isAdmin = currentUser.role === 'ADMIN';

    if (loading) {
        return <Loading gif />;
    }
    return (
        <div>
            <div className="pt-0 mt-0">
                <BackButton pathName="/team" />
                {data && (
                    <div>
                        <Row className="align-items-stretch">
                            <Col xs={12} md={4}>
                                <h2 className="m-0 mb-4">Profile Details</h2>
                                <Card body className="bg-white p-3 mb-0">
                                    <div className="py-7 bg-primary-hover rounded mb-7 position-relative">
                                        <ProfileImage photoURL={user?.photoURL}
                                            size={150}
                                            className="position-absolute profile-user-image"
                                        />
                                    </div>
                                    <h2 className="text-center pt-2">
                                        {user.displayName}
                                    </h2>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        Role
                                        <p>
                                            {user?.role && (
                                                <span>
                                                    {capitalizeFirstLetter(user.role)}{user?.role === 'TEAM' && ' member'}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">Email <p>{user.email}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        Status:
                                        <p className="d-flex align-items-center">
                                            <span className={`d-flex mr-1 status-${user?.status || 'not-available'}`} />
                                            {user.status || 'Not available'}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        Last seen:
                                        <p> {user.lastSeen ? moment(new Date(user.lastSeen)).from(moment(new Date()))
                                            : 'No data available'}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        Member since: <p> {moment(new Date(user.createdAt)).format('DD MMM YYYY')}</p>
                                    </div>
                                    <hr />
                                    {(isAdmin && user.uid !== 'logically-intelligence') && (
                                        <div>
                                            <h5 className="font-weight-bold">Change Role</h5>
                                            <EditUserRole user={user} />
                                        </div>
                                    )}
                                    {isAdmin && <hr />}
                                    {isAdmin && (
                                        <div>
                                            <p className="p-0 btn mw-0 font-weight-bold text-primary"
                                                type="button"
                                                onClick={() => setModal({
                                                    component: (
                                                        <RemoveUserModal closeRemoveModal={closeModal}
                                                            currentUser={currentUser}
                                                            user={user}
                                                        />
                                                    )
                                                })}
                                            >
                                                Remove User
                                            </p>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                            <Col xs={12} md={8} className="d-flex flex-column">
                                <h2 className="m-0 mb-4">Assigned Situation Rooms</h2>
                                <Card body className="bg-white p-0 m-0 flex-1 position-relative">
                                    <RoomsList instance={user.instance.id} user={user.uid} />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
};
