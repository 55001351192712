import React, { useState } from 'react';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { getRoom } from '../../../../../utils/variables';
import { useQueryValidator } from '../../../../SituationRooms/RoomEditor/RoomPreview/useQueryValidator';
import { CoOccurence } from './CoOccurence';
import { KeywordFrequency } from './Frequency';
import { SignificantTerms } from './SignificantTerms';
import { TopEntityMentions } from './TopEntityMentions';

export const TermAnalysis = ({ preview = false, room: previewRoom = null, noAnnotations }) => {
    const [tab, setTab] = useState(false);
    const room = previewRoom || getRoom();

    const { data: validations } = useQueryValidator({
        room,
        validateIngestionQuery: false
    });

    const keywords = validations?.keywords || [];

    const coOccurenceTab = {
        key: 'co-occurrence',
        name: 'Co-occurrence',
        tooltip: 'Top 10 keywords that most commonly appear with the keywords added to this situation room.',
        component: <CoOccurence keywords={keywords} preview={preview} />
    };

    const allTabs = [
        {
            key: 'top-entity-mentions',
            name: 'Top entity mentions',
            tooltip: 'A high level graph of how the top 5 most mentioned entities are trending over the set period.',
            component: <TopEntityMentions preview={preview} noAnnotations={noAnnotations} />
        },
        {
            key: 'frequency',
            name: 'Frequency',
            tooltip: 'Top 10 keywords (with the biggest amount of matches) added to this situation room by you or your team.',
            component: <KeywordFrequency keywords={keywords} preview={preview} />
        },
        coOccurenceTab,
        {
            key: 'significant-terms',
            name: 'Significant terms',
            tooltip: `Top 10 unusual terms related to the content of your query. Adding
            them to your situation room might make the data more relevant.`,
            component: <SignificantTerms keywords={keywords} />
        }
    ];
    const currentTab = tab || (preview ? coOccurenceTab : allTabs[0]);
    return (
        <div>
            <h3 className="mt-0 mb-3">Term Analysis</h3>
            <div className="d-flex">
                <Dropdown value={currentTab} options={allTabs} onChange={setTab} className="mw-220" />
            </div>
            <hr className="my-3 mx-n3" />
            {allTabs.map(item => {
                const selected = currentTab.key === item.key;
                if (!selected) return null;
                return (
                    <div key={item.key}>
                        <p className="mb-3">
                            {item.tooltip}
                        </p>
                        {item.component}
                    </div>
                );
            })}
        </div>
    );
};
