import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { DELETE_USER } from '../../../graphql';
import { useUser } from '../../../context/user/UserContext';
import { useModal } from '../../../context/modal/ModalComponent';

export const UserDeleteConfirmationModal = () => {
    const { user } = useUser();
    const history = useHistory();
    const { closeModal } = useModal();
    const [deleteSelected, setDeleteSelected] = useState(false);
    const [modalInputText, setModalInputText] = useState('');
    const [confirmationError, setConfirmationError] = useState(false);
    const [deleteUser] = useMutation(DELETE_USER, {
        onCompleted: () => history.push(`${window.location.origin}/user-deleted`)
    });

    const handleDelete = () => {
        if (modalInputText !== 'DELETE') {
            return setConfirmationError(true);
        }

        setConfirmationError(false);
        return deleteUser({
            variables: {
                uid: user?.uid,
                id: user?.id
            }
        });
    };

    return (
        <div>
            <h2 className="m-0">Delete account</h2>
            <hr />
            <p>Your account will be permanently deleted.</p>
            {deleteSelected && (
                <div>
                    <p>Type DELETE to confirm.</p>
                    <Input type="text" data-testid="delete-input" onChange={(e) => setModalInputText(e.target.value)} />
                    {confirmationError && (
                        <p className="text-danger">Either type &ldquo;DELETE&rdquo; or press &ldquo;Cancel&rdquo;</p>
                    )}
                </div>
            )}
            <hr />
            {!deleteSelected && (
                <>
                    <Button color="primary" onClick={closeModal}>Keep my account</Button>
                    <Button className="ml-3" onClick={() => setDeleteSelected(true)}>Delete my account</Button>
                </>
            )}
            {deleteSelected && (
                <>
                    <Button color="primary" onClick={handleDelete}>Delete my account</Button>
                    <Button className="ml-3" onClick={closeModal}>Cancel</Button>
                </>
            )}
        </div>
    );
};
