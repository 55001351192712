import React from 'react';
import { Link } from 'react-router-dom';

type CardWithLinkProps = {
    title: string,
    subtitle: string,
    link: string
}
export const CardWithLink = ({ title, subtitle, link }: CardWithLinkProps) => (
    <div className="p-3 bg-white">
        <Link to={link}>
            <h3 className="text-primary m-0">{title}</h3>
        </Link>
        <hr className="my-2" />
        {subtitle}
        <Link to={link}>View details</Link>
    </div>
);
