import React from 'react';
import { Chart } from 'regraph';
import { ContentDetails, UserDetails } from '../modals';
import { useModal } from '../../../../../context/modal/ModalComponent';

type UseChartClickProps = {
    items: Chart.Props['items']
}

export const useChartClick = ({ items }: UseChartClickProps): { handleClick: Chart.onClickHandler } => {
    const { setModal } = useModal();

    return {
        handleClick: ({ id }) => {
            if (!items) return null;
            if (!id || !items[id] || !items[id].data) return null;
            const itemData = items[id].data;

            if (itemData.type === 'content') {
                setModal({
                    header: 'Content details',
                    component: <ContentDetails id={itemData.id} />,
                });
            }
            if (itemData.type === 'author') {
                setModal({
                    header: 'User details',
                    component: <UserDetails from={itemData.id} author={itemData.author} contentType={itemData.contentType} />,
                });
            }
        }
    };
};
