import React from 'react';
import { Check } from 'react-feather';

export const Template = ({ template, selected, onClick }) => (
    <div key={template.id}
        className="template-thumb-container"
        onClick={onClick}
    >
        <div className="rounded border-dashed template-thumb position-relative"
            style={{
                backgroundImage: template.backgroundImage ? `url("${template.backgroundImage}")` : 'none',
                backgroundSize: 'cover'
            }}
        >
            {selected?.id === template.id && (
                <div className="bottom-right bg-success d-flex align-items-center justify-content-center
rounded-circle position-absolute"
                >
                    <Check size={16} color="#fff" />
                </div>
            )}
        </div>
        <p className=" sentence-format mb-0 mt-2">{template.name}
        </p>
    </div>
);
