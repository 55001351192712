import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CLAIMS } from '../../../graphql';
import { Loading } from '../../../components/Loading';
import { FactsTable } from './FactsTable';

export const FactChecksContainer = ({ bread, project, roomDetails, user }) => {
    const { data: getClaims, loading } = useQuery(GET_CLAIMS, {
        variables: {
            userId: user.id,
            projectId: roomDetails.situationRoom.project_id
        },
        fetchPolicy: 'no-cache',
    });
    const claimDocs = getClaims?.getClaims?.entries[0]?.claim_details?.claims_docs;

    if (loading) {
        return <Loading gif relative height={600} />;
    }
    return (
        <div className="page-padding">
            {bread}
            <p className="mt-4">Fact-checking is a process that seeks to verify sometimes factual information,
                in order to promote the veracity and correctness of reporting.
                You can fact check threats that were flagged as misinformation.
            </p>
            <h4 className="my-4">Your requests</h4>
            <FactsTable project={project} claimDocs={claimDocs} />
        </div>
    );
};
