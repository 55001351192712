import React from 'react';
import { BackButton } from '../../../components/Atoms/BackButton';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { AllUsers } from './AllUsers';

export const Users = () => (
    <div className="page-padding">
        <BreadCrumbs items={[{ title: 'Admin home', href: '/admin' }, { title: 'Users' }]} />
        <BackButton pathName="/admin" />
        <AllUsers />
    </div>
);
