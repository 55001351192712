import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ContentTableContentType } from './index';
import { getRoom } from '../../../utils/variables';
import { getHighlightedContent } from '../../../utils/getHighlightedContent';
import { getCtype, truncateString } from '../../../utils/text';
import { getPostedByName } from '../../../utils/getPostedByName';
import { SourceIconLabel } from '../../SourceIconLabel';
import { convertToDate, formatDate, formatDateLocal } from '../../../utils/getTimeFrom';
import { ExternalLinkButton } from '../../LinkModal/ExternalLinkButton';
import { TextTooltip } from '../../ToolTip/TextTooltip';
import { TranslateButton } from '../../Atoms/TranslateButton';
import { useQueryExtracts } from '../../../services/Content/getQueryExtracts';
import { useTranslateMultipleText } from '../../../services/google/translate';
import { TranslatedFromLanguage } from '../../../utils/TranslatedFromLanguage';
import { ContentId } from '../../../pages/Room/AIClustering/types';
import { DuplicatedDocsModal } from '../../../pages/Room/AIClustering/DuplicatedDocsModal';
import { useModal } from '../../../context/modal/ModalComponent';
import { highlightedKeywordsTooltipText, highlightedKeywordsTooltipTextSearch } from '../../../utils/text/getFoundText';
import { RootState } from '../../../store';

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type ContentViewProps = {
    content: ContentTableContentType
    textLimit: number
    onReadMoreClick: () => void
    onReadLessClick: () => void
    clusterData?: ContentId[],
    isOverview?: boolean
}

export const Content = ({
    content,
    textLimit,
    onReadMoreClick,
    onReadLessClick,
    clusterData,
    isOverview = false
}: ContentViewProps) => {
    const room = getRoom();
    const { setModal } = useModal();
    const location = useLocation();
    const [showLanguages, setShowLanguages] = useState(false);
    const ctype = getCtype(content?.contentType);
    const isKeywordSearched = useSelector((state: RootState) => state.filters.filters.query);
    const { highlightKeywords } = useSelector((state: {roomPreviewKeywords: RoomPreviewKeywords}) => state.roomPreviewKeywords);
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;
    let date = formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm z');
    const utcDate = moment.utc(convertToDate(content.pubdatetime)).format('DD/MM/YYYY @ HH:mm:ss z');
    const duplicatedDoc = clusterData?.filter(cluster => cluster.id === content.id)[0]?.duplicateIds || [];
    const contentText = ((content.body && content.body !== 'undefined contents') ? content.body : content.text).replace(/(?:https?):\/\/[\n\S]+./gim, '');
    const passKeywords = location.pathname.includes('create-room') || location.pathname.includes('edit-room')
        || location.pathname.includes('edit-draft-room');
    const { highlightedText } = useQueryExtracts({
        url: content.url,
        text: contentText,
        keywordsToHighlight: passKeywords ? highlightKeywords : []
    });

    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: highlightedText
    });
    const translation = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        getTranslation();
        setShowLanguages(!showLanguages);
    };

    const openDuplictedDocsModal = () => setModal({
        component: <DuplicatedDocsModal contentIds={duplicatedDoc} />,
        goToPreviousOnClose: true,
        size: 'xl'
    });
    const finalText = translated.length ? translated[0].translations[0] : highlightedText;
    if (dateInLocalTimeZone) {
        date = formatDateLocal(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z');
    }
    const postedBy = getPostedByName(content.from, content.displayName);

    return (
        <div>
            <p className="my-0">
                <SourceIconLabel data={{ contentType: content.contentType }} className="d-inline" />
                {getPostedByName(content.from, content.displayName)} via
                <span className={`ctype ${postedBy.length > 25 ? 'd-block' : ''}`}>
                    {ctype?.name}
                </span>
                {isOverview ? <p className="mt-1">{`${utcDate}`} {` | ${date}`}</p> : <>{` | ${utcDate}`} {` | ${date}`}</> }
                {clusterData ? (
                    <> | Reposts:
                        {duplicatedDoc?.length > 0 ? (
                            <span className="text-primary px-1 cursor-pointer"
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); openDuplictedDocsModal(); }}
                            >
                                {duplicatedDoc.length}
                            </span>
                        ) : <span> {duplicatedDoc.length}</span>}
                    </>
                )
                    : null}
            </p>
            {content.url && (
                <ExternalLinkButton url={content.url}>
                    <p className="url-hover cursor-pointer mb-1">{content.url}</p>
                </ExternalLinkButton>
            )}
            <div className="d-flex align-items-ceter justify-content-between bg-lightgray py-2 px-2 m-0">
                <p className="highlight-em dont-break-out">
                    {showLanguages && !translateLoading && (
                        <TranslatedFromLanguage languageVerbose={content?.langVerbose}
                            detectedLangs={content?.detectedLangs}
                        />
                    )}
                    {getHighlightedContent(truncateString(finalText, textLimit), isKeywordSearched
                        ? highlightedKeywordsTooltipTextSearch : highlightedKeywordsTooltipText)}&nbsp;
                    {(finalText.length > textLimit) ? (
                        <Button color="link" className="p-0 mw-0" onClick={onReadMoreClick}>Read more</Button>
                    ) : null}
                    {(textLimit > 400) ? (
                        <Button color="link" className="p-0 mw-0" onClick={onReadLessClick}>Read less</Button>
                    ) : null}
                </p>
                <div>
                    <TextTooltip placement="top"
                        title="Translate"
                        id={`content-${content.id}-translate`}
                        withIcon
                    >
                        <TranslateButton className="d-inline-block pl-4"
                            onClick={translation}
                            lang={content.langVerbose}
                            detectedLangs={content.detectedLangs}
                            loading={translateLoading}
                            showText={false}
                        />
                    </TextTooltip>
                </div>
            </div>
        </div>
    );
};
