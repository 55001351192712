import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

type SmallPaginationProps = {
    total: number;
    itemsPerPage: number;
    page: number;
    setPage: (e: number) => void
}

export const SmallPagination = ({ total, itemsPerPage, page, setPage }: SmallPaginationProps) => {
    if (total < itemsPerPage) {
        return null;
    }
    const ofMax = (page + 1) * itemsPerPage;
    const maxPages = Math.floor(total / itemsPerPage) - (total % itemsPerPage === 0 ? 1 : 0);
    return (
        <div className="d-flex align-items-center justify-content-end" data-testid="small-pagination">
            <p className="m-0">
                {page * itemsPerPage + 1}-{ofMax > total ? total : ofMax} of {total}
            </p>
            <button className="mx-1 p-0 border-0 bg-transparent"
                type="button"
                onClick={() => {
                    if (page !== 0) {
                        setPage(page - 1);
                    }
                }}
            >
                <ChevronLeft color={page !== 0 ? 'currentColor' : '#C4C4C4'} />
            </button>
            <button className="p-0 border-0 bg-transparent"
                type="button"
                onClick={() => {
                    if (page !== maxPages) {
                        setPage(page + 1);
                    }
                }}
            >
                <ChevronRight color={page !== maxPages ? 'currentColor' : '#C4C4C4'} />
            </button>
        </div>
    );
};
