import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useRemoveRestoreNarratives } from '../../../../../services/Clusters/useRemoveRestoreNarratives';

type ClusterIdAndStatusType = {
    id: string
    status?: string
    subClustersMappedId?: string[]
}

type RemoveClustersConfirmationProps = {
    clusterIdAndStatus: ClusterIdAndStatusType[]
    onCompleted: () => void
}

export const RemoveClustersConfirmation = ({ clusterIdAndStatus, onCompleted }: RemoveClustersConfirmationProps) => {
    const { closeModal } = useModal();
    const history = useHistory();
    const clusterIds = clusterIdAndStatus?.map(({ id }) => id);
    const { handleRemoveRestoreClusters, restoreRemoveLoading } = useRemoveRestoreNarratives();
    const handleRemoveNarrativeTheme = () => {
        // This ensures we are removing it permanently
        if (clusterIdAndStatus?.[0]?.status === 'deleted' && history.location?.pathname?.includes('settings')) {
            handleRemoveRestoreClusters(clusterIds, 'permanentlyRemove', 'theme');
        } else {
            handleRemoveRestoreClusters(clusterIds, 'remove', 'theme');
        }
        onCompleted();
    };
    return (
        <>
            <h3>Are you sure that you want to remove these narrative theme{clusterIds.length > 1 ? 's' : ''}?</h3>
            <hr />
            <p>The selected narrative theme{clusterIds.length > 1 ? 's' : ''} will be fully removed from this situation room.</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={restoreRemoveLoading}
                color="danger"
                onClick={handleRemoveNarrativeTheme}
            >Remove
            </Button>
        </>
    );
};
