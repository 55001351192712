import React, { useRef } from 'react';
import { ColorPickerIcon } from '../../../../../../../assets/svg/color-picker-icon';

export const ColorInput = ({ onChange, value }) => {
    const inputRef = useRef(null);
    const handleChange = (val) => {
        onChange(val);
    };
    return (
        <label role="button"
            className="py-11 px-1 rounded mr-2 mb-0 bg-light"
            onMouseDown={(e) => { e.preventDefault(); inputRef.current.click(); }}
        >
            <ColorPickerIcon />
            <input type="color"
                className="invisible p-0 h-0 w-0"
                value={value}
                ref={inputRef}
                onChange={(e) => handleChange(e.target.value)}
            />
        </label>
    );
};
