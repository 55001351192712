import React from 'react';
import { getSentimentIcon } from '../../../../components/Organisms/ContentTableView/SentimentCard';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { TooltipItem } from '../../../../components/ToolTip';
import { Spinner } from '../../../../assets/svg/Spinner';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { getRoom } from '../../../../utils/variables';

export const SentimentPanel = ({ sentiment, hasEnhancements }) => {
    const room = getRoom();
    const newEtlEnabled = room.instance?.plan?.others?.newEtl;

    return (
        <div className="bg-white mb-3 p-2 rounded-sm">
            <div className="d-flex align-items-center">
                <h3 className="m-0">Sentiment</h3>
                <TooltipItem id="sentimentTooltip"
                    content="Sentiment is how you platform perceives this content to be forwards the subject that it’s discussing"
                    placement="left"
                />
            </div>
            {(hasEnhancements || !newEtlEnabled) ? (
                <div>
                    {(sentiment !== 'positive' && sentiment !== 'neutral' && sentiment !== 'negative') ? (
                        <div className="bg-verylight rounded p-2 mt-1">
                            {getSentimentIcon(sentiment)}
                            <strong>Unknown</strong>
                        </div>
                    ) : (
                        <div className="bg-verylight rounded p-2 mt-1">
                            {getSentimentIcon(sentiment)}
                            <strong>{capitalizeFirstLetter(sentiment)}</strong>
                        </div>
                    )}
                </div>
            ) : (
                <div className="mt-1 d-flex bg-verylight p-2">
                    <Spinner />
                    <TooltipWrapper id="sentimentNotAvailableTooltip"
                        placement="top-start"
                        className="ml-1"
                        tooltipText="Sentiment information will be available once the enhancement is completed for this content."
                    >
                        <p className="mb-0 widget-text-style">Not available</p>
                    </TooltipWrapper>
                </div>
            )}
        </div>
    ); };
