import { useQuery } from '@apollo/client';
import { useContentFilters } from '../../pages/Room/useFilters';
import { gql } from '../../generated';

type UseTotalThreatsAndMatchesProps = {
    dateFilter?: { startDate?: number; endDate?: number };
    showFull?: boolean;
    maxItems?: string;
}

export const useTotalThreatsAndMatches = ({ dateFilter, showFull, maxItems }: UseTotalThreatsAndMatchesProps) => {
    const defaultFilters = (dateFilter?.startDate || dateFilter?.endDate) ? { date: dateFilter } : {};
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters });
    const ret = useQuery(TOTAL_MATCHES_AND_THREATS_OVER_TIME, {
        variables: {
            ...filters,
            interval: showFull ? '1d' : (maxItems || '20')
        }
    });

    return {
        ...ret,
        data: ret.data?.totalMatchesAndThreatsOverTime || {
            matches: { data: [], count: 0 },
            threats: { data: [], count: 0 },
            interval: '1d'
        }
    };
};

export const TOTAL_MATCHES_AND_THREATS_OVER_TIME = gql(`
    query totalMatchesAndThreatsOverTime(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $interval: String
    ) {
        totalMatchesAndThreatsOverTime(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            interval: $interval
        ) {
            matches {
                data {
                    key
                    count
                }
                count
            }
            threats {
                data {
                    key
                    count
                }
                count
            }
            interval
        }
    }
`);
