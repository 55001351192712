import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../../useFilters';

export const useKeywordFrequency = ({ keywords = [] }) => {
    const filters = useContentFilters({ useGlobalFilters: true });
    const ret = useQuery(KEYWORD_FREQUENCY, {
        variables: {
            ...filters,
            keywords
        },
        skip: !keywords.length
    });

    return {
        ...ret,
        data: [...(ret.data?.keywordFrequency || [])]
            .sort((a, b) => b.count - a.count)
            .slice(0, 10)
            .map(item => ({ ...item, value: item.keyword })),
    };
};

export const KEYWORD_FREQUENCY = gql`
    query keywordFrequency(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $keywords: [String]
    ) {
        keywordFrequency(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            keywords: $keywords
        ) {
            keyword
            count
            threats
        }
    }
`;
