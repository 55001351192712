import React from 'react';
import { NestedCheckbox } from '../../../Filters/NestedCheckbox';
import { getCtype } from '../../../../utils/text';

export const SourceFinder = ({
    sourceFilters,
    sourcesWithDomains,
    setSourcesWithDomains,
    shownDetails,
    setShownDetails,
    setCollapseAll
}) =>
    (
        <NestedCheckbox values={sourcesWithDomains.map(source => ({ ...source, subCategories: source.domains }))}
            setValues={(newSources) =>
                setSourcesWithDomains(newSources.map(source => ({ key: source.key, domains: source.subCategories })))}
            value="source"
            filters={sourceFilters.map(filter => ({
                ...filter,
                label: getCtype(filter.key).name,
                subCategories: filter.domains.map(domain => ({ key: domain, label: domain })),
                subCategoriesCount: filter.domainCount
            }))}
            shownDetails={shownDetails}
            setShownDetails={setShownDetails}
            setCollapseAll={setCollapseAll}
        />
    );
