import { useQuery } from '@apollo/client';
import { getRoom } from '../../utils/variables';
import { gql } from '../../generated';

export const useClusteringTimeEstimate = ({ refresh }: { refresh?: boolean } = {}) => {
    const room = getRoom();
    const projectId = room?.project_id;
    const { data, loading } = useQuery(GET_CLUSTERING_TIME_ESTIMATE, {
        variables: {
            projectId,
            refresh
        },
        skip: !projectId
    });

    return {
        estimatedTime: data?.getClusteringTimeEstimate || 0,
        loading
    };
};

const GET_CLUSTERING_TIME_ESTIMATE = gql(
    `query getClusteringTimeEstimate($projectId: String!, $refresh: Boolean) {
        getClusteringTimeEstimate(projectId: $projectId, refresh: $refresh)
    }`
);
