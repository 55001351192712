import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getReportPages } from './index';

export const ExportAsPPT = () => {
    const { report } = useSelector(state => state.createReport);
    const [loading, setLoading] = useState(false);
    const pagesToExport = getReportPages(report);

    if (!report?.name || !report?.template) return null;

    const exportToPpt = () => {
        setLoading(true);
        const pptx = new PptxGenJS();
        const allPages = pagesToExport.map((page) => new Promise((res) => {
            const element = document.getElementById(`pdf-page-${page.id}`);
            html2canvas(element, { scale: 2.5 }).then(canvas => res({ imgData: canvas.toDataURL() }));
        }));

        Promise.all(allPages).then(values => {
            for (let i = 0; i < values.length; i++) {
                const slide = pptx.addSlide();
                const { imgData } = values[i];
                slide.addImage({ data: imgData, w: '100%', h: '100%' });
            }
            pptx.writeFile({ fileName: `${report.name}` });
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Button color="primary" onClick={exportToPpt} disabled={loading}>
            {loading ? <div className="text-primary px-4"><Spinner size="sm" data-testid="export-report" /></div> : 'Export as PPT'}
        </Button>
    );
};
