import { useMutation } from '@apollo/client';
import React from 'react';
import { Button } from 'reactstrap';
import { X } from 'react-feather';
import { Loading } from '../../../../components/Loading';
import { UPDATE_SITUATION_ROOM } from '../../../../graphql';
import { getToast } from '../../../../utils/getToast';
import { color } from '../../../../utils/getColors';

export const RemoveUserModal = ({ room, closeModal, user, refetch }) => {
    const toast = getToast();
    const [updateSituationRoom, { loading }] = useMutation(UPDATE_SITUATION_ROOM, {
        onCompleted: () => {
            toast.success('User removed');
            closeModal();
            refetch();
        }
    });
    if (loading) {
        return <Loading height={200} relative />;
    }
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <h2>Remove user</h2>
                <X color={color.darkblue[700]} size={20} role="button" onClick={() => closeModal()} />
            </div>
            <hr />
            <p>Are you sure you want to remove {user.displayName || user.email} from &quot;{room.project_name}&quot; team?</p>
            <hr />
            <Button onClick={() => closeModal()} color="secondary" className="mr-2">Cancel</Button>
            <Button color="danger"
                onClick={() => updateSituationRoom({
                    variables: {
                        id: room.id,
                        removedUsers: [...room.removedusers, user.id]
                    }
                })}
            >Yes, Remove
            </Button>
        </div>
    );
};
