import React from 'react';
import { Button, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdatedReportPages } from '../../getUpdatedReportPages';
import { CustomNarrativeChart } from '../CustomNarrativeChart';
import { setReportAction } from '../../../../../../../../redux/reports';

export const CustomNarrativesTableInner = ({ count, data }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const selectedCustomNarratives = report.pages.filter(a => a.type === 'customNarratives').map(a => a.blocks).flat();
    const toggleCustomNarrative = (narrative, selected) => {
        const pages = getUpdatedReportPages(
            report,
            selected ? selectedCustomNarratives.filter(a => a.id !== narrative.id) : [...selectedCustomNarratives, narrative],
            'customNarratives'
        );
        dispatch(setReportAction({
            ...report,
            pages
        }));
    };
    return (
        <Table>
            <thead>
                <tr>
                    <td>Narratives ({count})</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
                {data.map(customNarrative => {
                    const selected = selectedCustomNarratives.find(a => a.id === customNarrative.id);
                    return (
                        <tr key={customNarrative.id}>
                            <td>
                                {customNarrative.narrative_title}
                                <CustomNarrativeChart data={customNarrative} />
                            </td>
                            <td className="no-white-space d-flex flex-column">
                                {!selected
                                    ? (
                                        <Button color="primary" onClick={() => toggleCustomNarrative(customNarrative, selected)}>
                                            Add to report
                                        </Button>
                                    )
                                    : (
                                        <Button color="danger"
                                            onClick={() => toggleCustomNarrative(customNarrative, selected)}
                                        >
                                            Remove from report
                                        </Button>
                                    ) }
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
