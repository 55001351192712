import React from 'react';

export const Slides = ({
    slide,
    currentPage,
    logicallyLogo,
    pageNumber,
    mainTitle,
    subtitle,
    pageTitle,
    bodyText
}) => (
    <div>
        {slide === 0 && (
            <div className={`pp-slide ${currentPage === 0 ? '' : 'd-none'}`} id="ppSlide-0">
                <div className="d-flex justify-content-between">
                    <div className={`px-5 py-4 border-dashed ${logicallyLogo ? '' : 'invisible'}`}>
                        <p className={`m-0 ${(mainTitle.color === 1 ? 'text-white' : 'text-secondary')}`}>
                            Upload logo
                        </p>
                    </div>
                    <div className="px-5 py-4 border-dashed position-relative">
                        <p className={`m-0 ${(mainTitle.color === 1 ? 'text-white' : 'text-secondary')}`}>
                            Upload logo
                        </p>
                    </div>
                </div>
                <div className="px-4 py-3 border-dashed main-title">
                    <h1 className={`m-0 font-size-${mainTitle.fontSize} 
                        font-weight-${mainTitle.bold ? 'bold' : 'normal'} ${(mainTitle.color === 1 ? 'text-white' : 'text-secondary')}`}
                    >
                        Title
                    </h1>
                </div>

                <div className={`px-4 py-3 border-dashed sub-title 
                    ${mainTitle.fontSize > 30 ? ' mt-3' : ''}`}
                >

                    <h3 className={`m-0 font-size-${subtitle.fontSize} 
                        font-weight-${subtitle.bold ? 'bold' : 'normal'} ${(subtitle.color === 1 ? 'text-white' : 'text-secondary')}`}
                    >
                        Subtitle
                    </h3>
                </div>
                <div className="px-4 py-3 border-dashed date-field">
                    <h3 className={`m-0 font-size-${subtitle.fontSize} 
                        font-weight-${subtitle.bold ? 'bold' : 'normal'} ${(subtitle.color === 1 ? 'text-white' : 'text-secondary')}`}
                    >
                        Footer
                    </h3>
                </div>
                <div className={`${pageNumber ? 'report-page-number p-2' : 'invisible'}`}>
                    <div className="border-dashed p-2">
                        1
                    </div>
                </div>
            </div>
        )}
        {slide === 1 && (
            <div className={`pp-slide flex-column ${currentPage === 1 ? 'd-flex' : 'd-none'}`} id="ppSlide-1">
                <div className="px-4 py-3 border-dashed page-title">
                    <h1 className={`m-0 font-size-${pageTitle.fontSize} 
                        font-weight-${pageTitle.bold ? 'bold' : 'normal'} ${(pageTitle.color === 1 ? 'text-white' : 'text-secondary')}`}
                    >
                        Page title goes here
                    </h1>
                </div>
                <div className="px-4 py-3 border-dashed body-text h-100 mt-4 flex-1">
                    <p className={`m-0 font-size-${bodyText.fontSize} 
                        font-weight-${bodyText.bold ? 'bold' : 'normal'} ${(bodyText.color === 1 ? 'text-white' : 'text-secondary')}`}
                    >
                        Body text goes here
                    </p>
                </div>
            </div>
        )}
    </div>
);
