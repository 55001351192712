import { useQuery } from '@apollo/client';
import { GOOGLE_TRENDS_QUERY } from '../../graphql';

export const useRelatedQueries = ({ query, skip }) => {
    const { loading, data } = useQuery(GOOGLE_TRENDS_QUERY, {
        variables: {
            keyword: query
        },
        skip
    });
    const queries = data?.googleTrendsQuery || [];
    return {
        loading,
        data: queries.length > 10 ? queries.slice(0, 10) : queries
    };
};
