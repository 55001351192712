import React from 'react';
import { ExportingMimeTypeValue, Options } from 'highcharts';
import { merge } from 'lodash';
import { ResponsiveChartRef } from './ResponsiveChart';

type UseChartExportProps = {
    chartRef: React.RefObject<ResponsiveChartRef>
    fileName: string
}

export type ExportChartProps = {
    type?: ExportingMimeTypeValue
    chartOptions?: Options
}

export const useChartExport = ({
    chartRef,
    fileName
}: UseChartExportProps) => ({
    type = 'image/jpeg',
    chartOptions = {}
}: ExportChartProps = {}) => {
    if (!chartRef.current) return;
    const chart = chartRef.current.getHighchartInstance();
    chart.exportChart({
        type,
        filename: fileName
    }, merge(chartOptions, {
        chart: {
            backgroundColor: 'white',
            spacing: [20, 20, 20, 20]
        }
    }));
};
