import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { X } from 'react-feather';
import { Button } from 'reactstrap';
import { GET_CONTENT_LABELS } from '.';
import { LabelsDataProps } from './InputLabelName';
import { getToast } from '../../../utils/getToast';

type DeleteLabelProps = {
    labelsData: LabelsDataProps[],
    setDeleteLabels: (value: boolean) => void,
    setOpenDeletePopover: (e: boolean) => void,
    labelIds: string[],
    setDeleteLabelIds: (e: []) => void
}
export const DeleteLabels = ({ labelsData, setDeleteLabels, setOpenDeletePopover, labelIds, setDeleteLabelIds }: DeleteLabelProps) => {
    const toast = getToast();
    const [deleteContentLabels, { loading }] = useMutation(DELETE_CONTENT_LABELS, {
        refetchQueries: [GET_CONTENT_LABELS],
        onCompleted: () => {
            toast.success(`${labelIds.length} label${labelIds.length > 1 ? 's' : ''} successfully deleted `);
            setOpenDeletePopover(false);
            setDeleteLabelIds([]);
            setDeleteLabels(false);
        }
    });

    const handleDeleteContentLabels = () => {
        deleteContentLabels({
            variables: {
                labelIds
            }
        });
    };

    const contentCount = () => {
        const contentList: string[] = [];
        let noContent = false;
        labelsData.forEach((a) => {
            if (a.contentIds.length > 0) {
                contentList.push(...a.contentIds);
            } else {
                noContent = true;
            }
        });
        if (!noContent) {
            return Array.from(new Set(contentList));
        }
        return [];
    };

    return (
        <div className="p-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h3>Delete Labels?</h3>

                <X color="#050E2B"
                    size={24}
                    data-testid="close-modal"
                    className="cursor-pointer"
                    stroke="#050E2B"
                    onClick={() => setOpenDeletePopover(false)}
                />
            </div>
            <p>You are about to delete {labelIds.length} label{labelIds.length > 1 && 's'},
                are you sure? {contentCount().length > 0
                && `${labelIds.length > 1
                    ? 'These labels are' : 'This label is'} attached to
                     ${contentCount().length} content item${contentCount().length > 1 ? 's' : ''}. `}
                This action cannot be undone.
            </p>
            <div className="mt-2">
                <Button color="secondary" onClick={() => setOpenDeletePopover(false)}>Cancel</Button>
                <Button color="danger"
                    className="ml-2"
                    data-testid="delete-label-content"
                    onClick={handleDeleteContentLabels}
                    disabled={loading}
                >{`Delete label${labelIds.length > 1 ? 's' : ''}`}
                </Button>
            </div>
        </div>
    );
};

export const DELETE_CONTENT_LABELS = gql`
    mutation deleteContentLabels($labelIds: [String]!) {
        deleteContentLabels(labelIds: $labelIds) {
            done
            message
        }
    }
`;
