import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../../useFilters';

export const useSignificantTerms = ({ keywords = [] }) => {
    const filters = useContentFilters({ useGlobalFilters: true });
    const ret = useQuery(SIGNIFICANT_TERMS, {
        variables: filters,
    });

    return {
        ...ret,
        data: ret.data?.significantTerms
            ?.filter(item => !keywords.find(val => val.toLowerCase() === item.value.toLowerCase()))
            ?.slice(0, 10) || [],
    };
};

export const SIGNIFICANT_TERMS = gql`
    query significantTerms(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        significantTerms(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
        ) {
            value
            count
            matchPercentage
            type
        }
    }
`;
