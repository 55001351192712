import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useRemoveContent } from '../../../../../components/CommonModals/useRemoveContent';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { getToast } from '../../../../../utils/getToast';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useUpdateThreatSignals } from '../../../../../components/Organisms/ContentTableView/ThreatSignals/useUpdateThreatSignals';
import { AddedSignal } from '../../../../../components/Organisms/ContentTableView/ThreatSignals/ManageThreatsModal';

type ResetModalProps ={
    contentIds: string[],
    threatsReset?: boolean,
    clearSelection: () => void,
    addedSignals?: AddedSignal[],
    projectId: string
}

export const ResetThreatsModal = ({ contentIds, threatsReset = false, clearSelection, addedSignals = [], projectId }: ResetModalProps) => {
    const { closeModal } = useModal();
    const toast = getToast();
    const { updateThreatSignals, loading: resetLoading } = useUpdateThreatSignals({ clearSelection,
        addedSignals: addedSignals.map((a) => a.signalKey),
        ids: contentIds,
        threatsReset });
    const { removeContent, loading } = useRemoveContent({
        type: CONTENT_TYPE.MATCH,
        action: 'reset',
        onCompleted: () => {
            toast.success('This content has been marked as ‘threatening’.');
            closeModal();
        },
        ids: contentIds
    });
    const handleReset = () => {
        if (threatsReset) {
            return updateThreatSignals({
                variables: {
                    projectId,
                    contentIds,
                    addedThreatSignals: [],
                    removedThreatSignals: [],
                    reset: true
                }
            });
        }
        return removeContent(contentIds.map((a) => ({ id: a })));
    };
    return (
        <>
            <p>Resetting threats will restore the content(s) back to it’s original state which will override any changes made.</p>
            <hr />
            <div>
                <Button color="secondary" className="mr-2" disabled={loading || resetLoading} onClick={closeModal}>Back</Button>
                <Button color="danger" disabled={loading || resetLoading} onClick={handleReset}>{(loading || resetLoading) ? (
                    <Spinner className="ml-1"
                        size="sm"
                        color="white"
                    />
                ) : 'Reset' }
                </Button>
            </div>
        </>
    );
};
