import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { getRoom, getUser } from '../../utils/variables';
import { getToast } from '../../utils/getToast';
import { REMOVE_RESTORE_CLUSTERS } from '../../graphql';
import { useModal } from '../../context/modal/ModalComponent';

type ClusterType = 'theme' | 'narratives';

interface Type {
    remove: string,
    restore: string,
    permanentlyRemove: string
}

const clusterStatus: Type = {
    remove: 'remove',
    restore: 'restore',
    permanentlyRemove: 'perm_remove'
};

export const useRemoveRestoreNarratives = () => {
    const room = getRoom();
    const user = getUser();
    const toast = getToast();
    const [clusterType, setCusterType] = useState('');
    const [checkStatus, setCheckStatus] = useState('');
    const { closeModal } = useModal();
    const [removeRestoreCluster, { loading: restoreRemoveLoading, data }] = useMutation(REMOVE_RESTORE_CLUSTERS, {
        refetchQueries: 'active',
        onCompleted: () => {
            if (data.removeRestoreClusters) {
                toast.success(`
                    Narrative${clusterType === 'theme' ? ' themes' : 's'} ${checkStatus
                        === clusterStatus.restore ? 'restored' : 'removed'} successfully`);
                closeModal();
            }
            else {
                toast.error(`Failed to remove narrative${clusterType === 'theme' ? ' themes' : 's'}`);
                closeModal();
            }
        },
        onError: () => {
            toast.error(`Failed to remove narrative${clusterType === 'theme' ? ' themes' : 's'}`);
            closeModal();
        },
    });
    const handleRemoveRestoreClusters = (subClustersMappedId: string[], status: keyof Type, type: ClusterType) => {
        setCusterType(type);
        setCheckStatus(status);
        removeRestoreCluster({
            variables: {
                projectId: room.project_id,
                subClusterIds: subClustersMappedId,
                type: clusterStatus[status],
                userName: user?.displayName || `${user?.firstName}" "${user?.lastName}`,
                isNarrativeTheme: type === 'theme'
            },
            update: (cache) => {
                for (const id of subClustersMappedId) {
                    cache.modify({
                        id: cache.identify({ __typename: 'SubCluster', id }),
                        fields: {
                            status: () => clusterStatus[status]
                        }
                    });
                }
            },
        });
    };

    return {
        handleRemoveRestoreClusters,
        restoreRemoveLoading
    };
};
