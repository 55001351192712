import moment from 'moment';
import React from 'react';
import { Edit, MoreVertical, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { useUser } from '../../context/user/UserContext';
import { DeleteArchiveModal } from '../Room/SettingsNew/Modals';
import { UnarchiveModal } from './UnarchiveModal';
import { RoomMetrics } from './RoomMetrics';
import { color } from '../../utils/getColors';
import { Archived } from '../../assets/svg/archived';

export const SituationRoomRow = ({ room, tab }) => {
    const { setModal, closeModal } = useModal();
    const handleModal = (type) => {
        const modalHeader = `Are you sure you want to ${type} ${room.status === 'Draft' ? 'draft' : 'this situation room'}`;
        setModal({
            header: modalHeader,
            component: <DeleteArchiveModal closeModal={closeModal}
                type={type}
                draft={status === 'Draft'}
                data={{ id: room.id, title: room.project_name, draftId: room.draftId, situationRoomId: room.situationRoomId }}
            />,
        });
    };
    const restoreRoomModal = () => {
        setModal({
            header: (
                <span>
                    <Archived />
                    <span className="d-block mt-3">This room is archived</span>
                </span>
            ),
            component: <UnarchiveModal id={room.id} closeModal={closeModal} small />
        });
    };
    const { user, isAdmin } = useUser();
    let status = room.archived ? 'Archived' : 'Active';
    if (room.status === 'Draft') { status = 'Draft'; }

    const lastViewed = user.situationRoomViewList?.find(({ situationRoomid }) => situationRoomid === room.id)?.lastViewed;
    return (
        <>
            <td className="p-0">
                {status === 'Archived'
                    ? (
                        <Button color="link"
                            className="text-left"
                            onClick={() => restoreRoomModal()}
                        >
                            <span className="mr-1">{room.project_name}</span>
                            <RecentlyArchivedLabel room={room} />
                        </Button>
                    )
                    : (
                        <Link className="p-3 d-block"
                            to={room.status === 'Draft'
                                ? `/situation-room/${room.situationRoomId ? 'edit-draft-room' : 'create-room'}/${room.id}`
                                : `/situation-rooms/${room.id}`}
                        >
                            {room.project_name}
                        </Link>
                    )}

            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className={`mr-1 p-1 d-inline-block rounded bg-${statusColors[status]}`} />
                    {status}
                </div>
            </td>
            {status !== 'Draft' ? <RoomMetrics roomMetrics={room.metrics} /> : (
                <>
                    <td>{room?.createdBy?.displayName}</td>
                    <td>{moment(room.updatedAt).format('DD/MM/YYYY')}</td>
                </>
            )}
            {tab === 2 ? (
                <>
                    <td>{room.archivedDetails?.autoArchived ? 'Auto-archive' : room.archivedDetails?.archivedBy?.displayName}</td>
                    <td>{moment(room.archivedDetails?.archivedAt).format('DD/MM/YYYY')}</td>
                    <td>
                        <div className="d-flex align-items-center">
                            <Button color="link" className="p-0 mw-0 mr-4" onClick={() => restoreRoomModal()}>
                                Restore
                            </Button>
                            <UncontrolledDropdown direction="left" className="mw-0">
                                <DropdownToggle tag="div">
                                    <MoreVertical size={20} role="button" className="text-primary d-block" />
                                </DropdownToggle>
                                <DropdownMenu className="mw-0">
                                    <DropdownItem onClick={() => handleModal('delete')}>
                                        Delete room
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </td>
                </>
            ) : (
                <>
                    {status !== 'Draft' && (
                        <td className="p-3">
                            {lastViewed ? moment(new Date(lastViewed)).format('DD/MM/YYYY') : 'Not visited'}
                        </td>
                    )}
                    {room.status !== 'Draft' && (
                        <td className="p-3">
                            {moment(room.updatedAt).format('DD/MM/YYYY')}
                        </td>
                    )}
                    <td>
                        {(status === 'Active' && isAdmin) && (
                            <Link to={`/situation-room/edit-room/${room.id}`}>
                                <Edit size={20} className="d-block" />
                            </Link>
                        )}
                    </td>
                    <td>
                        {(status === 'Active' && isAdmin) && (
                            <UncontrolledDropdown direction="left" className="mw-0 pr-1">
                                <DropdownToggle tag="div">
                                    <MoreVertical size={20} role="button" className="text-primary d-block" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleModal('delete')}>
                                        Delete room
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleModal('archive')}>
                                        Archive room
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                        {(status === 'Draft' && isAdmin) && (
                            <div className="cursor-pointer" onClick={() => handleModal('delete')} data-testid="delete-draft">
                                <Trash2 size={20} className="d-block" color={color.blue[500]} />
                            </div>
                        )}
                    </td>
                </>
            )}
        </>
    );
};

const RecentlyArchivedLabel = ({ room }) => {
    if (!room.archivedDetails) return null;
    const date7DaysAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);
    const roomRecentlyArchived = new Date(room.archivedDetails.archivedAt) > date7DaysAgo;
    if (!roomRecentlyArchived) return null;
    return (
        <small className="highlighted-text text-dark px-11 font-size-sm">
            Recently archived
        </small>
    );
};

const statusColors = {
    Active: 'success',
    Archived: 'danger',
    Draft: 'warning'
};
