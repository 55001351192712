import React from 'react';
import { SubcategoryCheckboxFilter } from './SubcategoryCheckboxFilter';

export const NestedCheckbox = ({ filters, values, setValues, value, shownDetails, setShownDetails, setCollapseAll }) =>
    (
        <div className="p-2 scrollbar-small overflow-y-auto overflow-x-hidden maxh-420 minh-90 ">
            {filters.map(filter => (
                <div key={filter.key}>
                    <SubcategoryCheckboxFilter filters={filter}
                        values={values}
                        setValues={setValues}
                        value={value}
                        shownDetails={shownDetails}
                        setShownDetails={setShownDetails}
                        setCollapseAll={setCollapseAll}
                    />
                </div>
            ))}
        </div>
    );
