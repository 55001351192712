import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import moment from 'moment';
import { UserActions } from './UserActions';
import { AddNotes } from './AddNotes.js';
import { EmailListItem } from './EmailListItem.js';
import { checkSource, LogicallyAttachments, UserAttachments } from '../Utils/HelperFunctions';
import { useInvestigation } from './InvestigationContext.js';

export const InvestigationSummary = () => {
    const { investigation, emailList, setEmailList } = useInvestigation();
    const [showAddEmailInput, setShowAddEmailInput] = useState(false);
    const [emailToBeAdded, setEmail] = useState('');
    const [emailAlert, setEmailAlert] = useState(false);

    const validateEmail = () => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailToBeAdded)) {
            setEmailList([...emailList, emailToBeAdded]); setEmail(''); setShowAddEmailInput(false); setEmailAlert(false);
        }
        setEmailAlert(true);
    };
    return (
        <div className="p-3 bg-white">
            <p className="font-weight-bold">Summary</p>
            <p>{investigation.summary.trim() || 'Not available'}</p>
            <p className="font-weight-bold pt-3">Details</p>
            <p>{investigation.details}</p>
            <p className="font-weight-bold pt-3">Source</p>
            {checkSource(investigation.source)}
            <p className="font-weight-bold pt-3">Contact email</p>
            <div className="d-flex">
                <p className="mr-2">{investigation.email}</p>
                {investigation.status === 'In Progress' && (
                    <p onClick={() => setShowAddEmailInput(true)}
                        className=" text-primary cursor-pointer"
                    >
                        Add new email
                    </p>
                )}
            </div>
            {emailList.length > 0 && emailList.map((value) => (
                <div key={value}>
                    <EmailListItem emailList={emailList}
                        setEmailList={setEmailList}
                        value={value}
                    />
                </div>
            ))}
            {showAddEmailInput
                 && (
                     <div className="d-flex flex-column mt-2 mb-1">
                         <p>Add new email id</p>
                         <div className="d-flex">
                             <Input className=" w-25"
                                 type="email"
                                 pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                 value={emailToBeAdded}
                                 onChange={(e) => setEmail(e.target.value)}
                                 data-testid="contact-email-input"
                             />
                             <Button color="primary"
                                 className="ml-3"
                                 onClick={(e) => { e.preventDefault(); validateEmail(); }}
                             >
                                 Save
                             </Button>
                             <Button color="secondary"
                                 className="ml-3"
                                 onClick={() => { setShowAddEmailInput(false); setEmail(''); setEmailAlert(false); }}
                             >Close
                             </Button>
                         </div>
                         {emailAlert && <p className="text-danger mt-1 mb-0">Please enter valid email</p>}

                     </div>
                 ) }
            <p className="font-weight-bold pt-3">Received date</p>
            <p>{investigation.updatedAt ? moment(new Date(parseInt(investigation.updatedAt, 10)))
                .format('DD MMMM YYYY') : moment(new Date(parseInt(investigation.createdAt, 10)))
                .format('DD MMMM YYYY')}
            </p>
            <p className="font-weight-bold pt-3">Situation room</p>
            <p>{investigation.situationRoom.project_name}</p>
            <p className="font-weight-bold pt-3">User attachments</p>
            {investigation.uploadAttachments?.length > 0 ? investigation.uploadAttachments?.map((attachment) => (
                <div key={attachment}>
                    <UserAttachments attachment={attachment} />
                </div>
            )) : <p>No attachments</p>}
            {investigation.status !== 'In Progress' && (
                <LogicallyAttachments />
            )}
            {investigation.status === 'In Progress' && (
                <AddNotes />
            )}
            {investigation.status === 'In Progress' && (
                <UserActions />
            ) }
        </div>
    );
};
