import React, { useRef } from 'react';
import Highcharts, { Chart, Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { getSeries } from '../../utils/getSeries';
import { color } from '../../utils/getColors';

// eslint-disable-next-line @typescript-eslint/no-empty-function
Chart.prototype.showResetZoom = () => { };

type BrushChartProps = {
    data: any;
    // undefined added on purpose as per Highcharts documentation
    title?: string | undefined;
    onClick: (data: any) => void;
    setIsZoomed: (data: boolean) => void;
}

export const BrushLineChart = ({ data, title = '', onClick, setIsZoomed }: BrushChartProps) => {
    // React.MutableRefObject<any> causes issues with deep nesting
    const chartComponent = useRef<any>(null);
    const series = getSeries(data);
    const options: Options = {
        title: {
            text: title,
        },
        chart: {
            backgroundColor: 'transparent',
            height: 150,
            zoomType: 'x',
            spacing: [1, 1, 0, 1],
            events: {
                // any added on purpse as this can be a custom value
                selection: ({ resetSelection }: any) => {
                    if (resetSelection) {
                        setIsZoomed(false);
                    } else {
                        setIsZoomed(true);
                    }
                }
            } as any
        },
        xAxis: {
            type: 'datetime',
            tickLength: 0,
            gridLineWidth: 1,
            gridLineColor: color.grey[300],
            min: series[0].x,
            max: series[series.length - 1].x,
            endOnTick: true,
            startOnTick: true,
            tickPixelInterval: 95,
            dateTimeLabelFormats: {
                day: '%e %b',
                week: '%e %b',
                month: '%b %y',
                year: '%Y'
            }
        },
        yAxis: {
            min: 0,
            labels: {
                enabled: false
            },
            // added custom props not recognised by Highchart but reflected in UI
            title: {
                enabled: false
            } as any,
            tickAmount: 2,
            gridLineColor: color.grey[300]
        },
        legend: {
            enabled: false
        },
        colors: [color.blue[500]],
        plotOptions: {
            line: {
                marker: {
                    enabled: false,
                    lineColor: color.blue[500],
                    fillColor: '#fff',
                    lineWidth: 2,
                    radius: 5.5,
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            radiusPlus: 0
                        }
                    }
                },
                lineWidth: 2,
                states: {
                    hover: {
                        halo: {
                            opacity: 0
                        }
                    }
                },
                events: {
                    // any added on purpse as this can be a custom value
                    click: ({ point }: any) => {
                        if (point.y) {
                            onClick(point.custom.date);
                        }
                    }
                }
            },
        },
        tooltip: {
            useHTML: true,
            headerFormat: '',
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            padding: 0,
            pointFormatter() {
                const { x, y } = this as any;
                return `<div class="border rounded bg-white shadow-sm">
                    <table>
                        <tr class="border-bottom">
                            <td class="p-1">Date</td> 
                            <td class="p-1 text-right">${moment(x).format('DD MMMM YYYY')}</td>
                        </tr> 
                        <tr>
                            <td class="p-1">Threats</td> 
                            <td class="p-1 text-right">${y}</td>
                        </tr>
                    </table>
                </div>`;
            },
            style: {
                color: color.darkblue['700'],
                fontFamily: 'inherit',
                fontSize: '14px'
            },
            distance: 18
        },
        series: [{
            data: series,
            type: 'line'
        }],
        exporting: {
            enabled: false
        }
    };

    return (<HighchartsReact highcharts={Highcharts} options={options} ref={chartComponent} />);
};
