import { gql } from '@apollo/client';
import { findAndReplaceInObject } from '../../../../utils/lib/findAndReplaceInObject';

export const validateQuery = async ({ client, room, validateIngestionQuery = true }) => {
    const newQuery = {
        boolean: room?.query_type === 'boolean' ? room.query.boolean : '',
        included: room?.query_type === 'keyword' ? room.query.included : [],
        excluded: room?.query_type === 'keyword' ? room.query.excluded : []
    };
    if (newQuery.boolean?.split(' ').includes('##BW##')) {
        newQuery.boolean = newQuery.boolean?.split('##BW##')[0];
    }
    else if (newQuery.boolean?.split(' ').includes('##BW_ID##')) {
        newQuery.boolean = newQuery.boolean?.split('##BW_ID##')[0];
    }
    else if (newQuery.boolean?.split(' ').includes('##CT_ID##')) {
        newQuery.boolean = newQuery.boolean?.split('##CT_ID##')[0];
    }
    const postData = {
        query: JSON.parse(JSON.stringify(newQuery), (key, value) => {
            if (key === 'boolean') {
                return value === null ? undefined : escapeForwardSlashes(value);
            }
            return (value === null ? undefined : value);
        }),
        query_type: room?.query_type,
        count_historics: false
    };

    const validateQueryResponse = await client.query({
        query: QUERY_VALIDATOR,
        fetchPolicy: 'no-cache',
        variables: {
            data: postData,
            validateIngestionQuery
        }
    });

    const sanitisedValidateQueryResponse = findAndReplaceInObject(
        validateQueryResponse?.data?.validateQuery?.query || {},
        'minimum_should_match',
        (a) => a.toString()
    );

    return {
        validateQueryResponse: sanitisedValidateQueryResponse,
        keywords: validateQueryResponse?.data?.validateQuery?.keywords || [],
        isValid: validateQueryResponse?.data?.validateQuery?.isValid || false
    };
};
export const escapeForwardSlashes = (query) => query?.replace(
    /\/(?!\d+)(?![^\s]*\.(com|net|org|edu|gov|io|co|uk|fi|ru|jp|in|ax)[^\s]*)(?<!https?:\/\/[^\s]+)/g,
    '\\/'
);

const QUERY_VALIDATOR = gql`
    query validateQuery ($data: JSON, $validateIngestionQuery: Boolean){
        validateQuery (data: $data, validateIngestionQuery: $validateIngestionQuery) {
            isValid
            query
            errors {
                message
                startColumn
                endColumn
                startRow
                endRow
                errorCode
                type
            }
            keywords
        }
    }
`;
