import React from 'react';
import { ChevronDown } from 'react-feather';

export const EditTemplate = ({ collapse, selected, setNewTemplateModal, setCollapse }) => (
    <div className="d-flex align-items-center pb-3">
        <h4 className="my-0 mr-3">Select template</h4>
        {selected && (
            <p role="button"
                className="m-0 text-primary"
                onClick={() => setNewTemplateModal(selected)}
            >
                Edit template
            </p>
        )}
        <ChevronDown className={`ml-auto transition ${collapse ? 'rotate-180' : ''}`}
            role="button"
            onClick={() => setCollapse(!collapse)}
        />
    </div>
);
