import React, { useMemo, useContext, createContext } from 'react';
import Cookies, { Cookie, CookieSetOptions } from 'universal-cookie';

type CookiesContextProps = {
    getCookie: (name: string) => string;
    setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => void;
}

const CookiesContext = createContext({} as CookiesContextProps);

export const useCookies = () => {
    const context = useContext(CookiesContext);
    if (context === undefined) {
        throw new Error('useCookies be used within a CookiesContextProvider');
    }
    return context;
};

type CookiesContextProviderProps = {
    children: React.ReactNode;
    mocks?: {
        getCookie?: (name: string) => string;
        setCookie?: (name: string, value: string) => void;
    };
}

export const CookiesContextProvider = ({ children, mocks }: CookiesContextProviderProps) => {
    let context = useMemo(() => ({
        getCookie: (name: string) => new Cookies().get(name),
        setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => new Cookies().set(name, value, options || { path: '/' })
    }), []);

    if (mocks) {
        context = {
            getCookie: mocks.getCookie || context.getCookie,
            setCookie: mocks.setCookie || context.setCookie
        };
    }

    return <CookiesContext.Provider value={context}>{ children }</CookiesContext.Provider>;
};
