import React from 'react';

type EngagementCardProps = {
    value: string;
    count: number;
    onClick?: () => void;
};

export const EngagementCard = ({ value, count, onClick }: EngagementCardProps) => (
    <div className="p-2 bg-verylight rounded col">
        <div className="font-size-14 mb-11">{value}</div>
        {onClick ? (
            <span className="font-weight-bold text-primary cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick?.();
                }}
            >
                {count < 0 ? 'N/A' : count}
            </span>
        ) : (<div className="font-weight-bold">{count < 0 ? 'N/A' : count}</div>)}
    </div>
);
