import React, { useState } from 'react';
import { Alert, Button, Spinner } from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import { AlertCircle, AlertTriangle } from 'react-feather';
import { SelectedFilters } from './components/SelectedFilters';
import { useTotalMatches } from '../../services/Overview/totalMatches';
import { getRoom } from '../../utils/variables';
import { saveSelectedFilters, saveWatchlistFilters } from '../../pages/Room/store';
import { DropdownWithSearch } from './components/DropdownWithSearch';
import { SaveFilterButton } from './components/SaveFilter';
import { useUser } from '../../context/user/UserContext';
import { getCtype, truncateString } from '../../utils/text';
import { FiltersList } from './FiltersList/index';
import { DateRangeProps } from './components/DatePicker';
import { RootState } from '../../store';
import { color } from '../../utils/getColors';
import { removeTypename } from '../../utils/removeAllTypename';
import { allTypes } from '../../utils/lib/search';
import { useTotalClusters } from '../../services/Clusters/useTotalClusters';
import { handleReturnReduxFuntion } from './components/SelectedFilters/SelectedFilter';
import { setParam } from '../../utils/urlParams';
import { getToast } from '../../utils/getToast';

export enum SaveFilterTypes {
    SaveFilter = 'Save filter',
    Save = 'Save'
  }

export type DataProps = {
    filter: string,
    items: string[]
    __typename?: string
}

type UserProps = {
    displayName: string,
    id: string
}

export type RoomFilterProps = {
    id: string
    situationRoom: string,
    name: string,
    user: UserProps,
    data: DataProps[],
    createdAt: string
    dateRange: {
        startDate: number,
        endDate: number
        __typename?: string
    }
}

type FiltersLayoutProps = {
    setIsFiltersOpen: (e: boolean) => void,
    isFiltersOpen: boolean,
    setFilterName: (e: string) => void,
    filterName: string,
    hasFilters: boolean,
    contentIds?: string[],
    defaultFilters?: any,
    watchlist: boolean,
    overview: boolean,
    clusters?: boolean
}
export const FiltersLayout = ({
    setIsFiltersOpen, isFiltersOpen,
    filterName, setFilterName,
    hasFilters,
    contentIds,
    defaultFilters, watchlist = false, overview = false, clusters = false
}: FiltersLayoutProps) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const { user } = useUser();
    const toast = getToast();

    const [selectedFilterName, setSelectedFilterName] = useState('');
    const [disableSaveEditFilter, setDisableSaveFilter] = useState(false);
    const [saveFilterError, setSaveFilterError] = useState(false);
    const [applyFilterError, setApplyFilterError] = useState(false);

    const { data, loading } = useQuery(clusters ? GET_ROOM_FILTERS_IN_CLUSTERS : GET_ROOM_FILTERS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'no-cache'
    });
    const filtersData = ((clusters ? data?.getRoomFiltersInClusters?.filters : data?.getRoomFilters?.filters) || []) as RoomFilterProps[];
    const {
        keywords: selectedKeywords,
        languages: selectedLanguages,
        contentType: selectedSources,
        from: selectedActors,
        dateRange: selectedDateRange,
        sentiment: selectedSentiment,
        labels: selectedLabels,
        ids: selectedIds,
        threatTypes: selectedThreatTypes,
        origin: selectedLocations,
        entities: selectedEntities,
        mentions: selectedMentions,
        hashtags: selectedHashtags,
    } = useSelector((state: RootState) => state.selectedFilters.filters);

    const handleWatchlist = (ids: string[], text?: string) => {
        if (watchlist) {
            if (selectedLabels.length) {
                return ids.filter((a: string) => a && (!contentIds || contentIds.includes(a)));
            }
            return contentIds;
        }
        if (selectedLabels.length) {
            return selectedIds;
        } if (text) {
            return [];
        }
        return undefined;
    };
    const handleThreatsList = (text: string, selectedData: string[]) => {
        if (overview || clusters) {
            return undefined;
        }
        if (selectedData.length > 0) {
            if (text === 'resolvers') {
                const newList = allTypes.filter((a) => (a.isResolver && (selectedThreatTypes as string[])
                    .includes(a.key))).map((b) => b.key);
                if (newList.length > 0) {
                    return newList;
                }
                return undefined;
            }

            const newList = allTypes.filter((a) => (!a.isResolver && (selectedThreatTypes as string[])
                .includes(a.key))).map((b) => b.key);
            if (newList.length > 0) {
                return newList;
            }
            return undefined;
        }

        return undefined;
    };
    const handleMatchesDateRange = () => {
        if (defaultFilters) {
            if (Object.keys(defaultFilters)[0] === 'isThreat' && selectedDateRange.startDate === 0) {
                return {
                    startDate: moment(moment(room.start_date).toDate()).unix(),
                    endDate: moment(moment().endOf('day')).unix(),
                };
            }
            return selectedDateRange;
        }
        return selectedDateRange;
    };

    const filters = {
        filters: {
            date: handleMatchesDateRange(),
            contentType: selectedSources.length ? selectedSources : undefined,
            languages: selectedLanguages.length ? selectedLanguages : undefined,
            keywords: selectedKeywords.length ? selectedKeywords : undefined,
            from: selectedActors.length ? selectedActors : undefined,
            sentiment: selectedSentiment.length ? selectedSentiment : undefined,
            ids: handleWatchlist(selectedIds),
            labels: selectedLabels.length ? selectedLabels : undefined,
            threatLabels: handleThreatsList('labels', selectedThreatTypes),
            threatResolvers: handleThreatsList('resolvers', selectedThreatTypes),
            locations: selectedLocations.length > 0 ? selectedLocations : undefined,
            entities: selectedEntities.length > 0 ? selectedEntities : undefined,
            mentions: selectedMentions.length > 0 ? selectedMentions : undefined,
            hashtags: selectedHashtags.length > 0 ? selectedHashtags : undefined
        }
    };
    const { totalMatches, loading: totalMatchesLoading } = useTotalMatches(filters, defaultFilters, false, watchlist, clusters);
    const { counts, loading: totalClustersLoading } = useTotalClusters({ appliedFilters: filters, skip: !clusters });

    let totalMatchesDisplay = clusters ? (counts?.clusters || 0) : totalMatches;
    const handleApply = () => {
        if (!hasSelected || totalMatchesDisplay === 0) {
            toast.error('Resolve all errors before continuing');
            if (saveFilterError) {
                setSaveFilterError(false);
            }
            return setApplyFilterError(true);
        }
        if (selectedFilterName) {
            setFilterName(selectedFilterName);
        } else if (checkForData()) {
            setFilterName('Select filters');
        } else {
            setFilterName('Custom selected');
        }
        if (!watchlist) {
            if (clusters) {
                setParam('page', '1');
            }
            dispatch(handleReturnReduxFuntion(clusters)({
                date: selectedDateRange,
                contentType: selectedSources.length ? selectedSources : [],
                ids: handleWatchlist(selectedIds, 'apply'),
                keywords: selectedKeywords.length > 0 ? selectedKeywords : [],
                from: selectedActors.length ? selectedActors : [],
                languages: selectedLanguages.length ? selectedLanguages : [],
                sentiment: selectedSentiment.length ? selectedSentiment : [],
                threatTypes: ((!overview && !clusters) && selectedThreatTypes?.length > 0) ? selectedThreatTypes : [],
                origin: selectedLocations?.length > 0 ? selectedLocations : [],
                sources: selectedSources.length ? selectedSources : [],
                dateRange: handleDateRange(selectedDateRange, clusters ? undefined : room.start_date),
                sourcesWithDomains: selectedSources.length > 0
                    ? selectedSources.map((
                        item: string
                    ) => ({ key: item, label: getCtype(item) })) : [],
                entities: (selectedEntities.length > 0 && !clusters) ? selectedEntities : [],
                mentions: (selectedMentions.length > 0 && !clusters) ? selectedMentions : [],
                hashtags: (selectedHashtags.length > 0 && !clusters) ? selectedHashtags : [],
                roomId: room?.id,
                labels: (selectedLabels.length && !clusters) ? selectedLabels : []
            }));
        } else {
            dispatch(saveWatchlistFilters({
                ...filters.filters,
                origin: selectedLocations?.length > 0 ? selectedLocations : undefined,
                sources: selectedSources.length ? selectedSources : undefined,
                threatTypes: selectedThreatTypes?.length > 0 ? selectedThreatTypes : undefined,
                dateRange: handleDateRange(selectedDateRange, room.start_date),
                sourcesWithDomains: selectedSources.length > 0
                    ? selectedSources.map((
                        item: string
                    ) => ({ key: item, label: getCtype(item) })) : undefined,
                entities: selectedEntities.length > 0 ? selectedEntities : undefined,
                mentions: selectedMentions.length > 0 ? selectedMentions : undefined,
                hashtags: selectedHashtags.length > 0 ? selectedHashtags : undefined
            }));
        }
        setIsFiltersOpen(!isFiltersOpen);
    };

    if ((!clusters ? totalMatches : (counts?.clusters || 0)) > 1000000) {
        totalMatchesDisplay = `${(Math.floor(totalMatches / 1000000))}m+`;
    } else if ((!clusters ? totalMatches : (counts?.clusters || 0)) > 1000) {
        totalMatchesDisplay = `${Math.floor(totalMatches / 1000)}k+`;
    }

    const handleSelectItem = (id: string) => {
        const deletedTypenames = filtersData.map((a) => {
            const newFilter = { ...a };
            delete newFilter.dateRange.__typename;
            newFilter.data.forEach((item: DataProps) => removeTypename(item));

            return newFilter;
        });
        const selectedFilter = deletedTypenames.find((a) => a.id === id);
        if (selectedFilter) {
            const sourceFilter = selectedFilter.data.find((a) => a.filter === 'Sources')?.items;
            const languagesFilter = selectedFilter.data.find((a) => a.filter === 'Languages')?.items;
            const sentimentFilter = selectedFilter.data.find((a) => a.filter === 'Sentiment')?.items;
            const keywordFilter = selectedFilter.data.find((a) => a.filter === 'Keywords')?.items;
            const actorsFilter = selectedFilter.data.find((a) => a.filter === 'Actors')?.items;
            const labelsFilter = selectedFilter.data.find((a) => a.filter === 'Labels')?.items;
            const threatTypesFilter = selectedFilter.data.find((a) => a.filter === 'ThreatType')?.items;
            const locationsFilter = selectedFilter.data.find((a) => a.filter === 'Locations')?.items;
            const mentionsFilter = selectedFilter.data.find((a) => a.filter === 'Mentions')?.items;
            const entitiesFilter = selectedFilter.data.find((a) => a.filter === 'Entities')?.items;
            const hashtagsFilter = selectedFilter.data.find((a) => a.filter === 'Hashtags')?.items;
            dispatch(saveSelectedFilters({
                keywords: keywordFilter,
                languages: languagesFilter,
                contentType: sourceFilter,
                from: actorsFilter,
                dateRange: selectedFilter.dateRange,
                sentiment: sentimentFilter,
                labels: labelsFilter,
                threatTypes: threatTypesFilter,
                origin: locationsFilter,
                entities: entitiesFilter || [],
                hashtags: hashtagsFilter || [],
                mentions: mentionsFilter || []
            }));

            setSelectedFilterName(selectedFilter.name);
            setDisableSaveFilter(true);
        }
    };

    if (disableSaveEditFilter && filtersData?.length > 0 && selectedFilterName) {
        const filteredData = filtersData.filter(a => a.name === selectedFilterName)[0]?.data || [];
        const filterDate = filtersData.filter(a => a.name === selectedFilterName)[0].dateRange;
        const filterTypes = ['Languages', 'Sources', 'Keywords', 'Actors', 'Sentiment', 'Labels', 'ThreatType',
            'Locations', 'Entities', 'Mentions', 'Hashtags'];
        const filterItems = filteredData.reduce((acc: any, filter: any) => {
            acc[filter.filter] = filter.items;
            return acc;
        }, {});

        if (Object.keys(filterItems).length > 0) {
            const comparisons: any = {
                Languages: selectedLanguages,
                Sources: selectedSources,
                Keywords: selectedKeywords,
                Actors: selectedActors,
                Sentiment: selectedSentiment,
                Labels: selectedLabels,
                ThreatType: selectedThreatTypes,
                Locations: selectedLocations,
                Mentions: selectedMentions,
                Entities: selectedEntities,
                Hashtags: selectedHashtags
            };

            const hasChanges = filterTypes.some(filterType => {
                const selectedItems = comparisons[filterType] || [];
                const filteredItems = filterItems[filterType] || [];
                return !_.isEqual(removeTypename(selectedItems), removeTypename(filteredItems)); });
            if (hasChanges || !_.isEqual(filterDate, selectedDateRange)) {
                setDisableSaveFilter(false);
                setSelectedFilterName('');
            }
        }
    }
    const checkForData = () => {
        const dateFilter = _.isEqual({ startDate: 0, endDate: 0 }, selectedDateRange);
        const isFilterEmpty = !selectedSources.length && !selectedLanguages.length && !selectedSentiment.length
        && (!overview ? !selectedThreatTypes.length : true) && dateFilter && !selectedLocations.length && !selectedMentions.length
        && !selectedLabels.length && !selectedHashtags.length && !selectedEntities.length;
        return isFilterEmpty;
    };
    const hasSelected = selectedActors.length > 0 || selectedKeywords.length > 0 || selectedLabels.length > 0 || selectedSources.length > 0
    || selectedLanguages.length > 0 || selectedSentiment.length > 0 || (!overview && selectedThreatTypes.length > 0)
    || selectedHashtags.length > 0 || selectedLocations.length > 0
    || (clusters && !_.isEqual(selectedDateRange, { startDate: 0, endDate: 0 })) || selectedEntities.length > 0
    || selectedMentions.length > 0;

    if (hasSelected && saveFilterError) {
        setSaveFilterError(false);
    } else if (hasSelected && applyFilterError) {
        setApplyFilterError(false);
    }
    const loadingState = totalMatchesLoading || totalClustersLoading;

    return (
        <div className="mb-4">
            <div className="border px-3 py-2 bg-white d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <p className="mr-2 mt-1">Select saved filter</p>
                    <DropdownWithSearch name={truncateString(selectedFilterName, 15, false) || 'Select filters'}
                        placeholder="Search"
                        items={filtersData.length ? filtersData
                            .map((filter) => {
                                const { id } = filter;
                                const { name } = filter;
                                return { id, name };
                            }) : []}
                        handleSelectItem={handleSelectItem}
                        loading={loading}
                        emptyStateText="Saved filters will appear here"
                    />
                </div>
                <Link className="cursor-pointer mt-1 p-0 mw-0 link-zindex-1"
                    to={{ pathname: `/situation-room/${room?.id}/manage-saved-filters`,
                        state: { from: clusters, projectId: room.project_id } }}
                >
                    Manage saved filters
                </Link>
            </div>
            <FiltersList watchlist={watchlist}
                contentIds={contentIds || []}
                overview={overview}
                clusters={clusters}
            />

            {(hasSelected) && (
                <div className="border p-3 bg-white">
                    <SelectedFilters start_date={room.start_date}
                        setFilterName={setFilterName}
                        watchlist={watchlist}
                        clusters={clusters}
                    />
                </div>
            )}

            <div className={`border px-3 py-2 bg-white d-flex ${((totalMatchesDisplay === 0 && !loadingState) || saveFilterError
            || applyFilterError) ? 'justify-content-between' : 'justify-content-end'}`}
            >
                {(totalMatchesDisplay === 0 && !loadingState) && (
                    <div>
                        <Alert color="warning" className="mb-0 d-flex w-100 align-items-center">
                            <AlertCircle size={20} color={color.yellow[300]} className="mr-2 " />
                            No results found. Try adjusting your filters
                        </Alert>
                    </div>
                )}
                {(saveFilterError && !hasSelected) && (
                    <div>
                        <Alert color="danger" className="mb-0 d-flex w-100 align-items-center">
                            <AlertTriangle size={20} color={color.red[200]} className="mr-2 " />
                            You cannot save an empty filter. Please select options to save a filter.
                        </Alert>
                    </div>
                )}
                {(applyFilterError && !hasSelected) && (
                    <div>
                        <Alert color="danger" className="mb-0 d-flex w-100 align-items-center">
                            <AlertTriangle size={20} color={color.red[200]} className="mr-2 " />
                            You cannot apply an empty filter. Please select options to save a filter.
                        </Alert>
                    </div>
                )}
                <div className="d-flex">
                    <Button color="link" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>Close</Button>
                    <SaveFilterButton text={SaveFilterTypes.SaveFilter}
                        userID={user.id}
                        filterNamesList={filtersData.length ? filtersData
                            .map(({ name }) => name) : []}
                        roomFilters={filtersData}
                        overview={overview}
                        clusters={clusters}
                        setSaveFilter={(val) => { setSaveFilterError(val); if (applyFilterError) { setApplyFilterError(false); } }}
                    />
                    <Button color="primary ml-3" onClick={handleApply}>
                        Apply filter {(!loadingState && hasSelected) && `(${totalMatchesDisplay})`}
                        {loadingState && <Spinner className="ml-1" size="sm" color="white" />}
                    </Button>
                </div>

            </div>
        </div>
    );
};

export const handleDateRange = (data: DateRangeProps, startDate?: number) => {
    const roomStartDate = startDate ? moment(moment(startDate).toDate()).unix() : 0;
    return ({
        startDate: data?.startDate !== 0 ? data.startDate : roomStartDate,
        endDate: data?.endDate !== 0 ? data.endDate : moment(moment().endOf('day')).unix()
    });
};

export const handleReturnFilters = (state: any, watchlist: boolean, clusters: boolean) => {
    if (watchlist) { return state.watchlistFilters.filters; }
    if (clusters) {
        return state.clusterFilters.filters;
    }
    return state.filters.filters;
};

export const GET_ROOM_FILTERS = gql`
    query getRoomFilters($situationRoom: ID!, $limit: Int, $skip: Int) {
        getRoomFilters(situationRoom: $situationRoom,limit: $limit, skip: $skip) {
            filtersCount
            filters {
                id
                name
                user {
                    displayName
                    id
                }
                createdAt
                data {
                    filter
                    items
                }
                dateRange {
                    startDate
                    endDate
                }
            }
        }
    }
`;
export const GET_ROOM_FILTERS_IN_CLUSTERS = gql`
    query getRoomFiltersInClusters($situationRoom: ID!, $limit: Int, $skip: Int) {
        getRoomFiltersInClusters(situationRoom: $situationRoom,limit: $limit, skip: $skip) {
            filtersCount
            filters {
                id
                name
                user {
                    displayName
                    id
                }
                createdAt
                data {
                    filter
                    items
                }
                dateRange {
                    startDate
                    endDate
                }
            }
        }
    }
`;
