import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../../utils/useDropdown';

type ClustersSortOptionsProps = {
    sort: {field: string, order: string, name: string},
    setSort: (({ field, order, name }: { field: string, order: string, name: string }) => void)
    isNarrative?: boolean,
    isWatchlist?: boolean,
    classname?: string,
}

const sortOptions = [
    { field: 'title', order: 'asc', name: 'Title (A-Z)' },
    { field: 'title', order: 'desc', name: 'Title (Z-A)' },
    { field: 'engagement', order: 'desc', name: 'Highest engagement' },
    { field: 'volume', order: 'desc', name: 'Most content' },
    { field: 'duplicatedDocsCount', order: 'desc', name: 'Most duplicates' },
    { field: 'narratives', order: 'desc', name: 'Most narratives', narrativeThemesOnly: true },
    { field: 'isWatching', order: 'desc', name: 'Added to watchlist first' }
];

export const ClustersSortOptions = ({
    sort,
    setSort,
    isNarrative,
    isWatchlist,
    classname,
}: ClustersSortOptionsProps) => {
    const [dropdown, toggleDropdown] = useDropDown();

    return (
        <div className="d-flex align-items-center">
            <p className="set-max-content mr-1 mb-0">Sort by</p>
            <Dropdown isOpen={dropdown} toggle={toggleDropdown} className={`set-min-width ${classname}`}>
                <DropdownToggle data-testid="search-sort-dropdown">
                    {sort.name}
                    <ChevronDown size={16} className="ml-11" />
                </DropdownToggle>
                <DropdownMenu
                    modifiers={{
                        flip: {
                            fn: (data) => ({
                                ...data,
                                placement: 'right-end'
                            })
                        },
                        offset: {
                            offset: '0,-40px'
                        }
                    }}
                >
                    {sortOptions.map(({ field, order, name }) => {
                        if (isNarrative && field === 'narratives') return null;
                        if (isWatchlist && field === 'isWatching') return null;
                        return (
                            <DropdownItem key={field + order}
                                name={name}
                                onClick={() =>
                                    setSort({ field, order, name })}
                            >{name}
                            </DropdownItem>
                        ); })}
                    {/* {sortOptions.filter(({ narrativeThemesOnly }) => (isNarrative ? !narrativeThemesOnly : true))
                        .map(({ field, order, name }) => (
                            <DropdownItem key={field + order}
                                name={name}
                                onClick={() => setSort({ field, order, name })}
                            >
                                {name}
                            </DropdownItem>
                        ))} */}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
