import React from 'react';
import { ChevronDown } from 'react-feather';
import { Input } from 'reactstrap';

export const FontSelector = ({ value, onChange }) => (
    <div className="d-flex align-items-center">
        <div className="flex-1 position-relative">
            <Input type="select"
                value={value.fontSize}
                onChange={(e) => onChange({ ...value, fontSize: parseInt(e.target.value, 10) })}
            >
                {range(10, 40, 1).map((a) => <option key={a} value={a}>{a}</option>)}
            </Input>
            <ChevronDown size={16} className="position-absolute right-center mr-2 pointer-events-none" />
        </div>
    </div>
);

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
