import { convertToMarkers } from '../../convertToMarkers';
import { getTagData } from './getTagData';
import { sourceTags } from '.';

export const accountAtSymbolValidator = (query, tag, queryByLines, lineLengths) => {
    const highlight = [];

    const tagFragments = tag.split(':');
    if (sourceTags.includes(tagFragments[0])) {
        const tagData = getTagData(tagFragments, query);

        tagData.forEach(({ tagValue, tagSnapshot }) => {
            // ensure all account names start with @ symbol
            if (tagValue.charAt(0) !== '@') {
                highlight.push([
                    query.indexOf(tagSnapshot),
                    query.indexOf(tagSnapshot) + tagSnapshot.length
                ]);
            }
        });
    }

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'The account username must start with an @ symbol'
        )
    };
};
