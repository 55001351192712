export const findElementByStringInNestedArray = (arr: string[] | string[][], str: string): any => {
    let result: string[] | string[][] = [];
    if (!arr?.length || !str) {
        return result;
    }
    for (const item of arr) {
        if (Array.isArray(item)) {
            const arraySearched = findElementByStringInNestedArray(item, str);
            if (arraySearched.length) {
                return arraySearched; // Stop searching once we find the result
            }
        }
        if (typeof item === 'string' && item === str) {
            result = arr;
            return result; // Return immediately once the item is found
        }
    }
    return result;
};

export const findElementByStringFromArray = (arr: { id: string, title: string, subClustersMappedId?: string[] }[], str: string) => {
    if (!arr.length) {
        return null;
    }
    const found = arr.find(item => item?.subClustersMappedId?.includes(str));
    return found ? { id: found.id, title: found.title } : null;
};
