import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { isNil } from 'lodash';
import { Loading } from '../../../../../../components/Loading';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { PersonModal } from './PersonModal';
import { SortToggle } from '../../../../../../components/Table/SortToggle';
import { roundPercentage } from '../../../../../../utils/NumberFormat';
import { gql } from '../../../../../../generated';

type PossiblePersonsProps = {
    from: string;
    displayName: string;
    ctype: string;
}

export const PossiblePersons = ({ from, displayName, ctype }: PossiblePersonsProps) => {
    const [sort, setSort] = useState({ field: 'name', order: 'desc' as 'desc' | 'asc' });
    const { setModal } = useModal();
    const { data, loading } = useQuery(GET_PEOPLE, {
        variables: {
            username: from,
            name: displayName,
            contentType: ctype
        }
    });
    const people = ((data?.getPeople || [])).filter((person): person is NonNullable<typeof person> => !isNil(person)).map(person => ({
        ...person,
        name: (person.names ? person.names[0] : null) || (person.profiles?.length ? person.profiles[0]?.username : '')
    }));
    if (loading) return <Loading relative height={100} />;
    return (
        <div>
            <h3>Possible persons</h3>
            <p className="mb-2">
                These are the top 5 matches for the account handle, ranked by likelihood, with the match score representing
                the percentage chance that the account belongs to the listed person.
            </p>
            <Table className="border">
                <thead>
                    <tr>
                        <th><SortToggle sort={sort} setSort={setSort} name="Name" field="name" /></th>
                        <th><SortToggle sort={sort} setSort={setSort} name="Match score" field="score" /></th>
                    </tr>
                </thead>
                <tbody>
                    {people.map((person, i) => (
                        <tr key={person.id}>
                            <td className="p-0">
                                <Button color="link"
                                    className="py-2 px-3 mw-0 text-left"
                                    onClick={() => setModal({
                                        size: 'xl',
                                        component: <PersonModal people={people} activeIndex={i} />
                                    })}
                                >
                                    {person.name}
                                </Button>
                            </td>
                            <td>{roundPercentage(parseFloat(person.score || '0') * 100)}%</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {!people.length && <p className="mb-2">No results found</p>}
        </div>
    );
};

const GET_PEOPLE = gql(`
    query getPeople($username: String!, $name: String, $contentType: String) {
        getPeople(username: $username, name: $name, contentType: $contentType) {
            addresses {
                type
                value
                country
            }
            educations {
                start
                school
                end
            }
            emails {
                type
                value
            }
            gender
            id
            jobs {
                display
                start
                industry
                organization
                title
                end
            }
            languages
            names
            phones {
                type
                value
            }
            profiles {
                color
                icon
                id
                link
                source
                username
            }
            relationships {
                type
                value
            }
            urls {
                type
                value
            }
            image
            dob
            score
        }
    }
`);
