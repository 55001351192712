import { useLazyQuery } from '@apollo/client';
import { CHECK_NAME_EXISTS } from '../../pages/Room/Reports/graphql';
import { getParam } from '../../utils/urlParams';
import { getRoom } from '../../utils/variables';

export const useCheckNameExists = () => {
    const [checkNameExists, { data, loading }] = useLazyQuery(CHECK_NAME_EXISTS, {
        fetchPolicy: 'no-cache'
    });

    const report = data && data.getReports[0];

    return {
        checkNameExists: (name) => checkNameExists({
            variables: {
                name,
                situationRoom: getRoom()?.id
            }
        }),
        loading,
        exists: !!(report && report.id !== getParam('id'))
    };
};
