import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';

type Action = {
    onClick: () => void,
    name: string
}

type ModalWithActionProps = {
    action: Action,
    heading: string,
    subheading: string
}
export const ModalWithAction = ({ action, heading, subheading }: ModalWithActionProps) => {
    const { closeModal } = useModal();
    return (
        <div>
            <h2 className="m-0">{heading}</h2>
            <hr className="my-2" />
            <p className="m-0">{subheading}</p>
            <hr className="my-2" />
            <Button color="primary"
                className="mr-2"
                onClick={() => { closeModal(); action.onClick(); }}
            >
                {action.name}
            </Button>
            <Button onClick={closeModal}>Close</Button>
        </div>
    );
};
