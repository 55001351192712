import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { GET_ADMIN_USERS } from '../../graphql';
import { useSessionUser } from '../../auth0';
import { AdminUserContextProvider } from '../../context/adminUser/AdminUserContext';
import { LoggedInAdmin } from './LoggedInAdmin';

export const Admin = () => {
    const { user: sessionUser, loading: sessionDataLoading } = useSessionUser();
    const { data, loading: adminDataLoading } = useQuery(GET_ADMIN_USERS, {
        variables: {
            where: {
                uid: sessionUser.uid
            }
        },
        skip: !sessionUser.uid
    });

    const user = data ? data.adminUsers[0] : false;

    if (sessionDataLoading || adminDataLoading) {
        return <Loading gif />;
    }

    return (
        <AdminUserContextProvider user={user}>
            {user ? <LoggedInAdmin /> : <Redirect to="/" />}
        </AdminUserContextProvider>
    );
};
