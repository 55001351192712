import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { TokenTableBody } from './TokenTableBody';
import { Loading } from '../../../../components/Loading';
import { EmptyState } from '../Utils/EmptyState';

export const TokenRequested = ({ tokenRequestedData, tokensLoading }) => {
    const [sort, setSort] = useState({ field: 'clientName', order: 'desc' });
    if (tokensLoading) {
        return <Loading />;
    }
    return (
        <div className="overflow-x-auto">
            {!tokensLoading && tokenRequestedData?.length === 0 ? <EmptyState selectedTab="tokens" />
                : (
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <SortToggle sort={sort} setSort={setSort} field="clientName" name="Instances" />
                                </th>
                                <th>Requested tokens</th>
                                <th>Available tokens</th>
                                <th><SortToggle sort={sort} setSort={setSort} field="requestedBy" name="Requested by" /></th>
                                <th>Email</th>
                                <th>Actions</th>
                                <th />
                            </tr>
                        </thead>
                        <TokenTableBody tokensRequested={tokenRequestedData} sort={sort} />
                    </Table>
                ) }
        </div>
    );
};
