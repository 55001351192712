import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Dropdown } from '../Form/Dropdown';

export const SelectInstance = ({ instance, setInstance }) => {
    const { data, loading } = useQuery(GET_INSTANCES_FOR_FILTER);
    if (loading) { return null; }
    const instances = data?.instances || [];
    const options = instances.map(option => ({ key: option.id, name: option.clientName }));
    const selectedOption = options.find(({ key }) => key === instance) || {};
    return (
        <Dropdown label="Select instance"
            value={selectedOption}
            options={options}
            onChange={({ key }) => setInstance(key)}
            className="mb-2"
        />
    );
};

export const GET_INSTANCES_FOR_FILTER = gql`
    query instances {
        instances {
            id
            clientName
        }
    }
`;
