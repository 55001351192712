import React from 'react';
import { useRoomEditor } from '../RoomEditorContext';
import { BooleanEditor } from '../BooleanEditor';
import { KeywordsEditor } from '../KeywordsEditor';

export const RoomCreation = () => {
    const { isBooleanQuery } = useRoomEditor();
    return (
        <div className="left-panel d-flex flex-column" data-testid="left-panel">
            {isBooleanQuery ? <BooleanEditor /> : <KeywordsEditor />}
        </div>
    );
};
