import React from 'react';
import { formatThreatLabel, formatThreatResolvers } from '../../utils/text';
import { getRoom } from '../../utils/variables';

type ThreatLabelsProps = {
    threatLabels: [string]
    whyathreat: string,
    threatResolvers: string[]
}

export const ThreatLabels = ({ threatResolvers, threatLabels, whyathreat }: ThreatLabelsProps) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];

    const checkForDuplicatesLabels = ['deep-toxic-classifier', whyathreat];
    const checkForWhyAThreat = ['not-applicable', checkForDuplicatesLabels[0]];
    const labels: string[] = threatLabels && threatLabels.filter(label =>
        !checkForDuplicatesLabels.includes(label) && !disabledThreatTypes.includes(label));
    const whyathreatLabel = whyathreat && !checkForWhyAThreat.includes(whyathreat) && !disabledThreatTypes.includes(whyathreat);

    return (
        <div className="d-flex gap-1 flex-wrap mb-1">
            {threatResolvers?.filter(label => !disabledThreatTypes.includes(label))?.map((label) => (
                <div key={label} color="dark" className="tag">
                    {formatThreatResolvers(label).name}
                </div>
            ))}
            {labels?.map((label) => (
                <div key={label} color="dark" className="tag">
                    {formatThreatLabel(label).name}
                </div>
            ))}
            {whyathreatLabel && (
                <div color="dark" className="tag">
                    {formatThreatLabel(whyathreat)?.name}
                </div>
            )}
        </div>
    );
};
