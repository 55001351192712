import React from 'react';
import { Button, CustomInput } from 'reactstrap';
import { useCookies } from '../../../context/cookies/CookiesContext';

export const ActionConfirmationModal = ({ onCancel, onConfirm, cookieId }) => {
    const { setCookie } = useCookies();

    return (
        <div>
            <h2 className="mb-2">Are you sure?</h2>
            <hr />
            <p>You are about to apply the selected Boolean to your room, do you
                want to go ahead with this?
            </p>
            <hr />
            <CustomInput type="checkbox"
                name={cookieId}
                onChange={({ target: { checked } }) => setCookie(cookieId, !!checked)}
                className="custom-control-input-small mb-2"
                label="Do not show this message again"
                id={cookieId}
            />
            <Button className="mr-2"
                color="secondary"
                onClick={onCancel}
            >Cancel
            </Button>
            <Button color="primary" onClick={onConfirm}>Confirm</Button>
        </div>
    );
};
