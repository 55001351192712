import html2canvas from 'html2canvas';
import { useEffect, useState } from 'react';

type Timers = {
    [index: string]: ReturnType<typeof setTimeout>;
};
const timers: Timers = {};

type ImageData = {
    type: string,
}

type HtmlToImageProps = {
    id: string,
    data: ImageData
}

export const useHtmlToImage = ({ id, data }: HtmlToImageProps) => {
    const [image, setImage] = useState('');
    const element = document.getElementById(id);
    useEffect(() => {
        const fetchData = () => {
            clearTimeout(timers[id]);
            timers[id] = setTimeout(async () => {
                if (element) {
                    if (data.type === 'map') {
                        setImage(mapImg);
                    } else {
                        const canvas = await html2canvas(element);
                        const imgData = canvas.toDataURL();
                        setImage(imgData);
                    }
                }
            }, 1000);
        };
        fetchData();
    }, [id, element, data]);

    return image;
};

// eslint-disable-next-line max-len
const mapImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMQAAAByCAQAAACmPLaIAAAArElEQVR42u3RMQEAAAgDIJfc6BrB1wMqkOnigYgQgQgRiBCBCBGIEIEIEYgQgQgRiBCBCBGIEIEIRIhAhAhEiECECESIQIQIRIhAhAhEiECECEQgQgQiRCBCBCJEIEIEIkQgQgQiRCBCBCJEIAIRIhAhAhEiECECESIQIQIRIhAhAhEiECECESJEiECECESIQIQIRIhAhAhEiECECESIQIQIRIhABCJEIEIElwVcM6qPR13kDQAAAABJRU5ErkJggg==';
