import { ERROR_MARKER, HIGHLIGHT_ERROR_MARKER } from './editorConfig';

export const setHighlightError = (errorId, validationErrors, setValidationErrors, setHighlight, monacoInstance, editorInstance) => {
    const updatedValidationErrors = JSON.parse(JSON.stringify(validationErrors));

    for (let i = 0; i < validationErrors[errorId].markers.length; i++) {
        if (setHighlight) {
            updatedValidationErrors[errorId].markers[i].severity = HIGHLIGHT_ERROR_MARKER;
        } else {
            updatedValidationErrors[errorId].markers[i].severity = ERROR_MARKER;
        }
    }

    const markers = Object.keys(updatedValidationErrors).reduce((acc, key) => {
        if (updatedValidationErrors[key]?.markers?.length) {
            return acc.concat(updatedValidationErrors[key]?.markers);
        }
        return acc;
    }, []);

    setValidationErrors(updatedValidationErrors);

    monacoInstance.editor.setModelMarkers(editorInstance.getModel(), 'owner', markers);
};
