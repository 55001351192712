import React, { useState } from 'react';
import { StickToBottom } from '../../../../../components/StickToBottom';
import { DeletePermanentlyButton } from './DeletePermanentlyButton';
import { RecoverContentButtons } from './RecoverContentButtons';

export const FeedbackTableActionsBar = ({
    selectedContentIds,
    onRemovePermanentlySuccess,
    onRecoverContentSuccess,
    onRecoverContentAction,
    onRecoverAbort,
    onSelectAll,
    total,
    contentsToDisplay,
    searchFilters,
    offset,
    sort
}) => {
    const [allSelected, setAllSelected] = useState(false);

    const handleToggleSelectAll = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setAllSelected(!allSelected);

        if (!allSelected && onSelectAll) onSelectAll();
    };

    if (!selectedContentIds?.length) {
        return null;
    }

    const selectAllButtonText = (allSelected) ? `Select ${selectedContentIds.length}` : 'Select all';
    const selectedCount = allSelected ? total : selectedContentIds.length;
    const allowSelectAll = total > contentsToDisplay;

    return (
        <StickToBottom>
            <div>
                <hr />
                <div className="bg-hover p-1 d-flex align-items-center justify-content-between">
                    <p className="m-0 font-weight-bold">
                        {selectedCount} item
                        {selectedCount !== 1 ? 's' : ''} selected
                        {(allowSelectAll) && (
                            <span onClick={handleToggleSelectAll} className="text-primary font-weight-normal cursor-pointer ml-2">
                                <span className="pl-1">{selectAllButtonText}</span>
                            </span>
                        )}
                    </p>
                    <div className="d-flex align-items-end">
                        <RecoverContentButtons selectedContentIds={selectedContentIds}
                            onSuccess={onRecoverContentSuccess}
                            onAction={onRecoverContentAction}
                            onAbort={onRecoverAbort}
                            total={total}
                            allSelected={allSelected}
                            searchFilters={searchFilters}
                            offset={offset}
                            sort={sort}
                            disabled={!allSelected}
                        />
                        <DeletePermanentlyButton disabled={allSelected}
                            selectedContentIds={selectedContentIds}
                            onSuccess={onRemovePermanentlySuccess}
                        />
                    </div>
                </div>
                <hr className="m-0 border-white" />
            </div>
        </StickToBottom>
    );
};
