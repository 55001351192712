import React from 'react';
import { BackButton } from '../../../components/Atoms';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { SituationRoomsContextProvider } from './SituationRoomsContext';
import { SituationRoomsInner } from './SituationRoomsInner';

export const SituationRooms = () => (
    <div className="p-4">
        <BreadCrumbs items={[
            { title: 'Admin home', href: '/admin' },
            { title: 'Situation rooms' }
        ]}
        />
        <BackButton pathName="/admin" />
        <h2 className="mb-4 mt-0">Situation rooms</h2>
        <SituationRoomsContextProvider>
            <SituationRoomsInner />
        </SituationRoomsContextProvider>
    </div>
);
