import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { Check } from 'react-feather';
import { useUser } from '../../../context/user/UserContext';
import { getToast } from '../../../utils/getToast';
import { color } from '../../../utils/getColors';
import { useModal } from '../../../context/modal/ModalComponent';
import { GET_SAVED_BOOLEAN_SEARCH } from '.';

type SaveFilterModalProps = {
    booleanQuery: string,
    roomId: string,
    queryNamesList: string[]
}

export const SaveBooleanQueryModal = ({ booleanQuery, roomId, queryNamesList = [] }: SaveFilterModalProps) => {
    const [queryName, setQueryName] = useState('');
    const [error, setError] = useState(false);
    const { closeModal } = useModal();
    const [emptyName, setEmptyName] = useState(false);
    const { user } = useUser();
    const toast = getToast();
    const [saveBooleanSearch, { data, loading }] = useMutation(SAVE_BOOLEAN_SEARCH, {
        refetchQueries: [GET_SAVED_BOOLEAN_SEARCH],
        onCompleted: () => {
            toast.success('Boolean search saved');
            closeModal();
        },
        onError: () => {
            toast.error('Boolean search not saved');
            console.error('Boolean search not saved', data);
        }
    });
    const handleSave = () => {
        if (!queryName.trim()) {
            setEmptyName(true);
            toast.error('Resolve all errors before continuing');
        }
        else if ((!error && !emptyName && queryName)) {
            saveBooleanSearch({
                variables: {
                    user: user.id,
                    name: queryName.trim(),
                    situationRoom: roomId,
                    query: booleanQuery
                }
            });
        } else {
            toast.error('Resolve all errors before continuing');
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const finalName = e.target.value;
        setEmptyName(finalName?.trim() === '');
        if (e.target.value.length <= 70) {
            setError(queryNamesList?.includes(finalName) || false);
            setQueryName(finalName);
        }
    };

    return (
        <div>
            <p className="mt-3">Enter Boolean search name</p>
            <InputGroup border="none"
                state={(error || (emptyName)) ? 'error' : ''}
                className="mt-1"
            >
                <Input autoFocus
                    value={queryName}
                    onChange={(e) => handleOnChange(e)}
                    data-testid="boolean-name-input"

                />
                <span className="text-disabled mt-1 ml-1 mr-2">
                    ({queryName?.length}/70)
                </span>
                {(queryName?.trim() && !error) && (
                    <InputGroupAddon addonType="append">
                        <InputGroupText className="border-0 py-1 pr-1 pl-0 bg-white">
                            <Check color={color.green[200]} size={16} strokeWidth={3} />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
            </InputGroup>
            {error && (
                <p className="text-danger mt-1">
                    This name already exists. Choose a different name.
                </p>
            )}
            {((!queryName?.trim() && queryName !== '') || emptyName)
             && <p className="text-danger">Enter a valid name</p>}
            <hr className="mt-3 mb-3" />
            <div className="d-flex">
                <Button className="mr-2" onClick={closeModal} disabled={loading}>Cancel</Button>
                <Button color="primary"
                    onClick={() => handleSave()}
                    disabled={loading}
                    data-testid="saveFilter-button"
                >Save
                </Button>
            </div>
        </div>
    );
};

export const SAVE_BOOLEAN_SEARCH = gql`
    mutation saveBooleanSearch($query: String, $name: String, $situationRoom: ID!,$user: ID,  $id: ID) {
        saveBooleanSearch(query: $query, name: $name, situationRoom: $situationRoom, user: $user, id: $id) {
            id
            name
            query
            user {
                displayName
            }
        }
    }
`;
