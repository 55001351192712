import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Popover,
    PopoverBody
} from 'reactstrap';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import * as FeatherIcon from 'react-feather';
import {
    X,
    Plus,
    Tag,
    Minus,
    Search,
    Trash2
} from 'react-feather';
import { useDropDown } from '../../../utils/useDropdown';
import { getRoom } from '../../../utils/variables';
import { LabelsDataProps, SaveInputLabel, UPDATE_CONTENT_LABELS } from './InputLabelName';
import { DeleteLabels } from './DeleteLabels';
import overview from '../../../assets/images/emptyStates/overview.svg';
import { getToast } from '../../../utils/getToast';
import { Loading } from '../../../components/Loading';
import { color } from '../../../utils/getColors';

export type LabelDropdownProps = {
    roomId: string,
    contentId: string,
    children?: React.ReactNode
}

export const LabelDropdown = ({ roomId, contentId, children }: LabelDropdownProps) => {
    const { cache } = useApolloClient();
    const [dropdown, toggleDropdown] = useDropDown();
    const toast = getToast();
    const [openDeletePopover, setOpenDeletePopover] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [deleteLabels, setDeleteLabels] = useState(false);
    const room = getRoom();
    const [selected, setSelected] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [deleteLabelIds, setDeleteLabelIds] = useState<string[]>([]);
    const [labelsToDisplay, setLabelsToDisplay] = useState<LabelsDataProps[]>([]);
    const labelsFeatureEnabled = !!room.instance?.plan?.others?.labels;
    const [updateContentLabel] = useMutation(UPDATE_CONTENT_LABELS, {
        refetchQueries: [GET_CONTENT_LABELS],
        onCompleted: () => {
            toast.success('Content labels successfully updated');
        }
    });

    const { data, loading } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'network-only',
        skip: !labelsFeatureEnabled
    });

    const labelsData = useMemo(() => data?.getContentLabels || [], [data?.getContentLabels]);

    useEffect(() => {
        const selectedLabelIds: string[] = [];
        labelsData.forEach((a: LabelsDataProps) => {
            if (a.contentIds.includes(contentId)) {
                selectedLabelIds.push(a.id);
            }
        });
        setSelected(selectedLabelIds);
        setLabelsToDisplay(labelsData);
    }, [contentId, data, labelsData]);

    const handleSelectLabel = (id: string) => {
        const isPresent = selected.includes(id);
        if (isPresent) {
            const newSelected = selected.filter((a) => a !== id);
            setSelected(newSelected);
        } else {
            setSelected([...selected, id]);
        }
    };
    const handleDeleteLabels = (id: string) => {
        const isPresent = deleteLabelIds.includes(id);
        if (isPresent) {
            const newSelected = deleteLabelIds.filter((a) => a !== id);
            setDeleteLabelIds(newSelected);
        } else {
            setDeleteLabelIds([...deleteLabelIds, id]);
        }
    };
    const togglePopover = () => {
        if (isOpen) {
            const addedLabelIds: string[] = [];
            const removedLabelIds: string[] = [];
            labelsData.forEach((a: LabelsDataProps) => {
                if (!a.contentIds.includes(contentId) && selected.includes(a.id)) {
                    addedLabelIds.push(a.id);
                } if (a.contentIds.includes(contentId) && !selected.includes(a.id)) {
                    removedLabelIds.push(a.id);
                }
            });
            if (addedLabelIds.length > 0 || removedLabelIds.length > 0) {
                updateCacheManually(addedLabelIds, removedLabelIds);
                updateContentLabel({
                    variables: {
                        contentId,
                        addedLabelIds,
                        removedLabelIds
                    }
                });
            }
        }
        setOpen(!isOpen);
    };
    const updateCacheManually = (addedLabelIds: string[], removedLabelIds: string[]) => {
        labelsToDisplay.forEach(label => {
            if (removedLabelIds.includes(label.id)) {
                cache.modify({
                    id: cache.identify({ __typename: 'ContentLabel', id: label.id }),
                    fields: {
                        contentIds: (existing = []) => existing.filter((a: string) => a !== contentId)
                    }
                });
            }
            if (addedLabelIds.includes(label.id)) {
                cache.modify({
                    id: cache.identify({ __typename: 'ContentLabel', id: label.id }),
                    fields: {
                        contentIds: (existing = []) => [...existing, contentId]
                    }
                });
            }
        });
    };
    const handleSearch = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (searchValue) {
            const matched: LabelsDataProps[] = [];
            labelsData.forEach((item: LabelsDataProps) => {
                if (item.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                    matched.push(item);
                }
            });
            setLabelsToDisplay(matched);
        }
        else {
            setLabelsToDisplay(labelsData);
        }
    };

    const handleOnClickLabel = (labelId: string) => {
        if ((!selected.includes(labelId) && !deleteLabels) || (selected.includes(labelId) && !deleteLabels)) {
            handleSelectLabel(labelId);
        } else if (deleteLabels) {
            handleDeleteLabels(labelId);
        }
    };

    const handleOnClikcDelete = () => {
        if (deleteLabelIds.length > 0) {
            setOpenDeletePopover(true);
        } else {
            toast.error('You need to select labels to be deleted before you submit ‘Delete’.');
        }
    };

    useEffect(() => {
        if (labelsToDisplay.length === 0 && labelsData.length !== 0 && searchValue === '') {
            setLabelsToDisplay(labelsData);
        }
    }, [labelsData, labelsToDisplay.length, searchValue]);
    const replacedId = contentId.includes('.') ? contentId.replace(/\./g, '-') : contentId;
    useEffect(() => {
        if (!isOpen) {
            const selectedLabelIds: string[] = [];
            labelsData.forEach((a: LabelsDataProps) => {
                if (a.contentIds.includes(contentId)) {
                    selectedLabelIds.push(a.id);
                }
            });
            setSelected(selectedLabelIds);
            setDeleteLabels(false);
            setDeleteLabelIds([]);
        }
    }, [contentId, isOpen, labelsData]);

    if (!labelsFeatureEnabled) return null;

    return (
        <>
            {children || (
                <div id={`label-${replacedId}`}
                    className={`ml-1 mt-0 cursor-pointer ${isOpen ? 'label-btn ' : 'non-label-btn'}`}
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                    data-testid="label-content"
                >
                    <Tag size={24} />
                </div>
            )}
            <Popover isOpen={isOpen}
                toggle={togglePopover}
                position="auto"
                placement="auto"
                target={`${children ? 'child-' : ''}label-${replacedId}`}
                trigger="legacy"
                className="label-dropdown"
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            >
                <PopoverBody className="label-box">
                    {!openDeletePopover ? (
                        <div className="p-2">
                            <div className=" mb-1">
                                {(labelsData.length > 0 && !loading) && (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <h3>Add Labels</h3>

                                            <X color="#050E2B"
                                                size={24}
                                                data-testid="close-label-modal"
                                                className="cursor-pointer"
                                                stroke="#050E2B"
                                                onClick={togglePopover}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <form onSubmit={handleSearch} className="d-flex flex-1">
                                                <InputGroup border={searchValue ? 'active' : 'none'}>
                                                    <Input value={searchValue}
                                                        onChange={(e) => setSearchValue(e.target.value)}
                                                        placeholder="Search for labels"
                                                    />
                                                    {searchValue && (
                                                        <InputGroupAddon addonType="prepend" className="border-right small-border">
                                                            <InputGroupText role="button"
                                                                onClick={() => setSearchValue('')}
                                                                data-testid="x-icon"
                                                            >
                                                                <FeatherIcon.X color={color.blue[500]} size={16} />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    )}
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText role="button"
                                                            onClick={(e) => handleSearch(e as React.MouseEvent<HTMLButtonElement>)}
                                                        >
                                                            <Search size={16} color={color.blue[500]} />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </form>
                                            <span>
                                                <Dropdown onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                                    className="d-inline delete-label-dropdown"
                                                    isOpen={dropdown}
                                                    toggle={toggleDropdown}
                                                    tag="div"
                                                    direction="left"
                                                    data-testid="delete-dropdown"
                                                >
                                                    <DropdownToggle className="border-0 mw-0 mr-n1 ">
                                                        <span><i className={dropdown ? 'fa fa-ellipsis-v text-selected'
                                                            : 'fa fa-ellipsis-v text-primary'}
                                                        />
                                                        </span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setDeleteLabels(true)}>
                                                            <span>Delete labels</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {(!loading && labelsData.length === 0)
                                    && (
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2>No labels have been created yet.</h2>
                                            <X color="#050E2B"
                                                size={24}
                                                data-testid="close-modal"
                                                className="cursor-pointer"
                                                stroke="#050E2B"
                                                onClick={togglePopover}
                                            />
                                        </div>
                                    )}
                                {(loading && <Loading />)}
                            </div>
                            <hr className="label-hr-line" />
                            <div className="labels-container scrollbar-small overflow-y-auto">
                                {labelsToDisplay.length !== 0 && (labelsToDisplay.map((label: LabelsDataProps) => (
                                    <div color="primary"
                                        key={label.id}
                                        className={((selected.includes(label.id) && !deleteLabels)
                                     || deleteLabelIds.includes(label.id)) ? 'tag selected-label mb-1 mr-1 py-1 pr-0 pl-1 cursor-pointer'
                                            : 'tag mb-1 mr-1 py-1 pr-0 pl-1 cursor-pointer'}
                                        onClick={() => handleOnClickLabel(label.id)}
                                    >
                                        <span className="dont-break-out">
                                            {label.label}
                                        </span>
                                        {
                                            (!selected.includes(label.id) && !deleteLabels) && (
                                                <button type="button"
                                                    className="label-icon-btn"
                                                    data-testid={label.id}
                                                >
                                                    <Plus color={color.blue[500]} size={24} />
                                                </button>
                                            )
                                        }
                                        {
                                            (selected.includes(label.id) && !deleteLabels) && (
                                                <button type="button"
                                                    className="label-icon-btn"
                                                    data-testid={label.id}
                                                >
                                                    <Minus color={color.grey.white} size={24} />
                                                </button>
                                            )
                                        }
                                        {
                                            deleteLabels && (
                                                <button type="button"
                                                    className="label-icon-btn"
                                                    data-testid={label.id}
                                                >
                                                    <Trash2 color={deleteLabelIds.includes(label.id) ? color.grey.white : color.blue[500]}
                                                        size={24}
                                                    />
                                                </button>
                                            )
                                        }
                                    </div>
                                )))}
                                {
                                    labelsData.length === 0 && (
                                        <div>
                                            <p className="mb-1">Labels allows you to manually label documents within
                                                this platform for easier filtering.
                                            </p>
                                            <p>To create a new label click `Create new label`</p>
                                        </div>
                                    )
                                }
                                {(labelsToDisplay.length === 0 && labelsData.length > 0) && (
                                    <div className="d-flex flex-column align-items-center">
                                        <img src={overview} />
                                        <h2>There’s nothing to see here</h2>
                                        <p className="text-center">No data has been found based on your current search. Update
                                            your search and check again.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <hr className="label-hr-line" />
                            <div className={labelsData.length > 0 ? 'd-flex justify-content-end mt-2' : 'mt-2'}>
                                {deleteLabels ? (
                                    <>
                                        <Button color="secondary"
                                            onClick={() => { setDeleteLabels(false);
                                                setDeleteLabelIds([]); }}
                                        >Cancel
                                        </Button>
                                        <Button color="danger"
                                            data-testid="delete-label"
                                            className="ml-2"
                                            onClick={() => handleOnClikcDelete()}
                                        >Delete
                                        </Button>
                                    </>
                                ) : (
                                    <SaveInputLabel labelNames={labelsData.map((a: LabelsDataProps) => a.label)}
                                        roomId={roomId}
                                        contentId={contentId}
                                        selected={selected}
                                        labelsData={labelsData}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <DeleteLabels setDeleteLabels={setDeleteLabels}
                            setOpenDeletePopover={setOpenDeletePopover}
                            labelIds={deleteLabelIds}
                            setDeleteLabelIds={setDeleteLabelIds}
                            labelsData={labelsData}
                        />
                    )}
                </PopoverBody>
            </Popover>
        </>
    );
};

export const GET_CONTENT_LABELS = gql`
    query getContentLabels($situationRoom: ID!) {
        getContentLabels(situationRoom: $situationRoom) {
            id
            label
            contentIds
        }
    }
`;
