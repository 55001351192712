import React, { useState } from 'react';
import { ContentTableContentType, ContentTableView } from '../Organisms/ContentTableView';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { useModal } from '../../context/modal/ModalComponent';
import { RemoveContentModal } from '../CommonModals/RemoveContent';
import { RequestInvestigation } from '../RequestInvestigation';
import { ThreatIdentifier } from '../CommonModals/ThreatIdentifier';
import { NotThreatIdentifierModal } from '../CommonModals/NotThreatIdentifier';
import { useUser } from '../../context/user/UserContext';
import { getRoom } from '../../utils/variables';

type PaginationType = {
    count: number
    offset: number
    setOffset: (e: number) => void;
}

type ThreatsWatchlistContentTableProps = {
    contents: ContentTableContentType[]
    pagination?: PaginationType
    total: number
    isPreview?: boolean
}

export const ThreatsWatchlistContentTable = ({
    contents,
    pagination,
    total,
    isPreview
}: ThreatsWatchlistContentTableProps) => {
    const room = getRoom();
    const { notAllowed } = useUser();
    const { setModal, closeModal } = useModal();
    const [selectedContentItems, setSelectedContentItems] = useState([] as ContentTableContentType[]);
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const noActionsAllowed = notAllowed.includes('editData') || isPreview || !contentActionsEnabled;
    const handleSelectAll = () => setSelectedContentItems(contents);
    const handleClearSelection = () => {
        setSelectedContentItems([]);
    };
    const setRemoveContentModal = (data: { id: string }[]) => {
        setModal({
            modal: <RemoveContentModal removeData={data}
                type={CONTENT_TYPE.THREAT}
                onCompleted={handleClearSelection}
            />,
            goToPreviousOnClose: true
        });
    };
    const setRequestInvestigationModal = (content: ContentTableContentType) => setModal({
        modal: <RequestInvestigation defaultDetails={content} onClose={closeModal} />,
        goToPreviousOnClose: true
    });
    const setMarkAsThreatModal = (id: string) => setModal({
        modal: <ThreatIdentifier onClose={closeModal} threatId={id} />,
        goToPreviousOnClose: true
    });
    const setMarkAsNotThreatModal = (ids: string[]) => {
        setModal({ modal: <NotThreatIdentifierModal ids={ids} />, goToPreviousOnClose: true });
    };

    return (
        <ContentTableView contents={contents}
            selectedContentItems={selectedContentItems}
            total={total}
            pagination={pagination}
            allowSelectBulkActions={false}
            noActionsAllowed={noActionsAllowed}
            onSelectAll={handleSelectAll}
            onClearSelection={handleClearSelection}
            onSetSelectedContentItems={setSelectedContentItems}
            onRemoveContent={setRemoveContentModal}
            onRequestInvestigation={setRequestInvestigationModal}
            onMarkAsThreat={setMarkAsThreatModal}
            onMarkAsNotThreat={setMarkAsNotThreatModal}
        />
    );
};
