type SentimentType = {
    sentiment: string
    score: string
}

export const calculateAverageSentiment = (sentimentArr: SentimentType[]) => {
    const scores = sentimentArr.map((sentiment: any) => sentiment.score);
    const average = scores.reduce((largest: any, current: any) => (current
        > largest ? current : largest), sentimentArr[0]);
    return average.sentiment;
};
