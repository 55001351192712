import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../services/Content/contentInterface';
import { NotThreatIdentifierModal } from '../CommonModals/NotThreatIdentifier';

type MarkAsNotThreatButtonProps = {
    selectedContent: Pick<Content, WatchlistButtonContentKeys>[]
    onCompleted: () => void
    color?: string
    disabled?: boolean
    className?: any
}

export const MarkAsNotThreatButton = ({
    selectedContent,
    onCompleted,
    className,
    color,
    disabled = false
}: MarkAsNotThreatButtonProps) => {
    const { setModal } = useModal();

    const selectedItemContainsNonThreats = selectedContent.find(content => content.threatObject?.threatLevel === 'none');

    if (selectedItemContainsNonThreats) return null;

    const setNotThreatIdentifierModal = () => setModal({
        modal: <NotThreatIdentifierModal ids={selectedContent.map(({ id }) => id)}
            onCompleted={onCompleted}
        />
    });
    return (
        <Button className={className || 'mr-1'}
            color={color || 'primary'}
            onClick={setNotThreatIdentifierModal}
            disabled={!!disabled}
        >
            Mark as not threat
        </Button>
    );
};
