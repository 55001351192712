import React, { useState } from 'react';
import { Table } from 'reactstrap';
import noTeamMember from '../../../assets/images/noTeamMember.svg';
import { SortToggle } from '../../../components/Table/SortToggle';
import { lodashSort } from '../../../utils/lib/tableSorting';
import { UserRow } from './UserRow';

export const UsersTable = ({ filteredUsers, page }) => {
    const [sort, setSort] = useState({ field: 'displayName', sort: 'dsc' });
    return (
        <Table className="mb-0 border-bottom" hover>
            <thead>
                <tr>
                    <th>
                        <div className="d-flex">
                            <SortToggle sort={sort} setSort={setSort} field="displayName" name="Name" />
                        </div>
                    </th>
                    <th>
                        <div className="d-flex">
                            <SortToggle sort={sort} setSort={setSort} field="email" name="Email" />
                        </div>
                    </th>
                    <th>
                        <div className="d-flex">
                            <SortToggle sort={sort} setSort={setSort} field="role" name="Role" />
                        </div>
                    </th>
                    <th>
                        <div className="d-flex">
                            <SortToggle sort={sort} setSort={setSort} field="status" name="Status" />
                        </div>
                    </th>
                    <th>
                        <div className="d-flex">
                            <SortToggle sort={sort} setSort={setSort} field="lastSeen" name="Last seen" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {filteredUsers.length > 0 ? (
                    lodashSort(filteredUsers, sort).slice(page, page + 10).map((userData) => <UserRow key={userData.id} user={userData} />)
                ) : (
                    <tr>
                        <td colSpan="6" className="text-center pb-4 pt-4">
                            <img src={noTeamMember}
                                alt="no-team-member-svg"
                                data-testid="empty-state"
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};
