import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Button } from 'reactstrap';

type OpenButtonProps = {
    name: string,
    handleClick: () => void,
    isOpen: boolean,
    slidersIcon?: boolean,
    hasValues: boolean,
    setFilterName: (e: string) => void
}

export const OpenButton = ({
    name,
    handleClick,
    isOpen,
    slidersIcon = false,
    hasValues, setFilterName
}: OpenButtonProps) => {
    if (hasValues && name === 'Filters') {
        setFilterName('Custom selected');
    }
    return (
        <Button className={`d-flex align-items-center ${hasValues ? 'btn-has-filters' : ''}`}
            onClick={handleClick}
            data-testid="new-filters-button"
        >
            {slidersIcon && <FeatherIcon.Sliders size={16} className="mr-2" />}
            {(hasValues && name === 'Filters') ? 'Custom selected' : name}
            <FeatherIcon.ChevronDown className={`ml-11 transform-transition ${isOpen ? 'transform-btn-icon' : ''}`} />
        </Button>
    ); };
