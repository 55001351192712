import React, { useState } from 'react';
import {
    Form,
    Button,
    Label,
    FormGroup,
    Spinner
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import { ArrowLeft } from 'react-feather';
import { FORGOT_PASSWORD } from '../../graphql/Mutations';
import { Input } from '../../components/Form/Input';

type ForgotPasswordProps ={
    setForgotPasswordFlow: (forgotPass: boolean) => void
}

export const ForgotPassword = ({ setForgotPasswordFlow }: ForgotPasswordProps) => {
    const [email, setEmail] = useState('');
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');

    const checkEmailFormat = (e: React.FormEvent<HTMLFormElement>, emailCheckOnly?: string) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        e.preventDefault();
        if (!email) {
            setEmailError('Missing email, please enter your email.');
        }
        else if (email.indexOf('@') === -1) {
            setEmailError('This is an invalid email, emails should contain an "@" and a domain.');
        }
        else if (email.indexOf('.') === -1) {
            setEmailError('Email must contain a "." after the "@" symbol.');
        }
        else if (!pattern.test(email)) {
            setEmailError('Invalid email address format.');
        }
        else if (!emailCheckOnly) handleEmailSubmit();
    };

    const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
        onCompleted: () => {
            setPasswordResetSent(true);
        }
    });

    const handleEmailSubmit = () => {
        if (/\S+@\S+\.\S+/.test(email)) {
            forgotPassword({
                variables: {
                    email
                }
            });
        } else {
            setError('Please enter a valid email address.');
        }
    };

    if (passwordResetSent) {
        return (
            <div>
                <h1>Forgot your password</h1>
                <p>If an account exists for <strong>{email}</strong> we&rsquo;ll send link for resetting your password.</p>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                    <Button color="link" className="d-flex align-items-center mb-2 p-0" onClick={() => setForgotPasswordFlow(false)}>
                        <ArrowLeft size={18} />
                        <p className="my-0 ml-1 text-primary">Back to login</p>
                    </Button>
                    <Button color="link" className="p-0" onClick={() => setPasswordResetSent(false)}>Resend the link</Button>
                </div>
            </div>
        );
    }

    return (
        <div className="forgot-password">
            <h1>Forgot your password</h1>
            <p>Enter your email and we&rsquo;ll send you a link to reset your password.</p>
            <hr />
            <Form onSubmit={checkEmailFormat}>
                <FormGroup className="mb-2">
                    <Label for="email" className="mb-1">Email</Label>
                    <Input value={email}
                        onChange={(e) => { setEmail(e.target.value); setError(''); setEmailError(''); }}
                        onBlur={(e) => checkEmailFormat(e, 'emailCheckOnly')}
                        type="text"
                        error={emailError || error}
                        state={(error || emailError) ? 'error' : undefined}
                        data-testId="emailInput"
                    />
                </FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                    <Button color="link" className="d-flex align-items-center mb-2 p-0" onClick={() => setForgotPasswordFlow(false)}>
                        <ArrowLeft size={18} />
                        <p className="my-0 ml-1 text-primary">Back to login</p>
                    </Button>
                    <Button type="submit" color="primary">
                        {loading ? <Spinner size="sm" color="white" /> : 'Submit'}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
