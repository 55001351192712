import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { CheckboxFilter } from '../../../../components/Filters';
import { NumberInput } from '../../../../components/Atoms';
import { useInstance } from './InstanceContext';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { MultiSelectDropdown } from '../../../../components/Form/MultiSelectDropdown';
import { allTypes } from '../../../../utils/lib/search';

const entitlementsOptions = [
    {
        title: 'Core features',
        options: [
            { key: 'logicallyFactChecks', label: 'Logically Fact Checks' },
            { key: 'narratives', label: 'Custom Narratives' },
            { key: 'overview', label: 'Overview' },
            { key: 'threats', label: 'Threats' },
            { key: 'threatSignals', label: 'Threats explanation updates' },
            { key: 'aiClustering', label: 'AI Narratives' },
            { key: 'networks', label: 'Networks' }
        ],
        field: 'plan.core'
    },
    {
        title: 'Integrations',
        options: [
            { key: 'investigations', label: 'Investigations' },
            { key: 'identifiedActorEnrichments', label: 'Identified Actor Enrichments' },
            { key: 'crowdtangleCSV', label: 'Crowdtangle CSV Upload' },
            { key: 'reports', label: 'Reports' },
            { key: 'watchlist', label: 'Watchlist' }
        ],
        field: 'plan.integrations'
    },
    {
        title: 'Others',
        options: [
            { key: 'contentActions', label: 'Content Actions' },
            { key: 'dateInLocalTimeZone', label: 'Show dates in local time zone' },
            { key: 'newContentTableView', label: 'New content table view' },
            { key: 'betaFilters', label: 'Beta filters' },
            { key: 'labels', label: 'Labels' },
            { key: 'aiNarrativeCoordinates', label: 'AI Narrative Coordinates' },
            { key: 'aiNarrativeComparisons', label: 'AI Narrative Comparisons' },
            { key: 'alerts', label: 'Notifications' },
            { key: 'newEtl', label: 'New ETL' }
        ],
        field: 'plan.others'
    }
];

const entitlementsLimits = [
    { title: 'User limit', field: 'plan.userLimit' },
    { title: 'Situation Room limit', field: 'plan.situationroomLimit' }
];

export const EntitlementsForm = () => {
    const { instance, handleChange } = useInstance();
    const { user } = useAdminUser();
    const threatTypes = allTypes.map(({ name, key }) => ({
        id: key,
        text: name
    }));
    const selectedTypes = threatTypes.filter(({ id }) => instance.plan.disabledThreatTypes.includes(id));
    const disabledOptions = instance.plan.core.narratives ? [] : [{ key: 'custom',
        text: 'Enable Custom Narratives to enable Custom threats' }];
    useEffect(() => {
        if (!instance.plan.core.narratives && instance.plan.disabledThreatTypes.includes('custom')) {
            handleChange(instance.plan.disabledThreatTypes.filter((a) => a !== 'custom'), 'plan.disabledThreatTypes');
        }
    }, [handleChange, instance.plan.core.narratives, instance.plan.disabledThreatTypes]);

    return (
        <div>
            <h3 className="m-0">Entitlements</h3>
            <hr className="my-3" />
            <Row className="mb-n2">
                {entitlementsOptions.map(({ field, title, options }) => {
                    const selectedValues = get(instance, field);
                    return (
                        <Col xs={12} lg={6} key={field} className="mb-2">
                            <p className="font-weight-bold">{title}</p>
                            <CheckboxFilter title={title}
                                filters={options}
                                values={
                                    options.filter(option => !selectedValues[option.key])
                                        .map(option => option.key)
                                }
                                setValues={(values) => handleChange(Object.fromEntries(
                                    options.map(option => ([
                                        option.key,
                                        !values.includes(option.key)
                                    ]))
                                ), field)}
                                value={field}
                                disabled={user.role !== 'ADMIN'}
                                subLevel={['Threats explanation updates']}
                            />
                        </Col>
                    );
                })}
            </Row>
            <hr className="my-3" />
            <Row>
                <Col xs={12} lg={12} xl={6}>
                    {entitlementsLimits.map(({ title, field }) => (
                        <NumberInput id={field}
                            label={title}
                            value={get(instance, field)}
                            onChange={(val) => handleChange(val, field)}
                            className="mb-2"
                            key={field}
                            disabled={user.role !== 'ADMIN'}
                        />
                    ))}
                </Col>
                <Col xs={12} lg={12} xl={6} className="mw-300">
                    <p>Disabled threat types</p>
                    <MultiSelectDropdown id="disabledThreatTypes"
                        options={threatTypes}
                        defaultOptions={selectedTypes}
                        onChange={(values) => handleChange(values.map(({ id }) => id), 'plan.disabledThreatTypes')}
                        disabledOptions={disabledOptions}
                    />
                </Col>
            </Row>
        </div>
    );
};
