import React from 'react';
import { NoDataAvailableState } from '../../../../../components/EmptyState';
import { EngagementCard } from './EngagementCard';
import { TooltipItem } from '../../../../../components/ToolTip';

export type EngagementDataType = {
    value: string;
    count: number;
};

type EngagementCardsProps = {
    engagementData: EngagementDataType[];
}

export const EngagementCards = ({ engagementData }: EngagementCardsProps) => (
    <>
        <div className="d-flex align-items-center mb-1">
            <h3 className="m-0">Engagement</h3>
            <TooltipItem id="engagementTooltip"
                content="Our analysis of the engagement of this content so you can see how it is being engaged with"
                placement="right"
            />
        </div>
        {engagementData.length > 0 ? (
            <div className="row row-cols-4 gap-2 ml-0 engagement-context">
                {engagementData.map(item => (
                    <EngagementCard key={item.value} value={item.value} count={item.count || -1} />
                ))}
            </div>
        ) : (
            <NoDataAvailableState />
        )}
    </>
);
