import React from 'react';
import { Table } from 'reactstrap';
import { Source } from '../../types';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { getCtype } from '../../../../../utils/text';

type TopSourcesProps = {
    topSources: Source[]
}

export const TopSources = ({ topSources }: TopSourcesProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], sourceName: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={sourceName} />,
        goToPreviousOnClose: true,
        size: 'xl'
    });
    if (!topSources?.length) {
        return <div className="border m-2"><EmptyState title="No data found" icon={Images().overview} /></div>;
    }
    return (
        <div className="bg-white px-2 pt-2 pb-1 w-100 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <Table hover className="border-left border-right border-bottom w-50">
                <thead>
                    <tr>
                        <th>Sources</th>
                        <th>Contents</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        topSources.map((sourceData) => {
                            const { name } = getCtype(sourceData.source);
                            return (
                                <tr key={sourceData.source}>
                                    <td className="w-75 text-primary cursor-pointer"
                                        onClick={() => openDuplictedDocsModal(sourceData.contentIds, name)}
                                    >
                                        {name}
                                    </td>
                                    <td>{sourceData.contentIds.length}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
};
