import React from 'react';
import { UncontrolledTooltipProps } from 'reactstrap';
import { TooltipWrapper } from './TooltipWrapper';

type TextTooltipProps = {
    title: string
    text?: string
    id?: string,
    placement: UncontrolledTooltipProps['placement'],
    children?: React.ReactNode | string
    className?: string,
    withIcon?: boolean
}

export const TextTooltip = ({ title, text, id = '', placement = 'bottom', children, className, withIcon = false }: TextTooltipProps) => {
    const tooltipId = id ? `tooltip-${id.replaceAll(' ', '-')}` : `tooltip-${title.replaceAll(' ', '-')}`;
    if (withIcon) {
        return (
            <TooltipWrapper tooltipText={title} id={tooltipId} placement={placement} className={`${className} tooltip-hover`}>
                {children}
            </TooltipWrapper>
        );
    }
    return (
        <TooltipWrapper tooltipText={children || text} id={tooltipId} placement={placement} className={`${className} tooltip-hover`}>
            <p className={`m-0 ${(id && id !== 'beta-labels') && 'text-primary'}`} id={tooltipId}>{title}</p>
        </TooltipWrapper>
    );
};
