import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { User } from '../../../../assets/svg/user';
import { Loading } from '../../../../components/Loading';
import { UPDATE_SITUATION_ROOM } from '../../../../graphql';

export const AddUserModal = ({ users, refetch, room }) => {
    const [added, setAdded] = useState([]);
    const [updateSituationRoom, { loading }] = useMutation(UPDATE_SITUATION_ROOM, {
        onCompleted: () => {
            refetch();
        }
    });
    return (
        <div className="position-relative">
            {loading && <Loading />}
            <Table>
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Role
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => {
                        if (added.includes(user.id)) { return null; }
                        return (
                            <tr key={user.id}>
                                <td>
                                    {user.photoURL ? (
                                        <img src={user.photoURL}
                                            alt="logically user"
                                            className="rounded-circle mr-2"
                                            style={{ width: 32, height: 32 }}
                                        />
                                    )
                                        : <span> <User size="106" /></span>}
                                    {user.displayName || `${user.firstName || ''} ${user.lastName || ''}`}
                                </td>
                                <td>
                                    {user.email}
                                </td>
                                <td className="text-capitalize">{user.role.toLowerCase()}</td>
                                <td>
                                    <p className="text-primary"
                                        role="button"
                                        onClick={() => {
                                            updateSituationRoom({
                                                variables: {
                                                    id: room.id,
                                                    removedUsers: room.removedUsers.filter(a => a !== user.id)
                                                }
                                            });
                                            setAdded([...added, user.id]);
                                        }}
                                    >
                                        Add
                                    </p>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};
