import { color as allColors } from './getColors';

const chartColor = [
    { sentiment: 'positive', color: allColors.green[200] },
    { sentiment: 'negative', color: allColors.red[200] },
    { sentiment: 'neutral', color: '#FCD742' },
    { sentiment: 'unknown', color: allColors.graphColors.unknown }
];

export const getSentimentColor = (sentiment: string) => chartColor?.filter(color => color?.sentiment === sentiment)[0]?.color;
