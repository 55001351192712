import React from 'react';
import { Alert } from '../../../../components/Atoms';
import { BooleanInputValidated } from '../../../../components/Molecules/BooleanInputValidated';
import { useRoomEditor } from '../RoomEditorContext';

export const BooleanQuery = ({
    booleanQuery,
    setBooleanQuery,
    disabled = false
}) => {
    const { errors, setErrors, validationErrors, setValidationErrors } = useRoomEditor();
    return (
        <div className="d-flex flex-column flex-1">
            {errors.query && (
                <div>
                    <Alert closeAlert={() => setErrors({ ...errors, query: false })}
                        errorMessage={(
                            <>
                                <span>
                                    We cannot submit your query, please check the Help tab and revise your query before resubmitting.
                                </span>
                                <div className="mb-n3">
                                    {errors.query.map((error, i) => {
                                        const key = error.errorCode + i;
                                        return <p key={key} className="mb-0 mt-1">{error.message}</p>;
                                    })}
                                </div>
                            </>
                        )}
                    />
                </div>
            )}
            {errors.emptyBoolean && <p className="text-danger">Your room must have a Boolean to operate.</p>}
            {errors.validationErrors && <p className="text-danger">Remove all errors by amending the Boolean.</p>}
            {(!errors.emptyBoolean && errors.noChanges) && <p className="text-danger">Make amendments to the Boolean before saving.</p>}
            <div className="border rounded d-flex flex-column flex-1">
                <BooleanInputValidated booleanQuery={booleanQuery}
                    setBooleanQuery={setBooleanQuery}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                    disabled={disabled}
                    resetGlobalErrors={() => {
                        if (errors.query) {
                            setErrors({ ...errors, query: false });
                        }
                        if (errors.validationErrors) {
                            setErrors({ ...errors, validationErrors: false });
                        }
                    }}
                />
            </div>
        </div>
    );
};
