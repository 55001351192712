import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSessionUser } from '../../auth0';
import { Loading } from '../../components/Loading';
import { getParam } from '../../utils/urlParams';
import { CREATE_SSO_USER } from '../../graphql/Mutations';
import { useUser } from '../../context/user/UserContext';

export const SSORedirect = () => {
    const ssoInstanceId = getParam('ssoInstanceId'); // only needed to populated mongo for SSO users on first log in/sign up
    const { user: sessionUser, loading: sessionLoading } = useSessionUser();
    const [userCreationLoading, setUserCreationLoading] = useState(false);
    const [createSSOUser] = useMutation(CREATE_SSO_USER, {
        onCompleted: (data) => {
            setUserCreationLoading(false);
            setUser(data.createSSOUser);
        }
    });
    const { user, setUser } = useUser();

    if (!sessionLoading && !userCreationLoading) {
        if (!user && sessionUser) {
            if (!ssoInstanceId) {
                return (
                    <div className="content-centered">
                        <h2 className="m-0 pt-3 ">
                            Please validate your SSO sign up link!
                        </h2>
                    </div>
                );
            }

            setUserCreationLoading(true);

            createSSOUser({
                variables: {
                    ...sessionUser,
                    instance: ssoInstanceId,
                }
            });
        }
    }

    return (
        <div className="content-centered">
            <Loading gif />
            <h2 className="m-0 pt-3 ">Please wait while we verify your SSO credentials</h2>
        </div>
    );
};
