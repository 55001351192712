import React from 'react';
import { Button } from 'reactstrap';
import { ContentTableContentType } from './index';

type RemoveContentButtonProps = {
    selectedContentItems: ContentTableContentType[]
    onRemoveContent?: (data: { id: string }[]) => void
}

export const RemoveContentButton = ({ selectedContentItems, onRemoveContent }: RemoveContentButtonProps) => {
    if (!onRemoveContent) {
        return null;
    }

    const handleRemoveContent = () => {
        const removeData = selectedContentItems.map(({ id }) => ({ id }));
        onRemoveContent(removeData);
    };
    return (
        <Button color="danger" onClick={handleRemoveContent} data-testid="remove-contents-button">
            Remove content{selectedContentItems.length > 1 && 's'}
        </Button>
    );
};
