import React from 'react';
import { sentiments } from '../../../utils/lib/search';
import { CheckboxFilter } from '../../Filters';
import { TooltipItem } from '../../ToolTip';

export const SentimentFilter = ({ sentiment = [], setSentiment, title = 'Sentiment' }) => (
    <div>
        <span className="d-flex">
            <p className="font-weight-bold">{title}</p>
            <TooltipItem placement="auto"
                id="sentiment-filters"
                content="We determine sentiment by scanning each piece of content automatically for certain
                words, language patterns, and source credibility."
                testid="sentiment-filters"
            />
        </span>
        <CheckboxFilter title={title}
            filters={sentiments}
            values={sentiment}
            setValues={setSentiment}
            value="sentiment"
        />
    </div>
);
