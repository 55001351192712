import { useMutation } from '@apollo/client';
import { getRoom } from '../../utils/variables';
import { gql } from '../../generated';

export const useStartRoomClustering = ({ refresh, recluster }: { refresh?: boolean, recluster?: boolean } = {}) => {
    const room = getRoom();
    const projectId = room?.project_id;
    const [startRoomClustering, { loading }] = useMutation(START_ROOM_CLUSTERING, {
        variables: {
            projectId,
            refresh,
            recluster
        },
        refetchQueries: ['getClusteringStatus'],
        awaitRefetchQueries: true
    });

    return {
        loading,
        startRoomClustering
    };
};

const START_ROOM_CLUSTERING = gql(
    `mutation startRoomClustering($projectId: String!, $refresh: Boolean, $recluster: Boolean) {
        startRoomClustering(projectId: $projectId, refresh: $refresh, recluster: $recluster)
    }`
);
