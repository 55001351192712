import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Loading } from '../../../../../../../components/Loading';
import { Pagination } from '../../../../../../../components/Molecules';
import { useFilterQuery } from '../../../../../../../services/Content/useFilterQuery';
import { getUpdatedReportPages } from '../getUpdatedReportPages';
import { ThreatContent } from './ThreatContent';
import { ThreatSummary } from './ThreatSummary';
import { NoResults } from '../NoResults';
import { useContent } from '../../../../../../../services/Content/getContent';
import { setReportAction } from '../../../../../../../redux/reports';
import { getRoom } from '../../../../../../../utils/variables';
import { SearchBarNew } from '../../../../../../../components/NewFilters';
import { SearchBar } from '../../../../../../../components/Search';

export const ThreatsTableContainer = () => {
    const room = getRoom();
    const filterQuery = useFilterQuery(true);
    const [offset, setOffset] = useState(0);
    const { report } = useSelector(state => state.createReport);
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    let endDate = report.filters.endDate > 0 ? report.filters.endDate : moment(moment().endOf('day').toDate()).unix();
    if (!report.filters.endDate && room.contentVisibilityRestriction) {
        endDate = moment(moment().subtract(room.contentVisibilityRestriction, 'days').endOf('day').toDate()).unix();
    }
    const { data: contents, loading, count } = useContent({
        filters: {
            isThreat: true,
            date: { endDate }
        },
        useGlobalFilters: true,
        sort: filterQuery.sort || 'date',
        fetchPolicy: 'no-cache',
        skipRequest: !room.project_id,
        skip: offset
    });

    if (loading) {
        return <Loading relative height={300} />;
    }

    if (!contents || contents?.length === 0) {
        return <NoResults />;
    }

    return (
        <ThreatsTable content={contents}
            count={count}
            offset={offset}
            setOffset={setOffset}
            fromModal
            betaFiltersEnabled={betaFiltersEnabled}
        />
    );
};

export const ThreatsTable = ({ content, count, offset, setOffset, fromModal, type = 'threats', betaFiltersEnabled = false }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const selectedThreats = report.pages.filter(a => a.type === type).map(a => a.blocks).flat();
    const { pathname } = useLocation();
    const searchBarRequired = pathname.includes('report');
    const toggleThreat = (threat, selected) => {
        const pages = getUpdatedReportPages(
            report,
            selected ? selectedThreats.filter(a => a.id !== threat.id) : [...selectedThreats, threat],
            type
        );
        dispatch(setReportAction({
            ...report,
            pages
        }));
    };

    return (
        <div>
            {searchBarRequired && betaFiltersEnabled ? <SearchBarNew score defaultFilters={{ isThreat: true }} />
                : (
                    <SearchBar placeholder="Search - Enter a search term to update the threats and press ENTER"
                        name="Page Filters"
                        additionalFilters={['whyathreat']}
                        score
                        showBooleanSearch
                    />
                )}
            <Table>
                <thead>
                    <tr>
                        <td>Threat labels & details ({count})</td>
                        <td>Content</td>
                    </tr>
                </thead>
                <tbody>
                    {content?.map(threat => {
                        if (!threat) return null;
                        const selected = selectedThreats.find(a => a.id === threat.id);
                        return (
                            <tr key={threat.id}>
                                <ThreatRow threat={threat}
                                    selected={selected}
                                    fromModal={fromModal}
                                    toggleThreat={toggleThreat}
                                />
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {setOffset && <Pagination total={count} itemsPerPage={10} offset={offset} setOffset={setOffset} className="py-2" />}
        </div>
    );
};

const ThreatRow = ({ threat, selected, fromModal, toggleThreat }) => (
    <>
        <td><ThreatSummary threatData={threat} /></td>
        <td className="d-flex flex-column">
            <ThreatContent content={threat} fromModal={fromModal} toggleThreat={toggleThreat} selected={selected} />
        </td>
    </>
);
