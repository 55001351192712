import { matchPath, useHistory } from 'react-router-dom';
import { forOwn } from 'lodash';
import { routes } from '../routes';

export const useCurrentRoute = () => {
    const history = useHistory();
    const flattenedRoutes = {} as { [key: string]: any };
    forOwn(routes, (value, key) => forOwn(value, (innerValue, innerKey) => {
        flattenedRoutes[`${key}_${innerKey}`] = innerValue;
    }));

    const currentRoutes = Object.entries(flattenedRoutes).filter(([key, value]) => matchPath(history.location.pathname, value));

    if (currentRoutes.length === 0) {
        return null;
    }

    return currentRoutes[currentRoutes.length - 1][0];
};
