export const getPostedByName = (from, displayName) => {
    if (!from && !displayName) return '';
    if (!displayName) {
        return `@${from.replace('@', '')}`;
    }
    if (displayName === from || !from) {
        return displayName;
    }
    return `@${from.replace('@', '')} (${displayName})`;
};
