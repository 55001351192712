import { convertToMarkers } from '../convertToMarkers';

export const hyphenWordWithQuotesValidator = (query: string, queryByLines: string[], lineLengths: number[]) => {
    const highlight: number[][] = [];

    if (!query || query.length === 0) {
        return {
            isValid: highlight.length === 0,
            markers: []
        };
    }

    // clean up query and split into elements using whitespace
    const queryElements = query?.trim().replaceAll(/\s*:\s*/g, ':').replaceAll(/\(\s*/g, '(').replaceAll(/\s*\)/g, ')')
        .split(/\s+/g);
    const incorrectHyphens = [];
    for (let i = 0; i < queryElements.length; i++) {
        const currentElement = queryElements[i];
        if (currentElement.includes('-') && !(currentElement.includes('from')
        || currentElement.includes('url') || currentElement.includes('link') || currentElement.includes('domain'))
        ) {
            if (!(currentElement.startsWith('"') && currentElement.endsWith('"'))) {
                incorrectHyphens.push(currentElement);
            }
        }
    }

    incorrectHyphens.map(item => {
        highlight.push([query.indexOf(item), query.indexOf(item) + item.length]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'Words with hyphens should be inside double quotes'
        )
    };
};
