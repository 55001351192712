import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useModal } from '../../context/modal/ModalComponent';
import { WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../services/Content/contentInterface';
import { useQueryExtracts } from '../../services/Content/getQueryExtracts';
import { getPostedByName } from '../../utils/getPostedByName';
import { largeNumber } from '../../utils/NumberFormat';
import { getCtype } from '../../utils/text';
import { ContentURL } from '../ActivityItem/ContentURL';
import { KeywordsModal } from '../ActivityItem/KeywordsModal';
import { SourceIconLabel } from '../SourceIconLabel';
import { ThreatLabels } from './ThreatLabels';
import { formatDate, formatDateLocal } from '../../utils/getTimeFrom';
import { getRoom } from '../../utils/variables';
import { DuplicatedDocsModal } from '../../pages/Room/AIClustering/DuplicatedDocsModal';
import { ContentId } from '../../pages/Room/AIClustering/types';

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type ContentTypes = 'body' | 'datetime' | WatchlistButtonContentKeys

type ContentMetricsProps = {
    content: Pick<Content, ContentTypes>,
    noActions: boolean
    clusterData?: ContentId[]
}

export const ContentMetrics = ({ content, noActions, clusterData }: ContentMetricsProps) => {
    const room = getRoom();
    const { setModal } = useModal();
    const location = useLocation();
    const { highlightKeywords } = useSelector((state: { roomPreviewKeywords: RoomPreviewKeywords }) => state.roomPreviewKeywords);
    const passKeywords = location.pathname.includes('create-room') || location.pathname.includes('edit-room')
        || location.pathname.includes('edit-draft-room');
    const duplicatedDoc = clusterData?.filter(cluster => cluster.id === content.id)[0]?.duplicateIds || [];

    const { keywordsMentioned, highlightedURL } = useQueryExtracts({
        url: content.url,
        text: content.body || content.text,
        keywordsToHighlight: passKeywords ? highlightKeywords : []
    });
    const openKeywordsModal = () => setModal({
        component: <KeywordsModal keywords={keywordsMentioned} />,
        goToPreviousOnClose: true
    });
    const openDuplictedDocsModal = () => setModal({
        component: <DuplicatedDocsModal contentIds={duplicatedDoc} />,
        goToPreviousOnClose: true,
        size: 'xl'
    });
    const ctype = getCtype(content?.contentType);
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;
    const threatResolvers = content.threatObject.threatResolvers?.filter(
        (resolver: any) => resolver.type === 'deep-toxic-classifier'
    ).map((type: any) => type.resolver);
    return (
        <div>
            <p className={noActions ? '' : 'content-top-bar'}>
                {ctype.disableEngagement ? null : (
                    <>
                        <strong>
                            Engagement: {content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}
                        </strong> |&#160;
                    </>
                )}
                <strong>Keywords mentioned:</strong>
                <span className="text-primary px-1 cursor-pointer"
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); openKeywordsModal(); }}
                >
                    {keywordsMentioned.length}
                </span>
                {clusterData ? (
                    <><strong>| Reposts:</strong>
                        {duplicatedDoc?.length > 0 ? (
                            <span className="text-primary px-1 cursor-pointer"
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); openDuplictedDocsModal(); }}
                            >
                                {duplicatedDoc.length}
                            </span>
                        ) : <span> {duplicatedDoc.length}</span>}
                    </>
                )
                    : null}
            </p>
            {!noActions && <hr className="mr-n3" />}
            <p className="my-1">
                <strong>Posted by:&nbsp;</strong>
                {getPostedByName(content.from, content.displayName)} via
                <SourceIconLabel data={{ contentType: content.contentType }} className="pl-1 d-inline" />
                <span className="ctype">{ctype?.name}</span>
            </p>
            <p>
                <strong>Posted on:</strong> {formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm z')}
                {dateInLocalTimeZone && (
                    ` | ${formatDateLocal(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}`
                )}
            </p>
            <p className="d-flex my-1">
                <strong className="mr-11">URL:</strong>
                <ContentURL url={highlightedURL || content.url} />
            </p>
            <p className="mb-1"><strong>Location mentions:</strong> {content.locationsDetected}</p>
            <ThreatLabels threatResolvers={threatResolvers}
                threatLabels={content.threatObject?.threatLabels}
                whyathreat={content.whyathreat}
            />
        </div>
    );
};
