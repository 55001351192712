import { useLocationMentions } from '../../../../../services/Overview';
import { withTableStructure } from './withTableStructure';

const columns = [
    { key: 'locationMentioned', label: 'Location', type: 'locations' },
    { key: 'count', label: 'Matches', percentage: true }
];

const config = {
    api: useLocationMentions,
    title: ({ preview }) => `${!preview ? 'Top 10 ' : ''}Location Mentions`,
    tooltip: 'Top locations mentioned in the content across this situation room.',
    columns,
    emptyWithoutAnnotations: true,
    label: 'Location mentions',
    id: 'topLocationMentions'
};

export const TopLocationMentions = withTableStructure(config);
