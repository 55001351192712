import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ThreatTags } from '../../../../../../../components/ActivityItem/ThreatTags';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { DeleteBlockButton } from '../getUpdatedReportPages';
import { AddThreatsModal } from './AddThreatsModal';
import { ThreatContent } from './ThreatContent';
import { ThreatSummary } from './ThreatSummary';

export const TopThreats = ({ data }) => {
    const { setModal } = useModal();
    if (data.blocks.length === 0) {
        return (
            <div className="w-100 flex-1 d-flex align-items-center justify-content-center">
                <Button color="link"
                    className="text-decoration-none"
                    onClick={() => setModal({
                        header: false,
                        size: 'full',
                        component: <AddThreatsModal />
                    })}
                >
                    + Add threats
                </Button>
            </div>
        );
    }

    return (
        <div className="font-size-10">
            {data.blocks.map(threat => {
                if (!threat) return null;
                return (
                    <div key={threat.id} className="bg-verylight border p-1 mb-2 position-relative">
                        <ThreatTags whyathreat={threat?.whyathreat} small />
                        <Row noGutters>
                            <div className="position-absolute report-right-edit-position">
                                <DeleteBlockButton block={threat} type="threats" />
                            </div>
                            <Col xs={12} md={4}>
                                <ThreatSummary threatData={threat} small />
                            </Col>
                            <Col xs={12} md={8}>
                                <ThreatContent content={threat} />
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </div>
    );
};
