import React from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { useModal } from '../../../../context/modal/ModalComponent';
import { DELETE_ADMIN_USER, GET_ADMIN_USERS } from '../../../../graphql';

export const RemoveAdminUser = ({ adminUser }) => {
    const { setModal, closeModal } = useModal();
    const [deleteAdminUser, { loading }] = useMutation(DELETE_ADMIN_USER, {
        refetchQueries: [GET_ADMIN_USERS],
        onCompleted: () => {
            closeModal();
        }
    });
    const setDeleteAdminUserModal = (user, id) => {
        setModal({
            component: (
                <>
                    <h3>{`Are you sure you want to delete ${user?.displayName || 'this user'}?`}</h3>
                    <hr />
                    <p>It will be permanently deleted.</p>
                    <hr />
                    <Button color="secondary" className="mr-3" onClick={closeModal}>Cancel</Button>
                    <Button color="danger" disabled={loading} onClick={() => deleteAdminUser({ variables: { id } })}>Delete</Button>
                </>
            ),
        });
    };

    return (
        <span className="text-danger cursor-pointer"
            onClick={() => setDeleteAdminUserModal(adminUser, adminUser.id)}
        >
            Remove user
        </span>
    );
};
