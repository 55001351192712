import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

type SortMethod<T extends string> = {
    field: T,
    order: 'desc' | 'asc'
}

type SortToggleProps<T extends string> = {
    sort: SortMethod<T>,
    setSort: (data: SortMethod<T>) => void,
    field: T,
    name: string | React.ReactNode,
    className?: string
    id?: string
}

export const SortToggle = <T extends string>({ sort, setSort, field, name, className = '', id }: SortToggleProps<T>) => (
    <div role="button"
        id={id || ''}
        className={`d-flex word-short ${className}`}
        data-testid={`sortToggle-${field}`}
        onClick={() => {
            if (sort.field !== field || sort.order === 'asc') {
                setSort({
                    field,
                    order: 'desc'
                });
            } else {
                setSort({
                    field,
                    order: 'asc'
                });
            }
        }}
    >
        {name}
        <span className="text-primary pl-11">
            {(sort.field === field && sort.order === 'asc') ? <ChevronUp size={22} /> : <ChevronDown size={22} />}
        </span>
    </div>
);
