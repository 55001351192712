import React from 'react';
import { Button } from 'reactstrap';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { getCtype, truncateString } from '../../../../../utils/text';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { formatDate, formatDateLocal } from '../../../../../utils/getTimeFrom';
import { getRoom } from '../../../../../utils/variables';
import { TranslateButton } from '../../../../../components/Atoms/TranslateButton';
import { useTranslateMultipleText } from '../../../../../services/google/translate';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';

type OriginalContentProps = {
    originatorPostId: string
}

export const OriginalContent = ({ originatorPostId }: OriginalContentProps) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const { loading, data: contentData } = useContent({
        filters: {
            ids: originatorPostId
        },
        name: 'getClusterContent'
    });
    const content = contentData && contentData[0];
    const ctype = getCtype(content?.contentType);
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const fullText = content?.body?.length > 0 ? content?.body?.replace(urlRegex, '') : content?.text?.replace(urlRegex, '');
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: fullText
    });
    const translatedText = translated.length ? translated[0].translations[0] : fullText;

    const viewSummaryModal = (summary: string) => setModal({
        component: (
            <div id={`original-poster-${content.id}`}>
                <h3 className="m-0">Original poster details</h3>
                <hr />
                <p className="my-2">{summary}</p>
                <hr />
                <div id="selected-cluster-view" className="d-flex justify-content-start align-items-center">
                    <Button onClick={closeModal} className="mr-2">Close</Button>
                    <TooltipWrapper tooltipText="Opens in the new tab"
                        id={`original-poster-${content.id}_view`}
                        placement="top-start"
                        container={`original-poster-${content.id}`}
                    >
                        <a href={`/situation-rooms/${room.id}/matches/${content.id}`}
                            data-testid={`originalPoster_${content.id}_viewDetails`}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-link p-0"
                            onClick={closeModal}
                        >
                            View Details
                        </a>
                    </TooltipWrapper>
                </div>
            </div>
        )
    });

    if (loading) {
        return <Loading relative height={200} background="white" />;
    }
    return (
        <div className="bg-white border p-2">
            <div className="mb-1 pr-1">
                <div className="d-flex align-items-top justify-content-between">
                    <div className="mr-1">
                        <span className="text-primary dont-break-out">{getPostedByName(content.from, content.displayName)} via </span>
                        <SourceIconLabel data={{ contentType: content?.contentType }} className="pl-1 d-inline" />
                        <span className="ctype">
                            {ctype.name}
                        </span>
                    </div>
                    <TranslateButton className="ml-1"
                        onClick={(e) => {
                            e.preventDefault();
                            getTranslation();
                        }}
                        lang={content.langVerbose}
                        detectedLangs={content.detectedLangs}
                        loading={translateLoading}
                    />
                </div>
                <p className="mt-2"><strong>Engagement: </strong>{content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}</p>
                <p className="mt-2">
                    {formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}
                    {dateInLocalTimeZone && (
                        ` | ${formatDateLocal(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}`
                    )}
                </p>
                <p className="mt-2">{truncateString(translatedText, 150)}</p>
                <Button color="link"
                    className="m-0 pt-1 d-inline p-0"
                    data-testid="narrativeViewOriginalPosterSummary"
                    onClick={() => viewSummaryModal(translatedText)}
                >
                    View summary
                </Button>
            </div>
        </div>
    );
};
