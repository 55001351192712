import React from 'react';
import { useQuery } from '@apollo/client';
import { integrationsRoutesLinks, configureRoutesLinks, sidebarRoutesLinks } from '../../routes';
import { SidebarMenu } from '../../components/Molecules';
import { Category, Links } from '../../components/Molecules/SidebarMenu';
import { gql } from '../../generated';

type MenuProps = {
    currentRoomLink: string;
    instanceId: string;
    children: React.ReactNode;
    disabled?: boolean;
}

export const Menu = ({ currentRoomLink, instanceId, children, disabled }: MenuProps) => {
    const { data } = useQuery(GET_INSTANCE_PLAN, {
        variables: { id: instanceId },
    });
    if (disabled || !data) return children;
    const instance = (data?.instance || {
        plan: {
            core: {},
            integrations: {}
        }
    });
    const filterLinks = (links: Links[], plan: { [key: string]: boolean | null | string } | false) =>
        links.filter(item => (plan ? plan[item.id] !== false : true)).map(link => ({
            ...link, path: currentRoomLink + link.path
        }));
    const menu = [
        { name: 'Core', links: filterLinks(sidebarRoutesLinks, instance?.plan?.core || {}) },
        { name: 'Integrations', links: filterLinks(integrationsRoutesLinks, instance?.plan?.integrations || {}) },
        { name: 'Configure', links: filterLinks(configureRoutesLinks, false) }
    ] as Category[];
    return (
        <SidebarMenu menu={menu}>
            {children}
        </SidebarMenu>
    );
};

export const GET_INSTANCE_PLAN = gql(`
    query instance($id: ID!) {
        instance(where: { id: $id }) {
            plan {
                core {
                    overview
                    threats
                    logicallyFactChecks
                    narratives
                    aiClustering
                    networks
                }
                integrations {
                    investigations
                    reports
                    watchlist
                }
            }
        }
    }
`);
