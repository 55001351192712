import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import { useTour } from '@reactour/tour';
import { TooltipItem } from '../../../../components/ToolTip';
import { SearchBarNew, SearchBarNewRef } from '../../../../components/NewFilters';

type NetworksTopBarProps = {
    totalCount: number
}

export const NetworksTopBar = ({ totalCount }: NetworksTopBarProps) => {
    const { setIsOpen } = useTour();
    const searchRef = useRef<SearchBarNewRef>(null);
    return (
        <>
            <div className="d-flex align-items-center">
                <div>
                    <h3 className="m-0">
                        Networks
                        <TooltipItem id="networksTooltip"
                            placement="bottom-start"
                            content={(
                                <div>
                                    <p>
                                        Common links between posts or comments are found as more data is ingested to the Situation Room
                                        over time. You can inspect the links and the shared themes here, visualizing the networks in an
                                        interactive format.
                                    </p>
                                    <a href="https://logically-intelligence.readme.io/docs/networks"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button color="link" className="p-0">Read more</Button>
                                    </a>
                                    <Button color="link" className="p-0 ml-2" onClick={() => setIsOpen(true)}>Feature tour</Button>
                                </div>
                            )}
                        />
                    </h3>
                </div>
                <div className="flex-1">
                    {totalCount > 2500 && (
                        <p className="mb-0 ml-2">
                            Displaying content with the highest engagement (limited to the top 2500 results)
                        </p>
                    )}
                </div>

            </div>
            <div className="mt-3">
                <SearchBarNew sort={false} showBooleanSearch ref={searchRef} />
            </div>
        </>
    );
};
