import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../../../../../components/Loading';
import { truncateString } from '../../../../../../../utils/text';
import { useWordcloud } from '../../../../../../../services/Overview';
import { WordCloud } from '../../../../../../../components/Highcharts';

export const WordcloudGenerator = ({ small }) => (
    <div className={`p-${small ? 1 : 2}`}>
        {wordcloudConfig.map(type => (
            <div key={type.type} className="mb-2">
                <WordcloudGeneratorInner type={type} />
            </div>
        ))}
    </div>
);

const WordcloudGeneratorInner = ({ type, small }) => {
    const { report } = useSelector(state => state.createReport);
    const { keywords, loading } = useWordcloud({
        field: type.type,
        size: 30,
        dateFilter: report.filters
    });

    if (loading) {
        return <Loading relative height={200} />;
    }
    const data = keywords.map(item => ({
        name: truncateString(item.keyword, 40),
        weight: item.count,
        original: item.key
    }));
    return (
        <div>
            <p><strong>{type.title}</strong></p>
            <div className={`report-wordcloud ${small ? 'small' : ''}`}>
                <WordCloud data={data} minFontSize={small ? 12 : 10} placement="horizontal" />
            </div>
        </div>
    );
};

const wordcloudConfig = [
    {
        type: 'hashtags',
        title: 'Hashtags'
    },
    {
        type: 'entities',
        title: 'Entities'
    },
    {
        type: 'mentions',
        title: 'Mentions'
    }
];
