import React from 'react';
import { Table as ReactTable } from 'reactstrap';
import { KeyBoardDownIcon, KeyBoardUpIcon } from '../../assets/icons/svgicons';

type SortItemProps = {
    dataKey: string,
    sortName: string,
    inverse: number,
    onClick: (data: { newInverse: number }) => void
}

type TableProps = {
    data: any[],
    columns: ColumnsProps[],
    condensed: string,
    className: string
}

type ColumnsProps = {
    name: string,
    field: string
}

export const SortItem = ({ dataKey, sortName, inverse, onClick }: SortItemProps) => {
    if (sortName === dataKey && inverse === 0) {
        return <div onClick={() => { onClick({ newInverse: 1 }); }}><KeyBoardDownIcon height="18px" width="18px" className="" /></div>;
    }

    return <div onClick={() => { onClick({ newInverse: 0 }); }}><KeyBoardUpIcon height="18px" width="18px" className="" /></div>;
};

export const Table = ({ data, columns, condensed, className }: TableProps) => (
    <ReactTable className={`m-0 ${className || ''}`}>
        <thead>
            <tr>
                {columns.map(col => (
                    <th key={col.name.replace(/ /g, '')} className={condensed || ''}>
                        {col.name}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {data.map(row => (
                <tr key={row.key.replace(/ /g, '')}>
                    {columns.map(col => (
                        <td key={row.key + col.name.replace(/ /g, '')} className={condensed || ''}>
                            {row[col.field]}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </ReactTable>
);
