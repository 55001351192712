import { useState } from 'react';
import { useRoomEditor } from '../RoomEditorContext';

export const useEditKeywords = ({ room, setRoom }) => {
    const { setErrors, errors } = useRoomEditor();
    const [duplicateKeyword, setDuplicateKeyword] = useState('');
    const [includedKeywordError, setIncludedKeywordError] = useState(undefined);
    const [excludedKeywordError, setExcludedKeywordError] = useState(undefined);

    const addKeyword = (newKeyword, isIncludedKeyword) => {
        setErrors({
            ...errors,
            emptyKeywords: false,
            noChanges: false
        });
        if (room.query.included.find(word => word.value === newKeyword)) {
            setDuplicateKeyword(newKeyword);
            if (isIncludedKeyword) {
                setIncludedKeywordError(`Keyword '${newKeyword}' has already been added to the room. You cannot add duplicate keywords`);
            } else {
                setExcludedKeywordError(
                    `Keyword '${newKeyword}' has already been added to Included keywords. You cannot add duplicate keywords`
                );
            }
        } else if (room.query.excluded.find(word => word.value === newKeyword)) {
            setDuplicateKeyword(newKeyword);
            if (isIncludedKeyword) {
                setIncludedKeywordError(
                    `Keyword '${newKeyword}' has already been added to Excluded keywords. You cannot add duplicate keywords`
                );
            } else {
                setExcludedKeywordError(
                    `Keyword '${newKeyword}' has already been excluded from the room. You cannot exclude duplicate keywords`
                );
            }
        } else {
            const includedKeywords = room.query.included;
            const excludedKeywords = room.query.excluded;
            if (isIncludedKeyword) {
                includedKeywords.push({ value: newKeyword, type: 'unknown' });
            } else {
                excludedKeywords.push({ value: newKeyword, type: 'unknown' });
            }
            setRoom({
                ...room,
                query: {
                    ...room.query,
                    included: includedKeywords,
                    excluded: excludedKeywords
                }
            });
        }
    };

    const removeKeyword = (keyword, isIncludedKeyword) => {
        let includedKeywords = room.query.included;
        let excludedKeywords = room.query.excluded;
        if (isIncludedKeyword) {
            includedKeywords = includedKeywords.filter(word => word.value !== keyword.value);
        } else {
            excludedKeywords = excludedKeywords.filter(word => word.value !== keyword.value);
        }
        setRoom({
            ...room,
            query: {
                ...room.query,
                included: includedKeywords,
                excluded: excludedKeywords
            }
        });
    };

    const clearKeywords = (isIncludedKeyword) => {
        setRoom({
            ...room,
            query: {
                ...room.query,
                included: isIncludedKeyword ? [] : room.query.included,
                excluded: !isIncludedKeyword ? [] : room.query.excluded
            }
        });
    };

    return { duplicateKeyword,
        setDuplicateKeyword,
        includedKeywordError,
        setIncludedKeywordError,
        excludedKeywordError,
        setExcludedKeywordError,
        addKeyword,
        removeKeyword,
        clearKeywords };
};
