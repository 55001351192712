import { FROM_TAG_SOURCES } from '../components/Molecules/BooleanInputValidated/BooleanValidationErrors';
import { ACCOUNT_TAGS, TAGS } from '../components/Molecules/BooleanInputValidated/editorConfig';

export const extractKeywords = (defaultQuery) => {
    if (!defaultQuery) return [];
    let query = cleanQuery(defaultQuery);
    const keywords = [];
    query = query.replace(/(NOT|RT)/g, '');
    query = query.replace(/ NEAR\/\d /g, ' OR ');
    query = query.replace(/ NEAR\/\df /g, ' OR ');

    const allTags = TAGS.concat(ACCOUNT_TAGS);

    for (const tag of allTags) {
        query = query.replace(`${tag}:`, '');
    }
    for (const source of FROM_TAG_SOURCES) {
        query = query.replace(`:${source}`, '');
    }
    const orSplit = query.split('OR');
    for (const term of orSplit) {
        const andSplit = term.split('AND');
        for (const childTerm of andSplit) {
            const nonKwPattern = /[^a-zA-Z.\d\s]/g;
            const kw = childTerm.replace(nonKwPattern, '').trim();
            keywords.push(kw);
        }
    }
    return [...new Set(keywords)];
};

const cleanQuery = (query) => query.replace(/ or /g, ' OR ').replace(/ and /g, ' AND ').replace(/ not /g, ' NOT ').replace(/ near\//g, ' NEAR/');
