export const getRGB = (hex) => hex.match(/[A-Za-z0-9]{2}/g).map((v) => parseInt(v, 16)).join(',');

export const rgbToHex = (rgb) => {
    const red = toHex(rgb[0]);
    const green = toHex(rgb[1]);
    const blue = toHex(rgb[2]);
    return `#${red}${green}${blue}`;
};

const toHex = (rgb) => {
    const hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        return `0${hex}`;
    }
    return hex;
};

export const ctypeFontOptions = [
    { key: 'font_free', name: 'Font Awesome 5 Free' },
    { key: 'font_brands', name: 'Font Awesome 5 Brands' }
];
