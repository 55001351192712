import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TooltipItem } from '../../../../../components/ToolTip';
import { RootState } from '../../../../../store';
import { SelectedNarrative } from '../SelectedNarrative';
import { resetNarrativeList, setNarrativesComparisonList } from '../../../store';
import { getToast } from '../../../../../utils/getToast';
import { getRoom } from '../../../../../utils/variables';

type SelectedNarrativesType = {
    id: string
    title: string
}

export const NarrativeComparison = () => {
    const dispatch = useDispatch();
    const toast = getToast();
    const history = useHistory();
    const room = getRoom();
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);
    const removeNarrativeItem = (narrativeId: string) => {
        toast.success('Narrative removed from comparison');
        const selectedItems = selectedNarratives.filter((narrative: SelectedNarrativesType) => narrative.id !== narrativeId);
        dispatch(setNarrativesComparisonList({
            selectedNarratives: selectedItems
        }));
    };
    const setCompare = () => {
        if (selectedNarratives.length < 2) {
            toast.error('Please select at least 2 Narratives to compare');
            return;
        }
        const id = `?id=${selectedNarratives.map((item: {id: string}) => item.id)}`;
        history.push(`/situation-rooms/${room.id}/ai-narratives/narrative-comparison/${id}`);
    };
    return (
        <div className="bg-white d-flex justify-content-between py-2 px-4 mb-4 border-bottom border-top">
            <div className="d-flex align-items-center">
                <p className="mb-0">Selected narratives</p>
                <TooltipItem id="compareNarratives"
                    placement="bottom-start"
                    className="bg-white text-black"
                    content={(
                        <p>You can compare up to 4 narratives</p>
                    )}
                />
                {selectedNarratives.length > 0
                    ? selectedNarratives.map((narrative: SelectedNarrativesType) =>
                        <SelectedNarrative key={narrative.id} selectedNarrative={narrative} removeItem={removeNarrativeItem} />) : null}
            </div>
            <div>
                <Button color="link" className="mr-1" onClick={() => dispatch(resetNarrativeList())}>Clear all</Button>
                <Button color="primary" onClick={setCompare}>Compare</Button>
            </div>
        </div>
    );
};
