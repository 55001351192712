import React from 'react';
import { Button, Table } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';

type KeywordsProps = {
    count: number,
    value: string
}

type KeywordsModalProps = {
    keywords: KeywordsProps[]
}

export const KeywordsModal = ({ keywords }: KeywordsModalProps) => {
    const { closeModal } = useModal();
    return (
        <div>
            <h2>Keywords mentioned</h2>
            <hr />
            <p>{keywords?.length < 1 ? 'There is no keyword mentioned for this content'
                : 'Keywords mentioned in the content are shown below.'}
            </p>
            <hr />
            {keywords?.length > 0 && (
                <Table bordered hover>
                    <thead>
                        <tr className="font-weight-bold">
                            <td>Keywords / URLs</td>
                            <td>No.s</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            keywords?.slice(6, keywords.length)?.map(keyword => (
                                <tr key={keyword.value + keyword.count}>
                                    <td>{keyword.value}</td>
                                    <td>{keyword.count}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            )}
            <Button color="secondary" onClick={closeModal}>Close</Button>
        </div>
    );
};
