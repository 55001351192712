import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { Pagination } from '../../../../components/Molecules';
import { RemoveAdminUser } from './RemoveAdminUser';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { ChangeUserRole } from './ChangeUserRole';

export const AdminUsersTable = ({ adminUsers }) => {
    const { isAdmin } = useAdminUser();
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState(adminUsers?.slice(0, 10) || []);

    useEffect(() => {
        if (adminUsers?.length > page) {
            setTableData(adminUsers?.slice(page, page + 10));
        }
        else {
            setPage(0);
            const offset = 10;
            setTableData(adminUsers?.slice(offset, offset + 10));
        }
    }, [page, adminUsers]);
    const [sort, setSort] = useState({ field: 'displayName', sort: 'asc' });

    return (
        <div className=" overflow-x-auto">
            <Table className="mb-0 border-bottom" hover>
                <thead>
                    <tr>
                        <th><SortToggle sort={sort} setSort={setSort} field="displayName" name="Name" /></th>
                        <th><SortToggle sort={sort} setSort={setSort} field="email" name="Email" /></th>
                        <th><SortToggle sort={sort} setSort={setSort} field="role" name="Role" /></th>
                        {isAdmin && <th />}
                        {isAdmin && <th />}
                    </tr>
                </thead>
                <tbody>
                    {lodashSort([...tableData], sort).map((item) => (
                        <tr key={item.id}>
                            <td>{item?.displayName}</td>
                            <td>{ item?.email }</td>
                            <td>{item?.role}</td>
                            {isAdmin && <td> <ChangeUserRole adminUser={item} /></td>}
                            {isAdmin && <td><RemoveAdminUser adminUser={item} /></td>}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {
                adminUsers?.length > 10 && (
                    <Pagination total={adminUsers?.length || 0}
                        itemsPerPage={10}
                        offset={page}
                        setOffset={setPage}
                        className="py-2"
                    />
                )
            }
        </div>
    );
};
