import { gql, useQuery } from '@apollo/client';
import { useUser } from '../../../../../context/user/UserContext';

export const useEntityHistory = ({ threat }) => {
    const { instance } = useUser();
    const { data, loading } = useQuery(GET_ENTITY_HISTORY, {
        variables: {
            threat,
            instance: instance.id || instance
        },
        fetchPolicy: 'no-cache'
    });
    return {
        data: data?.getEntitySentimentHistory || [],
        loading
    };
};

export const GET_ENTITY_HISTORY = gql`
    query getEntitySentimentHistory($instance: ID!, $threat: String!) {
        getEntitySentimentHistory(instance: $instance, threat: $threat) {
            user {
                displayName
            }
            action
            oldSentiment
            sentiment
            entityName
            createdAt
        }
    }
`;
