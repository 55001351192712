type BucketsTypes = {
    key: number,
    doc_count: number,
    count: number,
    engagement: undefined | { value: number }
}

export const getSeries = (buckets: BucketsTypes[], type?: string) => {
    let hasVal = false;
    const data = buckets.map(bucket => {
        // get timezone offset based on point date
        const timezoneOffset = -(new Date(bucket.key).getTimezoneOffset() * 60 * 1000);
        return ({
            x: bucket.key - timezoneOffset,
            y: (type === 'count' || !type) ? (bucket.doc_count || bucket.count) : (bucket.engagement?.value || 0),
            custom: {
                date: bucket.key
            }
        });
    });
    return data.sort((a, b) => b.x - a.x)
        .slice(0, 1000)
        .reverse()
        .filter(a => {
            if (!hasVal && a.y) {
                hasVal = true;
            }
            return hasVal;
        });
};
