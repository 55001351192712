import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modals: []
};

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setModalsAction: (state, { payload }) => ({
            ...state,
            modals: [...state.modals, ...payload]
        }),
        closeModalAction: (state) => {
            if (state.modals.find(a => a.goToPreviousOnClose)) {
                return {
                    ...state,
                    modals: [...state.modals.slice(0, state.modals.length - 1)]
                };
            }

            return {
                ...state,
                modals: []
            };
        },
        // This is because the 'current' modal should already be closed
        openPreviousModalAction: (state, { payload }) => ({
            ...state,
            modals: [...state.modals.slice(0, state.modals.length - (payload || 1))]
        })
    }
});

export const { setModalsAction, closeModalAction, openPreviousModalAction } = modalsSlice.actions;
export const modals = modalsSlice.reducer;
