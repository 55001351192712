import React from 'react';
import { ArrowDownCircle, ArrowUpCircle } from 'react-feather';
import { CheckBox } from '../../../../../components/Form/CheckBox';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { getTotalInPastXDays } from './trend';

type TrendCardProps = {
    data: { key: number, count: number }[]
    show: boolean
    setShow: (show: boolean) => void
    totalCount: number
    id: string
    tooltip: string
    label: string
    interval?: string
}

export const TrendCard = ({ data, show, setShow, totalCount, id, tooltip, label, interval = '1d' }: TrendCardProps) => {
    const trendInPastXDays = getTotalInPastXDays({ data, interval });
    return (
        <div className="mr-2 border rounded p-2 w-100">
            <div className="d-flex align-items-top">
                <CheckBox id={id}
                    name={id}
                    value={id}
                    checked={show}
                    onChange={() => setShow(!show)}
                    className={`checkbox-color-${label === 'Threats' ? 'pink' : 'green'}`}
                />
                <div>
                    <TooltipWrapper id={`${id}Tooltip`}
                        placement="top-start"
                        tooltipText={tooltip}
                    >
                        <p className="mb-1 widget-text-style">{label}</p>
                    </TooltipWrapper>
                    <div className="d-flex align-items-center mb-1">
                        <h2 className="mr-1">{largeNumber(totalCount)}</h2>
                        {trendInPastXDays.trending ? <ArrowUpCircle className="text-success" />
                            : <ArrowDownCircle className="text-danger" />}
                    </div>
                    {trendInPastXDays.message && (
                        <p className="mb-0">{trendInPastXDays.message}</p>
                    )}
                </div>
            </div>
        </div>
    );
};
