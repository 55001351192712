import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import { SearchSortDropdown } from '../../../../components/Search/SearchSortDropdown';
import { TooltipItem } from '../../../../components/ToolTip';
import { getRoom } from '../../../../utils/variables';
import { Spinner } from '../../../../assets/svg/Spinner';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { useTotalMatches } from '../../../../services/Overview/totalMatches';
import { useCountOfContentAwatingEnhancement } from '../../../../services/Overview/countOfContentAwatingEnhancement';

type MatchesHeaderProps = {
    count: number
    sort: string
    setSort: (sort: { value: string; name: any; }) => void
    isPreview?: boolean
}

export const MatchesHeader = ({ count, sort, setSort, isPreview = false }: MatchesHeaderProps) => {
    const room = getRoom();
    const handleSort = (sortNum: number, e: React.MouseEvent<any>) => {
        const target = e.target as HTMLInputElement;
        const sortOptions = [
            'date',
            'engagement',
            'score'
        ];
        setSort({ value: sortOptions[sortNum - 1], name: target.name });
    };

    const { awaitingEnhancementCount } = useCountOfContentAwatingEnhancement();
    const { totalMatches: allMatchesCount } = useTotalMatches({}, {}, false);

    const percentageEnhancement = (allMatchesCount - awaitingEnhancementCount) / allMatchesCount * 100;
    const newEtlEnabled = room.instance?.plan?.others?.newEtl;
    const shouldShowEnhancementSpinner = awaitingEnhancementCount > 0 && newEtlEnabled && percentageEnhancement < 90;

    return (
        <div className="mb-2 bg-white">
            <div className="d-flex align-items-center flex-wrap gap-1">
                <div className="d-flex">
                    <h3 className="my-0">{count > 1 ? `${count} ` : ''}Matches</h3>
                    <TooltipItem id="matchesFeedTooltip"
                        placement="top"
                        content={(
                            <>
                                <p>New content matches will appear in your feed chronologically as they are detected and analyzed.</p>
                                <p className="highlight-em m-0">
                                    <em>Highlighted</em> information represents the keywords that are monitored in your Situation Room.
                                </p>
                            </>
                        )}
                    />
                </div>
                {shouldShowEnhancementSpinner && (
                    <TooltipWrapper id="matchesEnhancementsTooltip"
                        tooltipText="Threat information will be available once the enhancement is completed for a few pieces of content."
                    >
                        <Spinner />
                    </TooltipWrapper>
                )}
                <div className="flex-1" />
                <div className="mr-n2 mb-n1 ml-auto">
                    <SearchSortDropdown score={!isPreview} sortOption={sort} handleSort={handleSort} />
                </div>
                {!isPreview ? (
                    <TooltipWrapper id="seeAllMatchesTooltip"
                        className="ml-1"
                        tooltipText="See all matches"
                    >
                        <Link to={`/situation-rooms/${room.id}/overview/matches`}
                            className="btn btn-primary mw-0 p-1"
                            data-testid="matches-live-feed-see-more"
                        >
                            <ArrowRight />
                        </Link>
                    </TooltipWrapper>
                ) : null}
            </div>
            <hr className="mx-n3" />
        </div>
    );
};
