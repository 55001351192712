import React, { useState } from 'react';
import { User } from '../../../assets/svg/user';

type ProfileImageProps = {
    className?: string;
    photoURL?: string;
    size?: number;
}

export const ProfileImage = ({ className, photoURL, size = 32 }: ProfileImageProps) => {
    const [error, setError] = useState(false);
    if (photoURL && !error) {
        return (
            <img src={photoURL}
                alt=""
                className={`rounded-circle align-self-center ${className}`}
                style={{ width: size, height: size, objectFit: 'cover' }}
                onError={() => setError(true)}
            />
        );
    }
    return <span className={`rounded-circle align-self-center ${className}`}><User size={size} /></span>;
};
