import React from 'react';
import { Plus } from 'react-feather';

export const CreateNew = ({ setNewTemplateModal }) => (
    <div className="template-thumb-container" onClick={() => setNewTemplateModal()}>
        <div className="rounded border-dashed template-thumb d-flex
     align-items-center justify-content-center text-primary"
        >
            <Plus size={30} />
        </div>
        <p className="mb-0 mt-2 ">Create new</p>
    </div>
);
