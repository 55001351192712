import { gql, useMutation } from '@apollo/client';
import { useUser } from '../../../../../context/user/UserContext';
import { getRoom } from '../../../../../utils/variables';

export const useUpdateEntities = ({ id }) => {
    const room = getRoom();
    const { user, instance } = useUser();
    const [updateThreat] = useMutation(UPDATE_THREAT);
    const [createEntitySentimentHistory] = useMutation(CREATE_ENTITY_SENTIMENT_HISTORY);
    return {
        updateEntities: (data, itemChanged, isNew, oldItem) => {
            updateThreat({
                variables: {
                    id,
                    data: {
                        project_id: room.project_id,
                        entity_sentiment: data
                    }
                }
            });
            const action = isNew ? 'create' : 'update';
            createEntitySentimentHistory({
                variables: {
                    data: {
                        threat: id,
                        instance: instance.id || instance,
                        user: user.id,
                        action: itemChanged.deleted ? 'delete' : action,
                        oldSentiment: oldItem?.sentiment,
                        sentiment: itemChanged.sentiment,
                        entityName: itemChanged.entity,
                        createdAt: new Date()
                    }
                }
            });
        }
    };
};

export const UPDATE_THREAT = gql`
    mutation updateThreat($data: JSON!, $id: String!) {
        updateThreat(data: $data, id: $id)
    }
`;

export const CREATE_ENTITY_SENTIMENT_HISTORY = gql`
    mutation createEntitySentimentHistory($data: EntitySentimentHistoryInput) {
        createEntitySentimentHistory(data: $data) {
            done
        }
    }
`;
