import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { getRoom } from '../../../utils/variables';
import { GET_LOCATIONS } from '../../../graphql';
import { RootState } from '../../../store';
import { DropdownWithSearchAlphabetical } from '../components/DropdownWithSearchAlphabetical';
import { saveSelectedFilters } from '../../../pages/Room/store';

type LocationsFilterProps = {
    clusters: boolean
}

export const LocationsFilter = ({ clusters = false }: LocationsFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const room = getRoom();
    const { projectId } = location.state || {};
    const { origin: selectedLocations }: { origin: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { data, loading } = useQuery(!clusters ? GET_LOCATIONS : GET_LOCATIONS_MENTIONS_IN_CLUSTERS, {
        variables: {
            projectId: room?.project_id || projectId,
            threats: location.pathname?.includes('/threats') || location.pathname?.includes('/watchlist'),
            size: 1000
        }
    });
    let locations = [];
    if (clusters) {
        locations = data?.getLocationMentionsInClusters || [];
    } else {
        locations = data?.getLocations || [];
    }
    const filteredLocations = locations.filter((a: string) => a);
    const locationsData = filteredLocations.map((loc: string) => ({
        id: loc,
        name: loc,
        selected: selectedLocations.includes(loc)
    }));

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            origin: selectedLocations.includes(id)
                ? selectedLocations.filter((loc: string) => loc !== id)
                : [...selectedLocations, id]
        }));
    };
    const countFilters = selectedLocations.length;

    return (
        <DropdownWithSearchAlphabetical placeholder="Search for locations"
            items={locationsData}
            handleSelectItem={handleSelectItem}
            name={countFilters > 0
                ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
            loading={loading}
        />
    );
};

export const GET_LOCATIONS_MENTIONS_IN_CLUSTERS = gql`
    query getLocationMentionsInClusters(
        $projectId: String
        $size: Int
    ) {
        getLocationMentionsInClusters(
        projectId: $projectId
        size: $size
        )
    }
`;
