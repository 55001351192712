import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { AlertTriangle, Check } from 'react-feather';
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { GET_CONTENT_LABELS } from '.';
import { getToast } from '../../../utils/getToast';
import { color } from '../../../utils/getColors';

export type LabelsDataProps = {
    id: string,
    situationRoom: string,
    label: string,
    contentIds: [string]
}

type SaveInputLabelProps = {
    labelNames: [string],
    roomId: string,
    contentId: string,
    selected: string[],
    labelsData: LabelsDataProps[]
}

export const SaveInputLabel = ({ labelNames, roomId, contentId, selected, labelsData }: SaveInputLabelProps) => {
    const [labelName, setLabelName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [inputLabel, setInputLabel] = useState(false);
    const [emptyName, setEmptyName] = useState(false);
    const toast = getToast();

    const [createContentLabel, { loading }] = useMutation(CREATE_LABEL, {
        refetchQueries: [GET_CONTENT_LABELS],
        onCompleted: () => {
            toast.success('New label successfully created');
            setLabelName('');
            setInputLabel(false);
        }
    });

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        if (name === '') {
            setEmptyName(true);
        } else {
            setEmptyName(false);
        }
        if (e.target.value.length <= 70) {
            setLabelName(name);
            if (labelNames.includes(name)) {
                setNameError(true);
            } else {
                setNameError(false);
            }
        }
    };
    const handleCreateLabel = () => {
        if (!nameError && labelName?.trim()) {
            createContentLabel({
                variables: {
                    data: {
                        situationRoom: roomId,
                        label: labelName,
                        contentIds: [contentId]
                    }
                }
            });
        } else {
            if (labelName?.trim() === '') { setEmptyName(true); }
            toast.error('Resolve all errors before continuing');
        }
    };

    return (
        <div className="w-100">
            {inputLabel && (
                <>
                    <p className="font-weight-bold mb-0 d-inline">
                        Input label name
                    </p>
                    <InputGroup border="none"
                        state={(nameError) ? 'error' : ''}
                        className="mt-1"
                    >
                        <Input placeholder="Label name"
                            autoFocus
                            value={labelName}
                            onChange={(e) => handleNameChange(e)}
                            data-testid="label-input"
                        />
                        <span className={(nameError || ((labelName?.trim() && !nameError))) ? 'text-disabled mt-1 ml-1'
                            : 'text-disabled mt-1 mr-2 ml-1'}
                        >
                            ({labelName?.length}/70)
                        </span>
                        {(nameError) && (
                            <InputGroupAddon addonType="append">
                                <InputGroupText className="border-0 p-1 bg-white">
                                    <AlertTriangle color={color.red[200]} size={20} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                        {(labelName?.trim() && !nameError) && (
                            <InputGroupAddon addonType="append">
                                <InputGroupText className="border-0 p-1 bg-white">
                                    <Check color={color.green[200]} size={16} strokeWidth={3} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                    </InputGroup>
                    {nameError && (
                        <p className="text-danger">
                            This label already exists, pick a different name
                        </p>
                    )}
                    {((!labelName?.trim() && labelName !== '') || emptyName)
                     && <p className="text-danger">Enter a valid name for the label</p>}
                </>
            )}
            <div className={labelNames.length > 0 ? 'd-flex justify-content-end mt-0' : 'mt-0'}>
                {inputLabel ? (
                    <div className="mt-1">
                        <Button color="secondary" onClick={() => { setInputLabel(false); setLabelName(''); }}>Cancel</Button>
                        <Button color="primary ml-2"
                            onClick={handleCreateLabel}
                            disabled={loading}
                            data-testid="label-create"
                        >Create
                        </Button>
                    </div>
                )
                    : (

                        <Button color="primary"
                            data-testid="label-name-create"
                            onClick={() => setInputLabel(true)}
                        >Create new label
                        </Button>

                    ) }
            </div>
        </div>
    );
};

export const CREATE_LABEL = gql`
    mutation createContentLabel($data:contentLabelInput) {
        createContentLabel(data:$data) {
            id
            label
        }
    }
`;

export const UPDATE_CONTENT_LABELS = gql`
    mutation updateContentLabels($contentId: ID!,$addedLabelIds: [String]!, $removedLabelIds: [String]!) {
        updateContentLabels(addedLabelIds: $addedLabelIds,removedLabelIds:$removedLabelIds, contentId: $contentId) {
            done
            message
        }
    }
`;
