import React, { useState } from 'react';
import { Loading } from '../../../components/Loading';
import { PageFilters } from './PageFilters';
import { InvestigationTable } from './Utils/InvestigationTable';
import { EmptyState } from './Utils/EmptyState';

export const InProgress = ({ inProgressData, loading }) => {
    const uniqueRoomList = [...new Set(inProgressData.map(item => item.situationRoom.project_name))];
    const inProgressRoomList = uniqueRoomList && uniqueRoomList.map((item) => ({ id: `${item}-inprogress`, text: item }));
    const [searchFilters, setSearchFilters] = useState({ searchValue: '', situationRooms: [] });

    const handleSearch = (val, indentifier) => {
        setSearchFilters({
            ...searchFilters,
            [indentifier]: val
        });
    };

    const filterData = () => inProgressData?.filter(item => {
        let ret = true;
        if (searchFilters.searchValue && !item.summary.toLowerCase().includes(searchFilters.searchValue.toLowerCase())) {
            ret = false;
        }
        if (!searchFilters.situationRooms.find(room => room.text === item.situationRoom.project_name)) {
            ret = false;
        }
        return ret;
    });
    const filteredInvestigations = filterData();

    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            { inProgressData.length === 0 ? <EmptyState selectedTab="in-progress" />
                : (
                    <>
                        <PageFilters handleSearchValue={(val, indentifier) => handleSearch(val, indentifier)}
                            filterRooms={inProgressRoomList}
                            currentTab="inProgress"
                        />
                        {filteredInvestigations?.length > 0 ? <InvestigationTable data={filteredInvestigations || []} />
                            : <EmptyState selectedTab="in-progress" reason /> }
                    </>
                )}
        </div>
    );
};
