import { gql, useQuery } from '@apollo/client';
import { capitalize } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useLocationMentions = ({ defaultFilters = {}, size = 10 } = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters });
    const ret = useQuery(TOP_LOCATION_MENTIONS, {
        variables: {
            ...filters,
            size
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.topLocationMentions?.data?.map(item => ({
            ...item,
            locationMentioned: capitalize(item.locationMentioned),
            key: item.locationMentioned
        })) || [] : [],
        total: ret.data?.topLocationMentions?.total || 0
    };
};

export const TOP_LOCATION_MENTIONS = gql`
    query topLocationMentions(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topLocationMentions(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        ) {
            total
            data {
                locationMentioned
                count
            }
        }
    }
`;
