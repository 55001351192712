import React from 'react';
import Parser, { HTMLReactParserOptions, domToReact, Element, DOMNode } from 'html-react-parser';
import { TooltipWrapper } from '../components/ToolTip/TooltipWrapper';

const generateUniqueId = (() => {
    let id = 0;
    return () => `highlight-tooltip-${id++}`;
})();

export const getHighlightedContent = (text: string, tooltip?: string) => {
    if (!text) {
        return [];
    }
    const options: HTMLReactParserOptions = {
        replace: (domNode) => {
            // Check if the node is an <em> tag with the class 'highlight-em'
            if (domNode instanceof Element && domNode.name === 'em' && domNode.attribs.class === 'highlight-em') {
                if (tooltip) {
                    return (
                        <TooltipWrapper id={generateUniqueId()}
                            placement="top-start"
                            tooltipText={tooltip}
                            className="highlighted-text d-inline-flex"
                        >
                            {domToReact(domNode.children as DOMNode[], options)}
                        </TooltipWrapper>
                    );
                }
            }

            // If it's not an <em> tag or doesn't have the 'highlight-em' class, return its content as a span
            if (domNode instanceof Element && domNode.name !== 'em') {
                return (
                    <span>
                        {domToReact(domNode.children as DOMNode[], options)}
                    </span>
                );
            }
        },
    };

    // Replace <em> tags with 'highlight-em' class
    return Parser(text.replaceAll('<em>', '<em class="highlight-em">'), options);
};
