import React from 'react';
import { useModal } from '../../context/modal/ModalComponent';
import { LinkModal } from '.';

type ExternalLinkButtonProps = {
    url: string;
    children?: React.ReactNode;
    classname?: string
}

export const ExternalLinkButton = ({ url, children, classname = '' }: ExternalLinkButtonProps) => {
    const { setModal } = useModal();

    return (
        <a href={url}
            target="_blank"
            rel="noreferrer"
            className={`dont-break-out url-hover ${classname}`}
            data-testid={`table-link-${url}`}
            onClick={(e) => {
                if (!localStorage.getItem('externalLinks')) {
                    e.preventDefault();
                    setModal({
                        header: false,
                        component: <LinkModal link={url} />
                    });
                }
            }}
        >
            {children || url}
        </a>
    );
};
