import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Col } from 'reactstrap';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash';
import { GET_ALL_SUB_CLUSTERS } from '../../../../../graphql';
import { Loading } from '../../../../../components/Loading';
import { getRoom } from '../../../../../utils/variables';
import { SubClustersTable } from '../../ListView/SubClustersTable';
import { SubCluster } from '../../types';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useGlobalFiltersInClusters } from '../../../../../services/Clusters/GlobalFIltersInClusters';
import { SearchBarNew } from '../../../../../components/NewFilters';
import { ClustersSortOptions } from '../../Utils/ClusterSort';
import { resetClusterFilters } from '../../../store';

export const AddNarrativeModal = () => {
    const room = getRoom();
    const { closeModal } = useModal();
    const dispatch = useDispatch();
    const [sort, setSort] = useState({ field: 'volume', order: 'desc', name: 'Most content' });
    const [subClustersOffset, setSubClustersOffset] = useState(0);
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const { data: subClustersData, loading: subClusterLoading } = useQuery(GET_ALL_SUB_CLUSTERS, {
        variables: {
            limit: 10,
            ...filters,
            sort: pick(sort, ['field', 'order']),
            skip: subClustersOffset,
            projectId: room.project_id
        },
        fetchPolicy: 'cache-and-network'
    });
    const getSubClustersData = subClustersData?.getAllSubClusters;
    const subClusters: SubCluster[] = getSubClustersData?.subClusters || [];
    const handleClose = () => {
        dispatch(resetClusterFilters());
        closeModal();
    };
    if (subClusterLoading) {
        return <Loading gif relative height={400} />;
    }
    return (
        <>
            <h2>
                Add narrative for comparison
                <X className="float-right" type="button" onClick={handleClose} />
            </h2>
            <hr className="mb-3" />
            <div className="mb-2">
                <SearchBarNew clusters score>
                    <Col xs="auto">
                        <ClustersSortOptions sort={sort} setSort={setSort} />
                    </Col>
                </SearchBarNew>
            </div>
            <SubClustersTable subClusters={subClusters}
                total={getSubClustersData?.total}
                offset={subClustersOffset}
                onOffsetChange={setSubClustersOffset}
                itemsPerPage={10}
                isCompareModal
            />
            <Button color="secondary" className="mt-2" onClick={handleClose}>Close</Button>
        </>
    );
};
