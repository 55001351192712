import React from 'react';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getSeries } from '../../utils/getSeries';

type BucketTypes = {
    buckets: any;
};

type MultiSeriesLineProps = {
    data: BucketTypes;
    type: string;
    startDate: number;
    endDate: number;
    chartRef: (data: any) => void;
    setState: (data: any) => void;
};

export const MultiSeriesLine = ({ data, type, startDate, endDate, chartRef, setState }: MultiSeriesLineProps) => {
    const { buckets } = data;
    // using any because causing issue with SeriesOptionsTypes
    const series: any[] = Object.entries(buckets).map(([key, value]: any) => {
        const d = getSeries(value.date.buckets, type);
        return { name: key.replace(/_/g, ' '), data: d };
    });

    const options: Options = {
        title: {
            text: '',
        },
        xAxis: {
            type: 'datetime',
            min: startDate !== null ? startDate * 1000 : null,
            max: endDate !== null ? endDate * 1000 : null,
        },
        yAxis: {
            min: 0,
            title: { text: type.charAt(0).toUpperCase() + type.slice(1) },
        },
        plotOptions: {
            series: {
                connectNulls: true,
                point: {
                    events: {
                        click: (x: any) => {
                            if (setState) setState((current: any) => ({ ...current, selection: x }));
                        },
                    },
                },
            },
        },
        series,
    };

    return (
        <div id="chart-line" data-testid="chart-line">
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
        </div>
    );
};
