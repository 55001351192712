import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const setParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({ path: url.href }, '', url.href);
};

export const getParam = (key) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return urlParams.get(key);
};

export const unsetParam = (key) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.replaceState({ path: url.href }, '', url.href);
};

export const getCurrentFromData = (current, data) => {
    let newCurrent = false;

    if (current && typeof current === 'object') {
        return current;
    }

    if (current && typeof current === 'string' && data?.length > 0) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === current) {
                newCurrent = data[i];
            }
        }
    }

    return newCurrent;
};

export const HandleLocationChange = ({
    page,
    pageOptions,
    setPage,
    pageKey
}) => {
    const location = useLocation();
    const callback = useCallback(() => {
        const pageParam = getParam(pageKey);
        let currentPage = pageParam || 0;

        if (pageOptions) {
            currentPage = pageOptions[pageParam];
        }

        if (page !== currentPage && currentPage !== undefined) {
            setPage(currentPage);
        }
    }, [pageKey, pageOptions, page, setPage]);

    useEffect(() => {
        callback();
    }, [location, callback]);

    return <div />;
};
