import React from 'react';
import { User } from '../../../assets/svg/user';
import { useUser } from '../../../context/user/UserContext';

export const ProfileImage = () => {
    const { user } = useUser();
    return (
        <div>
            <div className="position-relative d-inline-block">
                {user.photoURL ? (
                    <img src={user.photoURL}
                        alt="logically user"
                        className="rounded-circle"
                        style={{ width: 130 }}
                    />
                ) : (
                    <span className="mx-1">
                        <User size="106" />
                    </span>
                )}
            </div>
        </div>
    );
};
