import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Button, Table } from 'reactstrap';
import { HighlightDifferences } from '../../../../components/Atoms';
import { Loading } from '../../../../components/Loading';

export const RoomUpdates = ({ id }) => {
    const [open, setOpen] = useState(0);

    const { data, loading } = useQuery(ROOM_UPDATES, {
        variables: {
            data: {
                query: {
                    situationRoom: id
                }
            }
        }
    });
    if (loading) {
        return <Loading relative height={300} />;
    }
    const updates = data?.getSituationRoomUpdate || [];
    if (!updates.length) { return null; }
    return (
        <div>
            <h2 className="my-4">Room updates</h2>
            <Table className="m-0" hover>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Updated by</th>
                        <th>Updated at</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {updates.map((update, index) => (
                        <React.Fragment key={update.id}>
                            <tr>
                                <td>{update.type.filter(a => a !== 'id').join(', ')}</td>
                                <td>{update.updatedBy?.displayName}</td>
                                <td>{moment(new Date(update.createdAt)).format('DD/MM/YYYY h:mm A')}</td>
                                <td>
                                    <Button color="link" onClick={() => setOpen(open === index ? false : index)}>
                                        {open === index ? 'Hide' : 'View'} details
                                        {open === index ? <ChevronUp size={16} className="ml-11" />
                                            : <ChevronDown size={16} className="ml-11" />}
                                    </Button>
                                </td>
                            </tr>
                            {open === index && (
                                <tr>
                                    <td colSpan={4}>
                                        <pre className="pre-wrap">
                                            <HighlightDifferences before={update.before} after={update.after} />
                                        </pre>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export const ROOM_UPDATES = gql`
query roomUpdates($data: SituationRoomUpdateFilter) {
    getSituationRoomUpdate(data: $data){
        id
        createdAt
        situationRoom {
        project_name
        }
        updatedBy {
        displayName
        email
        }
        type
        before
        after
    }
}
`;
