import React, { useState } from 'react';
import { AlertCircle, Eye, EyeOff } from 'react-feather';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from 'reactstrap';
import { Loading } from '../../../components/Loading';
import { verifyPassword } from '../../../auth0';
import { useUser } from '../../../context/user/UserContext';
import { color } from '../../../utils/getColors';
import { useModal } from '../../../context/modal/ModalComponent';
import { UserDeleteConfirmationModal } from './UserDeleteConfirmationModal';

export const LoginAndSecurity = ({ updateProfile }) => {
    const { user } = useUser();
    const { setModal } = useModal();
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordEye, setOldPasswordEye] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordEye, setPasswordEye] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);

    const setUserDeleteConfirmationModal = ({ message, type }) => {
        setModal({
            header: false,
            component: <UserDeleteConfirmationModal />
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.length < 8) return setError('Password must be 8 characters or longer.');
        if (password !== confirmPassword) return setError('New password and Confirm password do not match.');
        if (oldPassword === password) return setError('Password cannot be the same as the old password.');

        setLoading(true);
        try {
            const verified = await verifyPassword(user.email, oldPassword);
            if (verified) {
                // update password using auth0
                await updateProfile({
                    variables: {
                        data: {
                            id: user.uid,
                            password,
                        },
                    },
                });
                setDone(true);
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
            } else {
                setError('The old password you have entered is incorrect');
            }
        } catch {
            setError('Something went wrong, please try again later');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div data-testid="account-security-form">
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label className="w-100">Old password
                        <InputGroup border={oldPassword ? 'active' : 'none'}>
                            <Input value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                type={oldPasswordEye ? 'text' : 'password'}
                                required
                            />
                            {oldPassword && (
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText onClick={() => setOldPasswordEye(!oldPasswordEye)}>
                                        <ResponsiveEyeIcon active={oldPasswordEye} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>
                    </Label>
                </FormGroup>
                <FormGroup className="mt-2">
                    <Label className="w-100">New password
                        <InputGroup border={passwordEye ? 'active' : 'none'}>
                            <Input value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={passwordEye ? 'text' : 'password'}
                                required
                            />
                            {password && (
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText onClick={() => setPasswordEye(!passwordEye)}>
                                        <ResponsiveEyeIcon active={passwordEye} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>
                    </Label>
                </FormGroup>
                <FormGroup className="mt-2">
                    <Label className="w-100">Confirm password
                        <InputGroup border={confirmPasswordEye ? 'active' : 'none'}>
                            <Input value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={confirmPasswordEye ? 'text' : 'password'}
                                required
                            />
                            {confirmPassword && (
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText onClick={() => setConfirmPasswordEye(!confirmPasswordEye)}>
                                        <ResponsiveEyeIcon active={confirmPasswordEye} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>
                    </Label>
                </FormGroup>
                <div className="mt-3 d-flex">
                    <Button className="mr-2" type="submit" data-testid="update-security-info">Update</Button>
                    <Button color="danger" data-testid="delete-account-button" onClick={setUserDeleteConfirmationModal}>
                        Delete account
                    </Button>
                </div>
                <Alert color="danger" className="my-3" isOpen={error !== false} toggle={() => setError(false)}>
                    <span><AlertCircle color={color.red[200]} /> </span>
                    {error}
                </Alert>
                <Alert color="success" className="my-3" isOpen={done} toggle={() => setDone(false)}>
                    <span><AlertCircle color={color.green[200]} /></span>
                    Successfully updated password!
                </Alert>
            </Form>
        </div>
    );
};

const ResponsiveEyeIcon = ({ active }) => {
    if (active) {
        return <Eye color={color.blue[500]} size={22} />;
    }
    return <EyeOff color={color.blue[500]} size={22} />;
};
