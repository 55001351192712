import React from 'react';
import { color } from '../../utils/getColors';

export const DashboardDefault = () => (
    <svg width="112" height="80" viewBox="0 0 112 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="dashboard-default">
            <path id="vector"
                d="M107.705 0H4.29536C3.20789 0 2.16497 0.431996 1.39602 1.20095C0.627064 1.96991 0.195068 3.01283 0.195068
                4.10029V11.5935H111.805V4.10029C111.805 3.56183 111.699 3.02865 111.493 2.53118C111.287 2.03371 110.985 1.5817
                110.604 1.20095C110.223 0.820203 109.771 0.518185 109.274 0.312126C108.776 0.106067 108.243 0 107.705 0Z"
                fill={color.grey[300]}
            />
            <path id="vector_2"
                d="M0.195069 69.1187C0.194737 69.6573 0.300606 70.1906 0.506548 70.6882C0.712489 71.1858 1.01447 71.638 1.39528
                72.0188C1.77609 72.3996 2.22826 72.7016 2.72588 72.9075C3.2235 73.1135 3.75681 73.2193 4.29536 73.219H107.705C108.792
                73.219 109.835 72.787 110.604 72.018C111.373 71.2491 111.805 70.2062 111.805 69.1187V11.5933H0.195069V69.1187Z"
                fill={color.grey[200]}
            />
            <path id="vector_3"
                d="M40.422 22.5352H12.0711C11.1154 22.5352 10.3406 23.31 10.3406 24.2657V58.2479C10.3406 59.2037 11.1154 59.9785
                12.0711 59.9785H40.422C41.3778 59.9785 42.1526 59.2037 42.1526 58.2479V24.2657C42.1526 23.31 41.3778 22.5352
                40.422 22.5352Z"
                fill={color.grey[100]}
            />
            <path id="vector_4"
                d="M102.086 22.5352H52.5213C51.5656 22.5352 50.7908 23.31 50.7908 24.2657V37.0896C50.7908 38.0453 51.5656 38.8201
                52.5213 38.8201H102.086C103.042 38.8201 103.817 38.0453 103.817 37.0896V24.2657C103.817 23.31 103.042 22.5352
                102.086 22.5352Z"
                fill={color.grey[100]}
            />
            <path id="vector_5"
                d="M102.086 42.4053H52.5213C51.5656 42.4053 50.7908 43.1801 50.7908 44.1358V56.9597C50.7908 57.9154 51.5656
                58.6902 52.5213 58.6902H102.086C103.042 58.6902 103.817 57.9154 103.817 56.9597V44.1358C103.817 43.1801
                103.042 42.4053 102.086 42.4053Z"
                fill={color.grey[100]}
            />
            <path id="vector_6"
                d="M23.7565 35.1719L14.8005 45.8963H32.7125L23.7565 35.1719Z"
                fill={color.grey[200]}
            />
            <path id="vector_7"
                d="M30.6683 33.9668L20.7068 45.8963H40.6323L30.6683 33.9668Z"
                fill={color.grey[300]}
            />
            <path id="vector_8"
                d="M18.6254 36.0487C19.7751 36.0487 20.7072 35.1167 20.7072 33.967C20.7072 32.8173 19.7751 31.8853 18.6254
                31.8853C17.4757 31.8853 16.5437 32.8173 16.5437 33.967C16.5437 35.1167 17.4757 36.0487 18.6254 36.0487Z"
                fill={color.grey[300]}
            />
            <path id="vector_9"
                d="M7.3191 7.62941C8.30556 7.62941 9.10525 6.82973 9.10525 5.84327C9.10525 4.85681 8.30556 4.05713 7.3191
                4.05713C6.33264 4.05713 5.53296 4.85681 5.53296 5.84327C5.53296 6.82973 6.33264 7.62941 7.3191 7.62941Z"
                fill={color.grey[100]}
            />
            <path id="vector_10"
                d="M11.7 7.62941C12.6864 7.62941 13.4861 6.82973 13.4861 5.84327C13.4861 4.85681 12.6864 4.05713 11.7
                4.05713C10.7135 4.05713 9.91382 4.85681 9.91382 5.84327C9.91382 6.82973 10.7135 7.62941 11.7 7.62941Z"
                fill={color.grey[200]}
            />
            <path id="vector_11"
                d="M16.3484 7.62941C17.3349 7.62941 18.1345 6.82973 18.1345 5.84327C18.1345 4.85681 17.3349 4.05713 16.3484
                4.05713C15.3619 4.05713 14.5623 4.85681 14.5623 5.84327C14.5623 6.82973 15.3619 7.62941 16.3484 7.62941Z"
                fill={color.grey[200]}
            />
            <path id="vector_12"
                d="M97.2351 28.8535H56.4165C56.249 28.8535 56.0883 28.787 55.9699 28.6685C55.8514 28.5501 55.7849 28.3894
                55.7849 28.2219C55.7849 28.0544 55.8514 27.8938 55.9699 27.7753C56.0883 27.6569 56.249 27.5903 56.4165
                27.5903H97.2351C97.4026 27.5903 97.5632 27.6569 97.6816 27.7753C97.8001 27.8938 97.8667 28.0544 97.8667
                28.2219C97.8667 28.3894 97.8001 28.5501 97.6816 28.6685C97.5632 28.787 97.4026 28.8535 97.2351 28.8535Z"
                fill={color.grey[300]}
            />
            <path id="vector_13"
                d="M97.2351 33.7671H56.4165C56.249 33.7671 56.0883 33.7006 55.9699 33.5821C55.8514 33.4637 55.7849 33.303
                55.7849 33.1355C55.7849 32.968 55.8514 32.8073 55.9699 32.6889C56.0883 32.5704 56.249 32.5039 56.4165
                32.5039H97.2351C97.4026 32.5039 97.5632 32.5704 97.6816 32.6889C97.8001 32.8073 97.8667 32.968 97.8667
                33.1355C97.8667 33.303 97.8001 33.4637 97.6816 33.5821C97.5632 33.7006 97.4026 33.7671 97.2351 33.7671Z"
                fill={color.grey[300]}
            />
            <path id="vector_14"
                d="M97.2351 48.7236H74.4019C74.2343 48.7236 74.0736 48.6571 73.9552 48.5387C73.8367 48.4202 73.7703
                48.2596 73.7703 48.092C73.7703 47.9245 73.8367 47.7639 73.9552 47.6455C74.0736 47.527 74.2343 47.4604
                74.4019 47.4604H97.2351C97.4027 47.4604 97.5633 47.527 97.6817 47.6455C97.8001 47.7639 97.8667 47.9245
                97.8667 48.092C97.8667 48.2596 97.8001 48.4202 97.6817 48.5387C97.5633 48.6571 97.4027 48.7236 97.2351 48.7236Z"
                fill={color.grey[300]}
            />
            <path id="vector_15"
                d="M97.2351 53.6372H74.4019C74.2343 53.6372 74.0736 53.5706 73.9552 53.4522C73.8367 53.3337 73.7703
                53.1731 73.7703 53.0056C73.7703 52.8381 73.8367 52.6774 73.9552 52.559C74.0736 52.4406 74.2343 52.374
                74.4019 52.374H97.2351C97.4027 52.374 97.5633 52.4406 97.6817 52.559C97.8001 52.6774 97.8667 52.8381
                97.8667 53.0056C97.8667 53.1731 97.8001 53.3337 97.6817 53.4522C97.5633 53.5706 97.4027 53.6372 97.2351 53.6372Z"
                fill={color.grey[300]}
            />
            <path id="vector_16"
                d="M56.0001 80.0002C65.2591 80.0002 72.7651 72.4942 72.7651 63.2352C72.7651 53.9762 65.2591 46.4702
                56.0001 46.4702C46.741 46.4702 39.2351 53.9762 39.2351 63.2352C39.2351 72.4942 46.741 80.0002 56.0001 80.0002Z"
                fill={color.grey[300]}
            />
            <path id="vector_17"
                d="M48.1432 68.9117C47.9757 68.9112 47.8152 68.845 47.696 68.7273C47.6372 68.6686 47.5906 68.5989 47.5587
                68.5222C47.5269 68.4454 47.5105 68.3632 47.5105 68.2801C47.5105 68.197 47.5269 68.1148 47.5587
                68.0381C47.5906 67.9613 47.6372 67.8916 47.696 67.8329L53.0519 62.4771C53.1106 62.4182 53.1803 62.3716
                53.2571 62.3397C53.3338 62.3079 53.416 62.2915 53.4991 62.2915C53.5822 62.2915 53.6644 62.3079 53.7412
                62.3397C53.8179 62.3716 53.8876 62.4182 53.9463 62.4771L57.0714 65.5996L63.41 59.261C63.4684 59.2008
                63.5382 59.1527 63.6153 59.1197C63.6924 59.0868 63.7753 59.0695 63.8592 59.0689C63.943 59.0683 64.0261
                59.0843 64.1037 59.1162C64.1812 59.1481 64.2517 59.1951 64.3109 59.2545C64.3701 59.3138 64.4169 59.3844
                64.4486 59.4621C64.4802 59.5398 64.4961 59.6229 64.4952 59.7068C64.4944 59.7906 64.4769 59.8735 64.4437
                59.9505C64.4104 60.0275 64.3623 60.0971 64.3019 60.1553L57.5186 66.9412C57.3994 67.0587 57.2388 67.1246
                57.0714 67.1246C56.904 67.1246 56.7434 67.0587 56.6242 66.9412L53.4991 63.816L48.5904 68.7273C48.4712
                68.845 48.3107 68.9112 48.1432 68.9117Z"
                fill={color.grey[100]}
            />
            <path id="vector_18"
                d="M63.8571 64.6246C63.6896 64.6246 63.529 64.558 63.4105 64.4395C63.2921 64.3211 63.2255 64.1605 63.2255
                63.993V60.3398H59.5698C59.4023 60.3398 59.2417 60.2733 59.1232 60.1548C59.0048 60.0364 58.9382 59.8758
                58.9382 59.7083C58.9382 59.5407 59.0048 59.3801 59.1232 59.2616C59.2417 59.1432 59.4023 59.0767 59.5698
                59.0767H63.8647C64.0322 59.0767 64.1928 59.1432 64.3113 59.2616C64.4297 59.3801 64.4963 59.5407 64.4963
                59.7083V64.0031C64.4936 64.1701 64.4248 64.3294 64.305 64.4458C64.1852 64.5623 64.0241 64.6266 63.8571 64.6246Z"
                fill={color.grey[100]}
            />
        </g>
    </svg>
);
