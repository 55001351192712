import React from 'react';

type CheckBoxProps = {
    id: string,
    label?: string,
    name: string,
    value: string,
    checked: boolean,
    onChange: () => void
    className?: string
}

export const CheckBox = ({
    id,
    label = '',
    name,
    value,
    checked,
    onChange,
    className = ''
}: CheckBoxProps) => (
    <div className={`custom-control custom-checkbox ${className}`}>
        <input type="checkbox"
            className="custom-control-input"
            id={`customControlValidation${id || label?.replace(/ /g, '')}`}
            aria-label="Checkbox for following text input"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            data-testid={`${id}Checkbox`}
        />
        <label className={`custom-control-label mr-${label ? '2' : '1'}`}
            style={{ marginTop: '2px' }}
            htmlFor={`customControlValidation${id || label.replace(/ /g, '')}`}
        >
            {label && <span className="text-down">{label}</span>}
        </label>
        <br />
    </div>
);
