import React from 'react';
import { useQuery } from '@apollo/client';
import { RefreshCw, X } from 'react-feather';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getRoom } from '../../utils/variables';
import { gql } from '../../generated';
import { useModal } from '../../context/modal/ModalComponent';
import { formatDateLocal } from '../../utils/getTimeFrom';
import { GET_CLUSTERS_COUNT } from '../../graphql';
import { Loading } from '../../components/Loading';
import { setClusteringStatus } from '../../pages/Room/AIClustering/store';

export const useClusteringStatus = ({
    refresh = false,
    refetchClustersCount
} = {} as { refresh?: boolean, refetchClustersCount?: () => void }) => {
    const room = getRoom();
    const projectId = room?.project_id;

    const { setModal } = useModal();
    const dispatch = useDispatch();

    const { data, loading, refetch } = useQuery(GET_CLUSTERING_STATUS, {
        variables: {
            projectId,
            refresh
        },
        skip: !projectId,
        fetchPolicy: 'cache-and-network'
    });

    const clusteringStatus = data?.getClusteringStatus;

    const clusteringHasNotBeenRun = clusteringStatus?.status === 0 && !clusteringStatus?.timeRemaining;
    const timeRemaining = clusteringStatus?.timeRemaining || 0;
    const clusteringStartTime = clusteringStatus?.time ? new Date(`${clusteringStatus.time}z`).getTime()
        : moment().subtract(2, 'days').valueOf();
    return {
        data: data?.getClusteringStatus,
        loading,
        clusteringHasNotBeenRun,
        timeRemaining,
        clusteringStartTime,
        refetchStatus: () => {
            refetch().then(res => {
                const newStatus = res.data?.getClusteringStatus;
                if (newStatus?.status === 1 && newStatus?.error === 0) {
                    const newClusteringStartTime = newStatus.time ? new Date(`${newStatus.time}z`).getTime() : Date.now();
                    refetchClustersCount?.();
                    if (refresh) {
                        dispatch(setClusteringStatus({
                            newThemes: newStatus.newThemes || 0,
                            newNarratives: newStatus.newNarratives || 0,
                            completedAt: Date.now()
                        }));
                    } else {
                        setModal({
                            header: false,
                            component: <NarrativesSuccessModal lastRunOn={newClusteringStartTime} />
                        });
                    }
                }
            });
        }
    };
};

const NarrativesSuccessModal = ({ lastRunOn }: { lastRunOn: number }) => {
    const { closeModal } = useModal();
    const tryAgainDate = new Date(lastRunOn + (24 * 60 * 60 * 1000));
    const room = getRoom();
    const projectId = room?.project_id;
    const { data, loading } = useQuery(GET_CLUSTERS_COUNT, {
        variables: {
            projectId
        },
        skip: !projectId,
        onCompleted: () => {
            if (!data?.getClustersCount?.clusters) {
                closeModal();
            }
        },
        fetchPolicy: 'network-only'
    });

    if (loading) {
        return <Loading relative height={200} />;
    }

    const counts = {
        narratives: data?.getClustersCount?.subClusters || 0,
        themes: data?.getClustersCount?.clusters || 0
    };
    return (
        <div>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0 mb-0">Narratives found</h2>
                <X color="#050E2B"
                    size={20}
                    className="cursor-pointer mt-11"
                    stroke="#050E2B"
                    onClick={closeModal}
                />
            </div>
            <hr className="mb-2 mt-2" />
            <p className="my-3">
                Narratives analysis run on {formatDateLocal(lastRunOn, 'DD/MM/YYYY, h:mmA')}, was
                able to find {counts.narratives} narratives & {counts.themes} narrative themes.
            </p>
            <p className="my-3">
                You can update narratives on {formatDateLocal(tryAgainDate, 'DD/MM/YYYY, h:mmA')}, do so by finding
                the <RefreshCw className="text-primary mx-11" /> icon at the top right of your screen.
            </p>
            <hr className="my-3" />
            <Button onClick={() => closeModal()}>Close</Button>
        </div>
    );
};

export const GET_CLUSTERING_STATUS = gql(`
    query getClusteringStatus($projectId: String!, $refresh: Boolean) {
        getClusteringStatus(projectId: $projectId, refresh: $refresh) {
            status
            count
            clusteringType
            time
            expectedRuntime
            error
            timeRemaining
            newNarratives
            newThemes
        }
    }
`);
