import React from 'react';
import moment from 'moment';
import { Copy, Edit, Trash2 } from 'react-feather';
import Editor from '@monaco-editor/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { RoomBooleanProps } from '.';
import { truncateString } from '../../../utils/text';
import { color } from '../../../utils/getColors';
import { editorOptions } from '../../Molecules/BooleanInputValidated/editorConfig';
import { DeleteBoolean } from './DeleteBoolean';
import { getToast } from '../../../utils/getToast';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';

type SavedBooleanRowProps = {
    boolean: RoomBooleanProps,
    handleEditSavedFilter: (e: string) => void,
}

export const SavedBooleanRow = ({ boolean, handleEditSavedFilter }: SavedBooleanRowProps) => {
    const { setModal, closeModal } = useModal();
    const toast = getToast();

    const handleBooleanDelete = (id: string) => {
        setModal({
            component: (<DeleteBoolean id={id} closeModal={closeModal} />)
        });
    };

    return (
        <tr key={boolean.id}>
            <td className="w-20-p saved-filter-row">
                <Button color="link"
                    className="p-0 m-0"
                    data-testid={`${boolean.id}-editname`}
                    onClick={() => handleEditSavedFilter(boolean.id)}
                >{truncateString(boolean.name, 15, false)}
                </Button>
            </td>
            <td className="w-15-p">
                {boolean?.user?.displayName}
            </td>
            <td className="w-10-p">{moment(boolean.createdAt).format('DD/MM/YYYY')}</td>
            <td className="w-40-p">
                <div className="border border-indigo-600">
                    <Editor height="136px"
                        className="custom-editor-container"
                        language="boolean"
                        theme="boolean-theme"
                        options={{ ...editorOptions, readOnly: true, domReadOnly: true }}
                        value={boolean.query}
                    />
                </div>
            </td>
            <td className="w-10-p">
                <div className="d-flex w-100">
                    <TooltipWrapper tooltipText="Copy" id={`tooltip-copy-${boolean.id}`} placement="top">
                        <CopyToClipboard text={boolean.query}
                            onCopy={() => { toast.success('Boolean query copied'); }}
                            data-testid={`${boolean.id}-copy`}
                        >
                            <Copy size={20} color={color.blue[500]} className="mr-1 cursor-pointer" />
                        </CopyToClipboard>
                    </TooltipWrapper>
                    <TooltipWrapper tooltipText="Edit" id={`tooltip-edit-${boolean.id}`} placement="top">
                        <Edit size={20}
                            className="d-block mr-1 cursor-pointer"
                            color={color.blue[500]}
                            onClick={() => handleEditSavedFilter(boolean.id)}
                            data-testid="edit-boolean"
                        />
                    </TooltipWrapper>
                    <TooltipWrapper tooltipText="Delete" id={`tooltip-delete-${boolean.id}`} placement="top">
                        <div className="cursor-pointer" onClick={() => handleBooleanDelete(boolean.id)} data-testid="delete-filter">
                            <Trash2 size={20} className="d-block" color={color.blue[500]} />
                        </div>
                    </TooltipWrapper>

                </div>
            </td>
        </tr>
    );
};
