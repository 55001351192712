import { ExportToCsv } from 'export-to-csv-fix-source-map';
import React from 'react';
import { Button } from 'reactstrap';
import { Download } from 'react-feather';
import { formatDate } from '../../../../utils/getTimeFrom';
import { getCtype } from '../../../../utils/text';
import { getContentText } from '../../../../utils/getContentText';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { Loading } from '../../../../components/Loading';
import { SubCluster } from '../types';
import { useContent } from '../../../../services/Content/getContent';
import { Content } from '../../../../services/Content/contentInterface';
import { getToast } from '../../../../utils/getToast';

type ExportNarrativesProps = {
    subCluster: SubCluster
    subClusterId: string
    containerId?: string
}

export const ExportNarratives = ({ subCluster, subClusterId, containerId }: ExportNarrativesProps) => {
    const toast = getToast();
    const contentIds = subCluster?.contentIds?.map(content => [content.id, ...content.duplicateIds]).flat();
    const { data: contents, loading } = useContent({
        filters: {
            ids: contentIds
        },
        limit: 10000,
        fetchPolicy: 'no-cache',
        name: 'getClusterExportContent',
        fragments: ['Metadata', 'Document', 'Engagement']
    });
    const exportCsvFile = () => {
        const csvExporter = new ExportToCsv({
            title: subCluster.subClusterTitle,
            useKeysAsHeaders: true,
            filename: `${subClusterId}-data`
        });
        csvExporter.generateCsv(contents?.map((content: Content) => {
            const safeNumber = (value?: number | null): number => {
                const num = Number(value);
                return Number.isNaN(num) ? -1 : num;
            };
            const contentTitle = getContentText({ body: content?.body, text: content?.text });
            const likesCount = safeNumber(content?.likesCount);
            const repliesCount = safeNumber(content?.repliesCount);
            const sharesCount = safeNumber(content?.sharesCount);
            const viewsCount = safeNumber(content?.viewsCount);
            const engagementCount = safeNumber(content?.engagement);
            return {
                content: `"${(contentTitle[0] || '')}"`,
                posted_on: formatDate(content?.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z'),
                posted_by: content?.displayName,
                content_url: content?.url || 'NA',
                source: getCtype(content?.contentType)?.name,
                engagement: engagementCount < 0 ? 'NA' : engagementCount,
                likes: likesCount < 0 ? 'NA' : likesCount,
                replies: repliesCount < 0 ? 'NA' : repliesCount,
                shares: sharesCount < 0 ? 'NA' : sharesCount,
                views: viewsCount < 0 ? 'NA' : viewsCount
            };
        }));
        toast.success('CSV file downloaded successfully.');
    };
    if (loading) {
        return (
            <div className="pl-1">
                <Loading relative iconSize="sm" />
            </div>
        );
    }

    return (
        <TooltipWrapper tooltipText="Download as CSV"
            id={`sub-cluster-view-${subClusterId}_export`}
            placement="top-start"
            container={containerId || `sub-cluster-view-${subClusterId}`}
        >
            <Button color="link" className="p-0 mw-0 ml-2">
                <Download size={24}
                    color="#006FF9"
                    aria-label="Download as CSV"
                    data-testid="export-button"
                    onClick={() => exportCsvFile()}
                />
            </Button>
        </TooltipWrapper>
    );
};
