import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_REMOVED_CONTENT } from '../../graphql';
import { getRoom } from '../../utils/variables';

export const useGetRemovedContentLazy = ({
    searchFilters = { searchValue: '' },
    offset = 0,
    limit = 10,
    sort
}) => {
    const room = getRoom();
    const [getRemovedContent, { data, loading }] = useLazyQuery(GET_REMOVED_CONTENT, {
        variables: {
            limit,
            projectId: room?.project_id,
            searchString: searchFilters.searchValue,
            offset,
            type: (sort && sort.name !== 'All') ? sort.type : ''
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    const content = data?.getRemovedContent?.data || [];
    const count = data?.getRemovedContent?.total || 0;

    return {
        getRemovedContent,
        data: content,
        count,
        loading
    };
};

export const useGetRemovedContent = ({
    searchFilters = { searchValue: '' },
    offset = 0,
    limit = 10,
    sort
}) => {
    const room = getRoom();
    const ret = useQuery(GET_REMOVED_CONTENT, {
        variables: {
            limit,
            projectId: room?.project_id,
            searchString: searchFilters.searchValue,
            offset,
            type: (sort && sort.name !== 'All') ? sort.type : ''
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    const content = ret.data?.getRemovedContent?.data || [];
    const count = ret.data?.getRemovedContent?.total || 0;

    return {
        ...ret,
        data: content,
        count
    };
};
