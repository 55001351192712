import React, { useState } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { SelectAllCheckBox } from './SelectAllCheckbox';
import { TooltipWrapper } from '../../../ToolTip/TooltipWrapper';

type ItemProps = {
    id: string,
    name: string,
    selected: boolean
}

type MultiCheckboxFilterProps = {
    title: string,
    filters: ItemProps[],
    values: string[],
    setValues: (e: string[]) => void,
    value: string,
    disabledOptions?: string[],
    disabled?: boolean,
    threat?: boolean
}

export const MultiCheckboxFilter = ({ title, filters, values, setValues, value, disabledOptions = [],
    disabled = false, threat = false }: MultiCheckboxFilterProps) => {
    const [newid] = useState(uuid());
    return (
        <SelectAllCheckBox id={`checkbox-${title}-${newid}`}
            options={filters?.map(({ id }) => id)}
            values={values}
            selectAllReversed
            allCheck={(ids) => setValues(ids)}
            disabledOptions={disabledOptions}
            disabled={disabled}
        >
            {filters?.map((item, index) => (
                <FormGroup key={item.id} className={index === filters.length - 1 ? 'mb-0' : ''}>
                    <CustomInput type="checkbox"
                        checked={values.indexOf(item.id) === -1}
                        className={`custom-control-input-big${item.id.length > 19 ? ' word-short' : ''} grid-item`}
                        label={item.name.length >= 14
                            ? (
                                <TooltipWrapper tooltipText={item.name} id={`tooltip-${newid}-${item.id}`}>
                                    <p className={threat ? '' : 'truncate-this'}>{item.name}</p>
                                </TooltipWrapper>
                            ) : <span className={threat ? '' : 'truncate-this'}>{item.name}</span>}
                        id={`filter-checkbox-${item.id}-${newid}`}
                        data-testid={`checkbox-${item.id}`}
                        value={value}
                        onChange={({ target: { checked } }) => {
                            if (!checked) {
                                setValues([...values, item.id]);
                            } else {
                                setValues(values.filter(a => a !== item.id));
                            }
                        }}
                        disabled={disabled}
                    />
                </FormGroup>
            ))}
        </SelectAllCheckBox>
    );
};
