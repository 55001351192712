import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useUser } from '../../context/user/UserContext';
import { CREATE_SITUATION_ROOM_DRAFT } from '../../graphql';

export const useCreateSituationRoomDraft = ({ filteredRoom: room }) => {
    const { user, instance } = useUser();
    const [createSituationRoomDraft, { loading }] = useMutation(CREATE_SITUATION_ROOM_DRAFT, {
        variables: {
            ...room,
            query: { included: room?.query?.included,
                excluded: room?.query?.excluded,
                boolean: room?.query?.boolean?.replace(/\s+/g, ' ').trim() },
            instance: instance.id || instance,
            createdBy: user.id,
            start_date: moment(room?.start_date).format('YYYY-MM-DD')
        },
        update: (cache) => cache.evict({ fieldName: 'situationRoomDrafts' })
    });
    return {
        loading,
        createSituationRoomDraft
    };
};
