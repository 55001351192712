import React from 'react';
import { StepType, TourProvider } from '@reactour/tour';
import { FeatureTourPopover } from './Popover';

export type TourStep = {
    title: string
    content: string
    selector?: string
};

type FeatureTourProps = {
    children: React.ReactNode
    tourSteps: TourStep[]
}

type GetPopoverContentProps = {
    currentStep: number
    setCurrentStep: (a: number) => void
    setIsOpen: (a: boolean) => void
    step: TourStep
}

export const FeatureTour = ({ children, tourSteps }: FeatureTourProps) => {
    const getPopoverContent = ({ currentStep, setCurrentStep, setIsOpen, step }: GetPopoverContentProps) => (
        <FeatureTourPopover currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            totalSteps={tourSteps.length}
            closeTour={() => setIsOpen(false)}
            title={step.title}
            content={step.content}
        />
    );
    const steps = tourSteps.map(step => ({
        selector: step.selector || '.center-target',
        position: 'center',
        content: ({ currentStep, setCurrentStep, setIsOpen }) => getPopoverContent({ currentStep, setCurrentStep, setIsOpen, step })
    })) as StepType[];
    return (
        <TourProvider steps={steps}
            disableInteraction
            showCloseButton={false}
            showNavigation={false}
            showBadge={false}
            styles={{
                popover: (base) => ({
                    ...base,
                    boxShadow: 'none',
                    padding: 24,
                    maxWidth: 500,
                    width: 500
                })
            }}
        >
            <div className="center-target absolute-center position-fixed" />
            {children}
        </TourProvider>
    );
};
