import { removeTypename } from '../../utils/removeAllTypename';

export const formatRoomDataToUpdate = (room) => {
    const removedTypename = removeTypename(room);
    return ({
        ...removedTypename,
        ...(removedTypename.createdBy ? { createdBy: removedTypename.createdBy.id } : {}),
        ...(removedTypename.updatedBy ? { updatedBy: removedTypename.updatedBy.id } : {}),
        ...(removedTypename.instance ? { instance: removedTypename.instance.id || removedTypename.instance } : {})
    });
};
