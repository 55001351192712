import React, { useEffect, useRef, useState } from 'react';
import Editor, { Monaco } from '@monaco-editor/react';
import { useSelector } from 'react-redux';
import { validateQuery } from './validateQuery';
import { BooleanValidationErrors, checkForCyrillicChars } from './BooleanValidationErrors';
import {
    ERROR_MARKER,
    IStandaloneCodeEditor,
    editorOptions,
    handleEditorDidMount,
    handleEditorWillMount
} from './editorConfig';
import { setHighlightError } from './setHighlightError';
import { useRoomEditor } from '../../../pages/SituationRooms/RoomEditor/RoomEditorContext';
import { RootState } from '../../../store';

type RoomEditorErrorsType = {
    setErrors: (errors: any) => void,
    errors?: any,
    draft: any,
    room: any
};
type ValidatorType = {
    isError: boolean;
    markers: any[];
}

type ErrorType = {
    [key: string]: ValidatorType;
}

type BooleanInputValidatedProps = {
    booleanQuery: string,
    setBooleanQuery: (e: string) => void,
    validationErrors: any,
    setValidationErrors: (e: any) => void,
    disabled: boolean,
    resetGlobalErrors: () => void,
    customNarrative?: boolean,
    booleanSearch?: boolean,
    emptyQuery?: boolean,
    noBorder?: boolean
}
export const BooleanInputValidated = ({
    booleanQuery,
    setBooleanQuery,
    validationErrors,
    setValidationErrors,
    disabled,
    resetGlobalErrors,
    customNarrative = false,
    booleanSearch, emptyQuery = false,
    noBorder
}: BooleanInputValidatedProps) => {
    const [editorInstance, setEditorInstance] = useState<IStandaloneCodeEditor | null>(null);
    const [monacoInstance, setMonacoInstance] = useState<Monaco | null>(null);
    const [completionDisposable, setCompletionDisposable] = useState<IStandaloneCodeEditor>();
    const [issues, setIssues] = useState(0);
    const [highlightErrorFocus, setHighlightErrorFocus] = useState(null);
    const { selectedTopic } = useSelector((state: RootState) => state.customNarratives);
    const selectedTopicRef = useRef(selectedTopic);
    const { errors: roomEditorErrors, setErrors, draft, room } = useRoomEditor() as RoomEditorErrorsType;
    useEffect(() => () => {
        if (
            completionDisposable?.dispose
                && typeof completionDisposable.dispose === 'function'
        ) {
            completionDisposable.dispose();
        }
    }, [completionDisposable]);

    const handleEditorChange = (value: any) => {
        if (typeof setErrors === 'function') { setErrors({
            ...roomEditorErrors,
            emptyBoolean: false,
            validationErrors: false,
            noChanges: false
        }); }
        if (value !== booleanQuery) {
            setHighlightErrorFocus(null);
        }
        if (!customNarrative) {
            resetGlobalErrors();
        }
        const filteredValue = checkForCyrillicChars(value);
        setBooleanQuery(filteredValue);
        const errors: ErrorType = validateQuery(filteredValue);
        setValidationErrors(errors);
        const markers = Object.keys(errors).reduce((acc, key) => {
            if (errors[key]?.markers?.length) {
                return acc.concat(errors[key]?.markers as any);
            }
            return acc;
        }, []);
        setIssues(markers?.length || 0);
        const model = editorInstance?.getModel();
        if (model) {
            monacoInstance?.editor.setModelMarkers(model, 'owner', markers);
        }
    };

    const handleErrorClick = (errorId: any) => {
        const setHighlight = validationErrors[errorId]?.markers?.[0]?.severity === ERROR_MARKER;
        setHighlightError(errorId, validationErrors, setValidationErrors, setHighlight, monacoInstance, editorInstance);

        if (highlightErrorFocus === errorId) {
            return setHighlightErrorFocus(null);
        }

        setHighlightErrorFocus(errorId);
    };

    useEffect(() => {
        if (highlightErrorFocus) {
            const errorLine = validationErrors[highlightErrorFocus]?.markers?.[0]?.startLineNumber;
            const errorColumn = validationErrors[highlightErrorFocus]?.markers?.[0]?.startColumn;
            editorInstance?.revealPositionInCenter({ column: errorColumn, lineNumber: errorLine }, 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlightErrorFocus]);
    useEffect(() => {
        if (customNarrative && (selectedTopic.boolean || selectedTopic.boolean === '')) {
            if (selectedTopic.threat !== selectedTopicRef.current.threat) {
                validateQuery(selectedTopic.boolean);
            }
            return () => {
                selectedTopicRef.current = selectedTopic;
            }; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTopic, booleanQuery]);
    useEffect(() => {
        if (draft && room?.query?.boolean) {
            handleEditorChange(room?.query?.boolean);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draft, room?.query?.boolean, monacoInstance]);
    const hasErrors = Boolean(Object.values(validationErrors)
        .find((error: any) => error.isError === true));
    const searchInputError = roomEditorErrors?.emptyBoolean || hasErrors || roomEditorErrors?.noChanges
     || emptyQuery;

    return (
        <div className={`d-flex flex-column w-100 h-100 ${booleanSearch && 'boolean-height-custom'}`}>
            <div className={`rounded boolean-input-validated editor-container ${!noBorder && 'border'}`}>
                {!booleanQuery && (
                    <div className="editor-placeholder">Enter Boolean query</div>
                )}
                <div className={
                    `boolean-search-input ${(searchInputError) ? 'boolean-search-input-error' : 'boolean-search-focused'}`
                }
                >
                    <Editor height="100%"
                        className="custom-editor-container"
                        language="boolean"
                        theme="boolean-theme"
                        beforeMount={(monaco) => handleEditorWillMount(monaco, setCompletionDisposable)}
                        onMount={(editor, monaco) => handleEditorDidMount(editor, monaco, setEditorInstance, setMonacoInstance)}
                        options={{
                            ...editorOptions,
                            readOnly: disabled,
                            domReadOnly: disabled,
                        }}
                        onChange={handleEditorChange}
                        value={booleanQuery}
                    />
                </div>
                {validationErrors !== '' && (
                    <BooleanValidationErrors highlightsLength={0}
                        validationErrors={validationErrors}
                        highlightErrorFocus={highlightErrorFocus}
                        onErrorClick={handleErrorClick}
                        issues={issues}
                    />
                )}
            </div>
        </div>
    );
};
