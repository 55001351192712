import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart } from '../../../../../../../components/Highcharts';
import { Loading } from '../../../../../../../components/Loading';
import { useSentiment } from '../../../../../../../services/Overview';
import { config } from '../../../../../Overview/Visualisation/utils';

export const Sentiment = ({ small }) => {
    const { report } = useSelector(state => state.createReport);
    const { data, loading } = useSentiment({
        defaultFilters: {
            date: report.filters
        }
    });

    if (loading) {
        return <Loading relative height={200} />;
    }
    return (
        <SentimentPieChart data={data} small={small} />
    );
};

const SentimentPieChart = ({ data, small }) => {
    const dataArray = data.map(a => a.count);
    const total = dataArray.reduce((b, a) => b + a, 0);
    return (
        <div className={`p-${small ? 1 : 2}`}>
            <PieChart data={data.map(a => a.count)}
                labels={data.map(a => a.sentiment)}
                colors={data.map((item, i) => config.getColor(item.sentiment.toLowerCase(), i))}
                legendEnabled={false}
                height={small ? 250 : 335}
                innerSize="80%"
            />
            {data.map((item, i) => {
                const percentage = (100 * item.count) / total;
                return (
                    <div className="d-flex align-items-center mb-1" key={item.sentiment}>
                        <div className="p-1 rounded"
                            style={{
                                backgroundColor: config.getColor(item.sentiment.toLowerCase(), i),
                            }}
                        />
                        <p className="mb-0 pl-1">
                            {item.sentiment} ({Math.round(percentage)}%)
                        </p>
                    </div>
                );
            })}
        </div>
    );
};
