import { useMutation } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { CREATE_INSTANCE, UPDATE_INSTANCE } from '../../../../graphql';
import { routes } from '../../../../routes';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../../utils/SuccessErrorModal';

export const SaveInstanceModal = ({ instance, setError, onCompleted, updateId }) => {
    const { closeModal, setModal } = useModal();
    const history = useHistory();
    const setStatusModal = ({ message, title, type, action }) => {
        setModal({
            component: <StatusModalBodyNew title={title} message={message} messageType={type} action={action} />
        });
    };
    const parseError = ({ graphQLErrors, networkError }) => {
        const errors = graphQLErrors.length > 0 ? graphQLErrors : networkError?.result?.errors || [];
        if (errors[0] && errors[0].message.includes('client name')) {
            setError({
                field: 'clientName',
                message: errors[0].message
            });
            closeModal();
            document.getElementsByClassName('content-page')[0]?.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            setStatusModal({
                message: 'Looks like something went wrong. Please try again later.',
                title: 'Update instance',
                type: STATUS_TYPES.error
            });
        }
    };
    const handleSuccess = () => {
        onCompleted();
        setStatusModal({
            message: `${instance.clientName} instance saved successfully`,
            type: STATUS_TYPES.success,
            action: () => history.push(routes.admin.instances)
        });
    };
    const [updateInstance] = useMutation(UPDATE_INSTANCE, {
        onCompleted: handleSuccess,
        onError: parseError
    });
    const [createInstance] = useMutation(CREATE_INSTANCE, {
        onCompleted: (data) => {
            updateId(data.createInstance.id);
            handleSuccess();
        },
        onError: parseError
    });

    const handleSave = () => {
        if (instance.id) {
            updateInstance({
                variables: {
                    data: instance
                }
            });
        } else {
            createInstance({
                variables: {
                    data: instance
                }
            });
        }
    };

    return (
        <div>
            <h2 className="m-0">Save client details</h2>
            <hr className="my-2" />
            <p>{instance.id ? 'Do you wish to save the updates in the instance?' : 'Do you wish to create a new instance?'}</p>
            <hr className="my-2" />
            <Button color="primary" className="mr-2" onClick={handleSave}>Yes</Button>
            <Button onClick={closeModal}>Cancel</Button>
        </div>
    );
};
