import React from 'react';
import { useQuery } from '@apollo/client';
import { Tabs } from '../../../components/Tabs';
import { InProgress } from './InProgress';
import { Completed } from './Completed';
import { TokenRequested } from './TokenRequested/index';
import { getRoom } from '../../../utils/variables';
import { GET_ALL_INVESTIGATIONS, GET_INVESTIGATION_TOKENS } from '../../../graphql';
import { BackButton } from '../../../components/Atoms';
import { BreadCrumbs } from '../../../components/BreadCrumbs';

export const Investigations = () => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_ALL_INVESTIGATIONS, {
        fetchPolicy: 'no-cache'
    });
    const { data: tokensRequested, loading: tokenRequestedLoading } = useQuery(GET_INVESTIGATION_TOKENS, {
        fetchPolicy: 'cache-and-network'
    });
    const tokensReqestedData = tokensRequested?.getInvestigationTokens.map(x => x.tokensRequested.map(tokens =>
        ({ ...tokens, client: x.instance, tokensAvailable: x.tokens, totalTokens: x.totalTokens }))).flat() || [];
    const investigations = data?.allInvestigations || [];
    const inProgressData = investigations.filter(item => item.status === 'In Progress' && item.situationRoom);
    const completedData = investigations.filter(item => item.status === 'Completed' && item.situationRoom);
    const bread = [
        { title: 'Admin home', href: '/admin' }, { title: 'Investigations' }
    ];
    return (
        <div className="page-padding">
            <BreadCrumbs items={bread} />
            <BackButton pathName="/admin" />
            <h2 className="m-0 mt-0">Investigations</h2>
            <div className="pt-4">
                <Tabs param
                    data={[
                        { name: 'In Progress',
                            id: 'in-progress',
                            content: <InProgress room={room}
                                inProgressData={inProgressData}
                                loading={loading}
                            /> },
                        { name: 'Completed',
                            id: 'completed',
                            content: <Completed room={room}
                                completedData={completedData}
                                loading={loading}
                            /> },
                        { name: `Token requested (${tokensReqestedData?.length})`,
                            id: 'token-requested',
                            content: <TokenRequested tokenRequestedData={tokensReqestedData} tokensLoading={tokenRequestedLoading} /> }
                    ]}
                />
            </div>
        </div>
    );
};
