import React, { useState, useRef, useEffect } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'react-feather';
import { getRoom } from '../../../utils/variables';
import { handleDateRange } from '../FiltersLayout';
import { RootState } from '../../../store';
import { saveFilters, saveSelectedFilters } from '../../../pages/Room/store';

export type DateRangeProps =
    { startDate: number, endDate: number }

type DatePickerProps = {
    globalFilter?: boolean
}

export const DatePicker = ({ globalFilter }: DatePickerProps) => {
    const room = getRoom();
    const dispatch = useDispatch();
    const { dateRange: selectedDate } = useSelector((state: RootState) => state[globalFilter ? 'filters' : 'selectedFilters'].filters);
    const isValidDateRange = (dateRange: any): dateRange is DateRangeProps =>
        dateRange && 'startDate' in dateRange && 'endDate' in dateRange && dateRange.startDate && dateRange.endDate;
    const finalDateRange: DateRangeProps = isValidDateRange(selectedDate) ? selectedDate : { startDate: 0, endDate: 0 };
    const finalRange = handleDateRange(finalDateRange, room.start_date);
    const [focus, setFocus] = useState(false);
    const dateRef = useRef<any>(null);

    useEffect(() => {
        if (finalRange && dateRef.current) {
            dateRef.current.setStartDate(moment.unix(finalRange.startDate).toDate());
            dateRef.current.setEndDate(moment.unix(finalRange.endDate).toDate());
        }
    }, [finalRange]);
    const convertedTime = moment(moment(room.start_date).toDate()).unix()
    || (finalRange ? finalRange.startDate : moment(moment().toDate()).unix());

    const inputRange = {
        'All time': [moment.unix(convertedTime).toDate(), moment().toDate()],
        'Last 24 Hours': [moment().subtract(24, 'hours').toDate(), moment().toDate()],
        'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
        'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
        'Last 3 Months': [
            moment().subtract(3, 'month').toDate(),
            moment().toDate()
        ],
    };

    const handleCallback = (start: moment.Moment, end: moment.Moment) => {
        const newDateRange = {
            startDate: moment(start).unix(),
            endDate: moment(end).unix(),
        };
        if (globalFilter) {
            dispatch(saveFilters({
                dateRange: {
                    startDate: moment(start).unix(),
                    endDate: moment(end).unix(),
                },
                roomId: room.id
            }));
            return;
        }
        const notUpdated = _.isEqual({
            startDate: moment(moment(room.start_date).toDate()).unix(),
            endDate: moment(moment().endOf('day')).unix(),
        }, newDateRange);
        dispatch(saveSelectedFilters({
            dateRange: notUpdated ? { startDate: 0, endDate: 0 } : newDateRange
        }));
    };

    const calculateLabel = () => {
        if (!finalRange || !finalRange.startDate || !finalRange.endDate) {
            return 'Custom Range';
        }

        for (const [label, range] of Object.entries(inputRange)) {
            const rangeStart = moment(range[0]).unix();
            const rangeEnd = moment(range[1]).unix();

            if (Math.abs(finalRange.startDate - rangeStart) < 3600 && Math.abs(finalRange.endDate - rangeEnd) < 3600) {
                return label;
            }
        }

        return 'Custom Range';
    };

    const getDisplayValue = () => {
        const currentLabel = calculateLabel();
        if (currentLabel && currentLabel !== 'Custom Range') {
            return currentLabel;
        } if (finalRange) {
            const start = moment.unix(finalRange.startDate).format('DD/MM/YYYY');
            const end = moment.unix(finalRange.endDate).format('DD/MM/YYYY');
            return `${start} - ${end}`;
        }
        return '';
    };

    return (
        <button type="button"
            className="btn p-0 cursor-default"
            data-testid="date-range-picker-button"
        >
            <InputGroup border={(focus || globalFilter) ? 'active' : 'none'} className="d-inline-flex mt-0">
                <Label className="d-flex w-100">
                    {globalFilter && <p className="m-0 form-control h-auto border-0 px-2 py-1 cursor-pointer">{getDisplayValue()}</p>}
                    <ReactDateRangePicker onCallback={handleCallback}
                        initialSettings={{
                            alwaysShowCalendars: true,
                            ranges: inputRange,
                            timePicker: true,
                            timePicker24Hour: true,
                            locale: { format: 'DD/MM/YYYY' },
                            opens: globalFilter ? 'left' : 'right'
                        }}
                        ref={(ref: any) => {
                            dateRef.current = ref;
                        }}
                    >
                        <input placeholder="dd/mm/yyyy"
                            type="text"
                            data-testid="input-range"
                            className={globalFilter ? 'w-0 invisible p-0' : 'form-control'}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        />
                    </ReactDateRangePicker>
                    <InputGroupAddon addonType="prepend"
                        className={`small-border cursor-pointer border-left ${globalFilter ? 'border-primary' : ''}`}
                    >
                        <InputGroupText className="bg-white border-left-0 p-1">
                            <Calendar className="text-primary" />
                        </InputGroupText>
                    </InputGroupAddon>
                </Label>
            </InputGroup>
        </button>
    );
};
