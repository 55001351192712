import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Edit, Grid } from 'react-feather';
import { useUser } from '../../../context/user/UserContext';
import { SituationRoomsDropdown } from './SituationRoomsDropdown';
import { getRoom } from '../../../utils/variables';
import { useCountOfContentAwatingEnhancement } from '../../../services/Overview/countOfContentAwatingEnhancement';
import { useContent } from '../../../services/Content/getContent';
import { closeBooleanFilters, closeFilters } from '../store';
import { setEdit } from '../Overview/store';
import { largeNumber } from '../../../utils/NumberFormat';
import { formatDate } from '../../../utils/getTimeFrom';
import { Spinner } from '../../../assets/svg/Spinner';
import { TooltipItem } from '../../../components/ToolTip';
import { TooltipWrapper } from '../../../components/ToolTip/TooltipWrapper';
import { UpdateNarrativeButton } from '../AIClustering/AutomatedNarratives/UpdateNarrativeModal';
import { UpdatingNarratives } from '../AIClustering/AutomatedNarratives/UpdatingNarratives';

type TopBarProps = {
    currentPage: string;
    isOverview: boolean;
};

export const TopBar = ({ currentPage, isOverview }: TopBarProps) => {
    const { user } = useUser();
    const room = getRoom();
    const dispatch = useDispatch();

    const { awaitingEnhancementCount } = useCountOfContentAwatingEnhancement({ skip: !isOverview });

    const { data: content, loading, count } = useContent({
        limit: 1,
        fragments: ['Metadata'],
        sort: 'updatedAt',
        name: 'getLatestContent'
    });

    const enableEditMode = () => {
        dispatch(closeFilters());
        dispatch(closeBooleanFilters());
        dispatch(setEdit(true));
    };

    const canEdit = count > 0;

    const newEtlEnabled = room.instance?.plan?.others?.newEtl;

    const enhancementsCompletedFormatted = largeNumber(count - awaitingEnhancementCount, 2);
    const countFormatted = largeNumber(count, 2);
    const percentage = (count - awaitingEnhancementCount) / count * 100;
    const shouldShowEnhancementsCount = (
        newEtlEnabled // global feature toggle
        && isOverview // only in overview
        && awaitingEnhancementCount !== 0 // if there are mo contents awaiting enhancement
        && !loading // wait to see how much content we have in the room
        && enhancementsCompletedFormatted !== countFormatted // to avoid edge cases e.g. 0.9m out of 0.9m completed
        && percentage < 90
    );

    return (
        <Row className="align-items-stretch px-4 mt-n2 border-bottom mx-n4">
            <Col xs="auto" className="border-right py-2 px-4">
                <SituationRoomsDropdown currentPage={currentPage} tag="h3" />
                {(newEtlEnabled && count > 0 && content[0]?.updatedAt) ? (
                    <p className="mb-0 mt-1 font-size-14">
                        Latest content update: {formatDate(content[0].updatedAt * 1000, 'DD/MM/YYYY @ HH:mm:ss z')}
                    </p>
                ) : null}
            </Col>
            {shouldShowEnhancementsCount ? (
                <Col xs="auto" className="border-right py-2 px-4">
                    <div className="d-flex align-items-center mb-1">
                        <Spinner />
                        <p className="mb-0 ml-1"><strong>LI is enhancing matches</strong></p>
                        <TooltipItem id="enhancementsTooltip"
                            placement="bottom-start"
                            className="bg-white text-black"
                            content={(
                                <>
                                    <p className="m-0">Logically Intelligence analyzes data for:</p>
                                    <ul className="pl-3">
                                        <li>
                                            <strong>Threat Analysis: </strong>
                                            Analyzing data to detect potentially dangerous content, such as threats toward an actor.
                                        </li>
                                        <li>
                                            <strong>Entity Extraction: </strong>
                                            Analyzing data to extract actors, locations, and organizations involved.
                                        </li>
                                        <li>
                                            <strong>Sentiment Analysis: </strong>
                                            Assessing data to determine the emotion conveyed, such as positive, negative,
                                            or neutral sentiment.
                                        </li>
                                    </ul>
                                    <p className="mb-0">
                                        Enhanced data will be available after enhancement for certain content. Look for the
                                        indicator <Spinner /> to find where enhancement occurs. Raw data, which includes
                                        content without enhancements -such as threat information, entities, sentiment, and
                                        location mentions - will be available directly below.
                                    </p>
                                </>
                            )}
                        />
                    </div>

                    <p className="m-0 font-size-14">{enhancementsCompletedFormatted}/{countFormatted} completed</p>
                </Col>
            ) : null}
            {(currentPage === 'Ai-narratives') && <UpdatingNarratives />}
            <Col className="p-0 flex-1" />
            <Col xs="auto" className="d-flex align-items-center py-2 px-4">
                {(canEdit && isOverview) && (
                    <TooltipWrapper id="editOverviewTooltip" tooltipText="Edit overview" placement="bottom-end">
                        <Button color="link" className="p-0 mw-0" onClick={enableEditMode} data-testid="editOverviewButton">
                            <Grid />
                        </Button>
                    </TooltipWrapper>
                )}
                {(currentPage === 'Ai-narratives') && <UpdateNarrativeButton />}
                {user.role === 'ADMIN' && (
                    <TooltipWrapper id="editRoomTooltip" tooltipText="Edit room" placement="bottom-end" className="ml-2">
                        <Link to={`/situation-room/edit-room/${room.id}`} className="link-zindex-1 p-0 mw-0 btn btn-link">
                            <Edit />
                        </Link>
                    </TooltipWrapper>
                )}
            </Col>
        </Row>
    );
};
