import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { BackButton } from '../../../components/Atoms/BackButton';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { GET_CTYPES } from '../../../graphql';
import { PageFilters } from './PageFilters';
import { SourceList } from './SourceList';
import { Loading } from '../../../components/Loading';

export const SourceMapping = () => {
    const { data: ctypeData, loading } = useQuery(GET_CTYPES, {
        fetchPolicy: 'cache-and-network'
    });
    const bread = [
        { title: 'Admin home', href: '/admin' }, { title: 'Source mapping' }
    ];
    const [searchValue, setSearchValue] = useState('');
    const handleSearch = (val) => {
        setSearchValue(val);
    };
    const filterData = () => ctypeData?.getCtypes?.filter(item => {
        let ret = true;

        if (searchValue && !(item.name.toLowerCase().includes(searchValue.toLowerCase())
        || item.key.toLowerCase().includes(searchValue.toLowerCase()))) {
            ret = false;
        }
        return ret;
    });

    if (loading) {
        <Loading />;
    }
    return (
        <div className="page-padding">
            <BreadCrumbs items={bread} />
            <BackButton pathName="/admin" />
            <h2 className="m-0">Source ({ctypeData?.getCtypes.length})</h2>
            <PageFilters handleSearchValue={val => handleSearch(val)} />
            <SourceList ctypeData={filterData() || []} />
        </div>
    );
};
