import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Button } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import { useUser } from '../../context/user/UserContext';

export const ArchivedNotification = ({ goToArchivedTab }) => {
    const { user } = useUser();
    const [show, setShow] = useState(true);
    const { data } = useQuery(GET_ARCHIVED_NOTIFICATION, {
        variables: {
            userId: user.id
        }
    });
    const [closeArchivedNotification] = useMutation(CLOSE_ARCHIVED_NOTIFICATION, {
        variables: {
            userId: user.id
        },
        refetchQueries: ['GetArchivedNotification']
    });
    const handleToggle = () => {
        closeArchivedNotification();
        setShow(false);
    };
    const rooms = data?.getArchivedNotifications || [];
    if (!rooms.length || !show) return null;
    return (
        <Alert color="warning" className="mb-3 p-2">
            <div className="d-flex align-items-center">
                <AlertCircle className="mr-2 text-warning" />
                <span className="flex-1">
                    Logically Intelligence has archived the &quot;{
                        rooms.join(', ')
                    }&quot; situation room{rooms.length > 1 ? 's as they have' : ' as it has'} not been accessed in the past 28 days.
                </span>
                <Button color="link" className="p-0" onClick={goToArchivedTab}>
                    View archived rooms
                </Button>
                <Button color="link" className="p-0 mw-0 ml-2" onClick={handleToggle} data-testid="archive-notification-close-button">
                    <X />
                </Button>
            </div>
        </Alert>
    );
};

export const GET_ARCHIVED_NOTIFICATION = gql`
    query GetArchivedNotification($userId: ID!) {
        getArchivedNotifications(userId: $userId)
    }
`;

export const CLOSE_ARCHIVED_NOTIFICATION = gql`
    mutation CloseArchivedNotification($userId: ID!) {
        closeArchivedNotification(userId: $userId)
    }
`;
