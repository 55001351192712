import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import DeleteSucess from '../../../../assets/images/DeleteSuccess.svg';

export const RemoveUserSuccessModal = ({ userName, closeModal }) => {
    const history = useHistory();
    return (
        <>
            <img src={DeleteSucess} alt="User deleted successfully" />
            <h2 className="mt-2">{userName} deleted successfully</h2>
            <hr />
            <Button onClick={() => { closeModal(); history.goBack(); }}>Close</Button>
        </>
    ); };
