import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { options } from './options';

export const ChangeSentiment = ({ data, updateSentiment }) => {
    const { closeModal } = useModal();
    const [sentiment, setSentiment] = useState(options[0].options.find(a => a.name === data.sentiment));
    return (
        <div>
            <h2 className="m-0">Change sentiment</h2>
            <hr />
            <p>Entity name</p>
            <p><strong>{data.entity}</strong></p>
            <Dropdown label="Select sentiment"
                className="mt-2"
                options={options[0].options.map(item => ({
                    ...item,
                    key: item.name,
                    name: capitalizeFirstLetter(item.name)
                }))}
                value={{
                    ...sentiment,
                    name: capitalizeFirstLetter(sentiment.name)
                }}
                onChange={(val) => {
                    setSentiment(val);
                }}
            />
            <hr />
            <Button className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button color="primary" onClick={() => updateSentiment(sentiment.name.toLowerCase())}>Save</Button>
        </div>
    );
};
