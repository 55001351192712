import React from 'react';
import { AddNewTopic } from './AddNewTopic';
import { AddNarrativeName } from './AddNarrativeName';

export const TopicLeftPanel = ({ nameError, narrativeName, handleNameChange, selectedTopic, setRightPanel,
    rightPanel, setBooleanSwitch, selectedNarrative }) =>
    (
        <div className="d-flex flex-column bg-white">
            <AddNarrativeName rightPanel={rightPanel}
                nameError={nameError}
                handleNameChange={handleNameChange}
                narrativeName={narrativeName}
            />
            <div className="mt-1 mb-3 d-flex flex-column">
                <h5>Topics</h5>
                <p>Enter a topic below and add keywords in the right side panel. You can add multiple topics for a narrative.</p>
            </div>
            <AddNewTopic setRightPanel={setRightPanel}
                selectedTopic={selectedTopic}
                setBooleanSwitch={setBooleanSwitch}
                selectedNarrative={selectedNarrative}
            />
        </div>
    );
