import React from 'react';
import moment from 'moment';
import { Col } from 'reactstrap';
import { useTimeRemaining, UseTimeRemainingProps } from './useTimeRemaining';
import { useClusteringStatus } from '../../../../services/Clusters/getClusteringStatus';
import { Spinner } from '../../../../assets/svg/Spinner';
import { TooltipItem } from '../../../../components/ToolTip';

export const UpdatingNarratives = () => {
    const {
        clusteringHasNotBeenRun,
        timeRemaining,
        data,
        loading,
        refetchStatus,
        clusteringStartTime
    } = useClusteringStatus({ refresh: true });

    if (clusteringHasNotBeenRun || (loading && !data) || timeRemaining === 0 || data?.clusteringType === 'normal') return null;

    const tryAgainDate = new Date(clusteringStartTime + (24 * 60 * 60 * 1000));

    return (
        <Col xs="auto" className="border-right py-2 px-4">
            <div className="d-flex align-items-center mb-1">
                <Spinner />
                <p className="mb-0 ml-1"><strong>Updating Narratives</strong></p>
                <TooltipItem id="updatingNarrativesTooltip"
                    placement="top"
                    content={(
                        <>
                            <p>
                                We’re finding new narratives by putting textual data through advanced algorithms that detect patterns,
                                sentiments, and relationships. This process involves the extraction of Keywords, Actors, and Sentiments,
                                which are then presented as a Narrative Theme or Narrative.
                            </p>
                            <p>
                                Narrative update was started at {moment(clusteringStartTime).format('DD/MM/YYYY, h:mmA')}. You will be
                                able to refresh Narratives again at {moment(tryAgainDate).format('DD/MM/YYYY, h:mmA')}.
                            </p>
                        </>
                    )}
                />
            </div>
            <UpdatingNarrativesProgress timeRemaining={timeRemaining}
                startTime={clusteringStartTime || Date.now()}
                refetchStatus={refetchStatus}
            />
        </Col>
    );
};

type UpdatingNarrativesProgressProps = UseTimeRemainingProps

const UpdatingNarrativesProgress = ({ timeRemaining, startTime, refetchStatus }: UpdatingNarrativesProgressProps) => {
    const { progress, timeLeft } = useTimeRemaining({ timeRemaining, startTime, refetchStatus });

    return (
        <div className="d-flex dont-break-out justify-content-between align-items-center">
            <p className="mr-2 mb-0 font-size-14">ETA {moment.duration(timeLeft * 1000).humanize()}</p>
            <p className="mb-0 tag small font-size-14">{Math.round(progress)}% complete</p>
        </div>
    );
};
