import React from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { NarrativeVersionModal } from '../Modals/NarrativeVersionModal/NarrativeVersionModal';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../utils/SuccessErrorModal';
import { useUpdateNarrativeVersion } from '../Modals/NarrativeVersionModal/useUpdateNarrativeVersion';
import { DeleteNarrativeVersionModal } from '../Modals/DeleteNarrativeVersionModal/DeleteNarrativeVersionModal';
import { useDeleteNarrativeVersion } from '../Modals/DeleteNarrativeVersionModal/useDeleteNarrativeVersion';
import { useDropDown } from '../../../utils/useDropdown';

type NarrativeProps = {
    notes?: string
    versionId: string,
    narrativeId?: string
}

type NarrativeVersionContextMenuProps = {
    narrative: NarrativeProps,
    getNarrativeVersions: () => void,
    goToPreviousVersion: () => void,
    goToNextVersion: () => void,
    selectedIndex: number,
    isCurrentNarrative: boolean
}
export const NarrativeVersionContextMenu = ({
    narrative,
    getNarrativeVersions,
    goToPreviousVersion,
    goToNextVersion,
    selectedIndex,
    isCurrentNarrative
}: NarrativeVersionContextMenuProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const { setModal, closeModal } = useModal();
    const { updateNarrativeVersion } = useUpdateNarrativeVersion();
    const { deleteNarrativeVersion } = useDeleteNarrativeVersion();
    const setNarrativeVersionModal = (onConfirm: ({ title, notes }: {title?: string; notes?: string; }) => void) => {
        setModal({
            header: false,
            component: <NarrativeVersionModal narrative={narrative} onConfirm={onConfirm} onCancel={closeModal} modalTitle="Edit Version" />
        });
    };
    const setDeleteVersionModal = ({ callback }: { callback: () => Promise<void> }) => {
        setModal({
            header: false,
            component: <DeleteNarrativeVersionModal onConfirm={callback} onCancel={closeModal} />
        });
    };
    const setStatusModal = ({ message, title, type }: { message: string; title: boolean; type: string }) => {
        setModal({
            header: false,
            component: <StatusModalBodyNew title={title} message={message} messageType={type} />
        });
    };
    const handleEditNarrativeClick = () => setNarrativeVersionModal(
        ({ title, notes }: {notes?: string; title?: string;}) => updateNarrativeVersion({
            variables: { versionId: narrative.versionId, narrativeTitle: title, notes }
        })
            .then(() => {
                getNarrativeVersions();
                closeModal();
                setStatusModal({
                    title: false,
                    type: STATUS_TYPES.success,
                    message: 'Narrative version updated successfully'
                });
            })
            .catch(() => {
                setStatusModal({
                    title: false,
                    type: STATUS_TYPES.error,
                    message: 'Error occurred, Please try again later'
                });
            })
    );

    const handleDeleteVersionClick = () => {
        setDeleteVersionModal({
            callback: async () => {
                try {
                    await deleteNarrativeVersion({ variables: { versionId: narrative.versionId } });
                    getNarrativeVersions();
                    if (selectedIndex === 0) {
                        goToNextVersion();
                    } else {
                        goToPreviousVersion();
                    }
                    setStatusModal({
                        title: false,
                        type: STATUS_TYPES.success,
                        message: 'Situation room version deleted successfully'
                    });
                    closeModal();
                } catch (err) {
                    setStatusModal({
                        title: false,
                        type: STATUS_TYPES.error,
                        message: 'Error occurred, Please try again later'
                    });
                }
            }
        });
    };

    return (
        <div onClick={toggleDropdown as React.MouseEventHandler<any>}>
            <Dropdown onClick={(e) => { e.preventDefault(); }}
                className="d-inline pl-1"
                isOpen={dropdown as boolean}
                direction={window.innerWidth < 540 ? 'left' : 'right'}
                toggle={() => null}
                data-testid={`booleanVersion-${narrative.narrativeId}-dropdown`}
            >
                <DropdownToggle className="btn bg-transparent pb-0 pt-0 align-dropdown-toggle"
                    onClick={(e) => { e.preventDefault(); }}
                    tag="button"
                    data-testid={`booleanVersion-${narrative.narrativeId}-dropdownToggle`}
                >
                    <span><i className="fa fa-ellipsis-v text-secondary" /></span>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>
                        <span onClick={handleEditNarrativeClick}>{' '}Edit Version</span>
                    </DropdownItem>
                    {!isCurrentNarrative && (
                        <DropdownItem>
                            <span onClick={handleDeleteVersionClick}>{' '}Delete Version</span>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
