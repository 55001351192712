import React from 'react';
import { Button } from 'reactstrap';

export const Buttons = ({ success, resetValues, handleInvite }) => (
    <div className="d-flex flex-row align-items-center">
        {success ? (
            <Button color="secondary"
                onClick={() => {
                    resetValues();
                }}
                className="mr-2"
                data-testid="close-button"
            >
                Close
            </Button>
        )
            : (
                <Button color="secondary"
                    onClick={() => {
                        resetValues();
                    }}
                    className="mr-2"
                    data-testid="cancel-button"
                >
                    Cancel
                </Button>
            )}
        {!success && (
            <Button color="primary" onClick={handleInvite} data-testid="send-invite-button">
                Send Invite
            </Button>
        )}
    </div>
);
