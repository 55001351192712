import React from 'react';
import { Button } from 'reactstrap';

export const DeleteVersionModal = ({ onConfirm, onCancel } = {}) => (
    <div>
        <h2 className="mb-2">Are you sure?</h2>
        <hr />
        <p>You are about to delete the selected Boolean Version from your room, do you
            want to go ahead with this?
        </p>
        <hr />
        <Button className="mr-2"
            color="secondary"
            onClick={onCancel}
        >Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>Confirm</Button>
    </div>
);
