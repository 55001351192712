import React, { useState } from 'react';
import {
    Dropdown as ReactDropdown,
    DropdownToggle,
    DropdownMenu,
    Label,
    DropdownItem,
    Input
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { useDropDown } from '../../utils/useDropdown';
import { color } from '../../utils/getColors';

type ValueProps = {
    color?: string,
    name: string,
    key: string,
    bgHex?: string
}

type DropdownProps = {
    label?: string,
    value: ValueProps,
    onChange: (e: ValueProps) => void,
    className?: string,
    options: ValueProps[],
    disabled?: boolean,
    error?: string,
    notSelected?: string,
    children?: React.ReactNode,
    search?: boolean
    dropdownMenuClass?: string
}

export const Dropdown = ({ label, value, onChange,
    className, options, disabled, error, notSelected = '', children, search, dropdownMenuClass = '' }: DropdownProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchVal, setSearchVal] = useState('');
    return (
        <div className={className || ''}>
            {label && <Label data-testid="dropdown-label" className="pr-1 no-white-space">{label}</Label>}
            <ReactDropdown isOpen={dropdown as boolean} toggle={toggleDropdown as React.MouseEventHandler<any>}>
                <DropdownToggle tag="div"
                    data-testid="dropdown-toggle"
                    state={error ? 'error' : ''}
                    className={`form-control d-flex align-items-center cursor-pointer ${disabled ? 'bg-light text-muted border' : ''}`}
                    disabled={disabled}
                >
                    {value?.color && !value?.bgHex && (
                        <div className={`bg-${value?.color} p-1 mr-1 rounded`} />
                    )}
                    {value?.bgHex && (
                        <div style={{ background: value.bgHex }}
                            className=" p-1 mr-1 rounded"
                        />
                    )}
                    {children ? (
                        <>
                            <span className="pr-5 mr-5 pl-2">{value?.name || notSelected}</span>
                            <span className="float-right p-1 border-left ml-auto">
                                {children}
                            </span>
                        </>
                    ) : (
                        <><span className="pr-1">{value?.name || notSelected}</span>
                            <span className="float-right ml-auto">
                                <FeatherIcon.ChevronDown data-testid="chevron-down" color={color.blue[500]} size={20} />
                            </span>
                        </>
                    )}
                </DropdownToggle>
                <DropdownMenu left={value?.toString()}
                    className={`maxh-300 scrollbar-small overflow-y-auto overflow-x-hidden ${dropdownMenuClass}`}
                >
                    {search && (
                        <div className="p-1">
                            <Input placeholder="Search"
                                value={searchVal}
                                onChange={(e) => {
                                    setSearchVal(e.target.value);
                                }}
                                data-testid="user-search-input"
                            />
                        </div>
                    )}
                    {options?.filter(a => (
                        a.key.includes(searchVal.toLowerCase())
                        || a.name.toLowerCase().includes(searchVal.toLowerCase())
                    ))?.map(item => (
                        <DropdownItem data-testid={`${item?.key}-dropdown-item`}
                            onClick={() => onChange(item)}
                            key={item?.key}
                            active={value?.key === item?.key}
                            className="d-flex align-items-center"
                        >
                            {item?.color && !item?.bgHex && (
                                <div className={`bg-${item?.color} p-1 mr-1 rounded`} />
                            )}
                            {item?.bgHex && (
                                <div style={{ background: item.bgHex }}
                                    className=" p-1 mr-1 rounded"
                                />
                            )}
                            {item?.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ReactDropdown>
            {error && <small><Label color="danger" className="mt-2">{error}</Label></small>}
        </div>
    );
};
