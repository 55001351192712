import { PropsWithChildren, ReactElement, cloneElement } from 'react';

export type AccordionItemProps = {
    header: ReactElement|string
    iconOpen?: ReactElement
    iconClose?: ReactElement
    useSeparator?: boolean
}

export const AccordionItem = ({ header, iconOpen, iconClose, useSeparator, children }: PropsWithChildren<AccordionItemProps>) =>
    cloneElement(children as ReactElement, { header, iconOpen, iconClose, useSeparator });
