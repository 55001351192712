import React from 'react';
import { NarrativeVersionContextMenu } from './NarrativeVersionContextMenu';

type DataProps = {
    id: string,
    keywords: string[],
    boolean?: string,
    threat?: string,
    query_type: string
}

type NarrativeProps = {
    data: DataProps[]
    narrativeTitle?: string,
    user?: {displayName: string},
    updatedAt?: Date,
    notes?: string,
    versionId: string,
    narrativeId?: string
}

type NarrativeVersionPanelProps = {
    narrative: NarrativeProps,
    selectVersion: (e: NarrativeProps) => void,
    selected: boolean,
    isCurrentNarrative: boolean,
    latest: boolean,
    getNarrativeVersions: () => void,
    goToPreviousVersion: () => void,
    goToNextVersion: () => void,
    selectedIndex: number
}

export const NarrativeVersionPanel = ({
    narrative,
    selectVersion,
    selected,
    isCurrentNarrative,
    latest,
    getNarrativeVersions,
    goToPreviousVersion,
    goToNextVersion,
    selectedIndex
}: NarrativeVersionPanelProps) => (
    <div className={`d-flex flex-column border rounded p-2 mb-2 ${selected && 'bg-light'}`}
        onClick={() => selectVersion(narrative)}
        data-testid={`${narrative.narrativeTitle}-panel`}
    >
        {(!selected) ? (
            <div className="d-flex justify-content-between">
                <span>{narrative.narrativeTitle}</span>
            </div>
        ) : (
            <div className="d-flex justify-content-between mb-n2">
                <div className="d-flex justify-content-between">
                    <span>{narrative.narrativeTitle}</span>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <NarrativeVersionContextMenu narrative={narrative}
                        getNarrativeVersions={getNarrativeVersions}
                        goToPreviousVersion={goToPreviousVersion}
                        goToNextVersion={goToNextVersion}
                        selectedIndex={selectedIndex}
                        isCurrentNarrative={isCurrentNarrative}
                    />
                </div>
            </div>
        )}
        <span>{narrative?.user?.displayName || 'N/A'}</span>
        <span>{new Date(narrative?.updatedAt || '').toLocaleDateString()}</span>
        <span>{new Date(narrative?.updatedAt || '').toLocaleTimeString()}</span>
        {narrative.notes && (
            <>
                <span className="font-weight-bold pt-1">Notes:</span>
                <span>{narrative.notes}</span>
            </>
        )}
        <div className="d-flex flex-row">
            {latest && <span className="small tag mt-1 mb-1" color="light">Latest</span>}
            {isCurrentNarrative
             && <span className={`small tag ${!latest ? 'mt-1 mb-1' : 'm-1'}`} color="light">Applied to Narrative</span>}
        </div>
    </div>
);
