import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { TitleTooltip } from '../utils';
import { WordCloud } from './Vis';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { getRoom } from '../../../../../utils/variables';
import { ActionsDropdown } from '../../../../../components/Atoms/ActionsDropdown';
import { NoAnnotationsEmptyState } from '../../NoAnnotationsEmptyState';

export const Wordcloud = ({ preview = false, noAnnotations }) => {
    const { setModal } = useModal();
    const history = useHistory();
    const room = getRoom();
    const wordcloudRef = useRef(null);

    const options = [
        { key: 'hashtags', name: 'Hashtags' },
        { key: 'entities', name: 'Entities', requiresAnnotations: true },
        { key: 'mentions', name: 'Mentions' }
    ];
    const [current, setCurrent] = useState(options[0]);
    const getContentModal = ({ wordCloudItem }) => {
        if (preview) {
            return (
                <ContentPreviewModal name={wordCloudItem.original.replace(/_/g, ' ')}
                    filter={{ value: [wordCloudItem.original], type: current.key }}
                    contentType={CONTENT_TYPE.THREAT}
                    preview={preview}
                />
            );
        }

        return (
            <ContentModal name={wordCloudItem.original.replace(/_/g, ' ')}
                filter={{ value: [wordCloudItem.original], type: current.key }}
                contentType={CONTENT_TYPE.THREAT}
            />
        );
    };
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const handleOnClick = (wordCloudItem) => {
        if (betaFiltersEnabled) {
            return history.push({ pathname: `/situation-rooms/${room.id}/overview/matches`,
                state: { value: wordCloudItem.original.replace(/_/g, ' '), type: current.key } });
        }
        return setModal({
            header: wordCloudItem.original.replace(/_/g, ' '),
            size: 'xl',
            component: getContentModal({ wordCloudItem })
        });
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="mb-3 d-flex">
                <TitleTooltip type="wordcloud" title="Wordcloud Generator" />
                <ActionsDropdown className="ml-auto">
                    <DropdownItem onClick={() => wordcloudRef.current?.exportWordcloud()}>
                        Export as PNG
                    </DropdownItem>
                </ActionsDropdown>
            </div>
            <div className="mb-1">
                <Dropdown value={current} options={options} onChange={setCurrent} />
            </div>
            {current.requiresAnnotations && noAnnotations ? (
                <NoAnnotationsEmptyState label="Entities" className="h-100 d-flex flex-column justify-content-center" />
            ) : (
                <WordCloud key={current.key}
                    field={current.key}
                    size={preview ? 15 : 30}
                    onWordClick={wordCloudItem => handleOnClick(wordCloudItem)}
                    ref={wordcloudRef}
                />
            )}
        </div>
    );
};
