import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as FeatherIcon from 'react-feather';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment/moment';
import { setReportAction } from '../../../../../redux/reports';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { processDateRange } from '../../../../../utils/getTimeFrom';
import { getRoom } from '../../../../../utils/variables';
import { color } from '../../../../../utils/getColors';

const dateFilters = [
    { key: 'all', name: 'All time', value: 'All', id: 'filter-time-all-time', label: 'All time' },
    { key: 'day', name: 'Last 24 hrs', value: 'Last 24 hrs', id: 'filter-time-last-24', label: 'Last 24 hrs' },
    { key: 'week', name: 'Last 7 days', value: 'Last 7 days', id: 'filter-time-last-7', label: 'Last 7 days' },
    { key: 'month', name: 'Last 30 days', value: 'Last 30 days', id: 'filter-time-last-30', label: 'Last 30 days' },
    { key: 'sixmonths', name: 'Last 6 Months', value: 'Last 6 Months', id: 'filter-time-last-6', label: 'Last 6 Months' },
    { key: 'custom', name: 'Custom date', value: 'Custom date', id: 'filter-time-last-custom', label: 'Custom' }
];

const savedReportDate = ({ report }) => {
    if (!report.filters?.startDate) return dateFilters[0];
    const dateStampStart = moment.unix(report.filters.startDate).format('L');
    const dateStampEnd = moment.unix(report.filters.endDate).format('L');
    return {
        key: 'custom',
        name: `${dateStampStart} - ${dateStampEnd}`
    };
};

export const DatePicker = () => {
    const ref = useRef();
    const room = getRoom();
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const [date, setDate] = useState(savedReportDate({ report }));
    const savedStartDate = report.filters?.startDate ? moment.unix(report?.filters?.startDate).toDate() : undefined;
    const savedEndDate = report.filters?.startDate ? moment.unix(report?.filters?.endDate).toDate() : undefined;

    let maxDate = moment().toDate();
    let dateFiltersOptions = dateFilters;
    if (room.contentVisibilityRestriction) {
        maxDate = moment().subtract(room.contentVisibilityRestriction, 'days').toDate();
        dateFiltersOptions = [
            { key: 'all', name: 'All time', value: 'All', id: 'filter-time-all-time', label: 'All time' },
            { key: 'custom', name: 'Custom date', value: 'Custom date', id: 'filter-time-last-custom', label: 'Custom' }
        ];
    }

    const onDatePickerCallback = (_e, datePicker) => {
        const start = datePicker.startDate;
        const end = datePicker.endDate;
        setDate({
            key: 'custom',
            name: `${start.format('L')} - ${end.format('L')}`
        });
        dispatch(setReportAction({
            ...report,
            filters: {
                ...report.filters,
                startDate: start.unix(),
                endDate: end.unix()
            }
        }));
    };

    return (
        <div>
            <Dropdown label="Date"
                className="ml-2 mr-1"
                value={date}
                onChange={(e) => {
                    if (e.key === 'custom') {
                        ref.current.ref.click();
                        return;
                    }
                    setDate(e);
                    const range = processDateRange({ rangeFilterOption: e.key });
                    dispatch(setReportAction({
                        ...report,
                        filters: {
                            ...report.filters,
                            startDate: range.startDate,
                            endDate: range.endDate
                        }
                    }));
                }}
                options={dateFiltersOptions}
            >
                <FeatherIcon.Calendar className="ml-1" color={color.blue[500]} />
            </Dropdown>
            <div className="daterangepicker-container" />
            <ReactDateRangePicker ref={ref}
                onApply={onDatePickerCallback}
                initialSettings={{
                    parentEl: '.daterangepicker-container',
                    startDate: savedStartDate,
                    endDate: savedEndDate,
                    minDate: moment(room.start_date).toDate(),
                    maxDate
                }}
            >
                <div />
            </ReactDateRangePicker>
        </div>
    );
};
