import React from 'react';
import moment from 'moment';
import { dateFilters } from '../../../utils/lib/search';
import { RadioFilter } from '../../Filters';
import { DateRangePicker } from '../../DateRangePicker';
import { getRoom } from '../../../utils/variables';

export const DateFilter = ({
    dateRangeOption,
    setRangeOption,
    setRange,
    inputRange,
    setInputRange,
    dateRange,
    title = 'Time'
}) => {
    let maxDate = moment().toDate();
    let dateFiltersOptions = dateFilters;
    const room = getRoom();

    if (room.contentVisibilityRestriction) {
        maxDate = moment().subtract(room.contentVisibilityRestriction, 'days').toDate();
        dateFiltersOptions = [
            { key: 'all', name: 'All time', value: 'All', id: 'filter-time-all-time', label: 'All time' },
            { key: 'custom', name: 'Custom date', value: 'Custom date', id: 'filter-time-last-custom', label: 'Custom' }
        ];
    }

    const config = {
        startDate: dateRange?.startDate ? moment.unix(dateRange.startDate).toDate() : null,
        endDate: dateRange?.startDate ? moment.unix(dateRange.endDate).toDate() : null,
        maxDate,
        minDate: moment(room.start_date).toDate()
    };

    const onDatePickerCallback = (start, end) => {
        const dateStampStart = moment(start).format('L');
        const dateStampEnd = moment(end).format('L');
        setInputRange(`${dateStampStart} - ${dateStampEnd}`);
        setRange({
            startDate: start.unix(),
            endDate: end.unix()
        });
    };

    return (
        <>
            <RadioFilter title={title}
                filters={dateFiltersOptions}
                checked={dateRangeOption}
                setChecked={setRangeOption}
            />
            {dateRangeOption === 'custom' && (
                <DateRangePicker inputRange={inputRange}
                    onDatePickerCallback={onDatePickerCallback}
                    config={config}
                />
            )}
        </>
    );
};
