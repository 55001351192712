import React from 'react';
import { useSelector } from 'react-redux';
import { Report } from '../../../../../assets/svg/report';
import { SelectIcon } from '../../../../../assets/svg/select-icon';

export const EmptyState = ({ field }) => {
    const { report } = useSelector(state => state.createReport);
    if (report && report[field]) {
        return null;
    }
    return (
        <div className="py-10 bg-white px-4 d-flex align-items-center justify-content-center mt-4">
            <div className="text-center">
                {emptyStates[field].icon}
                <h2 className="mb-0 mt-2">{emptyStates[field].text}</h2>
            </div>
        </div>
    );
};

const emptyStates = {
    name: {
        text: 'Enter report name and get started',
        icon: <Report />
    },
    template: {
        text: 'Select a template to continue',
        icon: <SelectIcon />
    }
};
