import React from 'react';
import { getIcons } from '../../../../../../utils/getIcons';
import { largeNumber } from '../../../../../../utils/NumberFormat';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';
import { unsetParam } from '../../../../../../utils/urlParams';

type SubClustersProps = {
    count?: number | string
    setTab?: (a: string) => void
    onClick?: () => void
    disabled?: boolean
}

export const SubClusters = ({
    count,
    setTab,
    onClick,
    disabled
}: SubClustersProps) => {
    const handleClick = () => {
        if (disabled) return;
        if (onClick) return onClick();
        if (setTab) setTab('narrative-clusters');
        unsetParam('hasDuplicates');
    };

    return (
        <div className="cluster-stat-container" onClick={handleClick}>
            <WidgetTextTooltip widgetName={WidgetName.Narratives} />
            <span className="font-weight-bold d-flex align-items-center">{getIcons('subClusters')}
                <p className={`m-0 ml-1 font-weight-normal ${disabled ? '' : 'btn btn-link d-inline p-0 mw-0'}`}>
                    {largeNumber(count)}
                </p>
            </span>
        </div>
    );
};
