import React from 'react';
import { Button, Col } from 'reactstrap';
import _ from 'lodash';
import { getRoom } from '../../../../utils/variables';
import { allTypes } from '../../../../utils/lib/search';
import { ContentTableContentType } from '..';

type ThreatSignalsProps = {
    content: Pick<ContentTableContentType, 'threatObject' >,
    handleSignalClick: () => void
}

export const ThreatSignalLabels = ({ content, handleSignalClick }: ThreatSignalsProps) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const { threatResolvers, removedThreatLabels, customThreatLabels } = content.threatObject;
    const displayList = [] as {signalName: string, confidence: number, isResolver: boolean}[];
    allTypes.forEach(type => {
        if (disabledThreatTypes.includes(type.key)) {
            return false;
        }
        const key = type.isResolver ? 'resolver' : 'type';
        threatResolvers?.forEach((c: ContentTableContentType['threatObject']['threatResolvers']) => {
            if ((c[key] === type.key) && (removedThreatLabels ? !removedThreatLabels.includes(type.key) : true)) {
                const item = { signalName: '', confidence: 0, isResolver: false };
                item.signalName = type.name;
                item.confidence = c.confidence;
                item.isResolver = type.isResolver || false;
                displayList.push(item);
            }
        });
        if (customThreatLabels && (customThreatLabels.map(a => a.signalKey)).includes(type.key)) {
            const item = { signalName: '', confidence: 0, isResolver: false };
            item.signalName = type.name;
            item.confidence = 0;
            item.isResolver = type.isResolver || false;
            displayList.push(item);
        }
    });
    if (displayList.length === 0) {
        return null;
    }
    const sortSignals = () => {
        const finalDisplayList = _.uniqBy(displayList, 'signalName');
        if (finalDisplayList.length > 1) {
            return finalDisplayList.sort((a, b) => b.confidence - a.confidence);
        }
        return finalDisplayList;
    };
    const highestValueSignal = sortSignals();

    return (
        <Col className="card content-view new-content-view threats mr-3 px-2 py-1 set-fit-content col-lg-4 col-xs-6">
            <p className="mb-1 set-fit-content">Threats</p>
            <Button color="link"
                className="p-0 mw-0 d-block"
                onClick={handleSignalClick}
                data-testid="threat-signal"
            >{displayList.length === 1 ? highestValueSignal[0].signalName
                    : `${highestValueSignal[0].signalName} | +${highestValueSignal.length - 1}`}
            </Button>
        </Col>

    );
};
