import React, { useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { AllTypes } from '../../../../utils/text';
import { ThreatLabels } from '../../../../components/ContentTable/ThreatLabels';
import { getRoom } from '../../../../utils/variables';

export const WhyIsThisAThreat = ({ data }) => {
    const [sort, setSort] = useState({
        field: 'typeName',
        order: 'asc'
    });

    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];

    const resolvers = data.threatObject?.threatResolvers?.map(item => ({
        ...item,
        resolverName: AllTypes[item.resolver]?.name || 'NA',
        typeName: AllTypes[item.type]?.name || 'Toxic information'
    }))?.filter(({ type }) => !disabledThreatTypes.includes(type));
    const threatResolvers = data.threatObject?.threatResolvers?.filter(
        (resolver) => resolver.type === 'deep-toxic-classifier'
    )?.map((type) => type.resolver)?.filter(resolver => !disabledThreatTypes.includes(resolver));

    return (
        <div className="bg-white p-3 mt-4 rounded-sm">
            <Row>
                <Col>
                    <h3 className="my-0">Threats</h3>
                    <p className="my-2">
                        LI analysis has identified this content as a threat based on these insights.
                    </p>
                    <ThreatLabels threatLabels={data.threatObject?.threatLabels}
                        threatResolvers={threatResolvers}
                        whyathreat={data.threatObject?.whyathreat}
                    />

                    <Row className="threat-resolvers pr-3 pl-2">
                        <Table className="border-bottom" hover>
                            <thead>
                                <tr>
                                    <th>
                                        <SortToggle sort={sort}
                                            setSort={setSort}
                                            name="Threat type"
                                            field="typeName"
                                        />
                                    </th>
                                    <th>
                                        <SortToggle sort={sort}
                                            setSort={setSort}
                                            name="Sub category"
                                            field="resolverName"
                                        />
                                    </th>
                                    <th>%</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lodashSort(resolvers, sort).map((item) => (
                                    <tr key={item.typeName + item.resolverName}>
                                        <td>{item.typeName}</td>
                                        <td>
                                            {item.resolverName}
                                        </td>
                                        {(item.type !== 'ITH' && item.type !== 'CTA') && (
                                            <td>
                                                {item.confidence}%
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
