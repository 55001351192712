import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

type PersonPaginationProps = {
    index: number,
    setIndex: (index: number) => void,
    totalLength: number,
    name?: string | null
}

export const PersonPagination = ({ index, setIndex, totalLength, name }: PersonPaginationProps) => {
    const prevClickDisabled = index === 0;
    const nextClickDisabled = index === totalLength - 1;
    return (
        <div className="border bg-lightgray d-flex justify-content-between mb-2">
            <h3 className="m-0 p-2">{name}</h3>
            <div className="d-flex">
                <div role="button"
                    onClick={() => { if (!prevClickDisabled) { setIndex(index - 1); } }}
                    className={`border-left p-2 bg-white text-${!prevClickDisabled ? 'primary' : 'muted'}`}
                >
                    <ChevronLeft />
                </div>
                <div role="button"
                    onClick={() => { if (!nextClickDisabled) { setIndex(index + 1); } }}
                    className={`border-left p-2 bg-white text-${!nextClickDisabled ? 'primary' : 'muted'}`}
                >
                    <ChevronRight />
                </div>
            </div>
        </div>
    );
};
