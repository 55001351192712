import React from 'react';
import { CheckboxFilter } from '../../Filters';
import { TooltipItem } from '../../ToolTip';
import { useTopLanguages } from '../../../services/Overview';
import { languagesList } from '../../../utils/variables';

export const LanguageFilter = ({ languages = [], setLanguages, title = 'Language' }) => {
    const { data, loading } = useTopLanguages({ size: 1000, useGlobalFilters: false });
    if (loading) return null;
    const filters = data.map(({ language, key }) => ({ key, label: language }));
    languagesList(data.map(({ key }) => key));
    return (
        <div>
            <span className="d-flex">
                <p className="font-weight-bold">{title}</p>
                <TooltipItem placement="auto"
                    id="language-filters"
                    content="Languages that have been used in the content"
                />
            </span>
            <div className="maxh-350 scrollbar-small overflow-y-auto overflow-x-hidden">
                <CheckboxFilter title={title}
                    filters={filters}
                    values={languages}
                    setValues={setLanguages}
                    value="languages"
                />
            </div>
        </div>
    );
};
