import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import { getRoom } from '../../../../../../utils/variables';
import { formatNetworksData } from './formatNetworksData';
import { NetworksHistoryStore } from '../../../store';
import { RootState } from '../../../../../../store';

export const useSaveNetwork = () => {
    const room = getRoom();
    const { present } = useSelector((state: NetworksHistoryStore) => state.networksHistory);
    const filters = useSelector((state: RootState) => state.filters.filters);
    const [saveNetwork, { loading }] = useMutation(SAVE_NETWORK, {
        refetchQueries: ['getNetworks']
    });

    return {
        saveNetwork: ({ name, onCompleted }: { name: string, onCompleted: () => void }) => {
            saveNetwork({
                variables: {
                    situationRoom: room.id,
                    network: {
                        name,
                        ...formatNetworksData(present),
                        filters: omit(filters, ['dateRangeOption'])
                    }
                },
                onCompleted
            });
        },
        loading
    };
};

export const SAVE_NETWORK = gql`
    mutation saveNetwork($situationRoom: ID!, $network: NetworkInput!) {
        saveNetwork(situationRoom: $situationRoom, network: $network)
    }
`;
