import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './assets/scss/theme.scss';
import { useApolloClient, useQuery } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid';
import LogRocket from 'logrocket';
import { X } from 'react-feather';
import { GET_USER } from './graphql';
import { Loading } from './components/Loading';
import { NavBar } from './components/NavBar';
import { NotFound } from './pages/Error/404';
import { useSessionUser } from './auth0';
import { ModalContextProvider } from './context/modal/ModalComponent';
import { UserContextProvider } from './context/user/UserContext';
import { getUser } from './utils/variables';
import { LoggedOutApp } from './LoggedOutApp';
import { LoggedInApp } from './LoggedInApp';
import { routes } from './routes';
import { CookiesContextProvider } from './context/cookies/CookiesContext';
import { fetchCtypes } from './utils/text';
import { mixpanelIdentify, mixpanelPeopleSet } from './mixpanel';
import { color } from './utils/getColors';
import { enableHotjar } from './hotjar';

export const App = () => {
    const client = useApolloClient();
    const [tabId] = useState(uuid());
    const { user: sessionUser, loading: sessionDataLoading, idToken } = useSessionUser();
    const { data, loading: userDataLoading } = useQuery(GET_USER, {
        variables: {
            uid: sessionUser.uid,
            truncateName: 30
        },
        skip: !sessionUser.uid,
        onCompleted: ({ user }) => {
            if (user) {
                LogRocket.identify(user.uid, {
                    name: user.displayName || '',
                    email: user.email,
                    instanceId: user.instance?.id,
                    clientName: user.instance?.clientName
                });
                LogRocket.track(user.instance?.id, {
                    name: user.displayName || '',
                    email: user.email,
                    instanceId: user.instance?.id,
                    clientName: user.instance?.clientName
                });
                enableHotjar({
                    id: user.uid,
                    name: user.displayName || '',
                    email: user.email,
                    instanceId: user.instance?.id,
                    clientName: user.instance?.clientName
                });
                mixpanelIdentify(user.uid);
                mixpanelPeopleSet({
                    $email: user.email,
                    $name: user.displayName || '',
                    $instanceId: user.instance?.id,
                    $clientName: user.instance?.clientName,
                    $environment: window.REACT_APP_ENV
                });
                darkmodeToggle(user.preferences.darkmode);
                fetchCtypes({ client });
            }
        }
    });
    const user = data?.user || false;

    const darkmodeToggle = (mode) => {
        if (mode && !document.body.classList.contains('darkmode--activated')) {
            document.body.classList.add('darkmode--activated');
        } else if (!mode) {
            document.body.classList.remove('darkmode--activated');
        }
    };

    useEffect(() => {
        if (user) {
            getUser(user);
        }
    }, [user]);

    useEffect(() => {
        const handleUnload = () => {
            let activeTabs = sessionStorage.activeTabs ? JSON.parse(sessionStorage.activeTabs) : [1];
            if (activeTabs.length === 1) {
                sessionStorage.removeItem('activeTabs');
                if (idToken) {
                    navigator.sendBeacon(`${window.FUNCTION_HOST}/update-user-status`, JSON.stringify({
                        token: idToken,
                        status: 'Away'
                    }));
                }
            } else {
                activeTabs = activeTabs.filter(a => a !== tabId);
                sessionStorage.activeTabs = JSON.stringify(activeTabs);
            }
        };
        window.addEventListener('beforeunload', handleUnload);
        window.onload = () => {
            const activeTabs = sessionStorage.activeTabs ? JSON.parse(sessionStorage.activeTabs) : [];
            activeTabs.push(tabId);
            sessionStorage.activeTabs = JSON.stringify(activeTabs);
        };
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [idToken, tabId]);

    if ((userDataLoading || sessionDataLoading) && !data) {
        return <Loading gif />;
    }

    const closeButton = ({ closeToast }) => <X color={color.darkblue['700']} size={24} onClick={closeToast} />;
    const toastTheme = user?.preferences?.darkmode ? 'dark' : 'light';

    return (
        <div className="app">
            <ToastContainer closeButton={closeButton} theme={toastTheme} autoClose={1500} closeOnClick newestOnTop />
            <div id="wrapper">
                <Router>
                    <CookiesContextProvider>
                        <UserContextProvider user={user}>
                            <ModalContextProvider>
                                <NavBar />
                                <Switch>
                                    <Route exact path={Object.values(routes.loggedOut)}>
                                        <LoggedOutApp />
                                    </Route>
                                    <Route exact
                                        path={[
                                            ...Object.values(routes.loggedIn),
                                            ...Object.values(routes.situationRoom),
                                            ...Object.values(routes.admin)
                                        ]}
                                    >
                                        <LoggedInApp />
                                    </Route>
                                    <Route component={NotFound} />
                                </Switch>
                            </ModalContextProvider>
                        </UserContextProvider>
                    </CookiesContextProvider>
                </Router>
            </div>
        </div>
    );
};
