import { gql, useQuery } from '@apollo/client';
import { formatLabel } from '../../pages/Room/Overview/Visualisation/utils';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useSentiment = ({ defaultFilters = {} } = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters });
    const ret = useQuery(CURRENT_SENTIMENT, {
        variables: filters
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.currentSentiment?.map(item => ({
            ...item,
            sentiment: formatLabel(item.sentiment, 'sentiment__keyword')
        })) || [] : []
    };
};

export const CURRENT_SENTIMENT = gql`
    query currentSentiment(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        currentSentiment(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
        ) {
            sentiment
            count
        }
    }
`;
