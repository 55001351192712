import React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { useQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { SubCluster } from '../types';
import { IconButton } from '../../../../components/Atoms';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { getRoom } from '../../../../utils/variables';
import { useModal } from '../../../../context/modal/ModalComponent';
import { gql } from '../../../../generated';

type NarrativeThemesProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    narratives: SubCluster[];
};

export const NarrativeThemes = ({ isCollapsed, setCollapsable, narratives }: NarrativeThemesProps) => {
    const { setModal, closeModal } = useModal();
    const narrativeThemeIds: string[] = narratives.map((c) => c.topClustersMappedId);
    const room = getRoom();
    const { data } = useQuery(GET_NARRATIVE_THEME, {
        variables: {
            projectId: room.project_id,
            id: narrativeThemeIds
        },
        skip: !narrativeThemeIds.length
    });
    const viewSummaryModal = (title: string, summary: string) => setModal({
        component: (
            <div>
                <h3 className="m-0">Narrative theme details</h3>
                <hr />
                <p className="font-weight-bold">{title}</p>
                <p className="my-2">{summary}</p>
                <hr />
                <Button onClick={closeModal}>Close</Button>
            </div>
        )
    });

    const narrativeThemes = data?.getClusters?.clusters || [];

    if (!narrativeThemes.length) return null;
    return (
        <>
            <div className="d-flex align-items-center gap-1 cursor-pointer" onClick={setCollapsable}>
                <IconButton
                    className="icon-button text-decoration-none"
                    icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                    text={<WidgetTextTooltip widgetName={WidgetName.NarrativeTheme} noMargin className="ml-2" />}
                />
            </div>
            <div className="grid_container_narrative mb-4 mt-3">
                {isCollapsed && [0, 1, 2, 3].map((_, index) => {
                    const currentNarrative = narratives[index];
                    if (!currentNarrative) {
                        return <div className="border p-2" key={_} />;
                    }
                    const narrativeTheme = narrativeThemes.find((c) => c.id === currentNarrative.topClustersMappedId);
                    if (!narrativeTheme) {
                        return <div className="border p-2" key={_} />;
                    }
                    return (
                        <div className="bg-white p-2 border" key={narrativeTheme.id + _}>
                            <p className="mb-2">{narrativeTheme.title}</p>
                            <Button color="link"
                                className="m-0 pt-1 d-inline p-0"
                                data-testid="narrativeThemeViewSummary"
                                onClick={() => viewSummaryModal(narrativeTheme.title, narrativeTheme.description)}
                            >
                                View summary
                            </Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export const GET_NARRATIVE_THEME = gql(`
    query getClusters($projectId: String!, $id: [String]){
        getClusters(projectId: $projectId, id: $id){
            clusters{
                id
                title
                description
            }
        }
    }
`);
