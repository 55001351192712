export const storeViewedItems = ({ narrativeId, isNarrativetheme }: { narrativeId?: string; isNarrativetheme?: boolean }) => {
    const key = isNarrativetheme ? 'themeIds' : 'narrativeIds';
    const storedIdsString = localStorage.getItem(key);
    const storedIds = storedIdsString ? JSON.parse(storedIdsString) : [];

    if (narrativeId && !storedIds.includes(narrativeId)) {
        const idsList = [...storedIds, narrativeId];
        localStorage.setItem(key, JSON.stringify(idsList));
    }

    else return storedIds;
};
