import { useEffect, useState } from 'react';

export type UseTimeRemainingProps = {
    timeRemaining: number
    startTime: number
    refetchStatus: () => void
}

export const useTimeRemaining = ({ timeRemaining, startTime, refetchStatus }: UseTimeRemainingProps) => {
    const [progress, setProgress] = useState(0);
    const [timeLeft, setTimeLeft] = useState(timeRemaining);

    useEffect(() => {
        const now = Date.now();
        const timeElapsed = (now - startTime) / 1000;
        const totalDuration = timeElapsed + timeRemaining;

        const initialProgress = Math.min((timeElapsed / totalDuration) * 100, 100);
        setProgress(initialProgress);
        setTimeLeft(Math.max(totalDuration - timeElapsed, 0));

        const intervalId = setInterval(() => {
            const newElapsed = (Date.now() - startTime) / 1000;
            const newProgress = Math.min((newElapsed / totalDuration) * 100, 100);
            const remaining = Math.max(totalDuration - newElapsed, 0);

            setProgress(newProgress < 0 ? 0 : newProgress);
            setTimeLeft(remaining);

            if (newProgress >= 100 || remaining <= 0) {
                clearInterval(intervalId);
            }
        }, 1000);

        // refetch status every 30 seconds
        const refetchInterval = setInterval(() => {
            refetchStatus();
        }, 30000);

        return () => {
            clearInterval(intervalId);
            clearInterval(refetchInterval);
        };
    }, [startTime, timeRemaining, refetchStatus]);

    return {
        progress,
        timeLeft: timeLeft > 0 ? timeLeft : 0
    };
};
