import React from 'react';
import { Col, Row } from 'reactstrap';
import { getRoom } from '../../../../utils/variables';
import { LabelDropdown } from '../../Labels';
import { LabelsDisplay } from '../../Labels/LabelsDisplay';
import { TooltipItem } from '../../../../components/ToolTip';
import { TextTooltip } from '../../../../components/ToolTip/TextTooltip';

type LabelsViewProps = {
    contentId?: string
}

export const LabelsView = ({ contentId }: LabelsViewProps) => {
    const room = getRoom();
    const labelsFeatureEnabled = !!room.instance?.plan?.others?.labels;
    if (!labelsFeatureEnabled || !contentId) return null;

    return (
        <div className="bg-white p-2 mt-0 mt-3 rounded-sm">
            <Row className="ml-0">
                <Col className="col-10 pr-1 pl-0 mb-1 d-flex align-items-center">
                    <h3 className="m-0">Labels</h3>
                    <TooltipItem id="labelsTooltip"
                        content="Labels allows you to manually label documents within this platform for easier filtering"
                        placement="right"
                    />
                </Col>
                <Col className="col-2 pl-1">
                    <div className="d-flex justify-content-end align-items-center">
                        <TextTooltip placement="top"
                            title="Labels"
                            id={`content-${contentId}-labels`}
                            withIcon
                        >
                            <LabelDropdown roomId={room?.id} contentId={contentId} />
                        </TextTooltip>
                    </div>
                </Col>
            </Row>
            <Row className="ml-0 w-100">
                <LabelsDisplay contentId={contentId} roomId={room?.id} showEmptyState />
            </Row>
        </div>
    );
};
