import React from 'react';
import { getPostedByName } from '../../utils/getPostedByName';
import { getCtype } from '../../utils/text';
import { ExternalLinkButton } from '../LinkModal/ExternalLinkButton';

type Data = {
    contentType?: string,
    from?: string,
    displayName?: string,
    url?: string
}

type SourceIconLabelProps = {
    data: Data,
    link?: boolean,
    className?: string
}
export const SourceIconLabel = ({ data, link = false, className = '' }: SourceIconLabelProps) => {
    const ctype = getCtype(data.contentType);

    if (!ctype.icon || ctype.icon === '') {
        return (getPostedByName(data.from, data.displayName));
    }

    const content = (
        <>
            <span className="mr-1">
                <i className={`fa ${ctype.icon}`}
                    data-testid="source-icon"
                    style={
                        ctype.FontFamily
                            ? { fontFamily: `"${ctype.FontFamily}"`, color: `rgb(${ctype.color})` }
                            : { color: `rgb(${ctype.color})` }
                    }
                />
            </span>
            {getPostedByName(data.from, data.displayName)}
        </>
    );
    if (link) {
        return (
            <div className="d-flex justify-content-between dont-break-out">
                <div>{content}</div>
                {data.url && (
                    <ExternalLinkButton url={data.url} classname="set-min-width">
                        View
                    </ExternalLinkButton>
                )}
            </div>
        );
    }
    return <span className={`${className} dont-break-out`}>{content}</span>;
};
