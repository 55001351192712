import { Button } from 'reactstrap';
import React from 'react';
import * as FeatherIcon from 'react-feather';
import { useModal } from '../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../utils/variables';

export const BatchRecoverCancelledModal = ({ onResume, onAbort, contentsToProcess } = {}) => {
    const room = getRoom();
    const { closeModal } = useModal();
    const handleAbort = () => {
        onAbort();
        closeModal();
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">Bulk recover cancelled</h2>
                <FeatherIcon.X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={handleAbort}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <div>
                Bulk recover has been cancelled {contentsToProcess || 0} are left to be recovered back to {room.project_name}
            </div>
            <hr className="my-2" />
            <Button className="mr-2" onClick={handleAbort}>Close</Button>
            <Button className="mr-2" color="danger" onClick={onResume}>Resume</Button>
        </>
    );
};
