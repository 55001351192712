import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../../../../../../components/Loading';
import { useCustomNarrativesAndThreats } from '../../../../../../../../services/Report/getCustomNarrativesAndThreats';
import { CustomNarrativesTableInner } from './CustomNarrativesTableInner';
import { NoResults } from '../../NoResults';

export const CustomNarrativesTable = () => {
    const { report } = useSelector(state => state.createReport);
    const { data, loading } = useCustomNarrativesAndThreats({ dateFilter: report.filters });

    if (loading) {
        return <Loading relative height={300} />;
    }

    if (!data || data?.length === 0) {
        return <NoResults text="There are no custom narratives created yet!" />;
    }

    return (
        <CustomNarrativesTableInner data={data} count={data.length} />
    );
};
