import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ContentPreviewModal } from './contentPreviewModal';
import { getRoom } from '../../../../utils/variables';
import { saveFilters } from '../../store';
import { getCtype } from '../../../../utils/text';
import { ContentModal } from './contentModal';

const types = {
    ctype__keyword: 'contentType',
    locations: 'origin',
    contentType: 'contentType',
    from: 'from',
    languages: 'languages'
};

export const ContentModalButton = ({ value, type, label, preview, children, count }) => {
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const room = getRoom();
    const ContentModalComponent = preview ? ContentPreviewModal : ContentModal;
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const handleClick = () => {
        if (!betaFiltersEnabled || preview) {
            return setModal({
                header: label,
                size: 'xl',
                component: <ContentModalComponent name={label} filter={{ value, type }} preview={preview} count={count} />
            });
        }
        if (type === 'contentType' || type === 'ctype__keyword') {
            dispatch(saveFilters({
                sourcesWithDomains: value.map((
                    item
                ) => ({ key: item, label: getCtype(item) })),
                sources: value,
                contentType: value,
                roomId: room?.id
            }));
        } else {
            dispatch(saveFilters({
                [types[type]]: value,
                roomId: room?.id
            }));
        }
    };
    const filterApplied = () => {
        if (type === 'contentType' || type === 'ctype__keyword') {
            return { sources: value };
        }

        return { [types[type]]: value };
    };
    if (betaFiltersEnabled && !preview) {
        return (
            <Button color="link" className="p-0 text-left" onClick={handleClick}>
                <Link to={{ pathname: `/situation-rooms/${room.id}/overview/matches`,
                    state: {
                        appliedFilter: filterApplied()
                    } }}
                >
                    {children || label}
                </Link>
            </Button>

        );
    }

    return (
        <Button color="link"
            className="p-0 text-left"
            data-testid={`content-modal-button-${value}`}
            onClick={() => handleClick()}
        >
            {children || label}
        </Button>
    );
};
