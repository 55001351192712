import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { DownloadCSV } from '../DownloadCSV';
import { useDropDown } from '../../../../../utils/useDropdown';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { color } from '../../../../../utils/getColors';

const contentTypes = [
    { key: 'ALL', name: 'All' },
    { key: 'THREATS', name: 'Threats', type: CONTENT_TYPE.THREAT },
    { key: 'MATCHES', name: 'Matches', type: CONTENT_TYPE.MATCH }
];

export const ContentTypeDropdown = ({ sort, setSort, setOffset, total }) => {
    const [dropdown, toggleDropdown] = useDropDown();
    return (
        <div className="d-flex align-items-center ml-2 mt-1">
            <p className="set-max-content mr-1">Content type</p>
            <Dropdown isOpen={dropdown} toggle={toggleDropdown} className="mb-1 set-width">
                <DropdownToggle tag="div" className="cursor-pointer d-flex" data-testid="contentType-toggle">
                    <div className="form-control">
                        {sort.name}
                        {' '}
                        <span className="float-right">
                            <FeatherIcon.ChevronDown color={color.blue[500]} size={15} />
                        </span>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {contentTypes.map((item) => (
                        <DropdownItem key={item.key}
                            active={sort.key === item.key}
                            onClick={() => { setSort(item); setOffset(0); }}
                        >
                            {item.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {total !== 0 && <DownloadCSV />}
        </div>
    );
};
