import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Label } from 'reactstrap';
import { GET_USER, UPDATE_USER } from '../../../graphql';
import { capitalizeFirstLetter } from '../../../utils/text';

export const EditUserRole = ({ user }) => {
    const [role, changeRole] = useState(user?.role);
    const [updateUser, { loading }] = useMutation(UPDATE_USER, {
        refetchQueries: [GET_USER]
    });
    const handleSave = () => {
        updateUser({
            variables: { data: {
                uid: user.uid,
                email: user.email,
                role
            } }
        });
    };
    const roles = ['ADMIN', 'TEAM', 'SYNDICATE'];
    return (
        <div>
            <div className="d-flex flex-wrap">
                {
                    roles.map((userRole, index) => (
                        <Label key={userRole} className={index !== 0 ? 'ml-2' : ''}>
                            <input type="radio"
                                className="mb-n11"
                                id={capitalizeFirstLetter(role)}
                                checked={role === userRole}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        changeRole(userRole);
                                    }
                                }}
                            />
                            {userRole === 'SYNDICATE' ? 'Syndicated' : capitalizeFirstLetter(userRole)}
                        </Label>
                    ))
                }

            </div>
            <Button className="mt-2"
                color={user.role === role ? 'disabled' : 'secondary'}
                disabled={user?.role === role || loading}
                onClick={handleSave}
            >
                Save
            </Button>
        </div>
    );
};
