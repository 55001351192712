import { accountAtSymbolValidator } from './accountAtSymbolValidator';
import { accountTagValidator } from './accountTagValidator';
import { FROM_TAG_SOURCES, MENTIONS_TAG_SOURCES, CASE_SENSITIVE_TAGS, tagsWithEnforcedEmptySpaces } from '../../BooleanValidationErrors';
import { allowedTagsValidator } from './allowedTagsValidator';
import { tagsCapitalisationValidator } from './tagsCapitalisationValidator';
import { enforceEmptySpaceInValueValidator } from './enforceEmptySpaceInValueValidator';
import { illegalEmptySpaceInValueValidator } from './illegalEmptySpaceInValueValidator';
import { domainTagValidator } from './domainTagValidator';

export const sourceTags = ['from', 'mentions', 'engagingWith'];

export const tagValidators = (query, queryByLines, lineLengths) => {
    const validationResults = {
        isTagValid: true,
        tagValidMarkers: [],
        isTagCapitalisationValid: true,
        tagCapitalisationMarkers: [],
        isAccountAtSymbolValid: true,
        accountAtSymbolMarkers: [],
        isFromTagValid: true,
        fromTagMarkers: [],
        isMentionsTagValid: true,
        mentionsTagMarkers: [],
        isEmptySpaceValid: true,
        emptySpaceMarkers: [],
        isIllegalEmptySpaceValid: true,
        illegalEmptySpaceMarkers: [],
        isDomainTagValid: true,
        domainMarkers: []
    };

    if (!query || query.length === 0) {
        return validationResults;
    }

    // Reduce the noise and split by a separator (empty space)
    const tags = query?.replaceAll(/[()"']/g, '')?.replaceAll(/\s*:\s*/g, ':').split(' ')
        // Remove non-tag modules
        .filter(module => module.indexOf(':') !== -1);

    if (!tags || tags.length === 0) {
        return validationResults;
    }

    // Remove identical tags to prevent duplicated highlights for tags that are different only by empty spaces
    const uniqueTags = [];
    for (let i = 0; i < tags.length; i++) {
        if (uniqueTags.indexOf(tags[i]) === -1) {
            uniqueTags.push(tags[i]);
        }
    }

    uniqueTags.forEach((tag) => {
        const tagFragments = tag.split(':');
        const tagKey = tagFragments[0];

        if (CASE_SENSITIVE_TAGS.findIndex(caseTag => caseTag.toLowerCase() === tagKey.toLowerCase()) === -1) {
            const { markers: tagValidMarkers } = allowedTagsValidator(query, tag, queryByLines, lineLengths);
            validationResults.tagValidMarkers = validationResults.tagValidMarkers.concat(tagValidMarkers);
        } else {
            const {
                markers: tagCapitalisationMarkers
            } = tagsCapitalisationValidator(query, queryByLines, lineLengths, tag, tagKey, CASE_SENSITIVE_TAGS);
            validationResults.tagCapitalisationMarkers = validationResults.tagCapitalisationMarkers.concat(tagCapitalisationMarkers);
        }

        if (sourceTags.includes(tagKey)) {
            const { markers: accountAtSymbolMarkers } = accountAtSymbolValidator(query, tag, queryByLines, lineLengths);
            validationResults.accountAtSymbolMarkers = validationResults.accountAtSymbolMarkers.concat(accountAtSymbolMarkers);

            if (tagKey === 'from') {
                const { markers: fromTagMarkers } = accountTagValidator(query, queryByLines, lineLengths, tag, FROM_TAG_SOURCES);
                validationResults.fromTagMarkers = validationResults.fromTagMarkers.concat(fromTagMarkers);
            } else if (tagKey === 'mentions') {
                const { markers: mentionsTagMarkers } = accountTagValidator(query, queryByLines, lineLengths, tag, MENTIONS_TAG_SOURCES);
                validationResults.mentionsTagMarkers = validationResults.mentionsTagMarkers.concat(mentionsTagMarkers);
            }
        }

        if (tagsWithEnforcedEmptySpaces.includes(tagKey)) {
            const { markers: emptySpaceMarkers } = enforceEmptySpaceInValueValidator(query, queryByLines, lineLengths, tag);
            validationResults.emptySpaceMarkers = validationResults.emptySpaceMarkers.concat(emptySpaceMarkers);
        } else {
            const { markers: illegalEmptySpaceMarkers } = illegalEmptySpaceInValueValidator(query, queryByLines, lineLengths, tag);
            validationResults.illegalEmptySpaceMarkers = validationResults.illegalEmptySpaceMarkers.concat(illegalEmptySpaceMarkers);
        }

        if (tagKey === 'domain') {
            const { markers: domainMarkers } = domainTagValidator(query, queryByLines, lineLengths, tag);
            validationResults.domainMarkers = validationResults.domainMarkers.concat(domainMarkers);
        }
    });

    validationResults.isTagValid = validationResults.tagValidMarkers.length === 0;
    validationResults.isTagCapitalisationValid = validationResults.tagCapitalisationMarkers.length === 0;
    validationResults.isAccountAtSymbolValid = validationResults.accountAtSymbolMarkers.length === 0;
    validationResults.isFromTagValid = validationResults.fromTagMarkers.length === 0;
    validationResults.isMentionsTagValid = validationResults.mentionsTagMarkers.length === 0;
    validationResults.isEmptySpaceValid = validationResults.emptySpaceMarkers.length === 0;
    validationResults.isIllegalEmptySpaceValid = validationResults.illegalEmptySpaceMarkers.length === 0;
    validationResults.isDomainTagValid = validationResults.domainMarkers.length === 0;

    return validationResults;
};
