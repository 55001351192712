import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { GET_SITUATION_ROOM_DRAFTS, UPDATE_SITUATION_ROOM, UPDATE_SITUATION_ROOM_DRAFT } from '../../../graphql';
import { Loading } from '../../../components/Loading';
import { useUser } from '../../../context/user/UserContext';
import { getToast } from '../../../utils/getToast';

type DataType = {
    id: string;
    title: string;
    draftId: string;
    situationRoomId: string;
};

type DeleteArchiveModalProps = {
    data: DataType;
    closeModal?: () => void;
    type: 'delete' | 'archive';
    onDone?: () => void;
    draft?: boolean | undefined;
}

type OptionsType = {
    delete: {
        variables: {
            id: string;
            deleted: boolean;
            action: string;
            is_active: boolean;
        };
        text: {
            completed: string;
            do: string;
            description: string;
            button: string;
        };
    };
    archive: {
        variables: {
            id: string;
            archived: boolean;
            action: string;
            is_active: boolean;
            archivedDetails: {
                archivedBy: string | undefined;
                archivedAt: Date;
                autoArchived: boolean;
            };
        };
        text: {
            completed: string;
            do: string;
            description: string;
            button: string;
        };
    };
};

export const DeleteArchiveModal = ({ data, closeModal, type, onDone, draft = false }: DeleteArchiveModalProps) => {
    const [loading, setLoading] = useState(false);
    const { user } = useUser();
    const toast = getToast();
    const options: OptionsType = {
        delete: {
            variables: {
                id: data.id,
                deleted: true,
                action: 'delete',
                is_active: false
            },
            text: {
                completed: 'deleted',
                do: 'Delete',
                description: 'All of the data will be permanently lost.',
                button: 'danger'
            }
        },
        archive: {
            variables: {
                id: data.id,
                archived: true,
                action: 'archive',
                is_active: false,
                archivedDetails: {
                    archivedBy: user.id,
                    archivedAt: new Date(),
                    autoArchived: false
                }
            },
            text: {
                completed: 'archived',
                do: 'Archive',
                description: `All of the data will become unavailable to the whole team. 
                You will be able to activate the room in the homepage.`,
                button: 'danger'
            }
        }
    };
    const vars = options[type];
    const [updateSituationRoom, { loading: roomLoading }] = useMutation(UPDATE_SITUATION_ROOM, {
        refetchQueries: [{ query: GET_SITUATION_ROOM_DRAFTS, variables: { instance: user?.instance?.id } }],
        onCompleted: () => {
            setLoading(false);
            toast.success(`You've ${vars.text.completed} selected situation room`);
            onDone?.();
            closeModal?.();
            if (type === 'delete' && data.draftId) {
                updateSituationRoomDraft({
                    variables: {
                        ...vars.variables,
                        id: data.draftId,
                        situationRoomId: data.situationRoomId
                    }
                });
            }
        },
        update: (cache) => cache.evict({ fieldName: 'situationRooms' })
    });
    const [updateSituationRoomDraft, { loading: draftLoading }] = useMutation(UPDATE_SITUATION_ROOM_DRAFT, {
        refetchQueries: [{ query: GET_SITUATION_ROOM_DRAFTS, variables: { instance: user?.instance?.id } }],
        onCompleted: () => {
            setLoading(false);
            toast.success(`Draft ${vars.text.completed}`);
            onDone?.();
            closeModal?.();
        },
        onError: () => setLoading(false),
        update: (cache) => cache.evict({ fieldName: 'situationRoomDrafts' })
    });
    const deleteRoom = () => {
        setLoading(true);
        if (draft) {
            updateSituationRoomDraft({
                variables: {
                    ...vars.variables,
                    situationRoomId: data.situationRoomId
                }
            });
        } else {
            updateSituationRoom({
                variables: {
                    ...vars.variables,
                    draftId: data.draftId
                }
            });
        }
    };
    if (loading || roomLoading || draftLoading) {
        return <Loading height={200} relative />;
    }
    return (
        <div>
            <p>{vars.text.description}</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={() => closeModal?.()}>Cancel</Button>
            <Button color={vars.text.button} onClick={() => deleteRoom()}>{vars.text.do}</Button>
        </div>
    );
};
