import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { RemoveUserModal } from '../../UserDetail/RemoveUserModal';
import { ProfileImage } from '../../../../../components/Atoms';

export const TeamMembersTableBody = ({ usersData }) => {
    const { setModal, closeModal } = useModal();
    const handleRemoveUser = (user) => {
        setModal({
            component: <RemoveUserModal userData={user} closeRemoveModal={closeModal} />
        });
    };
    return (
        <tbody>
            {usersData.map((user) => (
                <tr key={user.id}>
                    <td>
                        <ProfileImage photoURL={user.photoURL} />
                    </td>
                    <td><Link to={`/admin/allusers/${user.uid}`}>{user.displayName || 'Not available'}</Link></td>
                    <td>{user.email }</td>
                    <td>{user.instance?.clientName || 'NA' }</td>
                    <td>{user.role || 'Not available'}</td>
                    <td>

                        <div className={`d-flex align-items-center status-${user.status}`}>
                            <span className={user.status ? 'pl-2' : ''}>{user.status || 'Not available'}</span>
                        </div>

                    </td>
                    <td>{user.lastSeen ? moment(new Date(user.lastSeen)).from(moment(new Date())) : 'Not available'}</td>
                    <td className="text-danger cursor-pointer" onClick={() => handleRemoveUser(user)}>Remove</td>
                </tr>
            ))}
        </tbody>
    ); };
