import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown
} from 'reactstrap';

type DateButtonsProps = {
    setDateRange: (dateRange: null) => void
    setSelectedDateRangeLabel: (label: string) => void
    toggleRange: (start: number, end: number, label: string) => void
    selectedDateRangeLabel: string
}

export const DateButtons = ({ setDateRange, setSelectedDateRangeLabel, toggleRange, selectedDateRangeLabel }: DateButtonsProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (item?: { label: string, seconds: number }) => {
        if (!item) return;
        if (item.seconds === 0) {
            setDateRange(null);
            setSelectedDateRangeLabel(item.label);
        } else {
            toggleRange(
                new Date().getTime() - (item.seconds * 1000),
                new Date().getTime(),
                item.label
            );
        }
    };
    return (
        <>
            {buttonsConfig.map((item) => (
                <Button className="mw-0 mr-1 p-1 d-none d-xxl-block"
                    key={item.label}
                    color={selectedDateRangeLabel === item.label ? 'primary' : 'secondary'}
                    onClick={() => {
                        handleClick(item);
                    }}
                >
                    {item.label}
                </Button>
            ))}
            <Dropdown className="mr-1 d-block d-xxl-none" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle className="p-1 w-100 d-flex justify-content-between">
                    <span>{selectedDateRangeLabel}</span>
                    <ChevronDown className={`ml-1 transform-transition ${isOpen ? 'transform-btn-icon' : ''}`} />
                </DropdownToggle>
                <DropdownMenu>
                    {buttonsConfig.map((item) => (
                        <DropdownItem key={item.label}
                            onClick={() => handleClick(item)}
                            active={selectedDateRangeLabel === item.label}
                        >
                            {item.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

const buttonsConfig = [
    {
        label: '24hr',
        seconds: 60 * 60 * 24
    },
    {
        label: '7D',
        seconds: 60 * 60 * 24 * 7
    },
    {
        label: '30D',
        seconds: 60 * 60 * 24 * 30
    },
    {
        label: '3M',
        seconds: 60 * 60 * 24 * 30 * 3
    },
    {
        label: '6M',
        seconds: 60 * 60 * 24 * 30 * 6
    },
    {
        label: 'All time',
        seconds: 0
    }
];
