import React, { useRef, useState } from 'react';
import { Chart, FontLoader } from 'regraph';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../../../../../components/Loading';
import { useMapData } from '../../../../../../../services/Report/getMapData';
import { formatMapData } from './formatMapData';
import 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';
import { updatePageAction } from '../../../../../../../redux/reports';
import { color } from '../../../../../../../utils/getColors';

export const Map = ({ page, pdf }) => {
    if (pdf) {
        return (
            <div>
                {page.blocks?.length ? <img src={page.blocks[0].url} alt="locations-map" /> : null}
            </div>
        );
    }
    return <MapInner page={page} />;
};

const MapInner = ({ page }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const chartRef = useRef(null);
    const { data, loading } = useMapData({
        dateFilter: report.filters
    });
    const [updated, setUpdated] = useState(1);

    const fetchData = async () => {
        if (chartRef.current) {
            const exportedImage = await chartRef.current.export({ type: 'png' });
            dispatch(updatePageAction({
                id: page.id,
                updates: {
                    blocks: [{ url: exportedImage?.url }]
                }
            }));
        }
    };

    if (loading) {
        return <Loading relative height={200} />;
    }
    const formatted = formatMapData(data);
    const handleChartClick = () => setUpdated(updated + 1);
    return (
        <FontLoader config={{ custom: { families: ['Font Awesome 5 Free', 'Font Awesome 5 Brands'] } }}>
            <Chart ref={chartRef}
                items={formatted}
                map
                animation={{ animate: true, time: 750 }}
                options={{
                    backgroundColor: color.darkblue[700],
                    iconFontFamily: 'Font Awesome 5 Free',
                    navigation: false,
                    overview: false,
                    map: {
                        tiles: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    }
                }}
                className="flex-1"
                onChange={() => {
                    handleChartClick();
                    fetchData();
                }}
            />
        </FontLoader>
    );
};
