import { gql, useMutation } from '@apollo/client';

export const useUpdateNarrativeVersion = () => {
    const [updateNarrativeVersion, { loading }] = useMutation(UPDATE_NARRATIVE_VERSION);
    return {
        loading,
        updateNarrativeVersion
    };
};

const UPDATE_NARRATIVE_VERSION = gql`
    mutation updateNarrativeVersion(
        $versionId: ID!,
        $narrativeTitle: String,
        $notes: String
    ) {
        updateNarrativeVersion(
            data: {
                narrativeTitle: $narrativeTitle
                versionId: $versionId
                notes: $notes
            }
        ) { versionId }
    }
`;
