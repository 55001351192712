import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { InvestigationDetails } from './Investigations/InvestigationDetails';
import { SituationRooms } from './SituationRooms';
import { Investigations } from './Investigations';
import { Room } from './SituationRooms/Room';
import { adminSidebarRoutes, routes } from '../../routes';
import { InstanceSummary } from './Instances/InstanceSummary';
import { AdminOverview } from './Overview';
import { Instances } from './Instances';
import { Users } from './Users';
import { UserDetail } from './Users/UserDetail';
import { SourceMapping } from './SourceMapping';
import { SidebarMenu } from '../../components/Molecules/SidebarMenu';
import { SuperAdminUsers } from './SuperAdminUsers/index';
import { EditSource } from './SourceMapping/EditSource';

export const LoggedInAdmin = () => (
    <Switch>
        <Route exact path={routes.admin.base}>
            <AdminOverview />
        </Route>
        <SidebarMenu collapse={false} menu={[{ name: '', links: adminSidebarRoutes }]}>
            <Route exact path={routes.admin.instances}>
                <Instances />
            </Route>
            <Route path={routes.admin.instance}>
                <InstanceSummary />
            </Route>
            <Route path={routes.admin.users}>
                <SuperAdminUsers />
            </Route>
            <Route path={routes.admin.allUsers} exact>
                <Users />
            </Route>
            <Route path={routes.admin.allUsersUser}>
                <UserDetail />
            </Route>
            <Route path={routes.admin.investigations}>
                <Investigations />
            </Route>
            <Route path={routes.admin.investigation}>
                <InvestigationDetails />
            </Route>
            <Route path={routes.admin.situationRooms} exact>
                <SituationRooms />
            </Route>
            <Route path={routes.admin.situationRoom}>
                <Room />
            </Route>
            <Route path={routes.admin.cTypes} exact>
                <SourceMapping />
            </Route>
            <Route path={routes.admin.cType}>
                <EditSource />
            </Route>
        </SidebarMenu>
    </Switch>
);
