import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { getRoom } from '../../utils/variables';
import { useModal } from '../../context/modal/ModalComponent';
import { RemoveContentActions, useRemoveContent } from './useRemoveContent';
import { getToast } from '../../utils/getToast';

type RemoveContentModalProps = {
    onCompleted?: () => void
    onError?: () => void
    type: string
    removeData: { id: string }[]
}

export const RemoveContentModal = ({ removeData, onCompleted, onError, type }: RemoveContentModalProps) => {
    const room = getRoom();
    const toast = getToast();
    const { closeModal } = useModal();
    const { removeContent, loading, error } = useRemoveContent({
        type,
        action: RemoveContentActions.RemoveContent,
        onCompleted: () => {
            toast.success("You've removed selected content(s).");
            if (onCompleted) onCompleted();
            closeModal();
        },
        ids: removeData.map(item => item.id)
    });

    if (error && onError) {
        onError();
    }
    const handleRemoveContent = () => removeContent(removeData.map(item => ({ id: item.id })));

    return (
        <Modal isOpen toggle={closeModal}>
            <ModalBody>
                <h2>Remove this content?</h2>
                <hr />
                <p>
                    {`This content will be removed from "${room?.project_name}". 
                        You won't be able to see this content within this room.
                        This content can be restored under room settings, removed content.`}
                </p>
                <hr />
                <Button onClick={closeModal}>Cancel</Button>
                <Button color="danger" className="ml-2" onClick={handleRemoveContent} disabled={loading}>
                    Remove
                </Button>
            </ModalBody>
        </Modal>
    );
};
