import React from 'react';

export const Range = ({ min, max, value, onChange, step, className = '' }: any) => (
    <input type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        step={step}
        className={className}
    />
);
