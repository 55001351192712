import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
    Alert,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Spinner
} from 'reactstrap';
import { AlertCircle, AlertTriangle, Check } from 'react-feather';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FiltersDataProps, SAVE_ROOM_FILTERS } from '.';
import { useUser } from '../../../../context/user/UserContext';
import { RoomFilterProps } from '../../FiltersLayout';
import { removeTypename } from '../../../../utils/removeAllTypename';
import { getToast } from '../../../../utils/getToast';
import { color } from '../../../../utils/getColors';
import { RootState } from '../../../../store';
import { useGetActiveQueries } from '../../../../services/getActiveQueries';
import { useModal } from '../../../../context/modal/ModalComponent';

type SaveFilterModalProps = {
    filtersData: FiltersDataProps[],
    roomId: string,
    filterNamesList?: string[],
    roomFilters: RoomFilterProps[],
    clusters?: boolean
}

export const SaveFilterModal = ({ filtersData, roomId, filterNamesList, roomFilters,
    clusters = false }: SaveFilterModalProps) => {
    const [filterName, setFilterName] = useState('');
    const { closeModal } = useModal();
    const { dateRange: selectedDateRange } = useSelector((state: RootState) => state.selectedFilters.filters);
    const [error, setError] = useState(false);
    const [duplicateAlert, setDuplicateAlert] = useState(false);
    const [emptyName, setEmptyName] = useState(false);
    const { user } = useUser();
    const toast = getToast();

    const refetchQueries = useGetActiveQueries(['getRoomFilters', 'getRoomFiltersInClusters']);

    const [saveFilters, { loading }] = useMutation(SAVE_ROOM_FILTERS, {
        refetchQueries,
        onCompleted: () => {
            toast.success('Filter saved');
            closeModal();
        },
        onError: () => {
            toast.error('Filter not saved');
        }
    });
    const handleSaveFilter = () => {
        let isSame = false;
        const updatedFilterTypes = roomFilters.map((a) =>
            ({ data: removeTypename(a.data.map((b) => removeTypename(b))), dateRange: removeTypename(a.dateRange) }));
        updatedFilterTypes.forEach(a => { if (_.isEqual(a.data, filtersData) && _.isEqual(a.dateRange, selectedDateRange)) {
            isSame = true;
        } });
        if (!error && filterName && (isSame === duplicateAlert)) {
            saveFilters({ variables: {
                data: {
                    name: filterName?.trim(),
                    situationRoom: roomId,
                    data: filtersData,
                    user: user.id,
                    dateRange: selectedDateRange
                },
                text: clusters ? 'clusters' : 'overview'
            } });
        } else if (isSame && !duplicateAlert && !error && filterName) {
            setDuplicateAlert(true);
        } else {
            if (filterName === '') { setEmptyName(true); }
            toast.error('Resolve all errors before continuing');
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const finalName = e.target.value;
        setEmptyName(finalName?.trim() === '');
        if (e.target.value.length <= 70) {
            setError(filterNamesList?.includes(finalName) || false);
            setFilterName(finalName);
        }
    };

    return (
        <div>
            {duplicateAlert && (
                <Alert color="warning" className="mb-0 d-flex w-100 align-items-center">
                    <AlertCircle size={40} color={color.yellow[300]} className="mr-2 " />
                    A saved filter with the same options already exists. However,
                    you can still save this filter under a different name.
                </Alert>
            )}
            <p className="mt-3">Enter filter name</p>
            <InputGroup border="none"
                state={(error || (!filterName?.trim() && filterName !== '')) ? 'error' : ''}
                className="mt-1"
            >
                <Input placeholder="Filter name"
                    autoFocus
                    value={filterName}
                    onChange={(e) => handleOnChange(e)}
                    data-testid="filter-name"

                />
                <span className={(error || ((filterName?.trim() && !error))) ? 'text-disabled mt-1 ml-1'
                    : 'text-disabled mt-1 mr-2 ml-1'}
                >
                    ({filterName?.length}/70)
                </span>
                {(error && !filterName?.trim()) && (
                    <InputGroupAddon addonType="append">
                        <InputGroupText className="border-0 p-1 bg-white">
                            <AlertTriangle color={color.red[200]} size={20} />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
                {(filterName?.trim() && !error) && (
                    <InputGroupAddon addonType="append">
                        <InputGroupText className="border-0 p-1 bg-white">
                            <Check color={color.green[200]} size={16} strokeWidth={3} />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
            </InputGroup>
            {error && (
                <p className="text-danger mt-1">
                    This filter name already exists. Choose a different name.
                </p>
            )}
            {((!filterName?.trim() && filterName !== '') || emptyName) && <p className="text-danger">Enter a valid name for the filter</p>}
            <hr className="mt-3 mb-3" />
            <div className="d-flex">
                <Button className="mr-2" onClick={closeModal} disabled={loading}>Cancel</Button>
                <Button color="primary"
                    onClick={() => handleSaveFilter()}
                    disabled={loading}
                    data-testid="saveFilter-button"
                >{!loading ? 'Save filter' : <Spinner className="ml-1" size="sm" color="white" />}
                </Button>
            </div>
        </div>
    );
};
