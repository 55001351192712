import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NetworksTopBar } from './NetworksTopBar';
import { NetworksChart } from './NetworksChart';
import { getRoom } from '../../../utils/variables';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { FeatureTour } from '../../../components/FeatureTour';
import { networksTourSteps } from './featureTour';
import { saveFilters } from '../store';

export const NetworksPage = () => {
    const [totalCount, setTotalCount] = useState(0);
    const room = getRoom();
    const dispatch = useDispatch();
    const parseFilters = sessionStorage.getItem('initialFilters');
    const initialFilters = parseFilters ? JSON.parse(parseFilters) : null;

    if (initialFilters) {
        dispatch(saveFilters({ ...initialFilters, roomId: room?.id }));
        sessionStorage.removeItem('initialFilters');
    }
    return (
        <FeatureTour tourSteps={networksTourSteps}>
            <div className="pt-4 px-4 pb-2 border-bottom">
                <BreadCrumbs items={[
                    { title: 'Situation Rooms', href: '/situation-rooms' },
                    { title: room.project_name, href: `/situation-rooms/${room.id}` },
                    { title: 'Networks' }
                ]}
                />
                <NetworksTopBar totalCount={totalCount} />
            </div>
            <div className="d-flex flex-column mh-0 networks-chart">
                <NetworksChart setTotalCount={setTotalCount} />
            </div>
        </FeatureTour>
    );
};
