import React from 'react';

export const LoadingState = () => (
    <div className="loading-container">
        <div className="w-100">
            <div className="loading-bg mb-2"><p>Loading</p></div>
        </div>
        <div className="d-flex">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((a) => (
                <div key={a} className="loading-bg px-2 py-1 mr-2">
                    <p>Loading</p>
                </div>
            ))}
        </div>
    </div>
);
