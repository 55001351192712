import React from 'react';
import { BatchRecoverContentButton } from './BatchRecoverContentButton';
import { RecoverContentButton } from './RecoverContentButton';

export const RecoverContentButtons = ({
    selectedContentIds,
    onSuccess,
    onAction,
    onAbort,
    total,
    searchFilters,
    offset,
    sort,
    allSelected
}) => {
    if (!allSelected) {
        return <RecoverContentButton contentIds={selectedContentIds} onSuccess={onSuccess} />;
    }

    return (
        <BatchRecoverContentButton onSuccess={onSuccess}
            onAction={onAction}
            onAbort={onAbort}
            total={total}
            searchFilters={searchFilters}
            offset={offset}
            sort={sort}
        />
    );
};
