import React from 'react';
import { MinusSquare, PlusSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { getToast } from '../../../../../utils/getToast';
import { RootState } from '../../../../../store';
import { setNarrativesThemeComparisonList } from '../../../store';
import { CompareButtonType, getSelectedForComparison, SelectedNarrativesType } from './index';

type CompareButtonsType = {
    clusterIds: string[]
    clusterTitles: string[]
    type: CompareButtonType
    onAddedToComparison?: () => void
    container?: string
    id?: string,
    isCompareModal?: boolean
}

export const CompareNarrativeThemesButtons = ({
    clusterIds,
    clusterTitles,
    type,
    onAddedToComparison,
    container = 'selected-cluster-view',
    id = 'comparisonButton',
    isCompareModal = false
}: CompareButtonsType) => {
    const toast = getToast();
    const dispatch = useDispatch();
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const alreadyInComparison = clusterIds.some((singleId: string) => selectedNarrativeThemes.some((x: any) => x.id === singleId));

    const addToComparisonList = () => {
        if (selectedNarrativeThemes.length >= 4) {
            return toast.info('A maximum of 4 narrative themes can be compared. Narratives cannot be compared with narrative themes.');
        }

        const selected = getSelectedForComparison({
            ids: clusterIds,
            titles: clusterTitles,
            currentlySelected: selectedNarrativeThemes
        });

        dispatch(setNarrativesThemeComparisonList({
            selectedNarrativeThemes: Object.values(selected)
        }));
        if (onAddedToComparison) onAddedToComparison();
        toast.success(`Narrative theme${clusterIds.length > 1 ? 's' : ''} added to comparison`);
    };

    const removeFromComparison = () => {
        if (selectedNarrativeThemes.length < 3 && isCompareModal) {
            return toast.error('At least 2 Narrative themes are required for comparing, can\'t remove Narrative theme.');
        }

        const updatedThemes = selectedNarrativeThemes.filter((theme: SelectedNarrativesType) => !clusterIds.includes(theme.id));
        dispatch(setNarrativesThemeComparisonList({ selectedNarrativeThemes: updatedThemes }));
        toast.success(`Narrative${clusterIds.length > 1 ? 's' : ''} theme removed from comparison`);
    };

    if (type === CompareButtonType.AddButton) {
        return <Button onClick={addToComparisonList}>Add to narrative theme comparison</Button>;
    }

    return (
        <TooltipWrapper tooltipText={`${alreadyInComparison ? 'Remove from comparison' : 'Add to comparison'}`}
            id={id}
            placement="top-start"
            container={container}
        >
            {({ closeTooltip }) => (
                alreadyInComparison ? (
                    <MinusSquare size={24}
                        color="#006FF9"
                        className="mr-2 cursor-pointer"
                        aria-label="Remove from comparison"
                        data-testid="comparison-minus-icon"
                        onClick={() => { closeTooltip(); removeFromComparison(); }}
                    />
                ) : (
                    <PlusSquare size={24}
                        color="#006FF9"
                        className="mr-2 cursor-pointer"
                        aria-label="Add to comparison"
                        data-testid="comparison-plus-icon"
                        onClick={() => { closeTooltip(); addToComparisonList(); }}
                    />
                ))}
        </TooltipWrapper>
    );
};
