import { NetworkNode } from '../../useNetworks';
import { createLink } from './createLink';

export const useAuthorLinks = (data: NetworkNode[]) => Object.fromEntries(
    data.map(({ id, from, mentions }) => {
        const contentNodeId = `content_${id}`;
        return [
            [
                `author_${from}_${contentNodeId}`,
                createLink({
                    id1: `author_${from}`,
                    id2: contentNodeId,
                    label: 'posted'
                })
            ],
            ...mentions.map(mention => ([
                `${contentNodeId}_author_${mention}`,
                createLink({
                    id1: contentNodeId,
                    id2: `author_${mention}`,
                    label: 'mentions'
                })
            ]))
        ];
    }).flat()
);
