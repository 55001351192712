import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { AlertTriangle, Check } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RoomBooleanProps, ValidationErrors } from '.';
import { color } from '../../../utils/getColors';
import { BooleanSearchContent } from '../BooleanSearch/BooleanSearchContent';
import { saveSelectedFilters } from '../../../pages/Room/store';

type EditSavedFilterProps = {
    filterData: RoomBooleanProps,
    booleanName: string,
    setFilterName: (e: string) => void,
    filterNamesList: string[],
    validationErrors: ValidationErrors,
    setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
    setInvalidQuery: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditSavedBoolean = ({
    filterData,
    booleanName,
    setFilterName,
    filterNamesList,
    validationErrors,
    setValidationErrors,
    setInvalidQuery
}: EditSavedFilterProps) => {
    const { booleanQuery } = useSelector((state: RootState) => state.selectedFilters.filters);
    const dispatch = useDispatch();
    const [error, setError] = useState(false);

    useEffect(() => {
        setFilterName(filterData.name);
        dispatch(saveSelectedFilters({ booleanQuery: filterData.query }));
    }, [dispatch, filterData.name, filterData.query, setFilterName]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const finalName = e.target.value;
        const defaultName = filterData.name;
        if (e.target.value.length <= 70) {
            if (filterNamesList?.includes(finalName) && (finalName !== defaultName)) {
                setError(true);
            } else {
                setError(false);
            }
            setFilterName(e.target.value);
        }
    };

    return (
        <div className="d-flex flex-column flex-1 mx-4 mt-3" data-testid="edit-saved-boolean">

            <div className="d-flex flex-column w-25">
                <p>Boolean search name</p>
                <InputGroup border="none"
                    state={(error || !booleanName.trim()) ? 'error' : ''}
                    className="mt-1"
                >
                    <Input placeholder="Boolean name"
                        autoFocus
                        value={booleanName}
                        onChange={(e) => handleOnChange(e)}
                        data-testid="filter-name"

                    />
                    <span className={(error || ((booleanName?.trim() && !error))) ? 'text-disabled mt-1 ml-1'
                        : 'text-disabled mt-1 mr-2 ml-1'}
                    >
                        ({booleanName?.length}/70)
                    </span>
                    {(error || !booleanName.trim()) && (
                        <InputGroupAddon addonType="append">
                            <InputGroupText className="border-0 p-1 bg-white">
                                <AlertTriangle color={color.red[200]} size={20} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                    {(booleanName?.trim() && !error) && (
                        <InputGroupAddon addonType="append">
                            <InputGroupText className="border-0 p-1 bg-white">
                                <Check color={color.green[200]} size={16} strokeWidth={3} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                </InputGroup>
                {(!booleanName.trim()) && (
                    <p className="text-danger mt-1">
                        Your boolean must have a name.
                    </p>
                )}

                {(error && booleanName.trim()) && (
                    <p className="text-danger mt-1">
                        This boolean name already exists. Choose a different name.
                    </p>
                )}
            </div>
            <hr />
            <h3>Edit Boolean query</h3>
            <BooleanSearchContent
                booleanQuery={booleanQuery}
                setBooleanQuery={(query: string) => {
                    dispatch(saveSelectedFilters({
                        booleanQuery: query
                    }));
                }}
                setInvalidQuery={setInvalidQuery}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                disabled={false}
                emptyQuery={!booleanQuery}
                edit
            />
            {!booleanQuery && (
                <p className="text-danger mt-n2 mb-3">
                    Boolean query cannot be empty
                </p>
            )}

        </div>
    );
};
