import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { ChevronDown, User, LogOut } from 'react-feather';
import { Link } from 'react-router-dom';
import { client } from '../../auth0';
import { useUser } from '../../context/user/UserContext';
import { useDropDown } from '../../utils/useDropdown';

export const UserDropdown = () => {
    const { user } = useUser();
    const [dropdown, toggleDropdown, setDropdown] = useDropDown();

    if (!user) return null;

    return (
        <Dropdown className="align-self-center notification-list"
            tag="div"
            isOpen={dropdown}
            toggle={toggleDropdown}
            data-testid="user-dropdown"
        >
            <DropdownToggle tag="div" className="nav-link nav-user mr-0 mr-0" data-testid="user-dropdown-toggle">
                <div className="media user-profile ">
                    {user.photoURL && (
                        <img src={user.photoURL}
                            alt="logically-user"
                            className="rounded-circle align-self-center"
                            data-testid="user-image"
                        />
                    )}
                    <div className="media-body text-left">
                        <h6 className="pro-user-name ml-2 my-0">
                            <span className="text-light">
                                {user.displayName}
                            </span>
                            <span className="pro-user-desc d-block mt-1 text-white font-weight-light">{user.role}{' '}</span>
                        </h6>
                    </div>
                    <ChevronDown color="white" className="ml-2 align-self-center" />
                </div>
            </DropdownToggle>
            <DropdownMenu className="topbar-dropdown-menu profile-dropdown-items" onClick={() => setDropdown(false)}>
                <Link to="/profile" className="dropdown-item notify-item">
                    <User className="icon-dual icon-xs mr-2" />
                    <span>My Profile</span>
                </Link>
                <DropdownItem divider />
                <div className="dropdown-item notify-item"
                    onClick={() => client.logout({ returnTo: window.location.origin })}
                    data-testid="logout-button"
                >
                    <LogOut className="icon-dual icon-xs mr-2" />
                    <span>Logout</span>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};
