import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getToast } from '../../../../../utils/getToast';

export const ResetGraph = ({ resetGraph }: { resetGraph: () => void }) => {
    const { closeModal } = useModal();
    const toast = getToast();
    const handleResetGraph = () => {
        closeModal();
        resetGraph();
        toast.info('Network graph have been successfully reset to default');
    };
    return (
        <div>
            <p>Are you sure you want to reset this graph? This will undo all changes made.</p>
            <hr />
            <Button onClick={closeModal} className="mr-2">Cancel</Button>
            <Button onClick={handleResetGraph} color="primary">Reset</Button>
        </div>
    );
};
