import React, { useState } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { allTypes } from '../../../../../utils/lib/search';
import { SelectAllCheckBox } from '../../../components/DropdownWIthMultiCheckbox/SelectAllCheckbox';

type ItemProps = {
    id: string,
    name: string,
    selected: boolean
}

type MultiCheckboxFilterProps = {
    title: string,
    filters: ItemProps[],
    values: string[],
    setValues: (e: string[]) => void,
    value: string,
    disabledOptions?: string[],
    disabled?: boolean
}

export const MultiCheckboxFilter = ({ title, filters, values, setValues, value, disabledOptions = [],
    disabled = false }: MultiCheckboxFilterProps) => {
    const [newid] = useState(uuid());
    const resolverIds = allTypes.filter((a) => a.isResolver).map((b) => b.key);
    return (
        <SelectAllCheckBox id={`checkbox-${title}-${newid}`}
            options={filters?.map(({ id }) => id)}
            values={values}
            selectAllReversed
            allCheck={(ids) => setValues(ids)}
            disabledOptions={disabledOptions}
            disabled={disabled}
        >
            <FormGroup key="toxic-information">
                <CustomInput type="checkbox"
                    checked={_.difference(resolverIds, values).length > 0}
                    className="custom-control-input-big grid-item"
                    label={<span>Toxic information</span>}
                    id={`filter-checkbox-toxic-information-${newid}`}
                    data-testid="checkbox-toxic-information"
                    value={value}
                    onChange={({ target: { checked } }) => {
                        if (!checked) {
                            setValues([...values, ...resolverIds]);
                        } else {
                            setValues(values.filter(a => !resolverIds.includes(a)));
                        }
                    }}
                    disabled={disabled}
                />
            </FormGroup>
            {filters?.map((item, index) => (
                <FormGroup key={item.id}
                    className={`${index === filters.length - 1 ? 'mb-0' : ''}
                 ${resolverIds.includes(item.id) ? 'ml-1' : ''}`}
                >
                    <CustomInput type="checkbox"
                        checked={values.indexOf(item.id) === -1}
                        className="custom-control-input-big grid-item"
                        label={<span>{item.name}</span>}
                        id={`filter-checkbox-${item.id}-${newid}`}
                        data-testid={`checkbox-${item.id}`}
                        value={value}
                        onChange={({ target: { checked } }) => {
                            if (!checked) {
                                setValues([...values, item.id]);
                            } else {
                                setValues(values.filter(a => a !== item.id));
                            }
                        }}
                        disabled={disabled}
                    />
                </FormGroup>
            ))}
        </SelectAllCheckBox>
    );
};
