import React from 'react';
import { useDispatch } from 'react-redux';
import { X } from 'react-feather';
import { truncateString } from '../../../../utils/text';
import { saveFilters, saveSelectedFilters } from '../../../../pages/Room/store';
import { color } from '../../../../utils/getColors';

type SelectedBooleanSearchProps = {
    booleanValue: string
}

export const SelectedBooleanSearch = ({ booleanValue }: SelectedBooleanSearchProps) => {
    const dispatch = useDispatch();
    const clearBoolean = () => {
        dispatch(saveFilters({ booleanQuery: '', convertedBooleanQuery: '', booleanQueryKeywords: [] }));
        dispatch(saveSelectedFilters({ booleanQuery: '', convertedBooleanQuery: '', booleanQueryKeywords: [] }));
    };

    return (
        <div key="booleanQuery" className="d-flex border rounded mb-1 pl-2 justify-content-between pr-2 align-items-center mr-2">
            <span>Boolean query</span>
            <div key="booleanQuery" className="tag d-flex align-items-center ml-1 mb-1 mt-1">
                <span>{truncateString(booleanValue, 100)}</span>
                <div className="d-flex align-items-center pl-1 cursor-pointer"
                    data-testid="x-icon-boolean-search"
                    onClick={() => clearBoolean()}
                >
                    <X color={color.blue[500]} />
                </div>
            </div>
        </div>
    ); };
