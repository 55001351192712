import React from 'react';
import { WATCHLIST_BUTTON_TYPES } from '../../../pages/Room/Threats/Threat/WatchlistButton';
import { WatchlistButtonContainer } from '../../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { useGetWatchlist } from '../../../services/Threat/getWatchlist';
import { getRoom } from '../../../utils/variables';
import { StickToBottom } from '../../StickToBottom';
import { MarkAsNotThreatButton } from './MarkAsNotThreatButton';
import { RemoveContentButtons } from './RemoveContentButtons';
import { ContentTableContentType } from './index';
import { ManageThreatsButton } from './ThreatSignals/ManageThreatsButton';

type ContentTableActionsBarProps = {
    selectedContentItems: ContentTableContentType[]
    clearSelection: () => void
    total: number
    onSelectAll: () => void
    allowSelectBulkActions: boolean
    bulkContentsSelected?: boolean
    onMarkAsNotThreat?: (ids: string[]) => void
    onBulkContentsSelected?: (value: boolean) => void
    onRemoveContent?: (data: { id: string }[]) => void
    onBatchContentRemove?: () => void
}

export const ContentTableActionsBar = ({
    selectedContentItems,
    bulkContentsSelected,
    clearSelection,
    total = 0,
    onSelectAll,
    allowSelectBulkActions,
    onMarkAsNotThreat,
    onBulkContentsSelected,
    onRemoveContent,
    onBatchContentRemove
}: ContentTableActionsBarProps) => {
    const room = getRoom();
    const { watchlist, refetchWatchlist } = useGetWatchlist({ situationRoomId: room.id });
    const onWatchlistActionCompleted = () => {
        refetchWatchlist();
        clearSelection();
    };
    const optionsListToAdd = selectedContentItems.filter(
        contentItem => !watchlist.some(({ id }: {id: string}) => id === contentItem.id)
    );
    const optionsListToRemove = selectedContentItems.filter(
        contentItem => watchlist.some(({ id }: {id: string}) => id === contentItem.id)
    );
    const handleToggleSelectAll = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();

        if (onBulkContentsSelected) onBulkContentsSelected(!bulkContentsSelected);

        if (!bulkContentsSelected && onSelectAll) onSelectAll();
    };

    const selectAllButtonText = (bulkContentsSelected) ? `Select ${selectedContentItems.length}` : 'Select all';
    const selectedCount = bulkContentsSelected ? total : selectedContentItems.length;

    if (selectedCount === 0) return null;

    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;
    return (
        <StickToBottom>
            <div className="bg-hover p-1 d-flex align-items-center justify-content-between" data-testid="actions-bar">
                <p className="m-0 font-weight-bold">
                    {selectedCount} item
                    {selectedCount !== 1 ? 's' : ''} selected
                    {(allowSelectBulkActions && onBulkContentsSelected) && (
                        <span onClick={handleToggleSelectAll} className="text-primary font-weight-normal cursor-pointer ml-2">
                            <span className="pl-1" data-testid="bulk-select-all">{selectAllButtonText}</span>
                        </span>
                    )}
                </p>
                <div className="d-flex align-items-end">
                    {(optionsListToAdd.length > 0) && (
                        <WatchlistButtonContainer contents={optionsListToAdd}
                            onCompleted={onWatchlistActionCompleted}
                            onError={clearSelection}
                            type={WATCHLIST_BUTTON_TYPES.BUTTON}
                            disabled={!!bulkContentsSelected}
                        />
                    )}
                    {(optionsListToRemove.length > 0) && (
                        <WatchlistButtonContainer contents={optionsListToRemove}
                            isWatching
                            onCompleted={onWatchlistActionCompleted}
                            onError={clearSelection}
                            type={WATCHLIST_BUTTON_TYPES.BUTTON}
                            disabled={!!bulkContentsSelected}
                        />
                    )}
                    {threatSignalsEnabled && (
                        <ManageThreatsButton selectedContents={selectedContentItems} clearSelection={clearSelection} />
                    )}
                    {contentActionsEnabled && (
                        <>
                            {!threatSignalsEnabled
                            && (
                                <MarkAsNotThreatButton selectedContentItems={selectedContentItems}
                                    onMarkAsNotThreat={onMarkAsNotThreat}
                                    disabled={!!bulkContentsSelected}
                                />
                            )}
                            <RemoveContentButtons bulkContentsSelected={!!bulkContentsSelected}
                                selectedContentItems={selectedContentItems}
                                total={total}
                                onRemoveContent={onRemoveContent}
                                onBatchContentRemove={onBatchContentRemove}
                            />
                        </>
                    )}
                </div>
            </div>
        </StickToBottom>
    );
};
