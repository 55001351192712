import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRoom } from '../../../utils/variables';
import { DropdownWithSearchAndCheckboxes } from '../components/DropdownWithSearchAndCheckboxes';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { RootState } from '../../../store';

type KeywordsFilterProps = {
    clusters?: boolean
}

export const KeywordsFilter = ({ clusters = false }: KeywordsFilterProps) => {
    const room = getRoom();
    const location = useLocation();
    const dispatch = useDispatch();
    const { projectId } = location.state || {};

    const { keywords: selectedKeywords }: { keywords: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { data, loading } = useQuery(!clusters ? TOP_KEYWORDS : TOP_KEYWORDS_IN_CLUSTERS, {
        variables: {
            projectId: room?.project_id || projectId,
            size: 20
        }
    });
    let topKeywords = [];
    if (clusters) {
        topKeywords = (data?.getTopKeywordsInClusters || []) as string[];
    } else {
        topKeywords = (data?.topKeywords || []) as string[];
    }

    const keywordsData = topKeywords?.map(keyword => ({
        id: keyword,
        name: keyword,
        selected: selectedKeywords.includes(keyword)
    }));

    const handleSelectItem = (id: string) => {
        dispatch(saveSelectedFilters({
            keywords: selectedKeywords.includes(id)
                ? selectedKeywords.filter(keyword => keyword !== id)
                : [...selectedKeywords, id]
        }));
    };
    const countFilters = selectedKeywords.length;

    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={keywordsData}
                placeholder="Search Keywords"
                description="Top 20 Keywords"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
            />
        </div>
    );
};

export const TOP_KEYWORDS = gql`
    query topKeywords(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topKeywords(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        )
    }
`;

export const TOP_KEYWORDS_IN_CLUSTERS = gql`
    query getTopKeywordsInClusters(
        $projectId: String
        $size: Int
    ) {
        getTopKeywordsInClusters(
           projectId: $projectId
           size: $size 
        )    
    }

`;
