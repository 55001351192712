import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateRangePicker } from '../../../../../components/DateRangePicker';
import { selectRange } from '../../store';

type HandleDateRangeStateProps = {
    toggleRange: (a: number, b: number) => void
    selected?: boolean
}

export const HandleDateRangeState = forwardRef(({ toggleRange, selected = false }: HandleDateRangeStateProps, ref) => {
    const range = useSelector(selectRange);
    const [inputRange, setInputRange] = useState(range || false);

    if (range !== null && range !== inputRange) {
        setInputRange(range);
    }

    useImperativeHandle(ref, () => ({
        setInputRange,
        inputRange
    }));
    return (
        <div className="d-flex align-items-center">
            <DateRangePicker config={{ drops: 'down', append: true }}
                onDatePickerCallback={(a, b) => toggleRange(a.valueOf(), b.valueOf())}
                inputRange={inputRange}
                iconOnly
                selected={selected}
            />
        </div>
    );
});
