import React, { useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { color } from '../../../utils/getColors';
import { TextTooltip } from '../../ToolTip/TextTooltip';

export const SearchInput = ({
    placeholder,
    handleSearch,
    className = '',
    defaultValue = ''
}) => {
    const [value, setValue] = useState(defaultValue);
    const onResetQuery = () => {
        setValue('');
        handleSearch('');
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSearch(value);
    };

    return (
        <form onSubmit={handleFormSubmit} className={`d-block ${className}`} data-testid="search-input-component">
            <InputGroup border={value ? 'active' : 'none'}>
                <Input value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={placeholder || 'Search - Enter a search term to update the room overview and press ENTER'}
                    data-testid="search-input"
                />
                {value && (
                    <InputGroupAddon addonType="prepend" className="border-right small-border">
                        <InputGroupText role="button" onClick={onResetQuery} data-testid="x-icon">
                            <TextTooltip id="close-labels"
                                placement="bottom-start"
                                className="p-0 m-0"
                                title="Close"
                                withIcon
                            >
                                <FeatherIcon.X color={color.blue[500]} size={16} />
                            </TextTooltip>
                        </InputGroupText>
                    </InputGroupAddon>
                )}
                <InputGroupAddon addonType="prepend">
                    <InputGroupText role="button" onClick={() => handleSearch(value)} data-testid="search-icon">
                        <FeatherIcon.Search size={16} color={color.blue[500]} />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </form>
    );
};
