import { useSignificantTerms } from './useSignificantTerms';
import { withTable } from './withTable';

const columns = [
    { key: 'value', label: 'Keywords', className: 'dont-break-out' },
    { key: 'type', label: 'Category', className: 'pl-0' },
    { key: 'matchPercentage', label: 'Match %', className: 'px-0' }
];

export const SignificantTerms = withTable({
    columns,
    api: useSignificantTerms,
});
