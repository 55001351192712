import React from 'react';
import NodataForInvestigations from '../../../../assets/images/investigations/NodataForInvestigations.svg';

export const EmptyState = ({ selectedTab, reason }) => (
    <div className="d-flex flex-column justify-content-center align-items-center bg-white p-4" data-testid="empty-data">
        <img src={NodataForInvestigations} alt="no-data" width="100" />
        {(selectedTab === 'in-progress' || selectedTab === 'completed')
         && (
             <h2 className="mt-2 mb-2">There are no investigations {selectedTab === 'in-progress' ? 'in-progress' : 'completed'}
                 {reason && ' for these filters'}
             </h2>
         )}
        {selectedTab === 'tokens' && <h2>There are no tokens requested</h2>}
        <p>{reason ? 'Please try with different filters' : 'Please check back later'}</p>
    </div>
);
