import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_CTYPES } from '../graphql';
import { allCtypesMongo } from './variables';

export const fetchCtypes = ({ client }: { client: ApolloClient<NormalizedCacheObject> }) =>
    client.query({
        query: GET_CTYPES,
    }).then(({ data }: { data: any }) => allCtypesMongo(data.getCtypes));

type ThreatItem = {
    name: string;
    color: string;
    description?: string;
};

type ThreatsTypes = {
    automation_score: ThreatItem;
    custom: ThreatItem;
    'src-cred': ThreatItem;
    manual_label: Omit<ThreatItem, 'description'>;
    domestic_extremism: Omit<ThreatItem, 'description'>;
};

type LevelsTypes = {
    high: ThreatItem;
    NONE: ThreatItem;
    medium: ThreatItem;
    low: ThreatItem;
} & ResolversType;

type ResolversType = {
    TOXICITY: ThreatItem;
    SEVERE_TOXICITY: ThreatItem;
    THREAT: ThreatItem;
    INSULT: ThreatItem;
    IDENTITY_ATTACK: ThreatItem;
    INFLAMMATORY: ThreatItem;
    ATTACK_ON_AUTHOR: ThreatItem;
    ATTACK_ON_COMMENTER: ThreatItem;
};

type ContentType = {
    blogpost: Omit<ThreatItem, 'description'>;
    facebook: ThreatItem;
    tweet: ThreatItem;
    article: ThreatItem;
    redditpost: ThreatItem;
    forumpost: ThreatItem;
    tumblrpost: ThreatItem;
    review: ThreatItem;
    youtube: ThreatItem;
    tencetqq: ThreatItem;
    telegram: ThreatItem;
};

type GetAllTypesReturnType = ThreatsTypes & LevelsTypes & ContentType;

const GetAllTypes = (): GetAllTypesReturnType => ({
    ...THREAT,
    ...LEVEL,
    ...CONTENT_TYPE
});

export const getCtype = (item: string | undefined) => {
    const all = allCtypesMongo();
    const current: any = all?.find((a: any) => a.key === item);
    return current || { name: item };
};

export const capitalizeFirstLetter = (string: string | undefined) => (string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '');

export const truncateString = (str: string | undefined, number: number, toNearestFullWord = true) => {
    if (!str) return '';
    const num = number || 300;
    if (str.length <= num) return str;

    const trimmedString = str.slice(0, num);
    if (trimmedString.match(/[\u3400-\u9FBF]/) || !toNearestFullWord) return `${trimmedString}...`;

    const truncatedString = `${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))}...`;
    if (truncatedString === '...' || truncatedString.length <= 6) {
        return `${trimmedString}...`;
    }
    return truncatedString;
};

const LEVEL: LevelsTypes = {
    high: { name: 'High', color: '217, 142, 66', description: '' },
    NONE: { name: 'None', color: '118, 185, 92', description: '' },
    medium: { name: 'Medium', color: '80, 117, 179', description: '' },
    low: { name: 'Low', color: '80, 117, 179', description: '' },
    TOXICITY: { name: 'Toxic language', color: '80, 117, 179', description: '' },
    SEVERE_TOXICITY: { name: 'Severely toxic language', color: '80, 117, 179', description: '' },
    THREAT: { name: 'Threatening language', color: '80, 117, 179', description: '' },
    INSULT: { name: 'Insulting language', color: '80, 117, 179', description: '' },
    IDENTITY_ATTACK: { name: 'Attack on identity', color: '80, 117, 179', description: '' },
    INFLAMMATORY: { name: 'Inflammatory language', color: '80, 117, 179', description: '' },
    ATTACK_ON_AUTHOR: { name: 'Attack on author', color: '80, 117, 179', description: '' },
    ATTACK_ON_COMMENTER: { name: 'Attack on commenter', color: '80, 117, 179', description: '' },
};

const Resolvers: ResolversType = {
    TOXICITY: { name: 'Toxic language', color: '80, 117, 179', description: '' },
    SEVERE_TOXICITY: { name: 'Severely toxic language', color: '80, 117, 179', description: '' },
    THREAT: { name: 'Threatening language', color: '80, 117, 179', description: '' },
    INSULT: { name: 'Insulting language', color: '80, 117, 179', description: '' },
    IDENTITY_ATTACK: { name: 'Attack on identity', color: '80, 117, 179', description: '' },
    INFLAMMATORY: { name: 'Inflammatory language', color: '80, 117, 179', description: '' },
    ATTACK_ON_AUTHOR: { name: 'Attack on author', color: '80, 117, 179', description: '' },
    ATTACK_ON_COMMENTER: { name: 'Attack on commenter', color: '80, 117, 179', description: '' },
};

const THREAT: ThreatsTypes = {
    automation_score: { name: 'Automated account', color: 'primary', description: '' },
    custom: { name: 'Custom threat', color: 'primary', description: '' },
    'src-cred': { name: 'Source credibility', color: 'primary', description: '' },
    manual_label: { name: 'Manual label', color: 'primary' },
    domestic_extremism: { name: 'Domestic extremism', color: 'primary' },
};

const CONTENT_TYPE: ContentType = {
    blogpost: { name: 'Forums', color: '217, 142, 66' },
    facebook: { name: 'Facebook', color: '80, 117, 179', description: '' },
    tweet: { name: 'Twitter', color: '86, 172, 239', description: '' },
    article: { name: 'News & Articles', color: '118, 185, 92', description: '' },
    redditpost: { name: 'Reddit', color: '118, 185, 92', description: '' },
    forumpost: { name: 'Forum Post', color: '118, 185, 92', description: '' },
    tumblrpost: { name: 'Tumblr', color: '118, 185, 92', description: '' },
    review: { name: 'Review', color: '118, 185, 92', description: '' },
    youtube: { name: 'Youtube', color: '118, 185, 92', description: '' },
    tencetqq: { name: 'Tencet QQ', color: '118, 185, 92', description: '' },
    telegram: { name: 'Telegram', color: '118, 185, 92', description: '' },
};

export const formatThreatResolvers = (item: string) => {
    if (item !== 'not-applicable' && !Resolvers[item as keyof ResolversType] && item !== '-') {
        return {
            name: 'Custom threat',
            color: 'primary',
        };
    }
    return Resolvers[item as keyof ResolversType];
};

export const formatThreatLabel = (item: string) => {
    if (item !== 'not-applicable' && !THREAT[item as keyof ThreatsTypes] && item !== '-') {
        return {
            name: 'Custom threat',
            color: 'primary',
        };
    }
    return THREAT[item as keyof ThreatsTypes];
};

export const AllTypes = GetAllTypes();
