import React from 'react';
import { Loading } from '../../../components/Loading';

export const NoAnnotationsEmptyState = ({ label, className }: { label: string, className?: string }) => (
    <div className={`p-xl-6 p-4 mx-n3 ${className || ''}`}>
        <Loading gif relative gifSize={110} height="auto" />
        <p className="mt-2 text-center maxw-350 mx-auto">
            LI is enhancing the data. {label} will be updated once the enhancement
            is completed for a few pieces of content. Please check back later.
        </p>
    </div>
);
