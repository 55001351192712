import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';

export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
        if (items === undefined) return;
        const sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] === undefined || b[sortConfig.key] === undefined) {
                    throw Error(`key [${sortConfig.key}] doesn't exist on the sorted object`);
                }
                if (!Number.isNaN(parseInt(a[sortConfig.key], 10))) {
                    if (parseInt(a[sortConfig.key], 10) < parseInt(b[sortConfig.key], 10)) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (parseInt(a[sortConfig.key], 10) > parseInt(b[sortConfig.key], 10)) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                }
                else {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                }

                return 0;
            });
        } return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig
            && sortConfig.key === key
            && sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

export const lodashSort = (data, sort) => {
    if (!sort.field) {
        return data;
    }
    return orderBy(data, item => {
        const field = sort.field.split('.').reduce((a, b) => (a ? a[b] : ''), item);
        if (typeof field === 'string') {
            return field.toLowerCase();
        }
        return field;
    }, [sort.order]);
};
