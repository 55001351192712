import React from 'react';
import { SaveInvestigation } from './SaveInvestigation';
import { ExitDetailsView } from './ExitDetailsView';

export const UserActions = () => (
    <div className="d-flex">
        <SaveInvestigation />
        <ExitDetailsView />
    </div>
);
