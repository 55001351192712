import React from 'react';
import NoDataFound from '../../../../../assets/images/NoDataFound.png';

export const EmptyStatePanel = () => (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <img src={NoDataFound} alt="No Topics included" height={84} />
        <p className="mb-2 mt-2 text-center w-75">Add the topic and include the relevant keywords/boolean query for that particular
            topic.
        </p>
    </div>
);
