import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, FormGroup } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { saveSelectedFilters } from '../../../../pages/Room/store';
import { RootState } from '../../../../store';
import { useWordcloud } from '../../../../services/Overview';
import { Loading } from '../../../Loading';
import { TooltipWrapper } from '../../../ToolTip/TooltipWrapper';

type MentionsFilterProps = {
    searchQuery: string
}

export const MentionsFilter = ({ searchQuery = '' }: MentionsFilterProps) => {
    const dispatch = useDispatch();
    const [dropdownId] = useState(uuid());

    const { mentions: selectedMentions }: { mentions: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);

    const { keywords: topMentions, loading } = useWordcloud({
        field: 'mentions',
        size: 20,
        dateFilter: {},
        defaultFilters: {
            wildcard: {
                search: searchQuery,
                field: 'mentions'
            }
        },
        search: searchQuery
    });

    const mentionsData = topMentions?.map((mention: {key: string, keyword: string}) => ({
        id: mention.key,
        name: mention.keyword,
        selected: selectedMentions.includes(mention.key)
    }));

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        dispatch(saveSelectedFilters({
            mentions: selectedMentions.includes(id)
                ? selectedMentions.filter(mention => mention !== id)
                : [...selectedMentions, id]
        }));
    };

    return (
        <div>
            {(!searchQuery && mentionsData.length) ? <p>Top 20 mentions</p> : ''}
            <div className="scrollbar-small tab-filter">
                {(mentionsData.length > 0 && !loading) && (
                    <>
                        {mentionsData.map((item: {id: string, name: string, selected: boolean}) => (
                            <FormGroup key={item.id}>
                                <CustomInput tag="checkbox"
                                    type="checkbox"
                                    checked={item.selected}
                                    className="custom-input-filter py-1"
                                    label={item.name.length >= 11
                                        ? (
                                            <TooltipWrapper tooltipText={item.name} id={`tooltip_${dropdownId}_${item.id}`}>
                                                <p className="truncate-this">{item.name}</p>
                                            </TooltipWrapper>
                                        ) : <p className="truncate-this">{item.name}</p>}
                                    id={`${dropdownId}_${item.id}`}
                                    data-testid={`checkbox-${item.id}`}
                                    value={item.name}
                                    onChange={(e) => { handleSelectItem(item.id, e); }}
                                />
                            </FormGroup>
                        ))}
                    </>
                )}
                {loading && <Loading relative height={168} width={255} />}
                {(!mentionsData.length && !loading) && <p className="no-white-space">No results found</p>}
            </div>
        </div>
    );
};
