import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { SortToggle } from '../../../components/Table/SortToggle';
import { convertToDate } from '../../../utils/getTimeFrom';
import { lodashSort } from '../../../utils/lib/tableSorting';

export const InstancesTable = ({ instances }) => {
    const [sort, setSort] = useState({});

    return (
        <div className="overflow-x-auto">
            <Table>
                <thead>
                    <tr>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Client name" field="clientName" />
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Contact name" field="contactName" />
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="End date" field="validUntil" />
                        </th>
                        <th>
                            Trial
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {lodashSort(instances, sort).map(instance => {
                        const endDate = moment(convertToDate(instance.validUntil));
                        const isActive = endDate - moment() > 0;
                        return (
                            <tr key={instance.clientName}>
                                <td>
                                    <Link to={`/admin/instances/${instance.id}`}>
                                        {instance.clientName}
                                    </Link>
                                </td>
                                <td>{instance.contactName}</td>
                                <td>
                                    {instance.validUntil && (
                                        <div className="d-flex align-items-center">
                                            <div className={`bg-${isActive ? 'success' : 'danger'} p-1 rounded mr-1`} />
                                            <p className="m-0 line-height-1">{isActive ? 'Active' : 'Inactive'}</p>
                                        </div>
                                    )}
                                </td>
                                <td>{instance.validUntil ? endDate.format('DD/MM/YYYY') : ''}</td>
                                <td>{instance.isTrial ? 'Yes' : 'No'}</td>
                            </tr>
                        ); })}
                </tbody>
            </Table>
        </div>
    ); };
