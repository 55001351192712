import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { ExportOptions } from 'regraph';
import { RadioInput } from '../../../../../components/Atoms';
import { useModal } from '../../../../../context/modal/ModalComponent';

export type ChartExportModalProps = {
    chartExport: (exportOptions: ExportOptions) => void;
}

export const ChartExportModal = ({ chartExport }: ChartExportModalProps) => {
    const [format, setFormat] = useState<'png' | 'jpeg'>('png');
    const { closeModal } = useModal();
    const handleExportChart = () => {
        closeModal();
        chartExport({
            type: format,
            extents: 'view',
            fitTo: {
                width: 10000,
                height: 10000
            }
        });
    };
    return (
        <div>
            <RadioInput id="formatPng"
                name="format"
                checked={format === 'png'}
                onChange={() => setFormat('png')}
                label="PNG"
                className="mb-2"
            />
            <RadioInput id="formatJpeg"
                name="format"
                checked={format === 'jpeg'}
                onChange={() => setFormat('jpeg')}
                label="JPEG"
            />
            <hr />
            <Button onClick={closeModal} className="mr-2">Cancel</Button>
            <Button onClick={handleExportChart} color="primary">Export</Button>
        </div>
    );
};
