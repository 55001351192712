import { useQuery } from '@apollo/client';
import { GOOGLE_TRENDS_TOPIC } from '../../graphql';

export const useRelatedTopics = ({ query, skip }) => {
    const { loading, data } = useQuery(GOOGLE_TRENDS_TOPIC, {
        variables: {
            keyword: query
        },
        skip
    });
    const topics = data?.googleTrendsTopic || [];
    return {
        loading,
        data: topics.length > 10 ? topics.slice(0, 10) : topics
    };
};
