import { uniq } from 'lodash';
import isValidDomain from 'is-valid-domain';
import { useSelector } from 'react-redux';
import { createNode } from './createNode';
import { NetworkNode } from '../../useNetworks';
import { NetworksHistoryStore } from '../../../store';

export const useAuthorNodes = (data: NetworkNode[]) => {
    const { includeAuthorsAndMentions } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    if (!includeAuthorsAndMentions) return {};
    const allAuthors = uniq(data.map(({ from, mentions, contentType }) => [
        {
            author: from,
            contentType
        },
        ...mentions.map(mention => ({
            author: mention,
            contentType
        }))
    ]).flat());
    return Object.fromEntries(
        allAuthors.map(({ author, contentType }) => {
            const isDomain = isValidDomain(author);
            const authorText = `${isDomain ? '' : '@'}${author.replace('@', '')}`;
            return [
                `author_${author}`,
                {
                    ...createNode({
                        text: authorText,
                        fontIcon: isDomain ? 'fa-globe' : 'fa-user'
                    }),
                    data: {
                        type: 'author',
                        id: author,
                        author: authorText,
                        contentType
                    }
                }
            ];
        })
    );
};
