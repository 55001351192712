import React, { useEffect, useRef } from 'react';
import { Chart, FontLoader } from 'regraph';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ActionCreators } from 'redux-undo';
import { useNetworksData } from './useNetworks';
import { Loading } from '../../../../components/Loading';
import { useChartItems } from './chartItems';
import { NetworksToolbar, NetworksToolbarRef } from './Toolbar';
import { NetworksTimeline } from './Timeline';
import { NetworksHistoryStore, NetworksState, resetNetworks, setRegraphLoading } from '../store';
import {
    handleDragStart,
    useChartChange,
    useChartClick,
    useChartExport,
    useChartZoom,
    useDeleteNodes
} from './handlers';
import { useNetworksFeatureTour } from '../featureTour';
import { color } from '../../../../utils/getColors';

export type NetworksChartProps = {
    setTotalCount: (val: number) => void
}

export const minZoom = 0.01;
export const maxZoom = 4;

export const NetworksChart = ({ setTotalCount }: NetworksChartProps) => {
    const dispatch = useDispatch();
    const { regraphLoading, chartKey, chartUpdates } = useSelector((state: { networks: NetworksState }) => state.networks);
    const { chartState, layout, clusterBy } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    // fetch networks data
    const { loading, data, count } = useNetworksData();

    // format networks data for Regraph
    const items = useChartItems({ data });

    // refs
    const toolbarRef = useRef<NetworksToolbarRef>(null);
    const chartRef = useRef<Chart>(null);

    // feature tour
    const { handleNetworksFeatureTour } = useNetworksFeatureTour();

    // handlers
    useDeleteNodes({ chartRef });
    const { handleChange } = useChartChange();
    const { handleClick } = useChartClick({ items });
    const { chartZoom, onViewChange } = useChartZoom({ chartRef, toolbarRef });
    const { chartExport } = useChartExport({ chartRef });
    const handleUpdateComplete = () => {
        if (regraphLoading && !loading) {
            dispatch(setRegraphLoading(false));
            setTotalCount(count);
            // handle feature tour after loading
            handleNetworksFeatureTour();
        }
    };

    return (
        <div className="flex-1 d-flex flex-column mh-0">
            <CleanChartState />
            <div className="bg-primary-hover position-relative flex-1 mh-0">
                {loading && <Loading message="fetching data..." />}
                {(regraphLoading && !loading) && <Loading background={color.blue['100']} message="loading graph..." />}
                <MemoizedChart items={items}
                    positions={chartState.positions}
                    onUpdateComplete={handleUpdateComplete}
                    onDragStart={handleDragStart}
                    onClick={handleClick}
                    onViewChange={onViewChange}
                    onChange={handleChange}
                    chartRef={chartRef}
                    layout={{ name: layout }}
                    combine={clusterBy ? {
                        properties: [clusterBy],
                        level: 1
                    } : undefined}
                    key={chartKey}
                    chartUpdates={chartUpdates}
                />
                <NetworksToolbar ref={toolbarRef} chartZoom={chartZoom} chartExport={chartExport} />
            </div>
            <NetworksTimeline />
        </div>
    );
};

const CleanChartState = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetNetworks());
        dispatch(ActionCreators.clearHistory());
    }, [location.pathname, dispatch]);
    return null;
};

interface MemoizedChartProps extends Chart.Props {
    chartRef: React.RefObject<Chart<any, string | number>>
    chartUpdates: number
}

const MemoizedChart = React.memo(({
    items,
    onDragStart,
    onClick,
    onUpdateComplete,
    onViewChange,
    chartRef,
    layout,
    onChange,
    positions,
    combine,
    chartUpdates
}: MemoizedChartProps) => (
    <FontLoader config={{ custom: { families: ['Font Awesome 5 Free', 'Font Awesome 5 Brands'] } }}>
        <Chart items={items}
            options={chartOptions}
            onUpdateComplete={onUpdateComplete}
            onDragStart={onDragStart}
            onClick={onClick}
            onViewChange={onViewChange}
            onChange={onChange}
            positions={positions}
            ref={chartRef}
            layout={layout}
            combine={combine}
        />
    </FontLoader>
), (prevProps, nextProps) => (
    prevProps.layout?.name === nextProps.layout?.name
        && isEqual(prevProps.combine, nextProps.combine)
        && isEqual(prevProps.items, nextProps.items)
        && prevProps.chartUpdates === nextProps.chartUpdates
));

const chartOptions = {
    backgroundColor: color.blue[100],
    iconFontFamily: 'Font Awesome 5 Free',
    navigation: false,
    overview: false,
    labels: {
        maxLength: 20
    },
    selection: {
        labelColor: '#000',
        color: color.blue[500]
    },
    minZoom,
    maxItemZoom: maxZoom
};
