import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { FORGOT_PASSWORD } from '../../../graphql';

type PasswordResetExpiredProps = {
    email: string
}

export const PasswordResetExpired = ({ email }: PasswordResetExpiredProps) => {
    const history = useHistory();
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
        onCompleted: (res) => {
            const { done } = res.forgotPassword;
            if (done) {
                setPasswordResetSent(true);
            }
        }
    });
    return (
        <>
            {
                passwordResetSent ? (
                    <>
                        <h1>Reset your password</h1>
                        <p>If an account exists for <strong>{email}</strong> we&rsquo;ll send link for resetting your password.</p>
                    </>
                ) : (
                    <>
                        <h1>Forgot your password link expired</h1>
                        <p>Your sign up link has either already been used or timed out.
                            Please contact your administrator to get another sign up link
                        </p>
                    </>
                )
            }
            <hr />
            <div className="d-flex align-items-center justify-content-between">
                <Button color="link" className="d-flex align-items-center my-1 p-0" onClick={() => history.push('/login')}>
                    <ArrowLeft size={18} />
                    <p className="ml-1 my-0 text-primary">Back to login</p>
                </Button>
                {loading ? <Spinner size="sm" color="primary" className="mr-2" />
                    : (
                        <Button color="link"
                            onClick={() => forgotPassword({
                                variables: {
                                    email
                                }
                            })}
                        >Send link again
                        </Button>
                    )}
            </div>
        </>
    ); };
