export const user = {
    __typename: 'UserMongo',
    id: '63b267cfabaf106d10106e31',
    uid: '63b267cfff196ff71e029438',
    displayName: 'Pranit Tare',
    firstName: 'Pranit',
    lastName: 'Tare',
    email: 'pranit.t@logically.co.uk',
    photoURL: 'https://s.gravatar.com/avatar/20f225fbd5711f218170282158f31ce2?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fpr.png',
    role: 'ADMIN',
    instance: {
        __typename: 'Instance',
        id: '62bec49be6464c14106481b1',
        clientName: 'LI 2 E2E',
        account: {
            __typename: 'Account',
            accountManager: ''
        },
        plan: {
            __typename: 'Plan',
            core: {
                __typename: 'Core',
                overview: true,
                threats: true,
                logicallyFactChecks: true,
                narratives: true
            },
            integrations: {
                __typename: 'Integrations',
                investigations: true
            },
            disabledThreatTypes: []
        }
    },
    status: 'Active',
    lastSeen: 1672655113468,
    createdAt: 1672636367882,
    bio: null,
    situationRoomViewList: [],
    jobDescription: null,
    preferences: {
        __typename: 'Preferences',
        cookies: false,
        marketingEmails: false,
        productEmails: false,
        darkmode: false
    },
    welcomeMessage: null
};
