import React from 'react';
import { Col, Row } from 'reactstrap';
import { LogoL } from '../../assets/svg/logo';
import { WhatsNewCarousel } from './WhatsNewCarousel';

type AuthLayoutProps ={
    children: React.ReactNode
}

export const AuthLayout = ({ children }: AuthLayoutProps) => (
    <Row className="auth-layout">
        <Col xs={12} md={7} className="border-right auth-page-background">
            <div className="p-4 p-xl-7 h-100 justify-content-center flex-column d-none d-md-flex">
                <div className="position-absolute d-flex align-items-center top-0 mt-5">
                    <LogoL />
                    <h2 className="ml-2 my-0 text-logo dark text-dark">Intelligence</h2>
                </div>
                <WhatsNewCarousel />
            </div>
            <div className="p-4 d-block d-md-none">
                <div className="d-flex align-items-center">
                    <LogoL />
                    <h2 className="ml-2 my-0 text-logo dark text-dark">Intelligence</h2>
                </div>
                <h1 className="mb-0 mt-7">
                    Welcome to Logically Intelligence
                </h1>
            </div>
        </Col>
        <Col xs={12} md={5} className="pl-md-0 d-flex justify-content-center flex-column">
            <div className="p-4 p-xl-7">
                {children}
            </div>
        </Col>
    </Row>
);
