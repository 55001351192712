import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_ROOM_DRAFT } from '../../graphql';

export const useSituationRoomDraftByID = ({ id }) => {
    const { loading, data } = useQuery(GET_ROOM_DRAFT, {
        variables: {
            id
        },
        fetchPolicy: 'no-cache',
        skip: !id
    });
    return {
        loading,
        data: data?.situationRoomDraft ? {
            ...data.situationRoomDraft,
            start_date: moment(new Date(data.situationRoomDraft.start_date))
        } : false
    };
};
