import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Spinner } from 'reactstrap';
import { largeNumber } from '../../utils/NumberFormat';
import { useUser } from '../../context/user/UserContext';

export const RoomMetrics = ({ roomMetrics }) => {
    const { user } = useUser();
    const isNarrativesEnabled = user?.instance?.plan?.core?.aiClustering;
    if (!roomMetrics) {
        return (
            <>
                <td><Spinner size="sm" color="primary" /></td>
                <td><Spinner size="sm" color="primary" /></td>
                {isNarrativesEnabled && <td><Spinner size="sm" color="primary" /></td>}
                {isNarrativesEnabled && <td><Spinner size="sm" color="primary" /></td>}
            </>
        );
    }
    return (
        <>
            <td>{largeNumber(roomMetrics?.matches)}</td>
            <td>{largeNumber(roomMetrics?.threats)}</td>
            {isNarrativesEnabled && <td>{largeNumber(roomMetrics?.narrativeTheme)}</td>}
            {isNarrativesEnabled && <td>{largeNumber(roomMetrics?.narratives)}</td>}
        </>
    );
};

export const RoomMetricsThreats = ({ roomMetrics }) => {
    if (!roomMetrics) {
        return (
            <Spinner size="sm" color="primary" />
        );
    }
    return (
        <p className="m-0">
            <b>{roomMetrics?.threats}</b> Threats
        </p>
    );
};

export const useRoomMetrics = (projectIds = []) => {
    const { user } = useUser();

    const isNarrativesEnabled = user?.instance?.plan?.core?.aiClustering;

    const { data, loading } = useQuery(getRoomAggsQuery({ isNarrativesEnabled }), {
        variables: {
            projectIds
        },
        skip: !projectIds.length
    });
    if (loading) {
        return [];
    }
    return data?.getRoomMetrics || [];
};

export const getRoomAggsQuery = ({ isNarrativesEnabled }) => gql`
    query getRoomMetrics($projectIds: [String]!) {
        getRoomMetrics(projectIds: $projectIds) {
            matches
            threats
            ${isNarrativesEnabled ? 'narrativeTheme' : ''}
            ${isNarrativesEnabled ? 'narratives' : ''}
            projectId
        }
    }
`;
