import { convertToMarkers } from '../convertToMarkers';

export const wildcardAtStartValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    if (query.charAt(0) === '*') {
        const wildCardWordEnd = query.indexOf(' ');
        highlight.push([0, wildCardWordEnd === -1 ? query.length : wildCardWordEnd]);
    }

    [...query.matchAll(/[\s(]\*/g)].forEach((match) => {
        const wildcardIndex = match.index + 1;
        const wildcardStart = query.substring(wildcardIndex);

        const wildCardWordEnd = wildcardStart.match(/[\s)]/i);
        highlight.push([wildcardIndex, wildcardIndex + (wildCardWordEnd?.index || wildcardStart.length)]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have used a wildcard character at the start of a word'
        )
    };
};
