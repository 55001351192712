import React, { useState } from 'react';
import { ContentTableContentType, ContentTableView } from '../Organisms/ContentTableView';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { useModal } from '../../context/modal/ModalComponent';
import { RemoveContentModal } from '../CommonModals/RemoveContent';
import { RequestInvestigation } from '../RequestInvestigation';
import { NotThreatIdentifierModal } from '../CommonModals/NotThreatIdentifier';
import { ThreatIdentifier } from '../CommonModals/ThreatIdentifier';
import { useUser } from '../../context/user/UserContext';
import { getRoom } from '../../utils/variables';
import { BatchRemoveCompletedModal } from '../Organisms/Modals/BatchRemoveModal/BatchRemoveCompletedModal';
import { BatchRemoveModal } from '../Organisms/Modals/BatchRemoveModal/BatchRemoveModal';
import { ContentId } from '../../pages/Room/AIClustering/types';

type PaginationType = {
    count: number
    offset: number
    setOffset: (e: number) => void;
}

type HandleCompletedTypes = {
    total: number
    timeInSeconds: number
}

type MatchesContentTableProps = {
    contents: ContentTableContentType[]
    pagination?: PaginationType
    total: number
    displayLimit: number
    isPreview?: boolean
    isAINarratives?: boolean
    clusterData?: ContentId[]
}

export const MatchesContentTable = ({
    contents,
    pagination,
    total,
    displayLimit,
    isPreview,
    isAINarratives = false,
    clusterData
}: MatchesContentTableProps) => {
    const room = getRoom();
    const { notAllowed } = useUser();
    const { setModal, closeModal } = useModal();
    const [selectedContentItems, setSelectedContentItems] = useState([] as ContentTableContentType[]);
    const [bulkContentsSelected, setBulkContentSelected] = useState(false);
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const noActionsAllowed = notAllowed.includes('editData') || isPreview || !contentActionsEnabled;
    const allowSelectBulkActions = Boolean(!noActionsAllowed && total && displayLimit && (Number(total || 0) > Number(displayLimit || 0)));
    const handleSelectAll = () => setSelectedContentItems(contents);
    const handleClearSelection = () => {
        setBulkContentSelected(false);
        setSelectedContentItems([]);
    };
    const handleBulkRemoveCompleted = ({ total: totalRemoved, timeInSeconds }: HandleCompletedTypes) => {
        handleClearSelection();
        setModal({
            header: false,
            component: <BatchRemoveCompletedModal totalRemoved={totalRemoved} timeInSeconds={timeInSeconds} />,
        });
    };

    const setRemoveContentModal = (data: { id: string }[]) => {
        setModal({
            modal: <RemoveContentModal removeData={data}
                type={CONTENT_TYPE.THREAT}
                onCompleted={handleClearSelection}
            />,
            goToPreviousOnClose: true
        });
    };
    const setBatchRemoveContentModal = () => setModal({
        header: false,
        component: <BatchRemoveModal onCompleted={handleBulkRemoveCompleted}
            onAbort={handleClearSelection}
            contentType={CONTENT_TYPE.MATCH}
        />,
        backdrop: 'static'
    });
    const setRequestInvestigationModal = (content: ContentTableContentType) => setModal({
        modal: <RequestInvestigation defaultDetails={content} onClose={closeModal} />,
        goToPreviousOnClose: true
    });
    const setMarkAsThreatModal = (id: string) => setModal({
        modal: <ThreatIdentifier onClose={closeModal} threatId={id} />,
        goToPreviousOnClose: true
    });
    const setMarkAsNotThreatModal = (ids: string[]) => {
        setModal({ modal: <NotThreatIdentifierModal ids={ids} />, goToPreviousOnClose: true });
    };
    return (
        <ContentTableView contents={contents}
            selectedContentItems={selectedContentItems}
            total={total}
            pagination={pagination}
            allowSelectBulkActions={allowSelectBulkActions}
            noActionsAllowed={noActionsAllowed}
            bulkContentsSelected={bulkContentsSelected}
            onSelectAll={handleSelectAll}
            onClearSelection={handleClearSelection}
            onBulkContentsSelected={setBulkContentSelected}
            onSetSelectedContentItems={setSelectedContentItems}
            onRemoveContent={setRemoveContentModal}
            onRequestInvestigation={setRequestInvestigationModal}
            onMarkAsThreat={setMarkAsThreatModal}
            onMarkAsNotThreat={setMarkAsNotThreatModal}
            onBatchContentRemove={setBatchRemoveContentModal}
            isAINarratives={isAINarratives}
            clusterData={clusterData}
        />
    );
};
