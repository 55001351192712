import { useMutation } from '@apollo/client';
import { CREATE_SITUATION_ROOM, GET_SITUATION_ROOM_DRAFTS } from '../../graphql';

export const useCreateSituationRoom = (id) => {
    const [createSituationRoom, { loading }] = useMutation(CREATE_SITUATION_ROOM, {
        refetchQueries: [{ query: GET_SITUATION_ROOM_DRAFTS, variables: { instance: id } }],
        update: (cache) => cache.evict({ fieldName: 'situationRooms' })
    });
    return {
        loading,
        createSituationRoom
    };
};
