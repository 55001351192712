import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { SortToggle } from '../../../components/Table/SortToggle';
import { useSituationRooms } from './SituationRoomsContext';

export const SituationRoomsTable = ({ rooms }) => {
    const { sort, setSort } = useSituationRooms();
    return (
        <Table className="mb-0 border-bottom" hover>
            <thead>
                <tr>
                    <th>
                        <SortToggle sort={sort} setSort={setSort} field="project_name" name="Room name" />
                    </th>
                    <th>Status</th>
                    <th>Query</th>
                    <th>Client</th>
                    <th>
                        <SortToggle sort={sort} setSort={setSort} field="createdAt" name="Created date" />
                    </th>
                    <th>Created by</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {rooms.map((room) => {
                    let status = room.deleted ? 'Deleted' : 'Active';
                    if (room.archived) { status = 'Archived'; }
                    if (room.paused) { status = 'Paused'; }
                    return (
                        <tr key={room.id}>
                            <td><Link to={`/admin/situation-rooms/${room.id}`}>{room.project_name}</Link></td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className={`mr-1 p-1 d-inline-block rounded bg-${statusColors[status]}`} />
                                    {status}
                                </div>
                            </td>
                            <td>{room.query_type}</td>
                            <td>{room.instance?.clientName || ''}</td>
                            <td>{moment(new Date(room.createdAt)).format('DD/MM/YYYY')}</td>
                            <td>{room.actuallyCreatedBy?.displayName || room.createdBy?.displayName}</td>
                            <td><Link to={`/situation-rooms/${room.id}`}>View room</Link></td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

const statusColors = {
    Deleted: 'danger',
    Active: 'success',
    Archived: 'warning',
    Paused: 'info'
};
