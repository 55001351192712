import React, { useRef, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Wordcloud from 'highcharts/modules/wordcloud';
import { color } from '../../utils/getColors';
import { Loading } from '../Loading';

type WordClouldData = {
    name: string;
    weight: number;
    original: string;
    color?: string;
};

type WordCloudProps = {
    data: WordClouldData[];
    minFontSize?: number;
    callBack?: (data: any) => void;
    placement?: string; // horizontal is for reports only
};

Wordcloud(Highcharts);

export const WordCloud = ({ data, minFontSize, placement = 'center', callBack }: WordCloudProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

    // Measure container height dynamically
    useEffect(() => {
        if (containerRef.current) {
            setContainerHeight(containerRef.current.offsetHeight);
        }
        const handleResize = () => {
            if (containerRef.current) {
                setContainerHeight(containerRef.current.offsetHeight);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!data) {
        return (
            <div data-testid="wordcloud-loader">
                <Loading relative />
            </div>
        );
    }

    const options = {
        chart: {
            type: 'wordcloud',
            height: containerHeight || undefined,
        },
        title: {
            text: '',
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        series: [{
            type: 'wordcloud',
            data,
            name: 'Words',
            rotation: {
                from: 0,
                to: 0, // Disable word rotation for a cleaner look
            },
            colors: placement === 'horizontal' ? [color.grey[500]] : [color.blue[500]],
            minFontSize,
            dataLabels: {
                enabled: false,
                style: {
                    fontWeight: '800',
                },
            },
            events: {
                click: (e: any) => {
                    const index = e.point.index;
                    callBack?.(data[index]);
                }
            }
        }],
        plotOptions: {
            series: {
                cursor: placement === 'horizontal' ? 'default' : 'pointer', // Apply cursor to entire word cloud items
            },
            wordcloud: {
                placementStrategy: 'center',
            }
        }
    };
    return (
        <div ref={containerRef} data-testid="wordcloud" className="container h-100">
            <HighchartsReact options={options} highcharts={Highcharts} />
        </div>
    );
};
