import React, { useState } from 'react';
import { CustomInput, FormGroup } from 'reactstrap';
import { SuggestionsBox } from './SuggestionsBox';

export const SocialAccounts = ({ field, data }) => {
    const [accountFromTo, setAccountFromTo] = useState({
        fromAccount: false,
        toAccount: true
    });

    const includeContentOptions = [
        {
            name: 'To this account',
            checked: accountFromTo.toAccount && !accountFromTo.fromAccount,
            value: {
                fromAccount: false,
                toAccount: true
            }
        },
        {
            name: 'From this account',
            checked: !accountFromTo.toAccount && accountFromTo.fromAccount,
            value: {
                fromAccount: true,
                toAccount: false
            }
        },
        {
            name: 'Both',
            checked: accountFromTo.toAccount && accountFromTo.fromAccount,
            value: {
                fromAccount: true,
                toAccount: true
            }
        }
    ];

    return (
        <div>
            <p><strong>Similar accounts</strong></p>
            <p><strong>Include content</strong></p>
            <div className="d-flex align-items-center mb-2">
                {includeContentOptions.map((item) => (
                    <FormGroup key={item.name} className="mr-2">
                        <CustomInput type="checkbox"
                            checked={item.checked}
                            label={item.name}
                            value={item.name}
                            id={item.name.replaceAll(' ', '-')}
                            onChange={() => setAccountFromTo(item.value)}
                        />
                    </FormGroup>
                ))}
            </div>
            <SuggestionsBox title="Twitter accounts"
                keywords={data.map(a => ({
                    value: a.username ? `@${a.username}` : a.name,
                    type: 'account',
                    ...a
                }))}
                field={field}
                type="socialAccounts"
                accountFromTo={accountFromTo}
            />
            <hr className="my-4" />
        </div>
    );
};
