import { useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { useUser } from '../../context/user/UserContext';
import { GET_SITUATION_ROOMS } from '../../graphql';
import { RoomMetricsThreats, useRoomMetrics } from './RoomMetrics';

export const RecentSituationRooms = ({ instanceId }) => {
    const { user } = useUser();
    const recentRooms = user.situationRoomViewList?.filter(({ situationRoomid }) =>
        situationRoomid && situationRoomid !== 'undefined') || [];
    const roomIds = recentRooms.sort((a, b) =>
        new Date(b.lastViewed) - new Date(a.lastViewed)).map(({ situationRoomid }) => situationRoomid);
    const { data, loading } = useQuery(GET_SITUATION_ROOMS, {
        variables: {
            instance: instanceId,
            id: roomIds.slice(0, 3)
        },
        skip: !roomIds.length
    });

    const rooms = formatData(data?.situationRooms?.data || [], user);
    const roomMetrics = useRoomMetrics(rooms.map((room) => room.project_id));
    if (loading || !rooms.length) { return null; }
    return (
        <div>
            <h2 className="mb-2">Recent Situation Rooms</h2>
            <div data-testid="recent-situation-room-cards" className="d-flex flex-row flex-nowrap overflow-x-auto mb-3 mx-n4 px-4">
                {rooms.sort((a, b) => roomIds.indexOf(a.id) - roomIds.indexOf(b.id)).map((room, index) => (
                    <Card key={room.id} className={`border mb-1 ${index === rooms.length - 1 ? '' : 'mr-3'} mw-300`}>
                        <Link to={`/situation-rooms/${room.id}/overview`}>
                            <div className="p-3">
                                <p>
                                    <span className={room.archived ? 'show-circle bg-danger' : 'show-circle'} />
                                    <b className="ml-1 text-primary">{room.project_name}</b>

                                </p>
                                <p>
                                    <small>
                                        Last viewed -
                                        {' '}
                                        {moment(new Date(room.lastViewed)).format('DD/MM/YYYY | h:mm a')}
                                    </small>
                                </p>
                                <hr />
                                <RoomMetricsThreats roomMetrics={roomMetrics.find((agg) => agg.projectId === room.project_id)} />
                            </div>
                        </Link>
                    </Card>
                ))}
            </div>
        </div>
    );
};

const formatData = (rooms, user) => rooms.map((room) => ({
    status: room.archived ? 'Archived' : 'Active',
    lastViewed: user.situationRoomViewList?.find(({ situationRoomid }) => situationRoomid === room.id)?.lastViewed,
    ...room
}));
