import React, { useState } from 'react';
import { useCookies } from '../../../../../context/cookies/CookiesContext';
import { BooleanVersionModal } from '../../../../../components/Organisms/Modals/BooleanVersionModal/BooleanVersionModal';
import { NarrativeActionModal } from './NarrativeActionModal';

export const SaveNewNarrativeVersionModal = ({ onConfirm, onCancel, modalTitle, narrativeData = {}, roomID } = {}) => {
    const { getCookie } = useCookies();
    const doNotShowBooleanSaveWarning = getCookie('doNotShowNarrativeSaveWarning') === 'true';
    const [showForm, setShowForm] = useState(doNotShowBooleanSaveWarning);

    if (!showForm) {
        return <NarrativeActionModal onCancel={onCancel} onConfirm={() => setShowForm(true)} cookieId="doNotShowNarrativeSaveWarning" />;
    }

    return (
        <BooleanVersionModal narrativeId={narrativeData[0].id}
            onConfirm={onConfirm}
            onCancel={onCancel}
            modalTitle={modalTitle}
            roomId={roomID}
        />
    );
};
