import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'reactstrap';
import { Loading } from '../../../../components/Loading';
import { user as mockedUser } from '../../../Profile/preferences/mockTestData';
import { saveFilter, saveSort } from '../../store';
import { FeedbackFilters } from './FeedbackFilters';
import { FeedbackTable } from './FeedbackTable';
import { FeedbackTableActionsBar } from './TableActionsBar';
import { useGetRemovedContent } from '../../../../services/Content/getRemovedContent';
import { getUser } from '../../../../utils/variables';

export const ThreatsAndMatchesRemovedContent = () => {
    const dispatch = useDispatch();
    const user = getUser();
    const [offset, setOffset] = useState(0);
    const { sort, searchFilters } = useSelector((state: any) => state.removeContentFilter);
    const [checkBoxValues, setCheckBoxValues] = useState([]);
    const limit = 10;
    const { data: deletedContentData, count: total, loading, refetch } = useGetRemovedContent({
        searchFilters,
        offset,
        sort,
        limit
    });
    const handleSearch = (val: string, identifier: any) => {
        dispatch(saveFilter({
            ...searchFilters,
            [identifier]: val
        }));
        setOffset(0);
    };
    const deletedContentDataIds = deletedContentData?.map((x: any) => x?.doc_id);
    const handleClearSelection = () => setCheckBoxValues([]);
    const handleRemovePermanentlySuccess = () => {
        handleClearSelection();
        setOffset(0);
    };

    const handleRecoverContentSuccess = () => refetch();
    const handleRecoverContentAbort = () => {
        handleClearSelection();
        refetch();
    };
    const handleSelectAll = () => setCheckBoxValues(deletedContentDataIds);
    return (
        <>
            <FeedbackFilters handleSearchValue={handleSearch}
                setOffset={setOffset}
                setSort={(data: any) => dispatch(saveSort({ sort: data }))}
                sort={sort}
                total={total}
            />
            {loading ? <Loading relative height={300} /> : (
                <FeedbackTable removedContent={deletedContentData}
                    deletedContentDataIds={deletedContentDataIds}
                    checkBoxValues={checkBoxValues}
                    setCheckBoxValues={setCheckBoxValues}
                    user={user || mockedUser}
                    setPage={setOffset}
                />
            )}
            {checkBoxValues.length > 0 ? (
                <FeedbackTableActionsBar selectedContentIds={checkBoxValues}
                    onRemovePermanentlySuccess={handleRemovePermanentlySuccess}
                    onRecoverContentSuccess={handleRecoverContentSuccess}
                    onRecoverContentAction={handleClearSelection}
                    onRecoverAbort={handleRecoverContentAbort}
                    onSelectAll={handleSelectAll}
                    total={total}
                    searchFilters={searchFilters}
                    offset={offset}
                    sort={sort}
                    contentsToDisplay={limit}
                />
            ) : (
                total > limit && (
                    <div className="border-top">
                        <Pagination total={total}
                            itemsPerPage={limit}
                            offset={offset}
                            setOffset={setOffset}
                            className="py-2"
                        />
                    </div>
                )
            )}

        </>
    );
};
