import React from 'react';
import EmailSuccessIcon from '../../assets/icons/email-success.svg';
import EmailIcon from '../../assets/icons/email.svg';

export const Header = ({ success }) => (
    <>
        <img src={success ? EmailSuccessIcon : EmailIcon}
            alt={success ? 'email-success-icon' : 'email-icon'}
            className="mb-2"
            style={{ width: 80 }}
        />
        <h2 className="mb-2" data-testid="adding-members">
            {success ? 'Your invite(s) successfully sent!' : 'Add new team members'}
        </h2>
    </>
);
