import React, { useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { options } from './options';

export const AddEntity = ({ entities, addEntity }) => {
    const { closeModal } = useModal();
    const [name, setName] = useState('');
    const [error, setError] = useState(false);
    const [sentiment, setSentiment] = useState(false);
    const [sentementError, setSentementError] = useState(false);
    const handleNameChange = (e) => {
        const { value } = e.target;
        if (entities.find(a => a.entity.toLowerCase() === value?.trim().toLowerCase())) {
            setError('This entity name already exists.');
            setName(value.replace(/:/g, ''));
        } else if (value.length < 71) {
            setName(value.replace(/:/g, ''));
            if (error) { setError(false); }
        }
    };

    const handleSave = () => {
        if (name.length === 0) {
            setError('Entity name is required.');
        }
        if (!sentiment) {
            setSentementError('Selecting sentiment is required.');
        }
        if (name.length && sentiment) {
            addEntity({ entity: name, sentiment: sentiment.key, id: uuid() });
            closeModal();
        }
    };
    return (
        <div>
            <h2 className="m-0">Add new entity</h2>
            <hr />
            <p>
                An entity is a person (e.g. Boris Johnson), location, or organization. These are also known as key players in platform.
            </p>
            <Label>Add entity ({name.length}/70 characters)</Label>
            <Input data-testid="entity-input" type="text" value={name} onChange={handleNameChange} />
            {error && <p className="text-danger mb-0 mt-1">{error}</p>}
            <Dropdown label="Select sentiment"
                className="mt-2"
                options={options[0].options.map(item => ({
                    ...item,
                    key: item.name,
                    name: capitalizeFirstLetter(item.name)
                }))}
                value={sentiment}
                onChange={(val) => {
                    setSentiment(val);
                    if (sentementError) { setSentementError(false); }
                }}
                notSelected="Not selected"
            />
            {sentementError && <p className="text-danger mb-0 mt-1">{sentementError}</p>}
            <hr />
            <Button className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button color="primary" onClick={handleSave} disabled={error || sentementError}>Save</Button>
        </div>
    );
};
