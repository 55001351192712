import * as React from 'react';
import { color } from '../../utils/getColors';

export const NoSearchResults = () => (
    <svg width={72}
        height={80}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill={color.grey[300]}
            d="M10.104 0A4.12 4.12 0 0 0 5.93 4.067v65.764a4.12 4.12 0 0 0 4.175 4.065H60.75a4.118 4.118 0 0 0
            4.175-4.065V10.302L54.177 0H10.105Z"
        />
        <path d="M54.176 0v8.273a2.027 2.027 0 0 0 2.027 2.027h8.726L54.176 0Z"
            fill="#D2D4DB"
        />
        <path fill={color.grey[200]}
            d="M18.973 9.552H2.866A1.997 1.997 0 0 0 .87 11.548v16.107c0 1.103.894 1.997 1.996 1.997h16.107a1.997 1.997 0 0 0
            1.997-1.997V11.548a1.997 1.997 0 0 0-1.997-1.996Z"
        />
        <path fill="#D2D4DB"
            d="M16.683 15.689H5.155a.542.542 0 0 1 0-1.084h11.528a.542.542 0 0 1 0 1.084ZM16.683 18.542H5.155a.542.542 0 0 1
        0-1.084h11.528a.542.542 0 0 1 0 1.084ZM16.683 21.588H5.155a.542.542 0 0 1 0-1.084h11.528a.542.542 0 0 1 0
        1.084ZM16.683 24.44H5.155a.542.542 0 1 1 0-1.084h11.528a.542.542 0 0 1 0 1.084Z"
        />
        <path fill="#C8CACE"
            d="M55.21 42.9a19.407 19.407 0 1 0-26.963 11.173l-1.57 4.632a5.982 5.982 0 0 0-4.985 4.028l-3.13 9.24c-1.048
        3.089.491 6.523 3.55 7.652a5.988 5.988 0 0 0 7.752-3.685l3.17-9.359a5.981 5.981 0 0 0-1.518-6.235l1.57-4.632A19.432
        19.432 0 0 0 55.21 42.9Zm-32.205-9.925a14.308 14.308 0 1 1 8.357 16.922 14.325 14.325 0 0 1-8.357-16.922Z"
        />
        <path fill={color.grey[200]}
            d="M69.133 44.545H53.026a1.997 1.997 0 0 0-1.996 1.997v16.107c0 1.103.894 1.997 1.996 1.997h16.107a1.997 1.997 0 0 0
        1.997-1.997V46.542a1.997 1.997 0 0 0-1.996-1.997Z"
        />
        <path fill="#D2D4DB"
            d="M61.086 48.998a4.437 4.437 0 0 1 2.577.77 2.675 2.675 0 0 1 .52 3.867 6.32 6.32 0 0 1-1.421 1.208 2.385 2.385 0
        0 0-1.017 1.26c-.173.528-.633.734-1.188.621a1.005 1.005 0 0 1-.757-1.023c.012-.476.205-.93.54-1.268.344-.339.712-.652
        1.101-.939.194-.153.379-.317.553-.492.453-.468.572-1.04.34-1.55-.216-.468-.763-.743-1.391-.713a1.44 1.44 0 0
        0-1.362.895 7.3 7.3 0 0 1-.268.538 1 1 0 0 1-1.152.544.867.867 0 0 1-.787-.956 2.353 2.353 0 0 1 .822-1.7 3.952
        3.952 0 0 1 2.89-1.062ZM60.836 60.2a1.25 1.25 0 0 1-.866-2.139 1.249 1.249 0 1 1 .866 2.139Z"
        />
    </svg>
);
