import React from 'react';
import { Button } from 'reactstrap';

type DeleteNarrativeVersionModalProps = {
    onConfirm: () => void,
    onCancel: () => void
}

export const DeleteNarrativeVersionModal = ({ onConfirm, onCancel }: DeleteNarrativeVersionModalProps) => (
    <div>
        <h2 className="mb-2">Are you sure?</h2>
        <hr />
        <p>You are about to delete the selected Narrative Version from your Custom
            Narratives, do you
            want to go ahead with this?
        </p>
        <hr />
        <Button className="mr-2"
            color="secondary"
            onClick={onCancel}
        >Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>Confirm</Button>
    </div>
);
