import { useTopLanguages } from '../../../../../services/Overview';
import { withTableStructure } from './withTableStructure';

const columns = [
    { key: 'language', label: 'Language', type: 'languages' },
    { key: 'count', label: 'Matches' },
    { key: 'threats', label: 'Threats' }
];

const config = {
    api: useTopLanguages,
    apiParams: { includeThreats: true },
    title: ({ preview }) => `Top ${!preview ? '10 ' : ''}Languages`,
    tooltip: `View the top languages being used in content across this room, with a count of the number of matches and
    threats written in that language. Where ‘unknown’ we’re unable to detect the language being used.`,
    columns,
    id: 'topLanguages'
};

export const TopLanguages = withTableStructure(config);
