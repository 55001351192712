import React from 'react';
import noNarratives from '../../../../../../assets/images/no-narratives.png';

export const NoResults = ({ text }) => (
    <div className="px-4 py-5 bg-white rounded">
        <div className="py-3" />
        <div className="minh-200 d-flex align-items-center justify-content-center">
            <div className="py-5">
                <img src={noNarratives} alt="" className="d-block mx-auto" />
                <p className="mt-3 text-center">
                    <strong>{text || 'We are still processing the data, check back later.'}</strong>
                </p>
            </div>
        </div>
        <div className="py-3" />
    </div>
);
