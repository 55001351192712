import React from 'react';

export const Box = ({ children, className = '' }) => (
    <div data-testid="overview-box"
        className={
            `overview-box bg-white position-relative border ${className}`
        }
    >
        {children}
    </div>
);
