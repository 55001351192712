import React from 'react';
import { Button } from 'reactstrap';
import { Star } from 'react-feather';
import { Loading } from '../../../../components/Loading';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { useUpdateClustersWatchlist } from '../../../../services/Clusters/watchlist';

type WatchlistButtonProps = {
    isWatching: boolean
    id: string
    type: 'cluster' | 'subcluster'
    containerId: string
    classnames?: string
}

export const WatchlistButton = ({ isWatching, id, type, containerId, classnames }: WatchlistButtonProps) => {
    const { updateClustersWatchlist, loading: watchlistLoading } = useUpdateClustersWatchlist({
        clusterIds: [id],
        isWatching: !isWatching,
        type
    });
    if (watchlistLoading) {
        return (
            <div className="pr-1">
                <Loading relative iconSize="sm" />
            </div>
        );
    }
    return (
        <TooltipWrapper tooltipText={isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
            id={`${containerId}_watchlist`}
            placement="top-start"
            container={containerId}
        >
            <Button color="link" className={`${classnames} p-0 mw-0`}>
                {isWatching ? (
                    <Star size={24}
                        color="#006FF9"
                        fill="#006FF9"
                        aria-label={isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
                        data-testid="watchlist-star"
                        onClick={() => updateClustersWatchlist()}
                    />
                )
                    : (
                        <Star size={24}
                            color="#006FF9"
                            aria-label={isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
                            data-testid="watchlist-star"
                            onClick={() => updateClustersWatchlist()}
                        />
                    )}
            </Button>
        </TooltipWrapper>
    );
};
