import { isNil, omitBy } from 'lodash';
import React, { useState } from 'react';
import { Search, X } from 'react-feather';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Dropdown } from '../../../components/Form/Dropdown';
import { FilterByClient } from './FilterByClient';
import { FilterByStatus } from './FilterByStatus';
import { useSituationRooms } from './SituationRoomsContext';
import { color } from '../../../utils/getColors';

export const SearchAndFilter = () => {
    const { filters, setFilters } = useSituationRooms();
    const [searchValue, setSearchValue] = useState('');
    const [searchBy, setSearchBy] = useState({ key: 'project_name', name: 'Room name' });

    const handleStatusFilterChange = (status) => {
        const statusIds = status.map(stat => stat.id);
        const statusFilters = {
            action: statusIds.includes('active') ? null : 'delete',
            archived: statusIds.includes('archived') ? null : false,
            deleted: statusIds.includes('deleted') ? null : false,
            paused: statusIds.includes('paused') ? null : false
        };
        setFilters(omitBy({
            ...filters,
            ...statusFilters
        }, isNil));
    };
    const onResetQuery = () => {
        setSearchValue('');
        setFilters(omitBy({ ...filters, [searchBy.key]: null }, isNil));
    };
    return (
        <div className="mb-4 d-flex align-items-center flex-wrap">
            <form className="mr-2 d-block flex-1 mw-300"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (searchValue) {
                        setFilters({ ...filters, [searchBy.key]: searchValue });
                    } else {
                        setFilters({ ...filters, [searchBy.key]: undefined });
                    }
                }}
            >
                <InputGroup border="none" className="mb-0 bg-white">
                    <Input value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search Situation room"
                    />
                    {searchValue && (
                        <InputGroupAddon addonType="prepend" className="border-0">
                            <InputGroupText role="button" onClick={onResetQuery} data-testid="x-icon" className="border-0">
                                <X color={color.blue[500]} size={16} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                    <InputGroupAddon addonType="append">
                        <Dropdown value={searchBy}
                            onChange={setSearchBy}
                            options={[
                                { key: 'project_name', name: 'Room name' },
                                { key: 'project_id', name: 'Project ID' }
                            ]}
                            className="border-right mr-11 pr-11"
                        />
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                        <InputGroupText tag="button" type="submit" data-testid="submit-room-search" className="border-0 bg-white">
                            <Search size={16} color={color.blue[500]} />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </form>
            <FilterByClient onChange={(instances) => setFilters({ ...filters, instance: instances.map(instance => instance.id) })} />
            <FilterByStatus onChange={handleStatusFilterChange} />
        </div>
    );
};
