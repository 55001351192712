import React from 'react';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { BackButton } from '../../../../components/Atoms';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { useModal } from '../../../../context/modal/ModalComponent';
import { EditRoomDetails } from './EditRoomDetails';
import { EditRoomNameModal } from './EditRoomNameModal';
import { useRoom } from './RoomContext';
import { RoomUpdates } from './RoomUpdates';

export const RoomInner = () => {
    const { room, setRoom, saveRoom } = useRoom();
    const { setModal } = useModal();

    return (
        <div className="p-4">
            <BreadCrumbs items={[
                { title: 'Admin home', href: '/admin' },
                { title: 'Situation rooms', href: '/admin/situation-rooms' },
                { title: room.project_name }
            ]}
            />
            <BackButton pathName="/admin/situation-rooms" />
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                <h2 className="m-0 d-flex align-items-center line-height-1">
                    {room.project_name}
                    <Edit className="text-primary ml-2"
                        role="button"
                        data-testid="edit-room-button"
                        onClick={() => setModal({
                            component: <EditRoomNameModal title={room.project_name}
                                id={room.id}
                                onChange={(title) => {
                                    setRoom({ ...room, project_name: title });
                                    saveRoom({ ...room, project_name: title });
                                }}
                            />
                        })}
                    />
                </h2>
                <Link to={`/situation-rooms/${room.id}`}>View situation room</Link>
            </div>
            <EditRoomDetails />
            <RoomUpdates id={room.id} />
        </div>
    );
};
