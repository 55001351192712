import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';

export const ConfirmCloseModal = ({
    saveAsDraft,
    edit,
    goBack,
    disabled
}) => {
    const { closeModal } = useModal();
    const saveDraft = () => {
        saveAsDraft();
        closeModal();
    };
    return (
        <div>
            <h2>Are you sure want to exit the screen?</h2>
            <hr />
            {edit ? (
                <div>
                    <p>Changes you made will not be saved.</p>
                    <hr />
                    <Button className="mr-2"
                        color="secondary"
                        onClick={() => {
                            closeModal();
                            goBack();
                        }}
                    >
                        Yes, exit
                    </Button>
                    <Button color="primary" onClick={saveDraft} disabled={disabled}>
                        Save as Draft
                    </Button>
                    <Button color="link" onClick={closeModal}>No, back to editing</Button>
                </div>
            ) : (
                <>
                    <p className="">Situation room data will not be saved.</p>
                    <hr />
                    <div>
                        <Button color="primary"
                            onClick={() => {
                                closeModal();
                                goBack();
                            }}
                        >
                            Yes, exit
                        </Button>
                        <Button color="secondary" onClick={saveDraft} disabled={disabled} className="mx-2">
                            Save as Draft
                        </Button>
                        <Button color="link" onClick={closeModal}>No, back to create</Button>
                    </div>
                </>
            )}
        </div>
    );
};
