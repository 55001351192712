import React from 'react';
import { Button } from 'reactstrap';
import { ContentTableContentType } from './index';

type MarkAsNotThreatButtonProps = {
    selectedContentItems: ContentTableContentType[]
    disabled: boolean
    onMarkAsNotThreat?: (ids: string[]) => void
}

export const MarkAsNotThreatButton = ({
    selectedContentItems,
    disabled,
    onMarkAsNotThreat
}: MarkAsNotThreatButtonProps) => {
    const selectedItemContainsNonThreats = selectedContentItems.find(content => content.threatObject?.threatLevel === 'none');

    if (selectedItemContainsNonThreats || !onMarkAsNotThreat) return null;

    return (
        <Button className="mr-1"
            color="primary"
            disabled={disabled}
            onClick={() => onMarkAsNotThreat(selectedContentItems.map(({ id }) => id))}
        >
            Mark as not threat
        </Button>
    );
};
