import React from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { DELETE_INVESTIGATION } from '../../../../graphql';
import { useModal } from '../../../../context/modal/ModalComponent';
import investigationDeleted from '../../../../assets/images/investigations/investigationDeleted.svg';
import { getToast } from '../../../../utils/getToast';

export const DetailsHeader = ({ detailsId }) => {
    const { setModal, closeModal } = useModal();
    const toast = getToast();
    const [deleteInvestigation] = useMutation(DELETE_INVESTIGATION, {
        onCompleted: () => {
            setModal({
                component: (
                    <>
                        <img src={investigationDeleted} alt="" width="100" />
                        <h3>Investigation deleted successfully</h3>
                        <hr className="w-100" />
                        <Button size="sm"
                            color="secondary"
                            onClick={() => window.location.replace(`${window.location.origin}/admin/investigations`)}
                        >Close
                        </Button>
                    </>
                )
            });
        },
        onError: () => {
            closeModal();
            toast.error('Investigation not saved, Please try again');
        }
    });
    const setDeleteModal = () => {
        setModal({
            component: (
                <div>
                    <h3>Are you sure you want to delete investigation?</h3>
                    <hr className="w-100" />
                    <p>It will be permanently deleted</p>
                    <hr className="w-100" />
                    <Button color="secondary" className="mr-2" onClick={() => closeModal()}>Cancel</Button>
                    <Button color="danger"
                        onClick={() => { closeModal(); deleteInvestigation({ variables: { id: detailsId } });
                        }}
                    >
                        Delete
                    </Button>
                </div>
            ),
        });
    };

    return (
        <p className="text-danger m-0 cursor-pointer" onClick={setDeleteModal}>Delete investigation</p>
    );
};
