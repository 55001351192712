import React, { useState } from 'react';
import { Button, Form, Label } from 'reactstrap';
import { useLazyQuery, useQuery } from '@apollo/client';
import { client } from '../../auth0';
import { GET_CONNECTION_DETAILS } from '.';
import { Input } from '../../components/Form/Input';
import { getParam } from '../../utils/urlParams';
import { gql } from '../../generated';

type SSOLoginProps = {
    setIsSSOLogin: (isSSO: boolean) => void
}

export const SSOLogin = ({ setIsSSOLogin }: SSOLoginProps) => {
    const [email, setEmail] = useState(getParam('ssoemail') || '');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');

    const [getConnectionDetails, { loading, data }] = useLazyQuery(GET_CONNECTION_DETAILS);

    const connectionName = data?.getConnectionDetails?.connectionName || getParam('ssocn') || '';

    const { data: connectionInstance, loading: connectionInstanceLoading } = useQuery(GET_CONNECTION_INSTANCE, {
        variables: { connectionName },
        skip: !connectionName,
        onCompleted: () => {
            loginWithCustomConnection();
        }
    });

    const instanceId = connectionInstance?.getConnectionInstance || '';

    const checkEmailFormat = (e: React.FormEvent<HTMLFormElement>, emailCheckOnly?: string) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        e.preventDefault();
        if (!email) {
            setEmailError('Missing email, please enter your email.');
        }
        else if (email.indexOf('@') === -1) {
            setEmailError('This is an invalid email, emails should contain an "@" and a domain.');
        }
        else if (email.indexOf('.') === -1) {
            setEmailError('Email must contain a "." after the "@" symbol.');
        }
        else if (!pattern.test(email)) {
            setEmailError('Invalid email address format.');
        }
        else if (!emailCheckOnly) authenticateEmail();
    };

    const authenticateEmail = () => {
        if (/\S+@\S+\.\S+/.test(email)) {
            getConnectionDetails({
                variables: { email }
            });
        } else {
            setError('Please enter a valid email address');
        }
    };
    const loginWithCustomConnection = () => {
        if (!instanceId || !connectionName) return;
        const redirectUrl = `${window.location.origin}/sso-auth?ssoInstanceId=${instanceId}&ssocn=${connectionName}`;
        client.authorize({
            connection: connectionName,
            responseType: 'token id_token',
            scope: 'openid profile email',
            redirectUri: redirectUrl,
        });
    };
    return (
        <Form onSubmit={checkEmailFormat} autoComplete="false">
            <Label className="mb-1">Email</Label>
            <Input value={email}
                onChange={(e) => { setEmail(e.target.value); setError(''); setEmailError(''); }}
                onBlur={(e) => checkEmailFormat(e, 'emailCheckOnly')}
                type="text"
                error={emailError}
                state={(error || emailError) ? 'error' : undefined}
                data-testId="emailInput"
            />
            {error ? (
                <p className="text-danger my-1">
                    {typeof error === 'string' ? error : 'Something went wrong, please try again.'}
                </p>
            ) : null }
            <div className="d-flex align-items-center justify-content-between mt-2">
                <Button color="link" className="pl-0" onClick={() => setIsSSOLogin(false)}>Login with a password</Button>
                <Button type="submit"
                    color="primary"
                    disabled={Boolean(loading || error || connectionInstanceLoading)}
                >Continue
                </Button>
            </div>
        </Form>
    );
};

export const GET_CONNECTION_INSTANCE = gql(`
    query getConnectionInstance($connectionName: String!) {
        getConnectionInstance(connectionName: $connectionName)
    }
`);
