import { gql, useQuery } from '@apollo/client';

export const useDictionary = ({ variables, skip }) => {
    const { loading, data } = useQuery(DICTIONARY, {
        variables,
        skip
    });
    const ret = data?.dictionary || {};
    return {
        loading,
        data: ret
    };
};

export const DICTIONARY = gql`
    query dictionary($keyword: String, $lang: String) {
        dictionary(keyword: $keyword, lang: $lang) {
            antonyms
            leetspeak
            lemmas
            synonyms
        }
    }
`;
