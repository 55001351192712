import React, { useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { ChevronLeft, ChevronRight } from 'react-feather';
import SplitPane from 'react-split-pane';
import { useRoomEditor } from './RoomEditorContext';

export const SplitView = ({ left, right, activeTab }) => {
    const [size, setSize] = useState({ value: '35%' });
    const { room, setRoom } = useRoomEditor();

    const onDragEnd = ({ source, destination, draggableId }) => {
        if (!source || !destination) { return null; }
        if (source.droppableId === 'keywords') {
            const newItem = JSON.parse(draggableId);
            setRoom({
                ...room,
                query: {
                    ...room.query,
                    [destination.droppableId]: [...room.query[destination.droppableId], newItem]
                }
            });
            return null;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                room.query[source.droppableId],
                source.index,
                destination.index
            );

            setRoom({
                ...room,
                query: {
                    ...room.query,
                    [source.droppableId]: items
                }
            });
        } else {
            const result = move(
                room.query[source.droppableId],
                room.query[destination.droppableId],
                source,
                destination
            );
            setRoom({
                ...room,
                query: {
                    ...room.query,
                    ...result
                }
            });
        }
    };
    const checkSize = () => {
        const sizeValue = parseInt((size.value).split('.')[0], 10);
        return sizeValue <= 10;
    };

    const onSizeChange = (e) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setSize({ value: e[1] }), 500);
    };
    const timerRef = useRef(null);
    const isSplitPane = activeTab !== 'room-details';
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <SplitPane split="vertical"
                className="split-view"
                onChange={(e) => onSizeChange(e)}
            >
                <div className={`${isSplitPane ? 'pl-4 pb-4 position-relative h-100' : 'p-4 position-relative h-100'} left-container`}>
                    {isSplitPane && (
                        <div className="position-relative top-right-new p-1">
                            <div className="py-0 px-11 text-primary mw-0 hover-white square-icon-button ml-auto "
                                onClick={(e) => { setSize({ value: parseInt((size.value).split('.')[0], 10) <= 10 ? '35%' : '0%' });
                                    e.currentTarget.blur(); }}
                                id="Tooltip-collapse-arrow"
                                data-testid="collapse-arrow"
                            >
                                {checkSize()
                                    ? (
                                        <p className="d-flex text-primary cursor-pointer">
                                            Collapse
                                            <span className="ml-1">
                                                <ChevronLeft size={20} />
                                            </span>
                                        </p>
                                    )
                                    : (
                                        <p className="d-flex text-primary cursor-pointer">
                                            Expand
                                            <span className="ml-1">
                                                <ChevronRight size={20} />
                                            </span>
                                        </p>
                                    )}
                            </div>
                        </div>
                    )}
                    {left}
                </div>
                {isSplitPane && (
                    <div className="h-100" size={size.value}>
                        {right}
                    </div>
                )}
            </SplitPane>
        </DragDropContext>
    );
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
