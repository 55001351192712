import { useMutation } from '@apollo/client';
import { CREATE_REPORT } from '../../pages/Room/Reports/graphql';

export const useCreateReport = () => {
    const [createReport, { data, loading }] = useMutation(CREATE_REPORT, {
        refetchQueries: ['getReports']
    });
    return {
        createReport: (variables) => createReport({ variables }),
        data: data?.createReport,
        loading
    };
};
