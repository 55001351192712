import { useKeywordFrequency } from './useKeywordFrequency';
import { withTable } from './withTable';

const columns = [
    { key: 'keyword', label: 'Keywords', className: 'dont-break-out' },
    { key: 'threats', label: 'Threats', className: 'pl-0' },
    { key: 'count', label: 'Matches', className: 'px-0' }
];

export const KeywordFrequency = withTable({
    columns,
    api: useKeywordFrequency,
});
