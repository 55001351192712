import { useMutation } from '@apollo/client';
import React from 'react';
import { Button } from 'reactstrap';
import { DELETE_USER } from '../../../../graphql';
import { RemoveUserSuccessModal } from './RemovedUserSuccessModal';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useGetActiveQueries } from '../../../../services/getActiveQueries';

export const RemoveUserModal = ({ userData, closeRemoveModal }) => {
    const { setModal, closeModal } = useModal();
    const refetchQueries = useGetActiveQueries(['allUsers']);
    const [deleteUser, { loading: deleteUserLoading }] = useMutation(DELETE_USER, {
        refetchQueries,
        onCompleted: () => {
            closeRemoveModal();
            setModal({
                component: <RemoveUserSuccessModal closeModal={closeModal}
                    userName={userData.displayName || 'User'}
                />
            });
        }
    });
    const handleDeleteUser = () => deleteUser({
        variables: {
            id: userData.id,
            uid: userData.uid
        },
    });
    return (
        <>
            <h2>Are you sure you want to delete {userData.displayName || 'this user'}?</h2>
            <hr />
            <p>It will be permanently deleted.</p>
            <hr />
            <Button onClick={() => closeRemoveModal()}>Close</Button>
            <Button className="ml-2"
                color="danger"
                disabled={deleteUserLoading}
                onClick={handleDeleteUser}
            >Delete
            </Button>
        </>
    );
};
