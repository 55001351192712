import React from 'react';
import { Upload, X } from 'react-feather';

export const AddLogo = ({ hasDarkBg, handleChange, logo, pdf }) => {
    const handleDrop = acceptedFiles => {
        if (acceptedFiles[0]) {
            handleChange(URL.createObjectURL(acceptedFiles[0]));
        }
    };

    return (
        <div className=" d-flex position-relative">
            <div className="position-relative">
                {!pdf && (
                    <input accept="image/* "
                        multiple=""
                        type="file"
                        autoComplete="off"
                        tabIndex="-1"
                        className="position-absolute h-100 w-100 z-index opacity-0"
                        onChange={(e) => handleDrop(e.target.files)}
                    />
                )}
                {logo ? (
                    <img src={logo}
                        alt="uploaded-logo"
                        style={{ maxWidth: 150, maxHeight: 100 }}
                    />
                )
                    : !pdf && (
                        <div className="p-4 border-dashed position-relative d-flex align-items-center justify-content-center"
                            role="button"
                        >
                            <Upload className={`${hasDarkBg ? 'text-white' : 'text-secondary'}`} />
                            <p className={`${hasDarkBg ? 'text-white my-0 ml-3 ' : 'my-0 ml-3  text-secondary'}`}>Upload logo</p>
                        </div>
                    )}
            </div>
            {(logo && !pdf) ? (
                <div className="ml-2 cursor-pointer" onClick={() => handleChange('')}>
                    <X className={`${hasDarkBg ? 'text-white' : 'text-secondary'}`} />
                </div>
            ) : null}
        </div>

    );
};
