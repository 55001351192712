import React, { useState } from 'react';
import {
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { X as Close } from 'react-feather';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { getRoom } from '../../../utils/variables';
import { Loading } from '../../Loading';
import { CheckboxFilter } from '../../Filters';
import { GET_LOCATIONS } from '../../../graphql';
import { TooltipItem } from '../../ToolTip';
import { color } from '../../../utils/getColors';

export const OriginsFilter = ({ selected, setSelected }) => {
    const location = useLocation();
    const room = getRoom();
    const { data, loading } = useQuery(GET_LOCATIONS, {
        variables: {
            projectId: room.project_id,
            threats: location.pathname?.includes('/threats'),
            size: 1000
        }
    });
    const [val, setVal] = useState('');
    const locations = data?.getLocations || [];
    return (
        <div>
            <span className="d-flex">
                <p className="font-weight-bold">Location mention</p>
                <TooltipItem placement="right"
                    id="location-filters"
                    content="Locations mentioned in the content."
                    testid="location-filters-tooltip"
                />
            </span>
            <FormGroup className={(val?.length === 0 && selected?.length === 0) ? 'mb-0' : ''}>
                <InputGroup border={val ? 'active' : 'none'}>
                    <Input placeholder="Search for Location" value={val} onChange={(e) => setVal(e.target.value)} />
                    {val && (
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText data-testid="search-input-close"
                                role="button"
                                onClick={() => setVal('')}
                            >
                                <Close color={color.blue[500]} size={16} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                </InputGroup>
            </FormGroup>
            {loading && <Loading height={100} relative />}
            <div className="scrollbar-small overflow-y-auto overflow-x-hidden maxh-350 py-1 ">
                <CheckboxFilter values={locations.filter(loc => !selected.includes(loc))}
                    filters={locations.filter(loc => {
                        if (!val) { return loc; }
                        return !loc.toLowerCase().search(val.toLowerCase());
                    }).map((label) => ({ key: label, label }))}
                    value="locations"
                    setValues={(newSelected) => setSelected(locations.filter(loc => !newSelected.includes(loc)))}
                />
            </div>
        </div>
    );
};
