import { useMutation } from '@apollo/client';
import { getRoom } from '../../../../utils/variables';
import { GET_REMOVED_CONTENT, RECOVER_CONTENT } from '../../../../graphql';

export const useRecoverContent = ({ onCompleted, refetchQueries } = {}) => {
    const room = getRoom();
    const defaultRefetchQueries = [GET_REMOVED_CONTENT];

    const [recoverContentRequest, { loading, error }] = useMutation(RECOVER_CONTENT, {
        refetchQueries: refetchQueries !== undefined ? refetchQueries : defaultRefetchQueries,
        onCompleted: () => {
            if (onCompleted) onCompleted();
        },
        fetchPolicy: 'no-cache',
    });

    const recoverContent = (ids) => recoverContentRequest({
        variables: {
            projectId: room?.project_id,
            docIds: ids
        }
    });

    return {
        recoverContent,
        loading,
        error
    };
};
