import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Images } from '../utils/modeSwitch';

type Action = {
    name: string;
    color: string;
} & ({ to: string; } | { onClick: () => void; })

type EmptyStateProps = {
    title: string;
    summary?: string;
    icon?: string;
    action?: Action;
    svgIcon?: React.ReactNode;
}

export const EmptyState = ({ title, summary, icon, action, svgIcon }: EmptyStateProps) => (
    <div className="py-7 px-3 d-flex justify-content-center bg-white text-center">
        <div className="maxw-500">
            {icon && <img src={icon} alt="" className="mb-2" />}
            {svgIcon ? <div className="mb-2">{svgIcon}</div> : ''}
            <h2 className="m-0">{title}</h2>
            {summary && <p className="mt-2 b-0">{summary}</p>}
            {action && <ActionButton action={action} />}
        </div>
    </div>
);

export const NoDataAvailableState = ({ text = 'No data available', subText }: { text?: string, subText?: React.ReactNode }) => (
    <div className="py-2 px-3 d-flex justify-content-center bg-verylight text-center">
        <div className="maxw-500">
            <img src={Images().metrics} alt="" className="mb-2" />
            <h2 className="m-0">{text}</h2>
            {subText}
        </div>
    </div>
);

const ActionButton = ({ action }: { action: Action }) => {
    const history = useHistory();

    return (
        <Button className="mt-2"
            onClick={'to' in action ? () => {
                history.push(action.to);
            } : action.onClick}
            color={action.color || 'primary'}
        >
            {action.name}
        </Button>
    );
};
