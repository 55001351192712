import { Button } from 'reactstrap';
import React from 'react';
import { X } from 'react-feather';
import { useModal } from '../../../../context/modal/ModalComponent';

export const BatchRecoverNoContentsModal = () => {
    const { closeModal } = useModal();

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">No content to recover</h2>
                <X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={closeModal}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <div>
                <p>
                    There is no content to recover.
                    It may be because someone recovered the content in the meantime.
                    Please refresh the page and try again
                </p>
            </div>
            <Button className="mr-2" color="secondary" onClick={closeModal}>Close</Button>
        </div>
    );
};
