import { convertToMarkers } from '../convertToMarkers';

export const forwardSlashValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];
    const result = query.replace(/\b(?:http|https):\/\/[^\s]+/g, match => 'x'.repeat(match.length))
        .replace(/(url|links):\s*([^\s]+)/g, match => 'x'.repeat(match.length));

    if (!result || result.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }
    [...result.matchAll(/(?<!NEAR)\/+/g)].forEach((match) => {
        highlight.push([match.index, match.index + 1]);
    });
    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You cannot use any of the following special characters within a Boolean, !, —, / or ^.'
        )
    };
};
