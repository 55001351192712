import React from 'react';
import { Button } from 'reactstrap';
import { useLocation } from 'react-router-dom/';
import { getHighlightedContent } from '../../utils/getHighlightedContent';
import { truncateString } from '../../utils/text';
import { TranslatedFromLanguage } from '../../utils/TranslatedFromLanguage';
import { getRoom } from '../../utils/variables';

type ContentTextProps = {
    text: string,
    textLimit: number,
    showLanguages: boolean,
    langVerbose?: string | undefined,
    translateLoading: boolean
    detectedLangs?: string[]
}

export const ContentText = ({ text, textLimit, showLanguages, langVerbose, translateLoading, detectedLangs = [] }: ContentTextProps) => {
    const room = getRoom();
    const location = useLocation();
    const isNotSituationRoom = location?.pathname?.includes('create-room') || location?.pathname?.includes('edit-room')
     || location?.pathname?.includes('edit-draft-room');

    return (
        <div>
            <p className="highlight-em dont-break-out d-inline">
                {showLanguages && !translateLoading && (
                    <TranslatedFromLanguage languageVerbose={langVerbose} detectedLangs={detectedLangs} />
                )}
                {getHighlightedContent(truncateString(text, textLimit))}&nbsp;
                {(text.length > textLimit && room?.id && !isNotSituationRoom) ? (
                    <Button color="link" className="p-0 mw-0">Read more</Button>
                ) : null}
            </p>
        </div>
    );
};
