/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { X } from 'react-feather';
import { ProgressBar } from '../../../ProgressBar';
import { getRoom } from '../../../../utils/variables';
import { BatchRecoverCancelledModal } from './BatchRecoverCancelledModal';
import { BatchRecoverNoContentsModal } from './BatchRecoverNoContentsModal';
import { useModal } from '../../../../context/modal/ModalComponent';
import { BatchRecoverPreparingModal } from './BatchRecoverPreparingModal';
import { useGetRemovedContentLazy } from '../../../../services/Content/getRemovedContent';
import { useRecoverContent } from './useRecoverContent';

export const BatchRecoverModal = ({ onCompleted, onAbort, searchFilters, offset, sort } = {}) => {
    const { closeModal } = useModal();
    const [timeStart, setTimeStart] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [firstFetch, setFirstFetch] = useState(false);
    const [lastContent, setLastContent] = useState('[]');
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [recoveryPending, setRecoveryPending] = useState(false);
    const { getRemovedContent, data: content, count: currentTotal, loading: fetchPending } = useGetRemovedContentLazy({
        searchFilters,
        offset,
        sort,
        limit: 100
    });

    const { recoverContent, loading: recoverRequestProcessing } = useRecoverContent({
        refetchQueries: []
    });

    const handleAbort = () => onAbort();
    const handleCancel = () => {
        if (totalDocuments === 0) return closeModal();
        setIsPaused(true);
    };
    const handleCompleted = () => onCompleted({ total: totalDocuments, timeInSeconds: (moment.now() - timeStart) / 1000 });

    const contentJson = useCallback(() => JSON.stringify(content.map(({ doc_id: docId }) => docId)), [content])();
    const isCompleted = firstFetch && !fetchPending && !isPaused && !recoveryPending && currentTotal === 0 && content.length === 0;
    const dataStateChanged = !fetchPending && currentTotal > totalDocuments;
    const pausePending = isPaused && recoveryPending;

    // Initial data fetch
    useEffect(() => {
        if (!firstFetch) {
            setTimeStart(moment.now());
            setFirstFetch(true);
            getRemovedContent();
        // Sets initial data and prevents negative progress when new data appears faster than we remove
        } else if (dataStateChanged) {
            setTotalDocuments(currentTotal);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTotal, dataStateChanged, firstFetch]);

    // Waiting for the data state to change (either removed by this process, other user or new data appears)
    useEffect(() => {
        if (recoveryPending && !fetchPending && contentJson === lastContent) {
            setTimeout(() => {
                getRemovedContent();
            }, 2000);
        } else if (recoveryPending && !fetchPending && !recoverRequestProcessing && contentJson !== lastContent) {
            setRecoveryPending(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentJson, fetchPending, lastContent, recoveryPending, recoverRequestProcessing]);

    // Remove batch logic
    useEffect(() => {
        if (isCompleted) {
            handleCompleted();
        } else if (contentJson !== lastContent && !fetchPending && !isPaused && !recoveryPending && content.length > 0) {
            const contentIds = content.map(({ doc_id: docId }) => docId);
            // Limits the risk of removing the same data twice due to race condition
            setLastContent(JSON.stringify(contentIds));
            setRecoveryPending(true);
            recoverContent(contentIds);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, contentJson, fetchPending, isCompleted, isPaused, lastContent, recoveryPending, timeStart, totalDocuments]);

    if (firstFetch && !fetchPending && totalDocuments === 0 && currentTotal === 0) {
        return <BatchRecoverNoContentsModal />;
    }

    if (totalDocuments === 0) {
        return <BatchRecoverPreparingModal handleCancel={handleCancel} />;
    }

    if (isPaused && !recoveryPending && !fetchPending) {
        return <BatchRecoverCancelledModal onResume={() => setIsPaused(false)} onAbort={handleAbort} contentsToProcess={currentTotal} />;
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">In progress</h2>
                <X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={handleCancel}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <BatchRecoverModalContent totalDocuments={totalDocuments} currentTotal={currentTotal} pausePending={pausePending} />
            <hr className="my-2" />
            <Button className="mr-2" color="secondary" disabled={pausePending} onClick={handleCancel}>Cancel</Button>
        </>
    );
};

const BatchRecoverModalContent = ({ totalDocuments, currentTotal, pausePending } = {}) => {
    const room = getRoom();
    const removedCount = totalDocuments > 0 ? totalDocuments - currentTotal : 0;
    let description = 'Bulk recovery in progress';

    if (pausePending) {
        description = 'Cancelling, please wait';
    }

    return (
        <>
            <div className="mb-1">
                <FontAwesomeIcon icon={faSpinner} className="mr-1 fa-spin" /> {description}
            </div>
            <ProgressBar max={totalDocuments} current={removedCount < 0 ? 0 : removedCount} />
            <p className="mt-1">There are {currentTotal} contents left to be recovered back to {room.project_name}</p>
        </>
    );
};
