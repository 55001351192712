import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, CustomInput, Spinner } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import _ from 'lodash';
import { allTypes } from '../../../../utils/lib/search';
import { ThreatSignalTooltip } from './threatSignalTooltip';
import { useModal } from '../../../../context/modal/ModalComponent';
import { color } from '../../../../utils/getColors';
import { RemoveAllThreatsModal } from './RemoveAllThreatsModal';
import { useUpdateThreatSignals } from './useUpdateThreatSignals';
import { ContentTableContentType } from '..';
import { getRoom } from '../../../../utils/variables';
import { getToast } from '../../../../utils/getToast';
import { useUser } from '../../../../context/user/UserContext';
import { LikelihoodContextModal } from './LikelihoodContextModal';
import { TooltipItem } from '../../../ToolTip';
import { ResetThreatsModal } from '../../../../pages/Room/Threats/Threat/ThreatPageCards/ResetThreatsModal';

type ManageThreatsModalProps = {
    selectedContents: ContentTableContentType[],
    clearSelection: () => void,
    handleClose?: () => void,
    summary?: () => void,
    isMatch?: boolean,
    singleContent?: boolean
}

export type AddedSignal = {
    signalKey: string,
    userName: string
}

export const ManageThreatsModal = ({ selectedContents = [], clearSelection, handleClose, summary,
    isMatch = false, singleContent = false }: ManageThreatsModalProps) => {
    const { closeModal, setModal } = useModal();
    const checkboxRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
    const room = getRoom();
    const toast = getToast();
    const { user } = useUser();
    const [addedSignals, setAddedSignals] = useState([] as AddedSignal[]);
    const [removedSignals, setRemovedSignals] = useState([] as string[]);
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const { updateThreatSignals, loading } = useUpdateThreatSignals({ clearSelection,
        isMatch,
        addedSignals: addedSignals.map((a) => a.signalKey),
        ids: selectedContents.map(a => a.id) });

    const selectedList = [] as { signalName: string, isResolver: boolean, key: string }[];
    let originalKeyList = [] as {contentId: string, keyList: string[],
         customLabelsList: string[],
         removedlabelsList: ContentTableContentType['threatObject']['removedThreatLabels']}[];
    if (!isMatch) {
        allTypes.forEach(type => {
            if (!disabledThreatTypes.includes(type.key)) {
                const key = type.isResolver ? 'resolver' : 'type';
                return selectedContents.forEach((content) => {
                    const { threatResolvers, customThreatLabels, removedThreatLabels } = content.threatObject;
                    threatResolvers?.forEach((c: ContentTableContentType['threatObject']['threatResolvers']) => {
                        if ((c[key] === type.key)) {
                            if ((removedThreatLabels ? !removedThreatLabels.includes(type.key) : true)) {
                                const item = { signalName: '', isResolver: false, key: '' };
                                item.signalName = type.name;
                                item.isResolver = type.isResolver || false;
                                item.key = type.key;
                                selectedList.push(item);
                            }
                            const itemPresent = originalKeyList.find((a) => a.contentId === content.id);
                            if (itemPresent) {
                                originalKeyList = originalKeyList.map(a =>
                                    (a.contentId === content.id
                                        ? {
                                            ...a,
                                            keyList: [...a.keyList, type.key],
                                            customLabelsList: customThreatLabels ? customThreatLabels.map((item) => item.signalKey) : []
                                        }
                                        : a));
                            } else {
                                originalKeyList = [...originalKeyList, { contentId: content.id,
                                    keyList: [type.key],
                                    customLabelsList: customThreatLabels ? customThreatLabels.map((item) => item.signalKey) : [],
                                    removedlabelsList: removedThreatLabels || [] }];
                            }
                        }
                    });

                    if (customThreatLabels && (customThreatLabels.map(a => a.signalKey)).includes(type.key)) {
                        const item = { signalName: '', isResolver: false, key: '' };
                        item.signalName = type.name;
                        item.isResolver = type.isResolver || false;
                        item.key = type.key;
                        selectedList.push(item);
                    }
                });
            }
        });
    }

    const filteredTypes = allTypes.filter((a) => !disabledThreatTypes.includes(a.key));
    const handleOnChange = (signalKey: string, isChecked: boolean) => {
        const addedKeys = addedSignals.map(a => a.signalKey);
        if (removedSignals.includes(signalKey)) {
            const filteredItems = removedSignals.filter((a) => a !== signalKey);
            setRemovedSignals(filteredItems);
        } else if (addedKeys.includes(signalKey)) {
            const filteredItems = addedSignals.filter((a) => a.signalKey !== signalKey);
            setAddedSignals(filteredItems);
        } else if (isChecked) {
            setRemovedSignals([...removedSignals, signalKey]);
        } else {
            setAddedSignals([...addedSignals, { signalKey, userName: user?.displayName || 'Not available' }]);
        }
    };

    const handleUpdateThreatSignals = () => {
        if (removedSignals.length > 0 && addedSignals.length === 0) {
            let removeContentIds = [] as string[];
            originalKeyList.forEach((a) => {
                const finalRemovedSignals = [...a.removedlabelsList, ...removedSignals];
                const finalAddedSignalsList = [...a.keyList, ...a.customLabelsList];
                if (_.every(finalAddedSignalsList, (item) => _.includes(finalRemovedSignals, item))) {
                    removeContentIds = [...removeContentIds, a.contentId];
                }
            });
            if (removeContentIds.length > 0) {
                return setModal({
                    header: 'Remove threats?',
                    component: <RemoveAllThreatsModal
                        clearSelection={clearSelection}
                        addedSignals={addedSignals}
                        removedSignals={removedSignals}
                        contentIds={selectedContents.map((content) => content.id)}
                        removeContentIds={removeContentIds}
                        projectId={room?.project_id}
                    />
                });
            }
        }
        return updateThreatSignals({
            variables: {
                projectId: room.project_id,
                contentIds: selectedContents.map((a) => a.id),
                addedThreatSignals: addedSignals,
                removedThreatSignals: removedSignals
            }
        });
    };

    const handleOnSave = () => {
        if (addedSignals.length === 0 && removedSignals.length === 0) {
            return toast.error('Update threats to save');
        }

        const currentCheckedList = selectedList.filter(a => !removedSignals.includes(a.key));
        if ((currentCheckedList.length === 0) && !isMatch && addedSignals.length === 0 && singleContent) {
            return setModal({
                header: 'Remove all threats?',
                component: <RemoveAllThreatsModal
                    clearSelection={clearSelection}
                    addedSignals={addedSignals}
                    removedSignals={removedSignals}
                    contentIds={selectedContents.map((a) => a.id)}
                    projectId={room?.project_id}
                />
            });
        }
        return handleUpdateThreatSignals();
    };
    const handleReset = () => {
        setModal({
            header: 'Reset threats?',
            component: <ResetThreatsModal contentIds={selectedContents.map((a) => a.id)}
                threatsReset
                clearSelection={clearSelection}
                projectId={room?.project_id}
            />
        });
    };
    const handleOnClose = () => {
        if (!summary) {
            closeModal();
        } else {
            summary();
        }
    };
    const handleOnClick = () => {
        setModal({
            header: 'How do we calculate likelihood?',
            component: <LikelihoodContextModal />
        });
    };
    const addedKeys = addedSignals.length > 0 ? addedSignals.map((a) => a.signalKey) : [];
    useEffect(() => {
        filteredTypes.forEach(signal => {
            const contentSignal = selectedList.find((a) => a.signalName === signal.name);
            const isChecked = (!!contentSignal || addedKeys.includes(signal.key)) && !removedSignals.includes(signal.key);
            let matchedAllContent = true;
            originalKeyList.forEach((a) => {
                if (!a.keyList.includes(signal.key) && !a.customLabelsList.includes(signal.key)) {
                    matchedAllContent = false;
                }
            });
            const isIndeterminate = isChecked && !matchedAllContent;
            const checkbox = checkboxRefs.current[signal.key];
            if (checkbox) {
                checkbox.indeterminate = isIndeterminate;
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTypes, addedSignals, removedSignals]);

    return (
        <>
            <div className="d-flex justify-content-between mb-1">
                <h2 className="mt-0 mb-0">Manage threats
                    <TooltipItem
                        id="manage-threat-signals-tooltip"
                        placement="bottom-start"
                        className="bg-dark-blue"
                        content={(
                            <>
                                <p>Manage threats allows you to add or remove threat types.</p>
                                <p>Any content that has a threat type assigned to it will mark the content as ‘threatening’.</p>
                                <p>Removing all threats will mark content as ‘non-threatening’.</p>
                            </>

                        )}
                    />
                </h2>
                <FeatherIcon.X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-11"
                    stroke="#050E2B"
                    onClick={() => { handleClose ? handleClose() : closeModal(); }}
                />
            </div>
            {removedSignals.length > 0 && (
                <Alert color="warning" className="mb-0 d-flex w-100 align-items-center">
                    <FeatherIcon.AlertCircle size={40} color={color.yellow[300]} className="mr-2 " />
                    <p>Removing a threat will remove the likelihood score on that threat until the threats
                        have been reset. <span className="text-primary" onClick={handleOnClick}>Learn more</span> about our
                        likelihood scores.
                    </p>
                </Alert>
            )}
            <div className="manage-signals mt-3">
                {filteredTypes.map((signal) => {
                    const contentSignal = selectedList.find((a) => a.signalName === signal.name);
                    const isChecked = (!!contentSignal || addedKeys.includes(signal.key)) && !removedSignals.includes(signal.key);

                    return (
                        <div className="d-flex p-1 signal-item mr-2 mb-2" key={signal.key}>
                            <CustomInput
                                checked={isChecked}
                                innerRef={(el: HTMLInputElement) => { checkboxRefs.current[signal.key] = el; }}
                                type="checkbox"
                                id={`${signal.name}`}
                                className="p-0 ml-4"
                                onChange={() => handleOnChange(signal.key, !!contentSignal)}
                                data-testid={signal.key}
                            />
                            <div className="d-flex">
                                {signal.isResolver && (
                                    <div className="d-flex">
                                        <ThreatSignalTooltip widgetName="Toxic information" classes="no-white-space" />
                                        &nbsp;-&nbsp;
                                    </div>
                                )}
                                <ThreatSignalTooltip widgetName={signal.name} classes="no-white-space" />
                            </div>

                        </div>
                    ); })}
            </div>
            <div className="mt-2">
                <Button color="secondary"
                    className="mr-2"
                    disabled={loading}
                    onClick={handleOnClose}
                >Close
                </Button>
                <Button color="primary" className="mr-2" disabled={loading} onClick={handleOnSave}>{(
                    loading)
                    ? <Spinner className="ml-1" size="sm" color="white" /> : 'Save'}
                </Button>
                <Button color="danger"
                    disabled={loading || isMatch}
                    onClick={handleReset}
                >{(loading)
                        ? <Spinner className="ml-1" size="sm" color="white" /> : 'Reset'}
                </Button>
            </div>
        </>

    );
};
