import React from 'react';
import { Button, CustomInput } from 'reactstrap';
import { RefreshCw } from 'react-feather';
import { useQuery } from '@apollo/client';
import { useModal } from '../../../../context/modal/ModalComponent';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { useClusteringStatus } from '../../../../services/Clusters/getClusteringStatus';
import { formatDateLocal } from '../../../../utils/getTimeFrom';
import { getToast } from '../../../../utils/getToast';
import { useCookies } from '../../../../context/cookies/CookiesContext';
import { useStartRoomClustering } from '../../../../services/Clusters/startRoomClustering';
import { GET_CLUSTERS_COUNT } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';

const cookieId = 'updateNarrativesConfirmation';

export const UpdateNarrativeModal = ({ startRoomClustering, lastUpdated }: { startRoomClustering: () => void, lastUpdated: number }) => {
    const { closeModal } = useModal();
    const { setCookie } = useCookies();
    return (
        <div>
            <p className="my-3">Narratives were last updated at {formatDateLocal(lastUpdated, 'DD/MM/YYYY, h:mmA')}.</p>
            <p className="my-3">
                Updating Narratives will add new Narratives up until the current time.
                This action can only be performed once every 24 hours.
            </p>
            <CustomInput type="checkbox"
                name={cookieId}
                onChange={({ target: { checked } }) => setCookie(cookieId, !!checked)}
                className="custom-control-input-small my-3"
                label="Do not show this message again"
                id={cookieId}
            />
            <hr className="my-3" />
            <Button onClick={closeModal} className="mr-2">Cancel</Button>
            <Button color="primary" onClick={() => { startRoomClustering(); closeModal(); }}>Update Narratives</Button>
        </div>
    );
};

export const UpdateNarrativeButton = () => {
    const { setModal } = useModal();
    const toast = getToast();
    const { startRoomClustering, loading: startRoomClusteringLoading } = useStartRoomClustering({ refresh: true });
    const { getCookie } = useCookies();

    const {
        clusteringHasNotBeenRun,
        loading,
        clusteringStartTime
    } = useClusteringStatus({ refresh: true });

    const room = getRoom();
    const projectId = room?.project_id;

    const { data: countData } = useQuery(GET_CLUSTERS_COUNT, {
        variables: {
            projectId
        },
        skip: !projectId
    });

    const tryAgainDate = new Date(clusteringStartTime + (24 * 60 * 60 * 1000));
    const canTryAgain = tryAgainDate < new Date();

    const handleClick = () => {
        if (!canTryAgain) {
            toast.error(`You can only update Narratives every 24 hours. You can next update 
                Narratives on ${formatDateLocal(tryAgainDate, 'DD/MM/YYYY, h:mmA')}.`);
            return;
        }
        const dontShowConfirmation = getCookie(cookieId);
        if (dontShowConfirmation === 'true') {
            startRoomClustering();
            return;
        }
        setModal({
            header: 'Update Narratives?',
            component: <UpdateNarrativeModal startRoomClustering={startRoomClustering}
                lastUpdated={clusteringStartTime}
            />
        });
    };

    const noClustersFound = !countData?.getClustersCount?.clusters;

    if (loading || noClustersFound) return null;

    if (clusteringHasNotBeenRun && noClustersFound) return null;

    return (
        <TooltipWrapper id="updateNarrativesTooltip" tooltipText="Update Narratives" placement="bottom-end">
            <Button color="link" className="p-0 mw-0" onClick={handleClick} data-testid="updateNarrativesButton">
                <RefreshCw className={startRoomClusteringLoading ? 'rotate' : ''} />
            </Button>
        </TooltipWrapper>
    );
};
