import * as React from 'react';
import { color } from '../../utils/getColors';

export const ColorPickerIcon = () => (
    <svg width={16}
        height={18}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill="#231F20"
            d="M2.23 14.29c-.316 0-.53-.106-.65-.32-.12-.213-.104-.475.04-.794L7.032.736C7.243.247 7.565 0 7.997 0c.196
            0 .377.056.542.17.165.111.297.3.404.566l5.434 12.44c.144.319.165.585.058.795-.104.21-.316.318-.631.318-.236
            0-.426-.062-.57-.188a1.373 1.373 0 0 1-.355-.548l-1.28-2.98H4.377l-1.26 2.98c-.21.489-.504.736-.884.736H2.23ZM7.979
            2.046 4.968 9.2h6.061L8.02 2.047h-.04Z"
        />
        <path d="M.306 16.392H4v1.605H.306a.309.309 0 0 1-.306-.31v-.986c0-.17.138-.309.306-.309Z"
            fill="#4367F8"
        />
        <path d="M8 16.392H4v1.605h4v-1.605Z" fill="#F64747" />
        <path d="M12 16.392H8v1.605h4v-1.605Z" fill="gold" />
        <path d="M12 16.392h3.694c.168 0 .306.14.306.31v.986c0 .17-.138.309-.306.309H12v-1.605Z"
            fill={color.green[200]}
        />
    </svg>
);
