import React from 'react';
import { formatThreatLabel } from '../../utils/text';
import { getRoom } from '../../utils/variables';

type ThreatTagsProps = {
    whyathreat: string,
    small?: boolean
}

export const ThreatTags = ({ whyathreat, small }: ThreatTagsProps) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];

    if (disabledThreatTypes.includes(whyathreat)) return null;

    const threatLabel = formatThreatLabel(whyathreat);

    return (
        <div className="d-flex align-items-center">
            {(threatLabel && whyathreat) && (
                <div color="dark"
                    className={`tag ${small ? 'small font-size-10 p-11 line-height-1 mh-0 mb-11' : 'line-height-normal mb-1'}`}
                >
                    {threatLabel.name}
                </div>
            )}
        </div>
    );
};
