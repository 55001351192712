import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { CoverPage, checkIfBgIsDark } from './CoverPage';
import { Map } from './Map';
import { OverviewPage } from './OverviewPage';
import { TextEditor } from './TextEditor';
import { ThreatsWatchlist } from './ThreatsWatchlist';
import { TopCustomNarratives } from './TopCustomNarratives';
import { TopThreats } from './TopThreats';
import { updatePageAction } from '../../../../../../redux/reports';
import { getRoom } from '../../../../../../utils/variables';

export const Page = ({ data, pdf, pageNumber }) => {
    const room = getRoom();
    const pageRef = useRef(null);
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const { template } = report;
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;
    const handleTitleUpdate = (val) => dispatch(updatePageAction({ id: data.id, updates: { pageTitle: val } }));
    const handleSummaryUpdate = (val) => dispatch(updatePageAction({ id: data.id, updates: { bodyText: val } }));
    useEffect(() => {
        if (pageRef.current) {
            // created custom attribute on purpose because the client height is not accessable once the page is destroyed
            pageRef.current.setAttribute('clientHeight', pageRef.current.clientHeight);
        }
    }, [data]);

    if ((data.type === 'threatsWatchlist') && !watchlistEnabled) {
        return null;
    }
    return (
        <div className="mb-4 p-4 shadow pp-slide-card bg-white height-auto"
            style={{
                backgroundImage: (data.type === 'cover' && template.backgroundImage) ? `url("${template.backgroundImage}")` : 'none'
            }}
            id={`${pdf ? 'pdf-' : ''}page-${data.id}`}
            ref={pageRef}
        >
            <div className="pp-slide d-flex flex-column">
                {data.type === 'cover' && <CoverPage data={data} pdf={pdf} />}
                {data.type !== 'cover' && (
                    <>
                        <div className={`page-title px-3 py-2 ${pdf ? '' : 'border-dashed'}
                        w-100 font-size-${template.style.pageTitle.fontSize}`}
                        >
                            <TextEditor value={data.pageTitle}
                                onChange={debounce(handleTitleUpdate, 500)}
                                placeholder={pdf ? '' : 'Edit page title'}
                                disabled={pdf}
                            />
                        </div>
                        <div className={`flex-1 body-text h-100 mt-3 d-flex flex-column
                        font-size-${template.style.bodyText.fontSize} ${
                        (data.type === 'summary' && !pdf) ? 'py-2 border-dashed mb-3' : ''}`}
                        >
                            {data.type === 'overview' && <OverviewPage data={data} />}
                            {data.type === 'summary' && (
                                <div className="px-3">
                                    <TextEditor value={data.bodyText}
                                        onChange={debounce(handleSummaryUpdate, 500)}
                                        placeholder={pdf ? '' : 'Add body text here'}
                                        multiline
                                        disabled={pdf}
                                    />
                                </div>
                            )}
                            {data.type === 'threats' && <TopThreats data={data} />}
                            {data.type === 'customNarratives' && <TopCustomNarratives data={data} />}
                            {data.type === 'threatsWatchlist' && <ThreatsWatchlist data={data} />}
                            {data.type === 'map' && <Map page={data} pdf={pdf} />}
                        </div>
                    </>
                )}

                {report.template.settings.pageNumber ? <PageNumber pageNumber={pageNumber} /> : null}
            </div>
        </div>
    );
};

const PageNumber = ({ pageNumber }) => {
    const { report: { template } } = useSelector(state => state.createReport);
    let color = 'dark';
    if (pageNumber === 1 && checkIfBgIsDark(template)) {
        color = 'light';
    }
    return <p className={`report-page-number m-0 text-${color}`}>{pageNumber}</p>;
};
