import { convertToMarkers } from '../convertToMarkers';

export const squareBracketsValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    // Find all square brackets outside quotes and store the indexes

    [...query.matchAll(/\[|\]/g)].forEach((match) => {
        highlight.push([match.index, match.index + match[0].length]);
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have used box brackets \'[ ]\'. The Boolean only supports parentheses \'( )\''
        )
    };
};
