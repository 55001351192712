import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { Pagination } from '../../../../components/Molecules';
import { lodashSort } from '../../../../utils/lib/tableSorting';

export const InvestigationTable = ({ data }) => {
    const [tableData, setTableData] = useState(data?.slice(0, 10) || []);
    const [sort, setSort] = useState({ field: 'summary', order: 'dsc' });
    const [page, setPage] = useState(0);
    useEffect(() => {
        if (data.length > page) {
            setTableData(data.slice(page, page + 10));
        }
        else {
            setPage(0);
            const offset = 10;
            setTableData(data.slice(offset, offset + 10));
        }
    }, [page, data]);
    return (
        <div className="overflow-x-auto">
            <Table striped className="mb-0 border-bottom" hover>
                <thead>
                    <tr>
                        <th><SortToggle sort={sort} setSort={setSort} field="summary" name="Investigation Title" /></th>
                        <th><SortToggle sort={sort} setSort={setSort} field="situationRoom.project_name" name="Situation room" /></th>
                        <th><SortToggle sort={sort} setSort={setSort} field="createdBy.displayName" name="User" /></th>
                        <th>Email</th>
                        <th><SortToggle sort={sort} setSort={setSort} field="updatedAt" name="Received date" /></th>
                    </tr>
                </thead>
                <tbody>
                    {lodashSort([...tableData], sort).map((item) => (
                        <tr key={item.id}>
                            <td>
                                <Link to={{ pathname: `/admin/investigation/${item.id}` }}
                                    key={item.id}
                                >
                                    {item?.summary.trim() || 'Not available'}
                                </Link>
                            </td>
                            <td>
                                {item?.situationRoom?.project_name }
                            </td>
                            <td>{item?.createdBy?.displayName || item?.createdBy?.firstName || 'Not available' }</td>
                            <td>{item?.email || item?.createdBy?.email}</td>
                            <td>{item?.updatedAt ? moment(new Date(item?.updatedAt)).format('DD/MM/YYYY | hh:mm a')
                                : moment(new Date(item.createdAt)).format('DD/MM/YYYY | hh:mm a')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {
                data.length > 10 && (
                    <Pagination total={data?.length || 0}
                        itemsPerPage={10}
                        offset={page}
                        setOffset={setPage}
                        className="py-2"
                    />
                )
            }
        </div>
    );
};
