import React from 'react';
import { Table } from 'reactstrap';
import { SortToggle } from '../../../../../components/Table/SortToggle';
import { TeamMembersTableBody } from './TeamMembersTableBody';

export const TeamMembersTable = ({ usersData, sort, setSort }) => (
    <Table className="mb-0 border-bottom" hover>
        <thead>
            <tr>
                <th />
                <th>
                    <SortToggle sort={sort} setSort={setSort} field="displayName" name="Name" />
                </th>
                <th>
                    <SortToggle sort={sort} setSort={setSort} field="email" name="Email" />
                </th>
                <th>
                    <SortToggle sort={sort} setSort={setSort} field="instance" name="Client" />
                </th>
                <th>
                    <SortToggle sort={sort} setSort={setSort} field="role" name="Role" />
                </th>
                <th>
                    <SortToggle sort={sort} setSort={setSort} field="status" name="Status" />
                </th>
                <th>
                    <SortToggle sort={sort} setSort={setSort} field="lastSeen" name="Last seen" />
                </th>
                <th>Action</th>
            </tr>
        </thead>
        <TeamMembersTableBody usersData={usersData} />
    </Table>
);
