import React from 'react';
import { Button } from 'reactstrap';

type IconButtonProps = {
    icon: React.ReactElement
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    className?: string
    disabled?: boolean
    dataTestid?: string
    text?: React.ReactElement | string
}

export const IconButton = ({ icon, onClick, className = '', disabled, dataTestid, text }: IconButtonProps) => (
    <Button onClick={onClick}
        className={`${className} p-0 mw-0 border-0 bg-transparent`}
        color="link"
        disabled={disabled}
        data-testid={dataTestid}
    >
        {icon}
        {text && text}
    </Button>
);
