import React from 'react';

export const Tool = ({ Icon, selected, onClick }) => (
    <div className={`${selected ? 'bg-light' : 'text-gray-700'} rounded py-11 px-1 transition mr-2`}
        role="button"
        onClick={onClick}
        onMouseDown={(e) => e.preventDefault()}
    >
        <Icon size={22} />
    </div>
);
