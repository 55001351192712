import React, { useState } from 'react';

import {
    CustomInput,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { useDropDown } from '../../../utils/useDropdown';
import { Loading } from '../../Loading';
import { color } from '../../../utils/getColors';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';

type DropdownWithSearchAndCheckboxesProps = {
    name: string,
    placeholder?: string,
    description?: string,
    items: {
        id: string,
        name: string,
        selected: boolean
    }[],
    handleSelect: (id: string) => void,
    loading: boolean,
    search?: boolean
};

export const DropdownWithSearchAndCheckboxes = ({
    name, placeholder, description, items, handleSelect, loading, search = true
}: DropdownWithSearchAndCheckboxesProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');
    const [dropdownId] = useState(uuid());

    const filteredItems = searchValue ? items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : items;

    const handleSelectItem = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleSelect(id);
    };

    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
        >
            <DropdownToggle tag="div"
                data-testid="dropdown-toggle"
                className={`dropdown-filter d-flex justify-content-between align-items-center cursor-pointer px-2 py-1 
                    ${dropdown && 'border border-primary'}`}
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color={color.blue[500]}
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={`dropdown-filter-menu-checkboxes ${dropdown && 'border border-primary'}`}>
                {(items.length > 0 && search) && (
                    <div className="p-2 border-bottom">
                        <Input value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            placeholder={placeholder || 'Search'}
                            data-testid="dropdown-search-input"
                        />
                    </div>
                )}
                <div className="dropdown-filter-menu-items scrollbar-small dropdown-form">
                    {(filteredItems.length > 0 && !loading) && (
                        <>
                            {description && <div>{description}</div>}
                            {filteredItems.map((item) => (
                                <FormGroup key={item.id}>
                                    <CustomInput tag="checkbox"
                                        type="checkbox"
                                        checked={item.selected}
                                        className="custom-control-input-checkbox py-1"
                                        label={item.name.length >= 11
                                            ? (
                                                <TooltipWrapper tooltipText={item.name} id={`tooltip_${dropdownId}_${item.id}`}>
                                                    <p className="truncate-this">{item.name}</p>
                                                </TooltipWrapper>
                                            ) : <p className="truncate-this">{item.name}</p>}
                                        id={`${dropdownId}_${item.id}`}
                                        data-testid={`checkbox-${item.id}`}
                                        value={item.name}
                                        onChange={(e) => { handleSelectItem(item.id, e); }}
                                    />
                                </FormGroup>
                            ))}
                        </>
                    )}
                    {loading && <Loading relative height={168} width={255} />}
                    {(!filteredItems.length && !loading) && <p className="no-white-space">No results found</p>}
                </div>
            </DropdownMenu>
        </Dropdown>
    ); };
