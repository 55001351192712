import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { getRoom } from '../../../../../utils/variables';
import { CompareNarrativeThemesButtons } from './CompareNarrativeThemesButtons';
import { CompareNarrativesButtons } from './CompareNarrativesButtons';
import { getParam } from '../../../../../utils/urlParams';

export enum CompareButtonType {
    AddButton = 'addButton',
    AddRemoveButton = 'addRemoveButton',
}

export type SelectedNarrativesType = {
    id: string
    title: string
}

type CompareButtonsType = {
    clusterIds: string[]
    clusterTitles: string[]
    type: CompareButtonType
    onAddedToComparison?: () => void
    container?: string
    id?: string,
    isParent?: boolean
    isCompareModal?: boolean
}

export const CompareButtons = ({
    clusterIds,
    clusterTitles,
    onAddedToComparison,
    type,
    container = 'selected-cluster-view',
    id = 'comparisonButton',
    isParent = false,
    isCompareModal = false
}: CompareButtonsType) => {
    const room = getRoom();
    const param = getParam('tab');
    const { isParentCluster } = useSelector((state: RootState) => state.selectedCluster);
    const { isListViewEnabled } = useSelector((state: RootState) => state.activeTab);
    const aiNarrativeComparisons = room.instance?.plan?.others?.aiNarrativeComparisons;
    const checkParent = (!isListViewEnabled && isParentCluster && param !== 'narrative-clusters') || isParent;

    if (!aiNarrativeComparisons) return null;

    if (checkParent) {
        return (
            <CompareNarrativeThemesButtons clusterIds={clusterIds}
                clusterTitles={clusterTitles}
                type={type}
                onAddedToComparison={onAddedToComparison}
                container={container}
                id={id}
                isCompareModal={isCompareModal}
            />
        );
    }

    return (
        <CompareNarrativesButtons clusterIds={clusterIds}
            clusterTitles={clusterTitles}
            type={type}
            onAddedToComparison={onAddedToComparison}
            container={container}
            isCompareModal={isCompareModal}
        />
    );
};

export const getSelectedForComparison = ({
    ids,
    titles,
    currentlySelected
}: {
    ids: string[],
    titles: string[],
    currentlySelected: SelectedNarrativesType[]
}) => {
    const idAndTitle = ids.map((id, index) => ({ id, title: titles[index] }));
    const allSelected = [...currentlySelected, ...idAndTitle];
    const selectedWithoutDuplicates: { [key: string]: SelectedNarrativesType } = {};
    // Duplicate Removal
    allSelected.forEach(selectedNarrative => {
        selectedWithoutDuplicates[selectedNarrative.id] = selectedNarrative;
    });

    return selectedWithoutDuplicates;
};
