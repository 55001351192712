import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../utils/useDropdown';
import { color } from '../../utils/getColors';

type SearchSortDropdownProps = {
    score?: boolean,
    sortOption: string,
    handleSort: ((num: number, e: React.MouseEvent<any>) => void)
}

export const SearchSortDropdown = ({
    score,
    sortOption,
    handleSort
}: SearchSortDropdownProps) => {
    const [dropdown, toggleDropdown] = useDropDown();

    return (
        <Dropdown isOpen={dropdown} toggle={toggleDropdown} className="mr-2 mb-1 set-min-width">
            <DropdownToggle tag="div" className="cursor-pointer d-flex" data-testid="search-sort-dropdown">
                <span className="flex-shrink-0 mr-1 align-self-center">Sort by</span>
                <div className="form-control">
                    {sortOption !== '' ? sortOption : 'Most recent'}
                    <span className="ml-1">
                        <ChevronDown color={color.blue[500]} size={15} />
                    </span>
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem name="Most recent" onClick={(e) => handleSort(1, e)}>Most recent</DropdownItem>
                <DropdownItem name="Engagement" onClick={(e) => handleSort(2, e)}>Engagement</DropdownItem>
                {score && <DropdownItem name="Relevance" onClick={(e) => handleSort(3, e)}>Relevance</DropdownItem>}
            </DropdownMenu>
        </Dropdown>
    );
};
