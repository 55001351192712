import { Button, Form } from 'reactstrap';
import React, { useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { TextareaWithCounter } from '../../../Form/TextareaWithCounter';
import { Alert } from '../../../Atoms';

type NarrativeProps = {
    narrativeTitle?: string,
    notes?: string
    title?: string
}

type NarrativeVersionModalProps = {
    onConfirm: ({ notes, title }: NarrativeProps) => void,
    onCancel: () => void,
    modalTitle: string,
    narrative?: NarrativeProps
}

export const NarrativeVersionModal = ({ onConfirm, onCancel, modalTitle, narrative = {} }: NarrativeVersionModalProps) => {
    const [title, setTitle] = useState(narrative.narrativeTitle || '');
    const [notes, setNotes] = useState(narrative.notes || '');
    const [showAlert, setShowAlert] = useState(false);

    const handleCancel = () => {
        onCancel();
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!title) {
            setShowAlert(true);
        } else {
            onConfirm({ title, notes });
        }
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between">
                    <h2 className="mb-2 mt-0">{modalTitle}</h2>
                    <FeatherIcon.X color="#050E2B"
                        size={20}
                        data-testid="close-modal"
                        className="cursor-pointer mt-1"
                        stroke="#050E2B"
                        onClick={handleCancel}
                    />
                </div>
                <div>
                    {showAlert && <Alert errorMessage="You must provide a version name" closeAlert={() => setShowAlert(false)} />}
                    <TextareaWithCounter className="mb-2"
                        ariaLabel="title"
                        label="Version name"
                        name="title"
                        value={title || ''}
                        rows="1"
                        onChange={(e) => { setTitle(e); setShowAlert(false); }}
                        limit={25}
                    />
                    <TextareaWithCounter className="mb-2"
                        ariaLabel="notes"
                        label="Notes"
                        name="notes"
                        value={notes || ''}
                        rows="4"
                        onChange={setNotes}
                        limit={250}
                    />
                </div>
                <Button className="mr-2" color="secondary" onClick={handleCancel}>Cancel</Button>
                <Button type="submit" color="primary">Confirm</Button>
            </Form>
        </div>
    );
};
