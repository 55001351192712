import React from 'react';
import moment from 'moment';
import { Edit, Trash2 } from 'react-feather';
import { Button } from 'reactstrap';
import { useModal } from '../../../context/modal/ModalComponent';
import { DataProps, RoomFilterProps } from '../FiltersLayout';
import { truncateString } from '../../../utils/text';
import { color } from '../../../utils/getColors';
import { DeleteFilter } from './DeleteFilter';
import { TooltipWrapper } from '../../ToolTip/TooltipWrapper';

type SavedFilterRowProps = {
    filter: RoomFilterProps,
    handleEditSavedFilter: (e: string) => void,
    clusters?: boolean
}

export const SavedFilterRow = ({ filter, handleEditSavedFilter, clusters = false }: SavedFilterRowProps) => {
    const { setModal } = useModal();

    const handleFilterDelete = (id: string) => {
        setModal({
            component: (
                <DeleteFilter id={id} clusters={clusters} />
            )
        });
    };
    const handleFilterName = (name: string) => {
        if (name === 'Locations') { return 'Location mentions'; }
        if (name === 'ThreatType') { return 'Threat type'; }
        return name;
    };
    const contentItems = filter.data.filter((a) => a.items.length > 0);

    return (
        <tr key={filter.id}>
            <td className="saved-filter-row">
                <Button color="link"
                    className="p-0 m-0"
                    onClick={() => handleEditSavedFilter(filter.id)}
                    data-testid={`${filter.id}-editname`}
                >
                    {truncateString(filter.name, 15, false)}
                </Button>
            </td>
            <td data-testid={`${filter.id}-name`}>{filter?.user?.displayName}</td>
            <td>{moment(filter.createdAt).format('DD/MM/YYYY')}</td>
            <td className="w-50-p">{contentItems.map((filterItem: DataProps) => (
                <p className="filter-item mr-1" key={filterItem.filter} data-testid={`${filter.id}-${filterItem.filter}`}>{
                    `${handleFilterName(filterItem.filter)} (${filterItem.items.length})`
                }
                </p>
            ))} {filter.dateRange.startDate !== 0
            && <p className="filter-item mr-1" key="Date">Date</p>}
            </td>
            <td>
                <div className="d-flex w-100">
                    <TooltipWrapper tooltipText="Edit" id={`tooltip-edit-${filter.id}`} placement="top">
                        <Edit size={20}
                            className="d-block mr-1 cursor-pointer"
                            color={color.blue[500]}
                            onClick={() => handleEditSavedFilter(filter.id)}
                            data-testid={`${filter.id}-edit`}
                        />
                    </TooltipWrapper>
                    <TooltipWrapper tooltipText="Delete" id={`tooltip-delete-${filter.id}`} placement="top">
                        <div className="cursor-pointer" onClick={() => handleFilterDelete(filter.id)} data-testid={`${filter.id}-delete`}>
                            <Trash2 size={20} className="d-block" color={color.blue[500]} />
                        </div>
                    </TooltipWrapper>

                </div>
            </td>
        </tr>
    ); };
