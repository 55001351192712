import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { AlertCircle } from 'react-feather';
import { Dropdown } from '../../../../components/Form/Dropdown';
import { useRoom } from './RoomContext';

export const SelectClient = ({ onChange, value }) => {
    const { room } = useRoom();
    const [originalValue] = useState(value);
    const { data, loading } = useQuery(GET_INSTANCES_FOR_CLIENT_SELECT);
    const instances = data?.instances || [];
    const options = instances.map(instance => ({ key: instance.id, name: instance.clientName }));
    if (loading) { return null; }
    const current = options.find(option => option.key === value);
    const original = options.find(option => option.key === originalValue);
    return (
        <div>
            <div className="maxw-350">
                <Dropdown options={options}
                    value={current}
                    onChange={(option) => onChange(option.key)}
                />
            </div>
            {originalValue !== value && (
                <div className="d-inline-block">
                    <Alert className="mt-3 mb-0 p-2 d-flex align-items-center line-height-1" color="primary">
                        <span className="pr-1"><AlertCircle className="text-primary" /></span>
                        Selecting a new instance will move {room.project_name} from {original.name} to {current.name}.
                    </Alert>
                </div>
            )}
        </div>
    );
};

export const GET_INSTANCES_FOR_CLIENT_SELECT = gql`
    query getInstancesForClientSelect {
        instances {
            id
            clientName
        }
    }
`;
