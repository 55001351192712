import { tagsWithEnforcedEmptySpaces } from '../../BooleanValidationErrors';
import { convertToMarkers } from '../../convertToMarkers';
import { getTagData } from './getTagData';

export const enforceEmptySpaceInValueValidator = (query, queryByLines, lineLengths, tag) => {
    const highlight = [];

    const tagFragments = tag.split(':');

    const tagData = getTagData(tagFragments, query);
    tagData?.forEach(({ tagValue, tagSnapshot, tagSnapshotValue }) => {
        if (tagValue.trimEnd().length === tagSnapshotValue.trimEnd().length) {
            highlight.push([
                query.indexOf(tagSnapshot),
                query.indexOf(tagSnapshot) + tagSnapshot.length
            ]);
        }
    });

    return highlight.length === 0 ? {
        markers: []
    } : {
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            `Tags: ${tagsWithEnforcedEmptySpaces.join(', ')} must be followed by empty space after colon`
        )
    };
};
