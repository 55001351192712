import { color } from '../../utils/getColors';

type DateTimeLabelFormats = {
    hour?: string;
    day?: string;
    month?: string;
    week?: string;
}

type ChartColors = string[]

export const dateTimeLabelFormats: DateTimeLabelFormats = {
    hour: '%d/%m/%Y %H:%M',
    day: '%d/%m/%Y',
    month: '%m/%Y',
    week: '%d/%m/%Y'
};

export const chartColors: ChartColors = ['#4367F8', color.graphColors.magenta,
    color.graphColors.robinsEggBlue, color.graphColors.tomato,
    color.graphColors.tangerineYellow, color.graphColors.slateBlue, color.graphColors.citrus,
    color.graphColors.endeavour, color.graphColors.lightBlue, '#26BC8C'];
