import { convertESInterval } from '../../../../../utils/convertESInterval';

const getPercentageChange = (currentTotal: number, previousTotal: number) => {
    if (previousTotal === 0) {
        return null;
    }
    const percentageChange = ((currentTotal - previousTotal) / previousTotal) * 100;
    return Math.abs(percentageChange).toFixed(2).replace(/\.00$/, '');
};

export const getTotalInPastXDays = ({ data, interval }: { data: { key: number, count: number }[], interval: string }) => {
    const intervalInMilliseconds = convertESInterval(interval);
    const millisecondsInADay = 24 * 60 * 60 * 1000;

    // Function to calculate total sum of count values between start and end timestamps
    const calcTotalSum = (startTime: number, endTime: number) => data.filter(point => point.key > startTime && point.key <= endTime)
        .reduce((total, point) => total + point.count, 0);

    if (data.length === 0) {
        return {
            message: '',
            trending: false
        };
    }

    const endTime = data[data.length - 1].key;

    const generateComparisonText = (timePeriod: number, label: string) => {
        const currentTotal = calcTotalSum(endTime - (timePeriod / 2), endTime);
        const previousTotal = calcTotalSum(endTime - timePeriod, endTime - (timePeriod / 2));
        const percentageChange = getPercentageChange(currentTotal, previousTotal);

        if (percentageChange === null) {
            return {
                message: '',
                trending: currentTotal > previousTotal
            };
        }
        return {
            message: `${percentageChange}% ${currentTotal > previousTotal ? 'more' : 'less'} than previous ${label}`,
            trending: currentTotal > previousTotal
        };
    };

    if (interval === '1h') {
        if (data.length <= 24) {
            return generateComparisonText(millisecondsInADay, '12 hours');
        }
        return generateComparisonText(2 * millisecondsInADay, '24 hours');
    }

    if (intervalInMilliseconds >= millisecondsInADay * 7) {
        return generateComparisonText(60 * millisecondsInADay, '30 days');
    }

    return generateComparisonText(14 * millisecondsInADay, '7 days');
};
