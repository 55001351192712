import React, { useState, useEffect, useCallback } from 'react';
import {
    Modal,
    ModalBody,
    Form,
    Button,
    ModalHeader
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import { CREATE_INVESTIGATION, GET_INVESTIGATION_TOKENS } from '../../graphql';
import { useUser } from '../../context/user/UserContext';
import { Summary } from './Summary';
import { Details } from './Details';
import { Source } from './Source';
import { ContactEmail } from './ContactEmail';
import { Attachments } from './Attachments';
import { Loading } from '../Loading';
import { getRoom } from '../../utils/variables';
import { getToast } from '../../utils/getToast';

const emptyCheck = /^\s+$/;

export const RequestInvestigationForm = ({
    defaultDetails,
    urlOfSource,
    threatTest,
    noModal,
    onClose
}) => {
    const room = getRoom();
    const { user } = useUser();
    const toast = getToast();
    const [summary, setSummary] = useState('');
    const [file, setFile] = useState(false);
    const [details, setDetails] = useState(defaultDetails ? defaultDetails.text : '');
    const [source, setSource] = useState(urlOfSource || (threatTest ? window.location.href : ''));
    const [email, setEmail] = useState(user?.email);
    const [loading, setLoading] = useState(false);
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [createInvestigation] = useMutation(CREATE_INVESTIGATION, {
        refetchQueries: [{ query: GET_INVESTIGATION_TOKENS, variables: { instance: room?.instance?.id } }],
        onCompleted: () => {
            setLoading(false);
            toast.success("You've requested an investigation.");
            onClose();
        },
        onError: () => {
            setLoading(false);
            toast.error('Investigation Not created, Please try again');
        }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            situationRoom: room?.id,
            createdBy: user.id,
            status: 'In Progress',
            summary,
            details,
            source,
            email,
            uploadAttachment: multipleFiles
        };

        setLoading(true);
        createInvestigation({ variables: { data } });
    };
    const initialValues = useCallback(() => {
        setSummary('');
        setDetails(defaultDetails ? defaultDetails.text : '');
        setSource(urlOfSource || (threatTest ? window.location.href : ''));
    }, [defaultDetails, threatTest, urlOfSource]);

    useEffect(() => {
        initialValues();
    }, [initialValues]);

    const content = (
        <>
            {loading && <Loading />}
            <Form onSubmit={handleSubmit} data-testid="request-investigation-form">
                <Summary summary={summary} setSummary={setSummary} />
                <Details details={details} setDetails={setDetails} />
                <Source source={source} setSource={setSource} />
                <ContactEmail email={email} setEmail={setEmail} />
                <Attachments file={file} setFile={setFile} multipleFiles={multipleFiles} setMultipleFiles={setMultipleFiles} />
                <div className="mb-3">
                    <Button color="primary"
                        disabled={summary.length === 0 || emptyCheck.test(summary)}
                        type="submit"
                        className="mr-3"
                        data-testid="submit-button"
                    >Submit
                    </Button>
                    <Button color="secondary" onClick={onClose} data-testid="cancel-button">Cancel</Button>
                </div>
            </Form>
        </>
    );
    return noModal ? (
        content
    ) : (
        <Modal isOpen toggle={onClose} size="xl" data-testid="request-investigation-modal">
            <ModalHeader className="pl-5 pr-5 pt-4 pb-4 align-items-center" tag="h2">
                Request Investigation
            </ModalHeader>
            <ModalBody className="p-5">
                {content}
            </ModalBody>
        </Modal>
    );
};
