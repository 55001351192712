import React, { useState } from 'react';
import { Loading } from '../../../../../../../components/Loading';
import { useGetWatchlist } from '../../../../../../../services/Threat/getWatchlist';
import { getRoom } from '../../../../../../../utils/variables';
import { ThreatsTable } from '../TopThreats/ThreatsTable';
import { NoResults } from '../NoResults';

export const ThreatsWatchlistTableContainer = () => {
    const room = getRoom();
    const [offset, setOffset] = useState(0);
    const { watchlist, loading } = useGetWatchlist({ situationRoomId: room.id, getFullContent: true });

    if (loading) {
        return <Loading relative height={300} />;
    }
    if (watchlist?.length === 0) {
        return <NoResults text="There are no threats added to the watchlist yet!" />;
    }

    return (
        <ThreatsTable count={watchlist.length}
            content={watchlist.splice(offset, offset + 10)}
            fromModal
            type="threatsWatchlist"
            offset={offset}
            setOffset={setOffset}
        />
    );
};
