import React, { useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { SearchBar } from '../../../../components/Search';
import { getFoundText } from '../../../../utils/text/getFoundText';
import { useContent } from '../../../../services/Content/getContent';
import { ContentTableTypes, ContentTables } from '../../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { getRoom } from '../../../../utils/variables';
import { SearchBarNew } from '../../../../components/NewFilters';

export const ContentModal = ({ filter = {}, name = '', contentType = CONTENT_TYPE.MATCH }) => {
    const room = getRoom();
    const [sort, setSort] = useState('datetime');
    const [offset, setOffset] = useState(0);
    const sortOptions = {
        datetime: 'date',
        engagement: 'engagement',
        relevance: 'score'
    };

    const foundText = getFoundText(filter.type, name);
    const defaultFilters = {
        ...(filter.type ? {
            [elasticFieldMapping[filter.type] || filter.type]: filter.value
        } : {}),
        ...(filter.filter || {})
    };
    const { data: contents, count, loading } = useContent({
        filters: defaultFilters,
        skip: offset,
        limit: 10,
        sort: sortOptions[sort],
        fetchPolicy: 'no-cache',
        name: 'getContentModalData',
        useGlobalFilters: true
    });
    const sortApplied = (sortNum) => {
        const sortKeys = Object.keys(sortOptions);
        setSort(sortKeys[sortNum - 1]);
        setOffset(0);
    };

    if (loading) return <Loading height={200} relative />;
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;

    return (
        <div className="threats" data-testid="content-modal">
            {betaFiltersEnabled ? <SearchBarNew sortApplied={sortApplied} score sort defaultFilters={defaultFilters} overview />
                : (
                    <SearchBar placeholder="Search - Enter a search term to update the matches and press ENTER"
                        name="Overview Filters"
                        sort
                        sortOptionKey={sort}
                        sortApplied={sortApplied}
                        score
                        hiddenFilters={filter.type ? [filter.type.split('_')[0]] : []}
                    />
                )}
            <p className="mb-2">{count} matches {foundText}</p>
            <div className="mx-n3">
                <ContentTables contents={contents}
                    pagination={{ offset, setOffset, count }}
                    tableType={ContentTableTypes.OVERVIEW}
                    contentType={contentType}
                />
            </div>
        </div>
    );
};

export const elasticFieldMapping = {
    ctype__keyword: 'contentType',
    locations_detected__keyword: 'locations',
    lang_verbose__keyword: 'languages',
    from__keyword: 'from',
    hashtags__keyword: 'hashtags',
    mentions__keyword: 'mentions',
    keywords__keyword: 'keywords',
    entities__keyword: 'entities',
    sentiment__keyword: 'sentiment',
    threat_object__threat_labels__keyword: 'threatLabels'
};
