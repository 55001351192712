import React from 'react';
import { Button } from 'reactstrap';
import ErrorIcon from '../assets/images/pages/error.png';
import { EmptyResults } from '../assets/svg/empty-results';
import { SuccessIcon } from '../assets/svg/success-icon';
import { useModal } from '../context/modal/ModalComponent';
// TODO: move it accordingly: {
//  (
//  !open
//  && (message === "Situation Room created successfully"
//  || message === "Situation Room draft created successfully"
//  || message === "Situation Room draft updated successfully"))
//  && <Redirect to="/situation-rooms" />}

export const STATUS_TYPES = {
    success: 'success',
    error: 'error'
};

type StatusModalBodyProps = {
    title: string,
    message: string,
    messageType: string
}

export const StatusModalBody = ({ title, message, messageType }: StatusModalBodyProps) => {
    const { openPreviousModal } = useModal();
    return (
        <div>
            <h2>{title}</h2>
            <hr />
            {messageType === STATUS_TYPES.success ? <SuccessIcon /> : <img src={ErrorIcon} alt="" style={{ width: 160 }} />}
            { messageType === STATUS_TYPES.success ? <h2> Great! </h2> : <h2> Sorry! </h2> }
            <p className="mb-0">{message}</p>
            <hr />
            <Button color="secondary"
                data-testid="onClose-openModal"
                onClick={() => {
                    openPreviousModal();
                }}
            >Close
            </Button>
        </div>
    ); };

type StatusModalBodyNewProps = {
    title?: boolean | string,
    message: string,
    messageType: string,
    action?: () => void
}
export const StatusModalBodyNew = ({ title, message, messageType, action }: StatusModalBodyNewProps) => {
    const { closeModal } = useModal();
    return (
        <div data-testid="create-room-success">
            {!title && messageType === STATUS_TYPES.success ? <SuccessIcon /> : <EmptyResults />}
            <h2 className={!title ? 'my-2' : 'mb-2'}>{title || message}</h2>
            <hr />
            {title ? (
                <>
                    <p>
                        {message}
                    </p>
                    <hr />
                </>
            ) : null}
            <Button color="secondary"
                onClick={() => {
                    if (action) { action(); }
                    closeModal();
                }}
            >Close
            </Button>
        </div>
    );
};
