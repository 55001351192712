import React, { useState } from 'react';
import { Pagination } from '../../../components/Molecules';
import { UsersTable } from './UsersTable';

export const Users = ({ users }) => {
    const [page, setPage] = useState(0);
    return (
        <div className="overflow-x-auto">
            <UsersTable filteredUsers={users} page={page} />
            {
                users?.length > 10 && (
                    <Pagination total={users?.length || 0}
                        itemsPerPage={10}
                        offset={page}
                        setOffset={setPage}
                        className="py-2"
                    />
                )
            }
        </div>
    );
};
