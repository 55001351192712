import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { getCtype } from '../../../utils/text';
import { getRoom } from '../../../utils/variables';
import { CheckboxFilter } from '../../Filters';
import { TooltipItem } from '../../ToolTip';
import { useModal } from '../../../context/modal/ModalComponent';
import { AdvanceSourceFilter } from './AdvanceSourceFilter';

export const SourceFilter = ({ sourcesWithDomains, setSourcesWithDomains }) => {
    const room = getRoom();
    const { setModal } = useModal();
    const { data } = useQuery(GET_SUBCATEGORY_DOMAIN, {
        variables: {
            projectId: room?.project_id,
            size: 100
        }
    });
    const source = data?.getContentTypesWithDomains?.map(src => ({ key: src.key, label: getCtype(src.key).name })) || [];
    const setAdvancedFiltersModal = () => {
        setModal({
            header: 'Source finder',
            component: <AdvanceSourceFilter sourceData={data?.getContentTypesWithDomains}
                sourcesWithDomains={sourcesWithDomains}
                setSourcesWithDomains={setSourcesWithDomains}
            />
        });
    };
    return (
        <div>
            <p className="font-weight-bold">Source</p>
            <div className="d-flex align-items-center">
                <p onClick={setAdvancedFiltersModal} className="mt-1 text-primary cursor-pointer">Source finder</p>
                <TooltipItem placement="right"
                    id="source-filters"
                    content="Sources and their domains mentioned in the content."
                    testid="suorces-filters-tooltip"
                />
            </div>
            <div className="maxh-350 scrollbar-small overflow-y-auto overflow-x-hidden">
                <CheckboxFilter title="Source"
                    filters={source}
                    values={source.filter(src => !sourcesWithDomains?.map(x => x.key).includes(src.key))?.map(x => x.key)}
                    setValues={(newSource) =>
                        setSourcesWithDomains(source.filter(src => !newSource.includes(src.key)))}
                    value="source"
                />
            </div>
        </div>
    );
};

export const GET_SUBCATEGORY_DOMAIN = gql`
    query getContentTypesWithDomains($projectId: String!, $size: Int, $filters: ContentFilters) {
        getContentTypesWithDomains(projectId: $projectId, size: $size, filters: $filters) {
            key
            domains
            domainCount
        }
    }
`;
