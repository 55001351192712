import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { standardTemplate } from './Templates/Standard';
import { RoomOverview } from '../../../generated/graphql';

// TODO: Test this
export const selectExtremesMin = createSelector(
    (state) => state.overview.visualisation.multiSmallCharts,
    ({ userMin }) => userMin
);
export const selectExtremesMax = createSelector(
    (state) => state.overview.visualisation.multiSmallCharts,
    ({ userMax }) => userMax
);
export const selectRange = createSelector(
    (state) => state.overview.visualisation.multiSmallCharts,
    ({ range }) => range
);

const initialState = {
    visualisation: {
        multiSmallCharts: {
            range: null,
            userMin: undefined,
            userMax: undefined
        }
    },
    edit: false,
    layout: standardTemplate as RoomOverview,
    layoutBackup: standardTemplate as RoomOverview,
    hasChanges: false
};

const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setRangeAction: (state, { payload }) => ({
            ...state,
            visualisation: {
                multiSmallCharts: {
                    ...state.visualisation.multiSmallCharts,
                    range: payload
                }
            }
        }),
        storeExtremesAction: (state, { payload: { userMin, userMax } }) => ({
            ...state,
            visualisation: {
                multiSmallCharts: {
                    ...state.visualisation.multiSmallCharts,
                    userMin,
                    userMax
                }
            }
        }),
        resetMultiSmallChartsAction: (state) => ({
            ...state,
            visualisation: {
                multiSmallCharts: initialState.visualisation.multiSmallCharts
            }
        }),
        setEdit: (state, { payload }) => ({
            ...state,
            edit: payload
        }),
        setLayout: (state, { payload }) => {
            const backup = JSON.parse(JSON.stringify(state.layoutBackup));

            return ({
                ...state,
                layout: payload,
                hasChanges: !isEqual(backup, payload)
            });
        },
        setLayoutBackup: (state, { payload }) => ({
            ...state,
            layoutBackup: payload,
            layout: payload,
            hasChanges: false,
            edit: false
        })
    }
});

export const {
    setRangeAction,
    storeExtremesAction,
    resetMultiSmallChartsAction,
    setEdit,
    setLayout,
    setLayoutBackup
} = overviewSlice.actions;
export const overview = overviewSlice.reducer;
