import React from 'react';
import { Button } from 'reactstrap';
import deletedIcon from '../../assets/images/Deleted.svg';

export const DeletedUser = () => (
    <div className="content-centered">
        <img src={deletedIcon} alt="user deleted" width="90" />
        <h2 className="m-0 pt-3 ">Your account is successfully deleted.</h2>
        <p className="pt-2 m-0 font-weight-semibold">Thank you for using Logically Intelligence.</p>
        <p className="font-weight-semibold">Please contact us if you need any support.</p>
        <Button className="mt-2 " onClick={() => { window.location.replace(`${window.location.origin}/login`); }}>Close window</Button>
    </div>
);
