import React from 'react';
import { color } from '../../utils/getColors';

export const User = ({ size }) => (
    <svg width={size ? `${size}px` : '60px'} height={size ? `${size}px` : '60px'} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill={color.blue['500']} />
        <path fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5514 1.20606V36.0345H59.3929C56.5999 49.7108 44.5016 60 30
            60C13.4315 60 0 46.5685 0 30C0 16.365 9.09625 4.85462 21.5514 1.20606Z"
            fill={color.blue['500']}
        />
    </svg>

);
