import { useQuery } from '@apollo/client';
import { CONTENT_ES_SERVICE } from '../../graphql';
import { checkFilters, makeQuery } from '../../pages/Room/CustomNarratives/Overview/CustomNarrativeDetails';
import { useFilterQuery } from '../Content/useFilterQuery';
import { useCustomNarratives } from '../Narratives/getCustomNarratives';

export const useCustomNarrativesAndThreats = ({ dateFilter }) => {
    const { data, loading } = useCustomNarratives();
    const narratives = data.map(narrative => ({
        id: narrative.id,
        threats: narrative.data.map(threat => ({
            ...threat,
            query: makeQuery(threat)
        })),
        narrative_title: narrative.name
    }));
    const allQueries = narratives.map(a => a.threats).flat();
    const { query: filterQuery } = useFilterQuery();
    const ret = useQuery(CONTENT_ES_SERVICE, {
        variables: {
            limit: 0,
            query: {
                ...filterQuery,
                bool: {
                    ...(filterQuery?.bool || {}),
                    filter: [
                        ...(filterQuery?.bool?.filter || []),
                        {
                            range: {
                                datetime_tz: {
                                    gte: dateFilter.startDate,
                                    lte: dateFilter.endDate
                                }
                            }
                        }
                    ]
                }
            },
            aggs: allQueries.map(threat => ({
                key: threat.id,
                value: {
                    filter: checkFilters(threat),
                    aggs: {
                        key: 'trend',
                        value: {
                            auto_date_histogram: {
                                buckets: 1000,
                                field: 'date',
                                minimum_interval: 'day'
                            },
                        }
                    }
                }
            }))
        },
    });
    return {
        data: ret.data?.contentServiceSearch?.aggregations ? narratives.map(narrative => ({
            ...narrative,
            threats: narrative.threats.map(threat => {
                const aggs = ret.data.contentServiceSearch.aggregations[threat.id];
                if (!aggs) { return false; }
                return {
                    ...threat,
                    aggs: {
                        ...aggs,
                        trend: aggs.trend.buckets
                    }
                };
            }).filter(a => a)
        })) : [],
        loading: loading || ret.loading
    };
};
