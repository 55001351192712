import React, { useState } from 'react';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { MoreVertical } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { useDropDown } from '../../../../../utils/useDropdown';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { RemoveConfirmation } from './RemoveConfimation';
import { RootState } from '../../../../../store';
import { EditModal } from './EditModal';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';

type ActionDropdownProps = {
    id: string
    containerId: string
    defaultTitle: string
    defaultSummary: string
    isSubCluster?: boolean
    onRemoved?: () => void
}

export const ActionDropdown = ({ id, defaultTitle, defaultSummary, containerId, isSubCluster = false, onRemoved }: ActionDropdownProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [actionId] = useState(uuid());
    const { selectedCluster } = useSelector((state: RootState) => state.selectedCluster);
    const { setModal } = useModal();
    const openConfirmationModal = () => {
        setModal({
            component: <RemoveConfirmation isParentCluster={!isSubCluster}
                id={id}
                onRemoved={onRemoved}
            />
        });
    };
    const openEditModal = () => {
        setModal({
            component: <EditModal isParentCluster={!isSubCluster}
                id={id}
                defaultTitle={defaultTitle}
                defaultSummary={defaultSummary}
            />
        });
    };

    const _id = `actions_${id || selectedCluster}_${actionId}`;

    return (
        <Dropdown onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            className="d-inline mw-0 mr-0"
            isOpen={dropdown}
            toggle={toggleDropdown}
            tag="div"
            direction={window.innerWidth < 540 ? 'left' : 'right'}
            data-testid="cluster-actions"
            id={`dropdown_${_id}`}
        >
            <DropdownToggle className="bg-transparent border-0 mw-0 mr-n1 my-n1 pr-0" data-testid="cluster-actions-toggle">
                <span>
                    <TooltipWrapper tooltipText="More actions" id={_id} placement="top-start" container={containerId}>
                        <Button color="link" className="p-0 mw-0" tag="div">
                            <MoreVertical data-testid="ellipses-vertical-icon" />
                        </Button>
                    </TooltipWrapper>
                </span>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={openEditModal}>
                    <span>Edit title and summary</span>
                </DropdownItem>
                <DropdownItem onClick={openConfirmationModal} className="px-3 py-1">
                    <span>Remove {(isSubCluster) ? 'narrative' : 'narrative theme'}</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};
