import * as React from 'react';
import { color } from '../../utils/getColors';

export const Preview = () => (
    <svg width={114}
        height={80}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M.876 69.514A4.124 4.124 0 0 0 5 73.638h104a4.123 4.123 0 0 0 4.124-4.124V11.66H.876v57.854Z"
            fill={color.grey[300]}
        />
        <path d="M109 0H5A4.124 4.124 0 0 0 .876 4.124v7.536h112.248V4.126a4.131 4.131 0 0 0-1.207-2.917A4.129 4.129 0 0 0 109 0Z"
            fill="#D2D4DB"
        />
        <path fill={color.grey[200]}
            d="M40.915 22.664H12.402c-.962 0-1.74.78-1.74 1.74v34.177c0 .961.778 1.74 1.74 1.74h28.513c.96 0 1.74-.779
        1.74-1.74V24.404c0-.96-.78-1.74-1.74-1.74ZM102.93 22.664H53.082c-.961 0-1.74.78-1.74 1.74v12.898c0 .96.779 1.74
        1.74 1.74h49.848a1.74 1.74 0 0 0 1.741-1.74V24.404a1.74 1.74 0 0 0-1.741-1.74ZM102.93 42.648H53.082c-.961
        0-1.74.779-1.74 1.74v12.897c0 .961.779 1.74 1.74 1.74h49.848a1.74 1.74 0 0 0 1.741-1.74V44.388a1.74 1.74 0 0
        0-1.741-1.74Z"
        />
        <path d="m24.153 35.373-9.007 10.786H33.16l-9.007-10.786Z" fill={color.grey[300]} />
        <path d="M31.104 34.161 21.086 46.16h20.04L31.103 34.16Z" fill="#fff" />
        <path d="M18.992 36.255a2.094 2.094 0 1 0 0-4.188 2.094 2.094 0 0 0 0 4.188Z"
            fill={color.grey[300]}
        />
        <path d="M7.622 7.673a1.796 1.796 0 1 0 0-3.592 1.796 1.796 0 0 0 0 3.592Z"
            fill={color.grey[200]}
        />
        <path fill={color.grey[300]}
            d="M12.028 7.673a1.796 1.796 0 1 0 0-3.593 1.796 1.796 0 0 0 0 3.593ZM16.703 7.673a1.796 1.796 0 1 0 0-3.593
        1.796 1.796 0 0 0 0 3.593Z"
        />
        <path fill="#D2D4DB"
            d="M98.052 29.016H57a.635.635 0 0 1 0-1.27h41.052a.635.635 0 0 1 0 1.27ZM98.052 33.958H57a.635.635 0 1 1
        0-1.27h41.052a.635.635 0 0 1 0 1.27ZM98.052 49.01H75.085a.635.635 0 1 1 0-1.27H98.05a.636.636 0 0 1 0
        1.27h.003ZM98.052 53.944H75.085a.635.635 0 1 1 0-1.27H98.05a.635.635 0 1 1 0 1.27h.003Z"
        />
        <path fill="#C8CACE"
            d="M57 80c9.311 0 16.86-7.549 16.86-16.86 0-9.313-7.549-16.862-16.86-16.862-9.313 0-16.861 7.55-16.861 16.861
        0 9.312 7.548 16.861 16.86 16.861Z"
        />
        <path fill="#fff"
            d="M57 69.466c-5.313 0-8.27-5.796-8.384-6.042a.64.64 0 0 1 0-.57c.124-.254 3.082-6.041 8.384-6.041 5.303 0
        8.27 5.795 8.385 6.042a.641.641 0 0 1 0 .569c-.115.246-3.075 6.042-8.385 6.042Zm-7.114-6.327c.638 1.108 3.194
        5.056 7.114 5.056s6.462-3.948 7.1-5.056c-.638-1.105-3.192-5.056-7.1-5.056-3.907 0-6.464 3.95-7.101 5.056h-.013Z"
        />
        <path d="M57 65.909a2.77 2.77 0 1 1 0-5.54 2.77 2.77 0 0 1 0 5.54Zm0-4.269a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
            fill="#fff"
        />
    </svg>
);
