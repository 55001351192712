import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Label } from 'reactstrap';
import { MultiSelectDropdown } from '../../../components/Form/MultiSelectDropdown';

export const FilterByClient = ({ onChange, defaultSelected }) => {
    const { data, loading } = useQuery(GET_INSTANCES_FOR_FILTER);
    const instances = data?.instances || [];
    const options = instances.map(instance => ({ id: instance.id, text: instance.clientName }));
    if (loading) { return null; }
    return (
        <div className="d-flex align-items-center">
            <Label className="mr-1">Filter by client</Label>
            <MultiSelectDropdown options={options}
                defaultOptions={defaultSelected || options}
                id="filter-by-client"
                onChange={onChange}
            />
        </div>
    );
};

export const GET_INSTANCES_FOR_FILTER = gql`
    query instances {
        instances {
            id
            clientName
        }
    }
`;
